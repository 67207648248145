import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import success from '../../../img/approvedicon.png'
import actionupload from '../../../img/action-ps-3.png';
import failure from '../../../img/cancelicon.png'
import InvoiceStatus from '../../../img/invoice-status.png'
import ReactToPrint from "react-to-print";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PaidIcon from '@mui/icons-material/Paid';
import Axios from "../../../Axios";
import { Toaster, toast } from 'react-hot-toast'
import * as XLSX from "xlsx";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 30px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const PrintPop = ({ bill }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); }
  const ref = useRef()

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const downloadExcelsheet = () => {
    let tableData = []
    if (bill.length > 0) {
      for (var i = 0; i < bill.length; i++) {
        var infos = {
          brandName: bill[i]?.billing?.Brand_Name,
          OrderId: bill[i]?.order_id,
          Service: bill[i]?.service,
          ProductId: bill[i]?.product_id,
          SalePrice: bill[i]?.amount ? bill[i]?.amount : 0,
          DtracerFee: bill[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
          PaymentProcessingFee: bill[i]?.payment_processing_fee?.toString(),
          BrandBalance: bill[i]?.positive_balance?.toString(),
          SettlementDate: bill[i]?.billing?.Payment_date,
          PaymentMethod: bill[i]?.billing?.Payment_method,

        }
        tableData.push(infos)
      }
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate a Blob from the workbook
      XLSX.writeFile(workbook, 'Brand.csv');
    } else {
      toast.error('No Product Selected')
    }

  };


  return (
    <div >
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="qr-detail cancel-invoice">

        {/* <div className='print-icon'>{<LocalPrintshopOutlinedIcon onClick={handleOpen}/>}</div> */}
        <div className='print'><img src={actionupload} alt={actionupload} onClick={handleOpen} /></div>
        {/* <Button onClick={handleOpen} ><LocalPrintshopOutlinedIcon /></Button> */}

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="popBOX BlockPopup cancel-invoice">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


            <div className="invoice-create">
              <p className="new-invoice">Data</p>

            </div>

            <TableContainer id="toprint" >
              <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={ref}>
                <TableHead>
                  <TableRow>


                    <TableCell align="center" className='head-topic'>Brand Name</TableCell>
                    <TableCell align="center" className='head-topic'>Order ID</TableCell>
                    <TableCell align="center" className='head-topic'>Service</TableCell>
                    <TableCell align="center" className='head-topic'>Product Id</TableCell>
                    <TableCell align="center" className='head-topic'>Sale Price</TableCell>
                    <TableCell align="center" className='head-topic'>Dtracer Fee</TableCell>
                    <TableCell align="center" className='head-topic'>Payment Processing Fee</TableCell>
                    <TableCell align="center" className='head-topic'>Brand Balance</TableCell>
                    <TableCell align="center" className='head-topic'>Settlement Date</TableCell>
                    <TableCell align="center" className='head-topic'>Payment Method</TableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {bill?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                      <TableCell className='row-topic'>{item?.details?.brand_name}</TableCell>
                      <TableCell className='row-topic'>{item?.order_id}</TableCell>
                      <TableCell className='row-topic'>{item?.service}</TableCell>
                      <TableCell className='row-topic'>{item?.product_id}</TableCell>
                      <TableCell className='row-topic'>{item?.amount ? item?.amount : 0}</TableCell>
                      <TableCell className='row-topic'>
                        {item?.service === "Certificate Creation" ? <>{item?.details?.product_details[0]?.Dtrace_Cer_Fee ? item?.details?.product_details[0]?.Dtrace_Cer_Fee : 0}</> : <></>}
                        {item?.service === "Warranty Extend" ? <>{item?.details?.product_details[0]?.Dtracer_Warr_Fee ? item?.details?.product_details[0]?.Dtracer_Warr_Fee : 0}</> : <></>}
                        {item?.service === "Change OwnerShip" ? <>{item?.details?.product_details[0]?.Dtracer_Change_Owner_Fee ? item?.details?.product_details[0]?.Dtracer_Change_Owner_Fee : 0}</> : <></>}
                      </TableCell>
                      <TableCell className='row-topic'>{item?.payment_processing_fee ? item?.payment_processing_fee : 0}</TableCell>
                      <TableCell className='row-topic'>{item?.positive_balance ? item?.positive_balance : 0}</TableCell>
                      <TableCell className='row-topic'>{new Date(item?.billing?.Payment_date).toLocaleDateString()}</TableCell>
                      <TableCell className='row-topic'>{item?.billing?.Payment_method}</TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div className="create-cancel-btns invoice-reject">
              <div className="create-btn">
                <ReactToPrint
                  bodyClass="print-agreement"
                  content={() => ref.current}
                  trigger={() => (

                    <Button className="create-green-btn" >Print</Button>
                  )}
                />

              </div>
              <div className="create-btn">
                <Button variant="contained" onClick={() => downloadExcelsheet()} >Download</Button>
                <Button className="create-red-btn" onClick={() => handleClose()}>Cancel</Button>
              </div>

            </div>
          </Box>
        </Modal>
      </div>
    </div>


  );
};

export default PrintPop;
