import React, { useState } from "react";
import "./FilterPopup.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import Axios from "../../../Axios";

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
  maxHeight: '400px',
  overflow: 'scroll !important'
};

const FilterPopup = ({ filterSeller }) => {
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName()
    setProductId()
    setStart()
    setEnd()
    setWarrStart()
    setDob()
    setWarrEnd()
  }


  const [productId, setProductId] = useState()
  const [name, setName] = useState()
  const [dob, setDob] = useState()
  const [country, setCountry] = useState()
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [warrStart, setWarrStart] = useState()
  const [warrEnd, setWarrEnd] = useState()
  const [semail, setSemail] = useState()
  const [phno, setPhno] = useState()


  const search = async () => {
  
      const adminFilter = window.localStorage.getItem('Squelch')

      var payload = {};
      if (productId) {
        payload.product_id = productId
      }
      if (name) {
        payload.name = name
        console.log(name, 'name');
      }
      if (dob) {
        payload.dob = dob
      }
      if (start) {
        payload.start = start
      }
      if (end) {
        payload.end = end
      }
      if (warrStart) {
        payload.extent_start = warrStart
      }
      if (warrEnd) {
        payload.extent_end = warrEnd
      }
      if (country) {
        payload.country = country
      }if(semail){
        payload.email = semail
      }if(phno){
        payload.phone = phno
      }

      console.log(payload, "payload")

      if(adminFilter == 'admin'){
        try {

          const { data } = await Axios.post(`/admin/getProductUsersFilter`, payload, {
            headers: {
              Authorization: window.localStorage.getItem('AdminToken')
            }
          })
          console.log("🚀 ~ file: FilterPopup.js:97 ~ search ~ data:", data)
          if (data?.success === true) {
            filterSeller(data?.result)
          }
          handleClose()
    
        } catch (error) {
          console.log("🚀 ~ file: FilterPopup.js:71 ~ search ~ error:", error)
        }
      }else{
        try {

      const { data } = await Axios.post(`/admin/customer_filter`, payload, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      console.log("🚀 ~ file: FilterPopup.js:97 ~ search ~ data:", data)
      if (data?.success === true) {
        filterSeller(data?.result)
      }
      handleClose()

    } catch (error) {
      console.log("🚀 ~ file: FilterPopup.js:71 ~ search ~ error:", error)
    }
  }

  }

  return (
    <div >
      <Button onClick={handleOpen} className="filter-btn">
        <SearchIcon />
        &nbsp; Filter
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
          <div className="listOne">
            <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Date Range
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      // defaultValue={yesterday}
                      // disablePast
                      onChange={(e) => {
                        console.log(`${e?.$M + 1}/${e?.$D}/${e?.$y}`, "date")
                        setStart(`${e?.$M + 1}/${e?.$D}/${e?.$y}`)
                      }}
                      views={["year", "month", "day"]}
                    />
                  </DemoItem>

                  <DemoItem>
                    <DatePicker
                      // defaultValue={yesterday}
                      // disablePast
                      onChange={(e) => {
                        console.log(`${e?.$M + 1}/${e?.$D}/${e?.$y}`, "end date")
                        setEnd(`${e?.$M + 1}/${e?.$D}/${e?.$y}`)
                      }}
                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Product ID
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Product ID "
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setProductId(e.target.value) }}
                  value={productId}
                />
              </div>
            </div>
          </div>

          <div className="listTwo">
            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Name
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Name "
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setName(e.target.value) }}
                  value={name}
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Country 
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Country Name "
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setCountry(e.target.value) }}
                  value={country}
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Email 
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Email "
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setSemail(e.target.value) }}
                  value={semail}
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Phone Number 
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Phone Number "
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setPhno(e.target.value) }}
                  value={phno}
                />
              </div>
            </div>

            {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Surname
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Surname"
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div> */}

            <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  DOB
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      // defaultValue={yesterday}
                      // disablePast
                      views={["year", "month", "day"]}
                      onChange={(e) => {

                        var date = e?.$D;
                        var month = e?.$M + 1
                        if (e?.$D?.toString().length === 1) {
                          date = `0${date}`
                        }
                        if (month?.toString().length === 1) {
                          month = `0${month}`
                        }
                        console.log(`${date}/${month}/${e?.$y}`, "end date")
                        setDob(`${date}-${month}-${e?.$y}`)
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="listThree">
            {/* <div className="warn">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Warranty Extension
              </Typography>
              <Checkbox defaultChecked className="checkBox" />
            </div> */}
            <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  End Of Warranty
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      // defaultValue={yesterday}
                      // disablePast
                      views={["year", "month", "day"]}
                      onChange={(e) => {
                        console.log(`${e?.$M + 1}/${e?.$D}/${e?.$y}`, "end date")
                        setWarrStart(`${e?.$M + 1}/${e?.$D}/${e?.$y}`)
                      }}
                    />
                  </DemoItem>

                  <DemoItem>
                    <DatePicker
                      // defaultValue={yesterday}
                      // disablePast
                      views={["year", "month", "day"]}
                      onChange={(e) => {
                        console.log(`${e?.$M + 1}/${e?.$D}/${e?.$y}`, "end date")
                        setWarrEnd(`${e?.$M + 1}/${e?.$D}/${e?.$y}`)
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Country
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Please Enter Country"
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div> */}
          </div>
          <div className="butOutLayer">
            <Button className='apply' onClick={() => { search() }} >Apply</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FilterPopup;
