import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./MypOrders.css";
import Modal from "@mui/material/Modal";
import { Toaster, toast } from "react-hot-toast";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Axios from "../../../Axios";
// import Renewal from './Renewal';
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import togglebtn from "../../../img/togglebtn.png";
import Menuclose from "../../../img/Menuclose.png";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Pagination from "@mui/material/Pagination";
// import CustomerFilter from './CustomerFilter';
// import Deactivte from './Deactivate';
// import Reactivate from './Reactivate';
import adidas from "../../../img/adidas.svg";
import nike from "../../../img/nike.svg";
import demo from "../../../img/demo.svg";
import rightState from "../../../img/rightState.png";
import wrongState from "../../../img/wrongState.png";
// import box from '../../../img/box.svg';
// import AddressPopup from './AddressPopup';
import constant from '../../../constants'

// tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import invoice from "../../../img/user-my-orders/invoice.png";
import Images1 from "../../../img/user-my-orders/pngwing-1.png";
import Images2 from "../../../img/user-my-orders/Group1.png";
import FooterImages from "../../../img/user-my-orders/footer-images.png";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Captura from "../../../img/user-my-orders/Captura.png"
import DownloadIcon from '@mui/icons-material/Download';

import Popa from "./MyorderInvoicesPopup"
import AdminMenuSide from "../../Admin/AdminMenuSide";

const stylepopup = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const stylepopuptwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
const todayStartOfTheDay = today.startOf("day");
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "0px",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  footercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    padding: "50px 0 0 !important",
    "@media (max-width: 991.98px)": {
      padding: "30px 0 !important",
    },
  },
  headercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },

  bodyclass: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    position: "relative",
  },

  reasonBlock: {
    fontSize: "24px",
    fontWeight: "600",
  },
});

function createData(
  brandLogo,
  brandName,
  cusName,
  email,
  status,
  moreInfo,
  renewal
) {
  return { brandLogo, brandName, cusName, email, status, moreInfo, renewal };
}

const rows = [
  createData(
    adidas,
    "ADIDAS",
    "Ismael Santana",
    "i.S@test.com",
    rightState,
    "",
    "10/08/2024"
  ),
  createData(
    nike,
    "NIKE",
    "Ismael Santana",
    "i.S@test.com",
    wrongState,
    "",
    "---------------"
  ),
  createData(
    demo,
    "TE DEMO BRAND",
    "Ismael Santana",
    "i.S@test.com",
    rightState,
    "",
    "10/08/2024"
  ),
];

const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "82%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "none",
  p: 2,
};

const styleReActive = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "3px solid #BFFF00 !important",
  p: 2,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Myorders = ({ row }) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [opentwo, setOpentwo] = React.useState(false);
  const handleOpentwo = () => setOpentwo(true);
  const handleClosetwo = () => setOpentwo(false);

  const openMenuHandler = () => {
    setMenuOpen(false);
  };

  const closeMenuHandler = () => {
    setMenuOpen(true);
  };

  const [users, setUsers] = useState([]);

  const [count, setCount] = useState();
  const [statussss, setstatusss] = useState();

  const [statuscheck, setStatusCheck] = useState([]);
  const [selcid, setSelcid] = useState();

  const defaultStatus = () => {
    var dt = [
      {
        Name: "Brand",
        Read: true,
        Write: false,
      },
      {
        Name: "Products",
        Read: true,
        Write: false,
      },
      {
        Name: "Traceability",
        Read: true,
        Write: false,
      },

      {
        Name: "Blockchain",
        Read: true,
        Write: false,
      },
      {
        Name: "Post-sale",
        Read: true,
        Write: false,
      },
      {
        Name: "Billing",
        Read: true,
        Write: false,
      },
      {
        Name: "Seller Auth",
        Read: true,
        Write: false,
      },
    ];
    setStatusCheck(dt);
  };

  useEffect(() => {
    defaultStatus();
  }, []);

  const getCheckStatus = async (id) => {
    try {
      const payload = {
        _id: id,
      };
      const { data } = await Axios.post(
        "/admin/getSubadminPermission",
        payload,
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success && data?.result?.length > 0) {
        setStatusCheck(data?.result);
      } else {
        defaultStatus();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: SubAdminList.js:266 ~ getCheckStatus ~ error:",
        error
      );
    }
  };

  const navigate = useNavigate();

  const filterData = (data) => {
    // setUsers1(data)
    setCount(Math.ceil(data?.length / 10));
  };
  const reset = (data) => {
    if (data === true) {
      getSellers();
    }
  };

  useEffect(() => {
    getSellers();
  }, []);
  const getSellers = async () => {
    try {
      setstatusss("Loading....");

      const { data } = await Axios.post(
        "/subAdminList",
        {},
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      );

      if (data?.success) {
        if (data?.result?.length > 0) {
          setUsers(data?.result);
          setCount(Math.ceil(data?.result?.length / 10));
        } else {
          setstatusss("No SubAdmin Available");
        }
      } else {
        setstatusss("No SubAdmin Available");
      }
    } catch (error) {
      console.log(error, "err");
      setstatusss("No SubAdmin Available");
    }
  };

  const activateDeactiveBrand = async (datas) => {
    try {
      const { data } = await Axios.post(
        `/subAdminStatus`,
        {
          _id: datas,
        },
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success === true) {
        toast.success(data?.message);
        getSellers();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: SubAdminList.js:239 ~ activateDeactiveBrand ~ error:",
        error
      );

      if (error?.response?.data?.success === false) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleChange = (name, type) => {
    const nextShapes = statuscheck.map((shape) => {
      if (shape.Name === name) {
        // No change
        if (type === "Read") {
          return {
            ...shape,
            Read: !shape.Read,
          };
        } else {
          return {
            ...shape,
            Write: !shape.Write,
          };
        }
      } else {
        // Return a new circle 50px below
        return shape;
      }
    });
    // Re-render with the new array
    setStatusCheck(nextShapes);
  };

  const editStatus = async () => {
    try {
      const payload = {
        _id: selcid,
        permission: statuscheck,
      };


      const { data } = await Axios.post("/admin/subAdminPermission", payload, {
        headers: {
          Authorization: window.localStorage.getItem("AdminToken"),
        },
      });
      if (data?.success) {
        toast.success(data?.message);
        handleClose();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: SubAdminList.js:367 ~ editStatus ~ error:",
        error
      );
    }
  };
  //   tables1

  function createData(
    date,
    orderid,
    description,
    ProductID,
    image,
    price,
    invoice
  ) {
    return { date, orderid, description, ProductID, image, price, invoice };
  }
  const rows = [
    createData(
      "10/04/23",
      "1001",
      "warranty extension",
      "ADD-12244-11",
      "",
      "$60",
      ""
    ),
    createData(
      "10/04/23",
      "1002",
      "change of ownership",
      "ADD-12244-11",
      "",
      "$60",
      ""
    ),
    createData(
      "10/04/23",
      "1003",
      "generation of certificate of authenticity",
      "ADD-12244-11",
      "",
      "$80",
      ""
    ),
  ];

  //   tables2
  function createDatatwo(
    datetwo,
    transactionid,
    descriptiontwo,
    ProductIDtwo,
    imagetwo,
    Registration
  ) {
    return {
      datetwo,
      transactionid,
      descriptiontwo,
      ProductIDtwo,
      imagetwo,
      Registration,
    };
  }
  const rowstwo = [
    createDatatwo(
      "10/04/23",
      "#435621",
      "warranty extension",
      "ADD-12244-11",
      "",
      ""
    ),
    createDatatwo(
      "10/04/23",
      "#435621",
      "change of ownership",
      "ADD-12244-11",
      "",
      ""
    ),
    createDatatwo(
      "10/04/23",
      "#435621",
      "generation of certificate of authenticity",
      "ADD-12244-11",
      "",
      ""
    ),
  ];

  const [valuetab1, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    if (newValue === 0) {
      myOrders()
    } else {
      myTranscations()
    }
    setValue(newValue);
  };

  const [openinvoice, setOpeninvoice] = useState(false);
  const handleOpeninvoice = () => setOpeninvoice(true);
  const handleCloseinvoice = () => setOpeninvoice(false);

  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [item, setItem] = useState(5)
  const [totalcount, setTotalcount] = useState()

  const [page1, setPage1] = useState(1)
  const [item1, setItem1] = useState(5)
  const [totalcount1, setTotalcount1] = useState()

  const myOrders = async () => {
    try {
      const { data } = await Axios.post("users/userorder", {
        page: page1,
        item: item1
      }, {
        headers: {
          Authorization: window.localStorage.getItem("AdminToken"),
        },
      });
      if (data?.success) {
        setOrders(data.result)
        setTotalcount1(Math.ceil(data?.count / item1))
      } else {
        setOrders([])
      }

    } catch (error) {
      console.log('error', error);
    }
  }

  const [newData, setNewdata] = useState([])
  const myTranscations = async () => {
    try {
      const { data } = await Axios.post("users/blockchainhistories", {
        page: page,
        item: item
      }, {
        headers: {
          Authorization: window.localStorage.getItem("AdminToken"),
        },
      });
      if (data?.success) {
        setNewdata(data?.result)
        setTotalcount(Math.ceil(data?.count / item))
      } else {
        setNewdata([])
      }

    } catch (error) {
    }
  }

  useEffect(() => {
    myOrders()
    // myTranscations()
  }, [])


  useEffect(() => {
    myTranscations()
  }, [page])

  useEffect(() => {
    myOrders()
  }, [page1])

  const handleChange1 = (event, value) => {
    // alert(value)
    setPage(value);
  };

  const handleChangePagination = (event, value) => {
    // alert(value)
    setPage1(value);
  };

  return (
    <div className="Admin customer address list-full-cnt dashboard-page Admin-customer-page">
      <Toaster position="top-center" reverseOrder={false} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className="admin-customer-contain">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}>
                <img src={togglebtn} alt="togglebtn" />
              </div>
              <div
                className="categories-menu2"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div className="togglebtn-close" onClick={closeMenuHandler}>
                  <img src={Menuclose} alt="Menuclose" />
                </div>
                <AdminMenuSide />
              </div>
            </Item>
          </Grid>

          <Box sx={{ flexGrow: 1 }} onClick={closeMenuHandler}>
            <Grid
              container
              spacing={0}
              className={classes.containwidth}
              id="admin-customer-body-block"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.headercls}>
                  <div className="listbody">
                    <div className="filter-btn">
                      <div className="filter-filed">
                        <div className="filter-box">
                          {/* <Button variant="contained">{<SearchIcon />}Filter</Button> */}
                          {/* <CustomerFilter filterData={filterData} reset={reset} /> */}
                          {/* filterData={filterData} reset={reset} */}
                        </div>
                      </div>
                    </div>
                    <div className="myorders-tabs-main">
                      {" "}
                      <Box sx={{ width: "100%" }}>
                        <Box>
                          <Tabs
                            value={valuetab1}
                            onChange={handleChangeTab}
                            aria-label="basic tabs example"
                          >
                            <Tab label="My Order" {...a11yProps(0)} />
                            <Tab label="My Transaction" {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={valuetab1} index={0}>
                          <div className="tablebody admin-customer-table-main">
                            <div className="dashboargraph-inner-top">
                              <Item
                                className={classes.dashboargraph}
                                id="earnings-sales"
                              >
                                <TableContainer component={Paper}>
                                  <Table aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>date</TableCell>
                                        <TableCell>order id</TableCell>
                                        <TableCell className="description-table">
                                          description
                                        </TableCell>
                                        <TableCell>Product ID</TableCell>
                                        <TableCell>image</TableCell>
                                        <TableCell>price</TableCell>
                                        <TableCell>invoice</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {orders.map((row, index) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              { border: 0 },
                                          }}
                                        >
                                          <TableCell scope="row">
                                            {row?.createdAt ? row?.createdAt.split('T')[0] : '--'}
                                          </TableCell>
                                          <TableCell>{row?.order_id}</TableCell>
                                          <TableCell>
                                            {row?.service}
                                          </TableCell>
                                          <TableCell>{row?.product_id}</TableCell>
                                          {/* <TableCell onClick={handleOpentwo} > */}
                                          <TableCell >

                                            {row.image}
                                            <div className="transimages">
                                              <img src={row?.prodDetails?.product_details[0]?.main_image ? row?.prodDetails?.product_details[0]?.main_image : Images1} alt="image" />

                                            </div>
                                          </TableCell>
                                          <TableCell>${row?.amount}</TableCell>
                                          <TableCell >
                                            {row?.invoice}  <Popa row={row} />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Item>
                            </div>
                            <div className="pagination-numb">
                              {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                              <Pagination count={totalcount} page={page} onChange={handleChangePagination} color="primary" shape="rounded" />
                            </div>
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={valuetab1} index={1}>
                          <div className="tablebody admin-customer-table-main">
                            <div className="dashboargraph-inner-top">
                              <Item
                                className={classes.dashboargraph}
                                id="earnings-sales"
                              >
                                <TableContainer component={Paper}>
                                  <Table aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>date</TableCell>
                                        <TableCell>Transaction Hash</TableCell>
                                        <TableCell className="description-table">
                                          service
                                        </TableCell>
                                        <TableCell>Product ID</TableCell>
                                        <TableCell>image</TableCell>
                                        {/* <TableCell>invoice</TableCell> */}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {newData.map((row, index) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              { border: 0 },
                                          }}
                                        >
                                          <TableCell scope="row">
                                            {row.createdAt.split('T')[0]}
                                          </TableCell>
                                          <TableCell>
                                            <div> <a href={`${constant.polygon_url}/${row.TransactionHash}`} target="_blank">{row.TransactionHash}</a></div>

                                          </TableCell>
                                          <TableCell>
                                            {row.Type}
                                          </TableCell>
                                          <TableCell>
                                            {row.product_id}
                                          </TableCell>
                                          {/* <TableCell onClick={handleOpen}> */}
                                          <TableCell>
                                            {row.imagetwo}
                                            <div className="transimages" >
                                              <img src={row?.prodDetails?.product_details[0]?.main_image ? row?.prodDetails?.product_details[0]?.main_image : Images1} alt="image" />
                                            </div>
                                          </TableCell>
                                          {/* <TableCell className="table-image">
                                            {row.Registration}
                                            <img src={Images2} alt="image" />
                                          </TableCell> */}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Item>
                            </div>
                            <div className="pagination-numb">
                              {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                              <Pagination count={totalcount} page={page} onChange={handleChange1} color="primary" shape="rounded" />
                            </div>
                          </div>
                        </CustomTabPanel>
                      </Box>
                    </div>
                  </div>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={stylepopup} className="Choose-blockchain-box-main">
                <div className="Choose-blockchain-title">
                  <div className="Choose-blockchain-icon">
                    <CloseIcon onClick={handleClose} />
                  </div>
                </div>
                <div className="transaction-hash-text-main">
                  <Typography variant="h5" component="h5">
                    transaction hash :
                  </Typography>
                  <Typography variant="h5" component="h5">
                    0x5fhheij5JRE6dvjhbrfb..
                  </Typography>
                </div>
                <div className="code-text"></div>
                <div className="transaction-hash-text-main">
                  <Typography variant="h5" component="h5">
                    status :
                  </Typography>
                  <Typography variant="h5" component="h5">
                    SUCEESS <CheckCircleIcon />
                  </Typography>
                </div>
                <div className="code-text"></div>
                <div className="transaction-hash-text-main">
                  <Typography variant="h5" component="h5"></Typography>
                  <Typography variant="h5" component="h5">
                    43592302
                  </Typography>
                </div>
                <div className="code-text"></div>
                <div className="transaction-hash-text-main">
                  <div>
                    <Typography variant="h5" component="h5">
                    </Typography>
                    <Typography variant="p" component="p">
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="h5" component="h5">
                      27 sec ago
                    </Typography>
                    <Typography variant="p" component="p">
                      (jun-06-2023 12:18:31 Pm +UTC)
                    </Typography>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>


          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={opentwo}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={opentwo}>
              <Box sx={stylepopuptwo} className="Choose-blockchain-box-main">
                <div className="Choose-blockchain-title">
                  <div className="Choose-blockchain-icon">
                    <CloseIcon onClick={handleClosetwo} />
                  </div>
                </div>
                <div className="Captura-images">
                  <img src={Captura} alt="image" />
                  <div className="export-btn">
                    <Button variant="" className="button-cls" >
                      <DownloadIcon />download</Button>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>



          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="footer-images-my-orders">
              {/* <img src={FooterImages} alt="image" /> */}
              <Footer />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Myorders;
