import React, { useState, useEffect } from 'react'
import Axios from '../../../Axios';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DashboardLineChart from './DashboardLineChart';
import XLSX from 'sheetjs-style'
import Files from '../../../img/files.svg';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MonthlyChart from './MonthlyChart'
import BrandFilterPopup from '../BrandPostSales/BrandFilterPopup';
import BrandProductsBody from '../BrandPostSales/BrandProductsBody';
import Pagination from '@mui/material/Pagination';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});


const BrandPostSalesBody = () => {

  const classes = useStyles();
  const [priceData, setpriceData] = useState([])
  const [lastpriceData, setlastpriceData] = useState([])
  const [this_Week, setThisWeek] = useState([])
  const [last_Week, setLastWeek] = useState([])
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const handleChange1 = (event, value) => {
    setPage(value);
  };
  const [isFilter, setIisfilter] = useState(false)
  const [product, setProduct] = useState([])

  const [orderId, setorderId] = useState()
  const [product_id, setproduct_id] = useState()
  const [brand_id, setbrand_id] = useState()
  const [ean, setean] = useState()
  const [paid, setpaid] = useState(false)
  const [unpaid, setunpaid] = useState(false)
  const [refund, setrefund] = useState(false)
  const [stripe, setstripe] = useState(false)
  const [bank, setbank] = useState(false)
  const [creationDate, setCreationDate] = useState([])
  const [creation1Date, setCreation1Date] = useState([])
  const [endDate, setEndDate] = useState([])
  const [end1Date, setEnd1Date] = useState([])
  const [paymentDate, setPaymentDate] = useState([])
  const [endpaymentDate, setendPaymentDate] = useState([])

  useEffect(() => {
    getGraphs()
    products()
    // graphdata()
  }, [])
  useEffect(() => {

    if (isFilter === false) {
      products()
      getGraphs()
    }
    else {
      productfilter()
      getfilterGraphs()
    }
  }, [page, isFilter])

  const export_post_sale = async () => {
    try {
      let tableData = []
      for (let i = 0; i < product.length; i++) {
        // const element = product[i];
        var dataobjects = {
          service: product[i].service,
          product_id: product[i].product_id,
          image: product[i]?.details?.product_details[0]?.main_image,
          price: product[i]?.amount,
          dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
          brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
          // billing: product[i]?.billing,
          payment_processing_fee: product[i]?.payment_processing_fee ? product[i]?.payment_processing_fee : 0,
          positive_balance: product[i]?.positive_balance ? product[i]?.positive_balance : 0,
          status: product[i]?.details?.product_details[0]?.status,
        }
        tableData.push(dataobjects)
      }
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      XLSX.writeFile(workbook, 'PostSales.csv');
    } catch (error) {
      console.log("🚀 ~ constexport_post_sale= ~ error:", error)

    }

  }

  const getGraphs = async () => {
    try {
      const { data } = await Axios.post(`/admin/getsale_graph`, '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {

          var graphDetails = []
          var priceDetail = []
          var lastpriceDetail = []
          var weekpriceDetail = []
          var lastweekpriceDetail = []

          for (var i = 0; i < data?.result?.month.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.price[i] === undefined || data?.result?.price[i] === null || data?.result?.price[i] === "null") {
              priceDetail.push(0)
            }
            else {
              priceDetail.push((data?.result?.price[i]).toFixed(2))
            }
            if (data?.result?.lastprice[i] === undefined || data?.result?.lastprice[i] === null || data?.result?.lastprice[i] === "null") {
              lastpriceDetail.push(0)
            }
            else {
              lastpriceDetail.push((data?.result?.lastprice[i]).toFixed(2))
            }
          }
          setpriceData(priceDetail)
          setlastpriceData(lastpriceDetail)
          for (var i = 0; i < data?.result?.week.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.weekprice[i] === undefined || data?.result?.weekprice[i] === null || data?.result?.weekprice[i] === "null") {
              weekpriceDetail.push(0)
            }
            else {
              weekpriceDetail.push((data?.result?.weekprice[i]).toFixed(2))
            }
            if (data?.result?.lastweekprice[i] === undefined || data?.result?.lastweekprice[i] === null || data?.result?.lastweekprice[i] === "null") {
              lastweekpriceDetail.push(0)
            }
            else {
              lastweekpriceDetail.push((data?.result?.lastweekprice[i]).toFixed(2))
            }
          }
          setThisWeek(weekpriceDetail)
          setLastWeek(lastweekpriceDetail)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }

  const products = async () => {
    try {
      const { data } = await Axios.post(`/admin/getPostSale`, {
        page: page - 1,
        limit: 10
      }, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      if (data?.result?.length > 0) {
        setProduct(data?.result)
        setCount(Math.ceil(data?.count / 10))
      }
    } catch (error) {
      console.log("AdminProductsBody:", error)
    }

  }

  const productfilter = async () => {
    try {
      setProduct([])
      setIisfilter(true)
      // getfilterGraphs()
      const { data } = await Axios.post(`/admin/getPostSale`, {
        order_id: orderId,
        product_id: product_id,
        brand_id: brand_id,
        ean: ean,
        paid: paid,
        unpaid: unpaid,
        refund: refund,
        bank: bank,
        stripe: stripe,
        creation_date: creationDate,
        creation1_date: creation1Date,
        end_date: endDate,
        end1_date: end1Date,
        payend_date: endpaymentDate,
        pay_date: paymentDate,
        page: page - 1,
        limit: 10
      }, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })

      if (data?.result?.length > 0) {
        setCount(Math.ceil(data?.count / 10))
        setProduct(data?.result)
      }
    } catch (error) {
      console.log("AdminProductsBody:", error)
    }

  }

  // const graphdata = async () => {
  //   try {
  //     const { data } = await Axios.post(`/admin/brandDashboardCount`, {}, {
  //       headers: { Authorization: window.localStorage.getItem("AdminToken") }
  //     })
  //     console.log("🚀 ~ file: DashboardBody.js:180 ~ graphdata ~ data:", data)
  //     if (data?.success == true) {

  //       var arr = []
  //       var temp = []
  //       var temp1 = []
  //       var date = []
  //       for (let i = 0; i < data?.result.length; i++) {
  //         const element = data?.result[i];
  //         temp.push(element?.transactionCount)
  //         date.push(element?._id)
  //       }
  //       arr = [
  //         {
  //           name: "Last Week",
  //           data: temp1
  //         },
  //         {
  //           name: 'This Week',
  //           data: temp
  //         }
  //       ]
  //       setThisWeek(arr)
  //       setDates(date)
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ file: DashboardBody.js:180 ~ graphdata ~ error:", error)

  //   }
  // }

  const handlecreationdatechange = (d, m, y) => {

    var credate = y + '-' + m + '-' + d

    setCreationDate(credate)
    var date = {}
    if ((creation1Date !== undefined) && (creation1Date.length !== 0)) {

      date.creation_date = credate
      date.creation1_date = creation1Date
      filterbyDate(date)
    }

  }

  const handlecreation1datechange = (d, m, y) => {

    var endDates = y + '-' + m + '-' + d
    var date = {}
    setCreation1Date(endDates)
    if ((creationDate !== undefined) && (creationDate.length !== 0)) {
      date.creation_date = creationDate
      date.creation1_date = endDates
      filterbyDate(date)
    }

  }

  const getfilterGraphs = async () => {
    try {


      const { data } = await Axios.post(`/admin/getsale_graph`, {
        order_id: orderId,
        product_id: product_id,
        brand_id: brand_id,
        ean: ean,
        paid: paid,
        unpaid: unpaid,
        refund: refund,
        bank: bank,
        stripe: stripe,
        creation_date: creationDate,
        creation1_date: creation1Date,
        end_date: endDate,
        end1_date: end1Date,
        payend_date: endpaymentDate,
        pay_date: paymentDate,
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {

          var graphDetails = []
          var priceDetail = []
          var lastpriceDetail = []
          var weekpriceDetail = []
          var lastweekpriceDetail = []

          for (var i = 0; i < data?.result?.month.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.price[i] === undefined || data?.result?.price[i] === null || data?.result?.price[i] === "null") {
              priceDetail.push(0)
            }
            else {
              priceDetail.push(data?.result?.price[i])
            }
            if (data?.result?.lastprice[i] === undefined || data?.result?.lastprice[i] === null || data?.result?.lastprice[i] === "null") {
              lastpriceDetail.push(0)
            }
            else {
              lastpriceDetail.push(data?.result?.lastprice[i])
            }
          }
          setpriceData(priceDetail)
          setlastpriceData(lastpriceDetail)
          for (var i = 0; i < data?.result?.week.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.weekprice[i] === undefined || data?.result?.weekprice[i] === null || data?.result?.weekprice[i] === "null") {
              weekpriceDetail.push(0)
            }
            else {
              weekpriceDetail.push(data?.result?.weekprice[i])
            }
            if (data?.result?.lastweekprice[i] === undefined || data?.result?.lastweekprice[i] === null || data?.result?.lastweekprice[i] === "null") {
              lastweekpriceDetail.push(0)
            }
            else {
              lastweekpriceDetail.push(data?.result?.lastweekprice[i])
            }
          }
          setThisWeek(weekpriceDetail)
          setLastWeek(lastweekpriceDetail)
        }
        else {
          setpriceData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
          setlastpriceData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
          setThisWeek([0, 0, 0, 0, 0, 0, 0])
          setLastWeek([0, 0, 0, 0, 0, 0, 0])
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }

  async function filterbyDate(date) {
    try {
      setProduct([])

      // getfilterGraphs()
      const { data } = await Axios.post(`/admin/getPostSale`, date, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })

      if (data?.result?.length > 0) {
        setCount(Math.ceil(data?.count / 10))
        setProduct(data?.result)
      }
    } catch (error) {
      console.log("AdminProductsBody:", error)
    }

  }
  const resetFilters = () => {
    setorderId()
    setproduct_id()
    setbrand_id()
    setpaid(false)
    setunpaid(false)
    setrefund(false)
    setstripe(false)
    setbank(false)
    setCreationDate([])
    setEndDate([])
    setCreation1Date([])
    setEnd1Date([])
    setPaymentDate([])
    setendPaymentDate([])

    products()
    getGraphs()
  };

  return (
    <div className='AdminPostSalesBody'>

      <Box sx={{ flexGrow: 1 }}>

        <Grid container spacing={0} className={classes.containwidth}>


          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className='filter-btn'>
              <div className='filter-filed'>
                <div className='filter-box'>
                  <BrandFilterPopup orderId={orderId} setorderId={setorderId} product_id={product_id} setproduct_id={setproduct_id} isFilter={isFilter} setIisfilter={setIisfilter}
                    brand_id={brand_id} setbrand_id={setbrand_id} ean={ean} setean={setean} paid={paid} setpaid={setpaid} unpaid={unpaid} setunpaid={setunpaid} refund={refund} setrefund={setrefund}
                    products={products} getGraphs={getGraphs} stripe={stripe} setstripe={setstripe} bank={bank} setbank={setbank}
                    creationDate={creationDate} setCreationDate={setCreationDate}
                    endDate={endDate} setEndDate={setEndDate}
                    creation1Date={creation1Date} setCreation1Date={setCreation1Date}
                    end1Date={end1Date} setEnd1Date={setEnd1Date}
                    paymentDate={paymentDate} setPaymentDate={setPaymentDate}
                    endpaymentDate={endpaymentDate} setendPaymentDate={setendPaymentDate}
                    productfilter={productfilter} />
                  <Button className='reset' onClick={() => { resetFilters() }}>Reset filter</Button>
                  {/* <BrandSettingPopup /> */}
                </div>
              </div>

            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className='start-end-date'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >
                  <DemoItem label="Start Date">
                    <DatePicker
                      onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                  <DemoItem label="End Date">
                    <DatePicker
                      onChange={(e) => { handlecreation1datechange(e.$D, e.$M + 1, e.$y) }}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div>

          </Grid>


        </Grid>

        <Button variant="contained" onClick={() => { export_post_sale() }} >
          Export<img src={Files} alt={Files} />
        </Button>

        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.dashboargraph} id="dashboargraph">

              <BrandProductsBody product={product} orderId={orderId} />

            </Item>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className='belowBtn'>

              <div className='download-keys'>
                <div className='save'><img src={dollarClick} alt={dollarClick} /></div>
                <div className='print'><img src={actionCross} alt={actionCross} /></div>
                <div className='print'><img src={actionupload} alt={actionupload} /></div>
              </div>

              <div className='download-keys right'>
                <div className='color-green-color icon'><img src={paid} alt={paid} />PAID</div>
                <div className='print icon'><img src={pendPaid} alt={pendPaid} />PENDING PAID</div>
                <div className='color-red-color icon'><img src={CancelIcon} alt={CancelIcon} />CANCEL</div>
              </div>

            </div>
          </Grid> */}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {product.length > 0 ? <div className='pagination-numb'>
              <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" />
            </div> : <></>}
          </Grid>

        </Grid>

        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Item className={classes.dashboargraph} id="dashboargraph">

              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>

                </div>


                <div className='dashboargraph-inner-mid'>
                  <MonthlyChart lastpriceData={lastpriceData} priceData={priceData} />
                </div>

                <div className='dashboargraph-inner-bottom'>

                </div>

              </div>


            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.rightpartstyle}>
            <Item className={classes.dashboargraph} id="dashboargraph">


              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>

                </div>


                <div className='dashboargraph-inner-mid'>
                  {/* <YearlyChart /> */}
                  <DashboardLineChart this_Week={this_Week} last_Week={last_Week} />
                </div>

              </div>


            </Item>
          </Grid>

        </Grid>


      </Box>
    </div>
  )
}

export default BrandPostSalesBody
