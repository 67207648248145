import React, { useEffect, useState } from "react";
import "./AdminCustomer.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import adidasLogo from '../../../img/adidasLogo.png';
import info from '../../../img/info.svg';
import { useRef } from 'react';
import Axios from '../../../Axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Toaster, toast } from 'react-hot-toast'
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MenuItem from '@mui/material/MenuItem';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete"




//Add info

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
]

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));




const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});

const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  width: '85%',
  border: "none",
  p: 2,
};

const AddressPopup = ({ row, role, decrptedData }) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState()
  const handleOpen = (rows) => { setOpen(true); setRows(rows) }
  const handleClose = () => setOpen(false);

  const [openEditInfo, setOpenEditInfo] = useState(false);
  const handleOpenEditInfo = () => setOpenEditInfo(true);
  const handleCloseEditInfo = () => setOpenEditInfo(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  // user info
  const type = useRef(null)
  const type1 = useRef(null)
  const name = useRef(null);
  const email = useRef(null);
  const web = useRef(null);
  const phone = useRef(null);
  const address = useRef(null);
  const city = useRef(null);
  const state = useRef(null);
  const country = useRef(null);
  const pincode = useRef(null);
  const contactname = useRef(null);
  const contactphone = useRef(null);
  const contactmail = useRef(null);
  const taxid = useRef(null);
  const taxname = useRef(null);
  const addressbilling = useRef(null);
  const statusbilling = useRef(null);
  const countrybilling = useRef(null);
  const bankno = useRef(null);
  const bankname = useRef(null);
  const number = useRef(null);
  const swiff = useRef(null);
  const info1 = useRef(null);

  const licenceno = useRef(null)
  const price = useRef(null)
  const totalprice = useRef(null)
  const discount = useRef(null)
  const ondate = useRef(null)
  const expiredate = useRef(null)
  const redate = useRef(null)
  const information = useRef(null)

  const [nameerr, setnameerr] = useState(null)
  const [emailerr, setemailerr] = useState(null)
  const [weberr, setweberr] = useState(null)
  const [phoneerr, setphoneerr] = useState(null)
  const [addresserr, setaddresserr] = useState(null)
  const [cityerr, setcityerr] = useState(null)
  const [stateerr, setstateerr] = useState(null)
  const [countryerr, setcountryerr] = useState(null)
  const [pincodeerr, setpincodeerr] = useState(null)
  const [contactphoneerr, setcontactphoneerr] = useState(null)
  const [contactemailerr, setcontactemailerr] = useState(null)
  const [contactnameerr, setcontactnameerr] = useState(null)

  const [taxiderr, settaxiderr] = useState(null)
  const [taxnameerr, settaxnameerr] = useState(null)
  const [abillingerr, setabillingerr] = useState(null)
  const [sbillingerr, setsbillingerr] = useState(null)
  const [cbillingerr, setcbillingerr] = useState(null)

  const [banknoerr, setbanknoerr] = useState(null)
  const [banknameerr, setbanknameerr] = useState(null)
  const [numbererr, setnumbererr] = useState(null)
  const [swifferr, setswifferr] = useState(null)
  const [infoerr, setinfoerr] = useState(null)

  const [licencenoerr, setlicencenoerr] = useState(null)
  const [priceerr, setpriceerr] = useState(null)
  const [totalpriceerr, settotalpriceerr] = useState(null)
  const [taxerr, settaxerr] = useState(null)
  const [discounterr, setdiscounterr] = useState(null)
  const [ondateerr, setondateerr] = useState(null)
  const [expiredateerr, setexpiredateerr] = useState(null)
  const [redateerr, setredateerr] = useState(null)
  const [informationerr, setinformationerr] = useState(null)
  const [logo, setlogo] = useState(null)
  const [thumb, setthumb] = useState(null)
  const [logoerr, setlogoerr] = useState(null)

  const [value, setValue] = React.useState('freelancer');
  const [click, setClick] = useState(false);

  const [emailstas, setemailstas] = useState([])

  const [userInfo, setUserInfo] = useState({})
  const [pay, setPay] = useState({})
  const [billingInfo, setBillingInfo] = useState({})
  const [licenceInfo, setLicenceInfo] = useState({})
  const [financeInfo, setFinanceInfo] = useState({})
  const [address1, setAddress1] = useState(row?.user_info?.address);
  const [valiaddre, setValiaddre] = useState(null)
  const [address2, setAddress2] = useState(row?.bill_info?.address_billing);
  const [valiaddre1, setValiaddre1] = useState(null)




  useEffect(() => {
    if (rows) {
      const count = rows?.licence_info?.information?.split(',')
      if (count > 0) {
        var dt = []
        for (let i = 0; i < count.length; i++) {
          const element = count[i];
          dt.push(element)
        }
        setemailstas(dt)
      }

    }
  }, [rows])

  useEffect(() => {
    if (rows) {
      setlogo(rows?.user_info?.logo)
      setUserInfo(rows?.user_info)
      setBillingInfo(rows?.bill_info)
      setLicenceInfo(rows?.licence_info)
      setFinanceInfo(rows?.financial_info)
      setPay(rows?.payment_method)
    }
  }, [rows])

  const createLogo = async (e) => {
    try {
      let files = await e.target.files[0]
      let formData = await new FormData();
      formData.append("image", files)

      const { data } = await Axios.post('/users/uploadlogo', formData)

      if (data.result) {
        setlogo(data.result)
        setlogoerr(null)
        // navigate(`${consts.route}/register-success`)
        toast.success('Image uploaded Successfully')

      }

    } catch (error) {
      console.log(error, "err")
    }
  }

  // const totalLicencePrice = () => {

  // }
  const changePrice = (data, discount, tax) => {
    if (data && licenceInfo.price) {
      var total = data * licenceInfo.price
      var subtotal
      if (Number(discount) > 0) {
        var subtot = (Number(discount) / 100) * Number(total)
        subtotal = total - subtot
        setLicenceInfo({ ...licenceInfo, total_price: subtotal, licenceno: data, discount: discount, tax: tax })
      }
      if (Number(subtotal) > 0 && Number(tax) > 0) {
        var taxs = (Number(tax) / 100) * Number(subtotal)
        setLicenceInfo({ ...licenceInfo, total_price: taxs + Number(subtotal), licenceno: data, discount: discount, tax: tax })
      }
      if (discount === undefined && tax === undefined) {
        setLicenceInfo({ ...licenceInfo, total_price: Math.floor(total), licenceno: data, discount: discount, tax: tax })
      }
    }
  }

  const handleSuccessRegister = async () => {
    try {
      console.log(pay)
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let reg = /^[0-9]+$/;
      if (userInfo.name === "") {
        setnameerr("Please Enter First Name")
      }
      else if (userInfo.email === "") {
        setemailerr("Please Enter Email")
      }
      else if (!(re.test(userInfo.email))) {
        setemailerr("Please Enter valid Email")
      } else if (userInfo.web === "") {
        setweberr("Please Enter Web")
      }
      else if (address1 === "") {
        setaddresserr("Please Enter Address")
      }
      // else if (userInfo.address === "") {
      //   setaddresserr("Please Enter Address")
      // }
      // else if (userInfo.city === "") {
      //   setcityerr("Please Enter City")
      // }
      // else if (userInfo.state === "") {
      //   setstateerr("Please Enter State")
      // }
      // else if (userInfo.country === "") {
      //   setcountryerr("Please Enter Country")
      // } 
      else if (userInfo.pin_code === "") {
        setpincodeerr("Please Enter pin Code")
      }
      else if (userInfo.phone === "") {
        setphoneerr("Please Enter Phone Number")
      } else if (userInfo.contact_phone === "") {
        setcontactphoneerr("Please Enter Contact Phone Number")
      } else if (userInfo.contact_mail === "") {
        setcontactemailerr("Please Enter Email")
      } else if (!(re.test(userInfo.contact_mail))) {
        setcontactemailerr("Please Enter valid Email")
      } else if (billingInfo.tax_id === "") {
        settaxiderr("Please Enter Tax Id")
      }
      // else if (!(reg.test(billingInfo.tax_id))) {
      //   settaxiderr("Please Valid number")
      // }
      else if (billingInfo.tax_name === "") {
        settaxnameerr("Please Enter Tax Name")
      }
      else if (address2 === "") {
        setabillingerr("Please Enter Address Billing")
      }
      else if (billingInfo.status_billing === "") {
        setsbillingerr("Please Enter State Billing")
      }
      // else if (billingInfo.country_billing === "") {
      //   setcbillingerr("Please Enter Country Billing")
      // }
      else if (financeInfo.bankno === "") {
        setbanknoerr("Please Enter Bank Number")
      }
      // else if (!(reg.test(financeInfo.bankno))) {
      //   setbanknoerr("Please Valid number")
      // }
      else if (financeInfo.bankname === "") {
        setbanknameerr("Please Enter Bank Name")
      }
      else if (financeInfo.number === "") {
        setnumbererr("Please Enter Route Number")
      }
      else if (!(reg.test(financeInfo.number))) {
        setnumbererr("Please Valid number")
      }
      else if (financeInfo.swiff === "") {
        setswifferr("Please Enter Swiff")
      }
      else if (financeInfo.info === "") {
        setinfoerr("Please Enter Info")
      }
      else if (licenceInfo.licenceno === "") {
        setlicencenoerr("Please Enter Licence Number")
      } else if (!(reg.test(licenceInfo.licenceno))) {
        setlicencenoerr("Please Valid number")
      }

      else if (licenceInfo.price === "") {
        setpriceerr("Please Enter Price")
      }
      else if (!(reg.test(licenceInfo.price))) {
        setpriceerr("Please Valid number")
      }
      else if (licenceInfo.total_price === "") {
        settotalpriceerr("Please Enter Price")
      }
      else if (!(reg.test(licenceInfo.total_price))) {
        settotalpriceerr("Please Valid number")
      }
      else if (licenceInfo.discount === "") {
        setdiscounterr("Please Enter Discount")
      }
      else if (!(reg.test(licenceInfo.discount))) {
        setdiscounterr("Please Valid number")
      }
      else if (licenceInfo.tax === "") {
        settaxerr("Please Enter Tax")
      }
      else if (!(reg.test(licenceInfo.tax))) {
        settaxerr("Please Valid number")
      }
      else if (licenceInfo.ondate === "") {
        setondateerr("Please Enter ondate")
      }
      else if (licenceInfo.expiredate === "") {
        setexpiredateerr("Please Enter Expire Date")
      }
      else if (licenceInfo.redate === "") {
        setredateerr("Please Enter Remember Date")
      }
      // else if (licenceInfo.information === "") {
      //   setinformationerr("Please Enter Info")
      // }
      else if (logo === null) {
        setlogoerr("Please Select Logo")
      }

      else {
        let newad = address1.split(",")
        let city = newad.length - 1
        const { data } = await Axios.post('/admin/update_brand',
          {
            _id: rows?._id,
            name: userInfo.name,
            email: userInfo.email,
            web: userInfo.web,
            address: address1,
            city: "",
            state: "",
            country: newad[city],
            pin_code: userInfo.pin_code,
            phone: userInfo.phone,
            contact_mail: userInfo.contact_mail,
            contact_phone: userInfo.contact_phone,
            contact_name: userInfo.contact_name,
            logo: logo,
            tax_id: billingInfo.tax_id,
            tax_name: billingInfo.tax_name,
            address_billing: address2,
            status_billing: billingInfo.status_billing,
            country_billing: "",
            bankno: financeInfo.bankno,
            bankname: financeInfo.bankname,
            number: financeInfo.number,
            swiff: financeInfo.swiff,
            info: financeInfo.info,
            type: value,
            licenceno: licenceInfo.licenceno,
            price: licenceInfo.price,
            total_price: licenceInfo.total_price,
            discount: licenceInfo.discount,
            ondate: licenceInfo.ondate,
            expiredate: licenceInfo.expiredate,
            redate: licenceInfo.redate,
            information: licenceInfo.information,
            tax: licenceInfo.tax,
            payment_method: pay,
          },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )

        if (data.success) {
          // navigate(`${consts.route}/register-success`)
          toast.success('Updated SucessFully')
          setTimeout(() => {
            window.location.reload()
            setClick(false)
          }, 2000);


        }
        else {
          toast.error(data.message)
        }
      }
    } catch (error) {
      console.log(error, "err")
      if (error?.response?.data?.message === "Email Already Exists") {
        setemailerr(error?.response?.data?.message)
      } else {
        toast.error(error?.response?.data?.message)
      }
    }
  }


  const nft = async (e) => {
    try {
      console.log(e, "cvijay");
      const { name, value } = e.target;
      setUserInfo((prevUser) => ({
        ...prevUser,
        ["pin_code"]: value,
      }));
      setpincodeerr(null)
      const add = await Axios.post('/validateaddress', {
        address: address1

      })
      if (add?.data?.success === true) {
        setValiaddre("Address Verified")
        let fromadd = add?.data?.result?.address.split(",")
        let postad = fromadd[2]
        let postadd = postad.split(" ")
        // if (fromadd[1] != undefined) {
        //   city.current.value = fromadd[1]
        // }
        // if (fromadd[3] != undefined) {
        //   country.current.value = fromadd[3]
        // }
        // if (postadd[1] != undefined) {
        //   state.current.value = postadd[1]
        // }
        // if (postadd[2] != undefined) {
        //   postalcode.current.value = postadd[2]
        // }
      } else {
        console.log("failed");
        setValiaddre("Address Not Verified")
      }


    } catch (error) {
      console.log(error, "error");
    }
  }
  const handleChangeAddress = (newAddress) => {
    setAddress1(newAddress);
    setValiaddre("")
  };
  const handleSelectAddress = (newAddress) => {
    setUserInfo((prevUser) => ({
      ...prevUser,
      ["pin_code"]: "",
    }));
    // let ad = newAddress.split(",")
    // console.log(ad.length, "address-vijay");
    // const cou = ad.length - 1
    // console.log();
    // console.log(cou, "vjlenghth");
    // console.log(ad[cou], "country");
    setAddress1(newAddress);
    // city.current.value = ad[1]
    // state.current.value = ad[2]
    // country.current.value = ad[cou]
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  const handleChangeAddress1 = (newAddress) => {
    setValiaddre1("")
    setabillingerr("")
    statusbilling.current.value = ""
    setAddress2(newAddress);
  };
  const handleSelectAddress1 = (newAddress) => {
    setValiaddre1("")
    statusbilling.current.value = ""
    // let ad = newAddress.split(",")
    // console.log(ad.length, "address-vijay");
    // const cou = ad.length - 1
    // console.log();
    // console.log(cou, "vjlenghth");
    // console.log(ad[cou], "country");
    setAddress2(newAddress);
    // city.current.value = ad[1]
    // state.current.value = ad[2]
    // country.current.value = ad[cou]
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  const nft1 = async (e) => {
    try {
      console.log("hai");
      setValiaddre1("")
      setsbillingerr("")
      if (pincode.current.value != undefined) {
        const add = await Axios.post('/validateaddress', {
          address: address1

        })
        if (add?.data?.success === true) {
          setValiaddre1("Address Verified")
          let fromadd = add?.data?.result?.address.split(",")
          let postad = fromadd[2]
          let postadd = postad.split(" ")
          // if (fromadd[1] != undefined) {
          //   city.current.value = fromadd[1]
          // }
          // if (fromadd[3] != undefined) {
          //   country.current.value = fromadd[3]
          // }
          // if (postadd[1] != undefined) {
          //   state.current.value = postadd[1]
          // }
          // if (postadd[2] != undefined) {
          //   postalcode.current.value = postadd[2]
          // }
        } else {
          console.log("failed");
          setValiaddre1("Address Not Verified")
        }
      }


    } catch (error) {
      console.log(error, "error");
    }
  }



  return (
    <div >
      <Button onClick={() => { handleOpen(row) }} className="activate">
        <img src={info} alt={info} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate Reactivate AddressPopup">
          <div className="filter-overflow">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

            <div id="brands"> <div className="thumbalin" ><img src={row?.user_info?.logo} alt={'adidasLogo'} /></div>  </div>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='addressList'>
                {row?.user_info?.address ? (
                  <>
                  <p className="reason">{row?.user_info?.name}</p>
                  <div className="add"><span className="mailTitle">Address: </span>{row?.user_info?.address}</div>
                </>) : (
                  <p className="reason">{row?.user_info?.name}</p>
                ) }

                <div className="mail">
                  <div className="mailValue"><span className="mailTitle">Brand-ID:</span> {row?.brandId ? row?.brandId : "-"}</div>
                  <div className="mailValue"><span className="mailTitle">E-mail:</span> {row?.user_info?.email}</div>
                  <div className="mailValue"><span className="mailTitle"> Web Address:</span> {row?.user_info?.web}</div>
                  <div className="mailValue"><span className="mailTitle">Contact person name:</span>   {row?.user_info?.contact_name}</div>
                  <div className="mailValue"><span className="mailTitle">email: </span>{row?.user_info?.contact_mail}</div>
                  <div className="mailValue"><span className="mailTitle">phone: </span>{row?.user_info?.phone}</div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listSec'>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                <div className='info'>
                  <div className='Bill'>
                    <p className="reason">Billing Info</p>

                    <div className='billingInfo'>
                      <div className="mail">
                        <div className="mailValue"><span className="mailTitle">CIF/NIF:</span> {row?.bill_info?.tax_id}</div>
                        <div className="mailValue"><span className="mailTitle">TAX Name:</span>{row?.bill_info?.tax_name}</div>
                        <div className="mailValue"><span className="mailTitle">Address:</span> {row?.bill_info?.address_billing}</div>
                        {/* <div className="mailValue"><span className="mailTitle">STATE: </span>{row?.bill_info?.status_billing}</div>
                        <div className="mailValue"><span className="mailTitle">Country: </span>{row?.bill_info?.country_billing}</div> */}
                      </div>
                    </div>
                  </div>

                </div>

              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                <div className='info'>
                  <div className='Bill'>
                    <p className="reason">Financial info</p>

                    <div className='billingInfo'>

                      <div className="mail">
                        <div className="mailValue"><span className="mailTitle">Bank account number: </span>{row?.financial_info?.bankno}</div>
                        <div className="mailValue"><span className="mailTitle">Routing number:</span> {row?.financial_info?.number}</div>
                        <div className="mailValue"><span className="mailTitle">Bank:</span>{row?.financial_info?.bankname}</div>
                        <div className="mailValue"><span className="mailTitle">SWIFT: </span>{row?.financial_info?.swiff}</div>
                        <div className="mailValue"><span className="mailTitle">Wallet Address: </span>0x5........81E</div>
                      </div>
                    </div>
                  </div>

                </div>

              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="reason">Licensing Information</p>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listSec'>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className='Bill'>
                    <div className='billingInfo'>
                      <div className="mail">
                        <div className="mailValue"><span className="mailTitle">Type of License: </span>{row?.licence_info?.type}</div>
                        <div className="mailValue"><span className="mailTitle">License Number:</span>{row?.licence_info?.licenceno}</div>
                        <div className="mailValue"><span className="mailTitle">Activation Date:</span> {row?.licence_info?.ondate}</div>
                        <div className="mailValue"><span className="mailTitle">Expiration Date: </span>{row?.licence_info?.expiredate}</div>
                        <div className="mailValue"><span className="mailTitle">Remember Date: </span>{row?.licence_info?.redate}</div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                  <div className='choice'>
                    <div className="warn">

                      <p className="reason">Active users</p>
                      <div className='billingInfo'>

                        <div className="mail">
                          {
                            row?.email_pswd && row?.email_pswd?.map((item, index) => {
                              return (
                                <div className="mailValue" key={index}><span className="mailTitle">{index + 1}. </span>{item?.email}</div>
                              )
                            })
                          }
                          {/* <div className="mailValue"><span className="mailTitle">Email: </span>{row?.user_info.email}</div>
                        <div className="mailValue"><span className="mailTitle">Email:</span> {row?.user_info.contact_phone}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>


            <div className="butOutLayer">
              {
                (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[0]?.Write === true)) ?
                  <Button className='edit' onClick={handleOpenEditInfo}>Edit info</Button>
                  :
                  <></>
              }

              {/* <Button className='create'>Download</Button> */}
            </div>

          </div>
        </Box>
      </Modal>


      {<Modal
        open={openEditInfo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate AdminAddUser ">
          <div className="menu" onClick={handleCloseEditInfo} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          {/* <div className="brand"><img src={adidasLogo} alt={'adidasLogo'} /></div> */}

          <div className='profile-image-outer'>
            <h4>Select Logo</h4>
            {logoerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{logoerr}</div> : <></>}
            <div className='profile-image profile-image-design-block'>
              <form>
                <input type="file" accept='image/*' className='input-field' hidden
                  onChange={(e) => { createLogo(e) }}
                />
                {logo ?
                  <img src={logo} alt={adidasLogo} /> :
                  <div className='upload-image-block'><CloudUploadIcon /></div>
                }
              </form>
            </div>
            <div className='edit-image-btn' onClick={() => document.querySelector(".input-field").click()}>
              <Button variant="contained" endIcon={<BorderColorIcon />}>
                Select
              </Button>
            </div>
          </div>


          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className='addressList'>
              <p className="reason">ADDRESS</p>

              <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block">

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='BRAND NAME'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setnameerr(null); setUserInfo({ ...userInfo, name: e.target.value }) }}
                        // inputRef={userInfo?.name}
                        value={userInfo?.name}
                      />
                      {nameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{nameerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='EMAIL'
                        variant="outlined"
                        className="textField"

                        // onChange={(e) => { setemailerr(null); setUserInfo({ ...userInfo, email: e.target.value }) }}
                        // inputRef={email}
                        value={userInfo?.email}
                      />
                      {emailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='WEB'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setweberr(null); setUserInfo({ ...userInfo, web: e.target.value }) }}
                        // inputRef={web}
                        value={userInfo?.web}
                      />
                      {weberr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{weberr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>


                {/* <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='CITY'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setcityerr(null); setUserInfo({ ...userInfo, city: e.target.value }) }}
                        // inputRef={city}
                        value={userInfo?.city}
                      />
                      {cityerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{cityerr}</div> : <></>}
                    </div>

                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='STATE'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setstateerr(null); setUserInfo({ ...userInfo, state: e.target.value }) }}
                        // inputRef={state}
                        value={userInfo?.state}
                      />
                      {stateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{stateerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='COUNTRY'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setcountryerr(null); setUserInfo({ ...userInfo, country: e.target.value }) }}
                        // inputRef={country}
                        value={userInfo?.country}
                      />
                      {countryerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{countryerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='PHONE'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setphoneerr(null); setUserInfo({ ...userInfo, phone: e.target.value }) }}
                        // inputRef={phone}
                        value={userInfo?.phone}
                      />
                      {phoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{phoneerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='CONTACT PERSON NAME'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setcontactnameerr(null); setUserInfo({ ...userInfo, contact_name: e.target.value }) }}
                        // inputRef={contactname}
                        value={userInfo?.contact_name}
                      />
                      {contactnameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactnameerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='CONTACT PERSON EMAIL'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setcontactemailerr(null); setUserInfo({ ...userInfo, contact_mail: e.target.value }) }}
                        // inputRef={contactmail}
                        value={userInfo?.contact_mail}
                      />
                      {contactemailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactemailerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='CONTACT PERSON PHONE'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { setcontactphoneerr(null); setUserInfo({ ...userInfo, contact_phone: e.target.value }) }}
                        // inputRef={contactphone}
                        value={userInfo?.contact_phone}
                      />
                      {contactphoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactphoneerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <PlacesAutocomplete
                        value={address1}
                        onChange={handleChangeAddress}
                        onSelect={handleSelectAddress}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              {...getInputProps({
                                // placeholder: "Search Places ...",
                                className: "location-search-input ssss"
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                                return (
                                  <div
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {addresserr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{addresserr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                  <Item className={classes.headercls}>
                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        placeholder='PIN CODE'
                        variant="outlined"
                        className="textField"
                        onChange={(e) => { nft(e) }}
                        // inputRef={pincode}
                        value={userInfo?.pin_code}
                      />
                      {pincodeerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{pincodeerr}</div> : <></>}
                    </div>
                  </Item>
                </Grid>

              </Grid>
              <div className="error-msg">
                <>{valiaddre === "Address Verified" ? <></> : <p>{valiaddre}</p>}</>
              </div>

              <div className="sucess-msg">
                <>{valiaddre === "Address Not Verified" ? <></> : <p>{valiaddre}</p>}</>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listSec'>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

              <div className='info'>
                <div className='Bill'>
                  <p className="reason">Billing Info</p>

                  <div className='billingInfo'>

                    <div className="productId">

                      <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-billing">

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Tax identification number'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { settaxiderr(null); setBillingInfo({ ...billingInfo, tax_id: e.target.value }) }}
                                // inputRef={taxid}
                                value={billingInfo?.tax_id}
                              />
                              {taxiderr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{taxiderr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Tax Name'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { settaxnameerr(null); setBillingInfo({ ...billingInfo, tax_name: e.target.value }) }}
                                // inputRef={taxname}
                                value={billingInfo?.tax_name}
                              />
                              {taxnameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{taxnameerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <PlacesAutocomplete
                                value={address2}
                                onChange={handleChangeAddress1}
                                onSelect={handleSelectAddress1}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Billing Address",
                                        className: "location-search-input  ssss"
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                                        return (
                                          <div
                                            key={suggestion.placeId}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style
                                            })}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              {abillingerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{abillingerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>



                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Post Code'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => nft1(e)}
                                // inputRef={statusbilling}
                                value={billingInfo?.status_billing}
                              />
                              {sbillingerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{sbillingerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                        <div className="error-msg">
                          {console.log(valiaddre1, "result")}
                          <>{valiaddre1 === "Address Verified" ? <></> : <p>{valiaddre1}</p>}</>
                        </div>

                        <div className="sucess-msg">
                          {console.log(valiaddre1, "resutl1")}
                          <>{valiaddre1 === "Address Not Verified" ? <></> : <p>{valiaddre1}</p>}</>
                        </div>




                        {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Country Billing'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { setcbillingerr(null); setBillingInfo({ ...billingInfo, country_billing: e.target.value }) }}
                                // inputRef={countrybilling}
                                value={billingInfo?.country_billing}
                              />
                              {cbillingerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{cbillingerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid> */}

                      </Grid>


                    </div>
                  </div>
                </div>

              </div>

            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

              <div className='info'>
                <div className='Bill'>
                  <p className="reason">Financial info</p>

                  <div className='billingInfo'>

                    <div className="productId">

                      <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-finance-info">

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Bank Account Number'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { setbanknoerr(null); setFinanceInfo({ ...financeInfo, bankno: e.target.value }) }}
                                // inputRef={bankno}
                                value={financeInfo?.bankno}
                              />
                              {banknoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{banknoerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Bank Name'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { setbanknameerr(null); setFinanceInfo({ ...financeInfo, bankname: e.target.value }) }}
                                // inputRef={bankname}
                                value={financeInfo?.bankname}
                              />
                              {banknameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{banknameerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Routing Number'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { setnumbererr(null); setFinanceInfo({ ...financeInfo, number: e.target.value }) }}
                                // inputRef={number}
                                value={financeInfo?.number}
                              />
                              {numbererr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{numbererr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Swift'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { setswifferr(null); setFinanceInfo({ ...financeInfo, swiff: e.target.value }) }}
                                // inputRef={swiff}
                                value={financeInfo?.swiff}
                              />
                              {swifferr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{swifferr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                          <Item className={classes.headercls}>
                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                placeholder='Other Information'
                                variant="outlined"
                                className="textField"
                                onChange={(e) => { setinfoerr(null); setFinanceInfo({ ...financeInfo, info: e.target.value }) }}
                                // inputRef={info1}
                                value={financeInfo?.info}
                              />
                              {infoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{infoerr}</div> : <></>}
                            </div>
                          </Item>
                        </Grid>

                      </Grid>


                    </div>
                  </div>

                </div>

              </div>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTri'>
            <div>
              <p className="reason">Licensing Information</p>
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTriRow'>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='rowOne'>

                  <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Licences Number
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setLicenceInfo({ ...licenceInfo, licenceno: e?.target?.value }); changePrice(e?.target?.value, licenceInfo?.discount, licenceInfo?.tax); setlicencenoerr(null); }}
                              // inputRef={licenceno}
                              value={licenceInfo?.licenceno}
                            />
                            {licencenoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{licencenoerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            price per licence
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setpriceerr(null); setLicenceInfo({ ...licenceInfo, price: e.target.value }) }}
                              // inputRef={price}
                              value={licenceInfo?.price}
                            />
                            {priceerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{priceerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            price total licenses
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              // onChange={(e) => { settotalpriceerr(null); setLicenceInfo({ ...licenceInfo, total_price: e.target.value }) }}
                              // inputRef={totalprice}
                              value={licenceInfo?.total_price}
                            />
                            {totalpriceerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{totalpriceerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Discount applied
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setdiscounterr(null); setLicenceInfo({ ...licenceInfo, discount: e.target.value }); changePrice(licenceInfo?.licenceno, e.target.value, licenceInfo?.tax) }}
                              // inputRef={discount}
                              value={licenceInfo?.discount}
                            />
                            {discounterr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{discounterr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Tax
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setdiscounterr(null); setLicenceInfo({ ...licenceInfo, tax: e.target.value }); changePrice(licenceInfo?.licenceno, licenceInfo?.discount, e.target.value) }}
                              // inputRef={tax}
                              value={licenceInfo?.tax}
                            />
                            {taxerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{taxerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            activation date
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setondateerr(null); setLicenceInfo({ ...licenceInfo, ondate: e.target.value }) }}
                              type="date"
                              // inputRef={ondate}
                              value={licenceInfo?.ondate}
                            />
                            {ondateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{ondateerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            expiration date
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              type="date"
                              onChange={(e) => { setexpiredateerr(null); setLicenceInfo({ ...licenceInfo, expiredate: e.target.value }) }}
                              // inputRef={expiredate}
                              value={licenceInfo?.expiredate}
                            />
                            {expiredateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{expiredateerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            remember date
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              type="date"
                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setredateerr(null); setLicenceInfo({ ...licenceInfo, redate: e.target.value }) }}
                              // inputRef={redate}
                              value={licenceInfo?.redate}
                            />
                            {redateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{redateerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                  </Grid>

                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='rightSide'>
                  <div className='choice'>
                    <div className="warn">
                      <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Select Type</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}

                          onChange={handleChange}
                        >
                          <FormControlLabel value="business" control={<Radio inputRef={type} />} label="BUSINESS" />
                          <FormControlLabel value="freelancer" control={<Radio inputRef={type1} />} label="FREELANCERS" />
                        </RadioGroup>
                      </FormControl>
                      {/* <div className="statusList"><Checkbox className="checkBox"/>BUSINESS</div>
              <div className="statusList"><Checkbox className="checkBox"/>FREELANCERS</div> */}
                    </div>
                  </div>

                  <div className='mailActive'>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Brand user's Email seperate using (,)
                    </Typography>

                    <div className='input-box'>
                      <TextField className='field-box'
                        required
                        multiline
                        rows={5}
                        rowsMax={10}
                        variant="outlined"
                        onChange={(e) => { setinformationerr(null); setLicenceInfo({ ...licenceInfo, information: e.target.value }) }}
                        // inputRef={information}
                        value={licenceInfo?.information}
                      />
                      {informationerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{informationerr}</div> : <></>}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="accord-div">
            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {/* <Typography className="add-btn">Add information</Typography> */}
              </AccordionSummary>
              {/* <AccordionDetails >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTri'>
        
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTriRow'>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='rowOne'>

                  <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Licences Number
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setlicencenoerr(null); setLicenceInfo({ ...licenceInfo, licenceno: e.target.value }) }}
                              // inputRef={licenceno}
                              value={licenceInfo?.licenceno}
                            />
                            {licencenoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{licencenoerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            price per licence
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setpriceerr(null); setLicenceInfo({ ...licenceInfo, price: e.target.value }) }}
                              // inputRef={price}
                              value={licenceInfo?.price}
                            />
                            {priceerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{priceerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            price total licenses
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { settotalpriceerr(null); setLicenceInfo({ ...licenceInfo, total_price: e.target.value }) }}
                              // inputRef={totalprice}
                              value={licenceInfo?.total_price}
                            />
                            {totalpriceerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{totalpriceerr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Discount applied
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setdiscounterr(null); setLicenceInfo({ ...licenceInfo, discount: e.target.value }) }}
                              // inputRef={discount}
                              value={licenceInfo?.discount}
                            />
                            {discounterr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{discounterr}</div> : <></>}
                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                      <Item className={classes.headercls}>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Discount applied
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              select

                              variant="outlined"
                              className="textField"
                              onChange={(e) => { setdiscounterr(null); setLicenceInfo({ ...licenceInfo, discount: e.target.value }) }}
                              // inputRef={discount}
                              value={licenceInfo?.discount}
                            >
                             {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
          </TextField>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  

                  </Grid>

                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='rightSide'>
                  

                  <div className='mailActive'>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Brand user's Email seperate using (,)
                    </Typography>

                    <div className='input-box'>
                      <TextField className='field-box'
                        required
                        multiline
                        rows={5}
                        rowsMax={10}
                        variant="outlined"
                        onChange={(e) => { setinformationerr(null); setLicenceInfo({ ...licenceInfo, information: e.target.value }) }}
                        // inputRef={information}
                        value={licenceInfo?.information}
                      />
                      {informationerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{informationerr}</div> : <></>}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
              </AccordionDetails> */}
            </Accordion>

          </div>

          <div className="butOutLayer">
            {/* <Button className='edit' onClick={handleOpenEditInfo}>edit info</Button> */}

            <Button className='create' onClick={() => { handleSuccessRegister() }}>Submit</Button>
          </div>
        </Box>
      </Modal>}

    </div>
  );
};

export default AddressPopup;
