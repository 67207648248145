import React, { useEffect, useState } from "react";
import "./Invoices.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from '@mui/styles';
import Axios from '../../../Axios';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import logo from '../../../img/dtlogo.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { Toaster, toast } from 'react-hot-toast'
import { useRef } from 'react';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import closeMenu from "../../../img/closemenu.png";
import File from "../../../img/system-file.svg";
import Files from '../../../img/files.svg';
import Print from '../../../img/print.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },
  containwidth: {
    padding: '0 30px',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name,
  calories,
  fat,
  carbs,
  protein
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('order id 10001 warranty extension product id add-1232-11', 1, '44,10$', '0%', '44,10$'),
];

const InvoicesPopup = ({ open, row, handleClose, getProfile }) => {
  const classes = useStyles();
  const [editopen, seteditOpen] = useState(false);
  const handleeditOpen = () => seteditOpen(true);
  const [date, setDate] = useState()
  const [expDate, setExpDate] = useState()
  const [totalprice, setTotalPrice] = useState(null)
  const [price, setprice] = useState(row?.price)
  const [discount, setdiscount] = useState(row?.discount)
  const [priceerr, setpriceerr] = useState(null)
  const [totalpriceerr, settotalpriceerr] = useState(null)
  const [discounterr, setdiscounterr] = useState(null)
  const handleeditClose = () => {
    seteditOpen(false)
    setpriceerr('')
    settotalpriceerr('')
    setdiscounterr('')
  }
  const Editinvoices = async () => {
    try {
      let reg = /^[0-9]+$/;
      console.log(row?._id)
      if (price === "") {
        setpriceerr("Please Enter Price")
      }
      else if (!(reg.test(price))) {
        setpriceerr("Please Valid number")
      }

      else if (discount === "") {
        setdiscounterr("Please Enter Discount")
      }
      else if (!(reg.test(discount))) {
        toast.error("Please Valid number")
        setdiscounterr("Please Valid number")
      }
      else if (totalprice === null) {
        settotalpriceerr("Please Enter Price")
      }
      else {
        const billData = {
          _id: row?._id,
          price: price,
          discount: discount,
          totalprice: totalprice,
        }
        const { data } = await Axios.post(`/admin/edit_billing`, billData,
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          })

        if (data?.success === true) {
          toast.success(data?.message)
          setTimeout(() => {
            seteditOpen(false)
            // status()
          }, 2000);
        }
      }
    } catch (error) {

      console.log(error, "err")
    }
  }

  const handleChangeprice = (e) => {
    setpriceerr(null)
    setprice(e.target.value)
  }

  const handleChangediscount = (e) => {
    setdiscounterr(null)
    setdiscount(e.target.value)
  }

  const totalLicencePrice = () => {
    if (row?.Quantity && price && discount) {
      var total = row?.Quantity * price
      if (Number(discount) > 0) {
        var tot = (discount / 100) * total
        setTotalPrice(total - tot)
      } else {
        setTotalPrice(total)
      }
    }
  }
  const selfRenewalBilling = async (row) => {
    try {

      const { data } = await Axios.post(`/users/self_renewal_billing`, { datas: row }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success) {
        console.log(data.result)
        toast.success("Your Invoice is Created and send to your Profile")
        setTimeout(() => {
          handleClose(false)
          getProfile()
        }, 2000);
        handleClose(false)

      }
    } catch (error) {
      console.log("🚀 getdetails ~ error:", error)

    }

  }
  // const totalLicencePrice = () => {
  //   if (row?.Quantity && price?.current?.value && discount?.current?.value) {
  //     var total = row?.Quantity * price?.current?.value
  //     if (Number(discount?.current.value) > 0) {
  //       var tot = (discount?.current.value / 100) * total
  //       setTotalPrice(total - tot)
  //     } else {
  //       setTotalPrice(total)
  //     }
  //   }
  // }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const convert = () => {
    const formattedDate = formatDate(row?.Creation_date);
    const formattedDate1 = formatDate(row?.Payment_date);
    setDate(formattedDate)
    setExpDate(formattedDate1)
  }

  useEffect(() => {
    convert()
  }, [row])



  return (
    <div className="invoicePopup">

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* {console.log(row)} */}
        <Box sx={style} className="popBOX BlockPopup">
          <div className="menu" onClick={() => { handleClose() }} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
          <div >
            <div id="alert-dialog-description" className='product-head'>
              <div className="logo-dt">
                <img src={logo} alt="dtlogo" />
              </div>
            </div>
            <Grid container spacing={0} className='whole-grid-value'>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <div className="dtllc-head para-mar-adj">
                  <p className="llc-head">{row?.Brand_Name}</p>
                  <p><b>{row?.Invoice_Id}</b></p>
                  {/* <p className="dtrace">dtracer.io</p> */}
                  <Link to="">{row?.email}</Link>
                  <p className="dtrace">{row?.Address}</p>
                  <p><b>{row?.Country}</b></p>
                </div>

                <div className="client-llc para-mar-adj">
                  <div className="client-llc-direct">
                    <p className="llc-head-1">CLIENT</p>
                    <div className="dtr">
                      <p className="dtrace-1"> Invoice Id : {row?.Invoice_Id} </p>
                      <p className="dtrace">Date: {date}</p>
                      <p className="dtrace">Expiration: {expDate}</p>
                    </div>

                  </div>
                </div>

                <div className="client-detail-table">

                  <Table sx={{ minWidth: 700 }} aria-label="customized table" className="invoice-billing-recieipt-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="head-part-table">Concept</StyledTableCell>
                        <StyledTableCell className="head-part-table">No.of.Licence</StyledTableCell>
                        <StyledTableCell className="head-part-table">Price</StyledTableCell>
                        <StyledTableCell className="head-part-table">Subtotal</StyledTableCell>
                        <StyledTableCell className="head-part-table">Discount</StyledTableCell>
                        <StyledTableCell className="head-part-table">Tax</StyledTableCell>
                        <StyledTableCell className="head-part-table">Total</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {rows.map((row) => ( */}
                      {/* <StyledTableRow key={row.name}> */}
                      <StyledTableRow >
                        <StyledTableCell className="table-value invoice-billing-recieipt-table-td">{row?.Invoice_concept}</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.Quantity}</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.price}</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.Quantity * row?.price}</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.discount}%</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.Tax_rate ? "0%" : "0%"}</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.Total_bil}</StyledTableCell>
                      </StyledTableRow>
                      {/* ))} */}
                    </TableBody>
                  </Table>

                </div>


              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="total-box invoice-billing-recieipt-pop-up ">
                  <div className="sub-total-direct">
                    <p><b>subtotal</b></p>
                    <div className="total-value">{row?.Total_bil}$</div>
                  </div>
                  <div className="sub-total-direct">
                    <p><b>discount</b></p>
                    <div className="total-value">{row?.discount}%</div>
                  </div>
                  <div className="sub-total-direct">
                    <p><b>tax 0%</b></p>
                    <div className="total-value">0,00$</div>
                  </div>
                  <div className="sub-total-direct">
                    <p><b>Total</b></p>
                    <div className="total-value">{row?.Total_bil}$</div>
                  </div>
                </div>
              </Grid>



            </Grid>
            <div className="export-btn">
              <Button variant="" className="button-cls" onClick={() => { handleClose() }} >Cancel</Button>
              <Button variant="" className="button-cls-2" onClick={() => { handleeditOpen(); selfRenewalBilling(row) }} >Create</Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* <Modal
        open={editopen}
        
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup">
          <div className="menu" onClick={handleeditClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
          <div >
            <div id="alert-dialog-description" className='product-head'>
              <div className="logo-dt">

                <img src={logo} alt="dtlogo" />
              </div>
            </div>
                       
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTri'>
            <div className="headTitle">Edit Invoice Price</div>
                                      <div className='rowOne'>

                                        <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  Price
                                                </Typography>

                                                <div className="cmn-input-cls" id="input">
                                                  <TextField
                                                    id="outlined-basic"

                                                    variant="outlined"
                                                    className="textField"
                                                    onChange={(e) => { handleChangeprice(e); totalLicencePrice() }}
                                                    name="price"
                                                    value={price}
                                                  />
                                                  {priceerr ? <div className='h3' style={{ color: 'red', margintop:"10px", textAlign: 'left' }}>{priceerr}</div> : <></>}
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>
</Grid>
<Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">
                                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  Discount
                                                </Typography>

                                                <div className="cmn-input-cls" id="input">
                                                  <TextField
                                                    id="outlined-basic"

                                                    variant="outlined"
                                                    className="textField"
                                                    onChange={(e) => { handleChangediscount(e); totalLicencePrice() }}
                                                    name="discount"
                                                    value={discount}
                                                  />
                                                  {discounterr ? <div className='h3' style={{ color: 'red', margintop:"10px", textAlign: 'left' }}>{discounterr}</div> : <></>}
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>
</Grid>
<Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">
                                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  Total Price
                                                </Typography>

                                                <div className="cmn-input-cls" id="input">
                                                  <TextField
                                                    id="outlined-basic"
                                                    disabled
                                                    variant="outlined"
                                                    className="textField"
                                                    value={totalprice}
                                                  // onChange={() => settotalpriceerr(null)}
                                                  // inputRef={totalprice}
                                                  />
                                                  {totalpriceerr ? <div className='h3' style={{ color: 'red', margin_top:"10px", textAlign: 'left' }}>{totalpriceerr}</div> : <></>}
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>                                 

                                        </Grid>

                                      </div>
                                   

                                  </Grid>
                                  
                                
            <div className="export-btn">
              <Button variant="" className="button-cls" onClick={()=>{handleeditClose()}}>Cancel</Button>
              <Button variant="" className="button-cls-2" onClick={()=>{Editinvoices()}}>Edit Details</Button>
            </div>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
};

export default InvoicesPopup;
