import Web3 from "web3"
import consts from "../../../constants"




const web3SendTransaction = async (txObject, privateAddress) => {
    
    // try {
    //     if(txObject.data) {
    //         var web3 = new Web3(consts.polygon_url)
    //         console.log("SEND TRANSACTION: ", txObject)
    //         const signedTx = await web3.eth.accounts.signTransaction(txObject, privateAddress);
    //         console.log("RESULT SIGNED: ", signedTx);
    //         const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
    //         console.log("RESULT SENT: ", receipt);
    //         return receipt;
    //     }
        
    // } catch (error) {
    //     throw new ('Error sending transaction');
    // }
    return new Promise((resolve, reject) => {
        var web3 = new Web3(consts.polygon_url)
        web3.eth.accounts.signTransaction(txObject, privateAddress, async (err, res1) => {
            if (err) {
                console.log('err', err)
                // reject(err)
            }
            else {
                // console.log('res', res1)
            }
            const raw = res1?.rawTransaction
            console.log("🚀 ~ web3.eth.accounts.signTransaction ~ raw:", raw)
            // console.lo
            // await web3.eth.sendSignedTransaction(raw, async (err, txHash) => {

            //     if (err) {
            //         console.log("🚀 ~ file: approveProduct.js:86 ~ awaitweb3.eth.sendSignedTransaction ~ err:", err)
            //     }
            //     else {
            //         console.log(txHash)

            //         await product.findOneAndUpdate({ _id: req.product_id, 'product_details.product_id': element.product_id }, { 'product_details.$.TxHash': txHash })
            //     }
            // })
            if(raw){
                await web3.eth.sendSignedTransaction(raw)
                .on('receipt', (receipt) => {
                    console.log("🚀 ~ .on ~ receipt:", receipt)
                    resolve(receipt)
                })
                .on('error', (err) => {
                    console.log(err, "errs")
                    reject(false)
                    reject(err)
                })
            }
            else reject('Error , raw not found')
            
        })
    })
}
export default web3SendTransaction