import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import RefreshIcon from "@mui/icons-material/Refresh";
import loader from "../../../img/loaderDtracer.gif";
import Ownership from "../../../img/Ownership.svg";
import guard from "../../../img/guard.png";
import home from "../../../img/home-btn.png";
import QRCode from "react-qr-code";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ReactToPrint from "react-to-print";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Box from "@mui/material/Box";
import consts from "../../../constants";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./AdminProducts.css";
import DownloadProducts from "./DownloadProducts";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Axios from "../../../Axios";
import closeMenu from "../../../img/closemenu.png";
import adidasLogo from "../../../img/adidasLogo.png";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import togglebtn from "../../../img/togglebtn.png";
import Menuclose from "../../../img/Menuclose.png";
import { Link, useLocation, useParams } from "react-router-dom";
import Files from "../../../img/files.svg";
import dayjs from "dayjs";
import "./QrProduct.css";
import Pagination from "@mui/material/Pagination";
import CustomerFilter from "./CustomerFilter";
import qrbar from "../../../img/qr-bar.png";
import rightState from "../../../img/rightState.png";
import wrongState from "../../../img/wrongState.png";
import productimagethumb from "../../../img/product-image-thumb.png";
import box from "../../../img/box.svg";
import AdminMenuSide from "../AdminMenuSide";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cross from "../../../img/cross.svg";
import MainBag from "../../../img/main-bag.png";
import QrBag from "../../../img/qr-bag-img.png";
import ProductInfo from "../../../img/qr-product-info.png";
import Thumbup from "../../../img/like-btn.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as XLSX from "xlsx";
import approve from "../../../img/approve-img.png";
import decline from "../../../img/decline-img.png";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Toaster, toast } from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddSellersPopup from "./AddSellersPopup1";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CryptoJS from "crypto-js";
import AddSellersproductEdit from "./AddSellersproductEdit";
import logout from "../../logout";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import BlockTwoToneIcon from "@mui/icons-material/BlockTwoTone";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { async } from "q";
import thumbalin from "../../../img/thumb-logo.svg";
import proceed from "../../../img/proceed.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import card1 from "../../../img/card1.png";
import AddSellersPopup2 from "./AddSellersPopup2";

const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
const todayStartOfTheDay = today.startOf("day");
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "0px",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  footercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    padding: "30px 0 !important",
    "@media (max-width: 991.98px)": {
      padding: "30px 0 !important",
    },
  },
  headercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },

  bodyclass: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    position: "relative",
  },
  stickycls: {
    position: "sticky",
    top: "0",
    zIndex: "999",
  },

  reasonBlock: {
    fontSize: "24px",
    fontWeight: "600",
  },
  modalpara: {
    fontSize: "18px",
    textAlign: "center",
    maxWidth: "750px !important",
    margin: "0 auto",
    paddingBottom: "80px",
    "@media (max-width: 767.98px)": {
      paddingBottom: "36px",
    },
  },
});

function createData(brandLogo, brandName, pdtName, refint, moreInfo, status) {
  return { brandLogo, brandName, pdtName, refint, moreInfo, status };
}

const row = [
  createData(
    productimagethumb,
    "ADIDAS",
    "test product name",
    "ADD1112-99",
    "TEST PRODUCT DESCRIPTION SHORT ",
    rightState
  ),
  createData(
    productimagethumb,
    "NIKE",
    "test product name",
    "ADD1112-99",
    "TEST PRODUCT DESCRIPTION SHORT ",
    wrongState
  ),
  createData(
    productimagethumb,
    "TE DEMO BRAND",
    "test product name",
    "ADD1112-99",
    "TEST PRODUCT DESCRIPTION SHORT ",
    rightState
  ),
];

const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "82%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "none",
  p: 4,
};

const styleReActive = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "3px solid #BFFF00 !important",
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const styledecline = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#fff",
  border: "none",
  p: 4,
  borderRadius: "20px",
};

const AdminProducts = () => {

  useEffect(() => {
    document.title = "Dtracer | Product"
  }, [])
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [allchecked, setAllchecked] = useState(false)
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [nameerr, setnameerr] = useState(null)
  const [phone, setphone] = useState("")
  const [phoneerr, setphoneerr] = useState(null)

  const [pass, setpass] = useState("");
  const [passerr, setpasserr] = useState(null);
  const user = window.localStorage.getItem("AdminToken");
  const [selectedDate, setSelectedDate] = useState(null);

  const blockProduct = async (rows) => {
    try {
      var reasons;
      if (other != "") {
        reasons = [...reason, other];
      } else {
        reasons = reason;
      }
      const { data } = await Axios.post(
        `/admin/brandBlockProduct`,
        {
          reason: reasons,
          status: rows,
          productId: selected?.product_id,
        },
        {
          headers: { Authorization: window.localStorage.getItem("AdminToken") },
        }
      );
      if (data?.success === true) {
        toast.success(data?.message);
        getSellers();
        setReason([]);

        if (rows === "block") {
          handleClose2();
          setOpenProductInfoAdmin1(false);
        } else {
          handleClose3();
          setOpenProductInfoAdmin1(false);
        }
        getProducts();
      } else {
        toast.error(data?.message);

        handleClose3();
        setReason([]);
      }
    } catch (error) {
      setReason([]);
      console.log("🚀 ~ file: Customer.js:598 ~ blockProduct ~ error:", error);
    }
  };

  const [other, setOther] = useState("");
  const [emailerr, setemailerr] = useState(null);
  const [emailerr1, setemailerr1] = useState(null);
  const onchangeother = (data) => {
    setOther(data);
  };
  const [age, setAge] = useState("");
  const [sellersId, setSellersId] = useState([]);
  const [sellersId1, setSellersId1] = useState([]);

  const handleChange1 = (event) => {
    setAge(event.target.value);
  };

  const [logoerr, setlogoerr] = useState(null);
  const [logo, setlogo] = useState(null);
  const classes = useStyles();
  const isMounted = useRef(null);
  const [menuOpen, setMenuOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [products1, setProducts1] = useState([]);
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const openMenuHandler = () => {
    setMenuOpen(false);
  };

  const closeMenuHandler = () => {
    setMenuOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loc, setLoc] = useState();

  // const queryParameters = new URLSearchParams(window.location.search)

  const role = window.localStorage.getItem("Squelch");
  const status = window.localStorage.getItem("isimo");

  const [sellers, setSellers] = useState([]);
  const [emails1, setemails1] = useState();
  let re1 =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sendQrCode = async (datas) => {
    try {
      if (!re1.test(emails1)) {
        setemailerr1("Please Enter Valid Email");
      } else {
        const { data } = await Axios.post(
          `/admin/sendqr`,
          {
            qrcode: datas,
            email: emails1,
          },
          {
            headers: {
              Authorization: window.localStorage.getItem("AdminToken"),
            },
          }
        );
        if (data?.success == true) {
          setemails1("");
          toast.success("Mail Send Successfully");
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AdminProducts.js:329 ~ sendQrCode ~ error:",
        error
      );
    }
  };

  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  const [openGenShipStatus, setOpenGenShipStatus] = useState(false);
  const [ship, setShip] = useState([]);

  const handleOpenGenShipStatus = async () => {
    try {
      var st = "";
      var status = true;
      var qrcodes = [];
      var qrids = [];
      for (var i = 0; i < selectedQr.length; i++) {
        var qrdetails = {};
        qrdetails.id = selectedQr[i]?._id;
        qrdetails.Detail1_image = selectedQr[i]?.product_details[0]?.main_image;
        qrdetails.product_category =
          selectedQr[i]?.product_details[0]?.product_category;
        qrdetails.Product_Name =
          selectedQr[i]?.product_details[0]?.Product_Name;
        qrcodes.push(qrdetails);
        qrids.push(selectedQr[i]?._id);
        if (Number(selectedQr?.Initial_stock) != selectedQr?.totalapproved) {
          status = false
        }
      }
      var { data } = await Axios.post(
        "/admin/checkShipmentStatus",
        { prodId: qrids },
        {
          headers: { Authorization: window.localStorage.getItem("AdminToken") },
        }
      );
      if (data?.result?.length > 0 && data?.success == true) {
        toast.error(`This Products Already Shiped ${data?.result?.toString()}`);
      } else if (data?.result?.length > 0 && data?.success == false) {
        if (status == false) {
          toast.error(
            `Blocked And Not Approved Products are Not Shipment ${data?.result?.toString()}`
          );
        } else {
          setShip(qrcodes);
          const dts = await Axios.post("/profile/generateQRCodeWithLogo", {
            text: `${consts.MainUrl}brand/feedback/${JSON.stringify(qrids)}`,
          });
          setQrs(dts?.data?.result);
          if (qrcodes.length != 0) {
            setOpenGenShipStatus(true);
          } else {
            toast.error("Please Select Product");
          }
        }
      } else {
        setShip(qrcodes);
        const dts = await Axios.post("/profile/generateQRCodeWithLogo", {
          text: `${consts.MainUrl}brand/feedback/${JSON.stringify(qrids)}`,
        });
        setQrs(dts?.data?.result);
        if (qrcodes.length != 0) {
          setOpenGenShipStatus(true);
        } else {
          toast.error("Please Select Product");
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleOpenGenShipStatus ~ error:", error);
    }
  };
  const handleCloseGenShipStatus = () => setOpenGenShipStatus(false);

  const ref = useRef();
  const refs = useRef();

  const sendQrCode1 = async () => {
    try {
      var qrcodes = [];

      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (var i = 0; i < selectedQr.length; i++) {
        var qrdetails = {};
        qrdetails.id = selectedQr[i].product_id;
        qrdetails.link = selectedQr[i].qr_link;
        qrcodes.push(qrdetails);
      }
      if (emails1 === undefined) {
        setemailerr("Please Give Email");
      } else if (!re.test(emails1)) {
        setemailerr("Please Enter valid Email");
      } else if (qrcodes.length <= 0) {
        toast.error("Please Select Product");
      } else if (qrcodes.length > 0 && qrcodes[0]?.link == "") {
        toast.error("QrCode Not Available");
      } else {
        setLoading(true);
        const { data } = await Axios.post(`/admin/sendqrcodes`, {
          qrcode: qrcodes,
          email: emails1
        }, {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken")
          }
        })
        if (data?.success == true) {
          setLoading(false)
          setemails1('')
          setemailerr()
          toast.success("Mail Send Successfully")
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(
        "🚀 ~ file: AdminProducts.js:329 ~ sendQrCode ~ error:",
        error
      );
    }
  };

  const downloadQrCode1 = async () => {
    try {
      var qrcodes = [];
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (var i = 0; i < selectedQr.length; i++) {
        var qrdetails = {};
        qrdetails.id = selectedQr[i].product_id;
        qrdetails.link = selectedQr[i].qr_link;
        qrcodes.push(qrdetails);
      }
      if (qrcodes.length <= 0) {
        toast.error("Please Select Product");
      } else if (qrcodes.length > 0 && qrcodes[0]?.link == "") {
        toast.error("QrCode Not Available");
      } else {
        setLoading1(true);
        const data = await Axios.post(`/pdf/downloadMultipleQrcodes`, {
          qrcode: qrcodes,
        }, {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken")
          }
        })
        if (data.status == 200) {
          // setStsDownload(false);
          window.location.href = `${consts.BackendUrl}/pdf/downloadMultipleQrcodes`;
          setTimeout(() => {
            setLoading1(false)
            toast.success("Pdf Downloaded Successfully");
            setSellersId([])
            setSelectedRows([])
            // setSelected1([])
            setAllchecked(false)
            // window.location.href = `http://localhost:3000/products/all`;
          }, 2000);
        }
      }
    } catch (error) {
      setLoading1(false);
      console.log(
        "🚀 ~ file: AdminProducts.js:329 ~ sendQrCode ~ error:",
        error
      );
    }
  };

  const [blockMulti, setBlockMultiple] = useState()
  const [blockMultiImg, setBlockMultipleImg] = useState()

  const BlockMultipleItems = async () => {
    try {
      if (selectedRows?.length > 0) {
        var arr = []
        var check = []
        for (let i = 0; i < selectedRows.length; i++) {
          const element = details?.product_details[selectedRows[i]];
          if (element?.status == "2") {
            check.push(element?.product_id)
          }
          arr.push(element?.product_id)
        }
        if (check?.length > 0) {
          toast.error("One of the Product is Already Blocked")
        } else {
          setBlockMultiple(arr)
          handleOpen22();
          setBlockMultipleImg(details?.product_details[0]?.main_image)
        }

      } else {
        toast.error("Please Select Product")
      }

    } catch (error) {
      console.log("🚀 ~ BlockMultipleItems ~ error:", error)
    }
  }

  const BlockMultipleItems1 = async (type) => {
    try {
      var reasons;
      if (other != "") {
        reasons = [...reason, other];
      } else {
        reasons = reason;
      }
      const { data } = await Axios.post(
        `/admin/brandBlockProduct`,
        {
          reason: reasons,
          status: type,
          productId: blockMulti,
        },
        {
          headers: { Authorization: window.localStorage.getItem("AdminToken") },
        }
      );
      if (data?.success === true) {
        toast.success(data?.message);
        getSellers();
        setReason([]);

        // if (rows === "block") {
        handleClose22();
        setOpenProductInfoAdmin1(false);
        // } else {
        //   handleClose3();
        //   setOpenProductInfoAdmin1(false);
        // }
        getProducts();
      } else {
        toast.error(data?.message);
        handleClose3();
        setReason([]);
      }

    } catch (error) {
      console.log("🚀 ~ BlockMultipleItems1 ~ error:", error)

    }
  }



  const getSellers = async () => {
    try {
      const { data } = await Axios.post("/users/get_seller", "", {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      });
      if (data?.success) {
        setAge(data?.result[0]?._id);
        setSellers(data?.result);
      } else {
        // toast.error(data?.message)
      }
    } catch (error) {
      console.log(error, "err");
      // toast.error("error")
    }
  };

  const getFile = async (e) => {
    try {
      // let files = await e.target.files[0]
      let formData = await new FormData();
      // formData.append("image", files)

      const { data } = await Axios.post("/users/uploadlogo", formData);

      if (data.result) {
        // setDoc(data.result)
        toast.success("File uploaded Successfully");
      }
    } catch (error) {
      console.log(error, "err");
    }
  };

  useEffect(() => {
    if (role === "brand") {
      getSellers();
    }
  }, [role]);

  const [decrptedData, setDecrptedData] = useState();
  const secretPass = "DtRaCeRsEcrEtFrEe";
  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt(status, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };

  useEffect(() => {
    if (role === "subAdmin") {
      decryptData();
    }
  }, [status]);

  const { id } = useParams();

  useEffect(() => {
    if (id !== "all") {
      getProductsById(id);
    } else {
      getProducts();
    }
  }, [id]);

  const [isActive, setIsActive] = useState(true);
  const redirectPath = "/";
  const timeoutSeconds = 5 * 60 * 1000;
  // const timeoutSeconds = 2 * 1000
  const navigate = useNavigate();
  useEffect(() => {
    screenInactive();
  }, [timeoutSeconds, isActive, navigate, redirectPath]);

  const screenInactive = () => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      if (isActive) {
        timeoutId = setTimeout(() => {
          logout();
          window.localStorage.removeItem("electrónico");
          window.localStorage.removeItem("AdminToken");
          window.localStorage.removeItem("Squelch");
          navigate(`${consts.route}/`);
        }, timeoutSeconds);
      }
    };

    const handleMouseActivity = () => {
      setIsActive(true);
      resetTimeout();
    };

    resetTimeout();

    window.addEventListener("mousemove", handleMouseActivity);
    window.addEventListener("keydown", handleMouseActivity);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleMouseActivity);
      window.removeEventListener("keydown", handleMouseActivity);
    };
  };

  const [click, setClick] = useState(false);
  const handleOpen = () => setClick(true);
  const handleExit = () => setClick(false);
  const [openAdminReActive, setOpenAdminReActive] = useState(false);
  const handleOpenAdminReActive = () => setOpenAdminReActive(true);
  const handleCloseAdminReActive = () => setOpenAdminReActive(false);
  const [openProductInfoAdmin, setOpenProductInfoAdmin] = useState(false);
  const [openProductInfoAdmin1, setOpenProductInfoAdmin1] = useState(false);
  const [openProductFeedBack, setOpenProductFeedBack] = useState(false);

  const handleOpenProductFeedBack = () => setOpenProductFeedBack(true);
  const handleProductFeedBackClose = () => setOpenProductFeedBack(false);

  const handleOpenProductInfoAdmin = () => setOpenProductInfoAdmin(true);
  const handleCloseProductInfoAdmin = () => setOpenProductInfoAdmin(false);

  const handleOpenProductInfoAdmin1 = () => setOpenProductInfoAdmin1(true);
  const handleCloseProductInfoAdmin1 = () => setOpenProductInfoAdmin1(false);

  const [openOwnerInfoAdmin, setOpenOwnerInfoAdmin] = useState(false);
  const handleOpenOwnerInfoAdmin = () => setOpenOwnerInfoAdmin(true);
  const handleCloseOwnerInfoAdmin = () => setOpenOwnerInfoAdmin(false);

  const [openOwnerProceedInfo, setOpenOwnerProceedInfo] = useState(false);
  const handleOpenOwnerProceedInfo = () => setOpenOwnerProceedInfo(true);
  const handleCloseOwnerProceedInfo = () => setOpenOwnerProceedInfo(false);

  const [openAdminPdtDeActive, setOpenAdminPdtDeActive] = useState(false);
  const handleOpenAdminPdtDeActive = () => setOpenAdminPdtDeActive(true);
  const handleCloseAdminPdtDeActive = () => setOpenAdminPdtDeActive(false);

  const [openAdminApproval, setOpenAdminApproval] = useState(false);
  const handleOpenAdminApproval = () => setOpenAdminApproval(true);
  const handleCloseAdminApproval = () => setOpenAdminApproval(false);

  const [openAdminQRProduct, setOpenAdminQRProduct] = useState(false);
  const handleOpenAdminQRProduct = () => setOpenAdminQRProduct(true);
  const handleCloseAdminQRProduct = () => setOpenAdminQRProduct(false);

  const [opendecline, setOpendecline] = React.useState(false);
  const handleOpendecline = () => setOpendecline(true);
  const handleClosedecline = () => setOpendecline(false);

  const [openDeclinePopUp, setOpenDeclinePopUp] = useState(false);
  const handleOpenDeclinePopUp = () => setOpenDeclinePopUp(true);
  const handleCloseDeclinePopUp = () => setOpenDeclinePopUp(false);

  const [openUploadPopUp, setOpenUploadPopUp] = useState(false);
  const handleOpenUploadPopUp = () => setOpenUploadPopUp(true);
  const handleCloseUploadPopUp = () => setOpenUploadPopUp(false);

  const [openProductSeller, setOpenProductSeller] = useState(false);
  const handleOpenProductSeller = () => {
    // console.log("onesesd")
    setOpenGenShipStatus(false);
    setOpenProductSeller(true);
  };
  const handleCloseProductSeller = () => setOpenProductSeller(false);

  const [selcSeller, setSelcSeller] = useState();
  const [date, setDate] = useState();

  const [indexSelc, setIndexSelc] = useState();

  const [openProductSellerCompleted, setOpenProductSellerCompleted] =
    useState(false);
  const handleOpenProductSellerCompleted = () => {
    if (selcSeller == undefined || selcSeller == "") {
      toast.error("Please Select any one Seller");
    } else if (selectedDate == undefined || selectedDate == null) {
      toast.error("Please Select Date");
    } else {
      var spl = selectedDate.$d.toString().split(" ");
      setDate(` ${spl[2]} / ${spl[1]} / ${spl[3]}`);
      setOpenProductSeller(false);
      setOpenProductSellerCompleted(true);
    }
  };
  const handleCloseProductSellerCompleted = () =>
    setOpenProductSellerCompleted(false);

  const [openViewMap, setopenViewMap] = useState(false);
  const handleopenViewMap = () => setopenViewMap(true);
  const handleCloseViewMap = () => setopenViewMap(false);

  const [openViewMessage, setOpenViewMessage] = useState(false);
  const handleOpenViewMessage = () => setOpenViewMessage(true);
  const handleCloseViewMessage = () => setOpenViewMessage(false);

  const roles = window.localStorage.getItem("Squelch");

  // useEffect(() => {

  //   if (!isMounted.current) {
  //     getProducts();
  //     isMounted.current = true;
  //   }
  //   else {
  //   }
  // }, [])

  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState();

  const [statussss, setstatusss] = useState();

  const handleChange = (event, value) => {
    setPage(value);
  };

  // useEffect(() => {
  //   getProductsa()
  // }, [page, products1])

  useEffect(() => {
    if (id !== "all") {
      getProductsById(id);
    } else {
      if (isfilter === false) {
        getProducts();
      }
    }
  }, [page]);
  const [reason, setReason] = useState([]);
  const [reasons, setReasonText] = useState("");
  const [isfilter, setIsfilter] = useState(false);
  const [datas1, setDatas] = useState([]);

  const setResons = (data) => {
    const dt = reason;
    const check = dt.includes(data);
    if (check === false) {
      setReason([...reason, data]);
    } else {
      setReason(reason.filter((item) => item !== data));
    }
  };

  const setReasontext = (data) => {
    setReason([data]);
  };

  const getProductsa = async () => {
    if (products1?.length > 0) {
      var currentpage = page - 1;
      var dt = [];
      if (currentpage === 0) {
        for (let i = 0; i < 10; i++) {
          const element = products1[i];
          if (element) {
            dt.push(element);
          }
        }
        setProducts(dt);
      } else {
        var first = currentpage * 10 + 1;
        var second = first + 10;
        for (let i = first; i < second; i++) {
          const element = products1[i];
          if (element) {
            dt.push(element);
          }
        }
        setProducts(dt);
      }
    } else {
      setProducts([]);
    }
  };

  const getProducts = async () => {
    try {
      setstatusss("Loading....");
      setProducts([]);
      const { data } = await Axios.get(
        `/admin/get_products?limit=10&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success) {
        if (data?.result?.docs?.length > 0) {
          setCount(data?.result?.totalPages);
          setProducts(data?.result?.docs);
          setSelected2(data?.result?.docs);
        } else {
          setstatusss("No Products Available");
        }
      }
    } catch (error) {
      setstatusss("No Products Available");
      console.log(error, "err");
    }
  };

  const getProductsById = async (id) => {
    try {
      setstatusss("Loading....");
      const { data } = await Axios.post(
        `/admin/productByBrand?limit=10&page=${page}`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success) {
        if (data?.result?.docs?.length > 0) {
          // setCount(Math.ceil(data?.result?.docs?.length / 10))
          setCount(data?.result?.totalPages);
          // setProducts1(data?.result?.docs)
          setProducts(data?.result?.docs);
          setSelected2(data?.result?.docs);
        } else {
          setstatusss("No Products Available");
        }
      }
    } catch (error) {
      console.log(error, "err");
    }
  };

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open22, setOpen22] = useState(false);
  const handleOpen22 = () => setOpen22(true);
  const handleClose22 = () => setOpen22(false);

  const [datas, setData] = useState([]);
  const [isSend, setIssend] = useState(false);
  const [selected, setSelected] = useState();
  const [selected1, setSelected1] = useState();
  const [selected2, setSelected2] = useState();
  const [details, setDetails] = useState({});
  const [stscheck, setStsCheck] = useState(false);

  const [qrs, setQrs] = useState();

  const [newSelec, setNewSelec] = useState();

  const [opens, setOpens] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [good, setGood] = useState(false);

  const handleGood = () => {
    setGood(!good);
  };

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const downloadImage = () => {
    saveAs(qrs, `${selcSeller?.sellerId}.jpg`); // Put your image URL here.
  };

  // const cleanField = (value) => value ? value.replace(/\([^)]+\)/, '').trim() : '';

  const downloadExcelsheet1 = () => {
    const tableData = [
      ['Serial_No', 'product_category', 'product_identifier', 'ean', 'Product_Name', 'Initial_stock', 'Unit_of_measurement', 'Measures', 'Size', 'main_image', 'Detail1_image', 'Detail2_image', 'Detail3_image', 'product_description', 'material', 'ingredients', 'departure_location', 'Cordenate_location', 'Attachments', 'Warranty (yes/no)',
        'Duration_of_the_official_warranty (months)', 'Allows_warranty_extension(yes/no)', 'Extra_warranty_time(months)', 'Warranty_extension_price', 'Type_of_currency', 'Physical_product_1_or_virtual_0', 'production_date', 'LOT_NUMBER', 'UN_Product_Classification_Number', 'Toxic', 'substance_type', 'composition'],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "sample.csv");
  }

  const downloadExcelsheet = () => {
    const tableData = [
      // ['Serial_No', 'product_category', 'product_identifier', 'ean', 'Product_Name', 'Initial_stock', 'Unit_of_measurement', 'Measures', 'Size', 'main_image', 'Detail1_image', 'Detail2_image', 'Detail3_image', 'product_description', 'material', 'ingredients', 'departure_location', 'Cordenate_location', 'Attachments', 'Warranty (yes/no)',
      //   'Duration_of_the_official_warranty (months)', 'Allows_warranty_extension(yes/no)', 'Extra_warranty_time(months)', 'Warranty_extension_price', 'Type_of_currency', 'Physical_product_1_or_virtual_0', 'production_date', 'LOT_NUMBER', 'UN_Product_Classification_Number', 'Toxic', 'substance_type', 'composition'],
    ];
    if (products.length > 0) {
      for (var i = 0; i < products.length; i++) {
        var proDetails = {
          // ProductName: products[i]?.brand_name,
          Category: products[i]?.product_details[0]?.product_category,
          ProductName: products[i]?.product_details[0]?.Product_Name,
          ShortDescription: products[i]?.product_details[0]?.product_description,
          InitialStock: products[i]?.Initial_stock,
          // Status: products[i]?.row?.status === '0' ? 'pending' : row?.status === '1' ? 'Approved' : 'Declined'
          Pending: products[i]?.totalpending,
          Approved: products[i]?.totalapproved,
          Declined: products[i]?.totaldeclined
        }
        tableData.push(proDetails)
      }
    }

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "productDetails.csv");
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { defval: "-" });
      setData(parsedData);
    };
  };

  function changeKey(array, oldKey, newKey) {
    array.forEach((obj) => {
      if (oldKey in obj) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
    });
  }

  const uploadProduct = async () => {
    const toastId = toast.loading("Uploading...");
    try {
      changeKey(datas, "Warranty (yes/no)", "Warranty");

      changeKey(
        datas,
        "Duration_of_the_official_warranty (months)",
        "Duration_of_the_official_warranty"
      );
      changeKey(datas, "Extra_warranty_time(months)", "Extra_warranty_time");
      changeKey(
        datas,
        "Allows_warranty_extension(yes/no)",
        "Allows_warranty_extension"
      );

      // const { data } = await Axios.post('/users/createproduct',
      const { data } = await Axios.post(
        "/users/productDetails",
        { products: datas },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success === true) {
        handleCloses();
        handleCloseUploadPopUp();
        toast.dismiss(toastId);
        toast.success("Product Added Successfully");
        getProducts();
      }
    } catch (error) {
      handleCloses();
      handleCloseUploadPopUp();
      getProducts();
      toast.dismiss(toastId);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (datas.length > 0) {
      // uploadProduct()
      handleClickOpens();
    }
  }, [datas]);

  const filterPro = (data, count) => {
    if (data?.length > 0) {
      setProducts(data);
      setCount(Math.ceil(count / 10));
    } else {
      setProducts(data);
      setstatusss("No product available for this filter.....");
    }
  };
  const reset = (data) => {
    if (data === true) {
      if (id !== "all") {
        getProductsById(id);
      } else {
        getProducts();
      }
    }
  };

  const sts = (data) => {
    if (data === true) {
      if (id !== "all") {
        getProductsById(id);
      } else {
        getProducts();
      }
    }
  };

  const setSelection = async (data) => {
    setSelected(data);
  };

  const setSelection1 = async (datas, data) => {
    setSelected1(data);
    setDetails(datas);
  };

  /*File Upload */
  const product_name = useRef();
  const brand_name = useRef();
  const category = useRef();
  const product_identifier = useRef();
  const ean = useRef();
  const initial_stock = useRef();
  const unit_measurement = useRef();
  const measurement = useRef();
  const mainimg = useRef();
  const detailimg1 = useRef();
  const detailimg2 = useRef();
  const detailimg3 = useRef();
  const product_description = useRef();
  const material = useRef();
  const ingredients = useRef();
  const depature_location = useRef();
  const coordinates = useRef();
  const license_link = useRef();
  const warrenty = useRef();
  const duration_guarantee = useRef();
  const extra_warrenty = useRef();
  const warrenty_price = useRef();
  const type_currency = useRef();
  const phy_pro_vir = useRef();
  const Production_date = useRef();
  const lot_number = useRef();
  const classification_num = useRef();
  const toxic = useRef();
  const substance_type = useRef();
  const composition = useRef();

  const [deleteId, setDeleteId] = useState();

  const [fed, setFed] = useState([{ name: "Duke 250" }]);

  const submits = async () => {
    try {
      const datas = {
        Product_Name: product_name.current.value,
        product_category: category?.current?.value,
        product_identifier: product_identifier?.current?.value,
        ean: ean?.current?.value,
        brand_name: brand_name?.current?.value,
        Initial_stock: initial_stock?.current?.value,
        Unit_of_measurement: unit_measurement?.current?.value,
        Measures: measurement?.current?.value,
        main_image: mainimg?.current?.value,
        Detail1_image: detailimg1?.current?.value,
        Detail2_image: detailimg2?.current?.value,
        Detail3_image: detailimg3?.current?.value,
        product_description: product_description?.current?.value,
        material: material?.current?.value,
        ingredients: ingredients?.current?.value,
        departure_location: depature_location?.current?.value,
        Cordenate_location: coordinates?.current?.value,
        License_Attachments: license_link?.current?.value,
        Warranty: warrenty?.current?.value,
        Duration_of_the_official_warranty: duration_guarantee?.current?.value,
        // Allows_warranty_extension: extra_warrenty?.current?.value,
        Extra_warranty_time: extra_warrenty?.current?.value,
        Warranty_extension_price: warrenty_price?.current?.value,
        Type_of_currency: type_currency?.current?.value,
        Physical_product_1_or_virtual_0: phy_pro_vir?.current?.value,
        production_date: Production_date?.current?.value,
        LOT_NUMBER: lot_number?.current?.value,
        UN_Product_Classification_Number: classification_num?.current?.value,
        Toxic: toxic?.current?.value,
        substance_type: substance_type?.current?.value,
        composition: composition?.current?.value,
      };
      // const { data } = await Axios.post(`/users/createproduct`, {
      const { data } = await Axios.post(
        `/users/productDetails`,
        {
          products: [datas],
        },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success === true) {
        toast.success("Product Added Successfully");
        handleClose();
        sts(true);
      }
    } catch (error) {
      console.log("🚀 ~ submits ~ error:", error);
    }
  };

  const [declineerr, setDeclineerr] = useState("");

  const DeclineProduct = async () => {
    if (reason == "") {
      setDeclineerr("* Please Specify a Reason");
    } else {
      try {
        const { data } = await Axios.post(
          `/users/approveProduct`,
          {
            product_id: deleteId?._id,
            status: "2",
            reason: reason,
          },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        );
        if (data?.success === true) {
          toast.success(data?.message);
          getProducts();

          handleCloseDeclinePopUp();
          // window.location.reload(true)
        } else {
          toast.error(data?.message);
        }
      } catch (error) {
        console.log("🚀  ~ error:", error);
      } finally {
        setDeclineerr("");
      }
    }
  };

  // const generatePdf = (datas, status) => {

  //   setStsDownload(true)
  //   DownloadProducts(datas, status)
  //   setStsDownload(false)
  // }

  const [stsDownload, setStsDownload] = useState(false);
  const [stsDownloads, setStsDownloads] = useState(false);
  const [ischecked, setisChecked] = useState(false);
  const [emails, setemails] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRows1, setSelectedRows1] = useState([]);
  const [selectedQr, setSelectedQr] = useState([]);

  const [selecteds, setSelecteds] = useState([]);
  const downloadPdf = async (product_id) => {
    try {
      setStsDownload(true);
      await Axios.get(`/pdf/download_pdf/${product_id}`, {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      })
        .then(async (res) => {
          if (res?.status === 200) {
            setStsDownload(false);
            window.location.href = `${consts.BackendUrl}/pdf/download_pdf/${product_id}`;
            setTimeout(() => {
              setOpenProductInfoAdmin(false);
              toast.success("Pdf Downloaded Successfully");
              // window.location.href = `http://localhost:3000/products/all`;
            }, 2000);
          } else {
            setStsDownload(false);
            toast.error("Can't Download Successfully");
          }
        })
        .catch((err) => {
          // window.location.href = `http://localhost:3000/products/all`;
          console.log(err);
          setStsDownload(false);
        });
    } catch (error) {
      setStsDownload(false);
      console.log(
        "🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:",
        error
      );
    }
  };

  const downloadPdfCertificate = async (product_id) => {
    try {
      setStsDownload(true);
      await Axios.get(`/pdf/downloadCertificatePdf/${product_id}`, {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      })
        .then(async (res) => {
          if (res?.status === 200) {
            setStsDownload(false);
            window.location.href = `${consts.BackendUrl}/pdf/downloadCertificatePdf/${product_id}`;
            setTimeout(() => {
              setOpenProductInfoAdmin(false);
              toast.success("Pdf Downloaded Successfully");
              // window.location.href = `http://localhost:3000/products/all`;
            }, 2000);
          } else {
            setStsDownload(false);
            toast.error("Can't Download Successfully");
          }
        })
        .catch((err) => {
          // window.location.href = `http://localhost:3000/products/all`;
          console.log(err);
          setStsDownload(false);
        });
    } catch (error) {
      setStsDownload(false);
      console.log(
        "🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:",
        error
      );
    }
  };

  const sendPdfCertificate = async (product_id) => {
    try {
      setStsDownloads(true);
      await Axios.post(
        `/users/create_cretificate`,
        {
          isSend: isSend,
          product_id: product_id,
          email: emails,
        },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
        .then(async (res) => {
          if (res?.status === 200) {
            setStsDownloads(false);

            toast.success("certificate send Successfully");
            handleCloseAdminQRProduct();
          } else {
            setStsDownloads(false);
            toast.error("Can't Download Successfully");
          }
        })
        .catch((err) => {
          console.log(err);
          setStsDownloads(false);
        });
    } catch (error) {
      setStsDownloads(false);
      console.log(
        "🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:",
        error
      );
    }
  };

  const setSellersIds = async () => {
    try {
      const { data } = await Axios.post(
        `/addSellerId`,
        {
          product_id: sellersId,
          seller_id: age,
        },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success === true) {
        toast.success(data?.message);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AdminProducts.js:727 ~ setSellersIds ~ error:",
        error
      );
      toast.error(error?.response?.data?.message);
    }
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;
  const isSelected1 = (id) => selectedRows1.indexOf(id) !== -1;

  const selectedInvoice = (row, details) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(
        selectedRows.filter((selectedItem) => selectedItem !== row)
      );

      setSelectedQr(
        selectedQr.filter(
          (selectedItem) => selectedItem.qr_link !== details.qr_link
        )
      );
    } else {
      setSelectedRows([...selectedRows, row]);

      setSelectedQr([...selectedQr, details]);
    }
  };

  useEffect(() => {
    if (selectedQr.length !== selected1?.length) {
      setAllchecked(false);
    }
    if (selectedQr.length === selected1?.length) {
      setAllchecked(true);
    }
  }, [selectedQr]);
  const selectedInvoice1 = (row, details) => {
    if (selectedRows1.includes(row)) {
      setSelectedRows1(
        selectedRows1.filter((selectedItem) => selectedItem !== row)
      );

      setSelectedQr(
        selectedQr.filter((selectedItem) => selectedItem._id != details._id)
      );
    } else {
      setSelectedRows1([...selectedRows1, row]);

      setSelectedQr([...selectedQr, details]);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {

      const newSelected = selected1?.map((n, index) => index);
      setSelectedRows(newSelected);
      setSelectedQr(selected1);
      setAllchecked(true);
    } else {
      // Deselect all rows
      setSelectedRows([]);
      setAllchecked(false);
    }
  };

  const handleSelectAllClick1 = (event) => {
    if (event.target.checked) {
      const newSelected = selected2?.map((n, index) => index);
      var inds = [];
      for (let i = 0; i < selected2.length; i++) {
        const element = selected2[i];
        inds.push(element?._id);
      }
      setSellersId(inds);
      setSelectedRows1(newSelected);
      setSelectedQr(selected2);
    } else {
      // Deselect all rows
      setSelectedRows1([]);
    }
  };

  const setIdss = async (dt) => {
    if (sellersId.includes(dt)) {
      setSellersId(sellersId.filter((selectedItem) => selectedItem != dt));
    } else if (sellersId.length == 0) {
      setSellersId([dt]);
    } else {
      setSellersId([...sellersId, dt]);
    }
  };

  const OwnerPayment = async (data) => {
    try {
      if (pass === "") {
        setpasserr("Password is Required");
      } else {
        await Axios.post(
          `/admin/adminchangeowner`,
          {
            email: email,
            phone: phone,
            password: pass,
            amount: selected?.product_id,
          },
          {
            headers: {
              Authorization: user,
            },
          }
        )
          .then(async (res) => {
            if (res?.data?.success) {
              //setStsDownloads(false)
              toast.success(res?.data?.message);
              window.location.reload();
            } else {
              //setStsDownloads(false)
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            console.log(err);
            // setStsDownloads(false)
          });
      }
    } catch (error) {
      //setStsDownloads(false)

      console.log(
        "🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:",
        error
      );
    }
  };
  const [sellss, setSellss] = useState();

  const getSellerss = async () => {
    try {
      const { data } = await Axios.post(
        "/users/get_seller",
        {},
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );
      if (data?.success == true) {
        setSellss(data?.result);
      }
    } catch (error) {
      console.log("🚀 ~ getSellerss ~ error:", error);
    }
  };

  const [ships, setShips] = useState([]);

  const getShipmentStatus = async () => {
    try {
      const { data } = await Axios.post(
        "/admin/getfeedback",
        {},
        {
          headers: { Authorization: window.localStorage.getItem("AdminToken") },
        }
      );
      if (data?.result?.length > 0) {
        setShips(data?.result);
      } else {
        setShips([]);
      }
    } catch (error) {
      console.log("🚀 ~ getShipmentStatus ~ error:", error);
    }
  };

  useEffect(() => {
    getSellerss();
    getShipmentStatus();
  }, []);

  const sendQr = async () => {
    try {
      const { data } = await Axios.post(
        "/admin/generateship",
        { prodId: sellersId, seller: selcSeller._id },
        {
          headers: { Authorization: window.localStorage.getItem("AdminToken") },
        }
      );
      if (data?.success == true) {
        toast.success("Shipment Send Successfully");
        handleCloseProductSellerCompleted();
      } else {
        toast.error("Error Sending");
      }
    } catch (error) {
      console.log("🚀 ~ sendQr ~ error:", error);
    }
  };

  if (
    role === "admin" ||
    (role === "subAdmin" && decrptedData && decrptedData[1]?.Read === true) ||
    role === "brand"
  ) {
    return (
      <>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="Admin customer address list-full-cnt dashboard-page Admin-customer-page">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.stickycls}
              >
                <Item className={classes.headercls}>
                  <Header />
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.bodyclass}>
                  <div className="togglebtn" onClick={openMenuHandler}>
                    <img src={togglebtn} alt="togglebtn" />
                  </div>
                  <div
                    className="categories-menu"
                    id={!menuOpen ? "openmenucls" : ""}
                  >
                    <div className="togglebtn-close" onClick={closeMenuHandler}>
                      <img src={Menuclose} alt="Menuclose" />
                    </div>
                    <AdminMenuSide />
                  </div>
                </Item>
              </Grid>

              <Grid container onClick={closeMenuHandler}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="padding-lr-0px-80px"
                >
                  <Item className={classes.headercls}>
                    <div className='listbody'>
                      <div className='filter-btn'>

                        <div className='filter-filed'>
                          <div className='filter-box'>
                            <CustomerFilter filterPro={filterPro} reset={reset} loc={id} setIsfilter={setIsfilter} isfilter={isfilter} setDatas={setDatas} page={page} setPage={setPage} />
                            <Modal
                              open={click}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box
                                sx={styleFilter}
                                className="AdminCustomerpopBOX Deactivate AdminAddUser "
                              >
                                <div className="menu" onClick={handleExit}>
                                  <Link>
                                    <img src={closeMenu} alt="closeMenu" />
                                  </Link>
                                </div>

                                <div className="brand">
                                  <img src={adidasLogo} alt={"adidasLogo"} />
                                </div>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <div className="addressList">
                                    <p className="reason">ADDRESS</p>

                                    <Grid
                                      container
                                      spacing={0}
                                      className={classes.containwidth}
                                      id="admin-customer-body-block"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="BRAND NAME"
                                              variant="outlined"
                                              className="textField"
                                              type="name"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="EMAIL"
                                              variant="outlined"
                                              className="textField"
                                              type="email"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="ADDRESS"
                                              variant="outlined"
                                              className="textField"
                                              type="address"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="WEB"
                                              variant="outlined"
                                              className="textField"
                                              type="email"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="CITY"
                                              variant="outlined"
                                              className="textField"
                                              type="name"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="NAME CONTACT PERSON"
                                              variant="outlined"
                                              className="textField"
                                              type="email"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="STATE"
                                              variant="outlined"
                                              className="textField"
                                              type="address"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="PHONE"
                                              variant="outlined"
                                              className="textField"
                                              type="email"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="COUNTRY"
                                              variant="outlined"
                                              className="textField"
                                              type="name"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={3}
                                        xl={3}
                                      >
                                        <Item className={classes.headercls}>
                                          <div className="cmn-input-cls">
                                            <TextField
                                              id="outlined-basic"
                                              placeholder="NAME CONTACT PERSON"
                                              variant="outlined"
                                              className="textField"
                                              type="email"
                                            />
                                          </div>
                                        </Item>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="listSec"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                  >
                                    <div className="info">
                                      <div className="Bill">
                                        <p className="reason">Billing Info</p>

                                        <div className="billingInfo">
                                          <div className="productId">
                                            <Grid
                                              container
                                              spacing={0}
                                              className={classes.containwidth}
                                              id="admin-customer-body-block-billing"
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Tax identification number"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="name"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Address Billing"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="email"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Tax Name"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="name"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Status Billing"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="email"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Country Billing"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="name"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                  >
                                    <div className="info">
                                      <div className="Bill">
                                        <p className="reason">Financial info</p>

                                        <div className="billingInfo">
                                          <div className="productId">
                                            <Grid
                                              container
                                              spacing={0}
                                              className={classes.containwidth}
                                              id="admin-customer-body-block-finance-info"
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Bank Account Number"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="name"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Bank Name"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="email"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Routing Number"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="name"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Swift"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="email"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <Item
                                                  className={classes.headercls}
                                                >
                                                  <div className="cmn-input-cls">
                                                    <TextField
                                                      id="outlined-basic"
                                                      placeholder="Other Information"
                                                      variant="outlined"
                                                      className="textField"
                                                      type="name"
                                                    />
                                                  </div>
                                                </Item>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="listTri"
                                >
                                  <div>
                                    <p className="reason">
                                      Licensing Information
                                    </p>
                                  </div>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="listTriRow"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <div className="rowOne">
                                        <Grid
                                          container
                                          spacing={0}
                                          className={classes.containwidth}
                                          id="admin-customer-body-block-license-info"
                                        >
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  licences number
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  price per licence
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  price total licenses
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  Discount applied
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  activation date
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  expiration date
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                          >
                                            <Item className={classes.headercls}>
                                              <div className="productId">
                                                <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                  className="modelTypo"
                                                >
                                                  remember date
                                                </Typography>

                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    // label="Enter Your Amount "
                                                    variant="outlined"
                                                    className="textField"
                                                  />
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <div className="rightSide">
                                        <div className="choice">
                                          <div className="warn">
                                            <div className="statusList">
                                              <Checkbox className="checkBox" />
                                              BUSINESS
                                            </div>
                                            <div className="statusList">
                                              <Checkbox className="checkBox" />
                                              FREELANCERS
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mailActive">
                                          <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                          >
                                            Brand user email seperate for ,
                                          </Typography>

                                          <div className="input-box">
                                            <TextField
                                              className="field-box"
                                              required
                                              multiline
                                              rows={5}
                                              rowsMax={10}
                                              variant="outlined"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <div className="butOutLayer">
                                  <Button className="edit">edit info</Button>
                                  <Button className="create">
                                    Create brand
                                  </Button>
                                </div>
                              </Box>
                            </Modal>
                          </div>

                          {roles === "brand" ? (
                            <div className="profile-image-outer excel-file-upload">
                              {logoerr ? (
                                <div
                                  className="h3"
                                  style={{ color: "red", textAlign: "left" }}
                                >
                                  {logoerr}
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="profile-image profile-image-design-block">
                                <form>
                                  <input
                                    type="file"
                                    accept=".csv, .xlsx, .xls"
                                    onChange={handleFileUpload}
                                    className="input-field"
                                    hidden
                                  // onChange={(e) => { createLogo(e) }}
                                  />
                                </form>
                              </div>
                              {/* <div className='edit-image-btn' onClick={() => document.querySelector(".input-field").click()}>
                                <Button variant="contained" endIcon={<BorderColorIcon />}>
                                  Upload
                                </Button>
  
                              </div> */}
                              <div className='add-seller up-btn'>
                                <div className='filter-box account'>
                                  <Button className='filter-btn button' variant="contained" onClick={() => { downloadExcelsheet() }} >
                                    Export
                                  </Button>
                                </div>

                                <div className='filter-box account'>
                                  <Button className='filter-btn button' onClick={handleOpenUploadPopUp} ><BorderColorIcon />Upload</Button>
                                </div>




                                {/* <div className="filter-box account">
                                  <Button
                                    className="filter-btn button"
                                    onClick={handleOpenUploadPopUp}
                                  >
                                    <BorderColorIcon />
                                    Upload
                                  </Button>
                                </div> */}

                                <Modal
                                  open={openUploadPopUp}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box
                                    sx={style}
                                    className="popBOX add-seller product-upload-popup"
                                  >
                                    <div
                                      className="menu"
                                      onClick={handleCloseUploadPopUp}
                                    >
                                      <Link>
                                        <img src={closeMenu} alt="closeMenu" />
                                      </Link>
                                    </div>

                                    <div className="headTitle">
                                      Create New Product
                                    </div>
                                    <div className="download">
                                      <h6>
                                        Sample Document <span> (CSV,XL) </span>{" "}
                                        to Upload the Product details
                                      </h6>

                                      <div>
                                        <p>
                                          Note:{" "}
                                          <span>
                                            The product details should be in the
                                            same format as the downloaded Csv
                                            file
                                          </span>
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          Note:{" "}
                                          <span>
                                            Product Co-ordinates Should Be
                                            Seperate Using (,)
                                          </span>
                                        </p>
                                      </div>
                                      <Button
                                        variant="contained"
                                        endIcon={<CloudDownloadIcon />}
                                        onClick={() => {
                                          downloadExcelsheet1();
                                        }}
                                      >
                                        Download
                                      </Button>
                                    </div>
                                    <div className="upload">
                                      <h6>
                                        Upload the product details here
                                        <span>(CSV,XL)</span>
                                      </h6>

                                      <div>
                                        <p>
                                          Note:
                                          <span>
                                            The product details should be in the
                                            same format as the downloaded Csv
                                            file
                                          </span>{" "}
                                        </p>
                                      </div>
                                      <Button
                                        variant="contained"
                                        endIcon={<BorderColorIcon />}
                                        onClick={() =>
                                          document
                                            .querySelector(".input-field")
                                            .click()
                                        }
                                      >
                                        Upload
                                      </Button>
                                    </div>

                                    {/* {<Modal
                open={createopen}
                onClose={createhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX add-seller">
  
                <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
  
  
                <div className="headTitle">Add Staff</div>
                  <div className="listOne">
  
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                      >
                      seller's name
                      </Typography>
  
                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Bharath"
                          className="textField"
                        />
                      </div>
                    </div>
  
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                      >
                      Seller delta
                      </Typography>
  
                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="kumar"
                          className="textField"
                        />
                      </div>
                    </div>
  
                  </div>
  
  
                  <div className="listOne">
  
                    <div className='SellerPaidTable'>
                        <Table sx={{ minWidth: 550 }} aria-label="customized table">
                              <TableHead className='SellerTableHead'>
                              <TableRow className='SellerTableRow'>
                                <StyledTableCell className="head-part-table">user </StyledTableCell>
                                <StyledTableCell className="head-part-table">password</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className='SellerTableBody'>
                            {rows.map((row) => (
                              <StyledTableRow key={row.name}>
                                <StyledTableCell className="table-value">{row.dueDate}</StyledTableCell>
                                <StyledTableCell  className="table-value">{row.service}</StyledTableCell>
  
                              </StyledTableRow>
  
                              
                            ))}
                          </TableBody>
                      </Table>
                      </div>
                  </div>
  
                  <div className="add-staff">
                    <div className='user-txt'>
                        <h4>User</h4>
                        <p>If you don't know the name of the staff, leave it blank and we will create a unique ID for that staff.</p>
                      </div>
                      
                      <div className='user-txt'>
                        <h4>Password</h4>
                        <p>If you do not know what password to assign, leave the space blank and we will assign a random password for each staff</p>
                      </div>
                    </div>
  
                <div className="filter-box account add">
                    <Button onClick={createhandleClose} className='cancel'>Cancel</Button>
                    <Button onClick={storehandleOpen}>Generate user</Button>
                </div>
  
                </Box>
              </Modal>} */}

                                    {/* {<Modal
                open={storeopen}
                onClose={storehandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX add-seller">
  
                <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
  
  
                <div className="headTitle">SELLER STORE DELTA</div>
  
                  <div className="listOne">
  
                    <div className='SellerPaidTable'>
                        <Table sx={{ minWidth: 550 }} aria-label="customized table">
                              <TableHead className='SellerTableHead'>
                              <TableRow className='SellerTableRow'>
                                <StyledTableCell className="head-part-table">staff number </StyledTableCell>
                                <StyledTableCell className="head-part-table">id user</StyledTableCell>
                                <StyledTableCell className="head-part-table">password</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className='SellerTableBody'>
                            {storerows.map((storerow) => (
                              <StyledTableRow key={storerow.name}>
                                <StyledTableCell className="table-value">{storerow.dueDate}</StyledTableCell>
                                <StyledTableCell  className="table-value">{storerow.service}</StyledTableCell>
                                <StyledTableCell  className="table-value">{storerow.password}<RefreshIcon/></StyledTableCell>
                              </StyledTableRow>
  
                              
                            ))}
                          </TableBody>
                      </Table>
                      </div>
                  </div>
  
  
                <div className="filter-box account add">
                    <Button onClick={deltahandleOpen}>send by email,the passwords to the seller</Button>
                    <Button className='cancel'>Generate all password</Button>
                </div>
  
                </Box>
              </Modal>} */}

                                    {/* {<Modal
                open={deltaopen}
                onClose={deltahandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX add-seller">
  
                <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
  
  
                <div className="headTitle">SELLER STORE DELTA</div>
  
                  <div className="message">
                      <img src={send} alt="closeMenu" />
                      <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                  </div>
  
  
                <div className="filter-box account add">
                    <Button>send by email,the passwords to the seller</Button>
                    <Button className='cancel'>Generate all password</Button>
                </div>
  
                </Box>
              </Modal>} */}
                                  </Box>
                                </Modal>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* <Button variant="contained">Export
                          <input
                            hidden={true}
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={() => { handleFileUpload() }}
  
                          />
                          <img src={Files} alt={Files} />
  
                        </Button> */}
                          {/* <input
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
  
                        /> */}
                        </div>
                      </div>
                      {roles === "admin" || roles === "brand" ? (
                        <div>
                          <AddSellersPopup sts={sts} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Item>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="padding-lr-0px-80px"
                >
                  <div className="tablebody admin-customer-table-main admin-pdts-table-main-block">
                    <Item className={classes.dashboargraph} id="earnings-sales">
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="tableScroll"
                        >
                          <TableHead>
                            <TableRow className="row-head row-head-product-admin">
                              {/* <TableCell className='head-bg'></TableCell> */}
                              <TableCell className="head-bg">
                                <Checkbox onChange={handleSelectAllClick1} />
                              </TableCell>
                              <TableCell className="head-bg">
                                product image
                              </TableCell>
                              <TableCell className="head-bg">
                                brand name
                              </TableCell>
                              <TableCell className="head-bg">
                                Category
                              </TableCell>
                              <TableCell className="head-bg">
                                product name
                              </TableCell>
                              {/* <TableCell className='head-bg'>Internal REF</TableCell> */}
                              <TableCell className="head-bg">
                                Short description
                              </TableCell>
                              <TableCell className="head-bg">
                                Initial Stock
                              </TableCell>
                              <TableCell className="head-bg">Status</TableCell>
                              <TableCell className="head-bg">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              products?.length > 0 ? (
                                products?.map((row, index) => {
                                  const isItemSelected = isSelected1(index);
                                  return (
                                    <TableRow key={index}>
                                      <TableCell
                                        className="body-bg"
                                        onClick={() => {
                                          setIdss(row?._id);
                                        }}
                                      >
                                        <Checkbox
                                          {...label}
                                          onChange={() => {
                                            selectedInvoice1(index, row);
                                          }}
                                          checked={isItemSelected}
                                        />
                                      </TableCell>

                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className="body-bg"
                                      >
                                        <div className="thumbalin">
                                          <img
                                            src={
                                              row?.product_details[0]
                                                ?.main_image
                                            }
                                            alt={"brandLogo"}
                                          />
                                        </div>
                                      </TableCell>
                                      <TableCell className="body-bg">
                                        {row?.brand_name
                                          ? row?.brand_name
                                          : "-"}
                                      </TableCell>
                                      <TableCell className="body-bg">
                                        {row?.product_details[0]
                                          ?.product_category
                                          ? row?.product_details[0]
                                            ?.product_category
                                          : "-"}
                                      </TableCell>
                                      <TableCell className="body-bg">
                                        {row?.product_details[0]?.Product_Name
                                          ? row?.product_details[0]
                                            ?.Product_Name
                                          : "-"}
                                      </TableCell>
                                      {/* <TableCell className='body-bg'>{row?.refint}</TableCell> */}
                                      <TableCell className="body-bg">
                                        {row?.product_details[0]
                                          ?.product_description
                                          ? row?.product_details[0]?.product_description
                                            ?.split(/\s+/)
                                            .slice(0, 10)
                                            .join(" ")
                                          : "-"}
                                      </TableCell>
                                      <TableCell className="body-bg">
                                        {row?.Initial_stock
                                          ? row?.Initial_stock
                                          : "-"}
                                      </TableCell>
                                      <TableCell className="body-bg">
                                        {/* {row?.status === "0" ? (
                                          <p>Pending..</p>
                                        ) : (
                                          <>
                                            {row?.status === "1" ? (
                                              <p style={{ color: "green" }}>
                                                Approved
                                              </p>
                                            ) : (
                                              <p style={{ color: "red" }}>
                                                Declined
                                              </p>
                                            )}
                                          </>
                                        )} */}
                                        {
                                          <React.Fragment>
                                            <p style={{ color: "green", margin: '0', whiteSpace: 'nowrap' }}> Approved : {row?.totalapproved} </p>
                                            <p style={{ color: "red", margin: '0', whiteSpace: 'nowrap' }}> Declined : {row?.totaldeclined} </p>
                                            <p style={{ color: "orange", margin: '0', whiteSpace: 'nowrap' }}> Pending : {row?.totalpending} </p>
                                          </React.Fragment>
                                        }
                                      </TableCell>
                                      <TableCell className="body-bg">
                                        <div className="icon-space">
                                          {/* <div>
                                {
                                  (row?.status === '1') && ((roles === 'brand') || (roles === 'admin')) ?
                                    <Button className="activate">
                                      <div onClick={() => { handleOpenAdminQRProduct(); setSelection1(row?.product_details) }} className='qrbar-code'><img src={qrbar} alt="qrbar" /></div>
                                    </Button>
                                    :
                                    <></>
                                }
                              </div> */}
                                          <Button
                                            onClick={() => {
                                              handleOpenProductInfoAdmin1();
                                              setSelection1(
                                                row,
                                                row?.product_details
                                              );
                                            }}
                                            className="activate qr-detail"
                                          >
                                            <InfoOutlinedIcon />
                                          </Button>
                                          {role === "admin" ||
                                            (role === "subAdmin" &&
                                              decrptedData &&
                                              decrptedData[1]?.Write === true) ? (
                                            <>
                                              {roles === "admin" ||
                                                roles === "subAdmin" ? (
                                                <Link
                                                  to="/products-approved"
                                                  state={{ data: row }}
                                                >
                                                  <img
                                                    src={approve}
                                                    alt={approve}
                                                  />
                                                </Link>
                                              ) : (
                                                <></>
                                              )}
                                              {roles === "admin" ||
                                                roles === "subAdmin" ? (
                                                <div className="wdwtc">
                                                  <Link
                                                    onClick={() => {
                                                      handleOpenDeclinePopUp();
                                                      setDeleteId(row);
                                                    }}
                                                  >
                                                    <img
                                                      src={decline}
                                                      alt={decline}
                                                    />
                                                  </Link>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {/* <Modal
                                open={opendecline}
                                onClose={handleClosedecline}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styledecline}>
                                  <div className='CloseOption-pop-up' onClick={handleClosedecline}><HighlightOffIcon /></div>
                                  <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                    Select the type of imported product
                                  </Typography>
                                </Box>
                              </Modal> */}

                                          {/* <Button onClick={handleOpenAdminReActive}><img src={box} alt={box} /></Button>
                  <Button onClick={handleOpenAdminPdtDeActive} className="activate"><img src={cross} alt={cross} /></Button>
                  <Button onClick={handleOpenAdminApproval} className="activate"><img src={rightState} alt={rightState} /></Button> */}
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : (
                                <TableCell
                                  colSpan={8}
                                  className="loading-center"
                                >
                                  <h3>{statussss}</h3>
                                </TableCell>
                              )
                              // <h3 style={{ textAlign: 'center' }} >{statussss}</h3>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {stsDownload ? (
                        <></>
                      ) : (
                        <div className="pagination-numbs">
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "25px",
                            }}
                          >
                            {/* <TextField className="input-txt-box" id="outlined-password-input"
                            placeholder="Email"
                            type="email"
                            value={emails1}
                            onChange={(e) => { setemailerr(); setemails1(e.target.value) }} />
                          {emailerr ? <div className='h6' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>} */}
                          </div>
                          <div className="generate-product">
                            {roles === "admin" ? (
                              <> </>
                            ) : (
                              <div className="certificate-btn qr-code">
                                <Button
                                  className="shipment-gen"
                                  onClick={handleOpenProductFeedBack}
                                >
                                  Shipment Status
                                </Button>
                              </div>
                            )}
                            {/* <div className="certificate-btn qr-code">
                            <Button onClick={() => { sendQrCode1() }}>Send QR code1</Button>
                          </div> */}
                            {roles === "admin" ? (
                              <></>
                            ) : (
                              <div className="certificate-btn qr-code">
                                <Button
                                  className="shipment-status"
                                  onClick={handleOpenGenShipStatus}
                                >
                                  Generate Shipments{" "}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Item>

                    <div className="pagination-numb">
                      {products?.length > 0 ? (
                        <Pagination
                          count={count}
                          page={page}
                          onChange={handleChange}
                          color="primary"
                          shape="rounded"
                        />
                      ) : (
                        <></>
                      )}
                      {/* <Pagination count={10} color="primary" shape="rounded" /> */}
                    </div>
                  </div>
                </Grid>

                <Modal
                  open={openAdminPdtDeActive}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={styleFilter}
                    className="AdminCustomerpopBOX Deactivate Deactivate-user-popup"
                  >
                    <div className="menu" onClick={handleCloseAdminPdtDeActive}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="brand">
                      <img src={adidasLogo} alt={"adidasLogo"} />
                    </div>

                    <div className="h2Variant">deactivate user</div>

                    <p className="reason">Reason</p>

                    <div className="listThree">
                      <div className="warn">
                        <div className="statusList">
                          <Checkbox className="checkBox" />
                          No license renewal
                        </div>
                        <div className="statusList">
                          <Checkbox className="checkBox" />
                          not satisfied with the service
                        </div>
                        <div className="statusList">
                          <Checkbox className="checkBox" />
                          temporary suspension
                        </div>
                        <div className="statusList">
                          <Checkbox className="checkBox" />
                          temporary penalty
                        </div>
                        <div className="statusList">
                          <Checkbox className="checkBox" />
                          others
                        </div>
                      </div>
                    </div>

                    <div className="input-box">
                      <TextField
                        className="field-box"
                        required
                        multiline
                        rows={5}
                        rowsMax={10}
                        width={100}
                        variant="outlined"
                      />
                    </div>
                    <div className="butOutLayer">
                      <Button className="deactive">
                        Deactivate User And All Its Products
                      </Button>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openAdminReActive}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="popBOX BlockPopup rejectpop approve"
                  >
                    <div className="menu" onClick={handleCloseAdminReActive}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="reject-info">
                      <div className="rejection-indication">
                        <p className="reject-head">REACTIVATE PRODUCT</p>
                        <p className="reason-reject approval-txt">
                          You are about to reactivate the product, this will
                          leave it in the same state it was in before it was
                          blocked.
                        </p>
                      </div>
                    </div>

                    <div className="decline-cancel-btn">
                      <div className="cancel-button">
                        <Button className="approve">Confirm</Button>
                      </div>
                    </div>
                  </Box>
                </Modal>

                {
                  <Modal
                    open={openDeclinePopUp}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX BlockPopup requpdate">
                      <div className="menu" onClick={handleCloseDeclinePopUp}>
                        <Link>
                          <img src={closeMenu} alt="closeMenu" />
                        </Link>
                      </div>

                      <div className="reject-info">
                        <div className="rejection-indication">
                          {/* <p className="reject-head">Request product</p> */}
                          {/* <p className="reason-reject">Please Inform The Brand Why You Need To Update Your Product Before Being Approved</p> */}
                          <p className="reason-reject">Please Inform The Brand Why They Need To Update Product Before Being Approved</p>
                        </div>
                      </div>

                      <div className="check-list">
                        <ul onChange={() => { setDeclineerr('') }}>
                          <li>
                            <Checkbox
                              {...label}
                              className="box-border"
                              onClick={() => {
                                setResons(
                                  "The description does not match the product image"
                                );
                              }}
                            />
                            The description does not match the product image
                          </li>
                          {/* <li>
                          <Checkbox {...label} className="box-border" onClick={() => { setResons('Product image does not match description') }} />Product image does not match description</li> */}
                          <li>
                            <Checkbox
                              {...label}
                              className="box-border"
                              onClick={() => {
                                setResons(
                                  "We have not been able to verify the authenticity of manufacture"
                                );
                              }}
                            />
                            We have not been able to verify the authenticity of
                            manufacture
                          </li>
                          <li>
                            <Checkbox
                              {...label}
                              className="box-border"
                              onClick={() => {
                                setResons("The product is duplicated");
                              }}
                            />
                            The product is duplicated
                          </li>
                          <li>
                            <Checkbox
                              {...label}
                              className="box-border"
                              onClick={() => {
                                setResons(
                                  "The product appears as the owner of another brand"
                                );
                              }}
                            />
                            The product appears as the owner of another brand
                          </li>
                        </ul>
                      </div>

                      <div className="text-box">
                        <TextField
                          className="box-size"
                          id="outlined-multiline-static"
                          multiline="multiline"
                          onChange={(e) => {
                            setReasontext(e.target.value);
                            setDeclineerr("");
                          }}
                          rows={5}
                          placeholder="Other reasons, please specify the reason"
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "red",
                        }}
                      >
                        {declineerr != "" ? <span>{declineerr}</span> : <></>}
                      </div>
                      <div className="decline-cancel-btn">
                        {/* <div className="decline-btn">
                                                            <Button className="req-up"><ReplayOutlinedIcon />Request update</Button>
                                                          </div> */}
                        {/* <span>{declineerr != '' ? }</span> */}
                        <div className="decline-cancel-btn">
                          {deleteId?.status === "2" ? (
                            <></>
                          ) : (
                            <Button
                              className="approve-tick"
                              onClick={() => {
                                DeclineProduct();
                              }}
                            >
                              Decline
                            </Button>
                          )}

                          <Button
                            className="wrong-tick"
                            onClick={handleCloseDeclinePopUp}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                }

                <Modal
                  open={openProductInfoAdmin}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup infopopup">
                    <div className="menu" onClick={handleCloseProductInfoAdmin}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="product-qr-info">
                      <div className="product-info-head">
                        <p className="product-code-head">Product info</p>
                        <p className="pro-id">
                          PRODUCT ID - {selected?.product_id}
                        </p>
                      </div>

                      <div className="info-product-scan">
                        <img src={selected?.main_image} alt="mainImage" />
                      </div>
                      <p className="lv-bag">{selected?.Product_Name}</p>
                      <div className="productinfo-img-bag">
                        {selected?.Detail1_image && (
                          <div className="info-bag">
                            <img src={selected?.Detail1_image} alt="product" />
                          </div>
                        )}
                        {selected?.Detail2_image && (
                          <div className="info-bag">
                            <img src={selected?.Detail2_image} alt="product" />
                          </div>
                        )}
                        {selected?.Detail3_image && (
                          <div className="info-bag">
                            <img src={selected?.Detail3_image} alt="product" />
                          </div>
                        )}

                        {/* <div className="info-bag">
                        <img src={QrBag} alt={QrBag} />
                      </div> */}
                      </div>

                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                          <div className="special-bag">
                            {/* <p>{selected?.Product_Name}</p> */}
                            {/* <div>Description</div> */}
                            <p className="product-info-title">Description</p>
                            {/* <p className="lv-made">Louis vuitton handbag made with cowhide</p> */}
                            <p className="cristin-para">
                              {selected?.product_description}
                            </p>
                          </div>
                          <div className="ean-info">
                            <div className="ean-cust">
                              <p className="product-info-title">EAN</p>
                              <p>{selected?.ean ? selected?.ean : "-"}</p>
                            </div>
                            <div className="ean-cust">
                              <p className="product-info-title">Ref customer</p>
                              <p>D1113s</p>
                            </div>
                            <div className="ean-cust">
                              <p className="product-info-title">Brand:</p>
                              <p>
                                {selected?.brand_name
                                  ? selected?.brand_name
                                  : "-"}{" "}
                              </p>
                            </div>
                            <div className="ean-cust">
                              <p className="product-info-title">
                                Initial stock
                              </p>
                              <p>
                                {" "}
                                {selected?.Initial_stock
                                  ? selected?.Initial_stock
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="ean-info">
                            <div className="ean-cust">
                              <p className="product-info-title">measures</p>
                              <p>
                                {selected?.Measures ? selected?.Measures : "-"}{" "}
                              </p>
                            </div>
                            <div className="ean-cust">
                              <p className="product-info-title">Material</p>
                              <p>
                                {" "}
                                {selected?.material
                                  ? selected?.material
                                  : "-"}{" "}
                              </p>
                            </div>
                            <div className="ean-cust">
                              <p className="product-info-title">
                                unit of measurement
                              </p>
                              <p>
                                {selected?.Unit_of_measurement
                                  ? selected?.Unit_of_measurement
                                  : "-"}{" "}
                              </p>
                            </div>
                          </div>

                          <div className="ean-info">
                            <div className="ean-cust">
                              <p className="product-info-title">warranty</p>
                              <p>
                                {selected?.Duration_of_the_official_warranty
                                  ? selected?.Duration_of_the_official_warranty
                                  : "-"}{" "}
                              </p>
                            </div>
                            {roles === "admin" && (
                              <div className="ean-cust">
                                <p className="product-info-title">
                                  License attachments
                                </p>
                                {/* <p> <a href={selected?.License_Attachments} > Download license</a></p> */}
                                <p>
                                  {" "}
                                  <Link
                                    to={selected?.License_Attachments}
                                    target="_blank"
                                    download
                                  >
                                    Download license
                                  </Link>
                                </p>
                              </div>
                            )}
                          </div>

                          {/* <div className="location-info">
                            <p className="product-info-title">Location</p>
                            <p>
                              Passeig de gracia 88, Barcelona, CP 08008 click to
                              see on the map
                            </p>
                          </div> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                          <div className="category-sctn info-category">
                            <div className="category-info">
                              <p className="product-client-id">
                                {/* Client ID <span> XXX11122S</span> */}
                              </p>
                            </div>
                            <div className="category-info">
                              <p className="product-client-id">
                                ID PRODUCT
                                <span>
                                  {" "}
                                  {selected?.product_id
                                    ? selected?.product_id
                                    : "-"}
                                </span>
                              </p>
                            </div>

                            <div className="category-info">
                              <p className="product-client-id">
                                Category{" "}
                                <span>
                                  {selected?.product_category
                                    ? selected?.product_category
                                    : "-"}
                                </span>
                              </p>
                            </div>
                            <div className="pend-approved-btn">
                              {selected?.status === "0" ? (
                                <div className="pending-appr-button">
                                  PENDING APROVAL
                                </div>
                              ) : (
                                <>
                                  {selected?.status === "1" ? (
                                    <div className="approve-tick">Approved</div>
                                  ) : (
                                    <>
                                      <div className="reason-info">
                                        <p className="product-client-id">
                                          Reason
                                        </p>

                                        {details?.reason?.length > 0 &&
                                          details?.reason?.map(
                                            (value, index) => (
                                              <p
                                                className="product-client-id"
                                                key={index}
                                              >
                                                <span>
                                                  {value ? value : "-"}
                                                </span>
                                              </p>
                                            )
                                          )}
                                      </div>
                                      <div className="wrong-tick">Declined</div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>

                            <div className="category-info">
                              {selected?.TxHash != "" ? (
                                <p className="product-client-id">
                                  Click Below To See Blockchain Info
                                </p>
                              ) : (
                                <p className="product-client-id">
                                  {" "}
                                  Blockchain Info Not Available In <br />{" "}
                                  Polygon Network{" "}
                                </p>
                              )}
                              <p>
                                {" "}
                                <a
                                  href={`${consts?.URL}/tx/${selected?.TxHash}`}
                                  target="_blank"
                                >
                                  {selected?.TxHash
                                    ? selected?.TxHash.slice(0, 20)
                                    : ""}{" "}
                                </a>{" "}
                              </p>
                            </div>

                            <div className="buttons-approve">
                              {/* <div>
                              <Button className="approve-tick">Approval</Button>
                            </div>
                            <div>
                              <Button className="wrong-tick">DECLINE</Button>
                            </div> */}

                              {details?.status === "0" ? (
                                <div className="pending-appr-button">
                                  PENDING APROVAL
                                </div>
                              ) : (
                                <>
                                  {selected?.status === "1" ? (
                                    <div className="approve-tick">Approved</div>
                                  ) : (
                                    <div className="wrong-tick">Declined</div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <div className="warranty-extension">
                        <div className="warranty-yrs">
                          <p className="product-info-title">Warranty:</p>
                          <p className="blue-msg">
                            {selected?.Duration_of_the_official_warranty
                              ? selected?.Duration_of_the_official_warranty
                              : "-"}{" "}
                          </p>
                        </div>
                        <div className="warranty-yrs">
                          <p className="product-info-title">Extension permit</p>
                          <p className="blue-msg">
                            {selected?.Allows_warranty_extension},{" "}
                            {selected?.Extra_warranty_time}
                          </p>
                        </div>
                        <div className="warranty-yrs">
                          <p className="product-info-title">
                            Price Extension Warranty
                          </p>
                          <p className="blue-msg">
                            {selected?.Warranty_extension_price
                              ? selected?.Warranty_extension_price
                              : "-"}{" "}
                            {selected?.Type_of_currency
                              ? selected?.Type_of_currency
                              : "-"}{" "}
                          </p>
                        </div>
                        <div className="warranty-yrs">
                          <p className="product-info-title">
                            Warranty extension applied
                          </p>
                          {selected?.Allows_warranty_extension === "yes" ||
                            selected?.Allows_warranty_extension === "Yes" ? (
                            <p className="blue-msg">
                              {/* Yes, */} {selected?.userExtenWarrenty}
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="edit-doenlod-info">
                        {roles === "brand" ? (
                          <div>
                            <AddSellersproductEdit
                              selected={selected}
                              details={details?.status}
                              handleCloseProductInfoAdmin={
                                handleCloseProductInfoAdmin
                              }
                              handleCloseProductInfoAdmin1={
                                handleCloseProductInfoAdmin1
                              }
                              getProducts={getProducts}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* {
                          details?.status != '1' ?
                            <div>
                              <AddSellersPopup2 sts={sts} selected={selected} />
                            </div>
                            : <></>
                        } */}

                        <div>
                          {stsDownload === true ? (
                            <Button className="down-info">
                              Downloading...
                            </Button>
                          ) : (
                            // <Button className="down-info" onClick={() => generatePdf(selected, details?.status)}>Download info</Button>

                            <Button
                              className="down-info"
                              onClick={() => downloadPdf(selected?.product_id)}
                            >
                              Download info
                            </Button>
                          )}
                        </div>
                      </div>

                      {/* generatePdf(selected, details?.status); */}
                    </div>
                  </Box>
                </Modal>

                <Modal
                  // open={true}
                  open={openProductInfoAdmin1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup infopopup">
                    <div
                      className="menu"
                      onClick={handleCloseProductInfoAdmin1}
                    >
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="tablebody admin-customer-table-main admin-pdts-table-main-block">
                        <div className="dashboargraph-inner-top">
                          {/* {
                          roles === 'brand' ?
                            <div className='product-allocate-btn'>
                              <FormControl sx={{ width: "auto" }}>

                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  defaultValue={age}
                                  onChange={handleChange1}
                                >
                                  {
                                    sellers?.map((item) => {
                                      return (
                                        <MenuItem value={item?._id}>{item?.firstname}</MenuItem>
                                      )
                                    })
                                  }
                                </Select>
                              </FormControl>
                              <Button onClick={() => { setSellersIds() }} >Product allocation</Button>
                            </div>
                            :
                            <></>
                        } */}

                          <Item
                            className={classes.dashboargraph}
                            id="earnings-sales"
                          >
                            <TableContainer sx={{ maxHeight: 640 }}>
                              <Table
                                aria-label="simple table"
                                className="tableScroll"
                              >
                                <TableHead>
                                  <TableRow className="row-head row-head-product-admin">
                                    <TableCell className="head-bg">
                                      {" "}
                                      <Checkbox
                                        checked={allchecked}
                                        onChange={handleSelectAllClick}
                                      />
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      product
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      Brand Name
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      Product Id
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      product name
                                    </TableCell>
                                    {/* <TableCell className='head-bg'>Internal REF</TableCell> */}
                                    <TableCell className="head-bg">
                                      Short description
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      Status
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      Sold
                                    </TableCell>
                                    <TableCell className="head-bg">
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {stsDownload ? (
                                    <div className="loaders-load">
                                      <img src={loader} />
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      {
                                        selected1?.length > 0 ? (
                                          selected1?.map((row, index) => {
                                            const isItemSelected =
                                              isSelected(index);
                                            return (
                                              <TableRow key={index}>
                                                <TableCell
                                                  className="body-bg"
                                                  onClick={() => {
                                                    setSellersId([
                                                      ...sellersId,
                                                      row?.product_id,
                                                    ]);
                                                  }}
                                                >
                                                  <Checkbox
                                                    {...label}
                                                    onChange={() => {
                                                      selectedInvoice(
                                                        index,
                                                        row
                                                      );
                                                    }}
                                                    checked={isItemSelected}
                                                  />
                                                </TableCell>

                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  className="body-bg"
                                                >
                                                  <div className="thumbalin">
                                                    <img
                                                      src={row?.main_image}
                                                      alt={"brandLogo"}
                                                    />
                                                  </div>
                                                </TableCell>
                                                <TableCell className="body-bg">
                                                  {row?.brand_name
                                                    ? row?.brand_name
                                                    : "-"}
                                                </TableCell>
                                                <TableCell className="body-bg">
                                                  {row?.product_id
                                                    ? row?.product_id
                                                    : "-"}
                                                </TableCell>
                                                <TableCell className="body-bg">
                                                  {row?.Product_Name
                                                    ? row?.Product_Name
                                                    : "-"}
                                                </TableCell>
                                                {/* <TableCell className='body-bg'>{row?.refint}</TableCell> */}

                                                <TableCell className="body-bg">
                                                  {row?.product_description
                                                    ? row?.product_description
                                                      ?.split(/\s+/)
                                                      .slice(0, 10)
                                                      .join(" ")
                                                    : "-"}
                                                </TableCell>
                                                <TableCell className="body-bg">
                                                  {row?.status === "0" ? (
                                                    <p>Pending..</p>
                                                  ) : (
                                                    <>
                                                      {row?.status === "1" ? (
                                                        <p
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          Approved
                                                        </p>
                                                      ) : (
                                                        <p
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          Blocked
                                                        </p>
                                                      )}
                                                    </>
                                                  )}
                                                </TableCell>
                                                <TableCell className="body-bg">
                                                  {row?.isSold === true
                                                    ? "Sold"
                                                    : "Not Sold"}
                                                </TableCell>
                                                <TableCell className="body-bg">
                                                  <div className="icon-space">
                                                    <Button
                                                      onClick={() => {
                                                        downloadPdf(
                                                          row?.product_id
                                                        );
                                                      }}
                                                    >
                                                      <FileDownloadOutlinedIcon />
                                                    </Button>
                                                    <Button
                                                      onClick={() => {
                                                        handleOpenProductInfoAdmin();
                                                        setSelection(row);
                                                      }}
                                                      className="activate qr-detail"
                                                    >
                                                      <InfoOutlinedIcon />
                                                    </Button>

                                                    <div>
                                                      {row?.status ===
                                                        "1" &&
                                                        (roles === "brand" ||
                                                          roles === "admin") ? (
                                                        <Button className="activate">
                                                          <div
                                                            onClick={() => {
                                                              handleOpenAdminQRProduct();
                                                              setSelection(row);
                                                            }}
                                                            className="qrbar-code"
                                                          >
                                                            <img
                                                              src={qrbar}
                                                              alt="qrbar"
                                                            />
                                                          </div>
                                                        </Button>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                    {roles === "admin" ? (
                                                      <></>
                                                    ) : (
                                                      <Button>
                                                        {row?.status === "2" ? (
                                                          <CheckCircleOutlineTwoToneIcon
                                                            onClick={() => {
                                                              handleOpen3();
                                                              setSelection(row);
                                                            }}
                                                          />
                                                        ) : (
                                                          <BlockTwoToneIcon
                                                            onClick={() => {
                                                              handleOpen2();
                                                              setSelection(row);
                                                            }}
                                                          />
                                                        )}
                                                      </Button>
                                                    )}
                                                    <Button
                                                      onClick={() => {
                                                        navigate(
                                                          "/traceability",
                                                          {
                                                            state: {
                                                              data: row,
                                                            },
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      {" "}
                                                      <AddLocationAltTwoToneIcon />
                                                    </Button>

                                                    {roles === "admin" &&
                                                      row?.isSold === true ? (
                                                      <Button
                                                        onClick={() => {
                                                          handleOpenOwnerInfoAdmin();
                                                          setSelection(row);
                                                        }}
                                                      >
                                                        <img
                                                          src={Ownership}
                                                          alt={approve}
                                                        />
                                                      </Button>
                                                    ) : (
                                                      <></>
                                                    )}

                                                    {/* {
                                    roles === 'admin' ?
                                      <Link to='/products-approved' state={{ data: row }} ><img src={approve} alt={approve} /></Link>
                                      :
                                      <></>
                                  }
                                  {
                                    (roles === 'admin') ?
                                      <div className='wdwtc'>
                                        <Link onClick={handleOpenDeclinePopUp}><img src={decline} alt={decline} /></Link>
                                      </div>
                                      :
                                      <></>
                                  } */}

                                                    {/* <Modal
                                open={opendecline}
                                onClose={handleClosedecline}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styledecline}>
                                  <div className='CloseOption-pop-up' onClick={handleClosedecline}><HighlightOffIcon /></div>
                                  <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                    Select the type of imported product
                                  </Typography>
                                </Box>
                              </Modal> */}

                                                    {/* <Button onClick={handleOpenAdminReActive}><img src={box} alt={box} /></Button>
                  <Button onClick={handleOpenAdminPdtDeActive} className="activate"><img src={cross} alt={cross} /></Button>
                  <Button onClick={handleOpenAdminApproval} className="activate"><img src={rightState} alt={rightState} /></Button> */}
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            );
                                          })
                                        ) : (
                                          <TableCell
                                            colSpan={4}
                                            className="loading-center"
                                            style={{ textAlign: "center" }}
                                          >
                                            <h3>{statussss}</h3>
                                          </TableCell>
                                        )
                                        // <h3 style={{ textAlign: 'center' }} >{statussss}</h3>
                                      }{" "}
                                    </>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {stsDownload ? (
                              <></>
                            ) : (
                              <div className="pagination-numbs">
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "25px",
                                  }}
                                >
                                  <TextField
                                    className="input-txt-box"
                                    id="outlined-password-input"
                                    placeholder="Email"
                                    type="email"
                                    value={emails1}
                                    onChange={(e) => {
                                      setemailerr();
                                      setemails1(e.target.value);
                                    }}
                                  />
                                  {emailerr ? (
                                    <div
                                      className="h6"
                                      style={{
                                        color: "red",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emailerr}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="generate-product btn-width">
                                  {/* {(roles === 'admin') ? <> </> :
                                  <div className="certificate-btn qr-code">
                                    <Button className='shipment-gen' onClick={handleOpen}>Shipment Status</Button>
                                  </div>} */}
                                  <div className="certificate-btn qr-code">
                                    {loading1 == true ? (
                                      <LoadingButton
                                        color="secondary"
                                        // onClick={handleClick}
                                        loading={loading1}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                      >
                                        <span>Sending...</span>
                                      </LoadingButton>
                                    ) : (
                                      <Button
                                        onClick={() => {
                                          downloadQrCode1();
                                        }}
                                      >
                                        Download Qr Code
                                      </Button>
                                    )}
                                  </div>
                                  <div className="certificate-btn qr-code">
                                    {loading == true ? (
                                      <LoadingButton
                                        color="secondary"
                                        // onClick={handleClick}
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                      >
                                        <span>Sending...</span>
                                      </LoadingButton>
                                    ) : (
                                      <Button
                                        onClick={() => {
                                          sendQrCode1();
                                        }}
                                      >
                                        Send QR code
                                      </Button>
                                    )}
                                  </div>
                                  {
                                    role == 'brand' ?
                                      <div className="certificate-btn qr-code">
                                        {loading2 == true ? (
                                          <LoadingButton
                                            color="secondary"
                                            // onClick={handleClick}
                                            loading={loading2}
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="contained"
                                          >
                                            <span>Blocking...</span>
                                          </LoadingButton>
                                        ) : (
                                          <Button
                                            onClick={() => {

                                              BlockMultipleItems();
                                            }}
                                          >
                                            Block Products
                                          </Button>
                                        )}
                                      </div> :
                                      <div className="certificate-btn qr-code">

                                      </div>
                                  }

                                  {/* {(roles === 'admin') ? <></> :
                                  <div className="certificate-btn qr-code">
                                    <Button className='shipment-status' onClick={handleOpenGenShipStatus}>Generate Shipment </Button>
                                  </div>} */}
                                </div>
                              </div>
                            )}
                          </Item>
                        </div>

                        <div className="pagination-numb">
                          {/* <Pagination count={count} page={page} onChange={handleChange} color="primary" shape="rounded" /> */}
                          {/* <Pagination count={10} color="primary" shape="rounded" /> */}
                        </div>
                      </div>
                    </Grid>
                  </Box>
                </Modal>
                <Modal
                  // open={true}
                  open={openProductFeedBack}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup infopopup">
                    <div className="menu" onClick={handleProductFeedBackClose}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="padding-lr-0px-80px"
                    >
                      <div className="tablebody fb admin-customer-table-main admin-pdts-table-main-block">
                        <TableContainer className="table-container-feedback">
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="tableScroll"
                          >
                            <TableHead>
                              <TableRow className="row-head row-head-product-admin">
                                <TableCell className="head-bg">
                                  product Name
                                </TableCell>
                                <TableCell className="head-bg">
                                  product img
                                </TableCell>
                                <TableCell className="head-bg">
                                  Category
                                </TableCell>
                                <TableCell className="head-bg">
                                  Seller Name
                                </TableCell>
                                <TableCell className="head-bg">
                                  Seller Id
                                </TableCell>
                                <TableCell className="head-bg">
                                  Status
                                </TableCell>
                                <TableCell className="head-bg">
                                  Description
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {ships.map((obj, ind) => {
                                return (
                                  <TableRow>
                                    <TableCell className="body-bg">
                                      {
                                        obj?.product_id?.product_details[0]
                                          ?.Product_Name
                                      }
                                    </TableCell>
                                    <TableCell className="body-bg">
                                      <img
                                        src={
                                          obj?.product_id?.product_details[0]
                                            ?.main_image
                                        }
                                        alt="c1"
                                        className="feedback-table-img"
                                      />
                                    </TableCell>
                                    <TableCell className="body-bg">
                                      {
                                        obj?.product_id?.product_details[0]
                                          ?.product_category
                                      }
                                    </TableCell>
                                    <TableCell className="body-bg">
                                      {obj?.seller_id?.firstname}
                                    </TableCell>
                                    <TableCell className="body-bg">
                                      {obj?.seller_id?.sellerId}
                                    </TableCell>
                                    <TableCell className="body-bg">
                                      {obj?.status == ""
                                        ? "Pending..."
                                        : obj?.status}
                                    </TableCell>
                                    {/* <TableCell className='body-bg'><Checkbox onClick={handleGood} />Good<div><Checkbox /> Bad</div></TableCell> */}
                                    <TableCell className="body-bg">
                                      <div className="description-div">
                                        {obj?.reason == "" ? "-" : obj?.reason}
                                      </div>{" "}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {/* 
                        <div className="feedbck-btn">
                          <Button>Submit</Button>
                        </div> */}
                      </div>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openAdminApproval}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="popBOX BlockPopup rejectpop approve"
                  >
                    <div className="menu" onClick={handleCloseAdminApproval}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="reject-info">
                      <div className="rejection-indication">
                        <p className="reject-head">Product Approval</p>
                        <p className="reason-reject approval-txt">
                          You are going to approve the products “$ product” ,
                          this action generates a new QR Code for the product,
                          which then will be recorded as a block in the
                          blockchain, are you sure of this action?
                        </p>
                      </div>
                    </div>

                    <div className="decline-cancel-btn">
                      <div className="decline-btn">
                        <Button className="cancel">Cancel</Button>
                      </div>
                      <div className="cancel-button">
                        <Button className="approve">Approval</Button>
                      </div>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup">
                    <div className="menu" onClick={handleClose2}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>
                    <div>
                      <div
                        id="alert-dialog-description"
                        className="product-head"
                      >
                        <p className="product-block">Block product</p>
                        <p className={classes.modalpara}>
                          You have selected to block these products, are you
                          sure to block the traceability and post-sale services
                          of the products?
                        </p>
                      </div>
                      <Grid container spacing={0} className="whole-grid-value">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="product-list-items">


                            <div className="list-item-name">
                              <div className="list-image">
                                <img
                                  src={selected?.main_image}
                                  alt={thumbalin}
                                />
                              </div>
                              <div className="item-name">
                                <p>{selected?.product_id}</p>
                              </div>
                            </div>
                            {/* <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div>
                                            <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div> */}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <p className={classes.reasonBlock}>
                            Select reason for blocking
                          </p>
                          <ul className="product-detail">
                            <li>
                              - The product has been stolen from its owner
                              <Checkbox
                                className="boxStyle"
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setReason([
                                      ...reason,
                                      "The product has been stolen from its owner",
                                    ]);
                                  } else {
                                    setReason(
                                      reason.filter(
                                        (item) =>
                                          item !==
                                          "The product has been stolen from its owner"
                                      )
                                    );
                                  }
                                }}
                              />
                            </li>
                            <li>
                              - Is involved in a judicial investigation
                              <Checkbox
                                className="boxStyle"
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setReason([
                                      ...reason,
                                      "Is involved in a judicial investigation",
                                    ]);
                                  } else {
                                    setReason(
                                      reason.filter(
                                        (item) =>
                                          item !==
                                          "Is involved in a judicial investigation"
                                      )
                                    );
                                  }
                                }}
                              />
                            </li>
                            <li>
                              - Other reasons (specify the reason)
                              <Checkbox
                                className="boxStyle"
                                onChange={(e) => { }}
                              />
                            </li>
                          </ul>
                          <div className="input-box2">
                            <TextField
                              className="field-box"
                              required
                              multiline
                              rows={5}
                              rowsMax={10}
                              variant="outlined"
                              onChange={(e) => {
                                onchangeother(e.target.value);
                              }}
                            />
                          </div>

                          {/* <Grid container spacing={0} className='grid-direct'>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <div className='block-product'>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <div className='disclamer-note'>
                                                    <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                </div>
                                            </Grid>
                                        </Grid> */}
                        </Grid>
                        <Grid container spacing={0} sx={{ marginTop: "20px" }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="blockpop-btn"
                          >
                            <div className="cancel-all">
                              <Button
                                className="cancel-btn"
                                onClick={() => {
                                  handleClose2();
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            {/* <div className='button-direct'>
                                            <Button className='block-btn'>Block Product</Button>

                                            <div className='disclamer-note2'>
                                                <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                            </div>

                                        </div> */}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="blockpop-btn"
                          >
                            {/* <div className='cancel-all'>
                                            <Button className='cancel-btn'>Cancel</Button>
                                        </div> */}
                            <div className="button-direct">
                              <Button
                                className="block-btn"
                                onClick={() => {
                                  blockProduct("block");
                                }}
                              >
                                Block Product
                              </Button>

                              <div className="disclamer-note2">
                                <p>
                                  Remember that this option is irreversible
                                  automatically, to reverse this situation
                                  contact{" "}
                                  <Link href="#" className="support-link">
                                    support@dtracer.io
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={open22}
                  onClose={handleClose22}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup">
                    <div className="menu" onClick={handleClose22}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>
                    <div>
                      <div
                        id="alert-dialog-description"
                        className="product-head"
                      >
                        <p className="product-block">Block product</p>
                        <p className={classes.modalpara}>
                          You have selected to block these products, are you
                          sure to block the traceability and post-sale services
                          of the products?
                        </p>
                      </div>
                      <Grid container spacing={0} className="whole-grid-value">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="product-list-items">


                            <div className="list-item-name">
                              <div className="list-image">
                                <img
                                  src={blockMultiImg}
                                  alt={thumbalin}
                                />
                              </div>
                              <div className="item-name">
                                {blockMulti?.length > 0 &&
                                  blockMulti?.map((item, index) => {
                                    return (
                                      <p>{item}</p>
                                    )
                                  })}

                              </div>
                            </div>
                            {/* <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div>
                                            <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div> */}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <p className={classes.reasonBlock}>
                            Select reason for blocking
                          </p>
                          <ul className="product-detail">
                            <li>
                              - The product has been stolen from its owner
                              <Checkbox
                                className="boxStyle"
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setReason([
                                      ...reason,
                                      "The product has been stolen from its owner",
                                    ]);
                                  } else {
                                    setReason(
                                      reason.filter(
                                        (item) =>
                                          item !==
                                          "The product has been stolen from its owner"
                                      )
                                    );
                                  }
                                }}
                              />
                            </li>
                            <li>
                              - Is involved in a judicial investigation
                              <Checkbox
                                className="boxStyle"
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setReason([
                                      ...reason,
                                      "Is involved in a judicial investigation",
                                    ]);
                                  } else {
                                    setReason(
                                      reason.filter(
                                        (item) =>
                                          item !==
                                          "Is involved in a judicial investigation"
                                      )
                                    );
                                  }
                                }}
                              />
                            </li>
                            <li>
                              - Other reasons (specify the reason)
                              <Checkbox
                                className="boxStyle"
                                onChange={(e) => { }}
                              />
                            </li>
                          </ul>
                          <div className="input-box2">
                            <TextField
                              className="field-box"
                              required
                              multiline
                              rows={5}
                              rowsMax={10}
                              variant="outlined"
                              onChange={(e) => {
                                onchangeother(e.target.value);
                              }}
                            />
                          </div>

                          {/* <Grid container spacing={0} className='grid-direct'>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <div className='block-product'>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <div className='disclamer-note'>
                                                    <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                </div>
                                            </Grid>
                                        </Grid> */}
                        </Grid>
                        <Grid container spacing={0} sx={{ marginTop: "20px" }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="blockpop-btn"
                          >
                            <div className="cancel-all">
                              <Button
                                className="cancel-btn"
                                onClick={() => {
                                  handleClose22();
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            {/* <div className='button-direct'>
                                            <Button className='block-btn'>Block Product</Button>

                                            <div className='disclamer-note2'>
                                                <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                            </div>

                                        </div> */}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="blockpop-btn"
                          >
                            {/* <div className='cancel-all'>
                                            <Button className='cancel-btn'>Cancel</Button>
                                        </div> */}
                            <div className="button-direct">
                              <Button
                                className="block-btn"
                                onClick={() => {
                                  BlockMultipleItems1("block");
                                }}
                              >
                                Block Product
                              </Button>

                              <div className="disclamer-note2">
                                <p>
                                  Remember that this option is irreversible
                                  automatically, to reverse this situation
                                  contact{" "}
                                  <Link href="#" className="support-link">
                                    support@dtracer.io
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={open3}
                  onClose={handleClose3}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup">
                    <div className="menu" onClick={handleClose3}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>
                    <div>
                      <div
                        id="alert-dialog-description"
                        className="product-head"
                      >
                        <p className="product-block">Activate Product</p>
                        {/* <p className={classes.modalpara}>You have selected to block these products, are you sure to block the traceability and post-sale services of the products?</p> */}
                      </div>
                      <Grid container spacing={0} className="whole-grid-value">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="product-list-items">
                            <div className="list-item-name">
                              <div className="list-image">
                                <img
                                  src={selected?.main_image}
                                  alt={thumbalin}
                                />
                              </div>
                              <div className="item-name">
                                <p>{selected?.product_id}</p>
                              </div>
                            </div>
                            {/* <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div>
                                            <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div> */}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <p className={classes.reasonBlock}>
                            Reason For Activate Product
                          </p>
                          {/* <ul className='product-detail'>
                                                <li>- The product has been stolen from its owner
                                                    <Checkbox className="boxStyle" onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            setReason([...reason, 'The product has been stolen from its owner'])
                                                        } else {
                                                            setReason(reason.filter(item => item !== 'The product has been stolen from its owner'))
                                                        }
                                                    }} />
                                                </li>
                                                <li>- Is involved in a judicial investigation<Checkbox className="boxStyle" onChange={(e) => {
                                              
                                                    if (e.target.checked === true) {
                                                        setReason([...reason, 'Is involved in a judicial investigation'])
                                                    } else {
                                                        setReason(reason.filter(item => item !== 'Is involved in a judicial investigation'))
                                                    }
                                                }} />
                                                </li>
                                                <li >- Other reasons (specify the reason)<Checkbox className="boxStyle" onChange={(e) => { }}

                                                />
                                                </li>
                                            </ul> */}
                          <br />
                          <div className="input-box2">
                            <TextField
                              className="field-box"
                              required
                              multiline
                              rows={5}
                              rowsMax={10}
                              variant="outlined"
                              onChange={(e) => {
                                onchangeother(e.target.value);
                              }}
                            />
                          </div>

                          {/* <Grid container spacing={0} className='grid-direct'>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <div className='block-product'>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <div className='disclamer-note'>
                                                    <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                </div>
                                            </Grid>
                                        </Grid> */}
                        </Grid>
                        <Grid container spacing={0} sx={{ marginTop: "20px" }}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="blockpop-btn"
                          >
                            <div className="cancel-all">
                              <Button
                                className="cancel-btn"
                                onClick={() => {
                                  handleClose2();
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            {/* <div className='button-direct'>
                                            <Button className='block-btn'>Block Product</Button>

                                            <div className='disclamer-note2'>
                                                <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                            </div>

                                        </div> */}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="blockpop-btn"
                          >
                            {/* <div className='cancel-all'>
                                            <Button className='cancel-btn'>Cancel</Button>
                                        </div> */}
                            <div className="button-direct">
                              <Button
                                className="Active-btn"
                                style={{
                                  backgroundColor: "#1bf61b",
                                  color: "black",
                                }}
                                onClick={() => {
                                  blockProduct("activate");
                                }}
                              >
                                Activate Product
                              </Button>

                              <div className="disclamer-note2">
                                <p>
                                  Remember that this option is irreversible
                                  automatically, to reverse this situation
                                  contact{" "}
                                  <Link href="#" className="support-link">
                                    support@dtracer.io
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openAdminQRProduct}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup qrproduct-pop">
                    <div className="menu" onClick={handleCloseAdminQRProduct}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="product-qr-info">
                      <div className="product-info-head">
                        <p className="product-code-head">QR product info</p>
                        <p className="pro-id">
                          PRODUCT ID {selected?.product_id}
                        </p>
                      </div>

                      <div className="qr-product-scan" ref={refs}>
                        {/* <QRCode
                        title="Dtracer"
                        value={selected?.qr_link}
                      // bgColor={back}
                      // fgColor={fore}
                      // size={size === '' ? 0 : size}
                      /> */}
                        <img src={selected?.qr_link} alt={"qr"} />

                        {/* <QRCode value="https://testing.dtracer.io/" /> */}
                      </div>

                      <div className="product-img-bag">
                        <div className="info-bag">
                          <img src={selected?.main_image} alt={QrBag} />
                        </div>
                        {selected?.Detail1_image ? (
                          <div className="info-bag">
                            <img src={selected?.Detail1_image} alt={QrBag} />
                          </div>
                        ) : (
                          <></>
                        )}
                        {selected?.Detail2_image ? (
                          <div className="info-bag">
                            <img src={selected?.Detail2_image} alt={QrBag} />
                          </div>
                        ) : (
                          <></>
                        )}
                        {selected?.Detail3_image ? (
                          <div className="info-bag">
                            <img src={selected?.Detail3_image} alt={QrBag} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="category-sctn">
                        <div className="category-info">
                          <p className="product-client-id">
                            {/* Client ID <span> XXX11122S</span> */}
                          </p>
                        </div>
                        <div className="category-info">
                          <p className="product-client-id">
                            ID PRODUCT
                            <span>
                              {" "}
                              {selected?.product_id
                                ? selected?.product_id
                                : "-"}
                            </span>
                          </p>
                        </div>

                        <div className="category-info">
                          <p className="product-client-id">
                            Category{" "}
                            <span>
                              {" "}
                              {
                                selected?.product_category ? selected
                                  ?.product_category : "-"
                              }
                            </span>
                          </p>
                        </div>

                        <div className="approved-btn">
                          {selected?.status === "0" ? (
                            <Button className="approve-button">Pending</Button>
                          ) : (
                            <>
                              {selected?.status === "1" ? (
                                <Button className="approve-button">
                                  Approved
                                </Button>
                              ) : (
                                <Button className="approve-button">
                                  Blocked
                                </Button>
                              )}
                            </>
                          )}
                        </div>

                        <div className="category-info">
                          <p className="product-client-id">
                            Blockchain info{" "}
                            <Link
                              to={`${consts?.URL}/tx/${selected?.TxHash}`}
                              target="_blank"
                              className="url-link"
                            >
                              url link
                            </Link>
                          </p>
                        </div>
                      </div>

                      {role == "brand" && (
                        <div className="generate-btns">
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "16px",
                            }}
                          >
                            <TextField
                              className="input-txt-box"
                              id="outlined-password-input"
                              placeholder="Email"
                              type="email"
                              value={emails1}
                              onChange={(e) => {
                                setemailerr1();
                                setemails1(e.target.value);
                              }}
                            />
                            {emailerr1 ? (
                              <div
                                className="h6"
                                style={{ color: "red", textAlign: "center" }}
                              >
                                {emailerr1}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="certificate-btn qr-code">
                            <Button
                              onClick={() => {
                                sendQrCode(selected?.qr_link);
                              }}
                            >
                              Send QR code
                            </Button>
                          </div>
                          <div className="certificate-btn print-btns">
                            <ReactToPrint
                              bodyClass="print-label"
                              content={() => refs.current}
                              trigger={() => <Button>Print</Button>}
                            />
                          </div>
                        </div>
                      )}
                      {role == "admin" || role == "subAdmin" ? (
                        <>
                          <div className="action-part">
                            <p className="action-head">ACTION</p>
                            <p className="action-part-cnt">
                              With this action you can generate a certificate of
                              authenticity and send it to the client who has
                              requested it,
                            </p>
                            <p className="action-info">
                              Warning: Do not generate any certificate without
                              having received the payment for it
                            </p>
                          </div>

                          <div className="generate-btns">
                            <div className="certificate-btn">
                              <Button
                                onClick={() => {
                                  downloadPdfCertificate(selected?.product_id);
                                }}
                              >
                                GENEREATE CERTIFICATE
                              </Button>
                            </div>
                            <div className="certificate-btn qr-code">
                              <Button>Send QR code</Button>
                            </div>
                            <div className="certificate-btn print-btns">
                              <Button>Print</Button>
                            </div>
                          </div>

                          <div className="email-input-box">
                            <div className="email-certificate">
                              <div className="certificate-box">
                                <p className="email-send">
                                  Enter email where the certificate will be sent
                                </p>
                              </div>
                              <div className="input-email">
                                <TextField
                                  className="input-txt-box"
                                  id="outlined-password-input"
                                  placeholder="Email"
                                  type="email"
                                  value={emails}
                                  onChange={(e) => setemails(e.target.value)}
                                />
                                <div className="default-acct">
                                  <p className="default-para">
                                    Use default account email
                                  </p>
                                  <Checkbox
                                    {...label}
                                    value={isSend}
                                    onChange={() => {
                                      setIssend(!isSend);
                                    }}
                                  />
                                </div>
                                <div className="approved-btn">
                                  {stsDownloads === true ? (
                                    <Button className="down-info">
                                      Sending...
                                    </Button>
                                  ) : (
                                    // <Button className="down-info" onClick={() => generatePdf(selected, details?.status)}>Download info</Button>

                                    <Button
                                      className="approve-button"
                                      onClick={() => {
                                        sendPdfCertificate(
                                          selected?.product_id
                                        );
                                      }}
                                    >
                                      Send certificate
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="successfull-certificate">
                            <div className="thumbs-up">
                              <img src={Thumbup} alt={Thumbup} />
                            </div>

                            <p className="success-msg">
                              Certificate successfully sent to applicant
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openOwnerInfoAdmin}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup infopopup">
                    <div className="menu" onClick={handleCloseOwnerInfoAdmin}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="extented-warrenty">
                      <div className="warrenty-body admin-owner">
                        <div className="watch-div">
                          <img src={selected?.main_image} alt="watch" />
                        </div>
                        <div className="watch-id">
                          <div className="watch-id-text">
                            {selected?.Product_Name} ID {selected?.product_id}
                          </div>
                          {/* <div className='watch-description'>
                                        Test product description, this is a demonstration of the description of a product where we inform about the characteristics of the product
                                    </div>
                                    <div className='map-component'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.42095469063!2d78.1227348!3d9.94600025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1699970698829!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div> */}
                          {/* <div className='Guarantee'>
                                        Guarantee activated on 01/01/2030 Valid until 01/01/2032
                                    </div>
                                    <div className='warrenty-records1'>
                                        Run don't lose it! The time allowed by the manufacturer to extend the warranty runs out,
                                        <p className='para-warrenty'>you have <span>3</span> days left</p>
                                    </div> */}

                          <div className="owner-text">
                            <div>OWNER :</div>
                            <div className="smith">john smith</div>
                          </div>
                          <div className="owner-form">
                            <div className="new-owner">NEW OWNER</div>
                            <div>
                              <TextField
                                id="outlined-basic1"
                                placeholder="Name"
                                name="name"
                                variant="outlined"
                                onChange={(e) => {
                                  setname(e.target.value);
                                  setnameerr(null);
                                }}
                              />
                            </div>
                            {nameerr !== null ? (
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: "10px",
                                }}
                              >
                                {nameerr}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <TextField
                                id="outlined-basic1"
                                type="Email"
                                placeholder="Email"
                                name="email"
                                variant="outlined"
                                onChange={(e) => {
                                  setemail(e.target.value);
                                  setemailerr(null);
                                }}
                              />
                            </div>
                            {emailerr !== null ? (
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: "10px",
                                }}
                              >
                                {emailerr}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div>
                              <TextField
                                id="outlined-basic1"
                                type="Number"
                                placeholder="Phone"
                                name="phone"
                                variant="outlined"
                                onChange={(e) => {
                                  setphone(e.target.value);
                                  setphoneerr(null);
                                }}
                              />
                            </div>
                            {phoneerr !== null ? (
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: "10px",
                                }}
                              >
                                {phoneerr}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="warrenty-main-display">
                            <div className="watch-div">
                              {" "}
                              <img src={selected?.main_image} alt="watch" />
                            </div>

                            <div className="warrenty-records1 br">
                              Change Owner
                            </div>
                            <div>
                              <div className="for-only-65">
                                <div>for only</div>
                                <span>${selected?.Change_Owner_price} </span>
                              </div>
                            </div>
                          </div>
                          <div className="proceed2-btn">
                            {/* <Link to='/user/entercode'><Button className='ownership-btn' ><img src={home} alt="homr" />proceed with the change of owner</Button></Link> */}
                            <Button
                              className="ownership-btn"
                              onClick={() => {
                                handleOpenOwnerProceedInfo();
                                setSelection(selected);
                              }}
                            >
                              <img src={home} alt="homr" />
                              proceed with the change of owner
                            </Button>
                          </div>
                          {/* 
                                    <div className='trns-details'>
                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr className='warrenty-hr' />

                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>




                                    </div> */}
                          <div className="user-logo2">
                            <img src={guard} alt="lg-1" />
                            <span className="go-green">100%</span>{" "}
                            <span>SECURE PAYMENTS</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openOwnerProceedInfo}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="popBOX BlockPopup infopopup">
                    <div className="menu" onClick={handleCloseOwnerProceedInfo}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="extented-warrenty">
                      <div className="warrenty-body admin-owner">
                        <div className="watch-div">
                          <img src={selected?.main_image} alt="watch" />
                        </div>
                        <div className="watch-id">
                          <div className="watch-id-text">
                            {selected?.Product_Name} ID {selected?.product_id}
                          </div>
                          {/* <div className='watch-description'>
            Test product description, this is a demonstration of the description of a product where we inform about the characteristics of the product
        </div>
        <div className='map-component'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.42095469063!2d78.1227348!3d9.94600025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1699970698829!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div> */}
                          {/* <div className='Guarantee'>
            Guarantee activated on 01/01/2030 Valid until 01/01/2032
        </div>
        <div className='warrenty-records1'>
            Run don't lose it! The time allowed by the manufacturer to extend the warranty runs out,
            <p className='para-warrenty'>you have <span>3</span> days left</p>
        </div> */}

                          {/* <div className='owner-text'>
            <div>
                OWNER :
            </div>
            <div className='smith'>
                john smith
            </div>
        </div> */}
                          <div className="owner-form">
                            <div>
                              <TextField
                                id="outlined-basic1"
                                label="Password"
                                variant="outlined"
                                name="password"
                                onChange={(e) => {
                                  setpass(e.target.value);
                                  setpasserr(null);
                                }}
                              />
                            </div>
                            {passerr !== null ? (
                              <div
                                style={{
                                  color: "red",
                                  textAlign: "center",
                                  fontSize: "10px",
                                }}
                              >
                                {passerr}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="Guarantee3">
                            <div className="securidy-code-text">
                              THIS ACTION GENERATES A NEW BLOCK IN THE
                              BLOCKCHAIN NETWORK.INFORMING THE NEW HOLDER OF THE{" "}
                              <span className="go-green">
                                PRODUCT ID {selected?.product_id},
                              </span>{" "}
                              IT IS NOT REVERSIBLE, BEFORE CONTINUING CARRYING
                              OUT THE NECESSARY CHECKS,WE WILL COMMUNICATE VIA
                              EMAIL WITH THE NEW HOLDER TO REQUEST HIS NEW
                              SECURITY KEY
                            </div>
                          </div>

                          <div className="warrenty-main-display">
                            <div className="watch-div">
                              {" "}
                              <img src={selected?.main_image} alt="watch" />
                            </div>

                            <div className="warrenty-records1 br">
                              Change Owner
                            </div>
                            <div>
                              <div className="for-only-65">
                                <div>for only</div>
                                <span>${selected?.Change_Owner_price} </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            {/* <Link to='/user/purchasesuccess'><Button className='proceed-btn' ><img src={proceed} alt="homr" />proceed</Button></Link> */}
                            {/* <Button className='proceed-btn' onClick={() => { navigate('/user/purchasesuccess', { state: { data: datas } }) }} ><img src={proceed} alt="homr" />proceed</Button> */}
                            <Button
                              className="proceed-btn"
                              onClick={() => {
                                OwnerPayment(
                                  datas?.product_details?.product_id
                                );
                              }}
                            >
                              <img src={proceed} alt="homr" />
                              proceedss
                            </Button>
                          </div>
                          {/* 
        <div className='trns-details'>
            <div className='trns-body'>
                <div className='warrenty-display'>
                    <div className='avatar-div'>
                        <Avatar>N</Avatar>
                    </div>
                    <div className='reference-body'>
                        <div className='ref-text'>
                            0x5fhheij556dvjhbrfb..
                        </div>
                        <div className='ref-text2'>
                            26 sec ago
                        </div>
                    </div>

                </div>
                <div className='warrenty-display'>
                    <div className='avatar-div'>
                        <Avatar>N</Avatar>
                    </div>
                    <div className='reference-body'>
                        <div className='ref-text'>
                            0x5fhheij556dvjhbrfb..
                        </div>
                        <div className='ref-text2'>
                            26 sec ago
                        </div>
                    </div>

                </div>
            </div>

            <hr className='warrenty-hr' />

            <div className='trns-body'>
                <div className='warrenty-display'>
                    <div className='avatar-div'>
                        <Avatar>N</Avatar>
                    </div>
                    <div className='reference-body'>
                        <div className='ref-text'>
                            0x5fhheij556dvjhbrfb..
                        </div>
                        <div className='ref-text2'>
                            26 sec ago
                        </div>
                    </div>

                </div>
                <div className='warrenty-display'>
                    <div className='avatar-div'>
                        <Avatar>N</Avatar>
                    </div>
                    <div className='reference-body'>
                        <div className='ref-text'>
                            0x5fhheij556dvjhbrfb..
                        </div>
                        <div className='ref-text2'>
                            26 sec ago
                        </div>
                    </div>

                </div>
            </div>




        </div> */}
                          <div className="user-logo2">
                            <img src={guard} alt="lg-1" />
                            <span className="go-green">100%</span>{" "}
                            <span>SECURE PAYMENTS</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={openGenShipStatus}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="popBOX BlockPopup infopopup seller-popp pppp"
                  >
                    {/* 
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}> */}
                    <div className="menu" onClick={handleCloseGenShipStatus}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="product-info-head">
                      <p className="pro-id">
                        These are the products selected in this shipment
                      </p>
                    </div>

                    <div className="genship-popup">
                      <TableContainer sx={{ width: "80%", margin: "0 auto" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className="head-bg">Product</TableCell>
                              <TableCell className="head-bg">
                                Thumbnail
                              </TableCell>
                              <TableCell className="head-bg">
                                Product Name
                              </TableCell>
                              <TableCell className="head-bg">
                                Category
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ship.map((value, index) => (
                              <TableRow>
                                <TableCell>{value?.id}</TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="body-bg"
                                >
                                  <div className="list-image">
                                    <img
                                      src={value?.Detail1_image}
                                      alt={thumbalin}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>{value?.Product_Name}</TableCell>
                                <TableCell>{value?.product_category}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    <div className="qr-product-scan genship">
                      {/* <QRCode
                        title="Dtracer"
                        value={qrs}

                      // bgColor={back}
                      // fgColor={fore}
                      // size={size === '' ? 0 : size}
                      /> */}
                      <img src={qrs} alt={"qr"} />
                    </div>

                    <div className="action-part genship">
                      <p className="action-part-cnt">
                        The authorized seller will have to indicate the status
                        of receipt of the merchandise and it will automatically
                        be recorded which authorized seller has received the
                        merchandise and the date and time.
                      </p>
                    </div>

                    <div className="generate-btns genship">
                      <div className="certificate-btn">
                        <Button onClick={handleOpenProductSeller}>
                          Send the QR code to the seller
                        </Button>
                      </div>
                    </div>

                    {/* </Box> */}
                  </Box>
                </Modal>

                <Modal
                  open={openProductSeller}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="popBOX BlockPopup infopopup seller-popp pppp"
                  >
                    {/* 
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}> */}
                    <div className="menu" onClick={handleCloseProductSeller}>
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="product-info-head">
                      <p className="pro-id">
                        Select the authorized seller that you are going to send
                        this bundle to
                      </p>
                    </div>
                    <div className="qr-product-scan genship">
                      {/* <QRCode
                        title="Dtracer"
                        value={qrs}

                      // bgColor={back}
                      // fgColor={fore}
                      // size={size === '' ? 0 : size}
                      /> */}
                      <img src={qrs} alt={"qr"} />
                    </div>

                    <div className="product-info-head">
                      <p className="pro-id">Authorized Seller</p>
                    </div>

                    <div className="auth-seller-flex">
                      <div className="bundle-seller">
                        <p class="action-part-cnt">Authorized seller </p>

                        {sellss?.length > 0 &&
                          sellss?.map((data, index) => {
                            return (
                              <p key={index}>
                                <Checkbox
                                  {...label}
                                  onChange={() => {
                                    setSelcSeller(data);
                                    setIndexSelc(index);
                                  }}
                                  checked={index == indexSelc}
                                />
                                <label>{data?.firstname}</label>
                              </p>
                            );
                          })}
                      </div>

                      <div className="start-end-date start-end-date-admin estimated date-product-admin">
                        <p class="action-part-cnt bundle">
                          Select estimated shipping date of the lot{" "}
                        </p>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              "DatePicker",
                              "DateTimePicker",
                              "TimePicker",
                              "DateRangePicker",
                            ]}
                          >
                            <DemoItem>
                              <DatePicker
                                value={selectedDate}
                                onChange={(newDate) =>
                                  setSelectedDate(dayjs(newDate))
                                }
                                views={["year", "month", "day"]}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="action-part genship">
                      <p className="action-info">
                        ATTENTION: This QR code is the one that identifies the
                        lot with all the products, so it must be visible from
                        the outside so that once the merchandise is received,
                        the status of arrival and date of arrival can be
                        reported.
                      </p>
                    </div>

                    <div className="generate-btns authseller">
                      <div className="certificate-btn">
                        <Button onClick={handleOpenProductSellerCompleted}>
                          Complete shipment
                        </Button>
                      </div>
                    </div>

                    {/* </Box> */}
                  </Box>
                </Modal>

                <Modal
                  open={openProductSellerCompleted}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >

                  <Box sx={style} className="popBOX BlockPopup infopopup seller-popp pppp">
                    {/* 
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}> */}
                    <div
                      className="menu"
                      onClick={handleCloseProductSellerCompleted}
                    >
                      <Link>
                        <img src={closeMenu} alt="closeMenu" />
                      </Link>
                    </div>

                    <div className="product-info-head">
                      <p className="pro-id complet">
                        Process completed, please print this QR code and add it
                        to the outside of the shipping package in a visible or
                        easily accessible part for authorized sellers.
                      </p>
                    </div>

                    <div className="qr-product-scan genship" ref={ref}>
                      {/* <QRCode
                        title="Dtracer"
                        value={qrs}

                      // bgColor={back}
                      // fgColor={fore}
                      // size={size === '' ? 0 : size}
                      /> */}
                      <img src={qrs} alt={"qr"} />
                    </div>

                    <p class="action-part-cnt bundle">Summary </p>

                    <div className="auth-seller-flex">
                      <div className="">
                        <span class="action-part-cnt bundle">SELLER NAME </span>
                        <p>{selcSeller?.firstname}</p>
                        <span class="action-part-cnt bundle">
                          SELLER STORE ID{" "}
                        </span>
                        <p>{selcSeller?.sellerId}</p>
                      </div>

                      <div className="">
                        <p class="action-part-cnt bundle">Departure date </p>
                        <p class="action-part-cnt bundle">{date} </p>
                      </div>
                    </div>

                    <div className="action-part genship">
                      <p className="action-info">
                        ATTENTION: This QR code is the one that identifies the
                        lot with all the products, so it must be visible from
                        the outside so that once the merchandise is received,
                        the status of arrival and date of arrival can be
                        reported.
                      </p>
                    </div>

                    <div className="generate-product process-complete">
                      <div className="certificate-btn qr-code">
                        <ReactToPrint
                          bodyClass="print-label"
                          content={() => ref.current}
                          trigger={() => (
                            <Button className="print-lab-first">
                              Print label
                            </Button>
                          )}
                        />
                      </div>
                      <div className="certificate-btn qr-code">
                        <Button
                          className="print-lab-sec"
                          onClick={() => {
                            sendQr();
                          }}
                        >
                          Send the QR code to the seller
                        </Button>
                      </div>
                      <div className="certificate-btn qr-code">
                        <Button
                          className="print-lab-third"
                          onClick={() => {
                            downloadImage();
                          }}
                        >
                          Download QR code
                        </Button>
                      </div>
                    </div>

                    {/* </Box> */}
                  </Box>
                </Modal>

                {datas.length > 0 && (
                  <Dialog
                    fullScreen={fullScreen}
                    open={opens}
                    onClose={handleCloses}
                    aria-labelledby="responsive-dialog-title"
                    className='upload_producr_details'
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {"Products"}
                    </DialogTitle>
                    <DialogContent>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {Object.keys(datas[0])?.map((key) => (
                                <TableCell key={key}>{key}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {datas.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                {Object.values(row).map((value, index) => (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    key={index}
                                  >
                                    {value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </DialogContent>
                    <DialogActions className="two-btn">
                      <Button
                        autoFocus
                        onClick={handleCloses}
                        className="cancel"
                      >
                        Cancel
                      </Button>
                      {stscheck === false ? (
                        <Button
                          onClick={() => {
                            setStsCheck(true);
                            uploadProduct();
                          }}
                          autoFocus
                          disabled={stscheck}
                          className="add-btn"
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          autoFocus
                          disabled={stscheck}
                          className="add-btn"
                        >
                          Add
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.footercls}>
                  <Footer />
                </Item>
              </Grid>
            </Grid>
          </Box>
        </div>
      </>
    );
  } else {
    const navigateTo = () => {
      if (
        datas?.product_details?.Change_Owner_price == undefined ||
        datas?.product_details?.Change_Owner_price == "0"
      ) {
        toast.error("Change Owner is Not Enabled");
      } else {
        let re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let reg = /^[0-9]+$/;

        //setStsDownloads(true)
        if (name === "") {
          setnameerr("name Required");
        } else if (email === "") {
          setemailerr("Email Required");
        } else if (!re.test(email)) {
          setemailerr("Please Enter valid Email");
        } else if (phone === "") {
          setphoneerr("Please Enter Number");
        } else if (!reg.test(phone)) {
          setphoneerr("Please Enter valid Number");
        } else {
          const details = {
            name: name,
            email: email,
            phone: phone,
          };
          navigate("/user/entercode", {
            state: { data: datas, detail: details },
          });
        }
      }
    };

    const OwnerPayment = async (data) => {
      try {
        if (pass === "") {
          setpasserr("Password is Required");
        } else {
          await Axios.post(
            `/admin/ownerpayment`,
            {
              product_id: data,
              email: details.email,
              phone: details.phone,
              password: pass,
              amount: datas?.product_details?.Change_Owner_price,
            },
            {
              headers: {
                Authorization: user,
              },
            }
          )
            .then(async (res) => {
              if (res?.data?.success) {
                //setStsDownloads(false)
                window.location.href = res?.data?.result?.url;
                //toast.success(res?.data?.message)
              } else {
                //setStsDownloads(false)
                toast.error(res?.data?.message);
              }
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
              // setStsDownloads(false)
            });
        }
      } catch (error) {
        //setStsDownloads(false)

        console.log(
          "🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:",
          error
        );
      }
    };

    return (
      <div className="Admin customer address list-full-cnt dashboard-page Admin-customer-page">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.bodyclass}>
                <div className="togglebtn" onClick={openMenuHandler}>
                  <img src={togglebtn} alt="togglebtn" />
                </div>
                <div
                  className="categories-menu"
                  id={!menuOpen ? "openmenucls" : ""}
                >
                  <div className="togglebtn-close" onClick={closeMenuHandler}>
                    <img src={Menuclose} alt="Menuclose" />
                  </div>
                  <AdminMenuSide />
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="premission-access">
                You Dont Have a Permission to View Products
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
};

export default AdminProducts;
