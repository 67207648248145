// import React from 'react'
// import ReactApexChart from "react-apexcharts";

// class DailyCost extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {

//       series: [{
//           name: "Blockchain Transactions Daily Cost",
//           data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 10, 41, 35,]
//       }],
//       options: {
//         chart: {
//           height: 350,
//           type: 'area',
//           zoom: {
//             enabled: false
//           },
//           toolbar: {
//             show: false,
//         },
//         },
//         dataLabels: {
//           enabled: false
//         },
//         stroke: {
//           curve: 'smooth'
//         },
//         grid: {
//           row: {
//             colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
//             opacity: 0.5
//           },
//         },
//         xaxis: {
//           categories: ['01:30', '01:35', '02:30', '02:00', '01:30', '03:30', '05:30', '07:30', '09:30',
//           '10:30', '01:30', '06:30'
//         ]
//         }
//       },


//     };
//   }


//     render() {
//       return (


// <div id="chart">
//   <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={200} />
// </div>


//       );
//     }
//   }

//   export default DailyCost;

import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const DailyCost = ({ cost, costTime }) => {

  const [state, setState] = useState(
    {
      series: [{
        name: "Blockchain Transactions Daily Cost",
        data: cost
      }],
      options: {
        chart: {
          id: "Daily_Cost",

          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: costTime
        }
      },


    }
  )

  useEffect(() => {
    setState({
      series: [{
        name: "Blockchain Transactions Daily Cost",
        data: cost
      }],
      options: {
        chart: {
          id: "Daily_Cost",
          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          },
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: costTime
        }
      },


    })
  }, [cost, costTime])
  return (


    <div id="chart">
      <ReactApexChart options={state.options} series={state.series} type="area" height={200} />
    </div>


  )
}

export default DailyCost;