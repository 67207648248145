import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Userdashboard.css"
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppsIcon from "@mui/icons-material/Apps";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import GridViewIcon from "@mui/icons-material/GridView";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from "@mui/material/Checkbox";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import toast from "react-hot-toast";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function UserDashboardBody({ nftdat }) {

  const [addCart, setaddCart] = useState(false);
  const handleAddCart = () => {
    setaddCart(!addCart);
    if (!addCart) {
      toast.success("Add to cart");
    } else {
      toast.success("Remove from cart");
    }
  };

  const navigate = useNavigate()
  const [tiles11, setTiles11] = useState(false);
  const [tiles22, setTiles22] = useState(false);
  const [tiles33, setTiles33] = useState(true);
  const [tiles44, setTiles44] = useState(false);


  const handleTileOne = () => {
    setTiles11(true);
    setTiles22(false);
    setTiles33(false);
    setTiles44(false);
  };

  const handleTileTwo = () => {
    setTiles11(false);
    setTiles22(true);
    setTiles33(false);
    setTiles44(false)
  };

  const handleTileThree = () => {
    setTiles11(false);
    setTiles22(false);
    setTiles33(true);
    setTiles44(false)
  };

  const handleTileFour = () => {
    setTiles11(false);
    setTiles22(false);
    setTiles33(false);
    setTiles44(true)
  };

  const LightTooltipImage = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      className="CurrentPrice-tabe-pop"
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[6],
      borderRadius: 5,
      fontSize: 12,
      padding: 2,
      width: "100%",
    },
  }));

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="SubPages">
            <div className="SubPages-inner-text">
              {/* <div className="btn-1">
              <Button>Collected</Button>
            </div> */}
              {/* <div>
                <Button>Offers Made</Button>
            </div>
            <div>
                <Button>Deals</Button>
            </div>
            <div>
                <Button>Created</Button>
           
            </div> */}
              {/* <div>
              <Button>Favourite</Button>

            </div> */}
              {/* <div>
                <Button>Activity</Button>
           
            </div> */}
              {/* <div >
                    <Button>More
                       
                    </Button>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                    >

                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Twenty</MenuItem>
                        <MenuItem value={21}>Twenty one</MenuItem>
                        <MenuItem value={22}>Twenty one and a half</MenuItem>
                    </Select> 

           
            </div> */}
            </div>
            {/* <hr /> */}

            <Grid container spacing={0} className="display-set">
              {/* <Grid xs={12} sm={12} md={12} lg={1} xl={1} >
              <div className="svg-icon" onClick={handleTileFive}>
                <FilterListIcon />
              </div>

            </Grid> */}

              {/* <Grid xs={12} sm={6} md={6} lg={2} xl={1} className="display-set1">
              <FormControl sx={{ m: 1, minWidth: 100 }} size="small">

                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  defaultValue={10}
                  className="select-div"
                  onChange={handleChange}

                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={2} xl={1} className="display-set1">
              <FormControl sx={{ m: 1, minWidth: 100 }} size="small">

                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  defaultValue={10}
                  className="select-div"
                  onChange={handleChange}

                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

              <Grid xs={12} sm={6} md={3} lg={3} xl={3}>
                {/* <div className="priceList">
                <FormControl sx={{ m: 1, minWidth: 150 }} size="small" className="simple-select-form">

                  <Select
                    labelId="demo-simple-select-label"
                    defaultValue={10}
                    id="demo-simple-select"
                    onChange={handleRecentChange}
                  >
                    <MenuItem value={10}> Recently Received</MenuItem>
                    <MenuItem value={30}>Price high to low</MenuItem>
                    <MenuItem value={20}>Price low to high</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              </Grid>


              <Grid xs={12} sm={6} md={6} lg={6} xl={6} className="search-bar">
                {/* <Search className="search-box-filter">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search by name"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
              </Grid>

              <Grid xs={12} sm={12} md={3} lg={3} xl={3}>

                <div className='tiles-block'>
                  {/* <div className={tiles11 ? 'tile-design active-tiles': 'tile-design'}><ViewListIcon /></div> */}
                  <div onClick={handleTileFour} className={tiles44 ? 'tile-design active-tiles' : 'tile-design'}>    <ViewListIcon /></div>
                  <div onClick={handleTileOne} className={tiles11 ? 'tile-design active-tiles' : 'tile-design'}> <GridViewIcon /></div>
                  <div onClick={handleTileTwo} className={tiles22 ? 'tile-design active-tiles' : 'tile-design'}> <AppsIcon /></div>
                  <div onClick={handleTileThree} className={tiles33 ? 'tile-design active-tiles' : 'tile-design'}><ViewQuiltIcon /></div>

                </div>

              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="body-text-div">
                  Dashboard
                </div>
              </Grid>

              {tiles44 ?
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableContainer sx={{ maxHeight: 450 }} className="tooltip-box">
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Id</TableCell>
                            <TableCell>Brand Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Warranty</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {
                            nftdat?.map((item, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                  onClick={() => { navigate('/user/myproductview', { state: { data: item } }) }}
                                >

                                  <TableCell>
                                    {/* {index+1} */}
                                    <Checkbox
                                      onClick={handleAddCart}
                                      {...label}
                                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                    />

                                  </TableCell>
                                  <TableCell className="collections">
                                    <div className="collections-outer">
                                      <LightTooltipImage

                                        placement="top-start"
                                        arrow
                                      >
                                        <Link to="/user/myproductview">
                                          <div className="collections-img">
                                            <img src={item?.product_details?.main_image} alt="" />
                                            {/* <span>Forge Day 0 Badge #2789</span> */}
                                          </div>

                                        </Link>
                                      </LightTooltipImage>

                                      {/* <span>{row.collectionname}</span> */}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <>
                                      <div className="CurrentPrice-tabe-eth">
                                        {/* <span>{row?.Name.toUpperCase()}</span> */}
                                        <span>{item?.product_details?.Product_Name}</span>
                                      </div>
                                    </>
                                  </TableCell>
                                  {/* <TableCell>
                              <div className="volume-rate-outer">
                                <div className="volume-rate">
                                  {row?.price} WETH
                                </div>
                                <span
                                  style={{
                                    color:
                                      `${row.volpercent}` < 0 ? "red" : "green",
                                  }}
                                >
                                  {row.volpercent}
                                </span>
                              </div>
                            </TableCell> */}
                                  <TableCell>{item?.product_details?.product_id}</TableCell>

                                  <TableCell>{item?.product_details?.brand_name}</TableCell>

                                  <TableCell>{item?.product_details?.product_description?.split(/\s+/).slice(0, 10).join(" ")}</TableCell>
                                  <TableCell>{item?.product_details?.Warranty}</TableCell>
                                </TableRow>
                              )
                            })
                          }


                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
                :
                <div className="card-grid">
                  {nftdat?.map((obj, ind) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6} md={4} lg={tiles11 ? 3 : tiles22 ? 3.2 : tiles33 ? 4 : null} xl={tiles11 ? 2 : tiles22 ? 3 : tiles33 ? 2.4 : null}>
                          {/* <Link to={{ pathname: "/user/home", state: obj }}> */}
                          <div className="card-btn" onClick={() => { navigate('/user/myproductview', { state: { data: obj } }) }} >
                            <Card sx={{ maxWidth: 280 }} className="card-btn1" key={ind}>
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="320"
                                  src={obj?.product_details?.main_image}
                                  alt="green iguana"
                                />


                                <CardContent className={tiles22 ? "padding-null" : null}  >

                                  <div className="card-inner-text">
                                    <span>
                                      {obj?.product_details?.Product_Name}
                                    </span>
                                    <div>
                                      {obj?.product_details?.product_id}
                                    </div>

                                  </div>
                                  {/* <div className="card-inner-text1">
                                    <span>
                                      {obj.text3}
                                    </span>
                                  </div>
                                  <div className="card-inner-text1">
                                    <p>  {obj.text4}</p>
                                  </div> */}
                                  {/* 
                          <Link to="/user/home">
                            <div className="card-buynow">
                            
                                <div className="card-buynow">
                                  <Button varient="contained" >Buy Now</Button>
                                </div>
                             
                            </div>
                          </Link> */}

                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </div>
                          {/* </Link> */}

                        </Grid>
                      </>
                    )
                  })}

                </div>}

            </Grid>

          </div>

        </Grid>
      </Grid>
    </>
  );
}

export default UserDashboardBody;



