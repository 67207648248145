import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './AdminProducts.css'
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Axios from '../../../Axios';
import closeMenu from "../../../img/closemenu.png";
import adidasLogo from '../../../img/adidasLogo.png';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Link, useLocation, useParams } from 'react-router-dom'
import Files from '../../../img/files.svg';
import dayjs from 'dayjs';
import './QrProduct.css'
import Pagination from '@mui/material/Pagination';
import CustomerFilter from './CustomerFilter';
import qrbar from '../../../img/qr-bar.png';
import rightState from '../../../img/rightState.png';
import wrongState from '../../../img/wrongState.png';
import productimagethumb from '../../../img/product-image-thumb.png'


const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


function DownloadProducts (datas,status) {
  

    console.log("Pdfd", datas)
    
    // const main =  adidasLogo
    // const logo1 =  adidasLogo
    // const logo2 =  adidasLogo
    // const logo3 =  adidasLogo
    const main = datas?.main_image  || adidasLogo
    const logo1 = datas?.Detail1_image || adidasLogo
    const logo2 = datas?.Detail2_image || adidasLogo
    const logo3 = datas?.Detail3_image || adidasLogo
    const sta = status
 
  
    // const dates = new Date(datas.Creation_date).toLocaleDateString()
    // const sdates = new Date(datas.Payment_date).toLocaleDateString()

    const head = [['Concept', 'Quantity', 'Price', 'Subtotal', 'Discount', 'Tax', 'Total']]
    
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    doc.setFontSize(10);
    doc.setFont('Arial', 'bold');
    doc.setFontSize(25);
    doc.text("Product INFO", 100, 15, "center");

    // const logo = datas?.Detail1_image + ".png"
    // const main = logo || adidasLogo
    var finalitextY = doc.lastAutoText || 10
    // doc.addImage(main, "PNG", 80, finalitextY+30, 40, 40);
    // doc.setFont('Arial', 'bold');
    // doc.setTextColor(0, 112, 240);
    // doc.setFontSize(25);
    // doc.text(`Product ID - ${datas?.product_id}`, 100, finalitextY+20, "center");

    doc.setFont('Arial', 'bold');
    doc.setTextColor(0, 112, 240);
    doc.setFontSize(25);
    doc.text(`Product ID - ${datas?.product_id}`, 100, finalitextY+20, "center");

    doc.addImage(main, "PNG", 80, finalitextY+30, 40, 40);

    var finalimgY = doc.lastAutoImage || 70

    doc.setFont('Arial', 'bold');
    doc.setTextColor(0, 112, 240);
    doc.setFontSize(25);
    doc.text(`${datas?.Product_Name}`, 100, finalimgY+20, "center");
  
    doc.addImage(logo1, "PNG", 35, finalimgY+30, 30, 30);
    var finalimgX = doc.lastAutoImage || 70
    doc.addImage(logo2, "PNG", finalimgX+20, finalimgY+30, 30, 30);
    doc.addImage(logo3, "PNG", finalimgX+75, finalimgY+30, 30, 30);


  

    doc.setFontSize(15);

    var finalY = doc.lastAutoImage || 130
    var finalX = doc.lastAutoTable.finalX || 20


    function roundedRect(x, y, width, height, radius, shadowSize) {
      doc.setLineWidth(0.1);
      doc.setDrawColor(218,149,45);
      doc.setFillColor(255);
      doc.roundedRect(x + shadowSize, y + shadowSize, width, height, radius, shadowSize, 'FD');
    }
    var shadowSize = 5;
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Description", 15, finalY + 15, "left");

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text("XXX11122S", 15, finalY + 22, "left");

    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("EAN", 15, finalY + 30, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Ref Customer", finalitextY + 80, finalY + 30, "right");
   
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.ean}`, 15, finalY + 37, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text("D1113s", finalitextY + 80, finalY + 37, "right");
   

var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Brand", 15, finalY + 44, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Initial Stock", finalitextY + 80, finalY + 44, "right");

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.brand_name}`, 15, finalY + 51, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Initial_stock}`, finalitextY + 80, finalY + 51, "right");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Measures", 15, finalY + 58, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Material", finalitextY + 80, finalY + 58, "right");

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Measures}`, 15, finalY + 65, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.material}`, finalitextY + 80, finalY + 65, "right");


    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Unit Of Measurement", 15, finalY + 72, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Warranty", finalitextY + 80, finalY + 72, "right");

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Unit_of_measurement}`, 15, finalY + 79, "left");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Warranty} , ${datas?.Duration_of_the_official_guarantee}`, finalitextY + 80, finalY + 79, "right");

    var finalitextY = doc.lastAutoText || 15
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Location", 15, finalY + 86, "left");

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.departure_location}`, 15, finalY + 93, "left");

  
    // Draw a rounded rectangle with a border radius of 10
    roundedRect(100, finalY + 10, 90, 95, 5, shadowSize);
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Client Id", 115, finalY + 30, "left");

    doc.setFontSize(13);
    doc.setTextColor(0, 112, 240);
    doc.setFont('Arial', 'bold');
    doc.text("XXX11122S", 185, finalY + 30, "right");

    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Product ID", 115, finalY + 40, "left");

    doc.setFontSize(13);
    doc.setTextColor(0, 112, 240);
    doc.setFont('Arial', 'bold');
    doc.text(`${datas?.product_id}`, 185, finalY + 40, "right");

        doc.setFontSize(13);
        doc.setTextColor(0, 0, 0);
    doc.setFont('Arial', 'bold');
    doc.text("Category", 115, finalY + 50, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.setTextColor(0, 112, 240);
    doc.text(`${datas?.product_category}`, 185, finalY + 50, "right");

    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.setTextColor(0, 0, 0);
    doc.text("Product Status", 150, finalY + 60, "center");

   

    var shadowSizes = 0;
        function roundedRect2(x, y, width, height, radius, shadowSizes) {
      doc.setLineWidth(0.1);
      sta === '1' ? doc.setDrawColor(0,255,0) :sta === '2' ? doc.setDrawColor(255,0,0) : doc.setDrawColor(0,0,255) 
      sta === '1' ? doc.setFillColor(0,255,0) :sta === '2' ? doc.setFillColor(255,0,0) : doc.setFillColor(0,0,255) 
      doc.roundedRect(x + shadowSizes, y + shadowSizes, width, height, radius, shadowSizes, 'FD');
    }
    roundedRect2(125, finalY + 65, 50, 10, 5, shadowSizes);

    doc.setFontSize(12);
    doc.setFont('Arial', 'normal');
    doc.setTextColor(0, 0, 0);
    sta === '1' ? doc.text("Approved", 150, finalY + 71, "center") : sta === '2' ? doc.text("Declined", 150, finalY + 71, "center") : doc.text("Pending", 150, finalY + 71, "center")


    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.setTextColor(0, 0, 0);
    doc.text("BlockChain Status", 150, finalY + 83, "center");

   

    var shadowSizes = 0;
        function roundedRect3(x, y, width, height, radius, shadowSizes) {
      doc.setLineWidth(0.1);
      sta === '1' ? doc.setFillColor(0,255,0) :sta === '2' ? doc.setFillColor(255,0,0) : doc.setFillColor(0,0,255) 
      sta === '1' ? doc.setDrawColor(0,255,0) :sta === '2' ? doc.setDrawColor(255,0,0) : doc.setDrawColor(0,0,255) 
      doc.roundedRect(x + shadowSizes, y + shadowSizes, width, height, radius, shadowSizes, 'FD');
    }
    roundedRect3(125, finalY + 88, 50, 10, 5, shadowSizes);

    doc.setFontSize(12);
    doc.setFont('Arial', 'normal');
    doc.setTextColor(0, 0, 0);
    sta === '1' ? doc.text("Approved", 150, finalY + 95, "center") : sta === '2' ? doc.text("Declined", 150, finalY + 95, "center") : doc.text("Pending", 150, finalY + 95, "center")
    function roundedRect1(x, y, width, height, radius, shadowSize) {
      doc.setLineWidth(0.1);
      doc.setDrawColor(217,217,217);
      doc.setFillColor(217,217,217);
      doc.roundedRect(x + shadowSize, y + shadowSize, width, height, radius, shadowSize, 'FD');
    }
    roundedRect1(10, finalY + 115, 180, 35, 5, shadowSize);


    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
doc.setFont('Arial', 'bold');
doc.text("Extension permit", 20, finalY + 134, "left");


    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
doc.setFont('Arial', 'bold');
doc.text("Price Extension Warranty", 65, finalY + 134, "left");

doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
doc.setFont('Arial', 'bold');
doc.text("Warranty Extension Applied", 130 , finalY + 134, "left");

doc.setFontSize(13);
doc.setTextColor(0, 112, 240);
doc.setFont('Arial', 'bold');
doc.text(`${datas?.Allows_warranty_extension}, ${datas?.Extra_warranty_time}`, 20, finalY + 144, "left");

    doc.setFontSize(13);
    doc.setTextColor(0, 112, 240);
    doc.setFont('Arial', 'bold');
    doc.text(`${datas?.Warranty_extension_price ? datas?.Warranty_extension_price : '-'} ${datas?.Type_of_currency ? datas?.Type_of_currency : '-'}`,65, finalY + 144, "left");
    doc.setFontSize(13);
doc.setTextColor(0, 112, 240);
doc.setFont('Arial', 'bold');
doc.text("Yes, 10-10-2022", 130, finalY + 144, "left");


    return (
     doc.save(`${datas.Product_Name}.pdf`)
  
  )
  
 }

export default DownloadProducts;
