/* eslint-disable no-useless-escape */
import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import "./UserloginNew.css"
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Axios from '../../../Axios';
import consts from '../../../constants';
import { Toaster, toast } from 'react-hot-toast'
import Checkbox from '@mui/material/Checkbox';
import logo from '../../../img/logo.png'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const RegisterNew = () => {

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [checks, setChecks] = useState(true)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const navigate = useNavigate()

    const email = useRef(null);
    const firstname = useRef(null);
    const lastname = useRef(null);
    const phone_no = useRef(null);
    const [pos, setPos] = useState("")
    const inpassref = useRef(null);
    const inputconfrimRef = useRef(null);
    const [emailerr, setemailerr] = useState(null)
    const [usernameerr, setusernameerr] = useState(null)
    const [usernameerr1, setusernameerr1] = useState(null)
    const [phonerr, setPhonerr] = useState(null)
    const [addressErr, setAddressErr] = useState(null)
    const [passworderr, setpassworderr] = useState(null)
    const [cpassworderr, setcpassworderr] = useState(null)
    const [checkerr, setcheckerr] = useState(null)
    const [postErr, setpostErr] = useState(null)
    const [address1, setAddress1] = useState("");
    const [valiaddre, setValiaddre] = useState(null)


    // Use useRef to store the selected value


    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(pos => {
    //         const lat = pos.coords.latitude
    //         const long = pos.coords.longitude
    //         const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`;
    //         fetch(url).then(res => res.json()).then((data) => {
    //             console.log(data)
    //             setCity(data.address.city)
    //             setSt(data.address.state)
    //             setCoun(data.address.country)
    //             setPos(data.address.postcode)
    //         }
    //         )
    //     })
    // }, [])

    function verifyemail() {
        // setemailerr(null)
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email.current.value)) {
            setemailerr("Please Enter valid email")
        } else if (email.current.value === "") {
            setemailerr("Please Enter Email")
        } else {
            setemailerr("")
        }
    }
    const phoneChange = () => {
        let reg = /^[0-9]+$/;
        if (phone_no.current.value === "") {
            setPhonerr("Please Phone Number")
        }
        else if (!(reg.test(phone_no.current.value))) {
            setPhonerr("Please Enter Valid Phone Number")
        }
        else {
            setPhonerr("")
        }
    }

    function getPassword() {
        setpassworderr(null)
        // if (inpassref.current.value === inputconfrimRef.current.value) {
        //   setConfirmPass("");
        // } else {
        //   setConfirmPass("Password Miss-Match");
        // }
    }

    function confirmPassword() {
        if (inputconfrimRef.current.value === " ") {
            setcpassworderr("Password Miss-Match");
        }
        else if (inpassref.current.value === inputconfrimRef.current.value) {
            setcpassworderr(null);
        } else {
            setcpassworderr("Password Miss-Match");
        }
    }
    const dataChange = () => {
        var dateReg = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\-]\d{4}$/
        if (lastname.current.value === "") {
            setusernameerr1("Please DOB")
        }
        else if (!(dateReg.test(lastname.current.value))) {
            setusernameerr1("Please Enter Valid DOB Format (DD-MM-YYYY)")
        }
        else {
            setusernameerr1("")
        }
    }
    const changeChecks = (check) => {
        setChecks(!check)
        setcheckerr("")
    }

    const [lat, setLat] = useState()
    const [long, setLong] = useState()
    const handleSuccessRegister = async () => {
        var dateReg = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\-]\d{4}$/
        let reg = /^[0-9]+$/;
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        try {
            //console.log(phone_no.current.value, "phone");
            // console.log(name.current.value, email.current.value, inputconfrimRef.current.value, inpassref.current.value, passworderr, cpassworderr, usernameerr, emailerr, confirmPass, "redfs")
            // if (name.current.value !== "" && email.current.value !== "" && inputconfrimRef.current.value !== "" && inpassref.current.value !== ""
            //   && passworderr === null && cpassworderr === null && usernameerr === null && emailerr === null && confirmPass === null)

            if (firstname.current.value === "") {
                setusernameerr("Please Enter Name")
                toast.error('Please Enter Name')
            }
            else if (email.current.value === "") {
                setemailerr("Please Enter Email")
                toast.error('Please Enter Email')
            }

            else if (!(re.test(email.current.value))) {
                setemailerr("Please Enter valid Email")
                toast.error('Please Enter valid Email')
            }
            else if (phone_no.current.value === "") {
                setPhonerr("Please Phone Number")
                toast.error('Please Phone Number')
            }
            else if (!(reg.test(phone_no.current.value))) {

                setPhonerr("Please Enter Valid Phone Number")
                toast.error('Please Enter Valid Phone Number')
            }
            else if (lastname.current.value === "") {

                setusernameerr1("Please Enter DOB")
                toast.error('Please Enter DOB')
            }
            else if (!(dateReg.test(lastname.current.value))) {
                setusernameerr1("Please Enter Valid DOB Format (DD-MM-YYYY)")
                toast.error('Please Enter Valid DOB Format (DD-MM-YYYY)')
            }
            else if (address1 === "") {
                setAddressErr("Please Enter Address")
                toast.error('Please Enter Address')
            }
            // else if (cit === "") {
            //     setCityErr("Please Enter City");
            //     toast.error('Please Enter City')
            // }
            // else if (st === "") {
            //     setstateErr("Please Enter State");
            //     toast.error('Please Enter State')
            // }
            // else if (coun === "") {
            //     setcountryErr("Please Enter Country");
            //     toast.error('Please Enter Country')
            // }
            else if (pos === "") {
                setpostErr("Please Enter Postal Code");
                toast.error('Please Enter Postal Code')
            }

            else if (inpassref.current.value === "") {
                setpassworderr("Please Enter Password")
                toast.error('Please Enter Password')
            } else if (inputconfrimRef.current.value === "") {
                setcpassworderr("Please Enter Confirm Password")
                toast.error('Please Enter Confirm Password')
            } else if (inpassref.current.value !== inputconfrimRef.current.value) {
                setcpassworderr("Password Miss-Match");
                toast.error('Password Miss-Match')
            }
            else if (!(checks)) {
                setcheckerr("Please Accept the Policy")
                toast.error('Please Accept the Policy')
            }

            else {
                let newad = address1.split(",")
                let city = newad.length - 1
                const { data } = await Axios.post('/users/register',
                    {
                        firstname: firstname.current.value,
                        DOB: lastname.current.value,
                        email: email.current.value,
                        password: inpassref.current.value,
                        role: "user",
                        phone_no: phone_no.current.value,
                        address: address1,
                        latitude: lat,
                        longitude: long,
                        city: "",
                        state: "",
                        country: newad[city],
                        postalcode: pos,
                    }
                )

                if (data.result) {
                    // navigate(`${consts.route}/register-success`)
                    toast.success('Registred Successfully, Please Verify Your Email')
                    setTimeout(() => {
                        navigate(`${consts.route}/user/login`)

                    }, 2000);


                }
            }
        } catch (error) {
            if (error?.response?.data?.message === "Email Already Exists") {
                setemailerr(error?.response?.data?.message)
            } else {
                setpassworderr(error?.response?.data?.message)
            }
        }
    }

    const nft = async (e) => {
        try {
            setpostErr(null)
            setValiaddre("")
            if (e.target.value !== "") {
                setPos(e.target.value)
                const add = await Axios.post('/validateaddress', {
                    address: address1

                })
                if (add?.data?.success === true) {
                    setValiaddre("Address Verified")
                    // let fromadd = add?.data?.result?.address.split(",")
                    // let postad = fromadd[2]
                    // let postadd = postad.split(" ")
                    // if (fromadd[1] != undefined) {
                    //   city.current.value = fromadd[1]
                    // }
                    // if (fromadd[3] != undefined) {
                    //   country.current.value = fromadd[3]
                    // }
                    // if (postadd[1] != undefined) {
                    //   state.current.value = postadd[1]
                    // }
                    // if (postadd[2] != undefined) {
                    //   postalcode.current.value = postadd[2]
                    // }
                } else {
                    setValiaddre("Address Not Verified")
                }
            } else {
                setpostErr(null)
            }

        } catch (error) {
            console.log(error, "error");
        }
    }

    const handleChangeAddress = (newAddress) => {
        setPos("")
        setAddress1(newAddress);
        setValiaddre("")
    };

    const handleSelectAddress = (newAddress) => {
        setPos("")
        setValiaddre("")
        // let ad = newAddress.split(",")
        // console.log(ad.length, "address-vijay");
        // const cou = ad.length - 1
        // console.log();
        // console.log(cou, "vjlenghth");
        // console.log(ad[cou], "country");
        setAddress1(newAddress);
        // city.current.value = ad[1]
        // state.current.value = ad[2]
        // country.current.value = ad[cou]
        geocodeByAddress(newAddress)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                setLat(latLng.lat)
                setLong(latLng.lng)
            })
            .catch((error) => console.error("Error", error));
    };

    return (
        <div className='login-page1 register'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1, }}>

                <div className='we-product'>
                    <div className='left-side-txt'><Link to="/"><img src={logo} alt="logo" /></Link></div>
                    <p>We Protect your brand 100% with blockchain technology</p>
                </div>

                <Grid container spacing={0} sx={{ alignItems: "center" }}>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
                        {/* <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
            <p>We Protect your brand 100% with blockchain technology</p> */}

                        {window.localStorage.getItem('camino') ? <div></div>
                            :
                            // <div className='user-login-text1'>
                            //     <div className='active-text'>
                            //         ACTIVATE PRODUCT
                            //     </div>
                            //     <div>
                            //         <img src={nftdata.main_image} alt="watch" className='watch-img' />
                            //     </div>
                            //     <div className='active-text'>
                            //         {nftdata.Product_Name}
                            //         <div>
                            //             {nftdata.product_id}
                            //         </div>
                            //     </div>

                            // </div>
                            <></>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>
                        <div className="form-input1">
                            {/* <div className='login-txt'>
                <h4>Hey! Buddy</h4>
                <h1>Welcome 🥳 </h1>
              </div> */}     <div className='login-main-text1'>
                                DTracer
                            </div>

                            <div className='register-form-innerdiv'>



                                <div className='email-password-block'>
                                    <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" className='textField' inputRef={firstname} onChange={() => { setusernameerr(null) }} />
                                        {usernameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{usernameerr}</div> : <></>}
                                    </div>

                                    <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email} onChange={verifyemail} />

                                        {emailerr !== null ? <div className='div' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{emailerr}</div> : <></>}
                                    </div>


                                    <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="Phone" variant="outlined" className='textField' inputRef={phone_no} onChange={() => { phoneChange() }} />
                                        {phonerr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{phonerr}</div> : <></>}
                                    </div>



                                    <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="Date of Birth" variant="outlined" className='textField' inputRef={lastname} onChange={() => { dataChange() }} />
                                        {usernameerr1 ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{usernameerr1}</div> : <></>}
                                    </div>

                                    <div className='cmn-input-cls'>
                                        <PlacesAutocomplete
                                            value={address1}
                                            onChange={handleChangeAddress}
                                            onSelect={handleSelectAddress}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className='new-reg-list n2'>
                                                    <TextField
                                                        {...getInputProps({
                                                            placeholder: "Address",
                                                            className: "location-search-input"
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item--active"
                                                                : "suggestion-item";
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                                : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                            return (
                                                                <div
                                                                    key={suggestion.placeId}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {addressErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{addressErr}</div> : <></>}
                                    </div>

                                    {/* <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="City" variant="outlined" className='textField' value={cit} onChange={(event) => { citchange(event) }} />
                                        {cityErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{cityErr}</div> : <></>}
                                    </div>

                                    <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="State" variant="outlined" className='textField' value={st} onChange={(event) => { stchange(event) }} />
                                        {stateErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{stateErr}</div> : <></>}
                                    </div> */}

                                    {/* <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="Country" variant="outlined" className='textField' value={coun} onChange={(event) => { countrchange(event) }} />
                                        {countryErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{countryErr}</div> : <></>}
                                    </div> */}

                                    <div className='cmn-input-cls'>
                                        <TextField id="outlined-basic" label="Postal Code" variant="outlined" className='textField' value={pos} onChange={(e) => { nft(e) }} />
                                        {postErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{postErr}</div> : <></>}
                                    </div>


                                    <div className="error-msg">
                                        <>{valiaddre === "Address Verified" ? <></> : <p>{valiaddre}</p>}</>
                                    </div>

                                    <div className="sucess-msg">
                                        <>{valiaddre === "Address Not Verified" ? <></> : <p>{valiaddre}</p>}</>
                                    </div>

                                    <div className='cmn-input-cls'>
                                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                                            <InputLabel htmlFor="outlined-adornment-password" onChange={getPassword}>Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                inputRef={inpassref}
                                                onChange={getPassword}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                            {passworderr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{passworderr}</div> : <></>}

                                        </FormControl>

                                        {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                                    </div>

                                    <div className='cmn-input-cls'>
                                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword1 ? 'text' : 'password'}
                                                inputRef={inputconfrimRef}
                                                onChange={confirmPassword}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword1}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                            {cpassworderr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{cpassworderr}</div> : <></>}
                                        </FormControl>
                                        {/* {confirmPass ? <h3 className='h3' style={{ color: 'red', textAlign: 'center' }}> {confirmPass} </h3> : <></>}
                                        {cpassworderr !== null ? <h3 className='h3' style={{ color: 'red', textAlign: 'center' }}>{cpassworderr}</h3> : <></>} */}
                                        {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                                    </div>

                                    <div className='crtPassword1'>
                                        <span>
                                            <Checkbox {...label} checked={checks} onChange={() => changeChecks(checks)} />
                                        </span>
                                        <span>I accept the privacy policy</span>
                                    </div>
                                    {checkerr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{checkerr}</div> : <></>}

                                    <div className='crtPassword'>Already have an account? <Link to="/user/login">Click here to Sign in.</Link></div>
                                </div>

                                <Button className='submit' onClick={() => { handleSuccessRegister() }}>Register</Button>
                            </div>


                        </div>
                    </Grid>

                </Grid>
            </Box>


        </div >
    )
}

export default RegisterNew

