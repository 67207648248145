import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import "./UserloginNew.css"
import logo from '../../../img/logo.png'


import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Axios from '../../../Axios';
import consts from '../../../constants';
import { Toaster, toast } from 'react-hot-toast'
import CryptoJS from "crypto-js";

const UserLoginNew = () => {
  // const classes = useStyles();
  const navigate = useNavigate()
  const history = useLocation();

  useEffect(() => {
    if (history?.state?.data === true) {
      block()
    }
  }, [history?.state?.data])

  const block = () => {
    if (history.state.data === true) {
      toast.error('Admin Blocked You!!!')
    }
  }

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const email = useRef(null);
  const password = useRef(null);

  const [emailerr, setemailerr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);

  const secretPass = 'DtRaCeRsEcrEtFrEe'

  const [lat, setLatitude] = useState()
  const [long, setLongitutde] = useState()

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      setLatitude(latitude)
      setLongitutde(longitude)
    }

    function error() {
      console.log("Unable to retrieve your location");
    }
  }, []);


  const onSubmit = async () => {
    try {
      if (email.current.value === "") {
        setemailerr("Please Enter your Email Address");
      } else if (password.current.value === "") {
        setpassworderr("Please Enter your Password");
      } else {
        // const re = new RegExp('^[a-z]+@[a-z]+\.[a-z]{1,3}$')
        // re.test(email.current.value)

        window.localStorage.removeItem("AdminToken");

        const { data } = await Axios.post("/adminlogin", {
          email: email.current.value,
          password: password.current.value,
          latitude: lat,
          longitude: long
        });
        if (data?.success) {
          toast.success('LoggedIn Successfully')
          window.localStorage.setItem(
            "electrónico",
            email.current.value
          )
          window.localStorage.setItem(
            "AdminToken",
            data.result.token
          )
          window.localStorage.setItem(
            "Squelch",
            data.result.admin.role
          )
          if ((data?.result?.admin?.role === 'admin') || (data?.result?.admin?.role === 'subAdmin')) {
            if (data?.result?.admin?.role === 'subAdmin') {

              const data1 = await Axios.post('/admin/getSubadminPermission', {},
                {
                  headers: {
                    Authorization: data.result.token,
                  },
                }
              )
              if (data1?.data?.success && data1?.data?.result?.length > 0) {
                const datas = CryptoJS.AES.encrypt(
                  JSON.stringify(data1?.data?.result),
                  secretPass
                ).toString();
                window.localStorage.setItem('isimo', datas)
              } else {
                // defaultStatus()
              }
              setTimeout(() => {
                navigate(`${consts.route}/dashboard`)
              }, 2000);
            } else {
              setTimeout(() => {
                navigate(`${consts.route}/dashboard`)
              }, 2000);
            }

          } else if (data?.result?.admin?.role === 'user') {
            const data = window.localStorage.getItem('camino')
            const emails = window.localStorage.getItem('comunicazione')
            if (data) {
              setTimeout(() => {

                navigate(`${consts.route}/user/productdetails/${data}/${emails}`)
                window.localStorage.removeItem('camino')
              }, 2000);
            } else {
              setTimeout(() => {
                navigate(`${consts.route}/user/dashboard`)
              }, 2000);
            }

          } else {
            setTimeout(() => {
              navigate(`${consts.route}/brand-/brand-dashboard`)
            }, 2000);
          }
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.message === "Invalid Email" ||
        error?.response?.data?.message === "Please Verify Email"
      ) {
        setemailerr(error?.response?.data?.message);
      }
      if (error?.response?.data?.message === "Wrong Password") {
        setpassworderr(error?.response?.data?.message);
      } else {
        setemailerr(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    // getassest()
    if (history?.state?.emailVerify === 0) {
      toast.success("Email Verified Successfully", {
        icon: '👏',
      })
    } else if (history?.state?.emailVerify === 1) {
      toast.error("Email Already Verified")
    } else if (history?.state?.login === 0) {
      toast.success("Please Login")
    }
  }, []);

  return (

    <div className='login-page1'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>

        <div className='we-product'>
          <div className='left-side-txt'><Link to="/"><img src={logo} alt="logo" /></Link></div>
          <p>We Protect your brand 100% with blockchain technology</p>
        </div>


        <Grid container spacing={0} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
            {window.localStorage.getItem('camino') ? <div></div>
              :
              // <div className='user-login-text1'>
              //   <div className='active-text'>
              //     ACTIVATE PRODUCT
              //   </div>
              //   <div>
              //     <img src={nftdata.main_image} alt="watch" className='watch-img' />
              //   </div>
              //   <div className='active-text'>
              //     {nftdata.Product_Name}
              //     <div>
              //       {nftdata.product_id}
              //     </div>

              //   </div>

              // </div>
              <></>
            }
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>

            <div className="form-input1">
              <div className='login-main-text'>
                DTracer
              </div>

              {/* <div className='login-txt'>
                <h4>Hey! Buddy</h4>
                <h1>Welcome Back 🥳 </h1>
              </div> */}

              <div className='email-password-block'>
                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email}
                    onChange={() => {
                      setemailerr(null);
                    }} />
                  {emailerr !== null ? (
                    <div style={{ color: "red", textAlign: 'center' }}>{emailerr}</div>
                  ) : (
                    <></>
                  )}

                </div>

                <div className='cmn-input-cls'>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      inputRef={password}
                      onChange={() => {
                        setpassworderr(null);
                      }}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {passworderr !== null ? (
                    <div style={{ color: "red", textAlign: 'center' }}>{passworderr}</div>
                  ) : (
                    <></>
                  )}
                  {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                </div>
                <div className='crtPassword'><Link to={`${consts.route}/forgot-mail`}>Forgot Password ?</Link></div>
              </div>

              <Button className='submit' onClick={onSubmit} >Login</Button>

              <div className='did-have-account'>
                If you didn't have an account?<span><Link to="/user/register">Register Now</Link></span>
              </div>

              {/* <div className='choice'>Or Used</div>

              <Button className='meta'><div className='btn-logo'><img src={btnlogo} alt="logo" /></div>Wallet Metamask</Button> */}
              {/* <div className='diamond-img'><img src={diamond} alt="diamond"/></div> */}
            </div>


          </Grid>



        </Grid>
      </Box>


    </div>
  )
}

export default UserLoginNew;
