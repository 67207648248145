import React, { useEffect, useState } from "react";
// import "./Invoices.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import logo from '../../../img/dtlogo.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import closeMenu from "../../../img/closemenu.png";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import invoice from "../../../img/user-my-orders/invoice.png";
import Axios from '../../../Axios';



const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },
  containwidth: {
    padding: '0 30px',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




const MyorderInvoicesPopup = ({ row, status, role, decrptedData }) => {
  console.log(row,'rowrowrow');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editopen, seteditOpen] = useState(false);
  const handleeditOpen = () => seteditOpen(true);
  const [date, setDate] = useState()
  const [expDate, setExpDate] = useState()
  const [totalprice, setTotalPrice] = useState(null)
  const [price, setprice] = useState(row?.price)
  const [discount, setdiscount] = useState(row?.discount)
  const [priceerr, setpriceerr] = useState(null)
  const [totalpriceerr, settotalpriceerr] = useState(null)
  const [discounterr, setdiscounterr] = useState(null)
  const [product, setProduct] = useState([])

  const handleeditClose = () => {
    seteditOpen(false)
    setpriceerr('')
    settotalpriceerr('')
    setdiscounterr('')
  }


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const convert = () => {
    const formattedDate = formatDate(row?.Creation_date);
    const formattedDate1 = formatDate(row?.Payment_date);
    setDate(formattedDate)
    setExpDate(formattedDate1)
  }

  useEffect(() => {
    convert()
  }, [row])



  return (
    <div className="invoicePopup">
      <Button onClick={handleOpen} >
      <img src={invoice} alt="image" />
      </Button>
      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

          
        <Box sx={style} className="popBOX BlockPopup Choose-blockchain-box-main">
        <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
          <div className="Captura-images">
            <div id="alert-dialog-description" className='product-head'>
              <div className="logo-dt">
                <img src={logo} alt="dtlogo" />
              </div>
            </div>
            <Grid container spacing={0} className='whole-grid-value'>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <div className="dtllc-head para-mar-adj">
                <h3>Dtracer llc</h3>
                  <p className="llc-head"></p>
                  <p>30-1343968</p>
                  <p><b>dtracer.io</b></p>
                  <Link to="">hello@dtracer.io</Link>
                  <p className="dtrace">1210 washinton av suite213,33139, miami beach,</p>
                  <p><b>florida</b></p>
                </div>

                <div className="client-llc para-mar-adj">
                  <div className="client-llc-direct">
                    <p className="llc-head-1">CLIENT</p>
                    <p className="llc-head">{row?.userDetails?.firstname}</p> <br></br>
                 {/* <p><b>{row?.billing?.Invoice_Id ? row?.billing?.Invoice_Id : '0'}</b></p> */}
                 {/* <p className="dtrace">dtracer.io</p> */}
                 <Link to="">{row?.userDetails?.email}</Link> <br></br>
                 <p className="dtrace">{row?.userDetails?.address}</p> 
                 <p><b>{row?.userDetails?.country}</b></p>
                    <div className="dtr">
                      {/* <p className="dtrace-1"> DTR Invoice </p>
                      <p className="dtrace">fecha: {date}</p>
                      <p className="dtrace">vencimiento: {expDate}</p> */}
                    </div>

                  </div>
                </div>

                <div className="client-detail-table">

                  <Table sx={{ minWidth: 700 }} aria-label="customized table" className="invoice-billing-recieipt-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="head-part-table">Concept</StyledTableCell>
                        <StyledTableCell className="head-part-table">qty</StyledTableCell>
                        <StyledTableCell className="head-part-table">price</StyledTableCell>
                        <StyledTableCell className="head-part-table">subtotal</StyledTableCell>
                        <StyledTableCell className="head-part-table">Tax</StyledTableCell>
                        <StyledTableCell className="head-part-table">Total</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow >
                        <StyledTableCell className="table-value invoice-billing-recieipt-table-td">{row?.service}</StyledTableCell>
                        <StyledTableCell className="table-value">1</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.amount}$</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.amount}$</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.tax ? row?.tax : '0'}</StyledTableCell>
                        <StyledTableCell className="table-value">{row?.amount}$</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="total-box invoice-billing-recieipt-pop-up ">
                  <div className="sub-total-direct">
                    <p><b>subtotal</b></p>
                    <div className="total-value">{row?.amount}$</div>
                  </div>
              
                  <div className="sub-total-direct">
                    <p><b>tax 0%</b></p>
                    <div className="total-value">{row?.tax ? row?.tax : '0,00$'}</div>
                  </div>
                  <div className="sub-total-direct">
                    <p><b>Total</b></p>
                    <div className="total-value">{row?.amount}$</div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="export-btn">
              {/* <Button variant="" className="button-cls" >
              <DownloadIcon/>download</Button> */}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MyorderInvoicesPopup;
