import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'
import "./Owner.css"
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import guard from "../../../img/guard.png";
import home from "../../../img/home-btn.png"
import AdminMenuSide from '../../Admin/AdminMenuSide';
import cancel from "../../../img/cancel.png"
import { Toaster, toast } from 'react-hot-toast'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const ChangeOwner = () => {

    const classes = useStyles();

    const location = useLocation()
    const navigate = useNavigate()
    const [email, setemail] = useState("")
    const [emailerr, setemailerr] = useState(null)
    const [name, setname] = useState("")
    const [nameerr, setnameerr] = useState(null)
    const [phone, setphone] = useState("")
    const [phoneerr, setphoneerr] = useState(null)
    const [datas, setDatas] = useState()
    // const [details, setDetails] = useState({})
    useEffect(() => {
        console.log(location?.state?.data, "asfdhgusahfui")
        setDatas(location?.state?.data)
    }, [location])


    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }
    const navigateTo = () => {
        if (datas?.product_details?.Change_Owner_price == undefined || (datas?.product_details?.Change_Owner_price == "0")) {
            toast.error('Change Owner is Not Enabled')
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let reg = /^[0-9]+$/;
            //setStsDownloads(true)
            if (name === "") {
                setnameerr("name Required")
            }
            else if (email === "") {
                setemailerr("Email Required")
            }
            else if (!(re.test(email))) {
                setemailerr("Please Enter valid Email")
            }
            else if (phone === "") {
                setphoneerr("Please Enter Number")
            }
            else if (!(reg.test(phone))) {
                setphoneerr("Please Enter valid Number")
            }
            else {
                const details = {
                    name: name,
                    email: email,
                    phone: phone
                }
                navigate('/user/entercode', { state: { data: datas, detail: details } })
            }
        }

    }


    return (
        <div className='dashboard-page change-owner'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>

                        <div className='extented-warrenty'>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div2'>
                                    <div>
                                        {/* <Link to="/user/dashboard"><img src={Menuclose} alt="close" /></Link> */}
                                        <div onClick={() => { navigate('/user/myproductview', { state: { data: datas } }) }} ><img src={Menuclose} alt="close" /></div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className='watch-div'>
                                    <img src={datas?.product_details?.main_image} alt="watch" />
                                </div>
                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        {datas?.product_details?.Product_Name} ID {datas?.product_details?.product_id}
                                    </div>
                                    {/* <div className='watch-description'>
                                        Test product description, this is a demonstration of the description of a product where we inform about the characteristics of the product
                                    </div>
                                    <div className='map-component'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.42095469063!2d78.1227348!3d9.94600025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1699970698829!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div> */}
                                    {/* <div className='Guarantee'>
                                        Guarantee activated on 01/01/2030 Valid until 01/01/2032
                                    </div>
                                    <div className='warrenty-records1'>
                                        Run don't lose it! The time allowed by the manufacturer to extend the warranty runs out,
                                        <p className='para-warrenty'>you have <span>3</span> days left</p>
                                    </div> */}

                                    <div className='owner-text'>
                                        <div>
                                            OWNER :
                                        </div>
                                        <div className='smith'>
                                            {datas?.name}
                                        </div>
                                    </div>
                                    <div className='owner-form'>
                                        <div className='new-owner'>
                                            NEW OWNER
                                        </div>
                                        <div>
                                            <TextField id="outlined-basic1" placeholder='Name' name="name" variant="outlined" onChange={(e) => { setname(e.target.value); setnameerr(null) }} />
                                        </div>
                                        {nameerr !== null ? (
                                            <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{nameerr}</div>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            <TextField id="outlined-basic1" type='Email' placeholder='Email' name="email" variant="outlined" onChange={(e) => { setemail(e.target.value); setemailerr(null) }} />
                                        </div>
                                        {emailerr !== null ? (
                                            <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{emailerr}</div>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            <TextField id="outlined-basic1" type="Number" placeholder='Phone' name="phone" variant="outlined" onChange={(e) => { setphone(e.target.value); setphoneerr(null) }} />
                                        </div>
                                        {phoneerr !== null ? (
                                            <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{phoneerr}</div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    <div className='warrenty-main-display'>
                                        <div className='watch-div' >   <img src={datas?.product_details?.main_image} alt="watch" /></div>

                                        <div className='warrenty-records1 br'>
                                            Change Owner
                                        </div>
                                        <div>
                                            <div className='for-only-65'>
                                                <div>for only</div>
                                                <span>${datas?.product_details?.Change_Owner_price} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='proceed2-btn'>
                                        {/* <Link to='/user/entercode'><Button className='ownership-btn' ><img src={home} alt="homr" />proceed with the change of owner</Button></Link> */}
                                        {datas?.product_details?.changeOwner === true ? <Button className='ownership-btn' onClick={() => { navigateTo() }} ><img src={home} alt="homr" />proceed with the change of owner</Button> :
                                            <p>Change Of Owner Disabled</p>}
                                    </div>
                                    {/* 
                                    <div className='trns-details'>
                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr className='warrenty-hr' />

                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>




                                    </div> */}
                                    <div className='user-logo2'>
                                        <img src={guard} alt="lg-1" />
                                        <span className='go-green'>100%</span> <span>SECURE PAYMENTS</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default ChangeOwner
