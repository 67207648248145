import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
// import './AdminTraceability.css';
import * as XLSX from "xlsx";
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Link, useLocation } from 'react-router-dom'
import locationMark from '../../../img/locationMark.png';
import locationMarkGreen from '../../../img/placeholder.png';
import locationMarkBlue from '../../../img/placeholder2.png';
import locationMarkRed from '../../../img/placeholder1.png';
import Pagination from '@mui/material/Pagination';
import actionLocation from '../../../img/actionLocation.svg'
import ProductImage from "../../../img/thumbLogo.svg";
import downloadIcon from "../../../img/downloadIcon.svg";
import group from "../../../img/group.svg";
import AdminMenuSide from '../AdminMenuSide';
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'
import { marker, Icon } from 'leaflet';
import SearchPopup from './SearchPopup';
import logout from '../../logout';




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },
  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});


const AdminTraceability1 = () => {

  useEffect(() => {
    document.title = "Dtracer | Traceability"
  }, [])

  const navigate = useNavigate()
  const location = useLocation()

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  useEffect(() => {
    profile()
  }, [])

  const [counts, setCounts] = useState()
  const [page, setPage] = useState(1)

  const [creationDate, setCreationDate] = useState([])
  const [product_id, setproduct_id] = useState(null)
  const [callapi, setCallapi] = useState(false)
  const [owner, setowner] = useState()
  const [qr_Status, setqr_Status] = useState()

  const [locations, setlocations] = useState()

  useState(() => {
    if (location?.state?.data) {
      setlocations(location?.state?.data)
      setproduct_id(location?.state?.data?.product_id)
    }

  }, [location])

  const handleChange1 = (event, value) => {
    setPage(value);
  };
  const [isActive, setIsActive] = useState(true);
  const redirectPath = '/'
  const timeoutSeconds = 5 * 60 * 1000

  useEffect(() => {
    screenInactive()
  }, [timeoutSeconds, isActive, navigate, redirectPath]);

  const screenInactive = () => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      if (isActive) {
        timeoutId = setTimeout(() => {
          logout()
          window.localStorage.removeItem('electrónico')
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`)
        }, timeoutSeconds);
      }
    };

    const handleMouseActivity = () => {
      setIsActive(true);
      resetTimeout();
    };


    resetTimeout();


    window.addEventListener('mousemove', handleMouseActivity);
    window.addEventListener('keydown', handleMouseActivity);


    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleMouseActivity);
      window.removeEventListener('keydown', handleMouseActivity);
    };
  }

  const profile = async () => {
    if (role === 'brand') {
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        navigate(`${consts.route}/`, { state: { data: true } })
      }

    }
  }

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)
  const [statuss, setstatuss] = useState()
  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const states = {
    center: [51.505, -0.091],
    zoom: 3,
  };

  const [details, setDetails] = useState([])
  const [markers, setMarkers] = useState([])
  const [isfilter, setisFilter] = useState(false)
  const [isfilterss, setisFilterss] = useState(false)
  const [userdetails, setUserdetails] = useState([])

  // const markers = [
  //   {
  //     geocode: [48.86, 2.3522],
  //     popUp: "Hello, I am pop up 1"
  //   },
  //   {
  //     geocode: [48.85, 2.3522],
  //     popUp: "Hello, I am pop up 2"
  //   },
  //   {
  //     geocode: [48.855, 2.34],
  //     popUp: "Hello, I am pop up 3"
  //   }
  // ];

  const [foucsLocation, setFocusLocation] = useState()

  const getPosition = async () => {
    try {
      setstatuss("Loading...")
      setDetails([])
      if (role === 'admin') {
        const { data } = await Axios.post('/admin/adminTracability', {
          page: page - 1,
          limit: 10,
          product_id: product_id
        }, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if (data?.result?.length > 0) {
            setCounts(Math.ceil(data?.count / 10))
            setDetails(data?.result)
            setstatuss()
            const dts = data?.result
            var arr = []
            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];
              //  console.log(element?.product_details?.latitude, element?.product_details?.longitude, "long,lat")
              if (((element?.product_details?.latitude != null) || (element?.product_details?.latitude != undefined)) && ((element?.product_details?.longitude != null) || (element?.product_details?.longitude != undefined))) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
        }

      } else if (role === 'brand') {
        const { data } = await Axios.post('/admin/brandTracability', {
          page: page - 1,
          limit: 5,
          product_id: product_id
        }, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if ((data?.resultBrand?.length > 0) && (data?.resultUser?.length > 0)) {
            var tempArr = data?.resultBrand?.concat(data?.resultUser)

            setDetails(tempArr)
            setUserdetails(data?.resultUser)
            setstatuss()
            setCounts(Math.ceil(data?.count / 10))
            // setDetails(data?.result)
            setstatuss()
            const dts = tempArr
            var arr = []

            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else if (data?.resultBrand?.length > 0) {
            var tempArr = data?.resultBrand

            setDetails(tempArr)
            setCounts(Math.ceil(data?.count / 10))

            setstatuss()

            // setDetails(data?.result)
            const dts = tempArr
            var arr = []

            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else if (data?.resultUser?.length > 0) {
            var tempArr = data?.resultUser
            setCounts(Math.ceil(data?.count / 10))

            setDetails(tempArr)
            setstatuss()

            // setDetails(data?.result)
            const dts = tempArr
            var arr = []

            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else {
            setDetails([])
            setstatuss("No Data Found")
          }
        }
        else {
          setDetails([])
          setstatuss("No Data Found")
        }
      }
    } catch (error) {
      setstatuss("No Data Found")
      console.log("🚀 ~ file: AdminTraceability1.js:184 ~ getPosition ~ error:", error)
    }
  }

  const downloadExcelsheet = (row) => {
    let tableData = []
    var startlocation = {}
    var buylocation = {}
    var lastlocation = {}
    var info = {}

    info = {
      BrandName: row?.brand_name,
      ProductID: row?.product_details?.product_id
    }
    tableData.push(info)

    if (row?.product_details?.trackExactLocation.length === 2) {
      startlocation = {
        longitude: row?.product_details?.trackExactLocation[0],
        latitude: row?.product_details?.trackExactLocation[1]
      }
      tableData.push(startlocation)
    }
    if (row?.product_details?.trackExactLocation.length === 4) {
      startlocation = {
        longitude: row?.product_details?.trackExactLocation[0],
        latitude: row?.product_details?.trackExactLocation[1]
      }
      tableData.push(startlocation)
      buylocation = {
        longitude: row?.product_details?.trackExactLocation[2],
        latitude: row?.product_details?.trackExactLocation[3]
      }
      tableData.push(buylocation)
    }
    if (row?.product_details?.trackExactLocation.length > 4) {
      startlocation = {
        longitude: row?.product_details?.trackExactLocation[0],
        latitude: row?.product_details?.trackExactLocation[1]
      }
      tableData.push(startlocation)
      buylocation = {
        longitude: row?.product_details?.trackExactLocation[2],
        latitude: row?.product_details?.trackExactLocation[3]
      }
      tableData.push(buylocation)
      lastlocation = {
        longitude: row?.product_details?.trackExactLocation[row?.product_details?.trackExactLocation.length - 2],
        latitude: row?.product_details?.trackExactLocation[row?.product_details?.trackExactLocation.length - 1]
      }
    }

    tableData.push(lastlocation)

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Co-Ord.csv');

  };

  useEffect(() => {
    if (isfilter === true && callapi === true) {
      getDetails()
      setCallapi(false)
    }
    else {
      getPosition()
      // setproduct_id(null)
    }
  }, [page, product_id])
  // const userSetFocus = async () => {

  // }

  useEffect(() => {
    if (isfilterss === true) {
      setproduct_id(null)
    }
  }, [isfilterss])



  const getDetails = async () => {
    try {

      setstatuss("Loading...")
      // setCounts(1)
      // setMarkers([])
      setDetails([])
      // setFocusLocation()

      if (role === 'admin') {
        const { data } = await Axios.post('/admin/adminTracability', {
          product_id: product_id,
          owner: owner,
          qr_Status: qr_Status,
          creation_date: creationDate,
          page: page - 1,
          limit: 10
        }, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if (data?.result?.length > 0) {
            setDetails(data?.result)
            setCounts(Math.ceil(data?.count / 10))
            setDetails(data?.result)

            const dts = data?.result
            var arr = []
            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];
              if ((element?.product_details?.latitude !== undefined) && (element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== undefined) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else {
            setDetails([])
            setstatuss("No Data Found")
          }
        }
        else {
          setDetails([])
          setstatuss("No Data Found")
        }
      } else if (role === 'brand') {
        const { data } = await Axios.post('/admin/brandTracability', {
          product_id: product_id,
          owner: owner,
          qr_Status: qr_Status,
          creation_date: creationDate,
          page: page - 1,
          limit: 5
        }, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {

          if ((data?.resultBrand?.length > 0) && (data?.resultUser?.length > 0)) {
            var tempArr = data?.resultBrand?.concat(data?.resultUser)
            setCounts(Math.ceil(data?.count / 10))
            setDetails(tempArr)
            setstatuss("No Data Found")
            // setDetails(data?.result)
            const dts = tempArr
            var arr = []

            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else if (data?.resultBrand?.length > 0) {
            var tempArr = data?.resultBrand
            setCounts(Math.ceil(data?.count / 10))
            setDetails(tempArr)
            setstatuss()
            // setDetails(data?.result)
            const dts = tempArr
            var arr = []

            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else if (data?.resultUser?.length > 0) {
            var tempArr = data?.resultUser
            setCounts(Math.ceil(data?.count / 10))
            setDetails(tempArr)
            setstatuss()
            // setDetails(data?.result)
            const dts = tempArr
            var arr = []

            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }
          else {
            setDetails([])
            setstatuss("No Data Found")
          }

        }
        else {
          setDetails([])
          setstatuss("No Data Found")
        }
      }
    } catch (error) {
      setstatuss("No Data Found")
      console.log("🚀 ~ file: AdminTraceability1.js:184 ~ getPosition ~ error:", error)
    }
  }


  const reset = () => {
    setMarkers([])
    setDetails([])
    setFocusLocation()
    setstatuss("")
    getPosition()
  }

  const getS = () => {
    setPage(1)
    getDetails()
  }

  useEffect(() => {
    if (product_id != null && callapi == true) {
      getDetails()
      getPosition()
    }
    setCallapi(false)
  }, [product_id])


  const customIcon = new Icon({
    iconUrl: locationMark,
    iconSize: [23, 30]
  })
  const customIconRed = new Icon({
    iconUrl: locationMarkRed,
    iconSize: [30, 30]
  })
  const customIconBlue = new Icon({
    iconUrl: locationMarkBlue,
    iconSize: [30, 30]
  })
  const customIconGreen = new Icon({
    iconUrl: locationMarkGreen,
    iconSize: [30, 30]
  })
  const [points, setPoints] = React.useState([]);
  const setTime = async (row) => {
    const product = row?.product_details?.trackExactLocation
    if (product.length > 0) {
      var pois = []
      var arr = []
      for (let i = 0; i < product.length; i++) {
        const element = product[i];
        if (element !== null || element !== " ") {
          var obj = {
            geocode: [element, product[i + 1]]
          }
          var poi = { lat: element, lng: product[i + 1] }
          arr.push(obj)
          pois.push(poi)
          i++
        }
      }


      // if (product.length === 2) {
      //   arr = [{
      //     geocode: [product[0], product[1]]
      //   }]
      //   pois = [{ lat: product[0], lng: product[1] }]
      // } else if (product.length === 4) {
      //   arr = [{
      //     geocode: [product[0], product[1]],
      //   },
      //   {
      //     geocode: [product[2], product[3]],
      //   }]

      //   pois = [{ lat: product[0], lng: product[1] }, { lat: product[2], lng: product[3] }]
      // } else if (product.length >= 6) {
      //   arr = [{
      //     geocode: [product[0], product[1]],
      //   },
      //   {
      //     geocode: [product[2], product[3]],
      //   },
      //   {
      //     geocode: [product[product.length - 2], product[product.length - 1]],
      //   }]

      //   pois = [{ lat: product[0], lng: product[1] }, { lat: product[2], lng: product[3] }, { lat: product[product.length - 2], lng: product[product.length - 1] }]
      // }


      setMarkers(arr)
      setPoints(pois)
      console.log("🚀 ~ file: AdminTraceability1.js:273 ~ setTime ~ poisss:", pois)
    }

  }

  const limeOptions = { color: 'lime' }

  const polyline = [
    [51.585, -8.09],
    [51.51, -8.1],
    [51.51, -0.12],
    [51.51, -8.2],
  ];
  return (


    <div className='TrabiliDadMainPage adminTraceability-main-page'>

      <Box sx={{ flexGrow: 1 }}>

        <Grid container>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
              <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                <AdminMenuSide />
              </div>

            </Item>
          </Grid>


          <Grid container onClick={closeMenuHandler} className='traceability-body' >


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='Title select-a-product-head' >

              <div className='leftFirstButton two-btnn '>
                {/* <div className='filter-box'><PostSalesFilterPopup /></div> */}
                <div>
                  <Button className="filterBtn">
                    {/* <SearchIcon />
                &nbsp; Search */}
                    <SearchPopup setqr_Status={setqr_Status} setowner={setowner} setproduct_id={setproduct_id} setCreationDate={setCreationDate} setisFilter={setisFilter} reset={reset} getS={getS} apicall={setCallapi} />
                  </Button>
                  <Button onClick={() => { reset(); setisFilter(false); setisFilterss(true) }} className="filter-colors">
                    {<RefreshIcon color='primary' />}
                    Filter Reset
                  </Button>
                </div>


              </div>
              <div className='leftFirstButton' style={{ marginTop: "10px", justifyContent: "center" }}>
                <div className='topTitle'>select a product</div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='select-a-product-location'>


              <div className='graphLine'>
                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-right'>
                    {
                      foucsLocation?.length > 0 ?
                        <Map center={foucsLocation} zoom={states?.zoom}>
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          />
                          {
                            markers?.map((marker, index) => (
                              <Marker position={marker?.geocode} icon={index == 0 ? customIconRed : index == 1 ? customIconGreen : index == 2 ? customIconBlue : customIcon}  >
                                <Popup>
                                  <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                  <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                  <label>Owned By: </label> <span>{marker?.userName}</span>
                                </Popup>
                              </Marker>
                            ))
                          }
                          <Polyline positions={points} color="blue" />
                          {/* <Marker position={this.state.center}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker> */}
                        </Map>
                        :
                        <Map center={states.center} zoom={states?.zoom}>
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          />
                          {/* {
                          markers?.map((marker) => (
                            <Marker position={marker?.geocode} icon={customIcon} >
                              <Popup>
                                <h2>{marker?.popUp}</h2>
                              </Popup>
                            </Marker>
                          ))
                        } */}
                          {/* <Marker position={this.state.center}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker> */}
                        </Map>
                    }

                    {/* <iframe src="https://www.google.com/maps/d/embed?mid=1hjs3mIoZBblBP_CvxiP4w38STiY&hl=en_US&ehbc=2E312F" width="100%" height="400" /> */}
                  </div>
                </div>

              </div>
            </Grid>



            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='select-a-product-location'>
              <div className='InvoicePaidTable'>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead className='invoiceTableHead'>
                    <TableRow className='invoiceTableRow'>
                      <StyledTableCell className="head-part-table">mark on the map</StyledTableCell>
                      <StyledTableCell className="head-part-table">product image</StyledTableCell>
                      {
                        role === 'admin' && <StyledTableCell className="head-part-table">Brand Name</StyledTableCell>
                      }
                      {
                        role === 'admin' && <StyledTableCell className="head-part-table">Brand ID</StyledTableCell>
                      }
                      <StyledTableCell className="head-part-table">Product ID</StyledTableCell>
                      <StyledTableCell className="head-part-table">Owner</StyledTableCell>
                      <StyledTableCell className="head-part-table">starting point</StyledTableCell>
                      <StyledTableCell className="head-part-table">last movement</StyledTableCell>
                      <StyledTableCell className="head-part-table">action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='invoiceTableBody'>


                    <>
                      {details.length > 0 && details?.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell className="table-value">  <div onClick={() => { setFocusLocation([row?.product_details?.latitude, row?.product_details?.longitude]) }} > <img src={locationMark} alt={locationMark} /> </div> </StyledTableCell>
                          <StyledTableCell component="th" scope="row" className="table-value"> <div className='tra-img'> <img src={row?.product_details?.main_image} alt={ProductImage} /></div></StyledTableCell>
                          {
                            role === 'admin' && <StyledTableCell className="table-value">{row?.brand_name}</StyledTableCell>
                          }
                          {
                            role === 'admin' && <StyledTableCell className="table-value">{row?.brandDetails?.brandId ? row?.brandDetails?.brandId : '-'}</StyledTableCell>
                          }
                          <StyledTableCell className="table-value">{row?.product_details?.product_id}</StyledTableCell>
                          {/* <StyledTableCell className="table-value">{row?.brandDetails ? row?.brandDetails?.user_info?.name : row?.usersDetails?.firstname}</StyledTableCell> */}
                          <StyledTableCell className="table-value">{userdetails.length > 0 ? userdetails[0]?.usersDetails?.firstname : row?.brandDetails?.user_info?.name}</StyledTableCell>
                          <StyledTableCell className="table-value">{row?.product_details?.trackLocation?.length > 0 ? row?.product_details?.trackLocation[0] : '0'} {row?.product_details?.trackLocation?.length > 1 ? row?.product_details?.trackLocation[1] : '0'}</StyledTableCell>
                          {/* <StyledTableCell className="table-value">{row?.product_details?.latitude} {row?.product_details?.longitude}</StyledTableCell> */}
                          <StyledTableCell className="table-value">{row?.product_details?.trackExactLocation[row?.product_details?.trackExactLocation.length - 2]} {row?.product_details?.trackExactLocation[row?.product_details?.trackExactLocation.length - 1]}</StyledTableCell>
                          <StyledTableCell className="table-value">
                            <div className='icon-space'>
                              <div onClick={() => { setTime(row) }} title="See Location" > <img src={actionLocation} alt={actionLocation} /></div>
                              <img src={downloadIcon} title="Download Excel" alt={downloadIcon} onClick={() => downloadExcelsheet(row)} />
                              <div onClick={
                                () => { navigate('/admin/customer', { state: { prod_id: row?.product_details?.product_id ? row?.product_details?.product_id : false } }) }
                              } title="View Owner"> <img src={group} alt={group} /></div>
                            </div>

                          </StyledTableCell>
                          {/* <StyledTableCell className="table-value"></StyledTableCell>
              <StyledTableCell className="table-value"></StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                      {
                        details.length <= 0 &&

                        <StyledTableRow >
                          <StyledTableCell className="table-value"></StyledTableCell>
                          <StyledTableCell component="th" scope="row" className="table-value"></StyledTableCell>
                          {
                            role === 'admin' && <StyledTableCell className="table-value"></StyledTableCell>
                          }
                          <StyledTableCell className="table-value"></StyledTableCell>
                          <StyledTableCell className="table-value">{statuss}</StyledTableCell>
                          <StyledTableCell className="table-value"></StyledTableCell>
                          <StyledTableCell className="table-value"> </StyledTableCell>
                          <StyledTableCell className="table-value"> </StyledTableCell>
                          {/* <StyledTableCell className="table-value"></StyledTableCell>
              <StyledTableCell className="table-value"></StyledTableCell> */}
                        </StyledTableRow>
                      }
                    </>


                  </TableBody>
                </Table>
              </div>
              <div className='tablebody'>
                {details.length > 0 ? <div className='pagination-numb'>
                  <Pagination count={counts} page={page} onChange={handleChange1} color="primary" shape="rounded" />

                </div> : <></>}

              </div>
            </Grid>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.footercls}>
              <Footer />
            </Item>
          </Grid>

        </Grid>
      </Box>
    </div>
  )
}

export default AdminTraceability1;
