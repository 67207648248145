import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import "../ExtendedWarrenty/Warrenty.css"
import Button from '@mui/material/Button'
import watch5 from "../../../img/watch5.png"
import Avatar from '@mui/material/Avatar';
import AdminMenuSide from '../../Admin/AdminMenuSide';
import warrentylogo from "../../../img/logo-warrenty.png"
import Modal from '@mui/material/Modal';
import Axios from '../../../Axios';
import Web3 from 'web3';
import locationMark from '../../../img/locationMark.png';
import locationMarkGreen from '../../../img/placeholder.png';
import locationMarkBlue from '../../../img/placeholder2.png';
import locationMarkRed from '../../../img/placeholder1.png';
import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import consts from '../../../constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgba(38, 38, 38, 0.32)',
    borderRadius: "35.368px",
    p: 4,
};


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const MyProductView = () => {
    const web3 = new Web3(consts.polygon_url)
    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const navigate = useNavigate()

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }
    const [wamon, setWamon] = useState()
    const [data, setData] = useState()
    const location = useLocation()
    const [transHistory, setTransHistory] = useState([])
    useEffect(() => {
        setData(location?.state?.data)
        setTime(location?.state?.data?.product_details)
    }, [location])

    // function getMonthDifference(startDate, endDate) {
    //     return (
    //         endDate.getMonth() -
    //         startDate.getMonth() +
    //         12 * (endDate.getFullYear() - startDate.getFullYear())
    //     );
    // }
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const history = async () => {
        try {
            const dts = await Axios.post(`/users/productTansHistory`, {
                productId: data?.product_details?.product_id
            }, {
                headers: { Authorization: window.localStorage.getItem("AdminToken") }
            })
            if (dts?.data?.success === true) {
                setTransHistory(dts?.data?.result)
            }
        } catch (error) {
            console.log("🚀 ~ file: ExtendWarrenty.js:148 ~ history ~ error:", error)
        }
    }

    const customIcon = new Icon({
        iconUrl: locationMark,
        iconSize: [23, 30]
    })
    const customIconRed = new Icon({
        iconUrl: locationMarkRed,
        iconSize: [30, 30]
    })
    const customIconBlue = new Icon({
        iconUrl: locationMarkBlue,
        iconSize: [30, 30]
    })
    const customIconGreen = new Icon({
        iconUrl: locationMarkGreen,
        iconSize: [30, 30]
    })

    useEffect(() => {
        history()
    }, [data])

    const states = {
        center: [51.505, -0.091],
        zoom: 3,
    };


    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${year}/${month}/${date}`;
    }

    const calculateDifference = () => {
        let eda = data?.product_details?.userExtenWarrenty
        const ndata = eda?.split("/")
        const ar = ndata?.reverse()
        const npurdat = ar?.join("/")
        const inaike = getDate()
        const start = new Date(npurdat);
        const end = new Date(inaike);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // setWadtae(diffDays)
        const rmonth = diffDays / 30
        setWamon(parseFloat(rmonth).toFixed(0))
    };
    useEffect(() => {
        calculateDifference()
    }, [data])

    const [markers, setMarkers] = useState([])
    const [points, setPoints] = React.useState([]);

    const setTime = async (row) => {
        const product = row?.trackExactLocation
        if (product.length > 0) {
            var pois = []
            var arr = []
            for (let i = 0; i < product.length; i++) {
                const element = product[i];
                if (element !== null || element !== " ") {
                    var obj = {
                        geocode: [element, product[i + 1]]
                    }
                    var poi = { lat: element, lng: product[i + 1] }
                    arr.push(obj)
                    pois.push(poi)
                    i++
                }
            }


            // if (product.length === 2) {
            //   arr = [{
            //     geocode: [product[0], product[1]]
            //   }]
            //   pois = [{ lat: product[0], lng: product[1] }]
            // } else if (product.length === 4) {
            //   arr = [{
            //     geocode: [product[0], product[1]],
            //   },
            //   {
            //     geocode: [product[2], product[3]],
            //   }]

            //   pois = [{ lat: product[0], lng: product[1] }, { lat: product[2], lng: product[3] }]
            // } else if (product.length >= 6) {
            //   arr = [{
            //     geocode: [product[0], product[1]],
            //   },
            //   {
            //     geocode: [product[2], product[3]],
            //   },
            //   {
            //     geocode: [product[product.length - 2], product[product.length - 1]],
            //   }]

            //   pois = [{ lat: product[0], lng: product[1] }, { lat: product[2], lng: product[3] }, { lat: product[product.length - 2], lng: product[product.length - 1] }]
            // }


            setMarkers(arr)
            setPoints(pois)
        }

    }



    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>



                        <div className='extented-warrenty' onClick={closeMenuHandler}>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div2'>
                                    <div>
                                        <Link to="/user/myproductlist"><img src={Menuclose} alt="close" /></Link>
                                    </div>

                                    {data?.product_details?.status !== "2" ? <div className='myproductlist-btn'>
                                        <Button onClick={() => { navigate("/user/generate-certificate1", { state: { data: data, transHistory: transHistory } }) }} >Generate Certificate</Button>
                                        <Button onClick={() => { navigate("/user/extendwarrenty", { state: { data: data } }) }} >Extend Warranty</Button>
                                        <Button onClick={() => { navigate("/user/changeowner", { state: { data: data } }) }} >Change of Ownership</Button>
                                    </div> : <></>}
                                    <div>

                                    </div>
                                </div>


                                <div className='watch-id2'>

                                    {/* <div className='lists' onClick={handleOpen}> */}
                                    <div className='lists' >
                                        <div className='product-img'>
                                            <img src={data?.product_details?.main_image} alt="watch" />
                                        </div>
                                        <div className='product-id'>
                                            {data?.product_details?.Product_Name}
                                        </div>

                                        <div className='product-description'>
                                            {data?.product_details?.product_description}
                                        </div>
                                    </div>

                                    <div className='map-component genral1'>
                                        {
                                            data != undefined &&
                                            <Map center={[data?.product_details?.latitude, data?.product_details?.longitude]} zoom={states?.zoom}>
                                                <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                />

                                                {
                                                    markers?.map((marker, index) => (
                                                        <Marker position={marker?.geocode} icon={index == 0 ? customIconRed : index == 1 ? customIconGreen : index == 2 ? customIconBlue : customIcon}  >
                                                            <Popup>
                                                                <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                                                <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                                                <label>Owned By: </label> <span>{marker?.userName}</span>
                                                            </Popup>
                                                        </Marker>
                                                    ))
                                                }
                                                <Polyline positions={points} color="blue" />


                                            </Map>
                                        }
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d54336394.93150973!2d-38.2961321!3d33.77538325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1700033209017!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    </div>

                                    {data?.product_details?.status !== "2" ? <>
                                        <div className='Guarantee'>
                                            {/* Warranty activated on {data?.product_details?.purchadeAt} Valid for {untill} */}
                                            Warranty activated on {data?.product_details?.purchadeAt} Valid for {wamon} Months
                                        </div>


                                        <div className='view-inner-display' >
                                            <img src={data?.product_details?.Detail1_image} className='product-view-icon' alt="watch" />
                                            <img src={data?.product_details?.Detail2_image} className='product-view-icon' alt="watch" />
                                            <img src={data?.product_details?.Detail3_image} className='product-view-icon' alt="watch" />
                                        </div>
                                        <div className='brand'>
                                            {data?.product_details?.brand_name}
                                        </div>
                                        <div className='productdemo-img'>
                                            <img src={data?.users?.user_info?.logo} className='demo-icon' alt="Brand" />
                                        </div>




                                        <div className='warrenty-trns'>
                                            latest transaction
                                        </div>



                                        <div className='trns-details'>

                                            {
                                                transHistory?.length > 0 &&
                                                transHistory?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className='trns-body'>
                                                                <div className='warrenty-display'>
                                                                    <div className='avatar-div'>
                                                                        <Avatar>N</Avatar>
                                                                    </div>
                                                                    <div className='reference-body'>
                                                                        <div className='ref-text' style={{ cursor: "pointer" }} onClick={() => { window.open(`${consts?.URL}/tx/${item?.TransactionHash}`) }}>
                                                                            {/* {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[1])}
                                                                             */}
                                                                            {item?.TransactionHash?.slice(0, 25)}...
                                                                        </div>
                                                                        {/* <div className='ref-text2'>
                                                                            26 sec ago
                                                                        </div> */}
                                                                    </div>

                                                                </div>
                                                                <div className='warrenty-display'>
                                                                    <div className='avatar-div'>
                                                                        <Avatar>N</Avatar>
                                                                    </div>
                                                                    <div className='reference-body'>
                                                                        {/* <div className='ref-text'>
                                                                            {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[2])}
                                                                        </div> */}
                                                                        {/* <div className='ref-text2'>
                                                                            26 sec ago
                                                                        </div> */}
                                                                        <div className='ref-text' style={{ cursor: "pointer" }} onClick={() => { window.open(`${consts?.URL}/address/${web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[1])}`) }} >
                                                                            <p>From </p>
                                                                            {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[1])}
                                                                        </div>
                                                                        <div className='ref-text' style={{ cursor: "pointer" }} onClick={() => { window.open(`${consts?.URL}/address/${web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[2])}`) }}>
                                                                            <p>To</p>
                                                                            {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[2])}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className='warrenty-hr' />
                                                        </>

                                                    )
                                                })

                                            }


                                        </div>

                                        <div>
                                            {/* <Link className='paswd-change-link'><Button className='Buy-now-warrenty' >Select Product</Button></Link> */}
                                            {/* <Button className='Buy-now-warrenty' >Select Product</Button> */}
                                        </div> </> : <>
                                        <div className='Guarantees'>
                                            {/* Warranty activated on {details?.product_details?.purchadeAt} Valid for {untill} */}
                                            This Product is Blocked
                                        </div>
                                        <div className='watch-description'>
                                            Reason:
                                        </div>
                                        {data?.product_details?.reason.length > 0 ? <>
                                            {data?.product_details?.reason && data?.product_details?.reason.map((reasons, index) => {
                                                return <div className='watch-description' key={index}>
                                                    {index + 1}:{reasons}
                                                </div>
                                            })}
                                        </> : <></>}
                                    </>}
                                    <div className='user-logo'>
                                        <img src={warrentylogo} alt="lg-1" />
                                        DTRACER.IO
                                    </div>
                                </div>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style} className="myproduct-popup">
                                        <div className='popup-img'>
                                            <div className='clock-div1'>
                                                <img src={watch5} alt="watch" />
                                            </div>
                                            <img src={watch5} alt="watch" />
                                            <div className='clock-div2'>
                                                <img src={watch5} alt="watch" />
                                            </div>
                                        </div>
                                    </Box>
                                </Modal>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default MyProductView
