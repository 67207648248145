import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import success from '../../../img/approvedicon.png'
import failure from '../../../img/cancelicon.png'
import InvoiceStatus from '../../../img/invoice-status.png'
import ReactToPrint from "react-to-print";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PaidIcon from '@mui/icons-material/Paid';
import Axios from "../../../Axios";
import { Toaster, toast } from 'react-hot-toast'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 30px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const PrintPop = ({ bill }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [doc, setDoc] = useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); }
  const ref = useRef()

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const Printto = () => {
    console.log("Print")
  }




  return (
    <div >
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="qr-detail cancel-invoice">

        <div className='print-icon'>{<LocalPrintshopOutlinedIcon onClick={handleOpen} />}</div>
        {/* <Button onClick={handleOpen} ><LocalPrintshopOutlinedIcon /></Button> */}

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="popBOX BlockPopup cancel-invoice">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


            <div className="invoice-create">
              <p className="new-invoice">Data</p>

            </div>

            <TableContainer id="toprint" >
              <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={ref}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className='head-topic'>Invoice ID</TableCell>
                    <TableCell align="center" className='head-topic'>Brand Name</TableCell>
                    <TableCell align="center" className='head-topic'>Address</TableCell>
                    <TableCell align="center" className='head-topic'>Country</TableCell>
                    <TableCell align="center" className='head-topic'>Total without taxes</TableCell>
                    <TableCell align="center" className='head-topic'>Invoice status</TableCell>
                    <TableCell align="center" className='head-topic'>TAX RATE</TableCell>
                    <TableCell align="center" className='head-topic'>Creation date</TableCell>
                    <TableCell align="center" className='head-topic'>Payment date</TableCell>
                    <TableCell align="center" className='head-topic'>Equivalence surcharge</TableCell>
                    <TableCell align="center" className='head-topic'>Invoice concept</TableCell>
                    <TableCell align="center" className='head-topic'>QTY</TableCell>
                    <TableCell align="center" className='head-topic'>Total bill</TableCell>
                    <TableCell align="center" className='head-topic'>Payment method</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bill?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {/* {console.log(row,'row')} */}
                      <TableCell className='row-topic'>{row?.Invoice_Id}</TableCell>
                      <TableCell className='row-topic'>{row.Brand_Name}</TableCell>
                      <TableCell className='row-topic'>${row.Address}</TableCell>
                      <TableCell className='row-topic'>{row.Country}</TableCell>
                      <TableCell className='row-topic'>{row?.price - (row?.discount / 100 * row?.price) ? row?.price - (row?.discount / 100 * row?.price) : ('-')}</TableCell>
                      <TableCell className='row-topic'>{row?.payment_status === '0' ? <img src={InvoiceStatus} alt={InvoiceStatus} /> : <>{row?.payment_status === '1' ? <img src={success} alt={success} /> : <img src={failure} alt={failure} />}</>}  </TableCell>
                      <TableCell className='row-topic'>{row.Tax_rate}</TableCell>
                      <TableCell className='row-topic'>{new Date(row.Creation_date).toLocaleDateString()}</TableCell>
                      <TableCell className='row-topic'>{new Date(row.Payment_date).toLocaleDateString()}</TableCell>
                      <TableCell className='row-topic'>{row.surcharge}</TableCell>
                      <TableCell className='row-topic'>{row.Invoice_concept}</TableCell>
                      <TableCell className='row-topic'>{row.Quantity}</TableCell>
                      <TableCell className='row-topic'>{row.Total_bil}</TableCell>
                      <TableCell className='row-topic'>{row.Payment_method}</TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div className="create-cancel-btn invoice-reject">
              <div className="create-btn">

                <ReactToPrint
                  bodyClass="print-agreement"
                  content={() => ref.current}
                  trigger={() => (
                    <Button className="create-green-btn" >Print</Button>
                  )}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>


  );
};

export default PrintPop;
