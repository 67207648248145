import React from 'react'
import ReactApexChart from "react-apexcharts";

class MobileSession extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        
          series: [44, 55],
          
          options: {
            legend: {
                position: 'bottom'
              },
              labels:['Desktop', 'Mobile'],
            chart: {
              type: 'donut',
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true
              },
              id:"Mobile Session"
              
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 180,
                
                },
                legend: {
                  position: 'bottom',
                
                }
              }
            }]
          },
        
        
        };
      }

    

      render() {
        return (
          

    <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="donut"   />
</div>
  

        );
      }
  }

  export default MobileSession;