import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CancelInvoice from './cancelInvoice'
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'
import DescriptionIcon from '@mui/icons-material/Description';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Filter = ({ reset, setisFilter }) => {

  const navigate = useNavigate()
  const licenceno = useRef(null)
  const [price, setPrice] = useState(null)
  const [service, setservice] = useState('')
  const [service1, setservice1] = useState(null)
  const [totalprice, setTotalPrice] = useState(null)
  const [totalpricewithouttax, setPricewithoutTax] = useState(null)

  const discount = useRef(null)
  const tax = useRef(null)
  const taxid = useRef(null);
  const vat = useRef(null)
  const vatc = useRef(null);
  const serviceLine = useRef(null);

  const [value, setValue] = React.useState('business');
  const state = useRef(null);
  const country = useRef(null);
  const clientid = useRef(null);
  const clientdata = useRef(null);
  const pincode = useRef(null);
  const [taxerr, settaxerr] = useState(null)

  const [stateerr, setstateerr] = useState(null)
  const [countryerr, setcountryerr] = useState(null)
  const [clientiderr, setclientiderr] = useState(null)
  const [clientdataerr, setclientdataerr] = useState(null)
  const [taxiderr, settaxiderr] = useState(null)
  const [vaterr, setvaterr] = useState(null)
  const [vatcerr, setvatcerr] = useState(null)
  const [licencenoerr, setlicencenoerr] = useState(null)
  const [priceerr, setpriceerr] = useState(null)
  const [totalpriceerr, settotalpriceerr] = useState(null)
  const [discounterr, setdiscounterr] = useState(null)
  const [service1err, setService1err] = useState(null)
  const [ondateerr, setondateerr] = useState(null)
  const [expiredateerr, setexpiredateerr] = useState(null)
  const [redateerr, setredateerr] = useState(null)
  const [informationerr, setinformationerr] = useState(null)
  const [check, setCheck] = useState(false)
  const [check1, setCheck1] = useState(false)

  const [check2, setCheck2] = useState(false)

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  useEffect(() => {
    profile()
  }, [])

  const checkboxChange = () => {
    setCheck(true)
    setCheck1(false)
    setCheck2(false)
  }
  const checkboxChange1 = () => {
    setCheck(false)
    setCheck1(true)
    setCheck2(false)
  }
  const checkboxChange2 = () => {
    setCheck(false)
    setCheck1(false)
    setCheck2(true)
  }
  const checkboxChange4 = () => {
    setCheck(false)
    setCheck1(false)
    setCheck2(false)
  }
  const handleChange = (event) => {
    setValue(event.target.value);
    // if (event.target.value === 'freelancer') {
    setDefaultPrice()
    // }
  };
  const totalLicencePrice = (discount, tax) => {
    if (licenceno?.current?.value && price) {
      var total = licenceno?.current?.value * Math.abs(price)
      var subtotal

      if (Number(discount) >= 0) {
        var subtot = (Number(discount) / 100) * Number(total)
        subtotal = total - subtot
        setTotalPrice(subtotal)
      }
      setPricewithoutTax(subtotal)
      if (Math.abs(subtotal) >= 0 && Number(tax) >= 0) {
        var taxs = (Number(tax) / 100) * Math.abs(subtotal)
        setTotalPrice(Math.abs(subtotal) + Number(taxs))
      }
      if (discount === undefined && tax === undefined) {
        setTotalPrice(Math.floor(total))
      }
    }

  }
  const setDefaultPrice = (val) => {
    if (value === 'freelancer') {
      setPrice(1290)
    } else {
      if (Number(val) > 0 && Number(val) <= 10) {
        setPrice(4990)
      } else if (Number(val) >= 11 && Number(val) <= 20) {
        setPrice(4990)
      } else if (Number(val) >= 21 && Number(val) <= 40) {
        setPrice(3990)
      } else if (Number(val) >= 41 && Number(val) <= 80) {
        setPrice(3490)
      } else if (Number(val) >= 81 && Number(val) <= 100) {
        setPrice(2990)
      } else if (Number(val) >= 101 && Number(val) <= 200) {
        setPrice(2555)
      } else if (Number(val) > 200) {
        setPrice(1999)
      }
      else {
        setPrice(0)
      }
    }

  }


  useEffect(() => {
    totalLicencePrice(discount?.current?.value, tax?.current?.value)
  }, [price])

  const profile = async () => {
    try {
      if (role === 'brand') {
        const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
          headers: {
            Authorization: user
          }
        })
        if (data.result.is_active === false) {
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`, { state: { data: true } })
        }

      }

    } catch (error) {
      console.log("🚀 ~ file: Filter.js:102 ~ profile ~ error:", error)

    }

  }


  const classes = useStyles();

  const [open, setOpen] = useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const createBilling = async () => {
    try {

      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let reg = /^[0-9]+$/;

      if (clientid.current.value === "") {
        // toast.error("Please Enter clientid")
        setclientiderr("Please Enter Brand Name")
      }
      // else if (clientdata.current.value === "") {
      //   // toast.error("Please Enter clientdata")
      //   setclientdataerr("Please Enter clientdata")
      // }

      else if (state.current.value === "") {
        // toast.error("Please Enter State")
        setstateerr("Please Enter Address")
      }
      else if (country.current.value === "") {
        // toast.error("Please Enter Country")
        setcountryerr("Please Enter Country")
      }
      else if (taxid.current.value === "") {
        // toast.error("Please Enter taxid")
        settaxiderr("Please Enter taxid")
      }

      else if (vatc.current.value === "") {
        // toast.error("Please Enter Vat Country")
        setvatcerr("Please Enter Vat Country")
      }
      else if (serviceLine.current.value === "") {
        // toast.error("Please Enter Vat Country")
        setService1err("Please Enter Service Line")
      }
      else if (licenceno.current.value === "") {
        // toast.error("Please Enter No of Licence")
        setlicencenoerr("Please Enter No of Licence")
      }
      else if (!(reg.test(licenceno.current.value))) {
        // toast.error("Please Valid number")
        setlicencenoerr("Please Valid number")
      }

      else if (price === "") {
        // toast.error("Please Enter Price")
        setpriceerr("Please Enter Price")
      }
      // else if (!(reg.test(price))) {
      //   // toast.error("Please Valid number")
      //   setpriceerr("Please Valid number")
      // }
      else if (vat.current.value === "") {
        // toast.error("Please Enter VAT")
        setvaterr("Please Enter VAT")
      }
      else if (!(reg.test(vat.current.value))) {
        // toast.error("Please Valid number")
        setvaterr("Please Valid number")
      }
      else if (discount.current.value === "") {
        // toast.error("Please Enter Discount")
        setdiscounterr("Please Enter Discount")
      }
      else if (!(reg.test(discount.current.value))) {
        // toast.error("Please Valid number")
        setdiscounterr("Please Valid number")
      }

      else if (tax.current.value === "") {
        // toast.error("Please Enter Tax")
        settaxerr("Please Enter Tax")
      } else if (!(reg.test(tax.current.value))) {
        toast.error("Please Valid number")
        settaxerr("Please Valid number")
      }
      else if (licenceno.current.value < 1) {
        toast.error("Please Enter Info")
        setinformationerr("Please Enter Info")
      }
      else {
        const datas = {
          state: state.current.value,
          country: country.current.value,
          client_id: clientid.current.value,
          // clientdata: clientdata.current.value,
          licenceno: licenceno.current.value,
          taxid: taxid.current.value,
          vat: vat.current.value,
          vat_country: vatc.current.value,
          price: price,
          tax: tax.current.value,
          total_price: totalprice,
          service_line: serviceLine.current.value,
          service_line1: service1,
          discount: discount.current.value,
        }

        const { data } = await Axios.post('/admin/create_billing', datas,
          {
            headers: {
              Authorization: window.localStorage.getItem("AdminToken"),
            },
          }
        )

        if (data.success) {
          // navigate(`${consts.route}/register-success`)
          toast.success('Billing Created SucessFully')
          window.location.reload(true)
          // setTimeout(() => {
          //   reset()
          //   setisFilter(false)
          // }, 2000);
        }
        else {

          toast.error(data.message)
        }

      }
    } catch (error) {
      console.log(error, "err")
    }
  }

  return (
    <div >

      <div className="qr-detail">
        <Button onClick={handleOpen} className="filter-btn"><DescriptionIcon />Create Invoice</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="popBOX BlockPopup filterpopup">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

            <div className="invoice-create">
              <p className="new-invoice">Create new invoice</p>
              <p>The identification number will be created automatically</p>
            </div>

            <Grid container spacing={0}>
              <Grid item sm={12} md={6} lg={6} xl={6}>
                <div className="id-client">
                  <p className="new-invoice">Invoice issue date</p>
                  <div className='start-end-date start-end-date-admin invoice-date'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'DateTimePicker',
                          'TimePicker',
                          'DateRangePicker',
                        ]}
                      >

                        <DemoItem>
                          <DatePicker
                            defaultValue={today}
                            disabled
                            views={['year', 'month', 'day']}
                          />
                        </DemoItem>

                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Brand Name</p>
                  <div>
                    {/* <TextField id="outlined-password-input" type="text" className="field-txt-box" /> */}
                    <TextField
                      id="outlined-password-input"
                      placeholder="Brand Name"
                      variant="outlined"
                      className="field-txt-box"
                      onChange={(e) => { setclientiderr(null) }}
                      inputRef={clientid}

                    />
                    {clientiderr ?
                      <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{clientiderr}</div>
                      : <></>}
                  </div>
                </div>

                {/* <div className="id-client">
                  <p className="new-invoice">Client data Social denomination</p>
                  <div>
                    <TextField
                      id="outlined-password-input"

                      variant="outlined"
                      className="field-txt-box"
                      onChange={(e) => { setclientdataerr(null) }}
                      inputRef={clientdata}

                    />
                    {clientdataerr ?
                      <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{clientdataerr}</div>
                      : <></>}
                  </div>
                </div> */}

                <div className="id-client">
                  <p className="new-invoice">Address</p>
                  <div>


                    <TextField
                      id="outlined-password-input"
                      placeholder='Address'
                      variant="outlined"
                      className="field-txt-box"
                      onChange={() => setstateerr(null)}
                      inputRef={state}
                    />
                    {stateerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{stateerr}</div> : <></>}
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Country</p>
                  <div>
                    {/* <TextField id="outlined-password-input" type="text" className="field-txt-box" /> */}

                    <TextField
                      id="outlined-password-input"
                      placeholder='Country'
                      variant="outlined"
                      className="field-txt-box"
                      onChange={() => setcountryerr(null)}
                      inputRef={country}
                    />
                    {countryerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{countryerr}</div> : <></>}
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Tax identification number</p>
                  <div>
                    <TextField
                      id="outlined-password-input"
                      placeholder="Tax Number"
                      variant="outlined"
                      className="field-txt-box"
                      onChange={(e) => { settaxiderr(null) }}
                      inputRef={taxid}

                    />
                    {taxiderr ?
                      <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{taxiderr}</div>
                      : <></>}
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">VAT Country</p>
                  <div>
                    <TextField
                      id="outlined-password-input"
                      placeholder="VAT Country"
                      variant="outlined"
                      className="field-txt-box"
                      onChange={(e) => { setvatcerr(null) }}
                      inputRef={vatc}

                    />
                    {vatcerr ?
                      <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{vatcerr}</div>
                      : <></>}
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={6} lg={6} xl={6}>
                <div className="services-line">
                  <div className="add-service">
                    <p className="new-invoice">Add Services line 1</p>
                    {/* <p><Checkbox {...label} checked={check} onChange={() => { checkboxChange(); setservice("Access to the system for one year") }} />Access to the system for one year</p>
                    <p><Checkbox {...label} checked={check1} onChange={() => { checkboxChange1(); setservice("Transactions made on the blockchain") }} />Transactions made on the blockchain</p>
                    <p><Checkbox {...label} checked={check2} onChange={() => { checkboxChange2(); setservice("Other services indicate the service") }} />Other services indicate the service</p> */}
                    <TextField id="outlined-password-input" type="text" className="field-txt-box max-size" multiline rows={2} placeholder="Other services"
                      onChange={(e) => { checkboxChange4(); setService1err('') }} inputRef={serviceLine}
                    />
                    {service1err ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{service1err}</div> : <></>}
                      <p className="new-invoice">Add Services line 2</p>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box max-size" multiline rows={2} placeholder="Other services"
                      onChange={(e) => { checkboxChange4(); setservice1(e.target.value) }}
                    />
                  </div>
                  <div className="id-client">
                    <p className="new-invoice">Quantity</p>
                    <div>
                      <TextField
                        id="outlined-password-input"
                        placeholder="Quantity"
                        variant="outlined"
                        className="field-txt-box"
                        onChange={(e) => {
                          setlicencenoerr(null);
                          totalLicencePrice(discount?.current?.value, tax?.current?.value);
                          setDefaultPrice(e.target.value)
                        }}
                        inputRef={licenceno}
                      />
                      {licencenoerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{licencenoerr}</div> : <></>}
                    </div>
                  </div>


                  <div className="id-client">
                    <p className="new-invoice">Net unit price</p>
                    <div>

                      <TextField
                        id="outlined-password-input"
                        placeholder="Net unit price"
                        variant="outlined"
                        className="field-txt-box"
                        onChange={(e) => { setpriceerr(null); setPrice(e.target.value) }}
                        value={price}
                      />
                      {priceerr ?
                        <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{priceerr}</div>
                        : <></>}

                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">VAT</p>
                    <div>
                      <TextField
                        id="outlined-password-input"
                        placeholder="VAT"
                        variant="outlined"
                        className="field-txt-box"
                        onChange={(e) => { setvaterr(null) }}
                        inputRef={vat}

                      />
                      {vaterr ?
                        <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{vaterr}</div>
                        : <></>}
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Discount</p>
                    <div>

                      <TextField
                        id="outlined-password-input"
                        placeholder="Discount"
                        variant="outlined"
                        type="Number"
                        className="field-txt-box"
                        onChange={(e) => { setdiscounterr(null); totalLicencePrice(e.target.value, tax.current.value) }}
                        inputRef={discount}
                      />
                      {discounterr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{discounterr}</div> : <></>}
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Tax</p>
                    <div>
                      <TextField
                        id="outlined-password-input"
                        placeholder="Tax"
                        variant="outlined"
                        type="Number"
                        className="field-txt-box"
                        onChange={(e) => { settaxerr(null); totalLicencePrice(discount?.current?.value, e.target.value) }}
                        inputRef={tax}
                      />
                      {taxerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{taxerr}</div> : <></>}
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Total invoice with taxes</p>
                    <TextField
                      id="outlined-password-input"
                      placeholder="Total invoice with taxes"
                      disabled
                      variant="outlined"
                      className="field-txt-box"
                      value={totalprice}
                    />
                    <div>

                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Total invoice without taxes</p>
                    <TextField
                      id="outlined-password-input"
                      placeholder="Total invoice without taxes"
                      disabled
                      variant="outlined"
                      className="field-txt-box"
                      value={totalpricewithouttax}
                    />
                    <div>

                    </div>
                  </div>
                </div>
              </Grid>

            </Grid>
            <div className="create-cancel-btn">
              <div className="create-btn">
                <Button className="create-green-btn" onClick={() => { createBilling() }}>Create</Button>
              </div>
              {/* <div className="create-btn">
                    <Button className="cancel-green-btn" >Cancel</Button>
                   </div> */}
              <div>
                <CancelInvoice />
              </div>
            </div>
          </Box>
        </Modal>
      </div>


    </div>


  );
};

export default Filter;
