import React, { useState, useRef } from "react";
import "./AdminCustomer.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Toaster, toast } from 'react-hot-toast'
import Axios from "../../../Axios";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
// import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import adidasLogo from '../../../img/adidasLogo.png';
import cross from '../../../img/cross.svg'
// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));


const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "2px solid #e42b15 !important",
  p: 2,
  overflow:'scroll'
};

const Deactivte = ({ row }) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const reasontext = useRef(null)
  const [checked, setChecked] = useState(false);
  const [istext, setIstext] = useState(true)
  const [otherchecked, setOtherchecked] = useState(false);
  const [reason, setReason] = useState(false)
  const [checkerr, setCheckerr] = useState(false)
  const [checkboxes, setCheckboxes] = useState([
    { label: 'No License Renewal', checked: false, value: 'No License Renewal' },

    { label: 'Not Satisfied with the Service', checked: false, value: 'Not Satisfied with the Service' },
    { label: 'Temporary Suspension', checked: false, value: 'Temporary Suspension' },
    { label: 'Temporary Penalty', checked: false, value: 'Temporary Penalty' },
    { label: 'Others', checked: false, value: 'Others' },
  ]);
  const [checkedIndex, setCheckedIndex] = useState(-1);
  const handleChanges = (index) => (event) => {

    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = event.target.checked;
    setCheckboxes(newCheckboxes);
    setCheckerr(false)
    setChecked(true)
    if (newCheckboxes[index].value === 'Others') {
      if (newCheckboxes[index].checked === true) {
        setIstext(false)
        setReason(reasontext?.current?.value)
      } else {
        setIstext(true)
      }
    } else {
      setIstext(true)
      setReason(newCheckboxes[index].value)
    }
    if (event.target.checked) {
      setCheckedIndex(index);
    } else {
      setCheckedIndex(-1);
      setChecked(false)
      setReason(false)
    }
  };
  const setusers = async () => {
    try {
      console.log(checked, reason)
      if (checked === false || reason === "") {
        setCheckerr("Please Give Reason")
      }

      else {
        const { data } = await Axios.post('/admin/deactive_brand',
          {
            _id: row._id,
            reason: reason
          },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )
        if (data?.success) {
          window.location.reload()
          toast.success('Deactivated Sucessfully')
        }
      }
    } catch (error) {
      console.log(error, "err")
      toast.error('Error')
    }

  }

  return (
    <div >
      <Button onClick={handleOpen} className="activate">

        <img src={cross} alt={cross} />


      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate Deactivate-user-popup">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          <div className="brand" id="brands">
            <div className="image-box">
              <img src={row?.user_info.logo} alt={'adidasLogo'} />
            </div>
          </div>

          <div className="h2Variant">Deactivate Brand</div>

          <p className="reason">Reason</p>


          <div className="listThree">
            <div className="warn">
              {checkboxes.map((checkbox, index) => (
                <div key={index}>
                  <Checkbox
                    checked={checkbox.checked}
                    onChange={handleChanges(index)}
                    color="primary"
                    inputProps={{ 'aria-label': checkbox.label }}
                    disabled={checkedIndex !== -1 && checkedIndex !== index}
                  />
                  <label>{checkbox.label}</label>
                </div>
              ))}
            </div>

          </div>
          {istext === false ?
            <div className='input-box'>
              <TextField className='field-box'
                required
                multiline
                rows={5}
                rowsMax={5}
                width={100}
                inputRef={reasontext}
                disabled={istext}
                onChange={(e) => { setReason(e.target.value); setCheckerr(true) }}
                variant="outlined"
              />
            </div>
            : <></>
          }

          {checkerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{checkerr}</div> : <></>}
          <div className="butOutLayer">
            <Button className='deactive' onClick={() => setusers()}>Deactivate Brand And All Its Products</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Deactivte;
