import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const QRTotalPending = ({ pendingCount }) => {

  const [options, setOptions] = useState({
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        fill: {
          color: '#000'
        },
        hollow: {
          size: '60%',
        },

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: '30px',
            fontWeight: 700,
            formatter: function (val) {
              return val
            }
          }
        }

      },
    },
    // labels: ['Cricket'],
  })

  const [series, setSeries] = useState([0])

  useEffect(() => {
    setSeries([pendingCount])
  }, [pendingCount])
  return (


    <div id="chart">
      <ReactApexChart options={options} series={series} type="radialBar" height={200} />
      <p className='font-size-cmn'>Products Pending Activation</p>
    </div>


  );
}

export default QRTotalPending

// class QRTotalPending extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {

//       series: [props?.pendingCount],
//       options: {
//         chart: {
//           type: 'radialBar',
//         },
//         plotOptions: {
//           radialBar: {
//             fill: {
//               color: '#000'
//             },
//             hollow: {
//               size: '60%',
//             },

//             dataLabels: {
//               name: {
//                 show: false,
//               },
//               value: {
//                 fontSize: '30px',
//                 fontWeight: 700,
//                 formatter: function (val) {
//                   return val
//                 }
//               }
//             }

//           },
//         },
//         // labels: ['Cricket'],
//       },


//     };
//   }


//   render() {
//     return (


//       <div id="chart">
//         <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={200} />
//         <p className='font-size-cmn'>QR Codes Pending Activation</p>
//       </div>


//     );
//   }
// }

// export default QRTotalPending;