import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './AdminBrands.css'
import Axios from '../../../Axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { blue } from '@mui/material/colors';
import Files from '../../../img/files.svg';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import RefreshIcon from '@mui/icons-material/Refresh';
import Checkbox from '@mui/material/Checkbox';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import EditSeller from './EditSeller';
import BrandsFilterPopup from './BrandsFilterPopup';
import AddBrandsPopup from './AddBrandsPopup';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import SvgMap from './SvgMap';
import cross from '../../../img/cross.svg'
import rightState from '../../../img/rightState.png';
import wrongState from '../../../img/wrongState.png';
import { Link } from 'react-router-dom'
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';



import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import AdminMenuSide from '../AdminMenuSide';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  border: 'none',
  padding: '15px 30px',
  borderRadius: '20px'
};


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: 500,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 500],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));





const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));



const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});

function createData(
  SellerId,
  status,
  creationDate,
  dueDate,
  service,
  category,
  toPay,
  subTotal,
  Total,
  taxType,
  Seller,
) {
  return { SellerId, status, creationDate, dueDate, service, category, toPay, subTotal, Total, taxType, Seller };
}

const rows = [
  createData('1001', 'Store Delta', '5', '12-09-2023 10:44', 'Activated'),
  createData('1002', 'Store Bravo', '10', '12-09-2023 10:44', 'Activated'),
  createData('1003', 'Store Alpha', '25', '12-09-2023 10:44', 'Disabled'),
];

const AdminBrands = () => {

  const classes = useStyles();
  const isMounted = useRef(false);
  const [menuOpen, setMenuOpen] = useState(true)
  const [Brands, setBrands] = useState([])
  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  useEffect(() => {
    // getBrands();  
    if (!isMounted.current) {
      getBrands();
      isMounted.current = true;
    }
    else {
      console.log("fetched")
    }
  }, [])

  const getBrands = async () => {
    try {
      const { data } = await Axios.get('/users/get_all_brand',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )

      if (data?.success) {
        console.log("Data", data?.result)
        setBrands(data?.result)
      }

    } catch (error) {
      console.log(error, "err")
    }

  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (

    <div className='sellerpage sellerpage-admin'>


      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
              <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                <AdminMenuSide />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='filter-main-seller'>


            <div className='start-end-date calender-date'>
              <div className='filter-box'>
                <BrandsFilterPopup />
                <Button className='filter-color'>{<RefreshIcon color='primary' />}Filter Reset</Button>
              </div>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div>

            {/* <AddBrandsPopup/> */}

            <div className='SellerPaidTable'>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className='SellerTableHead'>
                  <TableRow className='SellerTableRow'>
                    <StyledTableCell className="head-part-table">Index</StyledTableCell>
                    <StyledTableCell className="head-part-table">Brand name</StyledTableCell>
                    <StyledTableCell className="head-part-table">Creation date</StyledTableCell>
                    <StyledTableCell className="head-part-table">Activation codes</StyledTableCell>
                    <StyledTableCell className="head-part-table">Seller</StyledTableCell>
                    <StyledTableCell className="head-part-table">Status</StyledTableCell>
                    <StyledTableCell className="head-part-table">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='SellerTableBody'>
                  {Brands && Brands.map((row, index) => (
                    <StyledTableRow key={row?._id}>
                      <StyledTableCell component="th" scope="row" className="table-value">
                        {/* <Checkbox {...label} color="primary" className='checkBoxClr' /> */}
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell className="table-value">{row?.firstname} {row?.lastname}</StyledTableCell>
                      {/* <StyledTableCell className="table-value">{row?.contact_phone}</StyledTableCell> */}
                      <StyledTableCell className="table-value">{new Date(row?.createdAt).toLocaleString()}</StyledTableCell>
                      <StyledTableCell className="table-value">-</StyledTableCell>
                      {/* <StyledTableCell  className="table-value"><PlaylistAddCheckIcon/></StyledTableCell> */}
                      {/* <StyledTableCell  className="table-value"><div className='codes'>
                  <GroupAddIcon /> 
                  <GroupRemoveIcon/>
                <Stack direction="row" spacing={1} alignItems="center" className='action-ptxt'>
                    <Typography>Off</Typography>
                    <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    <Typography>On</Typography>
                </Stack>
                <EditSeller/>
                </div>
                {row?.action}
              </StyledTableCell>  */}
                      <StyledTableCell className="table-value">
                        <div className='brand-action'>
                          <Link to={`/products-seller/${row._id}`} className='approve'>Seller</Link>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell className="table-value" alignItems="center">
                        <div className='icon-space'>
                          <Button className="activate"><img src={cross} alt={cross} /></Button>
                          <Button className="activate"><img src={rightState} alt={rightState} /></Button>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell className="table-value" alignItems="center">
                        <div className='brand-action'>
                          <Link to={`/products-appove/${row._id}`} className='approve'>Approve</Link>
                          <Link onClick={handleOpen} className='cancel'>Decline</Link>
                        </div>

                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <div className='CloseOption-pop-up' onClick={handleClose}><HighlightOffIcon /></div>
                            <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                              Select the type of imported product
                            </Typography>
                          </Box>
                        </Modal>

                      </StyledTableCell>

                    </StyledTableRow>


                  ))}
                </TableBody>

              </Table>
            </div>



            <div className='tablebody'>
              <div className='pagination-numb'>
                <Pagination count={9} color="primary" shape="rounded" />
              </div>
              <div className='BtmBtn'>
                <div className='download-keys'>
                  <div className='save-icon'>{<SaveAltIcon sx={{ color: blue[500] }} />}</div>
                  <div className='print-icon'>{<LocalPrintshopOutlinedIcon />}</div>
                </div>
                <Button variant="contained" className='export'>
                  Export<img src={Files} alt={Files} />
                </Button>
              </div>

            </div>



          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='Title seller-main-body-content'>

            <div className='secound'>
              <div className='progress-bar'>
                <h4>Total stores and Total staff</h4>
                <Box sx={{ flexGrow: 1 }}>
                  <div className='progress-txt'>
                    <p>Staff</p>
                    <p>1500</p>
                  </div>
                  <BorderLinearProgress variant="determinate" value={70} />

                  <div className='progress-txt'>
                    <p>stores</p>
                    <p>991</p>
                  </div>
                  <BorderLinearProgress variant="determinate" value={40} />
                </Box>
              </div>

              <div className='seller-map'><SvgMap /></div>
            </div>

          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.footercls}>
              <Footer />
            </Item>
          </Grid>

        </Grid>
      </Box>




    </div>
  )
}

export default AdminBrands
