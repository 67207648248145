import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const DailyCost = ({ counts, dates }) => {

  const [state, setState] = useState(
    {
      series: [{
        name: "Customer Count",
        data: counts
      }],
      options: {
        chart: {
          id: "Daily_Cost",
          height: 350,
          type: 'area',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: dates
        }
      },


    }
  )

  useEffect(() => {
    setState({
      series: [{
        name: "Customer Count",
        data: counts
      }],
      options: {
        chart: {
          id: "Daily_Cost",
          height: 350,
          type: 'area',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: dates
        }
      },


    })
  }, [counts, dates])
  return (


    <div id="chart">
      <ReactApexChart options={state.options} series={state.series} type="area" height={200} />
    </div>


  )
}

export default DailyCost;