import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import rightState from '../../../img/rightState.png';
import cross from '../../../img/cross.svg'
import './BrandProductsBody.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: '0px',
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// const useStyles = makeStyles({

//   headercls: {
//     background: 'transparent !important',
//     borderRadius: '0px !important',
//     boxShadow: 'none !important',
//   },
//   containwidth: {
//     padding: '0 80px',
//     '@media (max-width: 991.98px)': {
//       padding: '0 10px',
//     },
//   },
//   rightpartstyle: {
//     paddingLeft: '50px',
//     '@media (max-width: 991.98px)': {
//       paddingLeft: '0px',
//     },
//   },
//   dashboargraph: {
//     background: 'transparent !important',
//     borderRadius: '0px !important',
//     boxShadow: 'none !important',
//   },
//   addserviceline: {
//     padding: '0 80px',
//     borderRadius: '16.667px',
//     border: '0.986px solid rgba(203, 239, 255, 0.16)',
//     background: '#F2F8FF',
//     padding: '20px !important',
//     marginTop: '40px',
//     backdropFilter: 'blur(98.61111450195312px)',
//     '@media (max-width: 991.98px)': {
//       padding: '0 10px',
//     }
//   }
// });


// const styleAdminProductsFilter = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: '#fff',
//   border: 'none',
//   p: 4,
// };


const BrandProductsBody = ({ product, orderid }) => {

  // const classes = useStyles();

  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [datas, setDatas] = useState([])

  const productss = async () => {
    try {
      if (product?.length > 0) {
        var dataobjects = {}
        var dataobjects1 = {}
        // var dataobjects2 = {}
        var dataarray = []

        for (var i = 0; i < product.length; i++) {
          if (product[i].service === "Certificate Creation") {
            if (product[i].payment_processing_fee != "undefined" && product[i].positive_balance != undefined) {
              dataobjects = {

                service: product[i].service,
                product_id: product[i].product_id,
                image: product[i]?.details?.product_details[0]?.main_image,
                price: product[i]?.amount,
                dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
                brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
                status: product[i]?.details?.product_details[0]?.status,
                billing: product[i]?.billing,
                payment_processing_fee: product[i]?.payment_processing_fee,
                positive_balance: product[i]?.positive_balance,
                createdAt: product[i]?.createdAt
              }

            } else {
              dataobjects = {

                service: product[i].service,
                product_id: product[i].product_id,
                image: product[i]?.details?.product_details[0]?.main_image,
                price: product[i]?.amount,
                dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
                brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
                status: product[i]?.details?.product_details[0]?.status,
                billing: product[i]?.billing,
                payment_processing_fee: 0,
                positive_balance: 0,
                createdAt: product[i]?.createdAt

              }
            }

            dataarray.push(dataobjects)

          }
          if (product[i].service === "Warranty Extend") {
            if (product[i].payment_processing_fee != undefined && product[i].positive_balance != undefined) {
              dataobjects1 = {
                service: product[i].service,
                product_id: product[i].product_id,
                image: product[i]?.details?.product_details[0]?.main_image,
                price: product[i]?.amount,
                dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
                brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
                status: product[i]?.details?.product_details[0]?.status,
                billing: product[i]?.billing,
                payment_processing_fee: product[i]?.payment_processing_fee,
                positive_balance: product[i]?.positive_balance,
                createdAt: product[i]?.createdAt

              }
            } else {
              dataobjects1 = {

                service: product[i].service,
                product_id: product[i].product_id,
                image: product[i]?.details?.product_details[0]?.main_image,
                price: product[i]?.amount,
                dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
                brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
                status: product[i]?.details?.product_details[0]?.status,
                billing: product[i]?.billing,
                payment_processing_fee: 0,
                positive_balance: 0,
                createdAt: product[i]?.createdAt

              }
            }
            dataarray.push(dataobjects1)

          }
          if (product[i].service === "Change OwnerShip") {
            if (product[i].payment_processing_fee != "undefined" && product[i].positive_balance != undefined) {
              dataobjects = {

                service: product[i].service,
                product_id: product[i].product_id,
                image: product[i]?.details?.product_details[0]?.main_image,
                price: product[i]?.amount,
                dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
                brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
                status: product[i]?.details?.product_details[0]?.status,
                billing: product[i]?.billing,
                payment_processing_fee: product[i]?.payment_processing_fee,
                positive_balance: product[i]?.positive_balance,
                createdAt: product[i]?.createdAt

              }

            } else {
              dataobjects = {

                service: product[i].service,
                product_id: product[i].product_id,
                image: product[i]?.details?.product_details[0]?.main_image,
                price: product[i]?.amount,
                dtracer_fee: product[i]?.details?.product_details[0]?.Dtrace_Cer_Fee,
                brand_fee: product[i]?.details?.product_details[0]?.Brand_Cer_Fee,
                status: product[i]?.details?.product_details[0]?.status,
                billing: product[i]?.billing,
                payment_processing_fee: 0,
                positive_balance: 0,
                createdAt: product[i]?.createdAt

              }
            }

            dataarray.push(dataobjects)

          }
        }
        setDatas(dataarray)
      }
      else {
        setDatas([])
      }
      // console.log("🚀 ~ file: BrandProductsBody.js:178 ~ products ~ data?.result:", data?.result)
    } catch (error) {
      console.log("🚀 ~ file: AdminProductsBody.js:106 ~ products ~ error:", error)
    }
  }

  useEffect(() => {
    productss()
  }, [])
  useEffect(() => {
    productss()
  }, [product])


  return (
    <div className='dashboard-body brand-post'>
      <Box sx={{ flexGrow: 1 }}>

        <Grid container>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className='InvoicePaidTable'>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className='invoiceTableHead'>
                  <TableRow className='invoiceTableRow'>
                    <StyledTableCell className="head-part-table">order id</StyledTableCell>
                    <StyledTableCell className="head-part-table">Date </StyledTableCell>
                    <StyledTableCell className="head-part-table">Service </StyledTableCell>
                    <StyledTableCell className="head-part-table">Product ID</StyledTableCell>
                    <StyledTableCell className="head-part-table">Image</StyledTableCell>
                    <StyledTableCell className="head-part-table">Sale price</StyledTableCell>
                    <StyledTableCell className="head-part-table">Dtracer Fee</StyledTableCell>
                    <StyledTableCell className="head-part-table">Brand Fee</StyledTableCell>
                    <StyledTableCell className="head-part-table">Payment processing fee</StyledTableCell>
                    <StyledTableCell className="head-part-table">Positive balance</StyledTableCell>
                    <StyledTableCell className="head-part-table">Status</StyledTableCell>
                    {/* <StyledTableCell className="head-part-table">invoice</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody className='invoiceTableBody'>
                  {datas?.length > 0 ? <>{datas?.length > 0 && datas.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" className="table-value">100{index + 1}</StyledTableCell>
                      <StyledTableCell className="table-value">{row?.createdAt?.split('T')[0]}</StyledTableCell>
                      <StyledTableCell className="table-value">{row?.service}</StyledTableCell>
                      <StyledTableCell className="table-value">{row?.product_id}</StyledTableCell>
                      <StyledTableCell className="table-value"><img src={row?.image} width={44} height={63} /></StyledTableCell>
                      <StyledTableCell className="table-value">{row?.price}</StyledTableCell>
                      <StyledTableCell className="table-value">{row?.dtracer_fee}%</StyledTableCell>
                      <StyledTableCell className="table-value">{row?.brand_fee}%</StyledTableCell>
                      <StyledTableCell className="table-value">{(row?.payment_processing_fee).toFixed(2)}</StyledTableCell>
                      <StyledTableCell className="table-value">{(row?.positive_balance).toFixed(2)}</StyledTableCell>
                      <StyledTableCell className="table-value ">{row?.billing?.payment_status === "1" ? <Button className="activate"><img src={rightState} alt={rightState} /></Button> : <Button className="activate"><img src={cross} alt={cross} /></Button>}</StyledTableCell>
                      {/* <StyledTableCell className="table-value "><InvoicesPopup row={row}/></StyledTableCell> */}


                    </StyledTableRow>
                  ))}</> : <TableRow>
                    <TableCell component="th" scope="row" className='body-bg' colSpan={10}>No Data Found</TableCell>

                  </TableRow>}
                </TableBody>
              </Table>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default BrandProductsBody
