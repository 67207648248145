import React, { useEffect, useState } from "react";
import "./AdminSellersAuth.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import send from '../../../img/send-msg.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRef } from "react";
import Axios from "../../../Axios";
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';



// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1207,
    // bgcolor: 'background.paper',
    border: "2px solid #000",
    p: 4,
};

const AddSellersPopup2 = ({ sts, selected }) => {
    console.log(selected, "asfdsfd")
    // const classes = useStyles();
    const user = window.localStorage.getItem('AdminToken')
    const role = window.localStorage.getItem('Squelch')

    const navigate = useNavigate()


    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
        setAge("")
    };

    const [createopen, createsetOpen] = React.useState(false);
    const createhandleOpen = () => createsetOpen(true);
    const createhandleClose = () => createsetOpen(false);

    useEffect(() => {
        profile()
    }, [])

    const profile = async () => {
        if (role === 'brand') {
            const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
                headers: {
                    Authorization: user
                }
            })
            console.log(data.result.is_active, "datass")
            if (data.result.is_active === false) {
                window.localStorage.removeItem('AdminToken')
                window.localStorage.removeItem('Squelch')
                navigate(`${consts.route}/`, { state: { data: true } })
                console.log("Logout")
            }

        }
    }

    const handleOpen = async () => {
        console.log(role, "rols")
        if (role === 'brand') {
            console.log(role, "rols")
            const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
                headers: {
                    Authorization: user
                }
            })
            if (data.result.is_active === false) {
                window.localStorage.removeItem('AdminToken')
                window.localStorage.removeItem('Squelch')
                navigate(`${consts.route}/`, { state: { data: true } })
            } else {
                setOpen(true);
                console.log(role, "rols")
            }
        } else {
            setOpen(true);
        }
    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const rows = [
        createData('USR22', '43y6p2'),
        createData('USR23', '43y6p2'),
        createData('USR24', '43y6p2'),
    ];

    function createData(
        dueDate,
        service

    ) {
        return { dueDate, service };
    }

    const [storeopen, storesetOpen] = React.useState(false);
    const storehandleOpen = () => storesetOpen(true);
    const storehandleClose = () => storesetOpen(false);
    const [cateda, setCateda] = useState(["Electonic Devices", "Unique Goods", "Fashion Goods", "Pharmecy and Healths Goods", "Goods Luxury items", "Agriculture", "Cattle Rasing", "Food and Drinks", "Manufacturing and Automotive", "Raw Materials", "Sports Articles", "Agrochemicals", "Jewels", "Watches", "Game and Video Game", "Documentation", "Others"])

    const storerows = [
        createData('SF1001', 'SF1001', '786543'),
        createData('SF1002', 'SF1002', '786543'),
        createData('SF1003', 'SF1003', '786543'),
        createData('SF1004', 'SF1004', '786543'),
        createData('SF1005', 'SF1005', '786543')

    ];

    function createData(
        dueDate,
        service,
        password

    ) {
        return { dueDate, service, password };
    }

    const [deltaopen, deltasetOpen] = React.useState(false);
    const deltahandleOpen = () => deltasetOpen(true);
    const deltahandleClose = () => deltasetOpen(false);


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    var [dts, setDts] = useState(selected)

    const product_name = useRef()
    const brand_name = useRef()
    const category = useRef()
    const product_identifier = useRef()
    const ean = useRef()
    const initial_stock = useRef()
    const unit_measurement = useRef()
    const measurement = useRef()
    const mainimg = useRef()
    const detailimg1 = useRef()
    const detailimg2 = useRef()
    const detailimg3 = useRef()
    const product_description = useRef()
    const material = useRef()
    const ingredients = useRef()
    const depature_location = useRef()
    const coordinates = useRef()
    const license_link = useRef()
    // const warrenty = useRef()
    const duration_guarantee = useRef()
    const extra_warrenty = useRef()
    const warrenty_price = useRef()
    const type_currency = useRef()
    const phy_pro_vir = useRef()
    const Production_date = useRef()
    const lot_number = useRef()
    const classification_num = useRef()
    const toxic = useRef()
    const substance_type = useRef()
    const composition = useRef()
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [allows, setallows] = useState("false");
    const [visuals, setvisuals] = useState("false");
    const [unitsmeasure, setunitsmeasure] = useState();
    const [warrenty, setWarrenty] = useState()
    const handleunitsmeasureChange = (event) => {
        setunitsmeasure(event.target.value);
    };
    const handlevisualsChange = (event) => {
        setvisuals(event.target.value);
    };
    const handleAllowsChange = (event) => {
        setallows(event.target.value);
    };
    const handleAllowsChange1 = (event) => {
        setWarrenty(event.target.value);
    };
    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const submits = async () => {
        try {
            const datas = {
                Product_Name: product_name.current.value,
                product_category: age,
                product_identifier: product_identifier?.current?.value,
                ean: ean?.current?.value,
                brand_name: brand_name?.current?.value,
                Initial_stock: initial_stock?.current?.value,
                Unit_of_measurement: unitsmeasure,
                Measures: measurement?.current?.value,
                main_image: mainimg?.current?.value,
                Detail1_image: detailimg1?.current?.value,
                Detail2_image: detailimg2?.current?.value,
                Detail3_image: detailimg3?.current?.value,
                product_description: product_description?.current?.value,
                material: material?.current?.value,
                ingredients: ingredients?.current?.value,
                departure_location: depature_location?.current?.value,
                Cordenate_location: coordinates?.current?.value,
                License_Attachments: license_link?.current?.value,
                Warranty: warrenty,
                Duration_of_the_official_warranty: duration_guarantee?.current?.value,
                Allows_warranty_extension: allows,
                Extra_warranty_time: extra_warrenty?.current?.value,
                Warranty_extension_price: warrenty_price?.current?.value,
                Type_of_currency: selectedCurrency,
                Physical_product_1_or_virtual_0: visuals,
                production_date: Production_date?.current?.value,
                LOT_NUMBER: lot_number?.current?.value,
                UN_Product_Classification_Number: classification_num?.current?.value,
                Toxic: toxic?.current?.value,
                substance_type: substance_type?.current?.value,
                composition: composition?.current?.value,
                isExtended: 0
            }
            // console.log(datas,"currency")
            // const { data } = await Axios.post(`/users/createproduct`, {
            const { data } = await Axios.post(`/users/productDetails`, {
                products: [datas]
            },
                {
                    headers: {
                        Authorization: localStorage.getItem("AdminToken"),
                    },
                }
            )
            if (data.success === true) {
                toast.success("Product Added Successfully")
                handleClose()
                sts(true)
            } else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ submits ~ error:", error)
        }

    }

    const userrole = window.localStorage?.getItem('Squelch')

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    return (
        <>
            {
                userrole !== 'admin' ?
                    <div className='add-seller'>
                        <div className='filter-box account'>
                            <Button className='down-info2' onClick={handleOpen}>Edit</Button>
                        </div>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="popBOX add-seller">
                                <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                                <div className="headTitle">Update Product</div>
                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Product Name
                                        </Typography>
                                        {/* {console.log(selected?.Product_Name, "asfdsfd")} */}
                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                value={dts?.Product_Name}
                                                onChange={(e) => { setDts({ ...dts, Product_Name: e.target.value }) }}
                                            // inputRef={product_name}
                                            />
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Brand Name
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                // inputRef={brand_name}
                                                value={dts?.brand_name}
                                                onChange={(e) => { setDts({ ...dts, brand_name: e.target.value }) }}
                                            />
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Category
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            {/* <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={category}
                      /> */}

                                            <FormControl fullWidth>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    label="Age"
                                                    onChange={handleChange}
                                                >
                                                    {cateda.map(cdata => (<MenuItem value={cdata}>{cdata}</MenuItem>))}

                                                    {/* <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                            </FormControl>

                                        </div>
                                    </div>


                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Product Identifier
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={product_identifier}
                                                value={dts?.product_identifier}
                                                onChange={(e) => { setDts({ ...dts, product_identifier: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Ean Number
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={ean}
                                                value={dts?.ean}
                                                onChange={(e) => { setDts({ ...dts, ean: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Initial Stock
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={initial_stock}
                                                value={dts?.Initial_stock}
                                                onChange={(e) => { setDts({ ...dts, Initial_stock: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>


                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"


                                        >
                                            Unit Of Measurement
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <Select
                                                id="currencySelect"
                                                value={unitsmeasure}
                                                onChange={(e) => { handleunitsmeasureChange(e) }}
                                                variant="outlined"
                                                className="textField"
                                            >

                                                <MenuItem value="cm">cm</MenuItem>
                                                <MenuItem value="cm2">cm2</MenuItem>
                                                <MenuItem value="cm3">cm3</MenuItem>

                                                <MenuItem value="km">km</MenuItem>
                                                <MenuItem value="hm">hm</MenuItem>
                                                <MenuItem value="dam">dam</MenuItem>
                                                <MenuItem value="m">m</MenuItem>
                                                <MenuItem value="dm">dm</MenuItem>
                                                <MenuItem value="mm">mm</MenuItem>

                                                <MenuItem value="km2">km2</MenuItem>
                                                <MenuItem value="hm2">hm2</MenuItem>
                                                <MenuItem value="dam2">dam2</MenuItem>
                                                <MenuItem value="m2">m2</MenuItem>
                                                <MenuItem value="dm2">dm2</MenuItem>
                                                <MenuItem value="mm2">mm2</MenuItem>

                                                <MenuItem value="kg">kg</MenuItem>
                                                <MenuItem value="hg">hg</MenuItem>
                                                <MenuItem value="dag">dag</MenuItem>
                                                <MenuItem value="g">g</MenuItem>
                                                <MenuItem value="dg">dg</MenuItem>
                                                <MenuItem value="cg">cg</MenuItem>
                                                <MenuItem value="mg">mg</MenuItem>

                                                <MenuItem value="kl">kl</MenuItem>
                                                <MenuItem value="hl">hl</MenuItem>
                                                <MenuItem value="dal">dal</MenuItem>
                                                <MenuItem value="l">l</MenuItem>
                                                <MenuItem value="dl">dl</MenuItem>
                                                <MenuItem value="cl">cl</MenuItem>
                                                <MenuItem value="ml">ml</MenuItem>

                                                <MenuItem value="km3">km3</MenuItem>
                                                <MenuItem value="hc3">hc3</MenuItem>
                                                <MenuItem value="dac3">dac3</MenuItem>
                                                <MenuItem value="m3">m3</MenuItem>
                                                <MenuItem value="dm3">dm3</MenuItem>
                                                <MenuItem value="mm3">mm3</MenuItem>

                                                <MenuItem value="KG">KG</MenuItem>
                                                <MenuItem value="s">s</MenuItem>
                                                <MenuItem value="K">K</MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="mol">mol</MenuItem>
                                                <MenuItem value="m2 o L">m2 o L</MenuItem>

                                                <MenuItem value="kg/m2">kg/m2</MenuItem>
                                                <MenuItem value="Hz">Hz</MenuItem>
                                                <MenuItem value="N">N</MenuItem>
                                                <MenuItem value="J">J</MenuItem>
                                                <MenuItem value="Pa">Pa</MenuItem>
                                                <MenuItem value="W">W</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                                <MenuItem value="V">V</MenuItem>
                                                <MenuItem value="Omega">Omega</MenuItem>

                                                <MenuItem value="Sv">Sv</MenuItem>
                                                <MenuItem value="Kl">Kl</MenuItem>
                                                <MenuItem value="in">in</MenuItem>
                                                <MenuItem value="ft">ft</MenuItem>
                                                <MenuItem value="yd">yd</MenuItem>
                                                <MenuItem value="rd">rd</MenuItem>
                                                <MenuItem value="ch">ch</MenuItem>
                                                <MenuItem value="fur">fur</MenuItem>
                                                <MenuItem value="mi">mi</MenuItem>

                                                <MenuItem value="sq ft o ft2">sq ft o ft2</MenuItem>
                                                <MenuItem value="ac">ac</MenuItem>
                                                <MenuItem value="sq yd o yd2">sq yd o yd2</MenuItem>
                                                <MenuItem value="sq rd o rd2">sq rd o rd2</MenuItem>
                                                <MenuItem value="sq mi o mi2">sq mi o mi2</MenuItem>

                                                <MenuItem value="gr">gr</MenuItem>
                                                <MenuItem value="oz">oz</MenuItem>
                                                <MenuItem value="lb">lb</MenuItem>
                                                <MenuItem value="st">st</MenuItem>
                                                <MenuItem value="US ctw">US ctw</MenuItem>
                                                <MenuItem value="UK ctw">UK ctw</MenuItem>
                                                <MenuItem value="US qtr">US qtr</MenuItem>
                                                <MenuItem value="Uk ctw">Uk qtr</MenuItem>
                                                <MenuItem value="US ton">US ton</MenuItem>
                                                <MenuItem value="Uk ton">Uk ton</MenuItem>

                                                <MenuItem value="in3 o cu in">in3 o cu in</MenuItem>
                                                <MenuItem value="ft3 o cu ft">ft3 o cu ft</MenuItem>
                                                <MenuItem value="yd3 o cu yd">yd3 o cu yd</MenuItem>
                                                <MenuItem value="mi3 o cu mi">mi3 o cu mi</MenuItem>

                                                <MenuItem value="pt">pt</MenuItem>
                                                <MenuItem value="gal">gal</MenuItem>
                                                <MenuItem value="pt">pk</MenuItem>
                                                <MenuItem value="bu">bu</MenuItem>

                                                <MenuItem value="fl dr">fl dr</MenuItem>
                                                <MenuItem value="fl oz">fl oz</MenuItem>
                                                <MenuItem value="qt">qt</MenuItem>
                                                <MenuItem value="b">b</MenuItem>

                                                <MenuItem value="KB">KB</MenuItem>
                                                <MenuItem value="MB">MB</MenuItem>
                                                <MenuItem value="GB">GB</MenuItem>
                                                <MenuItem value="TB">TB</MenuItem>
                                                <MenuItem value="PB">PB</MenuItem>
                                                <MenuItem value="EB">EB</MenuItem>
                                                <MenuItem value="ZB">ZB</MenuItem>
                                                <MenuItem value="YB">YB</MenuItem>
                                                <MenuItem value="BB">BB</MenuItem>

                                                <MenuItem value="Kbps">Kbps</MenuItem>
                                                <MenuItem value="Mbps">Mbps</MenuItem>
                                                <MenuItem value="Gbps">Gbps</MenuItem>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Measures
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                // inputRef={measurement}
                                                value={dts?.Measures}
                                                onChange={(e) => { setDts({ ...dts, Measures: e.target.value }) }}

                                            />
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Main Image Url
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                // inputRef={mainimg}
                                                value={dts?.main_image}
                                                onChange={(e) => { setDts({ ...dts, main_image: e.target.value }) }}

                                            />
                                            <div style={{ color: "blue", textAlign: 'left', fontSize: "10px" }}>* Give Image URL</div>
                                        </div>
                                    </div>


                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Detail Image1
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                // inputRef={detailimg1}
                                                value={dts?.Detail1_image}
                                                onChange={(e) => { setDts({ ...dts, Detail1_image: e.target.value }) }}

                                            />
                                            <div style={{ color: "blue", textAlign: 'left', fontSize: "10px" }}>* Give Image URL</div>
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Detail Image2
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={detailimg2}
                                                value={dts?.Detail2_image}
                                                onChange={(e) => { setDts({ ...dts, Detail2_image: e.target.value }) }}
                                                className="textField"
                                            />
                                            <div style={{ color: "blue", textAlign: 'left', fontSize: "10px" }}>* Give Image URL</div>
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Detail Image3
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={detailimg3}
                                                value={dts?.Detail3_image}
                                                onChange={(e) => { setDts({ ...dts, Detail3_image: e.target.value }) }}
                                                className="textField"
                                            />
                                            <div style={{ color: "blue", textAlign: 'left', fontSize: "10px" }}>* Give Image URL</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Product Description
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={product_description}
                                                value={dts?.product_description}
                                                onChange={(e) => { setDts({ ...dts, product_description: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Material
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={material}
                                                value={dts?.material}
                                                onChange={(e) => { setDts({ ...dts, material: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Ingredients
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={ingredients}
                                                value={dts?.ingredients}
                                                onChange={(e) => { setDts({ ...dts, ingredients: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Departure Location
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                // inputRef={depature_location}
                                                value={dts?.departure_location}
                                                onChange={(e) => { setDts({ ...dts, departure_location: e.target.value }) }}

                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Coordinates (lat,lang)
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={coordinates}
                                                value={dts?.Cordenate_location}
                                                onChange={(e) => { setDts({ ...dts, Cordenate_location: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Licence Attachment Link
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={license_link}
                                                value={dts?.Attachments}
                                                onChange={(e) => { setDts({ ...dts, Attachments: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            warranty
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <Select
                                                id="currencySelect"
                                                value={warrenty}
                                                onChange={handleAllowsChange1}
                                                variant="outlined"
                                                className="textField"
                                            >

                                                <MenuItem value="yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>

                                            </Select>
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Duration of Warranty(in months)
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={duration_guarantee}
                                                value={dts?.Duration_of_the_official_warranty}
                                                onChange={(e) => { setDts({ ...dts, Duration_of_the_official_warranty: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Extra warranty(in months)
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={extra_warrenty}
                                                value={dts?.Extra_warranty_time}
                                                onChange={(e) => { setDts({ ...dts, Extra_warranty_time: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            warranty Extension Price
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={warrenty_price}
                                                value={dts?.Warranty_extension_price}
                                                onChange={(e) => { setDts({ ...dts, Warranty_extension_price: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Allows warranty extension
                                        </Typography>
                                        <div className="cmn-input-cls">
                                            <Select
                                                id="currencySelect"
                                                value={allows}
                                                onChange={handleAllowsChange}
                                                variant="outlined"
                                                className="textField"
                                            >

                                                <MenuItem value="true">Yes</MenuItem>
                                                <MenuItem value="false">No</MenuItem>

                                            </Select>
                                        </div>
                                    </div>
                                    {/* <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Type of Currency
                    </Typography> */}
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Type of Currency
                                        </Typography>
                                        <div className="cmn-input-cls">
                                            <Select
                                                id="currencySelect"
                                                value={selectedCurrency}
                                                onChange={handleCurrencyChange}
                                                variant="outlined"
                                                className="textField"
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Currency
                                                </MenuItem>
                                                <MenuItem value="$">$</MenuItem>
                                                <MenuItem value="€">€</MenuItem>


                                            </Select>
                                        </div>
                                    </div>


                                </div>

                                <div className="listOne">
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Physical Product 0 or Virtual Product 1
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <Select
                                                id="currencySelect"
                                                name="Physical_product_1_or_virtual_0"
                                                onChange={(e) => { handlevisualsChange(e) }}
                                                value={visuals}
                                                variant="outlined"
                                                className="textField"
                                            >

                                                <MenuItem value="0">0</MenuItem>
                                                <MenuItem value="1">1</MenuItem>

                                            </Select>
                                            {/* <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={phy_pro_vir}
                        className="textField"
                      /> */}
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Production Date
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="textField"
                                                // inputRef={Production_date}
                                                value={dts?.production_date}
                                                onChange={(e) => { setDts({ ...dts, production_date: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            LOT number
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={lot_number}
                                                value={dts?.LOT_NUMBER}
                                                onChange={(e) => { setDts({ ...dts, LOT_NUMBER: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>


                                </div>

                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Production Classification Number
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={classification_num}
                                                value={dts?.UN_Product_Classification_Number}
                                                onChange={(e) => { setDts({ ...dts, UN_Product_Classification_Number: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Toxic
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={toxic}
                                                value={dts?.Toxic}
                                                onChange={(e) => { setDts({ ...dts, Toxic: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>
                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Substance Type
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={substance_type}
                                                value={dts?.substance_type}
                                                onChange={(e) => { setDts({ ...dts, substance_type: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="listOne">

                                    <div className="productId">
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h2"
                                            className="modelTypo"
                                        >
                                            Composition
                                        </Typography>

                                        <div className="cmn-input-cls">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                // inputRef={composition}
                                                value={dts?.composition}
                                                onChange={(e) => { setDts({ ...dts, composition: e.target.value }) }}
                                                className="textField"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="filter-box account">
                                    {/* <Button onClick={() => { handleClose() }}>Create Product</Button> */}
                                    <Button onClick={() => { submits() }}>Update Product</Button>
                                </div>

                                {/* {<Modal
              open={createopen}
              onClose={createhandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">Add Staff</div>
                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                    seller's name
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Bharath"
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                    Seller delta
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="kumar"
                        className="textField"
                      />
                    </div>
                  </div>

                </div>


                <div className="listOne">

                  <div className='SellerPaidTable'>
                      <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>
                              <StyledTableCell className="head-part-table">user </StyledTableCell>
                              <StyledTableCell className="head-part-table">password</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                          {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell className="table-value">{row.dueDate}</StyledTableCell>
                              <StyledTableCell  className="table-value">{row.service}</StyledTableCell>

                            </StyledTableRow>

                            
                          ))}
                        </TableBody>
                    </Table>
                    </div>
                </div>

                <div className="add-staff">
                  <div className='user-txt'>
                      <h4>User</h4>
                      <p>If you don't know the name of the staff, leave it blank and we will create a unique ID for that staff.</p>
                    </div>
                    
                    <div className='user-txt'>
                      <h4>Password</h4>
                      <p>If you do not know what password to assign, leave the space blank and we will assign a random password for each staff</p>
                    </div>
                  </div>

              <div className="filter-box account add">
                  <Button onClick={createhandleClose} className='cancel'>Cancel</Button>
                  <Button onClick={storehandleOpen}>Generate user</Button>
              </div>

              </Box>
            </Modal>} */}


                                {/* {<Modal
              open={storeopen}
              onClose={storehandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>

                <div className="listOne">

                  <div className='SellerPaidTable'>
                      <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>
                              <StyledTableCell className="head-part-table">staff number </StyledTableCell>
                              <StyledTableCell className="head-part-table">id user</StyledTableCell>
                              <StyledTableCell className="head-part-table">password</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                          {storerows.map((storerow) => (
                            <StyledTableRow key={storerow.name}>
                              <StyledTableCell className="table-value">{storerow.dueDate}</StyledTableCell>
                              <StyledTableCell  className="table-value">{storerow.service}</StyledTableCell>
                              <StyledTableCell  className="table-value">{storerow.password}<RefreshIcon/></StyledTableCell>
                            </StyledTableRow>

                            
                          ))}
                        </TableBody>
                    </Table>
                    </div>
                </div>


              <div className="filter-box account add">
                  <Button onClick={deltahandleOpen}>send by email,the passwords to the seller</Button>
                  <Button className='cancel'>Generate all password</Button>
              </div>

              </Box>
            </Modal>} */}


                                {/* {<Modal
              open={deltaopen}
              onClose={deltahandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>

                <div className="message">
                    <img src={send} alt="closeMenu" />
                    <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                </div>


              <div className="filter-box account add">
                  <Button>send by email,the passwords to the seller</Button>
                  <Button className='cancel'>Generate all password</Button>
              </div>

              </Box>
            </Modal>} */}


                            </Box>
                        </Modal>

                    </div> :
                    <></>
            }
        </>


    );
};

export default AddSellersPopup2;
