import React, { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PaidIcon from '@mui/icons-material/Paid';
import Axios from "../../../Axios";
import { Toaster, toast } from 'react-hot-toast'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const PaidPop = ({ row, status }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [doc, setDoc] = useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); }


  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const getFile = async (e) => {
    try {
      let files = await e.target.files[0]
      let formData = await new FormData();
      formData.append("image", files)

      const { data } = await Axios.post('/users/uploadlogo', formData)

      if (data.result) {
        setDoc(data.result)
        console.log(data.result, "image")
        toast.success('File uploaded Successfully')

      }

    } catch (error) {
      console.log(error, "err")
    }
  }

  const submit = async () => {
    console.log(doc, "Document")
    try {
      const { data } = await Axios.post(`/admin/approve_billing`, {
        invoice_id: row?._id,
        doc: doc
      }, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      if (data?.success === true) {
        toast.success(data?.message)
        handleClose()
        status()
      }
    } catch (error) {
      console.log("🚀 ~ ~ error:", error)
      if (error?.response?.data) {
        toast.error(error?.response?.data?.message)
      }
    }

  }
  return (
    <div >
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="qr-detail cancel-invoice">


        <Button onClick={handleOpen} ><PaidIcon /></Button>

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="popBOX BlockPopup cancel-invoice">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


            <div className="invoice-create">
              <p className="new-invoice">Mark as paid</p>
              <p className="paid-txt">You are going to mark the invoice ID {row?.Invoice_Id} dated [DATE] as paid. This action is not reversible. Are you happy to complete this operation?</p>
              <p><b>Do you want to attach proof of income</b></p>
            </div>

            <div className="file-upload">
              <Button component="label" className="upload-box" startIcon={<CloudUploadIcon />}>Upload your files here
                <VisuallyHiddenInput type="file" onChange={(e) => { getFile(e) }} />
              </Button>
            </div>

            <div className="create-cancel-btn invoice-reject">
              <div className="create-btn">
                <Button className="create-green-btn" onClick={() => { submit() }} >Mark As Paid</Button>
              </div>
              <div className="create-btn">
                <Button className="cancel-green-btn" onClick={() => { handleClose() }}>Cancel</Button>
              </div>

            </div>
          </Box>
        </Modal>
      </div>
    </div>


  );
};

export default PaidPop;
