import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'

import "./Home.css"
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import warrentylogo from "../../../img/logo-warrenty.png"
import locationMark from '../../../img/locationMark.png';
import AdminMenuSide from '../../Admin/AdminMenuSide';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { marker, Icon } from 'leaflet';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
      },
    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const Home = () => {

    const location = useLocation()
    const [details, setDetails] = useState()
    useEffect(() => {
        // console.log(location.state.data, "state")
        setDetails(location?.state?.data)
    }, [location?.state?.data])
    const classes = useStyles();

    const [untill, setUntill] = useState()
    const [validMonth, setValidMonth] = useState()
    function getMonthDifference(startDate, endDate) {
        console.log(startDate, endDate, "startend")
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }

    useEffect(() => {
        console.log(details?.product_details)
        if (details) {
            let matches = details?.product_details?.Warranty?.match(/(\d+)/)
            if (matches?.length > 0) {
                if (details?.product_details?.purchadeAt) {
                    var dt = details?.product_details?.purchadeAt?.split('/')
                    var newdt = Number(dt[2]) + Number(matches[0])
                    setUntill(`${dt[0]}/${dt[1]}/${newdt}`)

                    const valid = getMonthDifference(new Date(), new Date(`${newdt}-${dt[1]}-${dt[0]}`))
                    setValidMonth(valid)
                    console.log("🚀 ~ file: Home.js:101 ~ useEffect ~ valid:", valid)
                }
            }
        }
    }, [details])


    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    const states = {
        center: [51.505, -0.091],
        zoom: 3,
    };

    const customIcon = new Icon({
        iconUrl: locationMark,
        iconSize: [23, 30]
    })

    const [foucsLocation, setFocusLocation] = useState()

    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />
                            </div>

                        </Item>

                        <div className='extented-warrenty' onClick={closeMenuHandler}>

                            <div className='warrenty-body'>
                                <div className='back-arrow-left'>
                                    <Link to="/user/dashboard"><img src={Menuclose} alt="close" /></Link>
                                </div>
                                <div className='watch-div'>
                                    <img src={details?.product_details?.main_image} alt="watch" />
                                </div>
                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        {details?.product_details?.Product_Name}
                                    </div>
                                    <div className='watch-description'>
                                        {details?.product_details?.product_description}
                                    </div>
                                    {details?.product_details?.status !== "2" ? <>
                                        <div className='map-component'>
                                            {
                                                details != undefined &&
                                                <Map center={[details?.product_details?.latitude, details?.product_details?.longitude]} zoom={states?.zoom}>
                                                    <TileLayer
                                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                    />

                                                    <Marker position={[details?.product_details?.latitude, details?.product_details?.longitude]} icon={customIcon} >
                                                        {/* <Popup>
                                                        <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                                        <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                                        <label>Owned By: </label> <span>{marker?.userName}</span>
                                                    </Popup> */}
                                                    </Marker>


                                                </Map>
                                            }

                                            {/* <iframe src={`https://maps.google.com/maps?q=${details?.product_details?.latitude},${details?.product_details?.longitude}&hl=es;z=14&output=embed`}></iframe> */}
                                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.42095469063!2d78.1227348!3d9.94600025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1699970698829!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                        </div> </>
                                        : <></>
                                    }
                                    {details?.product_details?.status !== "2" ? <>
                                        <div className='Guarantee'>
                                            {/* Warranty activated on {details?.product_details?.purchadeAt} Valid until {untill} */}
                                            Warranty activated on {details?.product_details?.purchadeAt} Valid until {validMonth} Months
                                        </div>
                                        <div className='warrenty-records'>
                                            Latest records on the blockchain
                                        </div>
                                        <div className='warrenty-trns'>
                                            latest transaction
                                        </div>
                                        <div className='trns-details'>
                                            <div className='trns-body'>
                                                <div className='warrenty-display'>
                                                    <div className='avatar-div'>
                                                        <Avatar>N</Avatar>
                                                    </div>
                                                    <div className='reference-body'>
                                                        <div className='ref-text'>
                                                            0x5fhheij556dvjhbrfb..
                                                        </div>
                                                        <div className='ref-text2'>
                                                            26 sec ago
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='warrenty-display'>
                                                    <div className='avatar-div'>
                                                        <Avatar>N</Avatar>
                                                    </div>
                                                    <div className='reference-body'>
                                                        <div className='ref-text'>
                                                            0x5fhheij556dvjhbrfb..
                                                        </div>
                                                        <div className='ref-text2'>
                                                            26 sec ago
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <hr className='warrenty-hr' />

                                            <div className='trns-body'>
                                                <div className='warrenty-display'>
                                                    <div className='avatar-div'>
                                                        <Avatar>N</Avatar>
                                                    </div>
                                                    <div className='reference-body'>
                                                        <div className='ref-text'>
                                                            0x5fhheij556dvjhbrfb..
                                                        </div>
                                                        <div className='ref-text2'>
                                                            26 sec ago
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='warrenty-display'>
                                                    <div className='avatar-div'>
                                                        <Avatar>N</Avatar>
                                                    </div>
                                                    <div className='reference-body'>
                                                        <div className='ref-text'>
                                                            0x5fhheij556dvjhbrfb..
                                                        </div>
                                                        <div className='ref-text2'>
                                                            26 sec ago
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>




                                        </div></> : <>
                                        <div className='Guarantees'>
                                            {/* Warranty activated on {details?.product_details?.purchadeAt} Valid until {untill} */}
                                            This Product is Blocked
                                        </div>
                                        <div className='watch-description'>
                                            Reason:
                                        </div>
                                        {details?.product_details?.reason.length > 0 ? <>
                                            {details?.product_details?.reason && details?.product_details?.reason.map((reasons, index) => {
                                                return <div className='watch-description' key={index}>
                                                    {index + 1}:{reasons}
                                                </div>
                                            })}
                                        </> : <></>}
                                    </>}
                                    <div className='user-logo'>
                                        <img src={warrentylogo} alt="lg-1" />
                                        DTRACER.IO
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Home
