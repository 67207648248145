import React, { useEffect, useState } from "react";
import "./Invoices.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from '@mui/styles';
import Axios from '../../../Axios';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import logo from '../../../img/dtlogo.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import actionps4 from '../../../img/action-ps-4.png';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import { Toaster, toast } from 'react-hot-toast'
import { useRef } from 'react';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import closeMenu from "../../../img/closemenu.png";
import File from "../../../img/system-file.svg";
import Files from '../../../img/files.svg';
import Print from '../../../img/print.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },
  containwidth: {
    padding: '0 30px',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name,
  calories,
  fat,
  carbs,
  protein
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('order id 10001 warranty extension product id add-1232-11', 1, '44,10$', '0%', '44,10$'),
];

const InvoicesPopup = ({item}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editopen, seteditOpen] = useState(false);
  const handleeditOpen = () => seteditOpen(true);
  const [date, setDate] = useState()
  const [expDate, setExpDate] = useState()
  const [totalprice, setTotalPrice] = useState(null)
  const [price, setprice] = useState(item?.billing?.price)
  const [priceerr, setpriceerr] = useState(null)
  const [totalpriceerr, settotalpriceerr] = useState(null)
  const [discounterr, setdiscounterr] = useState(null)
  const handleeditClose = () => {
    seteditOpen(false)
    setpriceerr('')
    settotalpriceerr('')
    setdiscounterr('')
  }
 
  const handleChangeprice = (e) => {
    setpriceerr(null)
    setprice(e.target.value)
  }

  

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

   return (
    <div className="invoicePopup">
      <img src={actionps4} onClick={handleOpen} alt="actionps4" />
     
      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
          <div >
            <div id="alert-dialog-description" className='product-head'>
              <div className="logo-dt">
                <img src={logo} alt="dtlogo" />
              </div>
            </div>
            <Grid container spacing={0} className='whole-grid-value'>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <div className="dtllc-head para-mar-adj">
               {/* {console.log(item,"item")} */}
                <p><b> Payment To</b></p>
                <p className="llc-head">Dtracer</p>
                 <p className="dtrace">1210 Washington Avenue,</p>
                 <p className="dtrace">Miami Beach,Florida </p>
                 <p className="dtrace">United States</p>
                 
                </div>

                <div className="client-llc para-mar-adj">
                  <div className="client-llc-direct">
                  
                  <div className="dtllc-head1 para-mar-adj">
                  <p className="llc-heads">Client</p>
                  <p className="llc-head">{item?.billing?.Brand_Name}</p>
                  <p><b>{item?.billing?.Invoice_Id}</b></p>
                  {/* <p className="dtrace">dtracer.io</p> */}
                  <Link to="">{item?.billing?.email}</Link>
                  <p className="dtrace">{item?.billing?.Address}</p>
                  <p><b>{item?.billing?.Country}</b></p>
                </div>
                    <div className="dtr">
                      <p className="dtrace-1"> Invoice Id : {item?.billing?.Invoice_Id} </p>
                      <p className="dtrace">Date: {new Date(item?.billing?.createdAt).toLocaleDateString()}</p>
                      <p className="dtrace">Expiration: {new Date(item?.billing?.Payment_date).toLocaleDateString()}</p>
                    </div>

                  </div>
                </div>

                <div className="client-detail-table">

                  <Table sx={{ minWidth: 700 }} aria-label="customized table" className="invoice-billing-recieipt-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="head-part-table">Concept</StyledTableCell>
                        <StyledTableCell className="head-part-table">No.of.Licence</StyledTableCell>
                        <StyledTableCell className="head-part-table">Price</StyledTableCell>
                        <StyledTableCell className="head-part-table">Discount</StyledTableCell>
                        <StyledTableCell className="head-part-table">Subtotal</StyledTableCell>
                        <StyledTableCell className="head-part-table">Tax</StyledTableCell>
                        <StyledTableCell className="head-part-table">Total(Net)</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {rows.map((row) => ( */}
                      {/* <StyledTableRow key={row.name}> */}
                      <StyledTableRow >
                        <StyledTableCell className="table-value invoice-billing-recieipt-table-td">{item?.billing?.Invoice_concept}</StyledTableCell>
                        <StyledTableCell className="table-value">{item?.billing?.Quantity}</StyledTableCell>
                        <StyledTableCell className="table-value">{item?.billing?.price}</StyledTableCell>
                        <StyledTableCell className="table-value">{item?.billing?.discount}%</StyledTableCell>
                        <StyledTableCell className="table-value">{item?.billing?.price - (item?.billing?.discount / 100 * item?.billing?.price) ? (item?.billing?.price * item?.billing?.Quantity) - (item?.billing?.discount / 100 * (item?.billing?.price * item?.billing?.Quantity)) : ('-')}</StyledTableCell>
                        <StyledTableCell className="table-value">{item?.billing?.Tax_rate ? item?.billing?.Tax_rate : "0"}%</StyledTableCell>
                        <StyledTableCell className="table-value">{item?.billing?.Total_bil}</StyledTableCell>
                      </StyledTableRow>
                      {/* ))} */}
                    </TableBody>
                  </Table>

                </div>


              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="total-box invoice-billing-recieipt-pop-up ">
                  <div className="sub-total-direct">
                    <p><b>Sub Total</b></p>
                    <div className="total-value">${item?.billing?.price - (item?.billing?.discount / 100 * item?.billing?.price) ? (item?.billing?.price * item?.billing?.Quantity) - (item?.billing?.discount / 100 * (item?.billing?.price * item?.billing?.Quantity)) : ('-')}</div>
                  </div>
                  {/* <div className="sub-total-direct">
                    <p><b>Discount</b></p>
                    <div className="total-value">{item?.billing?.discount ? item?.billing?.discount : ('-')}%</div>
                  </div> */}
                  <div className="sub-total-direct">
                    <p><b>Tax %</b></p>
                    <div className="total-value">{item?.billing?.Tax_rate ? item?.billing?.Tax_rate : "0"}%</div>
                  </div>
                  <div className="sub-total-direct">
                    <p><b>Total(Net)</b></p>
                    <div className="total-value">${item?.billing?.Total_bil}</div>
                  </div>
                </div>
              </Grid>



            </Grid>
            
          </div>
        </Box>
      </Modal>
     
    </div>
  );
};

export default InvoicesPopup;
