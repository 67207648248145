import React, {useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import TextField from '@mui/material/TextField';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import Checkbox from '@mui/material/Checkbox';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

  const useStyles = makeStyles({

    modalpara:{
        fontSize: '18px',
        textAlign:'center',
        maxWidth:'750px !important',
        margin:'0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)' : {
            paddingBottom: '36px',
          },

      },

      reasonBlock:{
        fontSize:'24px',
        fontWeight:'600',
        margin:'0'
    }

  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const RejectPopup = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div >
      
           <div className="qr-detail">
      <Button onClick={handleOpen}><DoDisturbAltIcon className="red-tick" /></Button>
      <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup rejectpop">
        <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            
       <div className="reject-info">
        <div className="rejection-indication">
            <p className="reject-head">Reject product</p>
            <p className="reason-reject">You are going to reject this product(s), please indicate the reason for the rejection</p>
        </div>
       </div>
       
       <div className="check-list">
              <ul>
                <li> <Checkbox {...label}  className="box-border"/>The product does not match the description or image</li>
                <li> <Checkbox {...label}  className="box-border"/>We have not been able to validate the authenticity or veracity of the product</li>
                <li> <Checkbox {...label}  className="box-border"/>It is not a manufacturer brand of the product or it does not have distribution permits</li>
                <li> <Checkbox {...label}  className="box-border"/>Other motives</li>
              </ul>
       </div>
       
       <div className="text-box">
       <TextField className="box-size"
          id="outlined-multiline-static"
          multiline
          rows={5}
          
        />
       </div>



       <div className="decline-cancel-btn">
              <div className="decline-btn">
                <Button className="decline">Decline</Button>
              </div>
              <div className="cancel-button">
                <Button className="cancel">Cancel</Button>
              </div>
       </div>

        </Box>
      </Modal>
      </div>
    </div>

    
  );
};

export default RejectPopup;
