import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Separate from '../../../img/separate-img.svg'
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function createData(
    name,
    fat,
    carbs,
    protein,
    dob,
    phone,
    mail,
   end,
   
  ) {
    return { name,  fat, carbs, protein, dob,phone, mail, end};
  }
  
  const rows = [
    createData('0X6DEGEE45JFKS11521677', 'Transfer from', '44977100', '35 secs ago', '0X6DfGjEg....', "0X6DfGjEgsfsfsf....",  "0 matic",'0.005165164' ),
    createData('0X6DEGEE45JFKS11521677', 'Burn', '44977100', '35 secs ago', '0X6DfGjEg....', "0X6DfGjEgsfsfsf....",  "0 matic",'0.005165164'),
    createData('0X6DEGEE45JFKS11521677', 'Transfer from', '44977100', '35 secs ago', '0X6DfGjEg....', "0X6DfGjEgsfsfsf....",  "0 matic",'0.005165164'),
    createData('0X6DEGEE45JFKS11521677', 'Transfer from', '44977100', '35 secs ago', '0X6DfGjEg....', "0X6DfGjEgsfsfsf....",  "0 matic",'0.005165164'),
   
  ];
  


export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab-head-part'>
          <Tab label="Transactions" {...a11yProps(0)} className='name-part' />
          <Tab label="Internal txns" {...a11yProps(1)} className='name-part' />
          <Tab label="Erc-20 token txns" {...a11yProps(2)} className='name-part'/>
          <Tab label="Erc-1155 token txns" {...a11yProps(3)} className='name-part'/>
          <Tab label="Analytics" {...a11yProps(4)} className='name-part'/>
          <Tab label="Comments" {...a11yProps(5)} className='name-part'/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <div>
        <p>Latest 25 from a total of <b>124 transactions</b></p>
      </div>
      <div className='trans-table-block'>
      <Table className='tableScroll'>
        <TableHead>
          <TableRow className='row-head'> 
            <TableCell className='head-bg'>Tnx hash</TableCell>
            <TableCell className='head-bg'>
              <div className='eye-direct'>
              Methods
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
             </TableCell>
            <TableCell className='head-bg'><div className='eye-direct'>
              Block
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div></TableCell>
            <TableCell className='head-bg'>Age</TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            From
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            To
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>Value</TableCell>
            <TableCell className='head-bg'>[Txn fee]</TableCell>
          </TableRow>
         </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className='body-bg'>
                <div className='eye-direct'>
                <div>
                <RemoveRedEyeOutlinedIcon /> 
                </div>
                <div>
                {row.name}
                </div>
              
                </div>
                
              </TableCell>
              <TableCell className='body-bg'>{row.fat}</TableCell>
              <TableCell className='body-bg bg-border'>{row.carbs}</TableCell>
              <TableCell className='body-bg'>{row.protein}</TableCell>
              <TableCell className='body-bg'>{row.dob}</TableCell>
              <TableCell className='body-bg'>{row.phone}</TableCell>
              <TableCell className='body-bg'>{row.mail}</TableCell>
              <TableCell className='body-bg'>{row.end}</TableCell>
         
              
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div>
        <p>Latest 25 from a total of <b>124 transactions</b></p>
      </div>
      <div className='trans-table-block'>
      <Table className='tableScroll'>
      <TableHead>
          <TableRow className='row-head'> 
            <TableCell className='head-bg'>Tnx hash</TableCell>
            <TableCell className='head-bg'>
              <div className='eye-direct'>
              Methods
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
             </TableCell>
            <TableCell className='head-bg'><div className='eye-direct'>
              Block
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div></TableCell>
            <TableCell className='head-bg'>Age</TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            From
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            To
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>Value</TableCell>
            <TableCell className='head-bg'>[Txn fee]</TableCell>
          </TableRow>
         </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className='body-bg'>
               {row.name}
              </TableCell>
              <TableCell className='body-bg'>{row.fat}</TableCell>
              <TableCell className='body-bg'>{row.carbs}</TableCell>
              <TableCell className='body-bg'>{row.protein}</TableCell>
              <TableCell className='body-bg'>{row.dob}</TableCell>
              <TableCell className='body-bg'>{row.phone}</TableCell>
              <TableCell className='body-bg'>{row.mail}</TableCell>
              <TableCell className='body-bg'>{row.end}</TableCell>
         
              
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div>
        <p>Latest 25 from a total of <b>124 transactions</b></p>
      </div>
      <div className='trans-table-block'>
      <Table className='tableScroll'>
      <TableHead>
          <TableRow className='row-head'> 
            <TableCell className='head-bg'>Tnx hash</TableCell>
            <TableCell className='head-bg'>
              <div className='eye-direct'>
              Methods
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
             </TableCell>
            <TableCell className='head-bg'><div className='eye-direct'>
              Block
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div></TableCell>
            <TableCell className='head-bg'>Age</TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            From
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            To
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>Value</TableCell>
            <TableCell className='head-bg'>[Txn fee]</TableCell>
          </TableRow>
         </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className='body-bg'>
               {row.name}
              </TableCell>
              <TableCell className='body-bg'>{row.fat}</TableCell>
              <TableCell className='body-bg'>{row.carbs}</TableCell>
              <TableCell className='body-bg'>{row.protein}</TableCell>
              <TableCell className='body-bg'>{row.dob}</TableCell>
              <TableCell className='body-bg'>{row.phone}</TableCell>
              <TableCell className='body-bg'>{row.mail}</TableCell>
              <TableCell className='body-bg'>{row.end}</TableCell>
         
              
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <div>
        <p>Latest 25 from a total of <b>124 transactions</b></p>
      </div>
      <div className='trans-table-block'>
      <Table className='tableScroll'>
      <TableHead>
          <TableRow className='row-head'> 
            <TableCell className='head-bg'>Tnx hash</TableCell>
            <TableCell className='head-bg'>
              <div className='eye-direct'>
              Methods
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
             </TableCell>
            <TableCell className='head-bg'><div className='eye-direct'>
              Block
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div></TableCell>
            <TableCell className='head-bg'>Age</TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            From
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            To
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>Value</TableCell>
            <TableCell className='head-bg'>[Txn fee]</TableCell>
          </TableRow>
         </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className='body-bg'>
               {row.name}
              </TableCell>
              <TableCell className='body-bg'>{row.fat}</TableCell>
              <TableCell className='body-bg'>{row.carbs}</TableCell>
              <TableCell className='body-bg'>{row.protein}</TableCell>
              <TableCell className='body-bg'>{row.dob}</TableCell>
              <TableCell className='body-bg'>{row.phone}</TableCell>
              <TableCell className='body-bg'>{row.mail}</TableCell>
              <TableCell className='body-bg'>{row.end}</TableCell>
         
              
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      <div>
        <p>Latest 25 from a total of <b>124 transactions</b></p>
      </div>
      <div className='trans-table-block'>
      <Table className='tableScroll'>
      <TableHead>
          <TableRow className='row-head'> 
            <TableCell className='head-bg'>Tnx hash</TableCell>
            <TableCell className='head-bg'>
              <div className='eye-direct'>
              Methods
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
             </TableCell>
            <TableCell className='head-bg'><div className='eye-direct'>
              Block
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div></TableCell>
            <TableCell className='head-bg'>Age</TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            From
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            To
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>Value</TableCell>
            <TableCell className='head-bg'>[Txn fee]</TableCell>
          </TableRow>
         </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className='body-bg'>
               {row.name}
              </TableCell>
              <TableCell className='body-bg'>{row.fat}</TableCell>
              <TableCell className='body-bg'>{row.carbs}</TableCell>
              <TableCell className='body-bg'>{row.protein}</TableCell>
              <TableCell className='body-bg'>{row.dob}</TableCell>
              <TableCell className='body-bg'>{row.phone}</TableCell>
              <TableCell className='body-bg'>{row.mail}</TableCell>
              <TableCell className='body-bg'>{row.end}</TableCell>
         
              
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
      <div>
        <p>Latest 25 from a total of <b>124 transactions</b></p>
      </div>
      <div className='trans-table-block'>
      <Table className='tableScroll'>
      <TableHead>
          <TableRow className='row-head'> 
            <TableCell className='head-bg'>Tnx hash</TableCell>
            <TableCell className='head-bg'>
              <div className='eye-direct'>
              Methods
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
             </TableCell>
            <TableCell className='head-bg'><div className='eye-direct'>
              Block
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div></TableCell>
            <TableCell className='head-bg'>Age</TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            From
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>
            <div className='eye-direct'>
            To
              <div>
              <img src={Separate} alt={Separate} />
              </div>
            </div>
            </TableCell>
            <TableCell className='head-bg'>Value</TableCell>
            <TableCell className='head-bg'>[Txn fee]</TableCell>
          </TableRow>
         </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className='body-bg'>
               {row.name}
              </TableCell>
              <TableCell className='body-bg'>{row.fat}</TableCell>
              <TableCell className='body-bg'>{row.carbs}</TableCell>
              <TableCell className='body-bg'>{row.protein}</TableCell>
              <TableCell className='body-bg'>{row.dob}</TableCell>
              <TableCell className='body-bg'>{row.phone}</TableCell>
              <TableCell className='body-bg'>{row.mail}</TableCell>
              <TableCell className='body-bg'>{row.end}</TableCell>
         
              
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      </div>
      </CustomTabPanel>
    </Box>
  );
}