import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import YearlyChart from './YearlyChart';
import YearlyChartCount from './YearlyChartCount';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterPopup from './FilterPopup';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import closeMenu from "../../../img/closemenu.png";
import { Link } from 'react-router-dom'
import Files from '../../../img/files.svg';
import Pagination from '@mui/material/Pagination';
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import consts from '../../../constants'
import ChartFlow from '../../../img/ChartFlow.png';
import * as XLSX from "xlsx";
import locationMark from '../../../img/locationMark.png';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 20,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  padding: '15px 30px',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
    alignItems: 'center'
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  polygongas: {
    maxWidth: '70% !important',
    margin: '0 auto !important',
    '@media (max-width: 991.98px)': {
      maxWidth: '100% !important',
    },
  }
});


const BrandBlockchainBody = () => {

  const navigate = useNavigate()

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  useEffect(() => {
    profile()
  }, [])

  const profile = async () => {
    if (role === 'brand') {
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        navigate(`${consts.route}/`, { state: { data: true } })
      }

    }
  }
  const [productList, setProductList] = useState([])
  // const blockchainData = async () => {
  //   try {
  //     const { data } = await Axios.post('/getTranscations', {}, {
  //       headers: {
  //         Authorization: window.localStorage.getItem('AdminToken')
  //       }
  //     })
  //     console.log("🚀 ~ file: AdminBlockchainBody.js:204 ~ blockchainData ~ data:", data)
  //     if (data?.success === true) {
  //       setProductList(data?.result)
  //     }
  //     else {
  //       setProductList([])
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ file: AdminBlockchainBody.js:205 ~ blockchainData ~ error:", error)

  //   }

  // }

  useEffect(() => {
    blockchainData()
  }, [])

  const downloadExcelsheetCustomer = () => {
    const tableData = [
      // ['Serial_No', 'product_category', 'product_identifier', 'ean', 'Product_Name', 'Initial_stock', 'Unit_of_measurement', 'Measures', 'Size', 'main_image', 'Detail1_image', 'Detail2_image', 'Detail3_image', 'product_description', 'material', 'ingredients', 'departure_location', 'Cordenate_location', 'Attachments', 'Warranty (yes/no)',
      //   'Duration_of_the_official_warranty (months)', 'Allows_warranty_extension(yes/no)', 'Extra_warranty_time(months)', 'Warranty_extension_price', 'Type_of_currency', 'Physical_product_1_or_virtual_0', 'production_date', 'LOT_NUMBER', 'UN_Product_Classification_Number', 'Toxic', 'substance_type', 'composition'],
    ];
    // if (products.length > 0) {
    // for (var i = 0; i < products.length; i++) {
    console.log(userdetails, "userdetails")
    console.log(userdetails?.user_info?.name, "userdetails")
    var proDetails = {
      // ProductName: products[i]?.brand_name,
      Name: userdetails?.brands?.user_info?.name,
      Brand_ID: userdetails?.brands?.brandId,
      Email: userdetails?.brands?.user_info?.email,
      Phone_No: userdetails?.brands?.user_info?.phone,
      Role: userdetails?.brands?.user_info?.role,
      // Status:userdetails?.brands?.row?.status === '0' ? 'pending' : row?.status === '1' ? 'Approved' : 'Declined'
      Address: userdetails?.brands?.user_info?.address,
      Country: userdetails?.brands?.user_info?.country
    }
    tableData.push(proDetails)
    // }
    // }  

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "CustomerDetails.csv");
  };

  const classes = useStyles();

  const [profileopen, profilesetOpen] = React.useState(false);
  const profilehandleOpen = () => profilesetOpen(true);
  const profilehandleClose = () => profilesetOpen(false);

  const [mapopen, mapsetOpen] = React.useState(false);
  const maphandleOpen = () => mapsetOpen(true);
  const maphandleClose = () => mapsetOpen(false);


  const [productopen, productsetOpen] = React.useState(false);
  const producthandleOpen = () => productsetOpen(true);
  const producthandleClose = () => productsetOpen(false);
  const [creationDate, setCreationDate] = useState([])
  const [paymentDate, setPaymentDate] = useState([])
  const [creation, setCreation] = useState()
  const [payment, setPayment] = useState()
  const [mapDetails, setMapDetails] = useState()
  const [userdetails, setUserDetails] = useState()

  const [isfilter, setIsfilter] = useState(false)
  const [name, setName] = useState()


  const [country, setCountry] = useState()

  const states = {
    center: [51.505, -0.091],
    zoom: 3,
  };

  const customIcon = new Icon({
    iconUrl: locationMark,
    iconSize: [23, 30]
  })
  const [blochChainCount, setBlocChainCount] = useState(0)
  const counts123 = async () => {
    setBlocChainCount(0)
    try {
      const { data } = await Axios.post('/admin/brand_dashboard_count', {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setBlocChainCount(data?.result?.blockChain)
        // setPendingCount(data?.result?.pendingActivate)
        // setActivateCount(data?.result?.ActivateCount)

      }
    } catch (error) {
      console.log("🚀 ~ file: DashboardBody.js:116 ~ counts ~ error:", error)

    }

  }

  useEffect(() => {
    counts123()
  }, [])
  const [counts, setCounts] = useState()
  const [page, setPage] = useState(1)
  const [from, setFrom] = useState()

  const [transac, setTransac] = useState()

  const [transCount, setTransCount] = useState()

  const handleChange1 = (event, value) => {
    setPage(value);
  };

  // useEffect(()=>{
  //   blockchainData()
  // },[page])

  const blockchainData = async () => {
    try {
      const { data } = await Axios.post('/getTranscations', {
        creation_date: creationDate,
        payment_date: paymentDate,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
        setBlocChainCount(data?.count)
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminBlockchainBody.js:205 ~ blockchainData ~ error:", error)

    }

  }
  const filterBlockChain = async () => {
    try {
      const { data } = await Axios.post('/getTranscations', {
        name: name,
        from: from,
        creation: creation,
        payment: payment,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
        setBlocChainCount(data?.count)
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminBlockchainBody.js:205 ~ blockchainData ~ error:", error)

    }

  }

  const filterbyDate = async (pay) => {

    try {
      setIsfilter(true)
      const { data } = await Axios.post('/getTranscations', {
        creation_date: pay,
        payment_date: paymentDate,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
      }
    } catch (error) {
      console.log(error)

    }
  }
  const filterbyDate1 = async (pay) => {

    try {
      setIsfilter(true)
      const { data } = await Axios.post('/getTranscations', {
        creation_date: creationDate,
        payment_date: pay,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
        setBlocChainCount(data?.count)
      }
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    if (isfilter === true) {
      if ((paymentDate !== undefined) && (paymentDate.length !== 0)) {
        filterbyDate(creationDate)
      }
      else if ((creationDate !== undefined) && (creationDate.length !== 0)) {

        filterbyDate1(paymentDate)
      } else {
        setDetails()
      }
    } else {
      blockchainData()
    }
  }, [page])

  useEffect(() => {
    blockchainData()
  }, [])

  const setDetails = () => {
    setIsfilter(true)
    filterBlockChain()
  }

  const Resetfilter = () => {
    setPage(1)
    setName();
    setFrom();
    setPaymentDate([])
    setCreationDate([])
    setCreation();
    setPayment();
    blockchainData()
  }

  const getTransactions = async () => {
    try {
      const { data } = await Axios.post('/admin/getBlockchain', {}, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        const dts = data?.result
        var arr = []
        for (let i = 0; i < dts.length; i++) {
          const element = dts[i];
          // if (element?.TransactionFee) {
          arr.push([element?._id, element?.totalTransactionFee])
          // }
        }
        setTransac(arr)
      } else {
        setTransac([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:477 ~ getTransactions ~ error:", error)

    }
  }

  const getTransactionCountss = async () => {
    try {
      const { data } = await Axios.post(`/admin/getBlockChainCount`, {}, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        var arr = []
        for (let i = 0; i < data?.result?.length; i++) {
          const element = data?.result[i];
          arr.push([element?._id, element?.count])
        }
        setTransCount(arr)
      } else {
        setTransCount([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:507 ~ getTransactionCountss ~ error:", error)
    }
  }

  useEffect(() => {
    getTransactions()
    getTransactionCountss()
  }, [])


  const getTransactionCountss1 = async () => {
    try {
      const { data } = await Axios.post(`/admin/getBlockChainCount`, {
        creation_date: creation,
        payment_date: payment,
        name: name
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        var arr = []
        for (let i = 0; i < data?.result?.length; i++) {
          const element = data?.result[i];
          arr.push([element?._id, element?.count])
        }
        setTransCount(arr)
      } else {
        setTransCount([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:507 ~ getTransactionCountss ~ error:", error)
    }
  }

  const getTransactions1 = async () => {
    try {
      const { data } = await Axios.post('/admin/getBlockchain', {
        creation_date: creation,
        payment_date: payment,
        name: name
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        const dts = data?.result
        var arr = []
        for (let i = 0; i < dts.length; i++) {
          const element = dts[i];
          // if (element?.TransactionFee) {
          arr.push([element?._id, element?.totalTransactionFee])
          // }
        }
        setTransac(arr)
      } else {
        setTransac([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:477 ~ getTransactions ~ error:", error)

    }
  }

  useEffect(() => {
    getTransactionCountss1()
    getTransactions1()
  }, [blochChainCount])

  return (
    <div className='dashboard-body adminblock'>
      <Box sx={{ flexGrow: 1 }}>


        <Grid container spacing={0} className={classes.containwidth}>


          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Item className={classes.headercls}>

              <div className='filter-btn adminblock'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className='filter-filed'>
                    <div className='filter-box'>
                      {/* <Button variant="contained">{<SearchIcon />}Filter</Button> */}
                      <FilterPopup Resetfilter={Resetfilter} setName={setName} setFrom={setFrom} setCreation={setCreation} setPayment={setPayment} setDetails={setDetails} />
                      <Button className='filter-color' onClick={() => { setIsfilter(false); Resetfilter() }}>{<RefreshIcon color='primary' />}Filter Reset</Button>
                    </div>

                    {/* <div className='start-end-date start-end-date-admin adminblock'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'DateTimePicker',
                            'TimePicker',
                            'DateRangePicker',
                          ]}
                        >

                          <DemoItem>
                            <DatePicker
                              onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}
                              views={['year', 'month', 'day']}
                            />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'DateTimePicker',
                            'TimePicker',
                            'DateRangePicker',
                          ]}
                        >

                          <DemoItem>
                            <DatePicker
                              onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }}
                              views={['year', 'month', 'day']}
                            />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div> */}

                  </div>
                </Grid>
              </div>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.containwidth}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            {/* 
            <div className='start-end-date start-end-date-admin adminblock'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div> */}

            <Item className={classes.dashboargraph} id="dashboargraph adminblock">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>

                </div>

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <h5>Transactions Per Day</h5>
                    <p>Sales performance for online and offline revenue </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>
                {/* 
                {
                  transCount.sort((a, b) => {
                    // Convert the date strings to Date objects for comparison
                    let dateA = new Date(a._id);
                    let dateB = new Date(b._id);
                    
                    return dateA - dateB;
                })
                } */}

                <div className='dashboargraph-inner-bottom'>
                  <YearlyChart transac={transCount} />
                </div>

              </div>


            </Item>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.rightpartstyle}>
            {/* 
            <div className='start-end-date start-end-date-admin adminblock'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div> */}

            {/* <Item className={classes.headercls} id="dashboargraph adminblock">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>
                  <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button>
                </div>

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <h5>This years total revenue</h5>
                    <p>Sales performance for online and offline revenue </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>

                <div className='dashboargraph-inner-bottom'>
                  <CandleStick />
                </div>

              </div>


            </Item> */}

            <Item className={classes.dashboargraph} id="two-block-divs-1">
              <div className='total-pdts'>
                <p>Total Products Registered in The Blockchain</p>
                <div className='total-pdts-div'>
                  <div className='total-pdts-div-left'><label>Products</label><span>{blochChainCount}</span></div>
                  <div className='total-pdts-div-right'>
                    <img src={ChartFlow} alt="ChartFlow" />
                  </div>
                </div>
              </div>
            </Item>

          </Grid>

        </Grid>
        {/* 
        <Grid container spacing={0} className={classes.containwidth}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.polygongas}>

            <Item className={classes.headercls} id="recent-earnings-sales-admin">

              <div className='polygon'>
                <h3 className='head-table-recent'>Polygon Gas Tracker</h3>
                <p className='para-head-table-recent'>🔥 Burnet fees : 17,852,9631</p>
              </div>

              <div className='polygon-card'>

                <div className='card'>
                  <h4>Standard</h4>
                  <h5 className='green'>169.9 Gwei</h5>
                  <h6>(30-60 secs)</h6>
                  <p>Base: 167 | Priority 29.9000000000000000006</p>
                </div>

                <div className='card'>
                  <h4>Standard</h4>
                  <h5 className='blue'>169.9 Gwei</h5>
                  <h6>(30-60 secs)</h6>
                  <p>Base: 167 | Priority 29.9000000000000000006</p>
                </div>

                <div className='card'>
                  <h4>Standard</h4>
                  <h5 className='light-blue'>169.9 Gwei</h5>
                  <h6>(30-60 secs)</h6>
                  <p>Base: 167 | Priority 29.9000000000000000006</p>
                </div>

              </div>

              <div className='pending'>
                <h3>Pending Transaction Pool Info :</h3>

                <div className='last-block'>
                  <div><label>Last Block : </label><span>45028172</span></div>
                  <div><label>Avg.Utilization : </label><span>50.50%</span></div>
                </div>

                <div className='last-block'>
                  <div><label>Pending Queue : </label><span>39386 Txns</span></div>
                  <div><label>Last Refreshed : </label><span>Mon, 04 Sep 2023  13:23</span></div>
                </div>

                <div className='last-block'>
                  <div><label>Avg.Block Size : </label><span>83 Txns</span></div>
                </div>

                <div className='dashboargraph-inner-top'>
                  <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button>
                </div>

              </div>



            </Item>

          </Grid>
        </Grid> */}


        <Grid container spacing={0} className={classes.containwidth} sx={{ alignItems: 'start' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='blockchain-chart-div'>
            {/* 
            <div className='start-end-date start-end-date-admin adminblock'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      // disablePast
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div> */}

            <Item className={classes.dashboargraph} id="dashboargraph adminblock">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>

                </div>

                <div className='dashboargraph-inner-mid' >
                  <div className='dashboargraph-inner-mid-left'>
                    <h5>Daily Cost</h5>
                    <p>Sales performance for online and offline revenue </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>

                <div className='dashboargraph-inner-bottom'>
                  <YearlyChartCount transac={transac} />
                </div>

              </div>


            </Item>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <div className='listbody2'>

              <div className='tablebody'>
                <div className='dashboargraph-inner-top'>
                  <Item className={classes.dashboargraph} id="earnings-sales">
                    <TableContainer sx={{ maxHeight: 640 }}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className='row-head'>
                            <TableCell className='head-bg'>Internal contract ID</TableCell>
                            <TableCell className='head-bg'>From Wallet ID</TableCell>
                            <TableCell className='head-bg'>To Wallet ID</TableCell>
                            <TableCell className='head-bg'>GAS FEE</TableCell>
                            <TableCell className='head-bg'>DTRACER Fee</TableCell>
                            <TableCell className='head-bg'>Type</TableCell>
                            {/* <TableCell className='head-bg'>DTRACER Fee</TableCell> */}
                            <TableCell className='head-bg'>Status</TableCell>
                            <TableCell className='head-bg'>Date</TableCell>
                            <TableCell className='head-bg'>More Info</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productList.length > 0 ? productList?.map((blockrows, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row" className='body-bg'>{blockrows.brands?.user_info?.name}</TableCell>
                              <TableCell className='body-bg overflow'>{blockrows?.From}</TableCell>
                              <TableCell className='body-bg overflow'>{blockrows?.To}</TableCell>
                              <TableCell className='body-bg'>{(blockrows?.Other?.gasUsed * (blockrows?.Other?.effectiveGasPrice / (10 ** 18)))?.toFixed(6)}</TableCell>
                              {(blockrows.Type === "Mint") || (blockrows.Type === "Transfer") ? <TableCell className='body-bg overflow'>{"---"}</TableCell> : <TableCell className='body-bg overflow'>{blockrows?.TransactionFee ? (parseFloat(blockrows?.TransactionFee).toFixed(6)) : "---"}</TableCell>}
                              <TableCell className='body-bg overflow'>{blockrows?.Type}</TableCell>
                              {/* <TableCell className='body-bg'>---</TableCell> */}
                              {/* <TableCell className='body-bg'><div className='icon'><img src={blockrows?.Other?.status} alt="rightState" /></div></TableCell> */}
                              <TableCell className='body-bg'><div className='icon'>{blockrows?.Other?.status === true ? "true" : 'false'}</div></TableCell>
                              <TableCell className='body-bg'><div className='icon'>{blockrows?.createdAt?.split('T')[0]}</div></TableCell>
                              <TableCell className='body-bg'>
                                <div className='icon-space'>
                                  <Button onClick={() => { setUserDetails(blockrows); profilehandleOpen() }}>{<PersonIcon color="primary" />}</Button>

                                  {/* <Button> {<LocalPostOfficeOutlinedIcon  color="primary"/>}</Button> */}

                                  {/* <Button onClick={() => { setMapDetails(blockrows); maphandleOpen(); setCountry(`${blockrows?.product_details?.latitude}, ${blockrows?.product_details?.longitude}`) }}>{<PlaceIcon />}</Button> */}
                                  {/* <Button  >{<RefreshIcon />}</Button> */}
                                  {/* <Button onClick={() => { setMapDetails(blockrows); producthandleOpen() }}> {<InfoOutlinedIcon color="primary" />}</Button> */}
                                </div>
                              </TableCell>
                            </TableRow>

                          )) : <TableCell component="th" scope="row" className='body-bg'>No Data Found</TableCell>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>


                  <Modal
                    open={profileopen}
                    onClose={profilehandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" className='map-popup profile-brandpop'
                  >
                    <Box sx={style} className="popBOX">
                      <div className="menu" onClick={profilehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                      <Typography variant="h6" component="h2" className='hb' sx={{ textAlign: 'center' }}>
                        Customer information
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <div className='product'>

                          {/* <div className='lady-img'>
                            <img src={lady} alt="ladyimg" />
                          </div> */}

                          <div className="listThree profile-brandpop">
                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Name
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  // label="Bharath Kumar "
                                  value={userdetails?.brands?.user_info?.name}
                                  variant="outlined"
                                  className="textField"
                                />
                              </div>
                            </div>

                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Email
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  value={userdetails?.brands?.user_info?.email}
                                  variant="outlined"
                                  className="textField"
                                />
                              </div>
                            </div>
                            {/* <div className="productId">
                              <div className="start-end-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    className="modelTypo"
                                  >
                                    DOB
                                  </Typography>
                                  <DemoContainer
                                    components={[
                                      "DatePicker",
                                      "DateTimePicker",
                                      "TimePicker",
                                      "DateRangePicker",
                                    ]}
                                  >
                                    <DemoItem>
                                      <DatePicker
                                        defaultValue={userdetails?.brands?.user_info?.dob ? userdetails?.brands?.user_info?.dob :yesterday}
                                        // disablePast
                                        views={["year", "month", "day"]}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </div> */}

                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Phone
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  value={userdetails?.brands?.user_info?.phone}

                                  variant="outlined"
                                  className="textField"
                                />
                              </div>
                            </div>

                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Role
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  value={userdetails?.brands?.user_info?.role}

                                  variant="outlined"
                                  className="textField"
                                />
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="block-export">
                          <Button variant="contained" onClick={() => { downloadExcelsheetCustomer() }} >
                            Export<img src={Files} alt={Files} />
                          </Button>
                        </div>

                      </Typography>

                    </Box>
                  </Modal>


                  <Modal
                    open={mapopen}
                    onClose={maphandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" className='map-popup'
                  >

                    <Box sx={style} className='popBOX'>
                      <div className="menu" onClick={maphandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <Typography id="modal-modal-title" variant="h6" component="h2" className='brand-block-popmap'>
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20311214.073350176!2d101.98305051684045!3d71.89764895113782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1693909504227!5m2!1sen!2sin" width="80%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" /> */}

                        <Map center={[mapDetails?.product_details?.latitude, mapDetails?.product_details?.longitude]} zoom={states?.zoom}>
                          <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                          />

                          <Marker position={[mapDetails?.product_details?.latitude, mapDetails?.product_details?.longitude]} icon={customIcon} >
                            <Popup>
                              <label>Product Id: </label> <span>{mapDetails?.product_details?.product_id}</span><br></br>
                              <label>Product Name: </label> <span>{mapDetails?.product_details?.Product_Name}</span><br></br>
                              {/* <label>Owned By: </label> <span>{marker?.userName}</span> */}
                            </Popup>
                          </Marker>

                        </Map>
                      </Typography>

                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="listThree">
                          <div className="productId">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              className="modelTypo"
                            >
                              Country
                            </Typography>

                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                // label="Enter Your Amount "
                                variant="outlined"
                                className="textField"
                                value={country}

                              />
                            </div>
                          </div>
                          <div className="productId">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              className="modelTypo"
                            >
                              Movement location
                            </Typography>

                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                label="Av. Barcelona 55, Madrid "
                                variant="outlined"
                                className="textField"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="block-export">
                          <Button variant="contained">
                            Export<img src={Files} alt={Files} />
                          </Button>
                        </div>
                      </Typography>

                    </Box>
                  </Modal>



                  <Modal
                    open={productopen}
                    onClose={producthandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" className='product-popup'>

                    <Box sx={style} className="popBOX">
                      <div className="menu" onClick={producthandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                      <Typography variant="h6" component="h2" className='hb' sx={{ textAlign: 'center' }}>
                        Product Information
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                        <div className='product'>

                          <div className='lady-img'>
                            <img src={mapDetails?.product_details?.main_image} alt="ladyimg" />
                          </div>

                          <div className="listThree">
                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Name
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  // label="Bharath Kumar "
                                  variant="outlined"
                                  className="textField"
                                  value={mapDetails?.product_details?.Product_Name}
                                />
                              </div>
                            </div>

                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Category
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  // label="test@bharth.io "
                                  variant="outlined"
                                  className="textField"
                                  value={mapDetails?.product_details?.product_category}
                                />
                              </div>
                            </div>

                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                EAN Number
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  // label="D-2567827678 "
                                  variant="outlined"
                                  className="textField"
                                  value={mapDetails?.product_details?.ean}
                                />
                              </div>
                            </div>

                            <div className="productId">
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className="modelTypo"
                              >
                                Product Id
                              </Typography>

                              <div className="cmn-input-cls">
                                <TextField
                                  id="outlined-basic"
                                  // label="ADD-766748754 "
                                  variant="outlined"
                                  className="textField"
                                  value={mapDetails?.product_details?.product_id}


                                />
                              </div>
                            </div>
                          </div>

                          <div className='product-desc'>
                            <h5>Product Description</h5>
                            {/* <p>Lorem ipsum dolor sit amet consectetur. Quis nec tempus vel sed aenean tincidunt luctus scelerisque et. Fermentum interdum eros nunc aenean sed risus elit. Mattis amet aliquet lacus tellus. Tempor nunc amet tristique laoreet. Eget a augue amet viverra turpis posuere. Nisl neque morbi senectus adipiscing. Venenatis et malesuada suspendisse sapien pellentesque ac. Sed lectus ac pretium tellus porttitor aenean id arcu sit.</p> */}
                            <p>{mapDetails?.product_details?.product_description}</p>
                          </div>
                        </div>
                        <div className="block-export">
                          <Button variant="contained">
                            Export<img src={Files} alt={Files} />
                          </Button>
                        </div>

                      </Typography>

                    </Box>
                  </Modal>

                </div>



              </div>

            </div>

          </Grid>



        </Grid>
        <div className='pagination-numb'>
          <Pagination count={counts} page={page} onChange={handleChange1} color="primary" shape="rounded" />

          {/* <Pagination count={9} color="primary" shape="rounded" /> */}
        </div>
      </Box>
    </div>
  )
}

export default BrandBlockchainBody
