import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import cross from '../../../img/cross.svg'
import Header from '../../Header/Header';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './SubAdminList.css'
import Modal from "@mui/material/Modal";
import consts from '../../../constants';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Toaster, toast } from 'react-hot-toast'
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import adidasLogo from '../../../img/adidasLogo.png';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import info1 from '../../../img/info.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Axios from '../../../Axios';
// import Renewal from './Renewal';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Link, useNavigate } from 'react-router-dom'
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'
import Files from '../../../img/files.svg';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
// import CustomerFilter from './CustomerFilter';
import CustomerFilter1 from './CustomerFilter1';
// import Deactivte from './Deactivate';
// import Reactivate from './Reactivate';
import userProfile from '../../../img/userProfile.png'
import adidas from '../../../img/adidas.svg';
import nike from '../../../img/nike.svg';
import demo from '../../../img/demo.svg';
import rightState from '../../../img/rightState.png';
import wrongState from '../../../img/wrongState.png';
// import box from '../../../img/box.svg';
// import AddressPopup from './AddressPopup';
import AdminMenuSide from '../AdminMenuSide';
import { useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';

import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import logout from '../../logout';
import PersonIcon from '@mui/icons-material/Person';

const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '50px 0 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },
  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});

function createData(
  brandLogo,
  brandName,
  cusName,
  email,
  status,
  moreInfo,
  renewal,

) {
  return { brandLogo, brandName, cusName, email, status, moreInfo, renewal };
}

const rows = [
  createData(adidas, 'ADIDAS', 'Ismael Santana', 'i.S@test.com', rightState, '', "10/08/2024"),
  createData(nike, 'NIKE', 'Ismael Santana', 'i.S@test.com', wrongState, '', "---------------"),
  createData(demo, 'TE DEMO BRAND', 'Ismael Santana', 'i.S@test.com', rightState, '', "10/08/2024"),
];

const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: '82%',
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "none",
  p: 2,
};

const styleReActive = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "3px solid #BFFF00 !important",
  p: 2,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  '@media(max-width:991.98px)': {
    width: '70%'
  },
  pt: 2,
  px: 4,
  pb: 3,
};




const AdminCustomer = ({ row }) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const [users, setUsers] = useState([])



  const [count, setCount] = useState()
  const [statussss, setstatusss] = useState()


  const [statuscheck, setStatusCheck] = useState([])
  const [selcid, setSelcid] = useState()

  const defaultStatus = () => {
    var dt = [
      {
        Name: "Brand",
        Read: true,
        Write: false,
      },
      {
        Name: "Products",
        Read: true,
        Write: false,
      },
      {
        Name: "Traceability",
        Read: true,
        Write: false,
      },

      {
        Name: "Blockchain",
        Read: true,
        Write: false,
      },
      {
        Name: "Post-sale",
        Read: true,
        Write: false,
      },
      {
        Name: "Billing",
        Read: true,
        Write: false,
      },
      {
        Name: "Seller Auth",
        Read: true,
        Write: false,
      },
    ]
    setStatusCheck(dt)
  }

  useEffect(() => {
    defaultStatus()
  }, [])

  const getCheckStatus = async (id) => {
    try {
      const payload = {
        _id: id
      }
      const { data } = await Axios.post('/admin/getSubadminPermission', payload,
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      )
      console.log(data, "daasdfd")
      if (data?.success && data?.result?.length > 0) {
        setStatusCheck(data?.result)
      } else {
        defaultStatus()
      }
    } catch (error) {
      console.log("🚀 ~ file: SubAdminList.js:266 ~ getCheckStatus ~ error:", error)
    }
  }


  const [isActive, setIsActive] = useState(true);
  const redirectPath = '/'
  const timeoutSeconds = 5 * 60 * 1000
  const navigate = useNavigate()
  useEffect(() => {
    screenInactive()
  }, [timeoutSeconds, isActive, navigate, redirectPath]);

  const screenInactive = () => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      if (isActive) {
        timeoutId = setTimeout(() => {
          logout()
          window.localStorage.removeItem('electrónico')
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`)
        }, timeoutSeconds);
      }
    };

    const handleMouseActivity = () => {
      setIsActive(true);
      resetTimeout();
    };


    resetTimeout();


    window.addEventListener('mousemove', handleMouseActivity);
    window.addEventListener('keydown', handleMouseActivity);


    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleMouseActivity);
      window.removeEventListener('keydown', handleMouseActivity);
    };
  }

  const filterData = (data) => {
    // setUsers1(data)
    setUsers(data)
    setCount(Math.ceil(data?.length / 10))
  }
  const reset = (data) => {
    if (data === true) {
      getSellers()
    }
  }

  useEffect(() => {
    getSellers();
  }, [])
  const getSellers = async () => {
    try {
      setstatusss('Loading....')

      const { data } = await Axios.post('/subAdminList', {},
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      )

      if (data?.success) {
        if (data?.result?.length > 0) {
          console.log(data?.result, "result")
          setUsers(data?.result)
          setCount(Math.ceil(data?.result?.length / 10))
        } else {
          setstatusss('No SubAdmin Available')
        }

      } else {
        setstatusss('No SubAdmin Available')
      }

    } catch (error) {
      console.log(error, "err")
      setstatusss('No SubAdmin Available')
    }
  }

  const activateDeactiveBrand = async (datas) => {
    try {
      const { data } = await Axios.post(`/subAdminStatus`, {
        _id: datas
      },
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken")
          }
        })
      console.log("🚀 ~ file: SubAdminList.js:239 ~ activateDeactiveBrand ~ data:", data)
      if (data?.success === true) {
        toast.success(data?.message)
        getSellers()
      }
    } catch (error) {
      console.log("🚀 ~ file: SubAdminList.js:239 ~ activateDeactiveBrand ~ error:", error)

      if (error?.response?.data?.success === false) {
        toast.error(error?.response?.data?.message)
      }
    }
  }

  const handleChange = (name, type) => {
    const nextShapes = statuscheck.map(shape => {
      if (shape.Name === name) {
        // No change
        if (type === 'Read') {
          return {
            ...shape,
            Read: !shape.Read,
          };
        } else {
          return {
            ...shape,
            Write: !shape.Write,
          };
        }

      } else {
        // Return a new circle 50px below
        return shape;
      }
    });
    // Re-render with the new array
    setStatusCheck(nextShapes);
  }

  const editStatus = async () => {
    try {
      const payload = {
        _id: selcid,
        permission: statuscheck
      }

      console.log(payload, "data")

      const { data } = await Axios.post('/admin/subAdminPermission', payload,
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      )
      console.log(data, "data")
      if (data?.success) {
        toast.success(data?.message)
        handleClose()
      }
    } catch (error) {
      console.log("🚀 ~ file: SubAdminList.js:367 ~ editStatus ~ error:", error)
    }

  }
  const downloadExcelsheet = () => {
    console.log(users)

    let tableData = []

    for (var i = 0; i < users.length; i++) {
      let reasons;
      var info = {
        name: users[i].name,
        email: users[i].email,
        city: users[i].city,
        phone: users[i].phone,
        address: users[i].address,
        country: users[i].country,
        postal_code: users[i].postal_code,
        verified: users[i].verified,
        role: users[i].role,
        Permission: users[i].Permission,


      }
      //  for(var j=0;j<users[i].Permission.length;j++){
      //   var add = {
      //   Name:users[i].Permission[j].Name
      //   }
      //  }
      //  var info = {
      //    name: users[i].name,
      //    email: users[i].email,
      //    city: users[i].city,
      //    phone: users[i].phone,
      //    address: users[i].address,
      //    country: users[i].country,
      //    postal_code: users[i].postal_code,
      //    verified: users[i].verified,
      //    role: users[i].role,
      //    Permission: users[i].Permission,


      //  }
      tableData.push(info)
    }
    console.log(tableData, "Data")
    // const result = tableData.map(item => {
    //   const mergedObject = { ...item };
    //   if (item.Permission) {
    //     item.Permission.forEach(array2Item => {
    //       mergedObject[Object.keys(array2Item)[0]] = Object.values(array2Item)[0];
    //     });
    //     delete mergedObject.Permission; // Optionally remove the original array2 property
    //   }
    //   return mergedObject;
    // });

    const result = tableData.map(item => {
      const mergedObject = { ...item };

      if (item.Permission) {
        item.Permission.forEach(PermissionItem => {
          const key = Object.keys(PermissionItem)[0];
          const value = Object.values(PermissionItem)[0];

          // Check if the key already exists in mergedObject
          if (mergedObject.hasOwnProperty(key)) {
            // If it does, create an array if needed and push the new value
            if (!Array.isArray(mergedObject[key])) {
              mergedObject[key] = [mergedObject[key]];
            }
            mergedObject[key].push(value);
          } else {
            // If the key doesn't exist, simply add it to mergedObject
            mergedObject[key] = value;
          }
        });

        delete mergedObject.Permission; // Optionally remove the original array2 property
      }

      return mergedObject;
    });

    const mergedUsers = tableData.map(user => {
      const mergedUser = { ...user };

      if (user.Permission) {
        user.Permission.forEach(permission => {
          const permissionName = permission.Name;

          const permissionRead = permission.Name
          const permissionWrite = permission.Name

          delete permission.Name;
          mergedUser[permissionName] = permission;
          mergedUser[permissionRead] = mergedUser[permissionName].Read + "/" + mergedUser[permissionName].Write;
          // mergedUser[permissionName] = permission;
          // mergedUser[permissionWrite] = "Wtite "+mergedUser[permissionName].Write;
          // console.log(mergedUser[permissionName].Read,"1a")
        });
        console.log(mergedUser.Permission, "1")
        delete mergedUser.Permission; // Optionally remove the original Permission property
      }

      return mergedUser;
    });

    console.log(result, mergedUsers)

    const worksheet = XLSX.utils.json_to_sheet(mergedUsers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'User.csv');

  };

  return (

    <div className='Admin customer address list-full-cnt dashboard-page Admin-customer-page'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className='admin-customer-contain'>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
              <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                <AdminMenuSide />
              </div>
            </Item>
          </Grid>

          <div onClick={closeMenuHandler}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block">

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                  <Item className={classes.headercls}>

                    <div className='listbody'>
                      <div className='filter-btn'>
                        <div className='filter-filed'>
                          <div className='filter-box'>
                            {/* <Button variant="contained">{<SearchIcon />}Filter</Button> */}
                            <CustomerFilter1 filterData={filterData} reset={reset} />
                            {/* filterData={filterData} reset={reset} */}
                          </div>

                          <div className='addsub-admin-div'>
                            <Button variant="contained" onClick={() => { navigate(`${consts.route}/subadmin`) }} >
                              <PersonIcon />  Add SubAdmin
                            </Button>
                            <Button variant="contained" onClick={() => { downloadExcelsheet() }} >
                              Export<img src={Files} alt={Files} />
                            </Button>
                          </div>
                        </div>

                      </div>

                      <div className='tablebody admin-customer-table-main'>
                        <div className='dashboargraph-inner-top'>
                          <Item className={classes.dashboargraph} id="earnings-sales">
                            <TableContainer>
                              <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableScroll' >
                                <TableHead>
                                  <TableRow className='row-head'>
                                    <TableCell className='head-bg'>Name</TableCell>
                                    <TableCell className='head-bg'>Email</TableCell>
                                    <TableCell className='head-bg'>Role</TableCell>
                                    <TableCell className='head-bg'>Status</TableCell>
                                    <TableCell className='head-bg'>More info</TableCell>

                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {users?.length > 0 ? users?.map((row) => (
                                    <TableRow key={row?._id}>
                                      <TableCell component="th" scope="row" className='body-bg'>
                                        {row?.name}
                                      </TableCell>
                                      <TableCell className='body-bg'>{row?.email}</TableCell>
                                      <TableCell className='body-bg'>{row?.role}</TableCell>
                                      <TableCell className='body-bg'>{row?.verified ? <Button className="activate"><img src={rightState} alt={rightState} /></Button> : <Button className="activate"><img src={cross} alt={cross} /></Button>}</TableCell>
                                      <TableCell className='body-bg'>
                                        {row?.verified === true ? <Button style={{ backgroundColor: '#E42B15', color: 'black' }} onClick={() => { activateDeactiveBrand(row?._id) }} >Deactivate</Button> : <Button style={{ backgroundColor: '#94eb94', color: 'black' }} onClick={() => { activateDeactiveBrand(row?._id) }} >Activate</Button>}

                                        <Button style={{ backgroundColor: '#94eb94', color: 'black', marginLeft: '5px' }} onClick={() => { handleOpen(); setSelcid(row?._id); getCheckStatus(row?._id) }} >Edit Info</Button>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                    :
                                    <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Item>
                        </div>
                        <div className='pagination-numb'>
                          {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                          {/* <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" /> */}
                        </div>
                      </div>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style }} className="user-popup-table popBOX">
                <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                <h2 id="child-modal-title">SubAdmin Read and Write Access</h2>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">List</TableCell>
                        <TableCell align="center">Read</TableCell>
                        <TableCell align="center">Write</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statuscheck.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, textAlign: 'left' }}
                        >
                          <TableCell >
                            {row.Name}
                          </TableCell>
                          <TableCell align="right"> <Checkbox
                            checked={row?.Read}
                            onChange={() => { handleChange(row?.Name, 'Read') }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /></TableCell>
                          <TableCell align="right"> <Checkbox
                            checked={row?.Write}
                            onChange={() => { handleChange(row?.Name, 'Write') }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button variant="contained" onClick={() => { editStatus(); }}>Submit</Button>
              </Box>
            </Modal>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>
          </div>
        </Grid>
      </Box>
    </div>
  )
}

export default AdminCustomer;
