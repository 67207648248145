import React, {useState} from "react";
import "./FilterPopup.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from '@mui/styles'; 
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import success from "../../../img/success-img.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import './Transaction.css'
import TabTransaction from './TabTransaction'


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const useStyles = makeStyles({
    
  headercls: {
      background: 'transparent !important',
      borderRadius: '0px !important',
      boxShadow:'none !important',
  },
  containwidth:{
      padding: '0',
      '@media (max-width: 991.98px)' : {
          padding: '0 10px',
        },
  },
  rightpartstyle:{
      paddingLeft: '50px',
      '@media (max-width: 991.98px)' : {
          paddingLeft: '0px',
        },
  },
  dashboargraph: {
      background: 'transparent !important',
      borderRadius: '0px !important',
      boxShadow:'none !important',
      textAlign:'left !important',
  }
});

const FilterPopup = ({Resetfilter,setName,setFrom,setCreation,setPayment,setDetails}) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);    
  }
  const resets = () => {
    setName();
    setFrom()
    setOpen(false);
    setCreation([]);
    setPayment([]);
  }

  const [trandetailopen, trandetailsetOpen] = useState(false);
  const trandetailhandleOpen = () => trandetailsetOpen(true);
  const trandetailhandleClose = () => trandetailsetOpen(false);

  const [resendopen, resendsetOpen] = useState(false);
  const resendhandleOpen = () => resendsetOpen(true);
  const resendhandleClose = () => resendsetOpen(false);

  const [successopen, successsetOpen] = useState(false);
  const successhandleOpen = () => successsetOpen(true);
  const successhandleClose = () => successsetOpen(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlecreationdatechange = (d,m,y) =>{
    
    var credate = y+'-'+m+'-'+d
   
    setCreation(credate)
  
  }
  
  const handlepaymentdatechange = (d,m,y) =>{
    
     var payDate = y+'-'+m+'-'+d
     setPayment(payDate)

  }

  return (
    <div >
      <Button onClick={handleOpen} className="filterBtn">
        <SearchIcon />
        &nbsp; Filter
      </Button>
      {<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX">
        <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          <div className="listOne">
            <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Registration Date
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      onChange = {(e) => { handlecreationdatechange(e.$D,e.$M+1,e.$y) }}
                      views={["year", "month", "day"]}
                    />
                  </DemoItem>

                  <DemoItem>
                    <DatePicker
                      onChange = {(e) => { handlepaymentdatechange(e.$D,e.$M+1,e.$y) }}
                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Product Name
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  
                  variant="outlined"
                  className="textField"
                  onChange={(e)=>{setName(e.target.value)}}
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                ID Contract
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  
                  variant="outlined"
                  className="textField"
                  onChange={(e)=>{setFrom(e.target.value)}}
                />
              </div>
            </div>

          </div>

          {/* <div className="listTwo">

          <div className="warn blockchin">

            <div>
            <Checkbox  className="checkBox"/>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                  PENDING APPROVAL
              </Typography>
            </div>

            <div>
            <Checkbox  className="checkBox"/>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                 APPROVED
              </Typography>
            </div>

            <div>
            <Checkbox  className="checkBox"/>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                 REJECTED
              </Typography>
            </div>

            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Product Ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Client ID
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div>

          </div> */}

            <div className="butOutLayer adblocck">
          <Button className='apply' onClick={()=>{setDetails();handleClose()}}>Apply</Button>
          <Button className='reset-apply' onClick={()=>{Resetfilter();resets()}}>Reset Filter</Button>
          </div>

        </Box>
      </Modal>}


      {<Modal
        open={trandetailopen}
        onClose={trandetailhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX">
        <div className="menu" onClick={trandetailhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.dashboargraph}>
            <div >
                <div id="alert-dialog-description" className='product-head'>

              <div className="transaction-bottom">
                  <p className="detail-trans">Transaction Details </p>
              </div>
              <div>
                  <p className="gasless-para"><b>Sponsored : -</b> Gasless execution and MEV production on 1inch - #1 DEX aggregator. <span>Try Now!</span></p>
              </div>
                </div>
          
                <div className="transaction-tab-detail">
                    <TabTransaction />
                </div>
                
              </div>

            </Item>
          </Grid>

        </Grid>

            <div className="butOutLayer adblocck">
          <Button className='apply' >Apply</Button>
          <Button className='reset-apply' onClick={resendhandleOpen}>Reset Filter</Button>
          </div>

        </Box>
      </Modal>}



      {<Modal
        open={resendopen}
        onClose={resendhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX">
        <div className="menu" onClick={resendhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

             <div className='resend-heading'>Resend smart contract</div>
             <div className="success-img"><img src={success}/></div>
             <p className='resend-para'>The Smart contracts have been sent back to the blockchain, this action may take time depending on the time of the blockchain network</p>

        </Box>
      </Modal>}


      {<Modal
        open={successopen}
        onClose={successhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX">
        <div className="menu" onClick={successhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

             <div className='resend-heading'>Resend smart contract</div>
             <p className='resend-para'>Are you sure to resend the smart contract? This will generate a cost for the brand associated with this contract, you are going to resend the ID XXX contract(s)</p>


        </Box>
      </Modal>}

    </div>
  );
};

export default FilterPopup;
