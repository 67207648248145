import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'

import "./Warrenty.css"
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import guard from "../../../img/guard.png"
import AdminMenuSide from '../../Admin/AdminMenuSide';
import cancel from "../../../img/cancel.png"
import Axios from '../../../Axios';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const ExtendWarrenty = () => {
    const classes = useStyles();
    const [wadate, setWadtae] = useState()
    const [wamon, setWamon] = useState()
    const [menuOpen, setMenuOpen] = useState(true)
    const navigate = useNavigate()
    const openMenuHandler = () => {
        setMenuOpen(false)
    }
    const [untill, setUntill] = useState()
    const location = useLocation()
    const [datas, setDatas] = useState()
    const [lat, setLatitude] = useState('')
    const [long, setLongitutde] = useState('')

    useEffect(() => {
        if (location?.state?.data) {
            setDatas(location?.state?.data)
        }
    }, [location])

    const [warrentyYear, setWarrentyYear] = useState()

    const [validMonth, setValidMonth] = useState()
    function getMonthDifference(startDate, endDate) {
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }

    // useEffect(() => {
    //     if (datas) {
    //         let matches = datas?.product_details?.Warranty.match(/(\d+)/)
    //         let Warrentymatches = datas?.product_details?.Extra_warranty_time.match(/(\d+)/)
    //         if (Warrentymatches?.length > 0) {
    //             setWarrentyYear(Warrentymatches[0])
    //             if (datas?.product_details?.purchadeAt) {
    //                 var dt = datas?.product_details?.purchadeAt?.split('/')
    //                 var newdt = Number(dt[2]) + Number(matches[0])
    //                 setUntill(`${dt[0]}/${dt[1]}/${newdt}`)

    //                 const valid = getMonthDifference(new Date(), new Date(`${newdt}-${dt[1]}-${dt[0]}`))
    //                 setValidMonth(valid)
    //             }
    //         }

    //     }
    // }, [datas])

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    useEffect(() => {
        if ("geolocation" in navigator) {
            // Request the user's location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Success callback
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setLatitude(position.coords.latitude)
                    setLongitutde(position.coords.longitude)
                    // Here you can use latitude and longitude to determine the user's location
                },
                (error) => {
                    // Error callback
                    console.error("Error getting user's location:", error.message);
                }
            );
        } else {
            // Geolocation is not supported
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    const payment = async () => {
        try {
            const { data } = await Axios.post('/admin/payment', { amount: datas?.product_details?.Warranty_extension_price, product: datas.product_details?.product_id, extendDuration: datas?.product_details?.Duration_of_the_official_warranty, latitude: lat, longitude: long }, {
                headers: {
                    Authorization: window.localStorage.getItem("AdminToken")
                }
            })
            if (data?.success === true) {
                window.location.href = data?.result?.url
            }
        } catch (error) {
            console.log("🚀 ~  payment ~ error:", error)
        }
    }

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${year}/${month}/${date}`;
    }

    const calculateDifference = () => {
        let eda = datas?.product_details?.userExtenWarrenty
        const ndata = eda?.split("/")
        const ar = ndata?.reverse()
        const npurdat = ar?.join("/")
        const inaike = getDate()
        const start = new Date(npurdat);
        const end = new Date(inaike);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setWadtae(diffDays)
        const rmonth = diffDays / 30
        setWamon(parseFloat(rmonth).toFixed(0))
    };
    useEffect(() => {
        calculateDifference()
    }, [datas])
    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>

                        <div className='extented-warrenty'>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div'>
                                    <div>
                                        {/* <Link to="/user/dashboard"><img src={Menuclose} alt="close" /></Link> */}
                                        <div onClick={() => { navigate('/user/myproductview', { state: { data: datas } }) }} ><img src={Menuclose} alt="close" /></div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className='watch-div'>
                                    <img src={datas?.product_details?.main_image} alt="watch" />
                                </div>
                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        {datas?.product_details?.Product_Name}
                                    </div>
                                    {/* <div className='watch-description'>
                                        Test product description, this is a demonstration of the description of a product where we inform about the characteristics of the product
                                    </div>
                                    <div className='map-component'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.42095469063!2d78.1227348!3d9.94600025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1699970698829!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div> */}
                                    <div className='Guarantee'>
                                        {/* Warranty activated on {datas?.product_details?.purchadeAt} Valid for {untill} */}
                                        Warranty activated on {datas?.product_details?.purchadeAt} Valid for {wamon} Months
                                    </div>
                                    {datas?.product_details?.isExtended ? <></> : <div className='warrenty-records1'>
                                        Run don't lose it! The time allowed by the manufacturer to extend the warranty runs out,
                                        <p className='para-warrenty'>you have <span>{wadate}</span> days left</p>
                                    </div>}

                                    <div className='warrenty-main-display'>
                                        <div className='watch-default'>   <img src={datas?.product_details?.main_image} alt="watch" /></div>

                                        {datas?.product_details?.isExtended ? <div className='warrenty-records1 br'><p className='para-warrenty'>Warranty Extended</p></div> : <div className='warrenty-records1 br'>
                                            Extend the warranty now for an extra <p className='para-warrenty'><span>{datas?.product_details?.Extra_warranty_time}</span> Months</p>
                                        </div>}
                                        <div>
                                            <div className='for-only-65'>
                                                <div>for only</div>
                                                <span>{datas?.product_details?.Type_of_currency} {datas?.product_details?.Warranty_extension_price}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            datas?.product_details?.Warranty?.toLowerCase() == "yes" && datas?.product_details?.Brand_Warr_Fee != undefined && (datas?.product_details?.isExtended === undefined || datas?.product_details?.isExtended == 0) ?
                                                <Button className='Buy-now-warrenty' onClick={() => { payment() }} >Buy Now</Button> :
                                                datas?.product_details?.isExtended === undefined || datas?.product_details?.isExtended == 0 ?
                                                    // <p>Warrenty Extension Is In Progress</p> :
                                                    <p>Warrenty Extension Disabled</p> :
                                                    <></>


                                        }
                                    </div>
                                    {/* 
                                    <div className='trns-details'>
                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr className='warrenty-hr' />

                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>




                                    </div> */}
                                    <div className='user-logo2'>
                                        <img src={guard} alt="lg-1" />
                                        <span className='go-green'>100%</span> <span>SECURE PAYMENTS</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default ExtendWarrenty
