// import React from 'react'
// import ReactApexChart from "react-apexcharts";

// class YearlyChart extends React.Component {
//   constructor(props) {
//     super(props);
//     console.log(this.props.transac, "trns")

//     this.state = {
//       series: [{
//         data: this.props.transac
//       }],
//       options: {
//         chart: {
//           id: 'area-datetime',
//           type: 'area',
//           height: 200,
//           zoom: {
//             autoScaleYaxis: true
//           }
//         },
//         annotations: {
//           yaxis: [{
//             y: 30,
//             borderColor: '#999',
//             label: {
//               show: true,
//               text: 'Support',
//               style: {
//                 color: "#fff",
//                 background: '#00E396'
//               }
//             }
//           }],
//           xaxis: [{
//             x: '2023-12-21T11:49:47.175+00:00',
//             borderColor: '#999',
//             yAxisIndex: 0,
//             label: {
//               show: true,
//               text: 'Rally',
//               style: {
//                 color: "#fff",
//                 background: '#775DD0'
//               }
//             }
//           }]
//         },
//         dataLabels: {
//           enabled: false
//         },
//         markers: {
//           size: 0,
//           style: 'hollow',
//         },
//         xaxis: {
//           type: 'datetime',
//           min: '2023-12-21T06:35:18.428+00:00',
//           tickAmount: 6,
//         },
//         tooltip: {
//           x: {
//             format: 'dd MMM yyyy'
//           }
//         },
//         fill: {
//           type: 'gradient',
//           gradient: {
//             shadeIntensity: 1,
//             opacityFrom: 0.7,
//             opacityTo: 0.9,
//             stops: [0, 100]
//           }
//         },
//       },
//       selection: 'one_year',
//     };
//   }

//   render() {
//     return (


//       <div id="chart">
//         <div class="toolbar">
//           <button id="one_month"

//             onClick={() => this.updateData('one_month')} className={(this.state.selection === 'one_month' ? 'active' : '')}>
//             1M
//           </button>
//           &nbsp;
//           <button id="six_months"

//             onClick={() => this.updateData('six_months')} className={(this.state.selection === 'six_months' ? 'active' : '')}>
//             6M
//           </button>
//           &nbsp;
//           <button id="one_year"


//             onClick={() => this.updateData('one_year')} className={(this.state.selection === 'one_year' ? 'active' : '')}>
//             1Y
//           </button>
//           &nbsp;
//           <button id="ytd"

//             onClick={() => this.updateData('ytd')} className={(this.state.selection === 'ytd' ? 'active' : '')}>
//             YTD
//           </button>
//           &nbsp;
//           <button id="all"

//             onClick={() => this.updateData('all')} className={(this.state.selection === 'all' ? 'active' : '')}>
//             ALL
//           </button>
//         </div>

//         <div id="chart-timeline">
//           <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={200} />
//         </div>
//       </div>


//     );
//   }
// }

// export default YearlyChart;





import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const YearlyChart = ({ transac }) => {
  const [state, setState] = useState(
    {
      series: [{
        data: transac
      }],
      options: {
        chart: {
          id: 'Block_Chain',
          type: 'area',
          height: 200,
          zoom: {
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
        },
        annotations: {
          yaxis: [{
            y: 30,
            borderColor: '#999',
            label: {
              show: true,
              text: 'Support',
              style: {
                color: "#fff",
                background: '#00E396'
              }
            }
          }],
          xaxis: [{
            x: '2023-12-21T11:49:47.175+00:00',
            borderColor: '#999',
            yAxisIndex: 0,
            label: {
              show: true,
              text: 'Rally',
              style: {
                color: "#fff",
                background: '#775DD0'
              }
            }
          }]
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: '',
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
      },
      selection: 'one_year',
    }
  )

  useEffect(() => {
    setState(
      {
        series: [{
          data: transac
        }],
        options: {
          chart: {
            id: 'Block_Chain',
            type: 'area',
            height: 200,
            zoom: {
              autoScaleYaxis: true
            },
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              }
            },
          },
          annotations: {
            yaxis: [{
              y: 30,
              borderColor: '#999',
              label: {
                show: true,
                text: 'Support',
                style: {
                  color: "#fff",
                  background: '#00E396'
                }
              }
            }],
            xaxis: [{
              x: '2023-12-21T11:49:47.175+00:00',
              borderColor: '#999',
              yAxisIndex: 0,
              label: {
                show: true,
                text: 'Rally',
                style: {
                  color: "#fff",
                  background: '#775DD0'
                }
              }
            }]
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            type: 'datetime',
            min: transac?.length > 0 ? transac[0][0] : '2023-12-21T06:35:18.428+00:00',
            tickAmount: 6,
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy'
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          },
        },
        selection: 'one_year',
      }
    )
  }, [transac])


  return (
    <div id="chart">
      {/* <div class="toolbar">
        <button id="one_month"

          className={(state.selection === 'one_month' ? 'active' : '')}>
          1M
        </button>
        &nbsp;
        <button id="six_months"

          className={(state.selection === 'six_months' ? 'active' : '')}>
          6M
        </button>
        &nbsp;
        <button id="one_year"


          className={(state.selection === 'one_year' ? 'active' : '')}>
          1Y
        </button>
        &nbsp;
        <button id="ytd"
          className={(state.selection === 'ytd' ? 'active' : '')}>
          YTD
        </button>
        &nbsp;
        <button id="all"

          className={(state.selection === 'all' ? 'active' : '')}>
          ALL
        </button>
      </div> */}

      <div id="chart-timeline">
        <ReactApexChart options={state.options} series={state.series} type="area" height={200} />
      </div>
    </div>


  )
}

export default YearlyChart;