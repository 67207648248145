import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CancelInvoice from './cancelInvoice'
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Filter = () => {

  const navigate = useNavigate()

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  useEffect(() => {
    profile()
  }, [])

  const profile = async () => {
    if (role === 'brand') {
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      console.log(data.result.is_active, "datass")
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        console.log("Logout")
        navigate(`${consts.route}/`, { state: { data: true } })
      }

    }
  }


  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div >

      <div className="qr-detail">
        <Button onClick={handleOpen} className="filter-btn">Create Invoice</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="popBOX BlockPopup filterpopup">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

            <div className="invoice-create">
              <p className="new-invoice">Create new invoice</p>
              <p>The identification number will be created automatically</p>
            </div>

            <Grid container spacing={0}>
              <Grid item sm={12} md={6} lg={6} xl={6}>
                <div className="id-client">
                  <p className="new-invoice">Invoice issue date</p>
                  <div className='start-end-date start-end-date-admin invoice-date'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'DateTimePicker',
                          'TimePicker',
                          'DateRangePicker',
                        ]}
                      >

                        <DemoItem>
                          <DatePicker
                            defaultValue={yesterday}
                           // disablePast
                            views={['year', 'month', 'day']}
                          />
                        </DemoItem>

                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">ID Client</p>
                  <div>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Client data Social denomination</p>
                  <div>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Address</p>
                  <div>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Country</p>
                  <div>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">Tax identification number</p>
                  <div>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                  </div>
                </div>

                <div className="id-client">
                  <p className="new-invoice">VAT Country</p>
                  <div>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={6} lg={6} xl={6}>
                <div className="services-line">
                  <div className="add-service">
                    <p className="new-invoice">Add Services line 1</p>
                    <p><Checkbox {...label} defaultChecked />Access to the system for one year</p>
                    <p><Checkbox {...label} defaultChecked />Transactions made on the blockchain</p>
                    <p><Checkbox {...label} defaultChecked />Other services indicate the service</p>
                    <TextField id="outlined-password-input" type="text" className="field-txt-box max-size" multiline rows={4} placeholder="Other services" />
                  </div>
                  <div className="id-client">
                    <p className="new-invoice">QTY</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                    </div>
                  </div>


                  <div className="id-client">
                    <p className="new-invoice">Net unit price</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">VAT</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Discount</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Total invoice without tax</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">Total invoice with taxes</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" className="field-txt-box" />
                    </div>
                  </div>
                </div>
              </Grid>

            </Grid>
            <div className="create-cancel-btn">
              <div className="create-btn">
                <Button className="create-green-btn">Create</Button>
              </div>
              {/* <div className="create-btn">
                    <Button className="cancel-green-btn" >Cancel</Button>
                   </div> */}
              <div>
                <CancelInvoice />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>


  );
};

export default Filter;
