import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Table, TableCell, TableBody, TableRow, TableHead, TableContainer } from "@mui/material";
import Header from "../../Header/Header";
import AdminMenuSide from "../../Admin/AdminMenuSide";
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Checkbox } from "@mui/material";
import card1 from '../../../img/card1.png'
import './FeedBack.css'
import { TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../Axios";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Toaster, toast } from 'react-hot-toast'



const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '30px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },

    reasonBlock: {
        fontSize: '24px',
        fontWeight: '600'
    },
    modalpara: {
        fontSize: '18px',
        textAlign: 'center',
        maxWidth: '750px !important',
        margin: '0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)': {
            paddingBottom: '36px',
        }
    }
});

function FeedBack() {
    const [menuOpen, setMenuOpen] = useState(true)
    const classes = useStyles();
    const [fed, setFed] = useState([
        { name: 'Duke 250' },
    ])

    const navigate = useNavigate()

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }
    const [query, setQuery] = useState([])

    const handleQuery = (ind, already) => {
        var check = '';
        // if (already == 'good') {
        //     var chck = query.includes(ind)
        //     console.log("🚀 ~ handleQuery ~ chck:", chck)
        //     if (chck == true) {
        //         check = "true"
        //     }
        // }

        // if (check == "true") {
        if (query?.length == 0 && already == 'bad') {
            setQuery([ind])
        } else if (query?.includes(ind)) {
            setQuery((current) => current.filter((fruit) => fruit != ind))
        } else if (already == 'bad') {
            setQuery([...query, ind])
        }
        // }

    }

    useEffect(() => {
        console.log(query, "query")
    }, [query])

    const { id } = useParams()
    const [ids, setIds] = useState([])

    const [ships, setShips] = useState([])

    const [payload, setPayload] = useState([])

    useEffect(() => {
        setIds(JSON.parse(id))
    }, [id])

    const getShipment = async () => {
        try {
            const { data } = await Axios.post('/admin/generateshipStatus', { prodId: ids }, {
                headers: { Authorization: window.localStorage.getItem('AdminToken') }
            })
            console.log("🚀 ~ getShipment ~ data:", data)
            if (data?.success == true) {
                setShips(data?.result)
            } else {
                setShips([])
            }

        } catch (error) {
            console.log("🚀 ~ getShipment ~ error:", error)

        }
    }

    useEffect(() => {
        getShipment()
    }, [ids])

    const onChangeStatus = async (id, status, reason) => {
        console.log("🚀 ~ onChangeStatus ~ reason:", reason)
        let objToCheck = { id: id };
        let exists = payload.find(item => item.id == objToCheck.id);
        if (payload.length == 0) {
            setPayload([{ id: id, status: status, reason: reason }])
        } else if (exists) {
            const updatedData = payload.map(obj => {
                if (obj.id === id) {
                    // Update the status and reason for the specific object
                    return { ...obj, status: status, reason: reason };
                }
                return obj;
            });
            setPayload(updatedData)
        } else {
            setPayload([...payload, { id: id, status: status, reason: reason }])
        }
    }


    const onsubmit = async () => {
        console.log(payload, "payload")
        try {
            const { data } = await Axios.post('/admin/Feedback', { productid: payload }, {
                headers: { Authorization: window.localStorage.getItem('AdminToken') }
            })
            console.log("🚀 ~ onsubmit ~ data:", data)
            if (data?.success == true) {
                toast.success(data?.message)
                setTimeout(() => {
                    navigate('/')
                }, 1000);

            } else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ onsubmit ~ error:", error)
        }
    }

    return (<>
        <Grid container spacing={0}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>

                <Header />

            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                    <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                    <AdminMenuSide />
                </div>

            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='padding-lr-0px-80px'>
                <div className='tablebody fb admin-customer-table-main admin-pdts-table-main-block'>

                    <TableContainer className="table-container-feedback">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableScroll'>
                            <TableHead>
                                <TableRow className='row-head row-head-product-admin'>
                                    <TableCell className='head-bg'>product Name</TableCell>
                                    <TableCell className='head-bg'>product img</TableCell>
                                    <TableCell className='head-bg'>Category</TableCell>
                                    <TableCell className='head-bg'>Description</TableCell>
                                    <TableCell className='head-bg'>Status</TableCell>
                                    {/* <TableCell className='head-bg'>Bad</TableCell> */}
                                    {query ? (<TableCell className='head-bg' >Tell us</TableCell>) : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {ships.map((obj, ind) => {
                                    return (
                                        <TableRow >
                                            <TableCell className='body-bg'>{obj?.product_details[0]?.Product_Name}</TableCell>
                                            <TableCell className='body-bg'><img src={obj?.product_details[0]?.main_image} alt='c1' className="feedback-table-img" /></TableCell>
                                            <TableCell className='body-bg'>{obj?.product_details[0]?.product_category}</TableCell>
                                            <TableCell className='body-bg'>
                                                <div className="description-div">
                                                    {obj?.product_details[0]?.product_description.slice(0, 40)}
                                                </div>
                                            </TableCell>
                                            {/* <TableCell className='body-bg' onClick={() => { console.log(obj?._id, "sfdushfd") }} ><Checkbox /></TableCell> */}
                                            <TableCell className='body-bg'>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value="Good" control={<Radio />} label="Good" onClick={() => { onChangeStatus(obj?._id, "good", ""); handleQuery(ind, "good") }} />
                                                    <FormControlLabel value="Bad" control={<Radio />} label="Bad" onClick={() => { onChangeStatus(obj?._id, "bad", ""); handleQuery(ind, "bad") }} />
                                                </RadioGroup>
                                                {/* <Checkbox onClick={handleQuery} /> */}

                                            </TableCell>
                                            {query.includes(ind) == true ? (<TableCell className='body-bg'> <TextField id="outlined-basic" variant="outlined" className="txt-field-feedback" onChange={(e) => { onChangeStatus(obj?._id, "bad", e.target.value) }} /></TableCell>) : null}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="feedbck-btn" >
                        <Button onClick={() => { onsubmit() }} >Submit</Button>
                    </div>



                </div>
            </Grid>
        </Grid>

    </>);

}

export default FeedBack;