import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'

import "../Homepage/Home.css";
import "./GenerateCertificate.css";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import warrentylogo from "../../../img/logo-warrenty.png";
import secure from "../../../img/generate-certificate/secure.svg";
import AdminMenuSide from '../../Admin/AdminMenuSide';
import cancel from "../../../img/cancel.png"
import Web3 from 'web3';
import { Toaster, toast } from 'react-hot-toast'
import locationMark from '../../../img/locationMark.png';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { marker, Icon } from 'leaflet';
import consts from '../../../constants';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const GenerateCertificate1 = () => {

    const classes = useStyles();
    const location = useLocation()
    const navigate = useNavigate()
    const web3 = new Web3(consts.polygon_url)

    const [datas, setDatas] = useState()
    const [history, setHistory] = useState()

    useEffect(() => {
        setDatas(location.state.data)
        setHistory(location?.state?.transHistory)
    }, [location])

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    const states = {
        center: [51.505, -0.091],
        zoom: 3,
    };

    const customIcon = new Icon({
        iconUrl: locationMark,
        iconSize: [23, 30]
    })
    const nextStep = () => {
        if ((datas?.product_details?.Certificate_Price == undefined) || (datas?.product_details?.Certificate_Price == '0')) {
            toast.error('Generate Certificate is Not Enabled')
        } else {
            navigate('/user/generate-certificate2', { state: { data: datas, history: history } })
        }
    }

    return (
        <div className='Generate-certificate-page'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />
                            </div>
                        </Item>

                        <div className='extented-warrenty' onClick={closeMenuHandler}>
                            <div className='warrenty-body'>
                                <div className='warrenty-close-div2'>
                                    <div>
                                        {/* <Link to="/user/myproductview"><img src={Menuclose} alt="close" /></Link> */}
                                        <div onClick={() => { navigate('/user/myproductview', { state: { data: datas } }) }} ><img src={Menuclose} alt="close" /></div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className='watch-div'>
                                    <img src={datas?.product_details?.main_image} alt="watch" />
                                </div>
                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        {datas?.product_details?.Product_Name} ID {datas?.product_details?.product_id}
                                    </div>
                                    <div className='watch-description'>
                                        Generate Certificate of Authenticity
                                    </div>
                                    <div className='map-component genral1'>
                                        {
                                            datas != undefined &&
                                            <Map center={[datas?.product_details?.latitude, datas?.product_details?.longitude]} zoom={states?.zoom}>
                                                <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                />

                                                <Marker position={[datas?.product_details?.latitude, datas?.product_details?.longitude]} icon={customIcon} >
                                                    {/* <Popup>
                                                        <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                                        <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                                        <label>Owned By: </label> <span>{marker?.userName}</span>
                                                    </Popup> */}
                                                </Marker>


                                            </Map>
                                        }
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d54336394.93150973!2d-38.2961321!3d33.77538325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1700033209017!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    </div>

                                    <div className='warrenty-records'>
                                        latest transaction
                                    </div>

                                    <div className='trns-details'>
                                        {
                                            history?.length > 0 &&
                                            history?.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className='trns-body'>
                                                            <div className='warrenty-display'>
                                                                <div className='avatar-div'>
                                                                    <Avatar>Tx</Avatar>
                                                                </div>
                                                                <div className='reference-body' style={{ cursor: "pointer" }} onClick={() => { window.open(`${consts?.URL}/tx/${item?.TransactionHash}`) }} >
                                                                    <div className='ref-text'>
                                                                        {item?.TransactionHash?.slice(0, 25)}...
                                                                    </div>
                                                                    {/* <div className='ref-text2'>
                                                                        26 sec ago
                                                                    </div> */}
                                                                </div>

                                                            </div>
                                                            <div className='warrenty-display'>

                                                                <div className='reference-body'>
                                                                    <div className='ref-text' style={{ cursor: "pointer" }} onClick={() => { window.open(`${consts?.URL}/address/${web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[1])}`) }} >
                                                                        <p>From </p>
                                                                        {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[1])}
                                                                    </div>
                                                                    <div className='ref-text' style={{ cursor: "pointer" }} onClick={() => { window.open(`${consts?.URL}/address/${web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[2])}`) }}>
                                                                        <p>To</p>
                                                                        {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[2])}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className='gradient-border'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="415" height="2" viewBox="0 0 415 2" fill="none">
                                                                <path d="M0.279297 0.884766H414.762" stroke="url(#paint0_linear_1464_6006)" stroke-width="1.08895" />
                                                                <defs>
                                                                    <linearGradient id="paint0_linear_1464_6006" x1="415.442" y1="0.384946" x2="-7.87292" y2="-3.32038" gradientUnits="userSpaceOnUse">
                                                                        <stop stop-color="#DFE5F4" />
                                                                        <stop offset="0.503797" stop-color="#0070F0" />
                                                                        <stop offset="1" stop-color="#DFE5F4" />
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }


                                    </div>

                                    <div className='sales'>
                                        <div className='watch-div'>
                                            <img src={datas?.product_details?.main_image} alt="watch" />
                                        </div>

                                        <div className='col-svg'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="100" viewBox="0 0 3 100" fill="none">
                                                <path d="M1.33984 0.569336V87.6855" stroke="url(#paint0_linear_1464_6020)" stroke-width="1.76955" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6020" x1="1.34042" y1="87.5856" x2="-0.446899" y2="87.5752" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0378631" stop-color="#DFE5F4" />
                                                        <stop offset="0.132489" stop-color="#0070F0" />
                                                        <stop offset="0.244412" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='watch-description'>
                                            Generate Certificate of Authenticity
                                        </div>
                                        <div className='col-svg'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="100" viewBox="0 0 3 100" fill="none">
                                                <path d="M1.33984 0.569336V87.6855" stroke="url(#paint0_linear_1464_6020)" stroke-width="1.76955" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6020" x1="1.34042" y1="87.5856" x2="-0.446899" y2="87.5752" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0378631" stop-color="#DFE5F4" />
                                                        <stop offset="0.132489" stop-color="#0070F0" />
                                                        <stop offset="0.244412" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='price'>
                                            <p>for only</p>
                                            <h6>${datas?.product_details?.Certificate_Price ? datas?.product_details?.Certificate_Price : '0'}</h6>
                                        </div>
                                    </div>
                                    {/* <Link to="/user/generate-certificate2"> */}
                                  {datas?.product_details?.certificate === true ?   <div onClick={() => {
                                        nextStep()
                                    }}>
                                        <div className='gen-btn'>
                                            <Button>Generate Certificate</Button>
                                        </div>
                                    </div> :
                                    <p>Certificate Generation Disabled</p>
                                    }
                                    {/* </Link> */}
                                    <div className='user-logo'>
                                        <img src={secure} alt="secure" />
                                        <div>
                                            <span>100%</span> SECURE PAYMENTS
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default GenerateCertificate1;

