import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import PremiumChat from '../../User/PremiumBrands/PremiumChat'
import CompleteChat from '../../User/PremiumBrands/ComptedChat'
import Grid from '@mui/material/Grid';
import cross from '../../../img/cross.svg'
import Header from '../../Header/Header';
import io from "socket.io-client";
import moment from 'moment';
import QrBag from '../../../img/qr-bag-img.png'
import Thumbup from '../../../img/like-btn.png';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './Customer.css'
import Modal from "@mui/material/Modal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import group from "../../../img/group.svg";
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddLocationAltTwoToneIcon from '@mui/icons-material/AddLocationAltTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Toaster, toast } from 'react-hot-toast'
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import adidasLogo from '../../../img/adidasLogo.png';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import info1 from '../../../img/info.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Axios from '../../../Axios';
// import Renewal from './Renewal';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'
import Files from '../../../img/files.svg';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
// import CustomerFilter from './CustomerFilter';
// import Deactivte from './Deactivate';
// import Reactivate from './Reactivate';
import userProfile from '../../../img/userProfile.png'
import adidas from '../../../img/adidas.svg';
import nike from '../../../img/nike.svg';
import demo from '../../../img/demo.svg';
import rightState from '../../../img/rightState.png';
import wrongState from '../../../img/wrongState.png';
// import box from '../../../img/box.svg';
// import AddressPopup from './AddressPopup';
import AdminMenuSide from '../AdminMenuSide';
import { useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';

import locationMark from '../../../img/locationMark.png';
import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { marker, Icon } from 'leaflet';
import consts from '../../../constants';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import loader from '../../../img/loaderDtracer.gif'
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import moreicon from '../../../img/moreicon.png'
import settime from '../../../img/settime.png'
import cancel from '../../../img/cancel.png'
import profileicomstaticbig from '../../../img/profile-icom-static-big.png'
import messgcount from '../../../img/messgcount.png'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import thumbalin from '../../../img/thumb-logo.svg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FilterPopup from '../AdminProductsMenu/FilterPopup';
import RefreshIcon from '@mui/icons-material/Refresh';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import logout from '../../logout';
import approveicons from '../../../img/approvedicon.png'
import emptyicons from '../../../img/empty1.jpg'
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '50px 0 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },
    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },

    reasonBlock: {
        fontSize: '24px',
        fontWeight: '600'
    },

    modalpara: {
        fontSize: '18px',
        textAlign: 'center',
        maxWidth: '750px !important',
        margin: '0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)': {
            paddingBottom: '36px',
        },

    },

    reasonBlock: {
        fontSize: '24px',
        fontWeight: '600',
        margin: '0'
    }
});

function createData(
    brandLogo,
    brandName,
    cusName,
    email,
    status,
    moreInfo,
    renewal,

) {
    return { brandLogo, brandName, cusName, email, status, moreInfo, renewal };
}

const rows = [
    createData(adidas, 'ADIDAS', 'Ismael Santana', 'i.S@test.com', rightState, '', "10/08/2024"),
    createData(nike, 'NIKE', 'Ismael Santana', 'i.S@test.com', wrongState, '', "---------------"),
    createData(demo, 'TE DEMO BRAND', 'Ismael Santana', 'i.S@test.com', rightState, '', "10/08/2024"),
];

const styleFilter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: '82%',
    transform: "translate(-50%, -50%)",
    bgcolor: '#fff',
    border: "none",
    p: 2,
};

const styleReActive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: '#fff',
    border: "3px solid #BFFF00 !important",
    p: 2,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

let socket = io.connect(`${consts.MainUrl}`, { path: "/socket" })
const Customer = () => {
    const [isAdmin, setIsAdmin] = useState('')

    useEffect(() => {
        document.title = "Dtracer | Customers"
        setIsAdmin(window?.localStorage?.getItem('Squelch'));
    }, [])

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)
    const [customers, setCustomer] = useState()
    const [pro_id, setProductId] = useState()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const roles = window.localStorage.getItem('AdminToken')


    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const [stsDownload, setStsDownload] = useState(false)
    const [stsDownloads, setStsDownloads] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    const [value, setValue] = React.useState(0);
    const [userDetails, setUserDetails] = useState()
    const [page, setPage] = useState(1);
    const [count, setCount] = useState()
    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };
    const handleChange1 = (event, value) => {
        setPage(value);
    };

    const [value2, setValue2] = React.useState(0);


    const handleChange3 = (event, newValue) => {
        setValue2(newValue);
    };

    const [openViewMessage, setOpenViewMessage] = useState(false);
    const handleOpenViewMessage = () => setOpenViewMessage(true);
    const handleCloseViewMessage = () => setOpenViewMessage(false);


    const [openViewMap, setopenViewMap] = useState(false);
    const handleopenViewMap = () => setopenViewMap(true);
    const handleCloseViewMap = () => setopenViewMap(false);

    const [openInfo, setOpenInfo] = useState(false);
    const handleOpenInfo = () => setOpenInfo(true);
    const handleCloseInfo = () => setOpenInfo(false);

    const [users, setUsers] = useState([])

    const [openprod, setOpenprod] = useState(false);

    //const [count, setCount] = useState()
    const [statussss, setstatusss] = useState()
    // const [stsDownload, setStsDownload] = useState(false)
    // const [stsDownloads, setStsDownloads] = useState(false)
    const [ischecked, setisChecked] = useState(false)
    const [emails, setemails] = useState()
    const [emails1, setemails1] = useState()

    const [selectedRows, setSelectedRows] = useState([])
    const [selectedQr, setSelectedQr] = useState([])
    const role = window.localStorage.getItem('Squelch')
    const [statuscheck, setStatusCheck] = useState([])
    const [selcid, setSelcid] = useState()

    const [productDetails, setProductDetails] = useState()

    const [openProductInfoAdmin, setOpenProductInfoAdmin] = useState(false);
    const [openProductInfoAdmin1, setOpenProductInfoAdmin1] = useState(false);

    const handleOpenProductInfoAdmin = () => setOpenProductInfoAdmin(true);
    const handleCloseProductInfoAdmin = () => setOpenProductInfoAdmin(false);

    const handleOpenProductInfoAdmin1 = () => setOpenProductInfoAdmin1(true);
    const handleCloseProductInfoAdmin1 = () => setOpenProductInfoAdmin1(false);

    const [openAdminQRProduct, setOpenAdminQRProduct] = useState(false);
    const handleOpenAdminQRProduct = () => setOpenAdminQRProduct(true);
    const handleCloseAdminQRProduct = () => setOpenAdminQRProduct(false);

    const defaultStatus = () => {
        var dt = [
            {
                Name: "Brand",
                Read: true,
                Write: false,
            },
            {
                Name: "Products",
                Read: true,
                Write: false,
            },
            {
                Name: "Traceability",
                Read: true,
                Write: false,
            },

            {
                Name: "Blockchain",
                Read: true,
                Write: false,
            },
            {
                Name: "Post-sale",
                Read: true,
                Write: false,
            },
            {
                Name: "Billing",
                Read: true,
                Write: false,
            },
            {
                Name: "Seller Auth",
                Read: true,
                Write: false,
            },
        ]
        setStatusCheck(dt)
    }

    useEffect(() => {
        defaultStatus()
    }, [])

    const getCheckStatus = async (id) => {
        try {
            const payload = {
                _id: id
            }
            const { data } = await Axios.post('/admin/getSubadminPermission', payload,
                {
                    headers: {
                        Authorization: window.localStorage.getItem("AdminToken"),
                    },
                }
            )
            if (data?.success && data?.result?.length > 0) {
                setStatusCheck(data?.result)
            } else {
                defaultStatus()
            }
        } catch (error) {
            console.log("🚀 ~ file: SubAdminList.js:266 ~ getCheckStatus ~ error:", error)
        }
    }

    const [isActive, setIsActive] = useState(true);
    const redirectPath = '/'
    const timeoutSeconds = 5 * 60 * 1000
    const navigate = useNavigate()
    useEffect(() => {
        screenInactive()
    }, [timeoutSeconds, isActive, navigate, redirectPath]);

    const screenInactive = () => {
        let timeoutId;

        const resetTimeout = () => {
            clearTimeout(timeoutId);
            if (isActive) {
                timeoutId = setTimeout(() => {
                    logout()
                    window.localStorage.removeItem('electrónico')
                    window.localStorage.removeItem('AdminToken')
                    window.localStorage.removeItem('Squelch')
                    navigate(`${consts.route}/`)
                }, timeoutSeconds);
            }
        };

        const handleMouseActivity = () => {
            setIsActive(true);
            resetTimeout();
        };


        resetTimeout();


        window.addEventListener('mousemove', handleMouseActivity);
        window.addEventListener('keydown', handleMouseActivity);


        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleMouseActivity);
            window.removeEventListener('keydown', handleMouseActivity);
        };
    }

    const filterData = (data) => {
        // setUsers1(data)
        setCount(Math.ceil(data?.length / 10))
    }
    const reset = (data) => {
        if (data === true) {
            getSellers()
        }
    }

    useEffect(() => {
        getSellers();
    }, [])

    const getUsersProduct = async (userid) => {
        try {

            const { data } = await Axios.post('/admin/get_products_users', { user_id: userid }, {
                headers: {
                    Authorization: window.localStorage.getItem("AdminToken"),
                },
            })
            if (data?.success === true) {
                setProductDetails(data?.result)
            }
        } catch (error) {
            console.log("🚀 ~ file: Customer.js:306 ~ getUsersProduct ~ error:", error)

        }

    }

    const getSellers = async () => {
        try {
            setstatusss('Loading....')

            if (role === 'admin') {
                const { data } = await Axios.post('/admin/get_products_users', {pages : page},
                    {
                        headers: {
                            Authorization: window.localStorage.getItem("AdminToken"),
                        },
                    }
                )

                if (data?.success) {
                    if (data?.result?.length > 0) {
                        setUsers(data?.result)
                        // setCount(Math.ceil(data?.result?.length / 10))
                        setCount(Math.ceil(data?.count / 10))
                    } else {
                        setstatusss('Customers Not Available')
                    }

                } else {
                    setstatusss('Customers Not Available')
                }

            } else {
                const { data } = await Axios.post('/admin/get_products_users', {},
                    {
                        headers: {
                            Authorization: window.localStorage.getItem("AdminToken"),
                        },
                    }
                )
                if (data?.success) {
                    if (data?.result?.length > 0) {
                        setUsers(data?.result)
                        setCount(Math.ceil(data?.result?.length / 10))
                    } else {
                        setstatusss('Customers Not Available')
                    }

                } else {
                    setstatusss('Customers Not Available')
                }

            }
        } catch (error) {
            console.log(error, "err")
            setstatusss('Customers Not Available')
        }
    }

    const filterSeller = async (dts) => {
        if (dts?.length > 0) {
            setUsers(dts)
            setCount(Math.ceil(dts?.length / 10))
        } else {
            setstatusss('Customers Not Available')
            setUsers(dts)
        }

    }

    const activateDeactiveBrand = async (datas) => {
        try {
            const { data } = await Axios.post(`/subAdminStatus`, {
                _id: datas
            },
                {
                    headers: {
                        Authorization: window.localStorage.getItem("AdminToken")
                    }
                })
            if (data?.success === true) {
                toast.success(data?.message)
                getSellers()
            }
        } catch (error) {
            console.log("🚀 ~ file: SubAdminList.js:239 ~ activateDeactiveBrand ~ error:", error)

            if (error?.response?.data?.success === false) {
                toast.error(error?.response?.data?.message)
            }
        }
    }

    useEffect(()=> {
        getSellers()
    }, [page])

    // const handleChange = (name, type) => {
    //     const nextShapes = statuscheck.map(shape => {
    //         if (shape.Name === name) {
    //             // No change
    //             if (type === 'Read') {
    //                 return {
    //                     ...shape,
    //                     Read: !shape.Read,
    //                 };
    //             } else {
    //                 return {
    //                     ...shape,
    //                     Write: !shape.Write,
    //                 };
    //             }

    //         } else {
    //             // Return a new circle 50px below
    //             return shape;
    //         }
    //     });
    //     // Re-render with the new array
    //     setStatusCheck(nextShapes);
    // }
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setChat(false)
        setChat1(false)
        setdesErr(null)
        setcusErr(null)
        setnameErr(null)
        setidErr(null)
    };
    const editStatus = async () => {
        try {
            const payload = {
                _id: selcid,
                permission: statuscheck
            }

            // console.log(payload, "data")

            const { data } = await Axios.post('/admin/subAdminPermission', payload,
                {
                    headers: {
                        Authorization: window.localStorage.getItem("AdminToken"),
                    },
                }
            )
            // console.log(data, "data")
            if (data?.success) {
                toast.success(data?.message)
                handleClose()
            }
        } catch (error) {
            // console.log("🚀 ~ file: SubAdminList.js:367 ~ editStatus ~ error:", error)
        }

    }

    const [foucsLocation, setFocusLocation] = useState()

    const states = {
        center: [51.505, -0.091],
        zoom: 3,
    };

    const customIcon = new Icon({
        iconUrl: locationMark,
        iconSize: [23, 30]
    })

    const [selected, setSelected] = useState({})
    const [selected1, setSelected1] = useState({})
    const [datass, setDatass] = useState({})

    const [datas, setData] = useState([]);
    const [isSend, setIssend] = useState(false)
    const [details, setDetails] = useState({})
    const [stscheck, setStsCheck] = useState(false)
    const [reason, setReason] = useState([])
    const [other, setOther] = useState("")
    const onchangeother = (data) => {

        setOther(data)

    }
    const blockProduct = async (rows) => {
        try {
            var reasons
            if (other != "") {
                reasons = [...reason, other]
            }
            else {
                reasons = reason
            }
            const { data } = await Axios.post(`/admin/brandBlockProduct`, {
                reason: reasons,
                status: rows,
                productId: selected1?.product_id
            }, {
                headers: { Authorization: window.localStorage.getItem('AdminToken') }
            })
            if (data?.success === true) {
                toast.success(data?.message)
                getSellers()
                setReason([])

                if (rows === "block") {
                    handleClose2()
                } else {
                    handleClose3()
                }
                handleCloseInfo()
            } else {
                toast.error(data?.message)
                if (rows === "block") {
                    handleClose2()
                    handleCloseInfo()
                } else {
                    handleClose3()
                    handleCloseInfo()
                }
                setReason([])
            }
        } catch (error) {
            setReason([])
            console.log("🚀 ~ file: Customer.js:598 ~ blockProduct ~ error:", error)
        }


    }
    const [markers, setMarkers] = useState([])
    const [points, setPoints] = React.useState([]);

    const [selc, setSelc] = useState(false)
    const setTime = async (row) => {
        setSelc(true)
        const product = row?.trackExactLocation
        if (product?.length > 0) {
            var pois = []
            var arr = []
            for (let i = 0; i < product?.length; i++) {
                const element = product[i];
                if (element !== null) {
                    var obj = {
                        geocode: [element, product[i + 1]]
                    }
                    var poi = { lat: element, lng: product[i + 1] }
                    arr.push(obj)
                    pois.push(poi)
                    i++
                }
            }
            setMarkers(arr)
            setPoints(pois)
        }

    }

    const [sellersId, setSellersId] = useState()

    const downloadExcel = () => {
        let tableData = []
        if (users.length > 0) {
            for (var i = 0; i < users.length; i++) {
                var prodDetails = {
                    Thumbnail: users[i].products[0]?._id?.firstname,
                    CustomerName: users[i]?._id?.firstname,
                    Birthdaydate: users[i]?._id?.DOB,
                    Phone: users[i]?._id?.phone_no ? users[i]?._id?.phone_no : users[i]?.phone,
                    Email: users[i]?._id?.email ? users[i]?._id?.email : users[i]?.email,
                    QR: users[i]?.products[0]?.qr_link,
                    EndofWarranty: users[i]?.products[0]?.userExtenWarrenty,
                    WarrantyExpansion: users[i]?.products[0]?.isExtended ? "True" : 'False',
                    ProductStatus: users[i]?.products[0]?.status === "0" ? "Pending" : users[i]?.products[0]?.status === "1" ? "Unblocked" : "Blocked",
                    Productid: users[i]?.products[0]?.product_id,
                    Startingpoint: users[i]?.products[0]?.trackLocation[0],
                    Lastmovement: users[i]?.products[0]?.latitude
                }
                tableData.push(prodDetails)
            }
            const worksheet = XLSX.utils.json_to_sheet(tableData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            XLSX.writeFile(workbook, 'customerDetails.csv');
        }
    }

    const downloadPdf = async (product_id) => {
        try {
            setStsDownload(true)
            await Axios.get(`/pdf/download_pdf/${product_id}`,
                {
                    headers: {
                        Authorization: localStorage.getItem("AdminToken"),
                    },
                }).then(async (res) => {
                    if (res?.status === 200) {
                        setStsDownload(false)
                        window.location.href = `${consts.BackendUrl}/pdf/download_pdf/${product_id}`;
                        setTimeout(() => {

                            toast.success("Pdf Downloaded Successfully")

                        }, 2000);
                    }
                    else {
                        setStsDownload(false)
                        toast.error("Can't Download Successfully")
                    }
                }).catch((err) => {

                    console.log(err)
                    setStsDownload(false)
                })
        } catch (error) {
            setStsDownload(false)
            console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
        }

    }

    const setSelection = async (data) => {
        setSelected(data)
    }

    const setSelection1 = async (datas, data) => {

        setSelected1(data)
        setDetails(datas)
    }

    const [id, setId] = useState()
    const [name, setName] = useState()
    const [message, setMessage] = useState()
    const [message1, setMessage1] = useState()
    const [messageList, setmessageList] = useState([])
    const [messageLists, setmessageLists] = useState([])
    const [TicketList, setTicketList] = useState([])
    const [CompletedTickets, setCompletedTickets] = useState([])
    const [wallet, setWallet] = useState(null);
    const [notifications, setNotifications] = useState()
    const [openWallet, setOpenWallet] = useState(false);
    const handleOpenWallet = () => setOpenWallet(true);
    const handleCloseWallet = () => setOpenWallet(false);
    //const navigate = useNavigate()
    const [room, setRoom] = useState()
    const [tickets, setTickets] = useState([])
    const [names, setNames] = useState()
    const [emailerr, setemailerr] = useState(null)
    const [productHistory, setProductHistory] = useState([])

    const [newSelec, setNewSelec] = useState()

    const [iderr, setidErr] = useState(null)
    const [nameerr, setnameErr] = useState(null)
    const [cuserr, setcusErr] = useState(null)
    const [deserr, setdesErr] = useState(null)

    const [chat, setChat] = useState(false)
    const [chat1, setChat1] = useState(false)
    const handleChat = () => {
        setChat(!chat)
    }

    const handleChat1 = () => {
        setChat1(!chat1)
    }

    useEffect(() => {
        getTicket()
        getCompltedTicket()
    }, [chat, value])

    useEffect(() => {
        // getActivity()
        //getbrand()
        getTicket()
        getCompltedTicket()
    }, [])

    const getTicket = async () => {
        try {
            const { data } = await Axios.get(`/message/get_ticket`, {
                headers: {
                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                setTickets(data?.result)
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }
    }

    const getSingleTicket = async (datas, product_id) => {
        try {
            const { data } = await Axios.post(`/message/getSingleTicket`, { _id: datas, product_id: product_id }, {
                headers: {
                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                setTickets(data?.result)
                handleChat(); getOneTicket(data?.result[0]?.room)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
            return false
        }
    }

    const getCompltedTicket = async () => {

        try {

            const { data } = await Axios.get(`/message/get_completed_ticket`, {
                headers: {

                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {

                setCompletedTickets(data?.result)

            }


        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }
    }

    useEffect(() => {
        socket.on("receive", (data) => {
            setmessageList((prev) => [...prev, data]);
        });
    }, []);

    const getOneTicket = async (datas) => {
        try {
            const { data } = await Axios.get(`/message/get_one_ticket/${datas}`, {
                headers: {

                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                setRoom(data?.result?.ticketDetails?.room)
                setmessageList(data?.result?.messageDetails)
                var datass = []
                for (var i = 0; i < data?.result?.messageDetails; i++) {
                    datass.push(data?.result?.messageDetails[i].message)
                }
                setmessageLists(datass)
                setTicketList(data?.result?.ticketDetails)
                //setTickets(data?.result)
                socket.emit("join", {
                    room: data?.result?.ticketDetails?.room,
                })
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }
    }
    const [ids, setIds] = useState()

    const profile = async () => {
        try {
            if (role === 'brand') {
                const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
                    headers: {
                        Authorization: window.localStorage.getItem("AdminToken")
                    }
                })
                if (data?.success === true) {
                    //   setDatass(data?.result)
                    setIds(data?.result?._id)
                    //   setNames(data?.result?.user_info?.name)
                }
            } else {
                const { data } = await Axios.post(`/getAdminDetails`, {}, {
                    headers: {
                        Authorization: window.localStorage.getItem("AdminToken")
                    }
                })
                if (data?.success === true) {
                    //   setDatass(data?.result)
                    setIds(data?.result?._id)
                    //   if (data?.result?.name !== undefined) {
                    //     setNames(data?.result?.firstname)
                    //   }
                    //   else {
                    //     setNames(data?.result?.name)
                    //   }


                }
            }
        } catch (error) {
        }

    }

    useEffect(() => {
        profile()
    }, [])

    // const profile = async () => {
    //     try {
    //         if (role === 'brand') {
    //             const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
    //                 headers: {
    //                     Authorization: window.localStorage.getItem('AdminToken')
    //                 }
    //             })
    //             if (data?.success === true) {
    //                 setDatass(data?.result)
    //                 setIds(data?.result?._id)
    //                 setNames(data?.result?.user_info?.name)
    //             }
    //         } else {
    //             const { data } = await Axios.post(`/getAdminDetails`, {}, {
    //                 headers: {
    //                     Authorization: user
    //                 }
    //             })
    //             if (data?.success === true) {
    //                 setDatass(data?.result)
    //                 setIds(data?.result?._id)
    //                 if (data?.result?.name !== undefined) {
    //                     setNames(data?.result?.firstname)
    //                 }
    //                 else {
    //                     setNames(data?.result?.name)
    //                 }


    //             }
    //         }
    //     } catch (error) {
    //     }

    // }

    const createTicket = async () => {

        try {
            if (!id) {
                setidErr("Please select Requestor")
            }
            else if (!customers) {
                setcusErr("Please give Customer")
            }
            else if (!name) {
                setnameErr("Please give Title")
            }
            else if (!message) {
                setdesErr("Please give Description")
            }
            else {
                const { data } = await Axios.post(`/message/create_ticket`, {
                    name: name,
                    id: id,
                    description: message,
                    customer: customers,
                    product_id: pro_id
                }, {
                    headers: {

                        Authorization: window.localStorage.getItem('AdminToken')
                    }
                })
                if (data?.success === true) {
                    getTicket()
                    setdesErr(null)
                    setcusErr(null)
                    setnameErr(null)
                    setidErr(null)
                    setMessage()
                    setName()
                    setId()
                    setCustomer()
                    toast.success(data?.message)
                    setTimeout(() => {
                        setValue(0)
                    }, 2000);

                }
                else {
                    toast.error(data?.message)
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }


    }

    const getOneTicketMesg = async (datas) => {
        try {

            const { data } = await Axios.get(`/message/get_one_ticket/${datas}`, {
                headers: {

                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                setRoom(data?.result?.ticketDetails?.room)
                setmessageList(data?.result?.messageDetails)
                setTicketList(data?.result?.ticketDetails)
                //setTickets(data?.result)
                socket.emit("join", {
                    room: data?.result?.ticketDetails?.room,
                })

            }
            else {
                // toast.error(data?.result?.message)
                setRoom()
            }

        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }
    }

    const createMessage = async () => {
        try {
            socket.emit("join", {
                room: room,
            })
            const time = `${Date.now()}`
            socket.emit("send", {
                sender_name: names,
                room: room,
                time: time,
                message: message1,
            });

            const { data } = await Axios.post(`/message/send_message`, {

                room: room,
                time: time,
                message: message1,
            }, {
                headers: {

                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                getOneTicketMesg(room)
                setMessage1('')


            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }


    }

    const deleteTicket = async (datas) => {

        try {

            const { data } = await Axios.post(`/message/change_ticket`, {
                _id: datas
            }, {
                headers: {

                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                toast.success(data?.message)
                getTicket()
                getCompltedTicket()
            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }


    }

    const sendQrCode = async (datas) => {
        try {
            const { data } = await Axios.post(`/admin/sendqr`, {
                qrcode: datas,
                email: emails1
            }, {
                headers: {
                    Authorization: window.localStorage.getItem("AdminToken")
                }
            })
            if (data?.success == true) {
                setemails1('')
                toast.success("Mail Send Successfully")
            }
        } catch (error) {
            console.log("🚀 ~ file: AdminProducts.js:329 ~ sendQrCode ~ error:", error)

        }
    }

    const sendQrCode1 = async () => {
        try {

            var qrcodes = []

            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            for (var i = 0; i < selectedQr.length; i++) {
                var qrdetails = {}
                qrdetails.id = selectedQr[i].product_id
                qrdetails.link = selectedQr[i].qr_link

                qrcodes.push(qrdetails)

            }
            if (emails1 === undefined) {
                setemailerr("Please Give Email")
            }
            else if (!(re.test(emails1))) {
                setemailerr("Please Enter valid Email")
            }
            else if (qrcodes.length <= 0) {
                toast.error("Please Select Product")
            }
            else {
                const { data } = await Axios.post(`/admin/sendqrcodes`, {
                    qrcode: qrcodes,
                    email: emails1
                }, {
                    headers: {
                        Authorization: window.localStorage.getItem("AdminToken")
                    }
                })
                if (data?.success == true) {
                    setemails1('')
                    setemailerr()
                    toast.success("Mail Send Successfully")
                }
            }
            // console.log(data, "data")
        } catch (error) {
            console.log("🚀 ~ file: AdminProducts.js:329 ~ sendQrCode ~ error:", error)

        }
    }
    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const selectedInvoice = (row, details) => {
        if (selectedRows.includes(row)) {

            setSelectedRows(selectedRows.filter((selectedItem) => selectedItem !== row));

            setSelectedQr(selectedQr.filter((selectedItem) => selectedItem.qr_link !== details.qr_link))

        } else {

            setSelectedRows([...selectedRows, row]);

            setSelectedQr([...selectedQr, details])
        }


    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {

            const newSelected = selected1.map((n, index) => index);
            setSelectedRows(newSelected);
            setSelectedQr(selected1)
        } else {
            // Deselect all rows
            setSelectedRows([]);
        }
    };

    const downloadPdfCertificate = async (product_id) => {
        try {
            setStsDownload(true)
            await Axios.get(`/pdf/downloadCertificatePdf/${product_id}`,
                {
                    headers: {
                        Authorization: localStorage.getItem("AdminToken"),
                    },
                }).then(async (res) => {
                    if (res?.status === 200) {
                        setStsDownload(false)
                        window.location.href = `${consts.BackendUrl}/pdf/downloadCertificatePdf/${product_id}`;
                        setTimeout(() => {
                            setOpenProductInfoAdmin(false)
                            toast.success("Pdf Downloaded Successfully")
                            // window.location.href = `http://localhost:3000/products/all`;
                        }, 2000);
                    }
                    else {
                        setStsDownload(false)
                        toast.error("Can't Download Successfully")
                    }
                }).catch((err) => {
                    // window.location.href = `http://localhost:3000/products/all`;
                    console.log(err)
                    setStsDownload(false)
                })
        } catch (error) {
            setStsDownload(false)
            console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
        }

    }

    const sendPdfCertificate = async (product_id) => {
        try {
            setStsDownloads(true)
            await Axios.post(`/users/create_cretificate`, {
                isSend: isSend,
                product_id: product_id,
                email: emails
            },
                {
                    headers: {
                        Authorization: localStorage.getItem("AdminToken"),
                    },
                }).then(async (res) => {
                    if (res?.status === 200) {
                        setStsDownloads(false)

                        toast.success("certificate send Successfully")
                        handleCloseAdminQRProduct()
                    }
                    else {
                        setStsDownloads(false)
                        toast.error("Can't Download Successfully")
                    }
                }).catch((err) => {
                    console.log(err)
                    setStsDownloads(false)
                })
        } catch (error) {
            setStsDownloads(false)
            console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
        }

    }
    const [prodDetails, setProdDetails] = useState()
    const history = useLocation();
    const prodt = history?.state?.prod_id
    useEffect(() => {
        if (history?.state?.prod_id !== false) {
            const prodt = history?.state?.prod_id
            const prodDetailes = async (prod_id) => {
                try {
                    const { data } = await Axios.post(`/admin/proddetailes`, { prod_id: prodt }, {
                        headers: {
                            Authorization: localStorage.getItem("AdminToken"),
                        }
                    }

                    )
                    if (data?.success) {
                        setProdDetails(data.result)
                    }
                } catch (error) {
                    console.log("error", error)
                }

            }
            prodDetailes()
        }

    }, [])


    const handleOrderHistoryDetails = async(row) => {
        try {
            
            setOpenprod(true)
            const { data } = await Axios.post('/admin/prodhistory', {prodid : row?.product_details?.product_id, id : row?.product_details?.owner})
            
            if(data?.success){
    
            setProductHistory(data?.result);
    
            } else {
                setProductHistory([]);
            }

        } catch (error) {
            console.log(error,'error');
        }

       

    }

    return (
        <div className='Admin customer address list-full-cnt dashboard-page Admin-customer-page'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container className='admin-customer-contain'>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />
                            </div>
                        </Item>
                    </Grid>

                    <div onClick={closeMenuHandler}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block">

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Item className={classes.headercls}>

                                        <div className='listbody'>
                                            <div className='filter-btn'>
                                                <div className='filter-filed'>
                                                    <div className='filter-box'>
                                                        {/* <Button variant="contained">{<SearchIcon />}Filter</Button> */}
                                                        {/* <CustomerFilter filterData={filterData} reset={reset} /> */}
                                                        {/* filterData={filterData} reset={reset} */}
                                                        <FilterPopup filterSeller={filterSeller} />
                                                        <Button className='filter-color' onClick={() => { getSellers() }} >{<RefreshIcon color='primary' />}Filter Reset</Button>
                                                    </div>

                                                    <div>
                                                        <Button variant="contained" onClick={() => { downloadExcel() }} >
                                                            Export<img src={Files} alt={Files} />
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='tablebody admin-customer-table-main admin-cust'>
                                                <div className='dashboargraph-inner-top'>
                                                    <Item className={classes.dashboargraph} id="earnings-sales">
                                                        {stsDownload ?

                                                            <div className='loaders-load'>
                                                                <img src={loader} />
                                                            </div> : <TableContainer>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableScroll' >
                                                                    <TableHead>
                                                                        <TableRow className='row-head'>
                                                                            <TableCell className='head-bg'>Thumbnail</TableCell>
                                                                            <TableCell className='head-bg'>Customer Name</TableCell>
                                                                            {/* <TableCell className='head-bg'>Customer Last Name</TableCell> */}
                                                                            <TableCell className='head-bg'>Birthday date</TableCell>
                                                                            <TableCell className='head-bg'>Phone</TableCell>
                                                                            <TableCell className='head-bg'>Email</TableCell>
                                                                            <TableCell className='head-bg'>Address</TableCell>
                                                                            {/* <TableCell className='head-bg'>City</TableCell>
                                                                            <TableCell className='head-bg'>State</TableCell> */}
                                                                            <TableCell className='head-bg'>Country</TableCell>
                                                                            <TableCell className='head-bg'>Pincode</TableCell>
                                                                            {/* <TableCell className='head-bg'>QR</TableCell> */}
                                                                            {/* <TableCell className='head-bg'>End of Warranty</TableCell> */}
                                                                            {/* <TableCell className='head-bg'>Warranty Expansion</TableCell> */}
                                                                            {/* <TableCell className='head-bg'>Product Status</TableCell> */}

                                                                            <TableCell className='head-bg'>Actions</TableCell>

                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {users?.length > 0 ? users?.map((row) => (

                                                                            <TableRow key={row?._id}>
                                                                                <TableCell component="th" scope="row" className='body-bg'>
                                                                                    <div className='cus-table-img' >
                                                                                        {/* <img src={row?._id?.coverPicture ? row?._id?.coverPicture : emptyicons} /> */}
                                                                                        <img src={row?.coverPicture ? row?.coverPicture : emptyicons} />
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell className='body-bg'>{row?._id?.firstname ? row?._id?.firstname : row?.firstname}</TableCell>

                                                                                <TableCell className='body-bg'>{row?._id?.DOB ? row?._id?.DOB : row?.DOB}</TableCell>
                                                                                <TableCell className='body-bg'>{row?._id?.phone_no ? row?._id?.phone_no : row?.phone_no}</TableCell>
                                                                                <TableCell className='body-bg'>{row?._id?.email ? row?._id?.email : row?.email}</TableCell>

                                                                                <TableCell className='body-bg'>{row?._id?.address ? row?._id?.address : row?.address}</TableCell>
                                                                                {/* <TableCell className='body-bg'>{row?._id?.city ? row?._id?.city : row?.city}</TableCell>
                                                                                <TableCell className='body-bg'>{row?._id?.state ? row?._id?.state : row?.state}</TableCell> */}
                                                                                <TableCell className='body-bg'>{row?._id?.country ? row?._id?.country : row?.country}</TableCell>
                                                                                <TableCell className='body-bg'>{row?._id?.postalcode ? row?._id?.postalcode : row?.postalcode}</TableCell>
                                                                                {/* <TableCell className='body-bg'>{row?._id?.email ? row?._id?.email : row?.email}</TableCell> */}
                                                                                {/* <TableCell className='body-bg'>
                                                                                    <div className='cus-table-img' >
                                                                                        <img src={row?.product_details?.qr_link ? row?.product_details?.qr_link : row?.qr_link} />
                                                                                    </div>
                                                                                </TableCell> */}
                                                                                {/* <TableCell className='body-bg'>{row?.product_details?.userExtenWarrenty ? row?.product_details?.userExtenWarrenty : row?.userExtenWarrenty}</TableCell> */}
                                                                                {/* <TableCell className='body-bg'>{row?.product_details?.warrantyExpansion ? row?.product_details?.warrantyExpansion : "---"}</TableCell> */}

                                                                                {/* <TableCell className='body-bg'>{row?.product_details?.status === "0" ? "Pending" : (row?.product_details?.status === "1" ? "Unblocked" : "Blocked")}</TableCell> */}

                                                                                {/* <TableCell className='body-bg'>{row?.verified ? <Button className="activate"><img src={rightState} alt={rightState} /></Button> : <Button className="activate"><img src={cross} alt={cross} /></Button>}</TableCell> */}
                                                                                <TableCell className='body-bg' style={{ cursor: 'pointer' }}>
                                                                                    {/* <InfoOutlinedIcon style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px' }} onClick={() => { handleOpen(); getUsersProduct(row?.usersDetails?._id ? row?.usersDetails?._id : row?._id) }} /> */}
                                                                                    {/* <Button  >Info</Button> */}


                                                                                    {/* < SystemUpdateAltIcon style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px' }} onClick={() => downloadPdf(row?.product_details?.product_id)} /> */}
                                                                                    {/* <EmailOutlinedIcon onClick={() => { handleOpenViewMessage(); setId(row?.usersDetails?._id); setNames(row?.usersDetails?.firstname) }} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} /> */}
                                                                                    {/* {
                                                                                        row?.product_details?.status === "2" ?
                                                                                            <CheckCircleOutlineIcon onClick={() => { handleOpen3(); setSelected1(row) }} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} />
                                                                                            :
                                                                                            <BlockOutlinedIcon onClick={() => { handleOpen2(); setSelected1(row) }} handleOpen2={handleOpen2} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} />
                                                                                    } */}

                                                                                    {/* <LocationOnOutlinedIcon onClick={() => {
                                                                                        handleopenViewMap(); setSelected({
                                                                                            geocode: [row?.product_details?.latitude, row?.product_details?.longitude],
                                                                                            popUp: row?.product_details?.product_id,
                                                                                            ProductName: row?.product_details?.Product_Name
                                                                                        });
                                                                                        setSelected1(row)
                                                                                    }} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} /> */}
                                                                                    <InfoOutlinedIcon onClick={() => {
                                                                                        handleOpenInfo();
                                                                                        setId(row?._id?._id); setNames(row?._id?.firstname)
                                                                                        if (prodt == undefined) {
                                                                                            setNewSelec(row)
                                                                                        } else {
                                                                                            setNewSelec({ products: [prodDetails[0]?.product_details] })
                                                                                        }

                                                                                    }} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                            :
                                                                            <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>}
                                                    </Item>
                                                </div>
                                                <div className='pagination-numb'>
                                                    {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                                                    <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" />
                                                </div>
                                            </div>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <div className='tablebody admin-customer-table-main'>
                                <div className='dashboargraph-inner-top'>
                                    <Item className={classes.dashboargraph} id="earnings-sales">
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableScroll' >
                                                <TableHead>
                                                    <TableRow className='row-head'>
                                                        <TableCell className='head-bg'>Product Image</TableCell>
                                                        {role === 'admin' ? <TableCell className='head-bg'>Brand Name</TableCell> : <></>}
                                                        <TableCell className='head-bg'>Product Name</TableCell>
                                                        <TableCell className='head-bg'>Product Id</TableCell>
                                                        {/* <TableCell className='head-bg'>Status</TableCell> */}
                                                        <TableCell className='head-bg'>Warranty</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {productDetails?.length > 0 ? productDetails?.map((row) => (
                                                        <TableRow key={row?._id}>
                                                            <TableCell component="th" scope="row" className='body-bg'>
                                                                <div className='cus-table-img' >
                                                                    <img src={row?.product_details?.main_image} />
                                                                </div>
                                                            </TableCell>
                                                            {role === 'admin' ? <TableCell className='body-bg'>{row?.brand_name}</TableCell> : <></>}
                                                            <TableCell className='body-bg'>{row?.product_details?.Product_Name}</TableCell>
                                                            <TableCell className='body-bg'>{row?.product_details?.product_id}</TableCell>
                                                            {/* <TableCell className='body-bg'>{row?.verified ? <Button className="activate"><img src={rightState} alt={rightState} /></Button> : <Button className="activate"><img src={cross} alt={cross} /></Button>}</TableCell> */}
                                                            <TableCell className='body-bg'>
                                                                {/* <Button style={{ backgroundColor: '#94eb94', color: 'black', marginLeft: '5px' }} onClick={() => { handleOpen(); }} >Info</Button> */}
                                                                {row?.product_details?.Warranty}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                        :
                                                        <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Item>
                                </div>
                                <div className='pagination-numb'>
                                    {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                                    {/* <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" /> */}
                                </div>
                            </div>
                        </Modal>

                        {/* <Modal
                            open={openViewMessage}
                            className='whole-notify-inbox-outer'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style}>

                                <Box
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}>
                                    <div className="menu" onClick={handleCloseViewMessage} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange2}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab className='inbox-tab' label="inbox" {...a11yProps(0)} />
                                        <Tab className='Create-Incitant-tab' label="Create Incitant" {...a11yProps(1)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0} className="tab-content-whole-notify-inbox-outer">


                                        <div className='whole-notify-inbox'>


                                            <div className='notify-message notify-message-all-view'>
                                                <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                                                <div className='notify-message-detail'>
                                                    <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                                        Leakage on Lambd....</span> to you</strong>
                                                    <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                                                </div>
                                                <div className='notify-message-action'>
                                                    <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                                    <div className='action-icon'><img src={settime} alt="settime" /></div>
                                                    <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                                                </div>
                                            </div>

                                            <div className='notify-message notify-message-all-view'>
                                                <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                                                <div className='notify-message-detail'>
                                                    <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                                        Leakage on Lambd....</span> to you</strong>
                                                    <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                                                </div>
                                                <div className='notify-message-action'>
                                                    <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                                    <div className='action-icon'><img src={settime} alt="settime" /></div>
                                                    <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                                                </div>
                                            </div>

                                            <div className='notify-message notify-message-all-view'>
                                                <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                                                <div className='notify-message-detail'>
                                                    <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                                        Leakage on Lambd....</span> to you</strong>
                                                    <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                                                </div>
                                                <div className='notify-message-action'>
                                                    <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                                    <div className='action-icon'><img src={settime} alt="settime" /></div>
                                                    <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                                                </div>
                                            </div>

                                            <div className='notify-message notify-message-all-view'>
                                                <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                                                <div className='notify-message-detail'>
                                                    <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                                        Leakage on Lambd....</span> to you</strong>
                                                    <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                                                </div>
                                                <div className='notify-message-action'>
                                                    <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                                    <div className='action-icon'><img src={settime} alt="settime" /></div>
                                                    <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                                                </div>
                                            </div>

                                            <div className='whole-inbox-action-btns'>
                                                <Button variant="contained">View All</Button>
                                                <Button variant="contained">Remove All</Button>
                                                <Button variant="contained">Inbox Settings</Button>
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="tab-content-whole-notify-inbox-outer">
                                        <div className='create-issue-form'>
                                            <div className='text-field-issue'>
                                                <label>Requester Name</label>

                                                <TextField
                                                    fullWidth
                                                    id="outlined-select-currency"
                                                    select
                                                    defaultValue="EUR"
                                                >
                                                    <MenuItem value="Joshua">Joshua</MenuItem>
                                                    <MenuItem value="Joshua">Jacob</MenuItem>
                                                    <MenuItem value="Joshua">Joseph</MenuItem>
                                                    <MenuItem value="Joshua">Joshua</MenuItem>
                                                </TextField>

                                            </div>
                                            <div className='text-field-issue'>
                                                <label>Site</label>
                                                <TextField fullWidth id="outlined-basic" variant="outlined" />
                                            </div>
                                            <div className='text-field-issue'>
                                                <label>Requester Tittle</label>
                                                <TextField fullWidth id="outlined-basic" variant="outlined" />
                                            </div>
                                            <div className='text-field-issue'>
                                                <label>Description</label>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    multiline
                                                    fullWidth
                                                    maxRows={4}
                                                />
                                            </div>
                                            <Link className='Add-More-Details' to="">Add More Details</Link>
                                            <div className='Create-Issue-btn-submit'>
                                                <Button variant="contained">Create Issue</Button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Box>
                            </Box>
                        </Modal> */}

                        <Modal
                            open={openViewMessage}
                            className='whole-notify-inbox-outer whole-notify-inbox-outer-chatbox'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style}>

                                <Box className='msg-pop'
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', width: 1000, height: 500, overflow: 'scroll', justifyContent: 'flex-start', margin: '0 auto', borderRadius: '5px' }}>
                                    <div className="menu" onClick={handleCloseViewMessage} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                                    <Grid container>

                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Tabs
                                                orientation="vertical"
                                                variant="scrollable"
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="Vertical tabs example"
                                                sx={{ borderRight: 1, borderColor: 'divider', alignItems: 'center' }}
                                            >
                                                <Tab className='inbox-tab' label="inbox" {...a11yProps(0)} />
                                                <Tab className='Create-Incitant-tab' label="Create Ticket" {...a11yProps(1)} />
                                                <Tab className='Create-Incitant-tab' label="Closed Tickets" {...a11yProps(2)} />
                                            </Tabs>
                                        </Grid>

                                        <Grid xs={12} sm={12} md={12} lg={9} xl={9}>

                                            <TabPanel value={value} index={0} className="tab-content-whole-notify-inbox-outer">


                                                <div className='whole-notify-inbox'>
                                                    {chat ? (<>
                                                        <div>
                                                            <PremiumChat TicketList={TicketList} ids={ids} createMessage={createMessage} message1={message1} setMessage1={setMessage1} messageList={messageList} handleChat={handleChat} />
                                                        </div>
                                                    </>)
                                                        : (
                                                            <>
                                                                {tickets.length > 0 ? <>
                                                                    {tickets && tickets.map((ticket, index) => {
                                                                        return <div className='notify-message notify-message-all-view' key={index}>
                                                                            <div className='notify-message-detail' style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                                                <div onClick={() => { handleChat(); getOneTicket(ticket?.room) }} className="opendiv" >
                                                                                    <strong> <span>From :</span> {(String(ticket.requestor1) === ids) ? ticket?.receiver_name : ticket?.sender_name} </strong>
                                                                                    <strong> <span>To :</span> {(String(ticket.requestor1) === ids) ? ticket?.sender_name : ticket?.receiver_name} </strong>
                                                                                    {/* - <span>{ticket?.name}</span> */}
                                                                                    <p>Title : {ticket?.name}</p>
                                                                                    <p>Description : {ticket?.description}</p>
                                                                                    <p>Poduct Id: {ticket?.product_id}</p>
                                                                                    <div className='message-count-outer'>
                                                                                        <div className='message-count'> <label className='message-counter'>
                                                                                            <span>{moment(ticket.updatedAt).fromNow()}</span></label>

                                                                                            <div>
                                                                                                <img src={messgcount} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {(String(ticket.requestor2) === ids) ? <Button className="cancelbtn" variant="contained" onClick={() => { deleteTicket(ticket?._id) }}>Close</Button> : <></>}
                                                                                {/* {(String(ticket.requestor2) === ids) ? <img src={cancel} onClick={() => { deleteTicket(ticket?._id) }} alt="cancel" /> : <></>} */}
                                                                                {/* onClick={() => { handleChat1(); getOneTicket(ticket?.room) }} */}
                                                                            </div>
                                                                            <div className='whole-inbox-action-btns'>

                                                                            </div>
                                                                        </div>

                                                                    })}


                                                                </> : <>
                                                                    <div className='notify-message notify-message-all-view'>

                                                                        <div className='notify-message-detail'>
                                                                            <strong> No Tickets</strong>
                                                                        </div>

                                                                    </div>
                                                                </>}
                                                            </>
                                                        )
                                                    }


                                                </div>

                                            </TabPanel>
                                            <TabPanel value={value} index={1} className="tab-content-whole-notify-inbox-outer">
                                                <div className='create-issue-form'>
                                                    <div className='text-field-issue'>
                                                        <label>Customer</label>

                                                        <TextField
                                                            fullWidth
                                                            value={id}
                                                            onChange={(e) => { setId(e.target.value) }}
                                                            id="outlined-select-currency"
                                                            disabled
                                                            defaultValue=""
                                                        >
                                                            {/* {receivers && receivers.map((rec, index) => {
                                      return <MenuItem key={index} value={rec?.id}>{rec?.name}</MenuItem>
                                    })} */}

                                                        </TextField>

                                                    </div>
                                                    <div className='text-field-issue'>
                                                        <label>Product Id</label>
                                                        <TextField fullWidth id="outlined-basic"
                                                            value={pro_id}
                                                            onChange={(e) => setProductId(e.target.value)}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className='text-field-issue'>
                                                        <label>Customer</label>
                                                        <TextField fullWidth id="outlined-basic"
                                                            value={customers}
                                                            onChange={(e) => setCustomer(e.target.value)}
                                                            variant="outlined" />
                                                        {cuserr !== null ? (
                                                            <div style={{ color: "red", textAlign: 'center' }}>{cuserr}</div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className='text-field-issue'>
                                                        <label>Title</label>
                                                        <TextField fullWidth id="outlined-basic"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            variant="outlined" />
                                                        {nameerr !== null ? (
                                                            <div style={{ color: "red", textAlign: 'center' }}>{nameerr}</div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>

                                                    <div className='text-field-issue'>
                                                        <label>Description</label>
                                                        <TextField
                                                            id="outlined-multiline-flexible"
                                                            multiline
                                                            fullWidth
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            maxRows={4}
                                                        />
                                                        {deserr !== null ? (
                                                            <div style={{ color: "red", textAlign: 'center' }}>{deserr}</div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    {/* <Link className='Add-More-Details' to="">Add More Details</Link> */}
                                                    <div className='Create-Issue-btn-submit'>
                                                        <Button variant="contained" onClick={() => { createTicket() }}>Create Issue</Button>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel value={value} index={2} className="tab-content-whole-notify-inbox-outer">


                                                <div className='whole-notify-inbox'>
                                                    {chat1 ? (<>
                                                        <div>
                                                            <CompleteChat TicketList={TicketList} ids={ids} messageList={messageList} handleChat1={handleChat1} />
                                                        </div>
                                                    </>)
                                                        : (
                                                            <>
                                                                {CompletedTickets.length > 0 ? <>
                                                                    {CompletedTickets && CompletedTickets.map((ticket, index) => {
                                                                        return <div className='notify-message notify-message-all-view' key={index}>
                                                                            <div className='notify-message-detail' style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                                                <div onClick={() => { handleChat1(); getOneTicket(ticket?.room) }} className="opendiv" >
                                                                                    <strong> <span>From :</span> {(String(ticket.requestor1) === ids) ? ticket?.receiver_name : ticket?.sender_name} </strong>
                                                                                    <strong> <span>To :</span> {(String(ticket.requestor1) === ids) ? ticket?.sender_name : ticket?.receiver_name} </strong>
                                                                                    {/* - <span>{ticket?.name}</span> */}
                                                                                    <p>Title : {ticket?.name}</p>
                                                                                    <p>Description : {ticket?.description}</p>
                                                                                    <p>Poduct Id: {ticket?.product_id}</p>
                                                                                    <div className='message-count-outer'>
                                                                                        <div className='message-count'> <label className='message-counter'>
                                                                                            <span>{moment(ticket.updatedAt).fromNow()}</span></label>

                                                                                            <div>
                                                                                                <img src={messgcount} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* {(String(ticket.requestor2) === ids) ? <Button className="cancelbtn" variant="contained" onClick={() => { deleteTicket(ticket?._id) }}>Cancel</Button> : <></>} */}
                                                                                {/* {(String(ticket.requestor2) === ids) ? <img src={cancel} onClick={() => { deleteTicket(ticket?._id) }} alt="cancel" /> : <></>} */}
                                                                                {/* onClick={() => { handleChat1(); getOneTicket(ticket?.room) }} */}
                                                                            </div>
                                                                            <div className='whole-inbox-action-btns'>

                                                                            </div>
                                                                        </div>

                                                                    })}


                                                                </> : <>
                                                                    <div className='notify-message notify-message-all-view'>

                                                                        <div className='notify-message-detail'>
                                                                            <strong> No Tickets</strong>
                                                                        </div>

                                                                    </div>
                                                                </>}
                                                            </>
                                                        )
                                                    }


                                                </div>

                                            </TabPanel>
                                        </Grid>


                                    </Grid>


                                    {/* <div className='notify-message notify-message-all-view'>
                              <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                              <div className='notify-message-detail'>
                                <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                  Leakage on Lambd....</span> to you</strong>
                                <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                              </div>
                              <div className='notify-message-action'>
                                <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                <div className='action-icon'><img src={settime} alt="settime" /></div>
                                <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                              </div>
                            </div> */}

                                    {/* <div className='whole-inbox-action-btns'>
                              <Button variant="contained">View All</Button>
                              <Button variant="contained">Remove All</Button>
                              <Button variant="contained">Inbox Settings</Button>
                            </div> */}

                                    {/* </div>
                        </TabPanel> */}


                                </Box>
                            </Box>
                        </Modal>
                        <Modal
                            open={open2}
                            onClose={handleClose2}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="popBOX BlockPopup">
                                <div className="menu" onClick={handleClose2} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                                <div >
                                    <div id="alert-dialog-description" className='product-head'>
                                        <p className='product-block'>Block product</p>
                                        <p className={classes.modalpara}>You have selected to block these products, are you sure to block the traceability and post-sale services of the products?</p>
                                    </div>
                                    <Grid container spacing={0} className='whole-grid-value'>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className='product-list-items'>
                                                <div className='list-item-name'>
                                                    <div className='list-image'>
                                                        <img src={selected1?.main_image} alt={thumbalin} />
                                                    </div>
                                                    <div className='item-name'>
                                                        <p>{selected1?.product_id}</p>
                                                    </div>
                                                </div>
                                                {/* <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div>
                                            <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div> */}


                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p className={classes.reasonBlock}>Select reason for blocking</p>
                                            <ul className='product-detail'>
                                                <li>- The product has been stolen from its owner
                                                    <Checkbox className="boxStyle" onChange={(e) => {
                                                        if (e.target.checked === true) {
                                                            setReason([...reason, 'The product has been stolen from its owner'])
                                                        } else {
                                                            setReason(reason.filter(item => item !== 'The product has been stolen from its owner'))
                                                        }
                                                    }} />
                                                </li>
                                                <li>- Is involved in a judicial investigation<Checkbox className="boxStyle" onChange={(e) => {
                                                    if (e.target.checked === true) {
                                                        setReason([...reason, 'Is involved in a judicial investigation'])
                                                    } else {
                                                        setReason(reason.filter(item => item !== 'Is involved in a judicial investigation'))
                                                    }
                                                }} />
                                                </li>
                                                <li >- Other reasons (specify the reason)<Checkbox className="boxStyle" onChange={(e) => { }}

                                                />
                                                </li>
                                            </ul>
                                            <div className='input-box2'>
                                                <TextField className='field-box'
                                                    required
                                                    multiline
                                                    rows={5}
                                                    rowsMax={10}
                                                    variant="outlined"
                                                    onChange={(e) => { onchangeother(e.target.value) }}
                                                />
                                            </div>

                                            {/* <Grid container spacing={0} className='grid-direct'>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <div className='block-product'>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <div className='disclamer-note'>
                                                    <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                </div>
                                            </Grid>
                                        </Grid> */}



                                        </Grid>
                                        <Grid container spacing={0} sx={{ marginTop: '20px' }}>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='blockpop-btn'>
                                                <div className='cancel-all'>
                                                    <Button className='cancel-btn' onClick={() => { handleClose2() }} >Cancel</Button>
                                                </div>
                                                {/* <div className='button-direct'>
                                            <Button className='block-btn'>Block Product</Button>

                                            <div className='disclamer-note2'>
                                                <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                            </div>

                                        </div> */}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='blockpop-btn'>
                                                {/* <div className='cancel-all'>
                                            <Button className='cancel-btn'>Cancel</Button>
                                        </div> */}
                                                <div className='button-direct'>
                                                    <Button className='block-btn' onClick={() => { blockProduct("block") }} >Block Product</Button>

                                                    <div className='disclamer-note2'>
                                                        <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                    </div>

                                                </div>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </div>
                            </Box>
                        </Modal>

                        <Modal
                            open={open3}
                            onClose={handleClose3}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="popBOX BlockPopup">
                                <div className="menu" onClick={handleClose3} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                                <div >
                                    <div id="alert-dialog-description" className='product-head'>
                                        <p className='product-block'>Activate Product</p>
                                        {/* <p className={classes.modalpara}>You have selected to block these products, are you sure to block the traceability and post-sale services of the products?</p> */}
                                    </div>
                                    <Grid container spacing={0} className='whole-grid-value'>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className='product-list-items'>
                                                <div className='list-item-name'>
                                                    <div className='list-image'>
                                                        <img src={selected1?.main_image} alt={thumbalin} />
                                                    </div>
                                                    <div className='item-name'>
                                                        <p>{selected1?.product_id}</p>
                                                    </div>
                                                </div>
                                                {/* <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div>
                                            <div className='list-item-name'>
                                                <div className='list-image'>
                                                    <img src={thumbalin} alt={thumbalin} />
                                                </div>
                                                <div className='item-name'>
                                                    <p>ADD-15533-11</p>
                                                </div>
                                            </div> */}


                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <p className={classes.reasonBlock}>Reason For Activate Product</p>
                                            {/* <ul className='product-detail'>
                                                <li>- The product has been stolen from its owner
                                                    <Checkbox className="boxStyle" onChange={(e) => {
                                                        console.log(e.target.checked, "e")
                                                        if (e.target.checked === true) {
                                                            setReason([...reason, 'The product has been stolen from its owner'])
                                                        } else {
                                                            setReason(reason.filter(item => item !== 'The product has been stolen from its owner'))
                                                        }
                                                    }} />
                                                </li>
                                                <li>- Is involved in a judicial investigation<Checkbox className="boxStyle" onChange={(e) => {
                                                    console.log(e.target.checked, "e")
                                                    if (e.target.checked === true) {
                                                        setReason([...reason, 'Is involved in a judicial investigation'])
                                                    } else {
                                                        setReason(reason.filter(item => item !== 'Is involved in a judicial investigation'))
                                                    }
                                                }} />
                                                </li>
                                                <li >- Other reasons (specify the reason)<Checkbox className="boxStyle" onChange={(e) => { }}

                                                />
                                                </li>
                                            </ul> */}
                                            <br />
                                            <div className='input-box2'>
                                                <TextField className='field-box'
                                                    required
                                                    multiline
                                                    rows={5}
                                                    rowsMax={10}
                                                    variant="outlined"
                                                    onChange={(e) => { onchangeother(e.target.value) }}
                                                />
                                            </div>

                                            {/* <Grid container spacing={0} className='grid-direct'>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <div className='block-product'>

                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <div className='disclamer-note'>
                                                    <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                </div>
                                            </Grid>
                                        </Grid> */}



                                        </Grid>
                                        <Grid container spacing={0} sx={{ marginTop: '20px' }}>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='blockpop-btn'>
                                                <div className='cancel-all'>
                                                    <Button className='cancel-btn' onClick={() => { handleClose2() }} >Cancel</Button>
                                                </div>
                                                {/* <div className='button-direct'>
                                            <Button className='block-btn'>Block Product</Button>

                                            <div className='disclamer-note2'>
                                                <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                            </div>

                                        </div> */}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='blockpop-btn'>
                                                {/* <div className='cancel-all'>
                                            <Button className='cancel-btn'>Cancel</Button>
                                        </div> */}
                                                <div className='button-direct'>
                                                    <Button className='Active-btn' style={{ backgroundColor: "#1bf61b", color: "black" }} onClick={() => { blockProduct("activate") }} >Activate Product</Button>

                                                    <div className='disclamer-note2'>
                                                        <p>Remember that this option is irreversible automatically, to reverse this situation contact <Link href="#" className='support-link'>support@dtracer.io</Link></p>
                                                    </div>

                                                </div>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </div>
                            </Box>
                        </Modal>
                    {/* NEW MODEL PRODUCT HISTORY */}
                        <Modal
                            open={openprod}
                            className='whole-notify-inbox-outer'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="map-box">
                                {/* 
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}> */}
                                <div className="menu" onClick={ () => { setOpenprod(false) }}  ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                                
                                <div>
                                    <TableContainer sx={{ width: '80%', margin: '0 auto' }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='head-bg'>Product-id</TableCell>
                                                    <TableCell className='head-bg'>Amount</TableCell>
                                                    <TableCell className='head-bg'>Brand Amount</TableCell>
                                                    <TableCell className='head-bg'>Service</TableCell>
                                                    <TableCell className='head-bg'>Order id</TableCell>
                                                    <TableCell className='head-bg'>Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {productHistory?.length > 0 ? productHistory?.map((row, index) => (

                                                    <TableRow key={index}>
                                                        
                                                        <TableCell className='body-bg'>{row?.product_id}</TableCell>
                                                        <TableCell className='body-bg'>{row?.amount ? row?.amount : '0'}</TableCell>
                                                        <TableCell className='body-bg'>{row?.brand_amount ? row?.brand_amount : '0'}</TableCell>
                                                        <TableCell className='body-bg'>{row?.service}</TableCell>
                                                        <TableCell className='body-bg'>{row?.order_id}</TableCell>
                                                        <TableCell className='body-bg'>{row?.createdAt?.split('T')[0]}</TableCell>
                                                        

                                                        {/* <TableCell className='body-bg' style={{ cursor: 'pointer' }}>


                                                        </TableCell> */}

                                                    </TableRow>
                                                ))
                                                    :
                                                    <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>No Data Found</h3></TableCell>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                {/* </Box> */}
                            </Box>
                        </Modal>

                        <Modal
                            open={openInfo}
                            className='whole-notify-inbox-outer'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="map-box">
                                {/* 
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}> */}
                                <div className="menu" onClick={handleCloseInfo}  ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>



                                <div>
                                    <TableContainer sx={{ width: '80%', margin: '0 auto' }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell className='head-bg'></TableCell> */}
                                                    <TableCell className='head-bg'>Thumbnail</TableCell>
                                                    {/* <TableCell className='head-bg'>Customer Name</TableCell> */}
                                                    {/* <TableCell className='head-bg'>Customer Last Name</TableCell> */}
                                                    {/* <TableCell className='head-bg'>Birthday date</TableCell> */}
                                                    {/* <TableCell className='head-bg'>Phone</TableCell> */}
                                                    {/* <TableCell className='head-bg'>Email</TableCell> */}
                                                    <TableCell className='head-bg'>QR</TableCell>
                                                    <TableCell className='head-bg'>End of Warranty</TableCell>
                                                    <TableCell className='head-bg'>Warranty Expansion</TableCell>
                                                    <TableCell className='head-bg'>Product Status</TableCell>
                                                    <TableCell className='head-bg'>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {newSelec?.products?.length > 0 ? newSelec?.products?.map((row) => (

                                                    <TableRow key={row?._id}>
                                                        {/* <TableCell><Checkbox {...label} /></TableCell> */}
                                                        <TableCell component="th" scope="row" className='body-bg'>
                                                            <div className='cus-table-img' >
                                                                <img src={row?.product_details?.main_image ? row?.product_details?.main_image : row?.main_image} />
                                                            </div>
                                                        </TableCell>
                                                        {/* <TableCell className='body-bg'>{row?.usersDetails?.firstname ? row?.usersDetails?.firstname : row?.firstname}</TableCell> */}

                                                        {/* <TableCell className='body-bg'>{row?.usersDetails?.DOB ? row?.usersDetails?.DOB : row?.DOB}</TableCell> */}
                                                        {/* <TableCell className='body-bg'>{row?.usersDetails?.phone_no ? row?.usersDetails?.phone_no : row?.phone_no}</TableCell> */}
                                                        {/* <TableCell className='body-bg'>{row?.usersDetails?.email ? row?.usersDetails?.email : row?.email}</TableCell> */}
                                                        <TableCell className='body-bg'>
                                                            <div className='cus-table-img' >
                                                                <img src={row?.qr_link ? row?.qr_link : row?.product_details?.qr_link} />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className='body-bg'>{row?.userExtenWarrenty ? row?.userExtenWarrenty : row?.product_details?.userExtenWarrenty}</TableCell>
                                                        <TableCell className='body-bg'>{row?.isExtended ? "True" : "False"}</TableCell>
                                                        {
                                                            row?.status ?
                                                                <TableCell className='body-bg'>{row?.status === "0" ? "Pending" : (row?.status === "1" ? "Unblocked" : "Blocked")}</TableCell>
                                                                :
                                                                <TableCell className='body-bg'>{row?.product_details?.status === "0" ? "Pending" : (row?.product_details?.status === "1" ? "Unblocked" : "Blocked")}</TableCell>

                                                        }

                                                        {/* <TableCell className='body-bg'>{row?.verified ? <Button className="activate"><img src={rightState} alt={rightState} /></Button> : <Button className="activate"><img src={cross} alt={cross} /></Button>}</TableCell> */}
                                                        <TableCell className='body-bg' style={{ cursor: 'pointer' }}>
                                                            {/* <InfoOutlinedIcon style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px' }} onClick={() => { handleOpen(); getUsersProduct(row?.usersDetails?._id ? row?.usersDetails?._id : row?._id) }} /> */}
                                                            {/* <Button  >Info</Button> */}
                                                            {/* {stsDownload == true ?
                                                                <Button
                                                                    size="small"
                                                                    // onClick={handleClick}
                                                                    loading={true}
                                                                    variant="text"
                                                                    color='blue'
                                                                    disabled
                                                                >
                                                                </Button>
                                                                : */}
                                                            < SystemUpdateAltIcon style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px' }} onClick={() => downloadPdf(row?.product_id ? row?.product_id : row?.product_details?.product_id)} />

                                                            {/* } */}
                                                            <EmailOutlinedIcon onClick={
                                                                () => window.location = `mailto:${row?.email}`
                                                                // async () => {
                                                                //     console.log(row, "rows1234")
                                                                //     var dts = await getSingleTicket(row?.owner, row?.product_id)
                                                                //     console.log("🚀 ~ Customer ~ dts:", dts)
                                                                //     if (dts == true) {
                                                                //         handleOpenViewMessage();
                                                                //         {
                                                                //             if (row?.product_id) {
                                                                //                 setProductId(row?.product_id)
                                                                //             } else {
                                                                //                 setProductId(row?.product_details?.product_id)
                                                                //             }
                                                                //         }
                                                                //     } else {
                                                                //         toast.error("No Message Found")
                                                                //     }

                                                                // }
                                                            } style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} />
                                                            {
                                                                row?.status === "2" ?
                                                                    <CheckCircleOutlineIcon onClick={() => { handleOpen3(); setSelected1(row) }} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} />
                                                                    :
                                                                    <BlockOutlinedIcon onClick={() => { handleOpen2(); setSelected1(row) }} handleOpen2={handleOpen2} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} />
                                                            }

                                                            <LocationOnOutlinedIcon onClick={() => {
                                                                if (row?.product_id) {
                                                                    handleopenViewMap(); setSelected({
                                                                        geocode: [row?.latitude, row?.longitude],
                                                                        popUp: row?.product_id,
                                                                        ProductName: row?.Product_Name
                                                                    });
                                                                    setSelected1(row)
                                                                } else {
                                                                    handleopenViewMap(); setSelected({
                                                                        geocode: [row?.product_details?.latitude, row?.product_details?.longitude],
                                                                        popUp: row?.product_details?.product_id,
                                                                        ProductName: row?.product_details?.Product_Name
                                                                    });
                                                                    setSelected1(row?.product_details)
                                                                }

                                                            }} style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} />


                                                            {/* <InfoOutlinedIcon onClick={() => { handleOpenInfo(); setNewSelec(row) }} /> */}
                                                            { isAdmin == 'admin' ?
                                                            <InfoOutlinedIcon onClick={ ()=> handleOrderHistoryDetails(row) } style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px', cursor: "pointer" }} /> :
                                                            <></>
                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                ))
                                                    :
                                                    <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>No Data Found</h3></TableCell>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                {/* </Box> */}
                            </Box>
                        </Modal>


                        <Modal
                            open={openViewMap}
                            className='whole-notify-inbox-outer'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="map-box">
                                {/* 
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection:'column', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}> */}
                                <div className="menu" onClick={handleCloseViewMap}  ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                                <div className='map-comp'>
                                    {
                                        selc === false ?
                                            <Map center={selected?.geocode} zoom={states?.zoom}>
                                                <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                />

                                                <Marker position={selected?.geocode} icon={customIcon} >
                                                    <Popup>
                                                        <label>Product Id: </label> <span>{selected?.popUp}</span><br></br>
                                                        <label>Product Name: </label> <span>{selected?.ProductName}</span><br></br>
                                                        <label>Owned By: </label> <span>{selected?.userName}</span>
                                                    </Popup>
                                                </Marker>

                                            </Map>
                                            :
                                            <Map center={selected?.geocode} zoom={states?.zoom}>
                                                <TileLayer
                                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                />

                                                {
                                                    markers?.map((marker) => (
                                                        <Marker position={marker?.geocode} icon={customIcon} >
                                                            <Popup>
                                                                <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                                                <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                                                <label>Owned By: </label> <span>{marker?.userName}</span>
                                                            </Popup>
                                                        </Marker>
                                                    ))
                                                }
                                                <Polyline positions={points} color="blue" />

                                            </Map>
                                    }

                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.38238117099!2d78.14456234999999!3d9.946802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1700912768119!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                                </iframe> */}
                                </div>

                                <div>
                                    <TableContainer sx={{ width: '80%', margin: '0 auto' }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Mark on the map</TableCell>
                                                    <TableCell align="right">Product image</TableCell>
                                                    <TableCell align="right">Product id</TableCell>
                                                    <TableCell align="right">Starting point</TableCell>
                                                    <TableCell align="right">Last movement</TableCell>
                                                    <TableCell align="right">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableCell className='body-bg' onClick={() => { setTime(selected1) }} > <div className='location-img1' > <img src={locationMark} alt='location' /></div></TableCell>
                                                <TableCell className='body-bg'> <div className='location-img' > <img src={selected1?.main_image} alt='main_image' /></div></TableCell>
                                                <TableCell className='body-bg'>{selected1?.product_id}</TableCell>
                                                <TableCell className='body-bg'>{selected1?.trackLocation?.length > 0 ? selected1?.trackLocation[0] : '0'}, {selected1?.trackLocation?.length > 1 ? selected1?.trackLocation[1] : '0'}</TableCell>
                                                <TableCell className='body-bg'>{selected1?.latitude}, {selected1?.longitude}</TableCell>
                                                <TableCell className='body-bg'>
                                                    <div className='location-user' >
                                                        {/* < SystemUpdateAltIcon style={{ backgroundColor: 'transparent', color: 'black', marginLeft: '10px' }} /> */}
                                                        <div onClick={
                                                            () => { handleCloseViewMap() }
                                                        }> <img src={group} alt={group} /></div>
                                                    </div>

                                                </TableCell>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                                {/* </Box> */}
                            </Box>
                        </Modal>

                        <Modal
                            // open={true}
                            open={openProductInfoAdmin1}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className="popBOX BlockPopup infopopup">
                                <div className="menu" onClick={handleCloseProductInfoAdmin1} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className='tablebody admin-customer-table-main admin-pdts-table-main-block'>
                                        <div className='dashboargraph-inner-top'>
                                            {/* {
                          roles === 'brand' ?
                            <div className='product-allocate-btn'>
                              <FormControl sx={{ width: "auto" }}>

                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  defaultValue={age}
                                  onChange={handleChange1}
                                >
                                  {
                                    sellers?.map((item) => {
                                      return (
                                        <MenuItem value={item?._id}>{item?.firstname}</MenuItem>
                                      )
                                    })
                                  }
                                </Select>
                              </FormControl>
                              <Button onClick={() => { setSellersIds() }} >Product allocation</Button>
                            </div>
                            :
                            <></>
                        } */}

                                            <Item className={classes.dashboargraph} id="earnings-sales">

                                                <TableContainer sx={{ maxHeight: 640 }}>
                                                    <Table aria-label="simple table" className='tableScroll'>
                                                        <TableHead>
                                                            <TableRow className='row-head row-head-product-admin'>
                                                                <TableCell className='head-bg'> <Checkbox

                                                                    onChange={handleSelectAllClick}
                                                                /></TableCell>
                                                                <TableCell className='head-bg'>product</TableCell>
                                                                <TableCell className='head-bg'>Brand Name</TableCell>
                                                                <TableCell className='head-bg'>Product Id</TableCell>
                                                                <TableCell className='head-bg'>product name</TableCell>
                                                                {/* <TableCell className='head-bg'>Internal REF</TableCell> */}
                                                                <TableCell className='head-bg'>Short description</TableCell>
                                                                <TableCell className='head-bg'>Status</TableCell>
                                                                <TableCell className='head-bg'>Sold</TableCell>
                                                                <TableCell className='head-bg'>Action</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {stsDownload ?

                                                                <div className='loaders-load'>
                                                                    <img src={loader} />
                                                                </div>


                                                                : <>  {selected1?.length > 0 ? selected1?.map((row, index) => {
                                                                    const isItemSelected = isSelected(index);
                                                                    return (
                                                                        <TableRow key={index}>

                                                                            <TableCell className='body-bg' onClick={() => { console.log(row?.product_id, 'one'); setSellersId([...sellersId, row?.product_id]) }} >
                                                                                <Checkbox {...label} onChange={() => { selectedInvoice(index, row) }} checked={isItemSelected} />
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row" className='body-bg'>
                                                                                <div className='thumbalin'><img src={row?.main_image} alt={"brandLogo"} />
                                                                                </div>
                                                                                -                        </TableCell>
                                                                            <TableCell className='body-bg'>{row?.brand_name ? row?.brand_name : '-'}</TableCell>
                                                                            <TableCell className='body-bg'>{row?.product_id ? row?.product_id : '-'}</TableCell>
                                                                            <TableCell className='body-bg'>{row?.Product_Name ? row?.Product_Name : '-'}</TableCell>
                                                                            {/* <TableCell className='body-bg'>{row?.refint}</TableCell> */}

                                                                            <TableCell className='body-bg'>{row?.product_description ? row?.product_description?.split(/\s+/).slice(0, 10).join(" ") : '-'}</TableCell>
                                                                            <TableCell className='body-bg'>
                                                                                {
                                                                                    details?.status === '0' ?
                                                                                        <p>Pending..</p>
                                                                                        : <>
                                                                                            {
                                                                                                details?.status === '1' ?
                                                                                                    <p style={{ color: 'green' }} >Approved</p>
                                                                                                    :
                                                                                                    <p style={{ color: 'red' }} >Declined</p>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell className='body-bg'>{row?.isSold === true ? "Sold" : "Not Sold"}</TableCell>
                                                                            <TableCell className='body-bg'>
                                                                                <div className='icon-space'>
                                                                                    <Button onClick={() => { downloadPdf(row?.product_id) }}  ><FileDownloadOutlinedIcon /></Button>
                                                                                    <Button onClick={() => { handleOpenProductInfoAdmin(); setSelection(row) }} className="activate qr-detail"><InfoOutlinedIcon /></Button>

                                                                                    <div>
                                                                                        {
                                                                                            (details?.status === '1') && ((roles === 'brand') || (roles === 'admin')) ?
                                                                                                <Button className="activate">
                                                                                                    <div onClick={() => { handleOpenAdminQRProduct(); setSelection(row) }} className='qrbar-code'></div>
                                                                                                </Button>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </div>
                                                                                    {(roles === 'admin') ? <></> :

                                                                                        <Button>
                                                                                            {row?.status === "2" ? <CheckCircleOutlineTwoToneIcon onClick={() => { handleOpen3(); setSelection(row) }} /> : <BlockTwoToneIcon onClick={() => { handleOpen2(); setSelection(row) }} />}
                                                                                        </Button>

                                                                                    }
                                                                                    <Button onClick={() => { navigate('/traceability', { state: { data: row } }) }} > <AddLocationAltTwoToneIcon /></Button>

                                                                                    {/* {
                                    roles === 'admin' ?
                                      <Link to='/products-approved' state={{ data: row }} ><img src={approve} alt={approve} /></Link>
                                      :
                                      <></>
                                  }
                                  {
                                    (roles === 'admin') ?
                                      <div className='wdwtc'>
                                        <Link onClick={handleOpenDeclinePopUp}><img src={decline} alt={decline} /></Link>
                                      </div>
                                      :
                                      <></>
                                  } */}



                                                                                    {/* <Modal
                                open={opendecline}
                                onClose={handleClosedecline}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styledecline}>
                                  <div className='CloseOption-pop-up' onClick={handleClosedecline}><HighlightOffIcon /></div>
                                  <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                    Select the type of imported product
                                  </Typography>
                                </Box>
                              </Modal> */}



                                                                                    {/* <Button onClick={handleOpenAdminReActive}><img src={box} alt={box} /></Button>
                  <Button onClick={handleOpenAdminPdtDeActive} className="activate"><img src={cross} alt={cross} /></Button>
                  <Button onClick={handleOpenAdminApproval} className="activate"><img src={rightState} alt={rightState} /></Button> */}

                                                                                </div>
                                                                            </TableCell>


                                                                        </TableRow>
                                                                    )
                                                                })
                                                                    :
                                                                    <TableCell colSpan={4} className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>
                                                                    // <h3 style={{ textAlign: 'center' }} >{statussss}</h3>
                                                                } </>}
                                                        </TableBody>
                                                    </Table>

                                                </TableContainer>
                                                {stsDownload ? <></> : <div className='pagination-numbs'>
                                                    <div style={{ textAlign: 'center', marginBottom: '16px' }} >
                                                        <TextField className="input-txt-box" id="outlined-password-input"
                                                            placeholder="Email"
                                                            type="email"
                                                            value={emails1}
                                                            onChange={(e) => { setemailerr(); setemails1(e.target.value) }} />
                                                        {emailerr ? <div className='h6' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}

                                                    </div>
                                                    <div className="certificate-btn qr-code" style={{ "width": "50%" }}>
                                                        <Button onClick={() => { sendQrCode1() }}  >Send QR code</Button>
                                                    </div>
                                                </div>}
                                            </Item>
                                        </div>


                                        <div className='pagination-numb'>
                                            {/* <Pagination count={count} page={page} onChange={handleChange} color="primary" shape="rounded" /> */}
                                            {/* <Pagination count={10} color="primary" shape="rounded" /> */}
                                        </div>

                                    </div>
                                </Grid>
                            </Box>
                        </Modal>

                        <Modal
                            open={openAdminQRProduct}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style} className="popBOX BlockPopup qrproduct-pop">
                                <div className="menu" onClick={handleCloseAdminQRProduct} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                                <div className="product-qr-info">
                                    <div className="product-info-head">
                                        <p className="product-code-head">QR product info</p>
                                        <p className="pro-id">PRODUCT ID {selected?.product_id}</p>
                                    </div>

                                    <div className="qr-product-scan">
                                        {/* <QRCode
                        title="Dtracer"
                        value={selected?.qr_link}
                      // bgColor={back}
                      // fgColor={fore}
                      // size={size === '' ? 0 : size}
                      /> */}
                                        <img src={selected?.qr_link} alt={'qr'} />
                                    </div>

                                    <div className="product-img-bag">
                                        <div className="info-bag">
                                            <img src={selected?.main_image} alt={QrBag} />
                                        </div>
                                        {selected?.Detail1_image ?
                                            <div className="info-bag">
                                                <img src={selected?.Detail1_image} alt={QrBag} />
                                            </div>
                                            :
                                            <></>
                                        }
                                        {
                                            selected?.Detail2_image ?
                                                <div className="info-bag">
                                                    <img src={selected?.Detail2_image} alt={QrBag} />
                                                </div> :
                                                <></>
                                        }
                                        {
                                            selected?.Detail3_image ?
                                                <div className="info-bag">
                                                    <img src={selected?.Detail3_image} alt={QrBag} />
                                                </div>
                                                :
                                                <></>
                                        }


                                    </div>

                                    <div className="category-sctn">
                                        <div className="category-info">
                                            <p className="product-client-id">Client ID <span> XXX11122S</span></p>
                                        </div>
                                        <div className="category-info">
                                            <p className="product-client-id">ID PRODUCT<span> {selected?.product_id ? selected?.product_id : '-'}</span></p>
                                        </div>

                                        <div className="category-info">
                                            <p className="product-client-id">Category <span> {selected?.product_category?.selected?.product_category}</span></p>
                                        </div>

                                        <div className="approved-btn">
                                            {
                                                selected?.status === '0' ?
                                                    <Button className="approve-button">Pending</Button>
                                                    :
                                                    <>
                                                        {
                                                            selected?.status === '1' ?
                                                                <Button className="approve-button">Approved</Button>
                                                                :
                                                                <Button className="approve-button">Blocked</Button>

                                                        }
                                                    </>
                                            }
                                        </div>

                                        <div className="category-info">
                                            <p className="product-client-id">Blockchain info <Link to={`${consts?.URL}/tx/${selected?.TxHash}`} target="_blank" className="url-link">url link</Link></p>
                                        </div>
                                    </div>

                                    {
                                        role == "brand" &&
                                        <div className="generate-btns">
                                            <div style={{ textAlign: 'center', marginBottom: '16px' }} >
                                                <TextField className="input-txt-box" id="outlined-password-input"
                                                    placeholder="Email"
                                                    type="email"
                                                    value={emails1}
                                                    onChange={(e) => { setemailerr(); setemails1(e.target.value) }} />
                                                {emailerr ? <div className='h6' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}

                                            </div>
                                            <div className="certificate-btn qr-code">
                                                <Button onClick={() => { sendQrCode(selected?.qr_link) }}  >Send QR code</Button>
                                            </div>
                                            <div className="certificate-btn print-btns">
                                                <Button>Print</Button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (role == "admin") || (role == "subAdmin") ?
                                            <>
                                                <div className="action-part">
                                                    <p className="action-head">ACTION</p>
                                                    <p className="action-part-cnt">With this action you can generate a certificate of authenticity and send it to the client who has requested it,</p>
                                                    <p className="action-info">Warning: Do not generate any certificate without having received the payment for it</p>
                                                </div>

                                                <div className="generate-btns">
                                                    <div className="certificate-btn">
                                                        <Button onClick={() => { downloadPdfCertificate(selected?.product_id) }} >GENEREATE CERTIFICATE</Button>
                                                    </div>
                                                    <div className="certificate-btn qr-code">
                                                        <Button>Send QR code</Button>
                                                    </div>
                                                    <div className="certificate-btn print-btns">
                                                        <Button>Print</Button>
                                                    </div>
                                                </div>

                                                <div className="email-input-box">
                                                    <div className="email-certificate">
                                                        <div className="certificate-box">
                                                            <p className="email-send">Enter email where the certificate will be sent</p>
                                                        </div>
                                                        <div className="input-email">
                                                            <TextField className="input-txt-box" id="outlined-password-input"
                                                                placeholder="Email"
                                                                type="email"
                                                                value={emails}
                                                                onChange={(e) => setemails(e.target.value)} />
                                                            <div className="default-acct">
                                                                <p className="default-para">Use default account email</p>
                                                                <Checkbox {...label}
                                                                    value={isSend}
                                                                    onChange={() => { setIssend(!isSend) }} />
                                                            </div>
                                                            <div className="approved-btn">
                                                                {
                                                                    stsDownloads === true ?
                                                                        <Button className="down-info" >Sending...</Button>
                                                                        :
                                                                        // <Button className="down-info" onClick={() => generatePdf(selected, details?.status)}>Download info</Button>

                                                                        <Button className="approve-button" onClick={() => { sendPdfCertificate(selected?.product_id) }}>Send certificate</Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="successfull-certificate">
                                                    <div className="thumbs-up">
                                                        <img src={Thumbup} alt={Thumbup} />
                                                    </div>

                                                    <p className="success-msg">Certificate successfully sent to applicant</p>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                </div>
                            </Box>
                        </Modal>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Item className={classes.footercls}>
                                <Footer />
                            </Item>
                        </Grid>
                    </div>
                </Grid>
            </Box>
        </div>
    )
}

export default Customer
