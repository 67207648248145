import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const BillDailyCost = ({ cost, costTime }) => {

  const [state, setState] = useState(
    {
      series: [{
        name: "Daily Cost",
        data: cost
      }],
      options: {
        chart: {
          id:"Daily_Cost",
          height: 350,
          type: 'area',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: costTime
        }
      },


    }
  )

  useEffect(() => {
    console.log(cost,costTime,"asd")
    setState({
      series: [{
        name: "Daily Cost",
        data: cost
      }],
      options: {
        chart: {
          id:"Daily_Cost",
          height: 350,
          type: 'area',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: costTime
        }
      },


    })
  }, [cost, costTime])
  return (


    <div id="chart">
      <ReactApexChart options={state.options} series={state.series} type="area" height={200} />
    </div>


  )
}

export default BillDailyCost;