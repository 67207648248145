import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminSellersAuth.css";
import { Form, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Toaster, toast } from 'react-hot-toast'
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import send from '../../../img/send-msg.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import Axios from "../../../Axios";
import consts from "../../../constants";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete"
const user = window.localStorage.getItem('Squelch')
// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const AddSellersPopup = () => {
  // const classes = useStyles();
  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [createopen, createsetOpen] = React.useState(false);
  const createhandleOpen = () => createsetOpen(true);
  const createhandleClose = () => createsetOpen(false);
  const email = useRef(null);
  const firstname = useRef(null);
  const sellerid = useRef(null);
  const phone = useRef(null);
  const contactphone = useRef(null);
  const contactmail = useRef(null);
  const staffno = useRef(null);

  const address = useRef(null);
  const city = useRef(null);
  const state = useRef(null);
  // const country = useRef(null);
  const postalcode = useRef(null);

  const inputconfrimRef = useRef(null);
  const selectedValueRef = useRef('user');
  const [emailerr, setemailerr] = useState(null)
  const [usernameerr, setusernameerr] = useState(null)
  const [selleriderr, setselleriderr] = useState(null)
  const [phoneerr, setphoneerr] = useState(null)
  const [iderr, setiderr] = useState(null)
  const [valiaddre, setValiaddre] = useState(null)

  const [contactphoneerr, setcontactphoneerr] = useState(null)
  const [contactemailerr, setcontactemailerr] = useState(null)
  const [staffnoerr, setstaffnoerr] = useState(null)

  const [addresserr, setaddresserr] = useState(null)
  const [cityerr, setcityerr] = useState(null)
  const [stateerr, setstateerr] = useState(null)
  const [countryerr, setcountryerr] = useState(null)
  const [postalcodeerr, setpostalcodeerr] = useState(null)
  const [cpassworderr, setcpassworderr] = useState(null)
  const [confirmPass, setConfirmPass] = useState(null)
  const [mail, setMail] = useState("")
  const [address1, setAddress1] = useState("");
  // Use useRef to store the selected value

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const rows = [
    createData('USR22', '43y6p2'),
    createData('USR23', '43y6p2'),
    createData('USR24', '43y6p2'),
  ];

  function createData(
    dueDate,
    service

  ) {
    return { dueDate, service };
  }

  const isMounted = useRef(false);
  const [userDetails, setUserDetails] = React.useState({})
  const [brands, setbrands] = React.useState([])
  const Role = localStorage.getItem("Squelch")


  useEffect(() => {
    profile()
  }, [])

  const profile = async () => {
    if (role === 'brand') {
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        console.log("Logout")
        navigate(`${consts.route}/`, { state: { data: true } })
      }

    }
  }





  useEffect(() => {
    if (Role === 'admin') {
      getBrands();
      isMounted.current = true;
    }
    else {
      console.log("fetched")
    }
  }, [])

  const getBrands = async () => {
    try {
      if (user) {
        const { data } = await Axios.get('/admin/get_all_brands',
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )
        if (data?.success === true) {
          setbrands(data?.result)
        }
      }
    } catch (error) {
      console.log(error, "err")
    }

  }

  const [storeopen, storesetOpen] = React.useState(false);
  const storehandleOpen = () => storesetOpen(true);
  const storehandleClose = () => storesetOpen(false);

  const storerows = [
    createData('SF1001', 'SF1001', '786543'),
    createData('SF1002', 'SF1002', '786543'),
    createData('SF1003', 'SF1003', '786543'),
    createData('SF1004', 'SF1004', '786543'),
    createData('SF1005', 'SF1005', '786543')

  ];

  function createData(
    dueDate,
    service,
    password

  ) {
    return { dueDate, service, password };
  }


  const [deltaopen, deltasetOpen] = React.useState(false);
  const deltahandleOpen = () => deltasetOpen(true);
  const deltahandleClose = () => deltasetOpen(false);


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  // const handleChanges = (event) => {

  //   selectedValueRef.current = event.target.value; // Update the ref with the new value
  //   console.log(selectedValueRef.current, event.target.value)
  // };

  const handleChange = (event) => {

    selectedValueRef.current = event.target.value; // Update the ref with the new value
  };
  function verifyemail() {
    setemailerr(null)
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.current.value)) {
      setMail("")
    } else if (email.current.value === "") {
      setMail("")
    } else {
      setMail("Invalid Email Format")
    }
  }
  // function getPassword() {
  //   setpassworderr(null)
  //   // if (inpassref.current.value === inputconfrimRef.current.value) {
  //   //   setConfirmPass("");
  //   // } else {
  //   //   setConfirmPass("Password Miss-Match");
  //   // }
  // }

  // function confirmPassword() {
  //   setcpassworderr(null)
  //   if (inpassref.current.value === inputconfrimRef.current.value) {
  //     setConfirmPass(null);
  //   } else {
  //     setConfirmPass("Password Miss-Match");
  //   }
  // }
  const handleEmailchange = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.current.value === "") {
      setemailerr("Please Enter Email")
    } else if (!(re.test(email.current.value))) {
      setemailerr("Please Enter valid Email")
    }
    else {
      setemailerr()
    }
  }

  const handlePhonechange = () => {

    let reg = /^[0-9]+$/;
    if (phone.current.value === "") {
      setphoneerr("Please Enter Phone Number")
    } else if (!(reg.test(phone.current.value))) {
      setphoneerr("Please Valid Phone Number")
    }
    else {
      setphoneerr()
    }
  }

  const handlecEmailchange = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (contactmail.current.value === "") {
      setcontactemailerr("Please Enter Email")
    } else if (!(re.test(contactmail.current.value))) {
      setcontactemailerr("Please Enter valid Email")
    }
    else {
      setcontactemailerr()
    }
  }

  const [lat, setLat] = useState()
  const [lon, setLon] = useState()

  const [country, setCounty] = useState()

  const handleStaffchange = () => {
    let reg = /^[0-9]+$/;
    if (staffno.current.value === "") {
      setstaffnoerr("Please Enter No of Staff")
    } else if (!(reg.test(staffno.current.value))) {
      setstaffnoerr("Please Enter valid Number")
    }
    else {
      setstaffnoerr()
    }
  }

  const handlecPhonechange = () => {

    let reg = /^[0-9]+$/;
    if (contactphone.current.value === "") {
      setcontactphoneerr("Please Enter Phone Number")
    } else if (!(reg.test(contactphone.current.value))) {
      setcontactphoneerr("Please Valid Phone Number")
    }
    else {
      setcontactphoneerr()
    }
  }
  const nft = async (e) => {
    try {
      setpostalcodeerr(null)
      const add = await Axios.post('/validateaddress', {
        address: address1
      })
      if (add?.data?.success === true) {
        setValiaddre("Address Verified")
        let fromadd = add?.data?.result?.address.split(",")
        let postad = fromadd[2]
        let postadd = postad.split(" ")
        let geodata = add?.data?.geodata
        setLat(add?.data?.geodata?.location?.latitude)
        setLon(add?.data?.geodata?.location?.longitude)
        // if (fromadd[1] != undefined) {
        //   city.current.value = fromadd[1]
        // }
        // if (fromadd[3] != undefined) {
        //   country.current.value = fromadd[3]
        // }
        // if (postadd[1] != undefined) {
        //   state.current.value = postadd[1]
        // }
        // if (postadd[2] != undefined) {
        //   postalcode.current.value = postadd[2]
        // }
      } else {
        console.log("failed");
        setValiaddre("Address Not Verified")
      }


    } catch (error) {
      console.log(error, "error");
    }
  }


  const handleSuccessRegister = async () => {
    console.log(lon, lat, "larwserihsif")
    try {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let reg = /^[0-9]+$/;
      if (firstname.current.value === "") {
        setusernameerr("Please Enter Seller Name")
      }
      else if (sellerid.current.value === "") {

        setselleriderr("Please Enter Seller Id")
      } else if (email.current.value === "") {
        setemailerr("Please Enter Email")
      } else if (!(re.test(email.current.value))) {
        setemailerr("Please Enter valid Email")
      } else if (phone.current.value === "") {
        setphoneerr("Please Enter Phone Number")
      } else if (contactphone.current.value === "") {
        setcontactphoneerr("Please Enter Contact Phone Number")
      } else if (contactmail.current.value === "") {
        setcontactemailerr("Please Enter Email")
      } else if (!(re.test(contactmail.current.value))) {
        setcontactemailerr("Please Enter valid Email")
      } else if (staffno.current.value === "") {
        setstaffnoerr("Please Enter No.of.Staff")
      } else if (!(reg.test(staffno.current.value))) {
        setstaffnoerr("Please Valid number")
      }
      else if (address1 === "") {
        setaddresserr("Please Enter Address")
      }
      else if (postalcode.current.value === "") {
        setpostalcodeerr("Please Enter Postal Code")
      }
      else {
        if (Role === 'brand') {

          // let newad = address1.split(",")
          // let city = newad.length - 1

          const { data } = await Axios.post('/users/seller_register',
            {
              firstname: firstname.current.value,
              sellerId: sellerid.current.value,
              email: email.current.value,
              phone: phone.current.value,
              contact_mail: contactmail.current.value,
              contact_phone: contactphone.current.value,
              no_of_staff: staffno.current.value,
              address: address1,
              city: "",
              state: "",
              latitude: lat,
              longitude: lon,
              country: country,
              postal_code: postalcode.current.value,
              role: "seller",
            },
            {
              headers: {
                Authorization: localStorage.getItem("AdminToken"),
              },
            }
          )

          if (data.success) {
            // navigate(`${consts.route}/register-success`)
            toast.success('Seller Created Successfully')
            setTimeout(() => {
              window.location.reload()
              setOpen(false)
            }, 2000);


          }
          else {
            toast.error(data.message)
          }

        }
      }
      if (Role === 'admin') {
        let newad = address1.split(",")
        let city = newad.length - 1
        const { data } = await Axios.post('/users/seller_register',
          {
            firstname: firstname.current.value,
            sellerId: sellerid.current.value,
            email: email.current.value,
            phone: phone.current.value,
            contact_mail: contactmail.current.value,
            contact_phone: contactphone.current.value,
            no_of_staff: staffno.current.value,
            address: address1,
            city: "",
            state: "",
            country: country,
            postal_code: postalcode.current.value,
            role: "seller",
          },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )

        if (data.success) {
          // navigate(`${consts.route}/register-success`)
          toast.success('Seller Created Successfully')
          setTimeout(() => {
            window.location.reload()
            setOpen(false)
          }, 2000);


        }
        else {
          toast.error(data.message)
        }

      }
    }
    catch (error) {
      console.log(error, "err")
      if (error?.response?.data?.message === "Email Already Exists") {
        setemailerr(error?.response?.data?.message)
      } else {
        toast.error("Cant Add Seller")
      }
    }
  }



  const handleChangeAddress = (newAddress) => {
    setAddress1(newAddress);
  };
  const handleSelectAddress = (newAddress) => {
    // let ad = newAddress.split(",")
    // console.log(ad.length, "address-vijay");
    // const cou = ad.length - 1
    // console.log();
    // console.log(cou, "vjlenghth");
    // console.log(ad[cou], "country");
    setAddress1(newAddress);
    // city.current.value = ad[1]
    // state.current.value = ad[2]
    // country.current.value = ad[cou]
    geocodeByAddress(newAddress)
      .then((results) => {
        console.log(results, "results")
        getLatLng(results[0])
        if (results?.length > 0) {
          for (let i = 0; i < results[0]?.address_components?.length; i++) {
            const element = results[0]?.address_components[i];
            if (element?.types?.includes("country")) {
              console.log(element?.long_name, "country elemenrs")
              setCounty(element?.long_name)
            }
          }
        }

      }
      )
      .then((latLng) => {
        console.log("Success", latLng)
        setLat(latLng?.lat)
        setLon(latLng?.lng)
      })
      .catch((error) => console.error("Error", error));
  };




  return (
    <div className='add-seller'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className='filter-box account'>
        <Button className='filter-color' onClick={handleOpen}> <AccountCircleIcon /> Add Authorized Seller</Button>

      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX add-seller">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          <div className="headTitle">Create New Seller</div>

          {(Role === "admin") || (Role === 'subAdmin') ? <div className="listOne" style={{ alignItems: "flex-start" }}>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Select Brand                </Typography>
                <FormControl fullWidth>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    inputRef={selectedValueRef}
                    onChange={handleChange}
                  >
                    {brands.map((brand, index) => (
                      <MenuItem key={index} value={brand?._id}>
                        {brand?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {iderr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{iderr}</div> : <></>}
              </div>
            </div>

          </div> : <></>}


          {/* <PlacesAutocomplete
            value={address1}
            onChange={handleChangeAddress}
            onSelect={handleSelectAddress}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input"
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete> */}


          <div className="listOne" style={{ alignItems: "flex-start" }}>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Seller Name
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={firstname} onChange={() => { setusernameerr(null) }} />

              </div>
              {usernameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{usernameerr}</div> : <></>}
            </div>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Seller ID
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={sellerid} onChange={() => { setselleriderr(null) }} />

              </div>
              {selleriderr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{selleriderr}</div> : <></>}
            </div>
            <div className="productId">


              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Email
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={email} onChange={() => { handleEmailchange() }} />

              </div>
              {emailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}
            </div>

          </div>

          <div className="listOne" style={{ alignItems: "flex-start" }}>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Phone
                </Typography>
                <TextField id="outlined-basic" type="number" variant="outlined" className='textField' inputRef={phone} onChange={() => { handlePhonechange() }} />

              </div>
              {phoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{phoneerr}</div> : <></>}
            </div>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Contact Person's Email
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={contactmail} onChange={() => { handlecEmailchange(null) }} />

              </div>
              {contactemailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactemailerr}</div> : <></>}
            </div>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Contact Person's Phone
                </Typography>
                <TextField id="outlined-basic" type="number" variant="outlined" className='textField' inputRef={contactphone} onChange={() => { handlecPhonechange() }} />

              </div>
              {contactphoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactphoneerr}</div> : <></>}
            </div>

          </div>

          <div className="listOne" style={{ alignItems: "flex-start" }}>
            <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  No Of Staff
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={staffno} onChange={() => { handleStaffchange() }} />

              </div>
              {staffnoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{staffnoerr}</div> : <></>}
            </div>

            <div className="productId">

              <div className="cmn-input-cls">
                <span>Address</span>
                <PlacesAutocomplete
                  value={address1}
                  onChange={handleChangeAddress}
                  onSelect={handleSelectAddress}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input
                        {...getInputProps({
                          // placeholder: "Search Places ...",
                          className: "location-search-input"
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

              </div>
            </div>


            {/* <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Address
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={address} onChange={() => { setaddresserr(null) }} />

              </div>
              {addresserr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{addresserr}</div> : <></>}
            </div> */}
            {/* <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  City
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={city} onChange={() => { setcityerr(null) }} />

              </div>
              {cityerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{cityerr}</div> : <></>}
            </div> */}
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Postal Code
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={postalcode} onChange={(e) => nft(e)} />

              </div>
              {postalcodeerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{postalcodeerr}</div> : <></>}
            </div>
          </div>

          <div className="listOne last-list" style={{ alignItems: "flex-start" }}>
            {/* <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  State
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={state} onChange={() => { setstateerr(null) }} />

              </div>
              {stateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{stateerr}</div> : <></>}
            </div> */}

            {/* <div className="productId">


              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Country
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={country} onChange={() => { setcountryerr(null) }} />

              </div>
              {countryerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{countryerr}</div> : <></>}
            </div> */}

            {/* <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Postal Code123
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' inputRef={postalcode} onChange={(e) => { nft(e) }} />

              </div>
              {postalcodeerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{postalcodeerr}</div> : <></>}
            </div> */}
          </div>
          <div className="error-msg">
            <>{valiaddre === "Address Verified" ? <></> : <p>{valiaddre}</p>}</>
          </div>

          <div className="sucess-msg">
            <>{valiaddre === "Address Not Verified" ? <></> : <p>{valiaddre}</p>}</>
          </div>

          <div className="filter-box account">
            <Button onClick={() => { handleSuccessRegister() }}>Create Authorized Seller</Button>
          </div>
        </Box>
      </Modal>

      {<Modal
        open={createopen}
        onClose={createhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popBOX add-seller">

          <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


          <div className="headTitle">Add Authorized Staff</div>
          <div className="listOne" style={{ alignItems: "flex-start" }}>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                seller's name
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Bharath"
                  className="textField"
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Seller delta
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="kumar"
                  className="textField"
                />
              </div>
            </div>

          </div>


          <div className="listOne" style={{ alignItems: "flex-start" }}>

            <div className='SellerPaidTable'>
              <Table sx={{ minWidth: 550 }} aria-label="customized table">
                <TableHead className='SellerTableHead'>
                  <TableRow className='SellerTableRow'>
                    <StyledTableCell className="head-part-table">user </StyledTableCell>
                    <StyledTableCell className="head-part-table">password</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='SellerTableBody'>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell className="table-value">{row.dueDate}</StyledTableCell>
                      <StyledTableCell className="table-value">{row.service}</StyledTableCell>

                    </StyledTableRow>


                  ))}
                </TableBody>
              </Table>
            </div>
          </div>

          <div className="add-staff">
            <div className='user-txt'>
              <h4>User</h4>
              <p>If you don't know the name of the staff, leave it blank and we will create a unique ID for that staff.</p>
            </div>

            <div className='user-txt'>
              <h4>Password</h4>
              <p>If you do not know what password to assign, leave the space blank and we will assign a random password for each staff</p>
            </div>
          </div>

          <div className="filter-box account add">
            <Button onClick={createhandleClose} className='cancel'>Cancel</Button>
            <Button onClick={storehandleOpen}>Generate user</Button>
          </div>

        </Box>
      </Modal>}

      {<Modal
        open={storeopen}
        onClose={storehandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popBOX add-seller">

          <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


          <div className="headTitle">SELLER STORE DELTA</div>

          <div className="listOne" style={{ alignItems: "flex-start" }}>

            <div className='SellerPaidTable'>
              <Table sx={{ minWidth: 550 }} aria-label="customized table">
                <TableHead className='SellerTableHead'>
                  <TableRow className='SellerTableRow'>
                    <StyledTableCell className="head-part-table">staff number </StyledTableCell>
                    <StyledTableCell className="head-part-table">id user</StyledTableCell>
                    <StyledTableCell className="head-part-table">password</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='SellerTableBody'>
                  {storerows.map((storerow) => (
                    <StyledTableRow key={storerow.name}>
                      <StyledTableCell className="table-value">{storerow.dueDate}</StyledTableCell>
                      <StyledTableCell className="table-value">{storerow.service}</StyledTableCell>
                      <StyledTableCell className="table-value">{storerow.password}<RefreshIcon /></StyledTableCell>
                    </StyledTableRow>


                  ))}
                </TableBody>
              </Table>
            </div>
          </div>


          <div className="filter-box account add">
            <Button onClick={deltahandleOpen}>send by email,the passwords to the seller</Button>
            <Button className='cancel'>Generate all password</Button>
          </div>

        </Box>
      </Modal>}

      {<Modal
        open={deltaopen}
        onClose={deltahandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="popBOX add-seller">

          <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


          <div className="headTitle">SELLER STORE DELTA</div>

          <div className="message">
            <img src={send} alt="closeMenu" />
            <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
          </div>


          <div className="filter-box account add">
            <Button>send by email,the passwords to the seller</Button>
            <Button className='cancel'>Generate all password</Button>
          </div>

        </Box>
      </Modal>}


    </div>
  );
};

export default AddSellersPopup;
