
export const verifyText = (event) => {
    event.preventDefault();

    let re = /^[a-zA-Z ]+$/;
    if (!re.test(event.target.value)) {
        return { error: true, msg: 'components.registerTrial.form.errors.validText' };
    } else if (event.target.value === "") {
        return { error: true, msg: 'components.registerTrial.form.errors.enterText' };
    } else {
        return { error: false, msg: "", value : event.target.value };
    }
}

export const verifyFreeText = (event) => {
    event.preventDefault();

    let re = /^[a-zA-Z0-9.,\- ]+$/;
    if (!re.test(event.target.value)) {
        return { error: true, msg: 'components.registerTrial.form.errors.validText' };
    } else if (event.target.value === "") {
        return { error: true, msg: 'components.registerTrial.form.errors.enterText' };
    } else {
        return { error: false, msg: "", value : event.target.value };
    }
}

export const verifyPostCode = (event) => {
    event.preventDefault();

    let re =/^[a-zA-Z0-9.,\-]+$/
    if (!re.test(event.target.value)) {
        return { error: true, msg: 'components.registerTrial.form.errors.validText' };
    } else if (event.target.value === "") {
        return { error: true, msg: 'components.registerTrial.form.errors.enterText' };
    } else {
        return { error: false, msg: "", value : event.target.value };
    }
}


export const verifyEmail = (event) => {
    event.preventDefault();

    let re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!re.test(event.target.value)) {
        return { error: true, msg: 'components.registerTrial.form.errors.validEmail' };
    } else if (event.target.value === "") {
        return { error: true, msg: 'components.registerTrial.form.errors.enterEmail' };
    } else {
        return { error: false, msg: "", value : event.target.value };
    }
}

export const verifyPhone = ( phone ) => {
    let reg = /^\+\d{1,3}\d{4,}$/;
    console.log('pruebas: ' , phone);
    if (phone === "") {
        return { error: true, msg: 'components.registerTrial.form.errors.enterPhone' };
    }
    else if (!(reg.test(phone))) {
        return { error: true, msg: 'components.registerTrial.form.errors.validPhone' };
    }
    else {
        return { error: false, msg: "" , value : phone};
    }
  }

  

export const verifyUrl = ( event ) => {
    let reg = /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|(([0-9]{1,3}\.){3}[0-9]{1,3}))(:\d+)?(\/[-a-zA-Z0-9@:%._\+~#=]*)*(\?[;&a-zA-Z0-9@:%_\+.~#?&//=]*)?(#[-a-zA-Z0-9_]*)?$/;
    if (event.target.value === "") {
        return { error: true, msg: "components.registerTrial.form.errors.enterUrl" };
    }
    else if (!(reg.test(event.target.value))) {
        return { error: true, msg: "components.registerTrial.form.errors.validUrl" };
    }
    else {
        return { error: false, msg: "", value : event.target.value };
    }
}