import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link } from 'react-router-dom'

import "../Userdashboard.css"
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import like from "../../../img/like.png"
import AdminMenuSide from '../../Admin/AdminMenuSide';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const PaswdChange = () => {

    const [showPassword, setShowPassword] = React.useState(false);

    const [paswdchange, setPaswdchange] = React.useState(false)

    const paswdChange = () => {
        setShowPassword("test")
        setPaswdchange(true)
    }

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>



                        </Item>
                        <div className='pasword-change-body'>
                            <div className='security-pswd'>
                                Security Password Change
                            </div>

                            {paswdchange === true ? (<>
                                <div className='paswd-success-body'>
                                    <div className='like-icon'>
                                        <img src={like} alt="like" />
                                    </div>
                                    <div className='paswd-success-text'>
                                        Perfect we have updated your security password, remember to keep this password in a safe place, since it is essential to make any movement within the blockcahin
                                    </div>
                                    <Link to="/user/login2" className='paswd-change-link'><Button className='submit-paswd'>Close</Button></Link>


                                </div>
                            </>) :
                                (<div className='paswd-mid-body'>
                                    <div className='old-paswd'>
                                        Enter the old password to know that you are the owner
                                    </div>
                                    <div className='email-password-block'>
                                        <div className='cmn-input-cls'>
                                            <FormControl sx={{ m: 1 }} variant="outlined" className='textField'>
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <OutlinedInput

                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="OldPassword"
                                                />
                                            </FormControl>


                                        </div>




                                        {/* <div className='crtPassword'><Link to={`${consts.route}/reset-password`}>Forgot Password ?</Link></div> */}
                                    </div>
                                    <div className='old-paswd'>
                                        Enter the New password to know that you are the owner
                                    </div>
                                    <div className='email-password-block'>
                                        <div className='cmn-input-cls'>
                                            <FormControl sx={{ m: 1 }} variant="outlined" className='textField'>
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <OutlinedInput

                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="New Password"
                                                />
                                            </FormControl>


                                        </div>
                                        <div className='email-password-block'>
                                            <div className='cmn-input-cls'>
                                                <FormControl sx={{ m: 1 }} variant="outlined" className='textField'>
                                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput

                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Confirm Password"
                                                    />
                                                </FormControl>


                                            </div>




                                            {/* <div className='crtPassword'><Link to={`${consts.route}/reset-password`}>Forgot Password ?</Link></div> */}
                                        </div>

                                        <Link className='paswd-change-link'><Button className='submit-paswd' onClick={paswdChange} >password change</Button></Link>


                                        {/* <div className='crtPassword'><Link to={`${consts.route}/reset-password`}>Forgot Password ?</Link></div> */}
                                    </div>
                                </div>)}


                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default PaswdChange
