import React, { useState, useEffect } from "react";
import "./AdminFilterPopup.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import motor from '../../../img/motor.png';
import thumbsUp from '../../../img/thumbsUp.png';
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const AdminSettingPopup = () => {

  const navigate = useNavigate()

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  useEffect(() => {
    profile()
  }, [])

  const profile = async () => {
    try {
      if (role === 'brand') {
        const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
          headers: {
            Authorization: user
          }
        })
        if (data.result.is_active === false) {
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`, { state: { data: true } })
        }

      }
    } catch (error) {
      console.log("🚀 ~ file: AdminSettingPopup.js:140 ~ profile ~ error:", error)

    }

  }

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [reopen, setreopen] = useState(false);
  const handlereopen = () => setreopen(true);
  const handlereClose = () => setreopen(false);

  const [applyAll, setapplyAll] = useState(false);
  const handleapplyAll = () => setapplyAll(true);
  const handlecloseAll = () => setapplyAll(false);

  const [Thumb, setThumb] = useState(false);
  const handleThumb = () => setThumb(true);
  const handleThumbClose = () => setThumb(false);

  const [cancel, setCancel] = useState(false);
  const handleCancel = () => setCancel(true);
  const handleCancelClose = () => setCancel(false);


  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => setConfirm(true);
  const handleConfirmClose = () => setConfirm(false);

  const [brandName, setBrandName] = useState("")
  const [productId, setProductId] = useState("")

  const [brandNameerr, setBrandNameerr] = useState()
  const [productIderr, setProductIderr] = useState()

  const [selcProduct, setSelcProduct] = useState()

  const [brandWarrFee, setBrandWarrFee] = useState(0)
  const [dtracerWarrFee, setDtracerWarrFee] = useState(0)
  const [brandChangeOwnerFee, setBrandChangeOwnerFee] = useState(0)
  const [dtracerChangeOwnerFee, setDtracerChangeOwnerFee] = useState(0)
  const [brandCertificateFee, setBrandCertificateFee] = useState(0)
  const [dtracerCertificateFee, setDtracerCertificateFee] = useState(0)
  const [warrantyPrice, setWarrantyPrice] = useState(0)
  const [certificatePrice, setCertificatePrice] = useState(0)
  const [changeOwnerPrice, setChangeOwnerPrice] = useState(0)
  const [statusState, setStatusState] = useState(false);
  const [changeowner , setChangeowner] = useState(false);
  const [certi , setCerti] = useState();


  const selctBrand = async () => {
    try {
      if (brandName == "") {
        setBrandNameerr("Please Enter Brand Name ")
      } else if (productId == "") {
        setProductIderr("Please Enter Product ID ")
      } else {
        const { data } = await Axios.post('/admin/getPostSale', {

          brandName: brandName,
          productId: productId

        }, {
          headers: { Authorization: window.localStorage.getItem("AdminToken") }
        })
        console.log("🚀 ~ file: AdminSettingPopup.js:175 ~ selctBrand ~ data:", data)
        if (data?.success === true) {
          if (data?.result?.length > 0) {
            setSelcProduct(data?.result[0])
            const dts = data?.result[0]
            setWarrantyPrice(dts?.Warranty_extension_price)
            setCertificatePrice(dts?.Certificate_Price ? dts?.Certificate_Price : 0)
            setChangeOwnerPrice(dts?.Change_Owner_price ? dts?.Change_Owner_price : 0)
            setBrandWarrFee(dts?.Brand_Warr_Fee ? dts?.Brand_Warr_Fee : 0)
            setDtracerWarrFee(dts?.Dtracer_Warr_Fee ? dts?.Dtracer_Warr_Fee : 0)
            setBrandChangeOwnerFee(dts?.Brand_Chagne_Owner_Fee ? dts?.Brand_Chagne_Owner_Fee : 0)
            setDtracerChangeOwnerFee(dts?.Dtracer_Change_Owner_Fee ? dts?.Dtracer_Change_Owner_Fee : 0)
            setBrandCertificateFee(dts?.Brand_Cer_Fee ? dts?.Brand_Cer_Fee : 0)
            setDtracerCertificateFee(dts?.Dtrace_Cer_Fee ? dts?.Dtrace_Cer_Fee : 0)
            setChangeowner(dts?.changeOwner === true? dts?.changeOwner : false) 
            setStatusState(dts?.Warranty === "yes" ? true : false) 
            setCerti(dts?.certificate === true ? dts?.certificate : false) 
            handlereopen();
          } else {
            toast.error("Incorrect Brand Name or Product Id")
          }
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminSettingPopup.js:174 ~ selctBrand ~ error:", error)
    }
  }

  
  const updateProduct = async (dataas) => {
    try {
      if(certi === true && ((certificatePrice == 0) || (brandCertificateFee == 0 )|| (dtracerCertificateFee == 0) )){
        toast.error("Please fill all the field of certificate")
      }else if(changeowner === true && ((changeOwnerPrice == 0) || (brandChangeOwnerFee == 0) || (dtracerChangeOwnerFee == 0)) ){
        toast.error("Please fill all the field of Changeowner")
      } else if(statusState === true && ((warrantyPrice == 0) ||( brandWarrFee == 0) || (dtracerWarrFee == 0))){
        toast.error("Please fill all the field of Warrenty")
      }else{
        const payload = {
          product_id: dataas === "single" ? productId : selcProduct?.product_id,
          warrantyPrice: warrantyPrice,
          Certificate_Price: certificatePrice,
          Change_Owner_price: changeOwnerPrice,
          Brand_Warr_Fee: brandWarrFee,
          Dtracer_Warr_Fee: dtracerWarrFee,
          Brand_Chagne_Owner_Fee: brandChangeOwnerFee,
          Dtracer_Change_Owner_Fee: dtracerChangeOwnerFee,
          Brand_Cer_Fee: brandCertificateFee,
          Dtrace_Cer_Fee: dtracerCertificateFee,
          Warranty: statusState,
          certificate:certi,
          changeOwner:changeowner,
          updateall: dataas === "single" ? false : true
        }
        const { data } = await Axios.post(`/admin/updatePostSale`, payload, {
          headers: { Authorization: window.localStorage.getItem("AdminToken") }
        })
        console.log("🚀 ~ file: AdminSettingPopup.js:239 ~ updateProduct ~ data:", data)
        if (data?.success === true) {
          toast.success(data?.message)
          handlereClose()
          handleClose()
        } else {
          toast.error(data?.message)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminSettingPopup.js:238 ~ updateProduct ~ error:", error)
    }


  }


  return (
    <div className="adminFilterPopup AdminSettingPopup">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Button onClick={handleOpen} className="setting">
        <SearchIcon />
        &nbsp; Change Setting
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX adminFilterPopup AdminSettingPopup AdminSettingPopup-postsale">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          <div className="reason">Change Setting</div>
          <div className="listOne">
            <Grid container>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                <div className="productId">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    Brand Name
                  </Typography>

                  <div className="cmn-input-cls">
                    <TextField
                      id="outlined-basic"
                      placeholder="Nike"
                      variant="outlined"
                      className="textField"
                      onChange={(e) => { setBrandName(e.target.value); setBrandNameerr("") }}
                    />
                  </div>
                </div>
                {
                  brandNameerr != "" ? <div style={{ textAlign: "center", color: "red" }}>{brandNameerr}</div> : <></>
                }
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                <div className="productId">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    Product ID
                  </Typography>

                  <div className="cmn-input-cls">
                    <TextField
                      id="outlined-basic"
                      placeholder="235615"
                      variant="outlined"
                      className="textField"
                      onChange={(e) => { setProductId(e.target.value); setProductIderr("") }}
                    />
                  </div>
                </div>
                {
                  productIderr != "" ? <div style={{ textAlign: "center", color: "red" }}>{productIderr}</div> : <></>
                }
              </Grid>
            </Grid>
          </div>
          <div className="wholeButton">
            <Button onClick={() => { selctBrand() }} className='apply' >Apply</Button>
          </div>

          <Modal
            open={reopen}
            onClose={handlereClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

            <Box sx={style} className="popBOX adminFilterPopup AdminSettingPopup reopen AdminSettingPopup-postsale AdminChangeSettingPopup-postsale">
              <div className="menu" onClick={handlereClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

              <div className="reason">Change Setting</div>

              <div className="listOne">
                <Grid container>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                      >
                        Brand Name
                      </Typography>

                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          placeholder="Nike"
                          variant="outlined"
                          className="textField"
                          value={brandName}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                      >
                        Product ID
                      </Typography>

                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          placeholder="235615"
                          variant="outlined"
                          className="textField"
                          value={productId}
                        />
                      </div>
                    </div>
                  </Grid>

                </Grid>

              </div>

              <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className="gridBox">
                <Item className={classes.headercls}>

                  <div className='many-block-with-label-span-post-sale-admin'>

                    <div className='post-sale-admin-block'>
                      <div><label>Product Image</label></div>
                      <div><span><img src={selcProduct?.main_image} alt="motor" /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label>brand name</label></div>
                      <div><span>{selcProduct?.brand_name}</span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label>Product Name</label></div>
                      <div className='value'><span>{selcProduct?.Product_Name} </span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label>Internal REF</label></div>
                      <div><span>{selcProduct?.ean}</span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label className="headline">Activate or deactivate warranty extension service</label></div>
                      {/* <div><span><FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                      /></span></div> */}
                      <div><span><Switch  onChange={() => setStatusState(prevCheck => !prevCheck)} defaultChecked={statusState} color="warning" /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label className="headline">Activate or deactivate change of owner service</label></div>
                      {/* <div className='value'><span><FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                      /></span></div> */}
                      <div><span><Switch onChange={() => setChangeowner(prevCheck => !prevCheck)} defaultChecked={ changeowner === true? true : false } color="warning" /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label className="headline">Activate or deactivate certificate issuance service</label></div>
                      <div><span><Switch onChange={() => setCerti(prevCheck => !prevCheck)} defaultChecked={certi === true? true : false } color="warning" /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div className="color-orange-bg"><label className="headline">fee brand warrantly extension</label></div>
                      <div><span><input type="Number" value={brandWarrFee} style={{ textAlign: "center" }} onChange={(e) => { setBrandWarrFee(e.target.value) }} /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div className="color-green-bg"><label className="headline">fee dtracer warrantly extension</label></div>
                      <div><span><input type="Number" value={dtracerWarrFee} style={{ textAlign: "center" }} onChange={(e) => { setDtracerWarrFee(e.target.value) }} /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div className="color-orange-bg"><label className="headline">fee brand change of ownership</label></div>
                      <div><span><input type="Number" value={brandChangeOwnerFee} style={{ textAlign: "center" }} onChange={(e) => { setBrandChangeOwnerFee(e.target.value) }} /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div className="color-green-bg"><label className="headline">fee dtracer change of ownership</label></div>
                      <div><span><input type="Number" value={dtracerChangeOwnerFee} style={{ textAlign: "center" }} onChange={(e) => { setDtracerChangeOwnerFee(e.target.value) }} /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div className="color-orange-bg"><label className="headline">brand fee for issuing certificate</label></div>
                      <div><span><input type="Number" value={brandCertificateFee} style={{ textAlign: "center" }} onChange={(e) => { setBrandCertificateFee(e.target.value) }} /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div className="color-green-bg"><label className="headline">dtracer fee for issuing certificate</label></div>
                      <div><span><input type="Number" value={dtracerCertificateFee} style={{ textAlign: "center" }} onChange={(e) => { setDtracerCertificateFee(e.target.value) }} /></span></div>
                      {/* <div className='action-ps-options'>
        <img src={actionps1} alt="actionps1"/>
        <img src={actionps2} alt="actionps2"/>
        <img src={actionps3} alt="actionps3"/>
        <img src={actionps4} alt="actionps4"/>
        <img src={actionps5} alt="actionps5"/>
      </div> */}
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label className="headline">warrantly extension price</label></div>
                      <div><span><input type="Number" value={warrantyPrice} style={{ textAlign: "center" }} onChange={(e) => { setWarrantyPrice(e.target.value) }} /></span></div>
                    </div>

                    <div className='post-sale-admin-block'>
                      <div><label className="headline">certificate issue price</label></div>
                      <div><span><input type="Number" value={certificatePrice} style={{ textAlign: "center" }} onChange={(e) => { setCertificatePrice(e.target.value) }} /></span></div>
                    </div>
                    <div className='many-block-with-label-span-post-sale-admin left'>
                      <div className='post-sale-admin-block'>
                        <div><label className="headline">change of owner price</label></div>
                        <div><span><input type="Number" value={changeOwnerPrice} style={{ textAlign: "center" }} onChange={(e) => { setChangeOwnerPrice(e.target.value) }} /></span></div>
                      </div>

                      <div className='post-sale-admin-block'>
                        <div><label className="headline">period allowed from the purchase to apply the extension of the guarantee</label></div>
                        <div><span>15 days</span></div>
                      </div>

                      <div className='post-sale-admin-block'>
                        <div><label className="headline">period for which the guarantee extends</label></div>
                        <div><span>2 years</span></div>
                      </div>

                    </div>

                  </div>

                </Item>
              </Grid>

              <div className="wholeButton">
                <Button className='apply' onClick={() => { updateProduct("single") }} >Apply</Button>
                {/* <Button onClick={handleapplyAll} className='applyAll'>Apply To All</Button> */}
                <Button onClick={() => { updateProduct("multiple") }} className='applyAll'>Apply To All</Button>
              </div>

              <Modal
                open={applyAll}
                onClose={handlecloseAll}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box sx={{ ...style, width: 400 }} className="popBOX adminFilterPopup AdminSettingPopup reopen applyAll">
                  <div className="menu" onClick={handlecloseAll} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="reason">Change Setting</div>

                    <div className="alertInfo space">ATTENTION WILL MODIFY THE CONDITIONS OF THE $ID PRODUCT TO THE FOLLOWING CHANGES <br></br>
                      BEFORE XXXXXX <br></br>
                      AFTER THE CHANGE XXX</div>

                    <div className="alertInfo">ARE YOU SURE TO MAKE THIS CHANGE?</div>

                    <div className="wholeButton">
                      <Button onClick={handleThumb} className='apply'>Apply</Button>
                      <Button className='applyAll'>Cancel</Button>

                      <Modal
                        open={Thumb}
                        onClose={handleThumb}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...style, width: 400 }} className="popBOX adminFilterPopup AdminSettingPopup reopen applyAll thumb">
                          <div className="menu" onClick={handleThumbClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="reason">Change Setting</div>
                            <div className="textCenter">
                              <div><img src={thumbsUp} alt={thumbsUp} className="img-fluid" /></div>
                              <div className="Info">CHANGES APPLIED CORRECTLY</div>
                            </div>
                            <div className="wholeButton">
                              <Button onClick={handleCancel} className='applyAll'>Cancel</Button>
                            </div>

                            <Modal
                              open={cancel}
                              onClose={handleCancel}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box sx={{ ...style, width: 400 }} className="popBOX adminFilterPopup AdminSettingPopup reopen applyAll thumb cancel">
                                <div className="menu" onClick={handleCancelClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className="reason">Cancel Order</div>
                                  <div className="alertInfo cancel">You are going to proceed to cancel the order ID$ XXXX please indicate the reason for the cancellation, this will generate a refund to the customer by his usual payment method and it cannot be counted as a sale for the brand</div>

                                  <div className="reason select">Select the reason</div>

                                  <div className="listThree Select-the-reason-checkboxes">
                                    <div className="warn">
                                      <div className="statusList"><Checkbox className="checkBox" />Payment error</div>
                                      <div className="statusList"><Checkbox className="checkBox" />Trademark request</div>
                                      <div className="statusList"><Checkbox className="checkBox" />Other motives </div>
                                      <div className="statusList"><Checkbox className="checkBox" />Request by the client of the brand</div>
                                      <div className="statusList"><Checkbox className="checkBox" />The client is not satisfied</div>

                                    </div>

                                  </div>
                                  <div className="wholeButton">
                                    <Button onClick={handleConfirm} className='applyAll'>Reset Filter</Button>
                                  </div>

                                  <Modal
                                    open={confirm}
                                    onClose={handleConfirm}
                                    aria-labelledby="parent-modal-title"
                                    aria-describedby="parent-modal-description"
                                  >
                                    <Box sx={{ ...style, width: 400 }} className="popBOX adminFilterPopup AdminSettingPopup reopen applyAll thumb cancel confirm">
                                      <div className="menu" onClick={handleConfirmClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="reason">Cancel Order</div>
                                        <div className="alertInfo confirm">You will confirm that you have issued the corresponding payment of $ XX of the order ID XXX and on the [DATE] and this order will remain as paid to Brand XXXX
                                          Do you agree with this action?</div>

                                        <div className="reason select Select-the-reason-checkboxes-head">Select the reason</div>

                                        <div className="listThree Select-the-reason-checkboxes">
                                          <div className="warn">
                                            <div className="statusList"><Checkbox className="checkBox" />Wire Transfer</div>
                                            <div className="statusList"><Checkbox className="checkBox" />Via Wallet</div>
                                            <div className="statusList"><Checkbox className="checkBox" />Other</div>
                                          </div>

                                        </div>
                                        <div className="wholeButton">
                                          <Button className='apply'>Mark As Paid</Button>
                                          <Button className='applyAll'>Reset Filter</Button>
                                        </div>
                                      </Grid>
                                    </Box>
                                  </Modal>
                                </Grid>
                              </Box>
                            </Modal>

                          </Grid>
                        </Box>
                      </Modal>
                    </div>
                  </Grid>
                </Box>
              </Modal>


            </Box>
          </Modal>


        </Box>
      </Modal>
    </div>
  );
};

export default AdminSettingPopup;
