import React, { useState } from "react";
import "../../Admin/AdminProducts/AdminProducts.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import filter from '../../../img/filter.svg';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Axios from "../../../Axios";

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
  overflow: "scroll",
  maxHeight: "600px"
};

const CustomerFilter = ({ ResetFilter, filterApi, reset, setisFilter, page, setCount, Invoice_Id, setInvoice_Id, Payment_method, setPayment_method, Total_without_tax, setTotal_without_tax, price, setprice, Total_bil,
  setTotal_bil, Brand_Name, setBrand_Name, open, setOpen, handleOpen, handleClose,
  creationDate, setCreationDate,
  paymentDate, setPaymentDate,
  handlecreationdatechange,
  handlepaymentdatechange }) => {
  // const classes = useStyles();




  return (
    <div >
      <Button onClick={handleOpen} className="filterBtn" variant="contained" >Filter &nbsp;<img src={filter} /></Button>
      <Button className='filter-color' onClick={() => { ResetFilter() }}>{<RefreshIcon color='primary' />}Filter Reset</Button>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="AdminCustomerpopBOX AdminCustomerpopBOX-product-filter" >
          <div className="filter-overflow">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            <div className="h2Variant">Products filter</div>

            <div className="listOne">

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Invoice Id
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Enter Your Amount "
                    variant="outlined"
                    className="textField"
                    value={Invoice_Id}
                    onChange={(e) => { setInvoice_Id(e.target.value) }}
                  />
                </div>
              </div>

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Brand Name
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Brand mail"
                    variant="outlined"
                    className="textField"
                    value={Brand_Name}
                    onChange={(e) => { setBrand_Name(e.target.value) }}

                  />
                </div>
              </div>

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                customer ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div> */}

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Payment Method
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Brand mail"
                    variant="outlined"
                    className="textField"
                    value={Payment_method}
                    onChange={(e) => { setPayment_method(e.target.value) }}

                  />
                </div>
              </div>

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Total_without_tax
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Contact Mail"
                    variant="outlined"
                    className="textField"
                    value={Total_without_tax}
                    onChange={(e) => { setTotal_without_tax(e.target.value) }}
                  />
                </div>
              </div>

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  price
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Enter Your Amount "
                    variant="outlined"
                    className="textField"
                    value={price}
                    onChange={(e) => { setprice(e.target.value) }}
                  />
                </div>
              </div>

            </div>

            <div className="listTwo">

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Total_bil
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Contact Mail"
                    variant="outlined"
                    value={Total_bil}
                    className="textField"
                    onChange={(e) => { setTotal_bil(e.target.value) }}
                  />
                </div>
              </div>



              <div className="productId start-end-date">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Creation Date
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'DatePicker',
                      'DateTimePicker',
                      'TimePicker',
                      'DateRangePicker',
                    ]}
                  >
                    <DemoItem>
                      <DatePicker
                        defaultValue={yesterday}
                        // disablePast
                        //selected = {creationDate}
                        views={['year', 'month', 'day']}
                        onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}      //console.log(e.$D,e.$M,e.$y)
                      //value = {creationDate}
                      />
                    </DemoItem>

                    <DemoItem>
                      <DatePicker
                        defaultValue={yesterday}
                        // disablePast
                        //selected = {paymentDate}
                        views={['year', 'month', 'day']}
                        onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }} //;billdata();
                      // value = {paymentDate}
                      />
                    </DemoItem>

                  </DemoContainer>
                </LocalizationProvider>
              </div>



            </div>


            <div className="butOutLayer">
              <Button className='reset' onClick={() => { ResetFilter() }} >Reset filter</Button>
              <Button className='apply' onClick={() => { filterApi() }} >Apply</Button>
            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default CustomerFilter;
