import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import cross from '../../../img/cross.svg'
import Header from '../../Header/Header';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './AdminCustomer.css'
import consts from '../../../constants';
import Modal from "@mui/material/Modal";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Toaster, toast } from 'react-hot-toast'
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import adidasLogo from '../../../img/adidasLogo.png';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Axios from '../../../Axios';
import Renewal from './Renewal';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Link, useNavigate } from 'react-router-dom'
import Files from '../../../img/files.svg';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import CustomerFilter from './CustomerFilter';
import Deactivte from './Deactivate';
import Reactivate from './Reactivate';
import userProfile from '../../../img/userProfile.png'
import rightState from '../../../img/rightState.png';
import box from '../../../img/box.svg';
import AddressPopup from './AddressPopup';
import AdminMenuSide from '../AdminMenuSide';
import { useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import WalletPopUp from './WalletPopUp';
import CryptoJS from "crypto-js";
import loader from '../../../img/loaderDtracer.gif'
import logout from '../../logout';
import * as XLSX from "xlsx";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '50px 0 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },
  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});
const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: '82%',
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "none",
  p: 2,
};

const styleReActive = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "3px solid #BFFF00 !important",
  p: 2,
};



const AdminCustomer = ({ row }) => {
  useEffect(() => {
    document.title = "Dtracer | Customers"
  }, [])
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const isMounted = useRef(null)
  const [click, setClick] = useState(false);
  const handleOpen = () => setClick(true);
  const handleExit = () => {
    setClick(false);
    setAge('')
    setnameerr()
    setemailerr()
    setweberr('')
    setphoneerr('')
    setaddresserr('')
    // setcityerr('')
    // setstateerr('')
    // setcountryerr('')
    setpincodeerr('')
    setcontactphoneerr('')
    setcontactemailerr('')
    setcontactnameerr('')
    settaxiderr('')
    settaxnameerr('')
    setabillingerr('')
    setsbillingerr('')
    // setcbillingerr('')
    setbanknoerr('')
    setbanknameerr('')
    setnumbererr('')
    setswifferr('')
    setinfoerr('')
    setlicencenoerr('')
    setpriceerr('')
    settotalpriceerr('')
    setdiscounterr('')
    setondateerr('')
    setexpiredateerr('')
    setredateerr('')
    setinformationerr('')
    setlogo('')
    setlogoerr('')
    setpaymenterr('')
  }
  const [users, setUsers] = useState([])
  const [users1, setUsers1] = useState([])
  // const [user, setUser] = useState({})
  const [openReActive, setOpenReActive] = useState(false);
  // const handleOpenReActive = () => setOpenReActive(true);
  const handleCloseReActive = () => setOpenReActive(false);
  const [value, setValue] = React.useState('business');

  // const [openEditInfo, setOpenEditInfo] = useState(false);
  // const handleOpenEditInfo = () => setOpenEditInfo(true);
  // const handleCloseEditInfo = () => setOpenEditInfo(false);

  const role = window.localStorage.getItem('Squelch')
  const status = window.localStorage.getItem('isimo')

  const [decrptedData, setDecrptedData] = useState()
  const secretPass = 'DtRaCeRsEcrEtFrEe'
  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt(status, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };

  useEffect(() => {
    if (role === 'subAdmin') {
      decryptData()
    }
  }, [status])

  // user info
  const type = useRef(null)
  const type1 = useRef(null)
  const name = useRef(null);
  const email = useRef(null);
  const web = useRef(null);
  const phone = useRef(null);
  // const address = useRef(null);
  // const city = useRef(null);
  // const state = useRef(null);
  // const country = useRef(null);
  const pincode = useRef(null);
  const contactname = useRef(null);
  const contactphone = useRef(null);
  const contactmail = useRef(null);
  const taxid = useRef(null);
  const taxname = useRef(null);
  // const addressbilling = useRef(null);
  const statusbilling = useRef(null);
  // const countrybilling = useRef(null);
  const bankno = useRef(null);
  const bankname = useRef(null);
  const number = useRef(null);
  const swiff = useRef(null);
  const info = useRef(null);
  // const prperli = useRef(null);
  const licenceno = useRef(null)
  const [price, setPrice] = useState(null)
  const [totalprice, setTotalPrice] = useState(null)
  const discount = useRef(null)
  const tax = useRef(null)
  const ondate = useRef(null)
  const expiredate = useRef(null)
  const redate = useRef(null)
  const information = useRef(null)

  const [nameerr, setnameerr] = useState(null)
  const [emailerr, setemailerr] = useState(null)
  const [weberr, setweberr] = useState(null)
  const [phoneerr, setphoneerr] = useState(null)
  const [addresserr, setaddresserr] = useState(null)
  // const [cityerr, setcityerr] = useState(null)
  // const [stateerr, setstateerr] = useState(null)
  // const [countryerr, setcountryerr] = useState(null)
  const [pincodeerr, setpincodeerr] = useState(null)
  const [contactphoneerr, setcontactphoneerr] = useState(null)
  const [contactemailerr, setcontactemailerr] = useState(null)
  const [contactnameerr, setcontactnameerr] = useState(null)

  const [taxiderr, settaxiderr] = useState(null)
  const [taxerr, settaxerr] = useState(null)
  const [taxnameerr, settaxnameerr] = useState(null)
  const [abillingerr, setabillingerr] = useState(null)
  const [sbillingerr, setsbillingerr] = useState(null)
  // const [cbillingerr, setcbillingerr] = useState(null)

  const [banknoerr, setbanknoerr] = useState(null)
  const [banknameerr, setbanknameerr] = useState(null)
  const [numbererr, setnumbererr] = useState(null)
  const [swifferr, setswifferr] = useState(null)
  const [infoerr, setinfoerr] = useState(null)

  const [licencenoerr, setlicencenoerr] = useState(null)
  const [priceerr, setpriceerr] = useState(null)
  const [totalpriceerr, settotalpriceerr] = useState(null)
  const [discounterr, setdiscounterr] = useState(null)
  const [ondateerr, setondateerr] = useState(null)
  const [expiredateerr, setexpiredateerr] = useState(null)
  const [redateerr, setredateerr] = useState(null)
  const [informationerr, setinformationerr] = useState(null)
  const [logo, setlogo] = useState(null)
  const [thumb, setthumb] = useState(null)
  const [logoerr, setlogoerr] = useState(null)
  const [paymenterr, setpaymenterr] = useState(null)
  const [item, setItem] = useState(10)
  const [totalcount, setTotalcount] = useState()
  const [page, setPage] = useState(1);
  const [count, setCount] = useState()
  const [statussss, setstatusss] = useState()
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [valiaddre, setValiaddre] = useState(null)
  const [valiaddre1, setValiaddre1] = useState(null)



  const [active, setActive] = useState(false)

  const handleChange1 = (event, value) => {
    setPage(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    // if (event.target.value === 'freelancer') {
    setDefaultPrice()
    // }
  };

  const [isActive, setIsActive] = useState(true);
  const redirectPath = '/'
  const timeoutSeconds = 5 * 60 * 1000
  const navigate = useNavigate()
  useEffect(() => {
    screenInactive()
  }, [timeoutSeconds, isActive, navigate, redirectPath]);

  const screenInactive = () => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      if (isActive) {
        timeoutId = setTimeout(() => {
          logout()
          window.localStorage.removeItem('electrónico')
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`)
        }, timeoutSeconds);
      }
    };

    const handleMouseActivity = () => {
      setIsActive(true);
      resetTimeout();
    };


    resetTimeout();


    window.addEventListener('mousemove', handleMouseActivity);
    window.addEventListener('keydown', handleMouseActivity);


    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleMouseActivity);
      window.removeEventListener('keydown', handleMouseActivity);
    };
  }

  const createLogo = async (e) => {
    try {
      let files = await e.target.files[0]
      if (!files.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        toast.error('Please Select Only Image')
      } else {
        let formData = await new FormData();
        formData.append("image", files)

        const { data } = await Axios.post('/users/uploadlogo', formData)

        if (data?.result) {
          setlogo(data?.result)
          setlogoerr(null)
          // navigate(`${consts.route}/register-success`)
          toast.success('Image uploaded Successfully')
        }
      }

    } catch (error) {
      console.log(error, "err")
    }
  }

  const totalLicencePrice = (discount, tax) => {
    if (licenceno?.current?.value && price) {
      var total = licenceno?.current?.value * price
      var subtotal
      if (Number(discount) > 0) {
        var subtot = (Number(discount) / 100) * Number(total)
        subtotal = total - subtot
        setTotalPrice(subtotal)
      }
      if (Number(subtotal) > 0 && Number(tax) > 0) {
        var taxs = (Number(tax) / 100) * Number(subtotal)
        setTotalPrice(Number(subtotal) + Number(taxs))
      }
      if (discount === undefined && tax === undefined) {
        setTotalPrice(Math.floor(total))
      }
      if (discount == 0 && tax == 0) {
        setTotalPrice(Number(price) * Number(licenceno?.current?.value))
      }
      if (discount == 0 && tax != 0) {
        var txes = (Number(price) * Number(licenceno?.current?.value))
        var taxper = (Number(tax) / 100) * Number(txes)
        var roundtax = Math.floor(txes + taxper)
        setTotalPrice(roundtax)
      }
    }
  }

  useEffect(() => {
    totalLicencePrice(discount?.current?.value, tax?.current?.value)
  }, [price])

  const setDefaultPrice = (val) => {
    if (value === 'freelancer') {
      setPrice(1290)
    } else {
      if (Number(val) > 0 && Number(val) <= 10) {
        setPrice(4990)
      } else if (Number(val) >= 11 && Number(val) <= 20) {
        setPrice(4990)
      } else if (Number(val) >= 21 && Number(val) <= 40) {
        setPrice(3990)
      } else if (Number(val) >= 41 && Number(val) <= 80) {
        setPrice(3490)
      } else if (Number(val) >= 81 && Number(val) <= 100) {
        setPrice(2990)
      } else if (Number(val) >= 101 && Number(val) <= 200) {
        setPrice(2555)
      } else if (Number(val) > 200) {
        setPrice(1999)
      }
      else {
        setPrice(0)
      }
    }

  }

  const handleSuccessRegister = async () => {
    try {
      var val
      if (information.current.value == "") {
        val = []
      } else {
        val = information.current.value.split(',')
      }
      console.log("🚀 ~ handleSuccessRegister ~ val:", val)
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let reg = /^[0-9]+$/;

      if (logo === null) {
        toast.error("Please Select Logo")
        setlogoerr("Please Select Logo")
      } else if (name.current.value === "") {
        toast.error("Please Enter Brand Name")
        setnameerr("Please Enter Brand Name")
      }
      else if (email.current.value === "") {
        toast.error("Please Enter Email")
        setemailerr("Please Enter Email")
      }
      else if (!(re.test(email.current.value))) {
        toast.error("Please Enter valid Email")
        setemailerr("Please Enter valid Email")
      } else if (web.current.value === "") {
        toast.error("Please Enter Web")
        setweberr("Please Enter Web")
      } else if (address1 === "") {
        toast.error("Please Enter Address")
        setaddresserr("Please Enter Address")
      }
      // else if (city.current.value === "") {
      //   toast.error("Please Enter City")
      //   setcityerr("Please Enter City")
      // }
      // else if (state.current.value === "") {
      //   toast.error("Please Enter State")
      //   setstateerr("Please Enter State")
      // }
      // else if (country.current.value === "") {
      //   toast.error("Please Enter Country")
      //   setcountryerr("Please Enter Country")
      // }
      else if (pincode.current.value === "") {
        toast.error("Please Enter pin Code")
        setpincodeerr("Please Enter pin Code")
      }
      else if (phone.current.value === "") {
        toast.error("Please Enter Phone Number")
        setphoneerr("Please Enter Phone Number")
      } else if (contactname.current.value === "") {
        toast.error("Please Enter Contact Name")
        setcontactnameerr("Please Enter Contact Name")
      }
      else if (contactmail.current.value === "") {
        toast.error("Please Enter Contact Email")
        setcontactemailerr("Please Enter Contact Email")
      } else if (!(re.test(contactmail.current.value))) {
        toast.error("Please Enter valid Email")
        setcontactemailerr("Please Enter valid Email")
      }
      else if (contactphone.current.value === "") {
        toast.error("Please Enter Contact Phone Number")
        setcontactphoneerr("Please Enter Contact Phone Number")
      }


      else if (taxid.current.value === "") {
        toast.error("Please Enter Tax Id")
        settaxiderr("Please Enter Tax Id")
      }
      //  else if (!(reg.test(taxid.current.value))) {
      //   settaxiderr("Please Valid number")
      // }
      else if (address2 === "") {
        toast.error("Please Enter Address Billing")
        setabillingerr("Please Enter Address Billing")
      }
      else if (taxname.current.value === "") {
        toast.error("Please Enter Tax Name")
        settaxnameerr("Please Enter Tax Name")
      }
      else if (statusbilling.current.value === "") {
        toast.error("Please Enter Post code")
        setsbillingerr("Please Enter Post code")
      }
      // else if (countrybilling.current.value === "") {
      //   toast.error("Please Enter Country Billing")
      //   setcbillingerr("Please Enter Country Billing")
      // }


      else if (bankno.current.value === "") {
        toast.error("Please Enter Bank Number")
        setbanknoerr("Please Enter Bank Number")
      }
      // else if (!(reg.test(bankno.current.value))) {
      //   setbanknoerr("Please Valid number")
      // }
      else if (bankname.current.value === "") {
        toast.error("Please Enter Bank Name")
        setbanknameerr("Please Enter Bank Name")
      }
      else if (number.current.value === "") {
        toast.error("Please Enter Route Number")
        setnumbererr("Please Enter Route Number")
      }
      else if (!(reg.test(number.current.value))) {
        toast.error("Please Valid number")
        setnumbererr("Please Valid number")
      }
      else if (swiff.current.value === "") {
        toast.error("Please Enter Swiff")
        setswifferr("Please Enter Swiff")
      }
      else if (info.current.value === "") {
        toast.error("Please Enter info")
        setinfoerr("Please Enter info")
      }
      else if (licenceno.current.value === "") {
        toast.error("Please Enter No of Licence")
        setlicencenoerr("Please Enter No of Licence")
      } else if (!(reg.test(licenceno.current.value))) {
        toast.error("Please Valid number")
        setlicencenoerr("Please Valid number")
      }

      else if (price === "") {
        toast.error("Please Enter Price")
        setpriceerr("Please Enter Price")
      }
      else if (!(reg.test(price))) {
        toast.error("Please Valid number")
        setpriceerr("Please Valid number")
      }
      else if (discount.current.value === "") {
        toast.error("Please Enter Discount")
        setdiscounterr("Please Enter Discount")
      }
      else if (!(reg.test(discount.current.value))) {
        toast.error("Please Valid number")
        setdiscounterr("Please Valid number")
      }

      else if (tax.current.value === "") {
        toast.error("Please Enter Tax")
        settaxerr("Please Enter Tax")
      } else if (!(reg.test(tax.current.value))) {
        toast.error("Please Valid number")
        settaxerr("Please Valid number")
      }
      // else if (totalprice === null) {
      //   toast.error("Please Enter Price")
      //   settotalpriceerr("Please Enter Price")
      // }
      // else if (!(reg.test(totalprice))) {
      //   toast.error("Please Valid number")
      //   settotalpriceerr("Please Valid number")
      // }
      else if (ondate.current.value === "") {
        toast.error("Please Enter ondate")
        setondateerr("Please Enter ondate")
      }
      else if (expiredate.current.value === "") {
        toast.error("Please Enter Expire Date")
        setexpiredateerr("Please Enter Expire Date")
      }
      else if (redate.current.value === "") {
        toast.error("Please Enter Remember Date")
        setredateerr("Please Enter Remember Date")
      }
      else if (licenceno.current.value < 1) {
        toast.error("Please Enter Info")
        setinformationerr("Please Enter Info")
      }

      else if (age === '') {
        toast.error('Please Select Payment Method')
        setpaymenterr('Please Select Payment Method')
      }
      else if (Number(val.length) + 1 !== Number(licenceno.current.value)) {
        console.log("🚀 ~ handleSuccessRegister ~ Number(val.length):", Number(val.length))
        toast.error('Please Enter Correct Number of Emails')
        setinformationerr('Please Enter Correct Number of Emails')
      }

      else if (value === 'freelancer' && licenceno.current.value <= 2) {
        toast.error("Freelancer Have Max 2 Licence")
        setlicencenoerr("Freelancer Have Max 2 Licence")
      }
      else {
        let newad = address1.split(",")
        let city = newad.length - 1
        setActive(true)
        const { data } = await Axios.post('/admin/create_brand',
          {
            name: name.current.value,
            email: email.current.value,
            web: web.current.value,
            address: address1,
            city: "",
            state: "",
            country: newad[city],
            pin_code: pincode.current.value,
            phone: phone.current.value,
            contact_mail: contactmail.current.value,
            contact_phone: contactphone.current.value,
            contact_name: contactname.current.value,
            logo: logo,
            tax: tax.current.value,
            tax_id: taxid.current.value,
            tax_name: taxname.current.value,
            address_billing: address2,
            status_billing: statusbilling.current.value,
            country_billing: "",
            bankno: bankno.current.value,
            bankname: bankname.current.value,
            number: number.current.value,
            swiff: swiff.current.value,
            info: info.current.value,
            type: value,
            licenceno: licenceno.current.value,
            price: price,
            total_price: totalprice.toFixed(0),
            discount: discount.current.value,
            ondate: ondate.current.value,
            expiredate: expiredate.current.value,
            redate: redate.current.value,
            information: information.current.value,
            payment_method: age
          },
          {
            headers: {
              Authorization: window.localStorage.getItem("AdminToken"),
            },
          }
        )

        if (data.success) {
          // navigate(`${consts.route}/register-success`)
          toast.success('Brand Created Sucessfully')
          setTimeout(() => {
            window.location.reload()
            setClick(false)
          }, 2000);
        }
        else {
          setActive(false)
          toast.error(data.message)
        }
        setActive(false)
      }
    } catch (error) {
      setActive(false)
      console.log(error, "err")
      if (error?.response?.data?.message === "Email Already Exists") {
        setemailerr(error?.response?.data?.message)
      } else {
        toast.error(error?.response?.data?.message)
      }
    }
  }
  const filterData = (data) => {
    if (data.length > 0) {
      setUsers(data)
      setTotalcount(Math.ceil(data?.length / item))
    }
    else {
      setUsers([])
      setstatusss("No Data Available")
    }

  }
  const reset = (data) => {
    if (data === true) {
      getSellers()
    }
  }

  useEffect(() => {
    getSellers();
  }, [page])
  const getSellers = async () => {
    try {
      setstatusss('Loading....')

      const { data } = await Axios.post('/admin/get_filtered_brand', { page: page, item: item },
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      )

      if (data?.success) {
        if (data?.result?.length > 0) {
          setUsers(data?.result)
          setCount(Math.ceil(data?.result?.length / 10))
          setTotalcount(Math.ceil(data?.count / item))
          setstatusss()
        } else {
          setstatusss('No Brands Available')
        }

      } else {
        setstatusss('No Brands Available')
      }

    } catch (error) {
      console.log(error, "err")
      setstatusss('No Brands Available')

    }

  }
  useEffect(() => {
    getProductsa()
  }, [page, users1])

  const getProductsa = async () => {
    if (users1.length > 0) {
      var currentpage = page - 1
      var dt = []
      if (currentpage === 0) {
        for (let i = 0; i < 10; i++) {
          const element = users1[i];
          if (element) {
            dt.push(element)
          }
        }
        setUsers(dt)
      } else {
        var first = (currentpage * 10);
        var second = first + 10;
        for (let i = first; i <= second; i++) {
          const element = users1[i];
          if (element) {
            dt.push(element)
          }
        }
        setUsers(dt)
      }
    }

  }

  // const handleOpen123 = async (row) => {
  //   return (
  //     <AddressPopup row={row} />
  //   )
  // }

  const [age, setAge] = React.useState('');

  const handlePaymentChange = (event) => {
    setpaymenterr(null)
    setAge(event.target.value);
  };

  const handleEmailchange = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.current.value === "") {
      setemailerr("Please Enter Email")
    } else if (!(re.test(email.current.value))) {
      setemailerr("Please Enter valid Email")
    }
    else {
      setemailerr()
    }
  }

  // const handlePhonechange = () => {

  //   let reg = /^[0-9]+$/;
  //   if (phone.current.value === "") {
  //     setphoneerr("Please Enter Phone Number")
  //   } else if (!(reg.test(phone.current.value))) {
  //     setphoneerr("Please Valid Phone Number")
  //   }
  //   else {
  //     setphoneerr()
  //   }
  // }

  // const handlecEmailchange = () => {
  //   let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (contactmail.current.value === "") {
  //     setcontactemailerr("Please Enter Email")
  //   } else if (!(re.test(contactmail.current.value))) {
  //     setcontactemailerr("Please Enter valid Email")
  //   }
  //   else {
  //     setcontactemailerr()
  //   }
  // }

  // const handleStaffchange = () => {
  //   let reg = /^[0-9]+$/;
  //   if (staffno.current.value === "") {
  //     setstaffnoerr("Please Enter No of Staff")
  //   } else if (!(reg.test(staffno.current.value))) {
  //     setstaffnoerr("Please Enter valid Number")
  //   }
  //   else{
  //     setstaffnoerr()
  //   }
  // }

  // const handlecPhonechange = () => {

  //   let reg = /^[0-9]+$/;
  //   if (contactphone.current.value === "") {
  //     setcontactphoneerr("Please Enter Phone Number")
  //   }else if (!(reg.test(contactphone.current.value))) {
  //     setcontactphoneerr("Please Valid Phone Number")
  //   }
  //   else{
  //     setcontactphoneerr()
  //   }
  // }

  const downloadExcelsheet = () => {
    let tableData = []

    for (var i = 0; i < users.length; i++) {
      var infos = {
        Name: users[i].user_info.name,
        Address: users[i].user_info.address,
        // City: users[i].user_info.city,
        Contact_mail: users[i].user_info.contact_mail,
        Contact_name: users[i].user_info.contact_name,
        Contact_phone: users[i].user_info.contact_phone,
        Country: users[i].user_info.country,
        Email: users[i].user_info.email,
        // Email_verified: users[i].user_info.email_verified,
        // Location: users[i].user_info.location,
        Logo: users[i].user_info.logo,
        Phone: users[i].user_info.phone,
        Pin_code: users[i].user_info.pin_code,
        Role: users[i].user_info.role,
        // State: users[i].user_info.state,
        Thumb_nail: users[i].user_info.thumb_nail,
        Web: users[i].user_info.web,


        Address_billing: users[i].bill_info.address_billing,
        // Country_billing: users[i].bill_info.country_billing,
        Status_billing: users[i].bill_info.status_billing,
        Tax_id: users[i].bill_info.tax_id,
        Tax_name: users[i].bill_info.tax_name,


        Bank_Name: users[i].financial_info.bankname,
        Bank_No: users[i].financial_info.bankno,
        info: users[i].financial_info.info,
        Number: users[i].financial_info.number,
        Swift: users[i].financial_info.swiff,


        Discount: users[i].licence_info.discount,
        Expire_Date: users[i].licence_info.expiredate,
        Information: users[i].licence_info.information,
        Licenceno: users[i].licence_info.licenceno,
        Ondate: users[i].licence_info.ondate,
        Price: users[i].licence_info.price,
        Remember_Date: users[i].licence_info.redate,
        Total_price: users[i].licence_info.total_price,
        Type: users[i].licence_info.type,

        Is_active: users[i].is_active,
        Is_expire: users[i].is_expire,
        Payment_method: users[i].payment_method,
        Payment_status: users[i].payment_status,
        Reason: users[i].reason,
      }
      tableData.push(infos)
    }

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Brand.csv');

  };
  const [curr, setCurr] = useState()
  const currentDay = () => {
    const date = new Date();

    let day = date.getDate();
    if (day.toString().length === 1) {
      day = `0${day}`
    }
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`;
    setCurr(currentDate)
  }

  useEffect(() => {
    currentDay()
  }, [])


  const handleChangeAddress = (newAddress) => {
    setValiaddre("")
    setaddresserr("")
    pincode.current.value = ""
    setAddress1(newAddress);
  };
  const handleSelectAddress = (newAddress) => {
    setValiaddre("")
    pincode.current.value = ""
    // let ad = newAddress.split(",")
    // console.log(ad.length, "address-vijay");
    // const cou = ad.length - 1
    // console.log();
    // console.log(cou, "vjlenghth");
    // console.log(ad[cou], "country");
    setAddress1(newAddress);
    // city.current.value = ad[1]
    // state.current.value = ad[2]
    // country.current.value = ad[cou]
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  const nft = async (e) => {
    try {
      setValiaddre("")
      setpincodeerr(null)
      if (pincode.current.value != undefined) {
        const add = await Axios.post('/validateaddress', {
          address: address1

        })
        if (add?.data?.success === true) {
          setValiaddre("Address Verified")
          let fromadd = add?.data?.result?.address.split(",")
          let postad = fromadd[2]
          let postadd = postad.split(" ")
          // if (fromadd[1] != undefined) {
          //   city.current.value = fromadd[1]
          // }
          // if (fromadd[3] != undefined) {
          //   country.current.value = fromadd[3]
          // }
          // if (postadd[1] != undefined) {
          //   state.current.value = postadd[1]
          // }
          // if (postadd[2] != undefined) {
          //   postalcode.current.value = postadd[2]
          // }
        } else {
          console.log("failed");
          setValiaddre("Address Not Verified")
        }
      }


    } catch (error) {
      console.log(error, "error");
    }
  }

  const handleChangeAddress1 = (newAddress) => {
    setValiaddre1("")
    setabillingerr("")
    statusbilling.current.value = ""
    setAddress2(newAddress);
  };
  const handleSelectAddress1 = (newAddress) => {
    setValiaddre1("")
    statusbilling.current.value = ""
    // let ad = newAddress.split(",")
    // console.log(ad.length, "address-vijay");
    // const cou = ad.length - 1
    // console.log();
    // console.log(cou, "vjlenghth");
    // console.log(ad[cou], "country");
    setAddress2(newAddress);
    // city.current.value = ad[1]
    // state.current.value = ad[2]
    // country.current.value = ad[cou]
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  const nft1 = async (e) => {
    try {
      setValiaddre1("")
      setsbillingerr("")
      if (pincode.current.value != undefined) {
        const add = await Axios.post('/validateaddress', {
          address: address1

        })
        if (add?.data?.success === true) {
          setValiaddre1("Address Verified")
          let fromadd = add?.data?.result?.address.split(",")
          let postad = fromadd[2]
          let postadd = postad.split(" ")
          // if (fromadd[1] != undefined) {
          //   city.current.value = fromadd[1]
          // }
          // if (fromadd[3] != undefined) {
          //   country.current.value = fromadd[3]
          // }
          // if (postadd[1] != undefined) {
          //   state.current.value = postadd[1]
          // }
          // if (postadd[2] != undefined) {
          //   postalcode.current.value = postadd[2]
          // }
        } else {
          setValiaddre1("Address Not Verified")
        }
      }


    } catch (error) {
      console.log(error, "error");
    }
  }

  if ((role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[0]?.Read === true))) {
    return (
      <>

        {
          active === true &&
          <div className='loaders-load'>
            <img src={loader} />
          </div>
        }
        <div className={active === true ? 'Admin customer address list-full-cnt dashboard-page Admin-customer-page blurs' : 'Admin customer address list-full-cnt dashboard-page Admin-customer-page '}>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container className='admin-customer-contain'>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                <Item className={classes.headercls}>
                  <Header />
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.bodyclass}>
                  <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                  <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                    <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                    <AdminMenuSide />
                  </div>
                </Item>
              </Grid>


              <Box sx={{ flexGrow: 1 }} onClick={closeMenuHandler}>
                <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block">

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Item className={classes.headercls}>

                      <div className='listbody'>
                        <div className='filter-btn'>
                          <div className='filter-filed'>
                            <div className='filter-box'>
                              {/* <Button variant="contained">{<SearchIcon />}Filter</Button> */}
                              <CustomerFilter filterData={filterData} reset={reset} />
                              {/* filterData={filterData} reset={reset} */}
                              <div>
                                {(role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[0]?.Write === true)) ?
                                  <Button onClick={handleOpen} className='filterBtn'><img src={userProfile} alt={userProfile} />Add Brand</Button>
                                  :
                                  <Button className='filterBtn'><img src={userProfile} alt={userProfile} disabled />Add Brand</Button>
                                }

                                <Modal
                                  open={click}
                                  onClose={handleExit}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >

                                  <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate AdminAddUser txt-center admin-add">
                                    <div className="menu" onClick={handleExit} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                                    {/* <div className="brand"><img src={adidasLogo} alt={'adidasLogo'} /></div> */}

                                    <div className='uploade-files'>
                                      <div className='profile-image-outer'>
                                        <h4>Select Logo</h4>
                                        {logoerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{logoerr}</div> : <></>}
                                        <div className='profile-image profile-image-design-block'>
                                          <form>
                                            <input type="file" accept='image/*' className='input-field' hidden
                                              onChange={(e) => { createLogo(e) }}
                                            />
                                            {logo ?
                                              <img src={logo} alt={adidasLogo} /> :
                                              <div className='upload-image-block'><CloudUploadIcon /></div>
                                            }
                                          </form>
                                        </div>
                                        <div className='edit-image-btn' onClick={() => document.querySelector(".input-field").click()}>
                                          <Button variant="contained" endIcon={<BorderColorIcon />}>
                                            Select
                                          </Button>
                                        </div>
                                      </div>
                                    </div>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='addressList'>
                                        <p className="reason">ADDRESS</p>

                                        <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block">

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='BRAND NAME'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setnameerr(null)}
                                                  inputRef={name}

                                                />
                                                {nameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{nameerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='EMAIL'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => { handleEmailchange() }}
                                                  inputRef={email}
                                                />
                                                {emailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='WEB'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setweberr(null)}
                                                  inputRef={web}
                                                />
                                                {weberr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{weberr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>


                                          {/* <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='CITY'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setcityerr(null)}
                                                  inputRef={city}
                                                />
                                                {cityerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{cityerr}</div> : <></>}
                                              </div>

                                            </Item>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='STATE'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setstateerr(null)}
                                                  inputRef={state}
                                                />
                                                {stateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{stateerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='COUNTRY'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setcountryerr(null)}
                                                  inputRef={country}
                                                />
                                                {countryerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{countryerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid> */}



                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='PHONE'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setphoneerr(null)}

                                                  inputRef={phone}
                                                />
                                                {phoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{phoneerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='CONTACT PERSON NAME'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setcontactnameerr(null)}
                                                  inputRef={contactname}
                                                />
                                                {contactnameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactnameerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='CONTACT PERSON EMAIL'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setcontactemailerr(null)}
                                                  inputRef={contactmail}
                                                />
                                                {contactemailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactemailerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='CONTACT PERSON PHONE'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={() => setcontactphoneerr(null)}
                                                  inputRef={contactphone}
                                                />
                                                {contactphoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactphoneerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <PlacesAutocomplete
                                                  value={address1}
                                                  onChange={handleChangeAddress}
                                                  onSelect={handleSelectAddress}
                                                >
                                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                      <TextField
                                                        {...getInputProps({
                                                          placeholder: "Address",
                                                          className: "location-search-input brand www wwww-res"
                                                        })}
                                                      />
                                                      <div className="autocomplete-dropdown-container brand">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                          const className = suggestion.active
                                                            ? "suggestion-item--active"
                                                            : "suggestion-item";
                                                          // inline style for demonstration purpose
                                                          const style = suggestion.active
                                                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                          return (
                                                            <div
                                                              key={suggestion.placeId}
                                                              {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style
                                                              })}
                                                            >
                                                              <span>{suggestion.description}</span>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    </div>
                                                  )}
                                                </PlacesAutocomplete>
                                                {/* <TextField
                                                  id="outlined-basic"
                                                  placeholder='ADDRESS'
                                                  variant="outlined"
                                                  className="textField"
                                                  type="address"
                                                  onChange={() => setaddresserr(null)}
                                                  inputRef={address}
                                                /> */}
                                                {addresserr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{addresserr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                            <Item className={classes.headercls}>
                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  placeholder='POST CODE'
                                                  variant="outlined"
                                                  className="textField"
                                                  onChange={(e) => nft(e)}
                                                  inputRef={pincode}
                                                />
                                                {pincodeerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{pincodeerr}</div> : <></>}
                                              </div>
                                            </Item>
                                          </Grid>


                                        </Grid>
                                        <div className="error-msg">
                                          <>{valiaddre === "Address Verified" ? <></> : <p>{valiaddre}</p>}</>
                                        </div>

                                        <div className="sucess-msg">
                                          <>{valiaddre === "Address Not Verified" ? <></> : <p>{valiaddre}</p>}</>
                                        </div>
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listSec'>
                                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                        <div className='info'>
                                          <div className='Bill'>
                                            <p className="reason">Billing Info</p>

                                            <div className='billingInfo'>

                                              <div className="productId">

                                                <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-billing">

                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Tax identification number'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => settaxiderr(null)}
                                                          inputRef={taxid}
                                                        />
                                                        {taxiderr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{taxiderr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>


                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Tax Name'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => settaxnameerr(null)}
                                                          inputRef={taxname}
                                                        />
                                                        {taxnameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{taxnameerr}</div> : <></>}
                                                      </div>
                                                    </Item>


                                                  </Grid>


                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">

                                                        <PlacesAutocomplete
                                                          value={address2}
                                                          onChange={handleChangeAddress1}
                                                          onSelect={handleSelectAddress1}
                                                        >
                                                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                              <input
                                                                {...getInputProps({
                                                                  placeholder: "Billing Address",
                                                                  className: "location-search-input www wwww-res"
                                                                })}
                                                              />
                                                              <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map((suggestion) => {
                                                                  const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                  // inline style for demonstration purpose
                                                                  const style = suggestion.active
                                                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                                  return (
                                                                    <div
                                                                      key={suggestion.placeId}
                                                                      {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style
                                                                      })}
                                                                    >
                                                                      <span>{suggestion.description}</span>
                                                                    </div>
                                                                  );
                                                                })}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </PlacesAutocomplete>




                                                        {/* <TextField
                                                          id="outlined-basic"
                                                          placeholder='Address Billing'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setabillingerr(null)}
                                                          inputRef={addressbilling}
                                                        /> */}
                                                        {abillingerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{abillingerr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>



                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Post Code'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={(e) => nft1(e)}
                                                          inputRef={statusbilling}
                                                        />
                                                        {sbillingerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{sbillingerr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>

                                                  {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Country Billing'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setcbillingerr(null)}
                                                          inputRef={countrybilling}
                                                        />
                                                        {cbillingerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{cbillingerr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid> */}

                                                </Grid>

                                                <div className="error-msg">
                                                  <>{valiaddre1 === "Address Verified" ? <></> : <p>{valiaddre1}</p>}</>
                                                </div>

                                                <div className="sucess-msg">
                                                  <>{valiaddre1 === "Address Not Verified" ? <></> : <p>{valiaddre1}</p>}</>
                                                </div>


                                              </div>
                                            </div>
                                          </div>

                                        </div>

                                      </Grid>

                                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                        <div className='info'>
                                          <div className='Bill'>
                                            <p className="reason">Financial info</p>

                                            <div className='billingInfo'>

                                              <div className="productId">

                                                <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-finance-info">

                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Bank Account Number'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setbanknoerr(null)}
                                                          inputRef={bankno}
                                                        />
                                                        {banknoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{banknoerr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>

                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Bank Name'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setbanknameerr(null)}
                                                          inputRef={bankname}
                                                        />
                                                        {banknameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{banknameerr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>

                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Routing Number'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setnumbererr(null)}
                                                          inputRef={number}
                                                        />
                                                        {numbererr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{numbererr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>

                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Swift'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setswifferr(null)}
                                                          inputRef={swiff}
                                                        />
                                                        {swifferr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{swifferr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>

                                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                    <Item className={classes.headercls}>
                                                      <div className="cmn-input-cls">
                                                        <TextField
                                                          id="outlined-basic"
                                                          placeholder='Other Information'
                                                          variant="outlined"
                                                          className="textField"
                                                          onChange={() => setinfoerr(null)}
                                                          inputRef={info}
                                                        />
                                                        {infoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{infoerr}</div> : <></>}
                                                      </div>
                                                    </Item>
                                                  </Grid>

                                                </Grid>


                                              </div>
                                            </div>

                                          </div>

                                        </div>

                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTri'>
                                      <div>
                                        <p className="reason">Licensing Information</p>
                                      </div>
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTriRow'>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                          <div className='rowOne'>

                                            <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      No OF Licences
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        className="textField"
                                                        onChange={(e) => {
                                                          setlicencenoerr(null);
                                                          totalLicencePrice(discount?.current?.value, tax?.current?.value);
                                                          setDefaultPrice(e.target.value)
                                                        }}
                                                        inputRef={licenceno}
                                                      />
                                                      {licencenoerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{licencenoerr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      price per licence
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"

                                                        variant="outlined"
                                                        className="textField"
                                                        onChange={(e) => { setpriceerr(null); setPrice(e.target.value) }}
                                                        value={price}
                                                      />
                                                      {priceerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{priceerr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      Discount applied
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"

                                                        variant="outlined"
                                                        className="textField"
                                                        onChange={(e) => { setdiscounterr(null); totalLicencePrice(e.target.value, tax.current.value) }}
                                                        inputRef={discount}
                                                      />
                                                      {discounterr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{discounterr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      Tax
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"

                                                        variant="outlined"
                                                        className="textField"
                                                        onChange={(e) => { settaxerr(null); totalLicencePrice(discount?.current?.value, e.target.value) }}
                                                        inputRef={tax}
                                                      />
                                                      {taxerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{taxerr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      price total licenses
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"
                                                        disabled
                                                        variant="outlined"
                                                        className="textField"
                                                        value={totalprice}
                                                      // onChange={() => settotalpriceerr(null)}
                                                      // inputRef={totalprice}
                                                      />
                                                      {/* {totalpriceerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{totalpriceerr}</div> : <></>} */}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>



                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"

                                                    >
                                                      activation date
                                                    </Typography>
                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        className="textField"
                                                        onChange={() => setondateerr(null)}
                                                        type="date"
                                                        inputProps={{
                                                          min: curr
                                                        }}
                                                        inputRef={ondate}
                                                      />
                                                      {ondateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{ondateerr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      expiration date
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"

                                                        variant="outlined"
                                                        className="textField"
                                                        type="date"
                                                        inputProps={{
                                                          min: curr
                                                        }}
                                                        onChange={() => setexpiredateerr(null)}
                                                        inputRef={expiredate}
                                                      />
                                                      {expiredateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{expiredateerr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      remember date
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <TextField
                                                        id="outlined-basic"
                                                        type="date"
                                                        variant="outlined"
                                                        className="textField"
                                                        inputProps={{
                                                          min: curr
                                                        }}
                                                        onChange={() => setredateerr(null)}
                                                        inputRef={redate}
                                                      />
                                                      {redateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{redateerr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>


                                              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                                <Item className={classes.headercls}>
                                                  <div className="productId">
                                                    <Typography
                                                      id="modal-modal-title"
                                                      variant="h6"
                                                      component="h2"
                                                      className="modelTypo"
                                                    >
                                                      Payment Type
                                                    </Typography>

                                                    <div className="cmn-input-cls">
                                                      <Box sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                          {/* <InputLabel id="demo-simple-select-label">Payment Type</InputLabel> */}
                                                          <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            className='location-search-input www wwww-res'
                                                            value={age}
                                                            // label="Payment Type"
                                                            onChange={handlePaymentChange}
                                                          >
                                                            <MenuItem value={'Bank'}>Bank</MenuItem>
                                                            <MenuItem value={'Stripe'}>Stripe</MenuItem>
                                                          </Select>
                                                        </FormControl>
                                                      </Box>
                                                      {paymenterr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{paymenterr}</div> : <></>}
                                                    </div>
                                                  </div>
                                                </Item>
                                              </Grid>

                                            </Grid>

                                          </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                          <div className='rightSide'>
                                            <div className='choice'>
                                              <div className="warn">
                                                <FormControl>
                                                  <FormLabel id="demo-controlled-radio-buttons-group">Select Type</FormLabel>
                                                  <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={handleChange}
                                                  >
                                                    <FormControlLabel value="business" control={<Radio inputRef={type} />} label="BUSINESS" />
                                                    <FormControlLabel value="freelancer" control={<Radio inputRef={type1} />} label="FREELANCERS" />
                                                  </RadioGroup>
                                                </FormControl>
                                                {/* <div className="statusList"><Checkbox className="checkBox"/>BUSINESS</div>
                <div className="statusList"><Checkbox className="checkBox"/>FREELANCERS</div> */}
                                              </div>
                                            </div>

                                            <div className='mailActive'>
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                Brand user's Email seperate using (,)
                                              </Typography>

                                              <div className='input-box'>
                                                <TextField className='field-box'
                                                  required
                                                  multiline
                                                  rows={5}
                                                  rowsMax={10}
                                                  variant="outlined"
                                                  onChange={() => setinformationerr(null)}
                                                  inputRef={information}
                                                />
                                                {informationerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{informationerr}</div> : <></>}
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>


                                    <div className="butOutLayer">
                                      {/* <Button className='edit' onClick={handleOpenEditInfo}>edit info</Button> */}
                                      {
                                        active === true ?
                                          <Button className='create'>Creating....</Button>
                                          :
                                          <Button className='create' onClick={() => { handleSuccessRegister(); }}>Create brand</Button>

                                      }
                                    </div>
                                  </Box>
                                </Modal>
                              </div>
                            </div>

                            <div>
                              <Button variant="contained" onClick={() => { downloadExcelsheet() }} >
                                Export<img src={Files} alt={Files} />
                              </Button>
                            </div>
                          </div>

                        </div>

                        <div className='tablebody admin-customer-table-main'>
                          <div className='dashboargraph-inner-top'>
                            <Item className={classes.dashboargraph} id="earnings-sales">
                              <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableScroll' >
                                  <TableHead>
                                    <TableRow className='row-head'>
                                      <TableCell className='head-bg'>Brand Name</TableCell>
                                      <TableCell className='head-bg'>Brand Logo</TableCell>
                                      <TableCell className='head-bg'>Role</TableCell>
                                      <TableCell className='head-bg'>Email</TableCell>
                                      <TableCell className='head-bg'>Status</TableCell>
                                      <TableCell className='head-bg'>More info</TableCell>
                                      <TableCell className='head-bg'>Licence Auto Renewal</TableCell>
                                      <TableCell className='head-bg'>Licence Activation Date</TableCell>
                                      <TableCell className='head-bg'>Licence Expiration Date</TableCell>
                                      <TableCell className='head-bg'>Licence Remember Date</TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {users.length > 0 ? users.map((row) => (
                                      <TableRow key={row?._id}>
                                        <TableCell component="th" scope="row" className='body-bg'>
                                          {row?.user_info.name}
                                        </TableCell>
                                        <TableCell className='body-bg'>
                                          <div className='thumbalin'><img src={row?.user_info?.logo} alt={"brandLogo"} /></div>
                                        </TableCell>
                                        <TableCell className='body-bg'>{row?.user_info?.role}</TableCell>
                                        <TableCell className='body-bg'>{row?.user_info?.email}</TableCell>
                                        <TableCell className='body-bg'>
                                          {row?.is_active ? <Button className="activate"><img src={rightState} alt={rightState} /></Button> : <Button className="activate"><img src={cross} alt={cross} /></Button>}
                                        </TableCell>
                                        <TableCell className='body-bg'>
                                          <div className='icon-space'>
                                            {/* <Button onClick={() => { handleOpen123(row) }} className="activate">
                                      <img src={info1} alt={info1} />
                                    </Button> */}
                                            <AddressPopup row={row} role={role} decrptedData={decrptedData} />
                                            {
                                              (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[0]?.Write === true)) ?
                                                <>
                                                  {row?.is_active ? <Deactivte row={row} /> : <Reactivate row={row} />}</>
                                                :
                                                <></>
                                            }

                                            <div onClick={() => { navigate(`/products/${row?._id}`) }} ><img src={box} alt={box} /></div>


                                            {role != 'subAdmin' && row?.walletAddress ?
                                              // <div onClick={() => { }} style={{ marginLeft: '7px' }}><img src={walletimg} alt='wallet' width='30px' /></div>
                                              <WalletPopUp row={row} />
                                              :
                                              <></>}

                                          </div>

                                        </TableCell>
                                        <TableCell className='body-bg'>
                                          {row?.selfRenewal === true ? "ON" : "OFF"}
                                        </TableCell>
                                        <TableCell className='body-bg'>
                                          {row?.licence_info?.ondate}
                                        </TableCell>
                                        <TableCell className='body-bg'>
                                          {row?.is_expire === "2" || row?.is_expire === "3" ? <Renewal row={row} /> : <>{row?.licence_info?.expiredate}</>}
                                        </TableCell>
                                        <TableCell className='body-bg'>
                                          {row?.licence_info?.redate}
                                        </TableCell>
                                      </TableRow>

                                    ))
                                      :
                                      <TableCell colspan={8} className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Item>
                          </div>

                          <div className='pagination-numb'>
                            {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                            <Pagination count={totalcount} page={page} onChange={handleChange1} color="primary" shape="rounded" />

                          </div>

                          <Modal
                            open={openReActive}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >

                            <Box sx={styleReActive} className="AdminCustomerpopBOX Deactivate Reactivate Deactivate-Reactivate">
                              <div className="menu" onClick={handleCloseReActive} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                              <div className="brand"><img src={adidasLogo} alt={'adidasLogo'} /></div>

                              <div className="h2Variant">Reactivate user</div>

                              <p className="reason">Reason</p>


                              <div className="warn">
                                <div className="statusList"><Checkbox className="checkBox" />license renewal</div>
                                <div className="statusList"><Checkbox className="checkBox" />others</div>
                              </div>

                              <div className='input-box'>
                                <TextField className='field-box'
                                  required
                                  multiline
                                  rows={5}
                                  rowsMax={10}
                                  variant="outlined"
                                />
                              </div>
                              <div className="butOutLayer">
                                <Button className='reactive'>Deactivate Brand And All Its Products</Button>
                              </div>
                            </Box>
                          </Modal>

                        </div>

                      </div>

                    </Item>
                  </Grid>

                </Grid>
              </Box>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.footercls}>
                  <Footer />
                </Item>
              </Grid>

            </Grid>
          </Box>
        </div>
      </>

    )
  } else {
    return (
      <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container className='admin-customer-contain'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.bodyclass}>
                <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                  <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                  <AdminMenuSide />
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='premission-access'>You Don't Have Permission to View Brands</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }


}

export default AdminCustomer;
