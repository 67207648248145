import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import YearlyChart from './YearlyChart';
import * as XLSX from "xlsx";
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import locationMark from '../../../img/locationMark.png';
import Files from '../../../img/files.svg';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterPopup from './FilterPopup';
import PersonIcon from '@mui/icons-material/Person';
import TextField from '@mui/material/TextField';
import YearlyChartCount from './YearlyChartCount';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import closeMenu from "../../../img/closemenu.png";
import { Link } from 'react-router-dom'
import Pagination from '@mui/material/Pagination';
import DownloadIcon from '@mui/icons-material/Download';
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import consts from '../../../constants'
// import Web3 from 'web3';
import axios from 'axios';
// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 20,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));

// function salesEarning(date, count, earniings, tax) {
//   return { date, count, earniings, tax };
// }

// function recentEarning(country, recentearnings) {
//   return { country, recentearnings };
// }

// const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
// const todayStartOfTheDay = today.startOf('day');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '15px 30px',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  polygongas: {
    maxWidth: '70% !important',
    margin: '0 auto !important',
    '@media (max-width: 991.98px)': {
      maxWidth: '100% !important',
    },
  }
});



const AdminBlockchainBody = () => {
  // const web3 = new Web3('https://endpoints.omniatech.io/v1/matic/mainnet/public	')

  const navigate = useNavigate()
  const [creationDate, setCreationDate] = useState([])
  const [paymentDate, setPaymentDate] = useState([])
  const [rows, setRows] = useState()
  const [creation, setCreation] = useState([])
  const [payment, setPayment] = useState([])
  const [mapDetails, setMapDetails] = useState()
  const [gasTracker, setGasTracker] = useState()

  const [isfilter, setIsfilter] = useState(false)
  const [name, setName] = useState()

  const handlecreationdatechange = (d, m, y) => {

    var credate = y + '-' + m + '-' + d

    setCreationDate(credate)

    if ((paymentDate !== undefined) && (paymentDate.length !== 0)) {
      setPage(1)

      filterbyDate(credate)
    }

  }

  const states = {
    center: [51.505, -0.091],
    zoom: 3,
  };

  const customIcon = new Icon({
    iconUrl: locationMark,
    iconSize: [23, 30]
  })

  const handlepaymentdatechange = (d, m, y) => {

    var payDate = y + '-' + m + '-' + d

    setPaymentDate(payDate)
    if ((creationDate !== undefined) && (creationDate.length !== 0)) {
      setPage(1)

      filterbyDate1(payDate)
    }

  }
  const [counts, setCounts] = useState()
  const [page, setPage] = useState(1)
  const [from, setFrom] = useState()
  const handleChange1 = (event, value) => {
    setPage(value);
  };

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  useEffect(() => {
    profile()
  }, [])

  const profile = async () => {
    try {
      if (role === 'brand') {
        const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
          headers: {
            Authorization: user
          }
        })
        if (data.result.is_active === false) {
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`, { state: { data: true } })
        }

      }
    } catch (error) {
      console.log("🚀 ~ file: AdminBlockchainBody.js:255 ~ profile ~ error:", error)

    }

  }
  const [productList, setProductList] = useState()
  // useEffect(()=>{
  //   blockchainData()
  // },[page])

  const blockchainData = async () => {
    try {
      const { data } = await Axios.post('/getTranscations', {
        creation_date: creationDate,
        payment_date: paymentDate,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminBlockchainBody.js:205 ~ blockchainData ~ error:", error)

    }

  }
  const filterBlockChain = async () => {
    try {
      const { data } = await Axios.post('/getTranscations', {
        name: name,
        from: from,
        creation: creation,
        payment: payment,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminBlockchainBody.js:205 ~ blockchainData ~ error:", error)

    }

  }

  const filterbyDate = async (pay) => {

    try {
      setIsfilter(true)
      const { data } = await Axios.post('/getTranscations', {
        creation_date: pay,
        payment_date: paymentDate,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
      }
    } catch (error) {
      console.log(error)

    }
  }
  const filterbyDate1 = async (pay) => {

    try {
      setIsfilter(true)
      const { data } = await Axios.post('/getTranscations', {
        creation_date: creationDate,
        payment_date: pay,
        page: page - 1,
        limit: 10
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success === true) {
        setProductList(data?.result)
        setCounts(Math.ceil(data?.count / 10))
      }
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    if (isfilter === true) {
      if ((paymentDate !== undefined) && (paymentDate.length !== 0)) {
        filterbyDate(creationDate)
      }
      else if ((creationDate !== undefined) && (creationDate.length !== 0)) {

        filterbyDate1(paymentDate)
      } else {
        setDetails()
      }
    } else {
      blockchainData()
    }
  }, [page])

  useEffect(() => {
    blockchainData()
  }, [])

  const setDetails = () => {
    setIsfilter(true)
    filterBlockChain()
  }

  const Resetfilter = () => {
    setPage(1)
    setName();
    setFrom();
    setPaymentDate([])
    setCreationDate([])
    setCreation([]);
    setPayment([]);
    blockchainData()
  }

  const classes = useStyles();

  const [mapopen, mapsetOpen] = React.useState(false);
  const maphandleOpen = () => mapsetOpen(true);
  const maphandleClose = () => mapsetOpen(false);
  const [country, setCountry] = useState()


  const [productopen, productsetOpen] = React.useState(false);
  const producthandleOpen = () => productsetOpen(true);
  const producthandleClose = () => productsetOpen(false);

  const [transCount, setTransCount] = useState()

  const [transac, setTransac] = useState()


  const getTransactionCountss = async () => {
    try {
      const { data } = await Axios.post(`/admin/getBlockChainCount`, {}, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        var arr = []
        for (let i = 0; i < data?.result?.length; i++) {
          const element = data?.result[i];
          arr.push([element?._id, element?.count])
        }
        setTransCount(arr)
      } else {
        setTransCount([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:507 ~ getTransactionCountss ~ error:", error)
    }
  }

  const getTransactions = async () => {
    try {
      const { data } = await Axios.post('/admin/getBlockchain', {}, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        const dts = data?.result
        var arr = []
        for (let i = 0; i < dts.length; i++) {
          const element = dts[i];
          // if (element?.TransactionFee) {
          arr.push([element?._id, element?.totalTransactionFee])
          // }
        }
        setTransac(arr)
      } else {
        setTransac([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:477 ~ getTransactions ~ error:", error)

    }
  }


  useEffect(() => {
    getTransactionCountss()
    getTransactions()
  }, [])

  const [userdetails, setUserDetails] = useState()
  const [profileopen, profilesetOpen] = React.useState(false);
  const profilehandleOpen = () => profilesetOpen(true);
  const profilehandleClose = () => profilesetOpen(false);

  const downloadExcelsheet = (row) => {
    let tableData = []

    var datas = {
      "From Address": row?.From,
      "To Address": row?.To,
      "Dtracer Fee": row?.TransactionFee,
      "Type": row?.Type,
      "Brand ID": row?.brands?.brandId,
      "Brand Name": row?.brands?.user_info?.name,
      "Brand Email": row?.brands?.user_info?.email,
      "Brand Phone": row?.brands?.user_info?.phone,
      "Status": "True"
    }
    tableData.push(datas)

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'BlockchainTransaction.csv');

  };

  const getGasPrice = async () => {
    try {
      const { data } = await axios.get(`https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=${consts?.polygon_api_key}`)
      if (data?.status == "1") {
        setGasTracker(data?.result)
      }
    } catch (error) {
      console.log("🚀 ~ getGasPrice ~ error:", error)
    }
  }
  useEffect(() => {
    // subscribe()
    // var interval
    getGasPrice()
    // clearInterval(window.localStorage.getItem("interval"))
    // interval = setInterval(() => {
    //   const fullUrl = window.location.href
    //   if (fullUrl.split('/')[3] == "blockchain") {
    //     getGasPrice()
    //   }
    // }, 5000);
    // window.localStorage.setItem("interval", interval)
    // return clearInterval(interval)
  }, [])

  return (
    <div className='dashboard-body adminblock'>
      <Box sx={{ flexGrow: 1 }}>


        <Grid container spacing={0} className={classes.containwidth}>


          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Item className={classes.headercls}>

              <div className='filter-btn adminblock'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className='filter-filed'>
                    <div className='filter-box'>
                      {/* <Button variant="contained">{<SearchIcon />}Filter</Button> */}
                      <FilterPopup Resetfilter={Resetfilter} setName={setName} setFrom={setFrom} setCreation={setCreation} setPayment={setPayment} setDetails={setDetails} />
                      <Button className='filter-color' onClick={() => { setIsfilter(false); Resetfilter() }}>{<RefreshIcon color='primary' />}Filter Reset</Button>
                    </div>
                    <div>
                    </div>
                  </div>
                </Grid>
              </div>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={0} className={classes.containwidth}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            {/* 
            <div className='start-end-date start-end-date-admin adminblock'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}

                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }}

                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div> */}

            <Item className={classes.dashboargraph} id="dashboargraph adminblock">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>

                </div>

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <h5>Transactions Per Day</h5>
                    <p>Sales performance for online and offline revenue </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>

                <div className='dashboargraph-inner-bottom'>
                  <YearlyChart transac={transCount} />

                </div>

              </div>


            </Item>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.rightpartstyle}>

            {/* <div className='start-end-date start-end-date-admin adminblock'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker


                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}

                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div> */}

            <Item className={classes.headercls} id="dashboargraph adminblock">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>

                </div>

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <h5>Daily Cost</h5>
                    <p>Sales performance for online and offline revenue </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>

                <div className='dashboargraph-inner-bottom'>
                  {/* <CandleStick /> */}
                  <YearlyChartCount transac={transac} />
                </div>

              </div>


            </Item>

          </Grid>

        </Grid>

        <Grid container spacing={0} className={classes.containwidth}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.polygongas}>

            <Item className={classes.headercls} id="recent-earnings-sales-admin">

              <div className='polygon'>
                <h3 className='head-table-recent'>Polygon Gas Tracker</h3>
                <p className='para-head-table-recent'>🔥 Burnet fees : 17,852,9631</p>
              </div>

              <div className='polygon-card'>

                <div className='card'>
                  <h4>Standard</h4>
                  <h5 className='green'>{gasTracker?.SafeGasPrice ? parseInt(gasTracker?.SafeGasPrice) : 0} Gwei</h5>
                  <h6>(30-60 secs)</h6>
                  <p>Base: 0 | Priority {gasTracker?.SafeGasPrice}</p>
                </div>

                <div className='card'>
                  <h4>Fast</h4>
                  <h5 className='blue'>{gasTracker?.ProposeGasPrice ? parseInt(gasTracker?.ProposeGasPrice) : 0} Gwei</h5>
                  <h6>(10-30 secs)</h6>
                  <p>Base: 0 | Priority {gasTracker?.ProposeGasPrice}</p>
                </div>

                <div className='card'>
                  <h4>Rapid</h4>
                  <h5 className='light-blue'>{gasTracker?.FastGasPrice ? parseInt(gasTracker?.FastGasPrice) : 0} Gwei</h5>
                  <h6>(5-10 secs)</h6>
                  <p>Base: 0 | Priority {gasTracker?.FastGasPrice}</p>
                </div>

              </div>

              <div className='pending'>
                <h3>Pending Transaction Pool Info :</h3>

                <div className='last-block'>
                  <div><label>Last Block : </label><span>{gasTracker?.LastBlock}</span></div>
                  <div><label>Avg.Utilization : </label><span>50.50%</span></div>
                </div>

                <div className='last-block'>
                  <div><label>Pending Queue : </label><span>39386 Txns</span></div>
                  <div><label>Last Refreshed : </label><span>Mon, 04 Sep 2023  13:23</span></div>
                </div>

                <div className='last-block'>
                  <div><label>Avg.Block Size : </label><span>83 Txns</span></div>
                </div>

                <div className='dashboargraph-inner-top'>
                  <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button>
                </div>

              </div>



            </Item>

          </Grid>
        </Grid>


        <Grid container spacing={0} className={classes.containwidth}>

          <div className='listbody'>

            <div className='tablebody'>
              <div className='dashboargraph-inner-top'>
                <Item className={classes.dashboargraph} id="earnings-sales">
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow className='row-head'>
                          <TableCell className='head-bg'>Internal contract ID</TableCell>
                          <TableCell className='head-bg'>From Wallet ID</TableCell>
                          <TableCell className='head-bg'>To Wallet ID</TableCell>
                          {/* <TableCell className='head-bg'>Product ID</TableCell> */}
                          {/* <TableCell className='head-bg'>Gas Fee</TableCell> */}
                          <TableCell className='head-bg'>Type</TableCell>
                          <TableCell className='head-bg'>DTRACER Fee</TableCell>
                          <TableCell className='head-bg'>Status</TableCell>
                          <TableCell className='head-bg'>More Info</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productList?.map((blockrows, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" className='body-bg'>{blockrows.brands?.user_info?.name}</TableCell>
                            <TableCell className='body-bg overflow' onClick={() => { window.open(`${consts?.URL}address/${blockrows?.From}`) }} >{blockrows?.From}</TableCell>
                            <TableCell className='body-bg overflow' onClick={() => { window.open(`${consts?.URL}address/${blockrows?.To}`) }} >{blockrows?.To}</TableCell>
                            {/* <TableCell className='body-bg'>{blockrows?.product_id}</TableCell> */}
                            {/* <TableCell className='body-bg overflow'>---</TableCell> */}
                            <TableCell className='body-bg overflow'>{blockrows?.Type}</TableCell>
                            <TableCell className='body-bg overflow'>{blockrows?.TransactionFee ? (parseFloat(blockrows?.TransactionFee).toFixed(6)) : "---"}</TableCell>
                            {/* <TableCell className='body-bg'>---</TableCell> */}
                            {/* <TableCell className='body-bg'><div className='icon'><img src={blockrows?.Other?.status} alt="rightState" /></div></TableCell> */}
                            <TableCell className='body-bg'><div className='icon'>{blockrows?.Other?.status === true ? "true" : 'false'}</div></TableCell>
                            <TableCell className='body-bg'>
                              <div className='icon-space'>
                                {/* <Button onClick={() => { producthandleOpen(); setRows(blockrows) }}>{<InfoOutlinedIcon color="primary" />} */}
                                <Button onClick={() => { setUserDetails(blockrows); profilehandleOpen() }}>{<PersonIcon color="primary" />}</Button>
                                {/* </Button> */}
                                {/* <Button onClick={() => { setMapDetails(blockrows); maphandleOpen(); setCountry(`${blockrows?.product_details?.latitude}, ${blockrows?.product_details?.longitude}`) }}>{<PlaceIcon />}</Button> */}

                                {/* <Button> {<LocalPostOfficeOutlinedIcon  color="primary"/>}</Button> */}

                                {/* <Button >{<RefreshIcon />}</Button> */}
                                {role == "admin" && <Button onClick={() => { downloadExcelsheet(blockrows) }} > {<DownloadIcon color="primary" />}</Button>}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Item>

                <Modal
                  open={mapopen}
                  onClose={maphandleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description" className='map-popup'
                >

                  <Box sx={style} className='popBOX'>
                    <div className="menu" onClick={maphandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                    <Typography id="modal-modal-title" variant="h6" component="h2" className='brand-block-popmap'>
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20311214.073350176!2d101.98305051684045!3d71.89764895113782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1693909504227!5m2!1sen!2sin" width="80%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" /> */}

                      <Map center={[mapDetails?.product_details?.latitude, mapDetails?.product_details?.longitude]} zoom={states?.zoom}>
                        <TileLayer
                          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        />

                        <Marker position={[mapDetails?.product_details?.latitude, mapDetails?.product_details?.longitude]} icon={customIcon} >
                          <Popup>
                            <label>Product Id: </label> <span>{mapDetails?.product_details?.product_id}</span><br></br>
                            <label>Product Name: </label> <span>{mapDetails?.product_details?.Product_Name}</span><br></br>
                            {/* <label>Owned By: </label> <span>{marker?.userName}</span> */}
                          </Popup>
                        </Marker>

                      </Map>
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div className="listThree">
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Country
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              // label="Enter Your Amount "
                              variant="outlined"
                              className="textField"
                              value={country}

                            />
                          </div>
                        </div>
                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            Movement location
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              label="Av. Barcelona 55, Madrid "
                              variant="outlined"
                              className="textField"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="block-export">
                        {/* <Button variant="contained">
                            Export<img src={Files} alt={Files} />
                          </Button> */}
                      </div>
                    </Typography>

                  </Box>
                </Modal>


                {<Modal
                  open={productopen}
                  onClose={producthandleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description" className='product-popup'>


                  <Box sx={style} className="popBOX">
                    <div className="menu" onClick={producthandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                    <Typography variant="h6" component="h2" className='hb' sx={{ textAlign: 'center' }}>
                      Product Information
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                      <div className='product'>

                        <div className='lady-img'>
                          <img src={rows?.product_details?.main_image} alt="ladyimg" />
                        </div>

                        <div className="listThree">
                          <div className="productId">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              className="modelTypo"
                            >
                              Name
                            </Typography>

                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                className="textField"
                                disabled
                                value={rows?.product_details?.Product_Name}
                              />
                            </div>
                          </div>

                          <div className="productId">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              className="modelTypo"
                            >
                              Category
                            </Typography>

                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                disabled
                                value={rows?.product_details?.product_category}
                                variant="outlined"
                                className="textField"
                              />
                            </div>
                          </div>

                          <div className="productId">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              className="modelTypo"
                            >
                              EAN Number
                            </Typography>

                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                disabled
                                value={rows?.product_details?.ean}
                                variant="outlined"
                                className="textField"
                              />
                            </div>
                          </div>

                          <div className="productId">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                              className="modelTypo"
                            >
                              Product Id
                            </Typography>

                            <div className="cmn-input-cls">
                              <TextField
                                id="outlined-basic"
                                disabled
                                value={rows?.product_details?.product_id}
                                variant="outlined"
                                className="textField"
                              />
                            </div>
                          </div>


                        </div>

                        <div className='product-desc'>
                          <h5>Product Description</h5>
                          <p>{rows?.product_details?.product_description}</p>
                        </div>

                      </div>

                      <div className="block-export">
                        {/* <Button variant="contained">
                          Export<img src={Files} alt={Files} />
                        </Button> */}
                      </div>

                    </Typography>

                  </Box>
                </Modal>}



              </div>

              <div className='pagination-numb'>
                <Pagination count={counts} page={page} onChange={handleChange1} color="primary" shape="rounded" />

                {/* <Pagination count={9} color="primary" shape="rounded" /> */}
              </div>

            </div>

          </div>

        </Grid>


        <Modal
          open={profileopen}
          onClose={profilehandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description" className='map-popup profile-brandpop'
        >
          <Box sx={style} className="popBOX">
            <div className="menu" onClick={profilehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            <Typography variant="h6" component="h2" className='hb' sx={{ textAlign: 'center' }}>
              Customer information
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>

              <div className='product'>

                {/* <div className='lady-img'>
                            <img src={lady} alt="ladyimg" />
                          </div> */}

                <div className="listThree profile-brandpop">
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Name
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        // label="Bharath Kumar "
                        value={userdetails?.brands?.user_info?.name}
                        variant="outlined"
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Email
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        value={userdetails?.brands?.user_info?.email}
                        variant="outlined"
                        className="textField"
                      />
                    </div>
                  </div>
                  {/* <div className="productId">
                              <div className="start-end-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    className="modelTypo"
                                  >
                                    DOB
                                  </Typography>
                                  <DemoContainer
                                    components={[
                                      "DatePicker",
                                      "DateTimePicker",
                                      "TimePicker",
                                      "DateRangePicker",
                                    ]}
                                  >
                                    <DemoItem>
                                      <DatePicker
                                        defaultValue={userdetails?.brands?.user_info?.dob ? userdetails?.brands?.user_info?.dob :yesterday}
                                        // disablePast
                                        views={["year", "month", "day"]}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </div>
                            </div> */}

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Phone
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        value={userdetails?.brands?.user_info?.phone}

                        variant="outlined"
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Role
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        value={userdetails?.brands?.user_info?.role}

                        variant="outlined"
                        className="textField"
                      />
                    </div>
                  </div>
                </div>

              </div>

              <div className="block-export">
                <Button variant="contained">
                  Export<img src={Files} alt={Files} />
                </Button>
              </div>

            </Typography>

          </Box>
        </Modal>

      </Box>
    </div>
  )
}

export default AdminBlockchainBody
