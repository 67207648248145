import React, { useRef, useState, useEffect } from "react";
import "./AdminCustomer.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import box from '../../../img/box.svg';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Toaster, toast } from 'react-hot-toast'
import Axios from "../../../Axios";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
// import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import adidasLogo from '../../../img/adidasLogo.png';
import cross from '../../../img/cross.svg'
import { isDisabled } from "@testing-library/user-event/dist/utils";
// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));


const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "2px solid #B7F117 !important",
  p: 2,
};

const Renewal = ({ row }) => {

  const [ondate, setOnDate] = useState('')
  const [expiredate, setExpiredate] = useState('')

  const [redate, setReDate] = useState('')

  const handleExpirationDate = (value) => {
    setExpiredate(value)
  }

  const handleRememberDate = (value) => {
    setReDate(value)
  }

  const handleActivationDate = (value) => {
    setOnDate(value)
  }

  const [open, setOpen] = useState(false);
  const reasontext = useRef(null)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = useState(false);
  const [istext, setIstext] = useState(true)
  const [isdate, setIsdate] = useState(true)
  const [ondateerr, setondateerr] = useState(null)
  const [remembererr, setrememberdateerr] = useState(null)
  const [expiredateerr, setexpiredateerr] = useState(null)
  const [licenceInfo, setLicenceInfo] = useState({
    ondate: "",
    expiredate: "",
    redate: ""
  })
  const [otherchecked, setOtherchecked] = useState(false);
  const [reason, setReason] = useState(false)
  const [resonTxt, setReasonTxt] = useState()
  const [checkerr, setCheckerr] = useState(false)
  const [resonErr, setReasonErr] = useState()
  const [checkboxes, setCheckboxes] = useState([
    { label: 'Licence Renewal', checked: false, value: 'Licence Renewal' },
    { label: 'Others', checked: false, value: 'Others' },
    // { label: 'Checkbox 3', checked: false, value: 'Value 3' },
    // { label: 'Checkbox 4', checked: false, value: 'Value 4' },
    // { label: 'Checkbox 5', checked: false, value: 'Value 5' },
  ]);

  const [checkedIndex, setCheckedIndex] = useState(-1);
  const handleChanges = (index) => (event) => {

    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = event.target.checked;
    setCheckboxes(newCheckboxes);
    setCheckerr(false)
    setChecked(true)
    if (newCheckboxes[index].value === 'Others') {
      if (newCheckboxes[index].checked === true) {
        setIstext(false)
        setReason(reasontext?.current?.value || '')
      } else {
        setIstext(true)
      }

    } else {
      setIstext(true)
      setReason(newCheckboxes[index].value)
    }

    if (newCheckboxes[index].value === 'Licence Renewal') {
      if (newCheckboxes[index].checked === true) {
        setIsdate(false)
        setReason(reasontext?.current?.value)
      } else {
        setIsdate(true)
      }

    } else {
      setIsdate(true)
      setReason(newCheckboxes[index].value)
    }

    if (event.target.checked) {
      setCheckedIndex(index);
    } else {
      setCheckedIndex(-1);
      setChecked(false)
      setReason(false)
    }
  };
  const setusers = async () => {

    if (reason != "Others" && ondate == '') {
      setondateerr('*Please Enter A Activation Date')
    }
    else if (reason != "Others" && expiredate == '') {
      setexpiredateerr('*Please Enter A Expiry Date')
    }
    else if (reason != "Others" && redate == '') {
      setrememberdateerr('*Please Enter A Remember Date')
    }
    else if (reason == "Others" && reasontext?.current?.value == '') {
      setReasonErr('*Please Enter A Reason')
    }
    else {
      try {
        if (
          (checked || otherchecked) &&
          ((reasontext?.current?.value != '') || (licenceInfo.ondate != '') || (licenceInfo.expiredate != '') || (licenceInfo.redate != ''))
        ) {
          const updatedReason = reasontext?.current?.value || reason;
          const updatedLicenceInfo = {
            ondate: licenceInfo.ondate,
            expiredate: licenceInfo.expiredate,
            redate: licenceInfo.redate,
          };
          const { data } = await Axios.post('/admin/reactive_brand',
            {
              _id: row._id,
              licenceInfo: updatedLicenceInfo,
              reason: updatedReason
            },

            {
              headers: {
                Authorization: localStorage.getItem("AdminToken"),
              },
            }
          )
          if (data?.success) {
            window.location.reload()
            toast.success('Renewed Sucessfully')

          }
        } else {
          setCheckerr('Please Give Reason or Select Date');
        }

      } catch (error) {
        console.log(error, "err")
        toast.error('Error')
      }
    }

  }
  const [curr, setCurr] = useState()
  const currentDay = () => {
    const date = new Date();

    let day = date.getDate();
    if (day.toString().length === 1) {
      day = `0${day}`
    }
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`;
    setCurr(currentDate)
  }

  useEffect(() => {
    currentDay()
  }, [])


  return (
    <div >
      <Button onClick={handleOpen} className="activate">
        Licence Renewal
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate Deactivate-user-popup">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          <div className="brand" id="brands">
            <div className="image-box">
              <img src={row?.user_info.logo} alt={'adidasLogo'} />
            </div>
          </div>

          <div className="h2Variant">Renew Brand</div>

          <p className="reason">Reason</p>


          <div className="listThree">
            <div className="warn">
              {checkboxes.map((checkbox, index) => (
                <div key={index}>
                  <Checkbox
                    checked={checkbox.checked}
                    onChange={handleChanges(index)}
                    color="primary"
                    inputProps={{ 'aria-label': checkbox.label }}
                    disabled={checkedIndex !== -1 && checkedIndex !== index}
                  />
                  <label>{checkbox.label}</label>
                </div>
              ))}

            </div>
          </div>

          {istext === false ?
            <div className='input-box'>
              <TextField className='field-box'
                required
                multiline
                rows={5}
                rowsMax={5}
                width={100}
                inputRef={reasontext}
                disabled={istext}
                onChange={(e) => { setCheckerr(true) }}
                variant="outlined"
              />
              {resonErr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{resonErr}</div> : <></>}
            </div>
            : <></>
          }

          {isdate === false ?

            <> <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                activation date
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { handleActivationDate(e.target.value); setondateerr(null); setLicenceInfo({ ...licenceInfo, ondate: e.target.value }) }}
                  type="date"
                  // inputRef={ondate}
                  inputProps={{
                    min: curr
                  }}
                  value={licenceInfo?.ondate}
                />
                {ondateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{ondateerr}</div> : <></>}
              </div>
            </div>
              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  expiration date
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"

                    variant="outlined"
                    className="textField"
                    type="date"
                    onChange={(e) => { handleExpirationDate(e.target.value); setexpiredateerr(null); setLicenceInfo({ ...licenceInfo, expiredate: e.target.value }) }}
                    // inputRef={expiredate}
                    value={licenceInfo?.expiredate}
                    inputProps={{
                      min: curr
                    }}
                  />
                  {expiredateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{expiredateerr}</div> : <></>}
                </div>
              </div>
              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Remember date
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="textField"
                    onChange={(e) => { handleRememberDate(e.target.value); setrememberdateerr(null); setLicenceInfo({ ...licenceInfo, redate: e.target.value }) }}
                    type="date"
                    // inputRef={ondate}
                    value={licenceInfo?.redate}
                    inputProps={{
                      min: curr
                    }}
                  />
                  {remembererr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{remembererr}</div> : <></>}
                </div>
              </div>
            </>

            : <></>
          }

          {checkerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{checkerr}</div> : <></>}
          <div className="butOutLayer">
            <Button className='reactive' onClick={() => setusers()}>Approve Brand</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Renewal;
