import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ReactToPrint from "react-to-print";
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import * as XLSX from "xlsx";
import Button from '@mui/material/Button';
import { Chart } from "react-google-charts";
import './AdminSellersAuth.css'
import consts from '../../../constants';
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { pink, blue } from '@mui/material/colors';
import Files from '../../../img/files.svg';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import EditSeller from './EditSeller';
import SellersFilterPopup from './SellersFilterPopup';
import AddSellersPopup from './AddSellersPopup1';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import TextField from "@mui/material/TextField";
import send from '../../../img/send-msg.png';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import AdminMenuSide from '../AdminMenuSide';
import Axios from '../../../Axios';
import toast from 'react-hot-toast';
import CryptoJS from "crypto-js";
import logout from '../../logout';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};


function sellerStore(
  dueDate,
  service,
  password

) {
  return { dueDate, service, password };
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: 500,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 500],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));



const today = dayjs();


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '0 0 0 90px !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },
  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});

const AdminSellersAuth = () => {



  useEffect(() => {
    document.title = "Dtracer | Seller"
  }, [])
  const Role = localStorage.getItem("Squelch")
  const classes = useStyles();
  const count = useRef(null)
  const email = useRef(null)
  const [menuOpen, setMenuOpen] = useState(true)
  const [sellers, setSellers] = useState([])
  const [stafflen, setStaffLen] = useState(0)
  const [sellerlen, setSellerlen] = useState(0)
  const [staffs, setStaffs] = useState([])
  const [id, setId] = useState()
  const [staffid, setstaffId] = useState()
  const [staffname, setStaffName] = useState()
  const ref = useRef()
  const [tempstaff, settemStaffs] = useState([])
  const [counterr, setcounterr] = useState(false)
  const [emailerr, setemailerr] = useState(false)
  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [storeopen, storesetOpen] = React.useState(false);
  const storehandleOpen = () => storesetOpen(true);
  const storehandleClose = () => storesetOpen(false);

  const [deltaopen, deltasetOpen] = React.useState(false);
  const deltahandleOpen = () => deltasetOpen(true);
  const deltahandleClose = () => deltasetOpen(false);

  const [deltaopenConfirm, deltasetOpenConfirm] = React.useState(false);
  const deltahandleOpenConfirm = () => deltasetOpenConfirm(true);
  const deltahandleCloseConfirm = () => deltasetOpenConfirm(false);

  const [createopen, createsetOpen] = React.useState(false);
  const createhandleOpen = () => createsetOpen(true);
  const createhandleClose = () => createsetOpen(false);

  // const [openStaffDetails, setOpenStaffDetails] = React.useState(false);
  // const handleOpenStaffDetails = () => setOpenStaffDetails(true);
  // const handleCloseStaffDetails = () => setOpenStaffDetails(false);

  const [addStaffEmail, setAddStaffEmail] = React.useState(false);
  const openAddStaffEmail = () => setAddStaffEmail(true);
  const closeAddStaffEmail = () => setAddStaffEmail(false);

  const [addStaffEmailConfirm, setAddStaffEmailConfirm] = React.useState(false);
  // const openAddStaffEmailConfirm = () => setAddStaffEmailConfirm(true);
  const closeAddStaffEmailConfirm = () => setAddStaffEmailConfirm(false);

  const [deleteSellerStoreList, setDeleteSellerStoreList] = React.useState(false);
  const openDeleteSellerStoreList = () => setDeleteSellerStoreList(true);
  const closeDeleteSellerStoreList = () => setDeleteSellerStoreList(false);


  const [deleteStaffPopUp, setDeleteStaffPopUp] = React.useState(false);
  const openDeleteStaffPopUp = () => setDeleteStaffPopUp(true);
  const closeDeleteStaffPopUp = () => setDeleteStaffPopUp(false);

  // const [activate, seActivate] = React.useState(false);
  // const openActivate = () => seActivate(true);
  // const closeActivate = () => seActivate(false);

  // const [deactivate, setDeactivate] = React.useState(false);
  // const openDeactivate = () => setDeactivate(true);
  // const closDdeactivate = () => setDeactivate(false);
  const [counts, setCount] = useState()
  const [statussss, setstatusss] = useState()

  const role = window.localStorage.getItem('Squelch')
  const status = window.localStorage.getItem('isimo')
  const [creationDate, setCreationDate] = useState([])
  const [paymentDate, setPaymentDate] = useState([])
  const [decrptedData, setDecrptedData] = useState()
  const [Isfilter, setIsfilter] = useState(false)
  const [page, setPage] = useState(1)
  const handleChange1 = (event, value) => {
    setPage(value);
  };
  const secretPass = 'DtRaCeRsEcrEtFrEe'
  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt(status, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };
  const [isActive, setIsActive] = useState(true);
  const redirectPath = '/'
  const timeoutSeconds = 5 * 60 * 1000
  const navigate = useNavigate()
  useEffect(() => {
    screenInactive()
  }, [timeoutSeconds, isActive, navigate, redirectPath]);

  const screenInactive = () => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      if (isActive) {
        timeoutId = setTimeout(() => {
          logout()
          window.localStorage.removeItem('electrónico')
          window.localStorage.removeItem('AdminToken')
          window.localStorage.removeItem('Squelch')
          navigate(`${consts.route}/`)
        }, timeoutSeconds);
      }
    };

    const handleMouseActivity = () => {
      setIsActive(true);
      resetTimeout();
    };


    resetTimeout();


    window.addEventListener('mousemove', handleMouseActivity);
    window.addEventListener('keydown', handleMouseActivity);


    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleMouseActivity);
      window.removeEventListener('keydown', handleMouseActivity);
    };
  }

  useEffect(() => {
    if (role === 'subAdmin') {
      decryptData()
    }
  }, [status])


  useEffect(() => {
    if (Role === "brand") {
      getSellers()
    }
    else if ((Role === "admin") || (Role === 'subAdmin')) {
      getSellers1()
    }
  }, [])

  const getData = () => {
    if (Role === "brand") {
      getSellers()
    }
    else if ((Role === "admin") || (Role === 'subAdmin')) {
      getSellers1()
    }
  }
  const getSellers1 = async () => {
    try {
      setstatusss('Loading....')
      const { data } = await Axios.post('/users/get_all_sellers', {
        page: page - 1,
        limit: 10
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data?.success) {
        if (data?.result?.length > 0) {
          setSellers(data?.result)
          setCount(Math.ceil(data?.result?.length / 10))
          setSellerlen(data?.result.length)
          setStaffLen(data?.total_staff)
        } else {
          setstatusss('No Seller Found')
        }

      }
      else {
        setstatusss('No Seller Found')
        setSellers(data?.result)
        // toast.error(data?.message)
      }

    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }
  const getSellers = async () => {
    try {
      setstatusss('Loading ...')

      const { data } = await Axios.post('/users/get_seller', {
        page: page - 1,
        limit: 10
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data?.success) {
        setSellers(data?.result)
        setCount(Math.ceil(data?.result?.length / 10))
        setSellerlen(data?.result.length)
        setStaffLen(data?.total_staff)
      }
      else {
        setstatusss('No Seller Found')
        setSellers(data?.result)
        // toast.error(data?.message)
      }

    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }
  const getStaff = async (_id) => {
    try {
      const { data } = await Axios.post('/users/get_all_staffs', {
        _id: _id
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data?.success) {
        setStaffs(data?.result)
        setId(_id)
      }
      else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }
  const addStaff = async (_id) => {
    try {
      if (count.current.value === "") {
        setcounterr("Give Count")
      }
      else {
        const { data } = await Axios.post('/admin/addstaff', {
          _id: _id,
          count: count.current.value
        },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )
        if (data?.success) {
          settemStaffs(data?.result)
          toast.success(data?.message)
        }
        else {
          toast.error(data?.message)
        }
      }
    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }
  const activateSeller = async (user) => {
    try {
      var data = {}
      if (user.is_active === false) {
        data = await Axios.post('/admin/active_seller', {
          _id: user._id,

        },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )
      }
      else {
        data = await Axios.post('/admin/deactive_seller', {
          _id: user._id,

        },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )
      }
      if (data?.data?.success) {
        getData()
        toast.success(data?.data?.message)

      }
      else {
        toast.error(data?.data?.message)
      }

    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }
  const downloadExcelsheet = () => {

    let tableData = []

    for (var i = 0; i < sellers.length; i++) {
      let reasons;
      var info = {
        staff_id: sellers[i].staff_id,
        no_of_staff: sellers[i].no_of_staff,
        //pass: sellers[i].pass,
        contact_mail: sellers[i].contact_mail,
        firstname: sellers[i].firstname,
        email: sellers[i].email,
        city: sellers[i].city,
        phone: sellers[i].phone,
        address: sellers[i].address,
        country: sellers[i].country,
        postal_code: sellers[i].postal_code,
        contact_phone: sellers[i].contact_phone,
        state: sellers[i].state,
        sellerId: sellers[i].sellerId,
        verification: sellers[i].verification,

      }
      tableData.push(info)
    }
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Sellers.csv');

  };
  const filterPro = (data) => {
    setstatusss("Loading...")
    if (data.length > 0) {
      setstatusss()


      setSellers(data)
      setCount(Math.ceil(data?.length / 10))
    } else {
      setSellers(data)
      setstatusss("No product available for this filter.....")
    }

  }
  const reset = (data) => {

    setstatusss("Loading...")
    if (data === true) {
      setCreationDate([])
      setPaymentDate([])
      if ((role === 'admin') || (role === 'subAdmin')) {
        getSellers1()
      }
      else {
        getSellers()
      }

    }
    else {
      console.log("Cant reset")
    }
  }
  const deleteStaff = async () => {
    try {

      const { data } = await Axios.post('/users/deletestaff', {
        _id: id,
        staff_id: staffid
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data?.success) {
        getStaff(id)
        getData()
        toast.success(data?.message)
      }
      else {
        toast.error(data?.message)
        getData()
      }
    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }
  useEffect(() => {

    if (Isfilter === false) {
      getData()
    }
    else {
      filterPro()
    }
  }, [page])

  const sendMail = async () => {
    try {
      if (email.current.value === "") {
        setemailerr("Enter Email")
      }
      else {
        var tempstaffs = JSON.stringify(tempstaff)
        const { data } = await Axios.post('/admin/send_staff_mail', {
          _id: id,
          email: email.current.value,
          tempstaff: tempstaff
        },
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )
        if (data?.success) {
          toast.success(data?.message)
          closeAddStaffEmail()
          createhandleClose()
          settemStaffs([])
          getData()

        }
        else {
          toast.error(data?.message)
          getData()

        }
      }
    } catch (error) {
      console.log(error, "err")
      getData()

      // toast.error("error")
    }

  }

  // const handlecreationdatechange = (d, m, y) => {

  //   var credate = y + '-' + m + '-' + d

  //   setCreationDate(credate)
  //   if (paymentDate.length !== 0) {
  //     filtersdata(credate, paymentDate)
  //   }


  // }

  // const handlepaymentdatechange = (d, m, y) => {

  //   var payDate = y + '-' + m + '-' + d

  //   setPaymentDate(payDate)
  //   if (creationDate.length !== 0) {
  //     filtersdata(creationDate, payDate)

  //   }

  // }
  // const filtersdata = async (creationDates, paymentDates) => {
  //   setstatusss("Loading...")

  //   const datas = {
  //     creation_date: creationDates,
  //     payment_date: paymentDates
  //   }
  //   if ((role === 'admin') || (role === 'subAdmin')) {
  //     const { data } = await Axios.post(`/users/get_all_sellers`, datas,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("AdminToken"),
  //         },
  //       })
  //     if (data?.success) {
  //       if (data?.result?.length > 0) {
  //         setSellers(data?.result)
  //         setstatusss()
  //         //setStaffLen(data?.total_staff)
  //       } else {
  //         setSellers(data?.result)
  //         setstatusss('No Seller Found')
  //       }

  //     }
  //     else {
  //       setstatusss('No Seller Found')
  //       setSellers(data?.result)
  //     }
  //   }
  //   else {
  //     const { data } = await Axios.post(`/users/get_seller`, datas,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("AdminToken"),
  //         },
  //       })
  //     if (data?.success) {
  //       if (data?.result?.length > 0) {
  //         setSellers(data?.result)
  //         setstatusss()
  //         // setStaffLen(data?.total_staff)
  //       } else {
  //         setstatusss('No Seller Found')
  //         setSellers(data?.result)
  //       }

  //     }
  //     else {
  //       setstatusss('No Seller Found')
  //       setSellers(data?.result)
  //     }
  //   }

  // }
  const [datass, setDatass] = useState([
    ["Country", "Stores"],
  ])

  const counts_seller = async () => {
    try {
      const { data } = await Axios.post(`/users/sellerCountryWise`, {}, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success == true) {
        var arr = []
        for (let i = 0; i < data?.result?.length; i++) {
          const element = data?.result?.[i];
          arr.push([element?._id, element?.count])
        }
        setDatass([...datass, ...arr])
      }
    } catch (error) {
      console.log("🚀 ~ constcounts_seller= ~ error:", error)
    }
  }

  useEffect(() => {
    counts_seller()
  }, [])

  if ((role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[6]?.Read === true)) || (role === 'brand')) {
    return (

      <div className='sellerpage'>


        <Box sx={{ flexGrow: 1 }}>

          <Grid container className='containerBody  auth-body'>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.bodyclass}>
                <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                  <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                  <AdminMenuSide />
                </div>
              </Item>
            </Grid>



            <div className='wholeCointainWidth' onClick={closeMenuHandler}>
              <div className='innerBody'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                  <div className='filter-btn'>

                    <div className='filter-filed'>
                      <div className='filter-box'>
                        <SellersFilterPopup filterPro={filterPro} reset={reset} setIsfilter={setIsfilter} />

                      </div>

                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='Title'>
                  {
                    (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[6]?.Write === true)) || (role === 'brand') ?
                      <AddSellersPopup />
                      :
                      <></>
                  }

                  <div className='SellerPaidTable sellerauth'>
                    <Item className={classes.dashboargraph} id="earnings-sales">
                      <TableContainer>
                        <Table aria-label="customized table">
                          <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>
                              <StyledTableCell className="head-part-table">Seller Id</StyledTableCell>
                              <StyledTableCell className="head-part-table">Trade name</StyledTableCell>
                              <StyledTableCell className="head-part-table">Total Staff </StyledTableCell>
                              <StyledTableCell className="head-part-table">Creation date</StyledTableCell>
                              <StyledTableCell className="head-part-table">Status</StyledTableCell>
                              <StyledTableCell className="head-part-table">Activation codes</StyledTableCell>
                              {(role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[6]?.Write === true)) || (role === 'brand') ?
                                <StyledTableCell className="head-part-table">Action</StyledTableCell>
                                : <></>
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                            {sellers.length > 0 ? sellers.map((row, index) => (
                              <StyledTableRow key={row?._id}>
                                <StyledTableCell component="th" scope="row" className="table-value">
                                  {/* <Checkbox {...label} color="primary" className='checkBoxClr' /> */}
                                  {row?.sellerId}
                                </StyledTableCell>
                                <StyledTableCell className="table-value">{row?.firstname}</StyledTableCell>
                                <StyledTableCell className="table-value">{row?.no_of_staff}</StyledTableCell>
                                <StyledTableCell className="table-value">{new Date(row?.createdAt).toLocaleDateString()}</StyledTableCell>
                                {row?.is_active ? <StyledTableCell className="table-value" style={{ color: "green" }}>Activated</StyledTableCell> : <StyledTableCell className="table-value" style={{ color: "red" }}>Deactivated</StyledTableCell>}
                                <StyledTableCell className="table-value"><div onClick={() => { getStaff(row?._id); storehandleOpen() }}><PlaylistAddCheckIcon /></div></StyledTableCell>
                                {
                                  (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[6]?.Write === true)) || (role === 'brand') ?
                                    <StyledTableCell className="table-value"><div className='codes'>
                                      <div onClick={() => { getStaff(row?._id); createhandleOpen() }}><GroupAddIcon /></div>
                                      <div onClick={() => { getStaff(row?._id); openDeleteSellerStoreList() }}><GroupRemoveIcon /></div>
                                      <Stack direction="row" spacing={1} alignItems="center" className='action-ptxt'>
                                        <Typography>Off</Typography>
                                        <AntSwitch defaultChecked={row?.is_active} onChange={() => { activateSeller(row) }} inputProps={{ 'aria-label': 'ant design' }} />
                                        <Typography>On</Typography>
                                      </Stack>
                                      <EditSeller getData={getData} row={row} />
                                    </div>
                                      {row?.action}
                                    </StyledTableCell>
                                    :
                                    <></>
                                }


                              </StyledTableRow>


                            ))
                              :

                              <StyledTableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></StyledTableCell>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Item>
                    <Item className={classes.dashboargraph} id="earnings-sales" style={{ "display": "none" }}>
                      <TableContainer >
                        <Table aria-label="customized table" ref={ref}>
                          <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>

                              <StyledTableCell className="head-part-table">Seller Id</StyledTableCell>
                              <StyledTableCell className="head-part-table">Trade name</StyledTableCell>
                              <StyledTableCell className="head-part-table">Total Staff </StyledTableCell>
                              <StyledTableCell className="head-part-table">Creation date</StyledTableCell>
                              <StyledTableCell className="head-part-table">Status</StyledTableCell>

                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                            {sellers.length > 0 ? sellers.map((row, index) => (
                              <StyledTableRow key={row?._id}>
                                <StyledTableCell className="table-value" style={{ "textAlign": "center" }}>
                                  {/* <Checkbox {...label} color="primary" className='checkBoxClr' /> */}
                                  {row?.sellerId}
                                </StyledTableCell>
                                <StyledTableCell className="table-value" style={{ "textAlign": "center" }}>{row?.firstname}</StyledTableCell>
                                <StyledTableCell className="table-value" style={{ "textAlign": "center" }}>{row?.no_of_staff}</StyledTableCell>
                                <StyledTableCell className="table-value" style={{ "textAlign": "center" }}>{new Date(row?.createdAt).toLocaleDateString()}</StyledTableCell>
                                {row?.is_active ? <StyledTableCell className="table-value" style={{ textAlign: "center", color: "green" }}>Activated</StyledTableCell> : <StyledTableCell className="table-value" style={{ color: "red", textAlign: "center", }}>Deactivated</StyledTableCell>}

                              </StyledTableRow>


                            ))
                              :

                              <StyledTableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></StyledTableCell>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Item>
                    <div className='tablebody'>

                      <div className='BtmBtn'>
                        <div className='download-keys'>
                          <div className='save-icon'>{<SaveAltIcon sx={{ color: blue[500] }} onClick={() => { downloadExcelsheet() }} />}</div>
                          <ReactToPrint
                            bodyClass="print-agreement"
                            content={() => ref.current}
                            trigger={() => (
                              <div className='print-icon'>{<LocalPrintshopOutlinedIcon />}</div>

                            )}
                          />
                        </div>
                        <Button variant="contained" className='export' onClick={() => { downloadExcelsheet() }}>
                          Export<img src={Files} alt={Files} />
                        </Button>
                      </div>
                    </div>
                    <div className='pagination-numb'>
                      <Pagination count={counts} page={page} onChange={handleChange1} color="primary" shape="rounded" />
                    </div>
                  </div>

                  {<Modal
                    open={storeopen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">SELLER STORE DELTA</div>

                      <div className="listOne">

                        <div className='SellerPaidTable'>
                          <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead className='SellerTableHead'>
                              <TableRow className='SellerTableRow'>
                                <StyledTableCell className="head-part-table">Staff Name</StyledTableCell>
                                <StyledTableCell className="head-part-table">Staff ID</StyledTableCell>
                                <StyledTableCell className="head-part-table">Password</StyledTableCell>
                                {
                                  (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[6]?.Write === true)) || (role === 'brand') ?
                                    <StyledTableCell className="head-part-table"></StyledTableCell>
                                    :
                                    <></>
                                }
                              </TableRow>
                            </TableHead>
                            <TableBody className='SellerTableBody'>
                              {staffs && staffs.map((staff, index) => (
                                <StyledTableRow key={staff._id}>
                                  <StyledTableCell className="table-value">{staff?.name}</StyledTableCell>
                                  <StyledTableCell className="table-value">{staff?.StaffId}</StyledTableCell>
                                  <StyledTableCell className="table-value"><div className='seller-store-list'>{staff?.passwords}</div></StyledTableCell>
                                  {
                                    (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[6]?.Write === true)) || (role === 'brand') ?
                                      <StyledTableCell className="table-value">
                                        <div className='delete-seller-store' onClick={() => { setStaffName(staff?.name); setstaffId(staff?._id); openDeleteStaffPopUp() }}><DeleteIcon /></div>
                                      </StyledTableCell>
                                      : <></>
                                  }


                                  {/* <div className='delete-seller-store'><DeleteIcon/></div> */}
                                </StyledTableRow>


                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      </div>


                    </Box>
                  </Modal>}

                  {<Modal
                    open={deltaopen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">SELLER STORE DELTA</div>

                      <div className="message">
                        <img src={send} alt="closeMenu" />
                        <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                        <h6>Make sure before sending the email, since these codes are private for each seller.If you are not sure, please enter a secure email:Make sure before sending the email, since these codes are private for each seller.If you are not sure, please enter a secure email:</h6>
                      </div>

                      <div className="listOne">

                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                          >
                            New Email
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              className="textField"
                            />
                          </div>
                        </div>

                      </div>


                      <div className="filter-box account add seller-store-delta-email-btn">
                        <Button onClick={deltahandleOpenConfirm}>send by email,the passwords to the seller</Button>

                      </div>

                    </Box>
                  </Modal>}

                  {<Modal
                    open={deltaopenConfirm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={deltahandleCloseConfirm} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">SELLER STORE DELTA</div>

                      <div className="message">
                        <img src={send} alt="closeMenu" />
                        <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                      </div>




                      <div className="filter-box account add seller-store-delta-email-btn seller-store-delta-email-btn-ok">
                        <Button onClick={handleClose} >Ok</Button>
                        {/* <Button className='cancel' >Generate all password</Button> */}
                      </div>

                    </Box>
                  </Modal>}

                  {<Modal
                    open={createopen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">Add Staff </div>

                      <div className='no-of-staff-input'><label>Number of Staff(s):</label><div className="cmn-input-cls" style={{ marginBottom: "0px" }}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          className="textField"
                          type='number'
                          inputRef={count}
                          onChange={() => setcounterr(false)}
                        />
                      </div>
                        {counterr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{counterr}</div> : <></>}
                        <div className="filter-box account add seller-store-delta-email-btn" style={{ marginTop: "0px" }}>
                          {/* <Button onClick={createhandleClose} className='cancel'>Cancel</Button> */}
                          <Button onClick={() => { addStaff(id) }} style={{ gap: "0px", padding: "6px" }}>Add Staff</Button>
                        </div>
                      </div>

                      <div className="listOne">

                        <div className='SellerPaidTable'>
                          <TableContainer>
                            <Table aria-label="customized table">
                              <TableHead className='SellerTableHead'>
                                <TableRow className='SellerTableRow'>
                                  <StyledTableCell className="head-part-table">Staff Name</StyledTableCell>
                                  <StyledTableCell className="head-part-table">Staff ID</StyledTableCell>
                                  <StyledTableCell className="head-part-table">Password</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {tempstaff.length > 0 ? <TableBody className='SellerTableBody'>
                                {tempstaff && tempstaff.map((row, index) => (
                                  <StyledTableRow key={row._id}>
                                    <StyledTableCell className="table-value">{row?.staff?.staff_id}</StyledTableCell>
                                    <StyledTableCell className="table-value">{row?.staff?.staff_id}</StyledTableCell>
                                    <StyledTableCell className="table-value"><div className='seller-store-list'>{row?.staff?.password}</div></StyledTableCell>
                                    {/* <div className='delete-seller-store'><DeleteIcon/></div> */}
                                  </StyledTableRow>


                                ))}
                              </TableBody> : <TableBody className='SellerTableBody'>

                              </TableBody>}
                            </Table>
                          </TableContainer>

                        </div>
                      </div>

                      <div className="add-staff">
                        <div className='user-txt'>
                          <h4>User</h4>
                          <p>If you don't know the name of the staff, leave it blank and we will create a unique ID for that staff.</p>
                        </div>

                        <div className='user-txt'>
                          <h4>Password</h4>
                          <p>If you do not know what password to assign, leave the space blank and we will assign a random password for each staff</p>
                        </div>
                      </div>

                      <div className="filter-box account add seller-store-delta-email-btn">
                        {/* <Button onClick={createhandleClose} className='cancel'>Cancel</Button> */}
                        <Button onClick={openAddStaffEmail}>Generate user</Button>
                      </div>

                    </Box>
                  </Modal>}

                  {<Modal
                    open={addStaffEmail}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={closeAddStaffEmail} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">SELLER STORE DELTA</div>

                      <div className="message">
                        <img src={send} alt="closeMenu" />


                        <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                        <h6>Make sure before sending the email, since these codes are private for each seller.If you are not sure, please enter a secure email:Make sure before sending the email, since these codes are private for each seller.If you are not sure, please enter a secure email:</h6>
                      </div>

                      <div className="listOne">

                        <div className="productId">
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"

                          >
                            New Emails
                          </Typography>

                          <div className="cmn-input-cls">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              className="textField"
                              inputRef={email}
                              onChange={() => setemailerr(false)}
                              type="email"
                            />
                          </div>
                          {emailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}
                        </div>

                      </div>


                      <div className="filter-box account add seller-store-delta-email-btn">
                        <Button onClick={() => { sendMail() }}>send by email,the passwords to the seller</Button>
                        {/* <Button className='cancel' >Generate all password</Button>  ;openAddStaffEmailConfirm() */}
                      </div>

                    </Box>
                  </Modal>}

                  {<Modal
                    open={addStaffEmailConfirm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={closeAddStaffEmailConfirm} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">SELLER STORE DELTA</div>

                      <div className="message">
                        <img src={send} alt="closeMenu" />
                        <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                      </div>




                      <div className="filter-box account add seller-store-delta-email-btn seller-store-delta-email-btn-ok">
                        <Button onClick={handleClose} >Ok</Button>
                        {/* <Button className='cancel' >Generate all password</Button> */}
                      </div>

                    </Box>
                  </Modal>}

                  {<Modal
                    open={deleteSellerStoreList}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={closeDeleteSellerStoreList} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">Delete Staff</div>

                      <div className="listOne">

                        <div className='SellerPaidTable'>
                          <TableContainer>
                            <Table aria-label="customized table">
                              <TableHead className='SellerTableHead'>
                                <TableRow className='SellerTableRow'>
                                  <StyledTableCell className="head-part-table">Staff Name </StyledTableCell>
                                  <StyledTableCell className="head-part-table">Staff ID</StyledTableCell>
                                  <StyledTableCell className="head-part-table">Password</StyledTableCell>
                                  <StyledTableCell className="head-part-table"></StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody className='SellerTableBody'>
                                {staffs && staffs.map((staff, index) => (
                                  <StyledTableRow key={staff._id}>
                                    <StyledTableCell className="table-value">{staff?.name}</StyledTableCell>
                                    <StyledTableCell className="table-value">{staff?.StaffId}</StyledTableCell>
                                    <StyledTableCell className="table-value"><div className='seller-store-list'>{staff?.passwords}

                                    </div></StyledTableCell>
                                    <StyledTableCell className="table-value">
                                      <div className='delete-seller-store' onClick={() => { setStaffName(staff?.name); setstaffId(staff?._id); openDeleteStaffPopUp() }}><DeleteIcon /></div>
                                    </StyledTableCell>
                                    {/* <div className='delete-seller-store'><DeleteIcon/></div> */}
                                  </StyledTableRow>


                                ))}
                              </TableBody>

                            </Table>
                          </TableContainer>
                        </div>
                      </div>


                    </Box>
                  </Modal>}

                  {<Modal
                    open={deleteStaffPopUp}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={closeDeleteStaffPopUp} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">Seller Store Delta</div>


                      <div className="add-staff">

                        <div className='user-txt'>
                          <p>Are you sure to delete Staff ID {staffname} ?</p>
                        </div>
                      </div>

                      <div className="filter-box account add">
                        <Button className='cancel' onClick={() => { closeDeleteStaffPopUp() }}>Cancel</Button>
                        <Button onClick={() => { deleteStaff(); closeDeleteStaffPopUp() }}>Remove</Button>
                      </div>

                    </Box>
                  </Modal>}

                  {<Modal
                    open={deleteStaffPopUp}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX add-seller">

                      <div className="menu" onClick={closeDeleteStaffPopUp} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">Seller Store Delta</div>


                      <div className="add-staff">

                        <div className='user-txt'>
                          <p>Are you sure to Deacivate the Seller {staffname} ?</p>
                        </div>
                      </div>

                      <div className="filter-box account add">
                        <Button className='cancel' onClick={() => { closeDeleteStaffPopUp() }}>Cancel</Button>
                        <Button onClick={() => { deleteStaff(); closeDeleteStaffPopUp() }}>Remove</Button>
                      </div>

                    </Box>
                  </Modal>}

                  <div className='secound'>
                    <div className='progress-bar'>
                      <h4>Total stores and Total staff</h4>
                      <Box sx={{ flexGrow: 1 }}>
                        <div className='progress-txt'>
                          <p> Stores </p>
                          <p>{sellerlen ? sellerlen : ('0')}</p>
                        </div>
                        <BorderLinearProgress variant="determinate" value={sellerlen > 0 ? sellerlen : 0} />

                        <div className='progress-txt'>
                          <p>Staff</p>
                          <p>{stafflen ? stafflen : ('0')}</p>
                        </div>
                        <BorderLinearProgress variant="determinate" value={stafflen ? stafflen : 0} />
                      </Box>
                    </div>
                    {/* {
                      role === "admin" && <div className='seller-map'><SvgMap /></div>
                    } */}

                    <div className='seller-map'>
                      <Chart
                        chartEvents={[
                          {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                              const chart = chartWrapper.getChart();
                              const selection = chart.getSelection();
                              if (selection.length === 0) return;
                              const region = datass[selection[0].row + 1];
                            },
                          },
                        ]}
                        chartType="GeoChart"
                        width="100%"
                        height="400px"
                        data={datass}
                      />
                    </div>
                  </div>
                </Grid>
              </div>

              <Item className={classes.footercls}>
                <Footer />
              </Item>

            </div>

          </Grid>
        </Box>
      </div>
    )
  } else {
    return (
      <div className='sellerpage'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container className='containerBody  auth-body'>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.bodyclass}>
                <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                  <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                  <AdminMenuSide />
                </div>

              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='premission-access'>You Dont Have Permission to View Sellers</div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }


}

export default AdminSellersAuth
