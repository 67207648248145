import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button'
import "./UserloginNew.css"
// import profilebrand from '../../img/profilebrand.png'
import recover from "../../../img/revocer.png"
import Axios from '../../../Axios';
import consts from '../../../constants';
import { Toaster, toast } from 'react-hot-toast'
import CryptoJS from "crypto-js";


// const useStyles = makeStyles({

// });

const RecoverEmail = () => {
    // const classes = useStyles();
    const navigate = useNavigate()
    const history = useLocation();



    useEffect(() => {
        if (history?.state?.data === true) {
            console.log(history?.state?.data, "1");
            block()
        }
    }, [history?.state?.data])

    const block = () => {
        if (history.state.data === true) {
            console.log(history.state.data, "data-from product")
            toast.error('Admin Blocked You!!!')
        }
    }

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const email = useRef(null);
    const password = useRef(null);

    const [emailerr, setemailerr] = useState(null);
    const [passworderr, setpassworderr] = useState(null);

    const secretPass = 'DtRaCeRsEcrEtFrEe'

    const onSubmit = async () => {
        try {

            if (email.current.value === "") {
                setemailerr("Please Enter your Email Address");
            } else if (password.current.value === "") {
                setpassworderr("Please Enter your Password");
            } else {
                // const re = new RegExp('^[a-z]+@[a-z]+\.[a-z]{1,3}$')
                // re.test(email.current.value)

                window.localStorage.removeItem("AdminToken");

                const { data } = await Axios.post("/adminlogin", {
                    email: email.current.value,
                    password: password.current.value,
                });
                if (data?.success) {
                    // await Axios.get("/users/check2fa", {
                    //   headers: {
                    //     Authorization: data?.result?.token,
                    //   },
                    // })
                    //   .then((res) => {
                    //     return res.data.result
                    //       ? navigate(`${consts.route}/2FA`, {
                    //         state: { token: data?.result },
                    //       })
                    //       : (window.localStorage.setItem(
                    //         "Mellifluous",
                    //         data.result.token
                    //       ),

                    //         window.localStorage.setItem(
                    //           "users",
                    //           JSON.stringify(data.result.user)
                    //         ),
                    //         window.localStorage.setItem(
                    //           "kyc_verify", data.result.user.kyc_verify
                    //         ),
                    //         navigate(`${consts.route}/`)
                    //       );
                    //   })
                    //   .catch((err) => {
                    //     console.log(err.response.data.message, "err");
                    //   });
                    toast.success('LoggedIn Successfully')
                    window.localStorage.setItem(
                        "electrónico",
                        email.current.value
                    )
                    window.localStorage.setItem(
                        "AdminToken",
                        data.result.token
                    )
                    window.localStorage.setItem(
                        "Squelch",
                        data.result.admin.role
                    )
                    console.log("role", data.result.admin.role)
                    if ((data?.result?.admin?.role === 'admin') || (data?.result?.admin?.role === 'subAdmin')) {
                        if (data?.result?.admin?.role === 'subAdmin') {

                            const data1 = await Axios.post('/admin/getSubadminPermission', {},
                                {
                                    headers: {
                                        Authorization: data.result.token,
                                    },
                                }
                            )
                            if (data1?.data?.success && data1?.data?.result?.length > 0) {
                                const datas = CryptoJS.AES.encrypt(
                                    JSON.stringify(data1?.data?.result),
                                    secretPass
                                ).toString();
                                window.localStorage.setItem('isimo', datas)
                            } else {
                                // defaultStatus()
                            }
                            setTimeout(() => {
                                navigate(`${consts.route}/dashboard`)
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                navigate(`${consts.route}/dashboard`)
                            }, 2000);
                        }

                    } else if (data?.result?.admin?.role === 'user') {
                        const data = window.localStorage.getItem('camino')
                        if (data) {
                            setTimeout(() => {
                                navigate(`${consts.route}/user/productdetails/${data}`)
                                window.localStorage.removeItem('camino')
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                navigate(`${consts.route}/user/dashboard`)
                            }, 2000);
                        }

                    } else {
                        setTimeout(() => {
                            navigate(`${consts.route}/brand-/brand-dashboard`)
                        }, 2000);
                    }




                }
            }
        } catch (error) {
            console.log(error, "err")
            if (
                error?.response?.data?.message === "Invalid Email" ||
                error?.response?.data?.message === "Please Verify Email"
            ) {
                setemailerr(error?.response?.data?.message);
            }
            if (error?.response?.data?.message === "Wrong Password") {
                setpassworderr(error?.response?.data?.message);
            } else {
                setemailerr(error?.response?.data?.message);
            }
        }
    };

    useEffect(() => {
        console.log("logs")
        if (history?.state?.emailVerify === 0) {
            toast.success("Email Verified Successfully", {
                icon: '👏',
            })
        } else if (history?.state?.emailVerify === 1) {
            toast.error("Email Already Verified")
        } else if (history?.state?.login === 0) {
            toast.success("Please Login")
        }
    }, []);

    return (

        <div className='login-page1'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} sx={{ alignItems: "center" }}>


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='right-content'>

                        <div className="form-input1">
                            <div className='login-main-text'>
                                DTracer
                            </div>

                            {/* <div className='login-txt'>
                <h4>Hey! Buddy</h4>
                <h1>Welcome Back 🥳 </h1>
              </div> */}


                            <div className='recover-img'>
                                <img src={recover} alt='icon' />
                            </div>
                            <div className='recover-text1'>
                                Great, we have sent you an email with the instructions to recover your password
                            </div>
                            <div className='recover-text2'>
                                Check your inbox and if you continue to have problems please contact: Client@Dtracer.io
                            </div>

                            <div className='email-password-block'>
                                <div className='cmn-input-cls'>
                                    {/* <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email}
                    onChange={() => {
                      setemailerr(null);
                    }} />
                  {emailerr !== null ? (
                    <div style={{ color: "red", textAlign: 'center' }}>{emailerr}</div>
                  ) : (
                    <></>
                  )} */}

                                </div>

                                {/* <div className='crtPassword'><Link to={`${consts.route}/reset-password`}>Forgot Password ?</Link></div> */}
                            </div>

                            <Link to="/user/login"><Button className='submit' >Back To Login</Button></Link>

                            {/* <div className='did-have-account'>
                If you didn't have an account?<span><Link to="/register">Register Now</Link></span>
              </div> */}

                            {/* <div className='choice'>Or Used</div>

              <Button className='meta'><div className='btn-logo'><img src={btnlogo} alt="logo" /></div>Wallet Metamask</Button> */}
                            {/* <div className='diamond-img'><img src={diamond} alt="diamond"/></div> */}
                        </div>


                    </Grid>



                </Grid>
            </Box>


        </div>
    )
}

export default RecoverEmail;
