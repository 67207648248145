import React, { useState, useRef } from "react";
import "./AdminCustomer.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import Axios from "../../../Axios";

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 2,
  overflow: "scroll !important",
  maxHeight: "600px"
};

const CustomerFilter = ({ filterData, reset }) => {
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [users, setUsers] = useState()
  const name = useRef("");
  const email = useRef("");
  const country = useRef("");
  const contactmail = useRef("");
  const ondate = useRef("")
  const expiredate = useRef("")
  const [active, setActive] = useState()

  const filterBrands = async () => {

    try {
      const queryData = {
        brand_name: name.current.value,
        email: email.current.value,
        country: country.current.value,
        contact_email: contactmail.current.value,
        start_date: ondate.current.value,
        end_date: expiredate.current.value,
      }
      const { data } = await Axios.post('/admin/get_filtered_brand', queryData,
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        }
      )

      if (data?.success) {
        console.log("Data", data?.result)
        filterData(data?.result)
        handleClose()
      }

    } catch (error) {
      console.log(error, "err")

    }

  }
  const filterReset = async () => {

    handleClose()
    reset(true)
  }

  return (
    <Grid Container>
      <div  >
        <Button onClick={handleOpen} className="filterBtn">
          <SearchIcon />
          &nbsp; Filter
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="AdminCustomerpopBOX">
            <div className="filter-overflow">
              <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
              <div className="h2Variant">Filter</div>

              <div className="listOne">
                <div className="productId">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    brand name
                  </Typography>

                  <div className="cmn-input-cls">
                    <TextField
                      id="outlined-basic"
                      // label="Enter Your Amount "
                      variant="outlined"
                      className="textField"
                      inputRef={name}
                    />
                  </div>
                </div>

                <div className="productId">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    brand email
                  </Typography>

                  <div className="cmn-input-cls">
                    <TextField
                      id="outlined-basic"
                      // label="Brand mail"
                      variant="outlined"
                      className="textField"
                      type="email"
                      inputRef={email}
                    />
                  </div>
                </div>
              </div>

              <div className="listTwo">
                <div className="productId">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    contact email
                  </Typography>

                  <div className="cmn-input-cls">
                    <TextField
                      id="outlined-basic"
                      // label="Contact Mail"
                      variant="outlined"
                      className="textField"
                      type="email"
                      inputRef={contactmail}
                    />
                  </div>
                </div>
                <div className="productId">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    country
                  </Typography>

                  <div className="cmn-input-cls">
                    <TextField
                      id="outlined-basic"
                      // label="Enter Your Amount "
                      variant="outlined"
                      className="textField"
                      inputRef={country}
                    />
                  </div>
                </div>

              </div>

              <div className="listThree">
                {/* <div className="warn">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo"
                  >
                    Status

                  </Typography>
                  <div className="statusList"><Checkbox className="checkBox" />active</div>
                  <div className="statusList"><Checkbox className="checkBox" />Deactive</div>
                </div> */}
                <div className="start-end-date">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo renewal"
                    >
                      Next license renewal date
                    </Typography>
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"

                      >
                        Start
                      </Typography>

                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          // className="textField"

                          type="date"
                          inputRef={ondate}
                        />

                      </div>
                    </div>
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"

                      >
                        End
                      </Typography>

                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          // className="textField"

                          type="date"
                          inputRef={expiredate}
                        />

                      </div>
                    </div>
                  </LocalizationProvider>
                </div>

              </div>
              <div className="butOutLayer">
                <Button className='reset' onClick={() => filterReset()}>Reset filter</Button>
                <Button className='apply' onClick={() => filterBrands()}>Apply</Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </Grid>
  );
};

export default CustomerFilter;
