import React, { useState } from "react";
import "../AdminPostSales/AdminFilterPopup.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RefreshIcon from '@mui/icons-material/Refresh';
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import Axios from '../../../Axios'
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1207,
    // bgcolor: 'background.paper',
    border: "2px solid #000",
    p: 4,
};

const SearchPopup = ({ setqr_Status, setowner, setproduct_id, setCreationDate, setisFilter, reset, getS, apicall }) => {
    // const classes = useStyles();


    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setproduct_id()
        setowner()
        setqr_Status()
        setCreationDate([]); setOpen(true);
    }
    const handleClose = () => {

        setOpen(false)
    };
    const user = window.localStorage.getItem('AdminToken')
    const role = window.localStorage.getItem('Squelch')
    //   const [creationDate, setCreationDate] = useState([])
    //  const[product_id,setproduct_id]=useState()
    //  const[owner,setowner]=useState()
    //  const[qr_Status,setqr_Status]=useState()
    const handlecreationdatechange = (d, m, y) => {

        var credate = y + '-' + m + '-' + d

        setCreationDate(credate)



    }
    const getDetails = () => {
        setOpen(false)
        setisFilter(true)
    }
    //   const getDetails = async () => {
    //     try {
    //     setCounts(1)
    //       setMarkers([])
    //       setDetails([])
    //       setFocusLocation()
    //       if (role === 'admin') {
    //         const { data } = await Axios.post('/admin/adminTracability', {

    //         }, {
    //           headers: {
    //             Authorization: window.localStorage.getItem('AdminToken')
    //           }
    //         })
    //         if (data?.success === true) {
    //           if (data?.result?.length > 0) {
    //             setDetails(data?.result)
    //             const dts = data?.result
    //             var arr = []
    //             for (let i = 0; i < dts.length; i++) {
    //               const element = dts[i];

    //               if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
    //                 setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
    //                 var obj = {
    //                   geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
    //                   popUp: element?.product_details?.product_id
    //                 }
    //                 arr.push(obj)
    //               }
    //             }
    //             setMarkers(arr)
    //           }

    //         }
    //       } else if (role === 'brand') {
    //         const { data } = await Axios.post('/admin/brandTracability', {
    //           product_id:product_id,
    //           owner:owner,
    //           qr_Status:qr_Status,
    //           creation_date:creationDate,
    //           page: page - 1,
    //         limit: 5
    //         }, {
    //           headers: {
    //             Authorization: window.localStorage.getItem('AdminToken')
    //           }
    //         })
    //         if (data?.success === true) {

    //           if ((data?.resultBrand?.length > 0) && (data?.resultUser?.length > 0)){
    //             var tempArr = data?.resultBrand?.concat(data?.resultUser)
    //             setCounts(Math.round(data?.count / 10))
    //             setDetails(tempArr)
    //             // setDetails(data?.result)
    //             const dts = tempArr
    //             var arr = []

    //             for (let i = 0; i < dts.length; i++) {
    //               const element = dts[i];

    //               if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
    //                 setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
    //                 var obj = {
    //                   geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
    //                   popUp: element?.product_details?.product_id,
    //                   ProductName: element?.product_details?.Product_Name,
    //                   userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
    //                 }
    //                 arr.push(obj)
    //               }
    //             }
    //             setMarkers(arr)
    //           }
    //           else if (data?.resultBrand?.length > 0) {
    //             var tempArr = data?.resultBrand
    //             setCounts(Math.round(data?.count / 10))
    //             setDetails(tempArr)
    //             // setDetails(data?.result)
    //             const dts = tempArr
    //             var arr = []

    //             for (let i = 0; i < dts.length; i++) {
    //               const element = dts[i];

    //               if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
    //                 setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
    //                 var obj = {
    //                   geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
    //                   popUp: element?.product_details?.product_id,
    //                   ProductName: element?.product_details?.Product_Name,
    //                   userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
    //                 }
    //                 arr.push(obj)
    //               }
    //             }
    //             setMarkers(arr)
    //           }
    //           else if (data?.resultUser?.length > 0) {
    //             var tempArr = data?.resultUser
    //             setCounts(Math.round(data?.count / 10))
    //             setDetails(tempArr)
    //             // setDetails(data?.result)
    //             const dts = tempArr
    //             var arr = []

    //             for (let i = 0; i < dts.length; i++) {
    //               const element = dts[i];

    //               if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
    //                 setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
    //                 var obj = {
    //                   geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
    //                   popUp: element?.product_details?.product_id,
    //                   ProductName: element?.product_details?.Product_Name,
    //                   userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
    //                 }
    //                 arr.push(obj)
    //               }
    //             }
    //             setMarkers(arr)
    //           }
    // handleClose()
    //         }
    //       }

    //     } catch (error) {
    //       console.log("🚀 ~ file: AdminTraceability1.js:184 ~ getPosition ~ error:", error)
    //     }
    //   }

    return (
        <div className="adminFilterPopup">
            <Button onClick={handleOpen} className="filterBtn">
                <SearchIcon />
                &nbsp; Search
            </Button>



            {/* <Button onClick={()=>{reset();setisFilter(false)}} className="filter-colors">
            {<RefreshIcon color='#fff' />}
               Filter Reset
            </Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style} className="popBOX adminFilterPopup adminFilterPopup-postsale trace">
                    <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                    <div className="listOne1">
                        <div className="listThree2">

                            <div className="start-end-date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Typography
                                        id="modal-modal-title"
                                        variant="h6"
                                        component="h2"
                                        className="modelTypo"
                                    >
                                        Date range
                                    </Typography>
                                    <DemoContainer
                                        components={[
                                            "DatePicker",
                                            "DateTimePicker",
                                            "TimePicker",
                                            "DateRangePicker",
                                        ]}
                                    >
                                        <DemoItem>
                                            <DatePicker

                                                onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}
                                                views={["year", "month", "day"]}
                                            />
                                        </DemoItem>


                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>

                        </div>


                        <div className="listTwo">
                            <div className="productId">
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    className="modelTypo"
                                >
                                    Product ID
                                </Typography>

                                <div className="cmn-input-cls">
                                    <TextField
                                        id="outlined-basic"
                                        label="Please Enter Product ID "
                                        variant="outlined"
                                        className="textField"
                                        onChange={(e) => setproduct_id(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="productId">
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    className="modelTypo"
                                >
                                    Owner
                                </Typography>

                                <div className="cmn-input-cls">
                                    <TextField
                                        id="outlined-basic"
                                        label="Please Enter  Owner Name "
                                        variant="outlined"
                                        className="textField"
                                        onChange={(e) => setowner(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>



                    </div>






                    <div className="wholeButton">
                        <Button className='apply' onClick={() => { getDetails(); getS(); apicall(true) }}>Search</Button>

                    </div>
                </Box>
            </Modal>
        </div >
    );
};

export default SearchPopup;
