import React, { useState,useEffect } from "react";
import "./AdminProducts.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import filter from '../../../img/filter.svg';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Axios from "../../../Axios";

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
  overflow: "scroll",
  maxHeight: "600px"
};

const CustomerFilter = ({ filterPro, reset, loc,setIsfilter,isfilter,setDatas,page,setPage}) => {
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  }
  const [brand_name, setBrand_name] = useState()
  const [product_name, setProduct_name] = useState()
  const [productid, setProductid] = useState()
  const [ean, setEan] = useState()
  const [category, setCategory] = useState()
  const [pending, setPending] = useState(false)
  const [approve, setApprove] = useState()
  const [locked, setLocked] = useState()
  const [creation_date, setCreation] = useState([])
  const [payment_date, setPayment] = useState([])


  const filterApi = async () => {
    setIsfilter(true)
    const datas = {
      brand_name: brand_name,
      product_name: product_name,
      ean: ean,
      category: category,
      pending: pending,
      approved: approve,
      locked: locked,
      brand_id: loc,
      start_date: creation_date,
      end_date: payment_date,
      productid: productid,
      limit:10,
      page:page
    }
    const { data } = await Axios.post(`/users/filterProduct`, datas,
      {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      })
    handleClose()
    filterPro(data?.result,data?.response1.length)
    console.log(data?.response1.length,"datas",data?.result.length,data?.result)
    setDatas(data?.result)
  }

useEffect(()=>{
  console.log("pagedatas",page)
  if(isfilter === true){
    filterApi()
  }
  
},[page])
  const handlecreationdatechange = (d, m, y) => {

    var credate = y + '-' + m + '-' + d

    setCreation(credate)

  }

  const handlepaymentdatechange = (d, m, y) => {

    var payDate = y + '-' + m + '-' + d
    setPayment(payDate)

  }

  const ResetFilter = async () => {
    setBrand_name('')
    setProduct_name('')
    setEan('')
    setCategory('')
    setPending(false)
    setApprove(false)
    setLocked(false)
    handleClose()
    reset(true)
    setIsfilter(false)
    setPage(1)
  }


  return (
    <div >
      <Button onClick={handleOpen} className="filterBtn" variant="contained" >Filter &nbsp;<img src={filter} /></Button>
      <Button className='filter-color' onClick={() => { ResetFilter() }}>{<RefreshIcon color='primary' />}Filter Reset</Button>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="AdminCustomerpopBOX AdminCustomerpopBOX-product-filter" >
          <div className="filter-overflow">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            <div className="h2Variant">Product filter</div>

            <div className="listOne">

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  brand name
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Enter Your Amount "
                    variant="outlined"
                    className="textField"
                    value={brand_name}
                    onChange={(e) => { setPage(1);setBrand_name(e.target.value) }}
                  />
                </div>
              </div>

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                internal ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Brand mail"
                  variant="outlined"
                  className="textField"
                  type="email"
                />
              </div>
            </div> */}

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                customer ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div> */}

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  product name
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Brand mail"
                    variant="outlined"
                    className="textField"
                    value={product_name}
                    onChange={(e) => { setPage(1);setProduct_name(e.target.value) }}

                  />
                </div>
              </div>

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Product ID
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Brand mail"
                    variant="outlined"
                    className="textField"
                    value={productid}
                    onChange={(e) => { setPage(1);setProductid(e.target.value) }}

                  />
                </div>
              </div>

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  ean
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Contact Mail"
                    variant="outlined"
                    className="textField"
                    value={ean}
                    onChange={(e) => { setPage(1);setEan(e.target.value) }}
                  />
                </div>
              </div>

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  category
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Enter Your Amount "
                    variant="outlined"
                    className="textField"
                    value={category}
                    onChange={(e) => { setPage(1);setCategory(e.target.value) }}
                  />
                </div>
              </div>

            </div>

            <div className="listTwo">

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                ean
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Contact Mail"
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setEan(e.target.value) }}
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                category
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                  onChange={(e) => { setCategory(e.target.value) }}
                />
              </div>
            </div> */}

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                client  id
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div> */}

              <div className="productId start-end-date">

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="modelTypo renewal"
                  >
                    creation date
                  </Typography>
                  <DemoContainer
                    components={[
                      "DatePicker",
                      "DateTimePicker",
                      "TimePicker",
                      "DateRangePicker",
                    ]}
                  >
                    <DemoItem>
                      <DatePicker
                        onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}
                        views={["year", "month", "day"]}
                      />
                    </DemoItem>

                    <DemoItem>
                      <DatePicker
                        onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }}


                        views={["year", "month", "day"]}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>

              </div>

            </div>

            <div className="listThree">
              <div className="warn">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Status

                </Typography>
                <div className="statusList-outer-list-flex">
                  <div className="statusList"><Checkbox className="checkBox" onChange={() => { setPending(true) }} checked={pending} />pending approval</div>
                  <div className="statusList"><Checkbox className="checkBox" onChange={() => { setApprove(true) }} checked={approve} />approved</div>
                  <div className="statusList"><Checkbox className="checkBox" onChange={() => { setLocked(true) }} checked={locked} />locked</div>
                </div>
              </div>
              <div className="start-end-date">

              </div>

            </div>
            <div className="butOutLayer">
              <Button className='reset' onClick={() => { ResetFilter() }} >Reset filter</Button>
              <Button className='apply' onClick={() => { filterApi() }} >Apply</Button>
            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default CustomerFilter;
