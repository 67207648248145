import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const DashboardLineChart = ({ this_Week, last_Week }) => {

  const [chartdata, setChartData] = useState({

    series: [
      {
        name: "Last Week",
        data: last_Week
      },
      {
        name: 'This Week',
        data: this_Week
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true
        },
        toolbar: {
          show: true,
        },

        id: "Post_Sale",
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'smooth',
        dashArray: [8, 8]
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '$'
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + '$'
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val + '$'
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val + '$';
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    },


  });
  useEffect(() => {
    setChartData({

      series: [
        {
          name: "Last Week",
          data: last_Week
        },
        {
          name: 'This Week',
          data: this_Week
        }
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
          id: "Post_Sale",
        },
        title: {
          text: "Blockchain Transactions Daily Costs",
          align: "left",
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'smooth',
          dashArray: [8, 8]
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '$'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + ' $'
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val + ' $'
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val + ' $';
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },


    })
  }, [last_Week, this_Week])

  return (<div id="chart">
    <ReactApexChart options={chartdata.options} series={chartdata.series} type="line" height={350} />
  </div>


  );

}

export default DashboardLineChart;