import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './AdminTraceability.css';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import meta from '../../../img/MetaMask.png';
import { Link, useLocation } from 'react-router-dom'
import locationMark from '../../../img/locationMark.png';
import Pagination from '@mui/material/Pagination';
import actionLocation from '../../../img/actionLocation.svg'
import ProductImage from "../../../img/thumbLogo.svg";
import downloadIcon from "../../../img/downloadIcon.svg";
import group from "../../../img/group.svg";
import AdminMenuSide from '../AdminMenuSide';
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import Avatar from '@mui/material/Avatar';
import profileicoon from "../../../img/profile-icom-static.png"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import poly from "../../../img/polygon-icon.png"
import ShowChartTwoToneIcon from '@mui/icons-material/ShowChartTwoTone';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import card from "../../../img/atmcard.png"
import asset from "../../../img/asset-wallet.png"
import close from "../../../img/cancel.png"
import Web3 from 'web3';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },

    reasonBlock: {
        fontSize: '24px',
        fontWeight: '600'
    }
});

function createData(
    id,
    startingPoint,
    lastMove,
    action,
) {
    return { id, startingPoint, lastMove, action };
}

const rows = [
    createData('ADD-11234-11', '120 Av. Washiton st , USA', 'C/ MADRID 122, ESP'),
    createData('ADD-11234-11', '120 Av. Washiton st , USA', 'C/ MADRID 122, ESP'),

];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const WalletPage2 = () => {
    var web3 = new Web3(consts.polygon_url)
    const navigate = useNavigate()
    const location = useLocation()

    const user = window.localStorage.getItem('AdminToken')
    const role = window.localStorage.getItem('Squelch')

    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("success")
    if (type === "true") {
        console.log("trussss")
        queryParameters.delete("success")
        toast.success("Deposited Successfully")
    } else if (type === "false") {
        console.log("falseee")
        queryParameters.delete("success")
        toast.success("Deposited Cancelled")
    }

    const [address, setAddress] = useState(null)
    const [balance, setBalance] = useState(null)
    const [payment, setPayment] = useState(false)
    const handlepayment = () => {
        setPayment(!payment)
    }
    // useEffect(() => {
    //     console.log(location?.state?.data, "asdfsadf")
    // }, [location?.state?.data])

    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        profile()
    }, [])

    const profile = async () => {
        try {
            if (role === 'brand') {
                setFetching(true)
                const { data } = await Axios.post(`/users/getWalletDetails`, {}, {
                    headers: {
                        Authorization: user
                    }
                })
                if (data?.success === true) {
                    setAddress(data?.result.address)
                    var bal = await web3.eth.getBalance(data?.result.address)
                    var balance = await web3.utils.fromWei(bal, 'ether')
                    setBalance(balance)
                }
                setFetching(false)
            } else {
                setFetching(true)
                const { data } = await Axios.post(`/users/getWalletDetails`, {}, {
                    headers: {
                        Authorization: user
                    }
                })
                console.log(data.result, "datass")
                if (data?.success === true) {
                    setAddress(data?.result.address)
                    var bal = await web3.eth.getBalance(data?.result.address)
                    var balance = await web3.utils.fromWei(bal, 'ether')
                    setBalance(balance)
                }
                setFetching(false)
            }
        } catch (error) {
            console.log("🚀 ~ file: walletPage.js:134 ~ profile ~ error:", error)
        }

    }

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const [value, setValue] = React.useState(0);

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };

    const [Amount, setAmount] = useState('')
    const [Amounterr, setAmounterr] = useState("")

    const rechargeWallet = async () => {
        console.log(Amount, "amount")
        try {
            if (Amount === "") {
                setAmounterr("Please Enter Amount")
            } else {
                const { data } = await Axios.post(`/wallet/rechargeWallet`, {
                    amount: Amount,
                    address: address
                }, {
                    headers: { Authorization: window.localStorage.getItem("AdminToken") }
                })
                console.log(data, "amount")
                if (data?.success === true) {
                    window.location.href = data?.result?.url
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: WalletPage2.js:249 ~ rechargeWal ~ error:", error)

        }
    }

    return (

        <div className='TrabiliDadMainPage adminTraceability-main-page'>

            <Box sx={{ flexGrow: 1 }}>

                <Grid container>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />
                            </div>

                        </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='Title select-a-product-head'>

                        <div className='leftFirstButton'>
                            {/* <div className='filter-box'><PostSalesFilterPopup /></div> */}
                            {/* <Button className="filterBtn">
                <SearchIcon />
                &nbsp; Search
              </Button> */}

                            {/* <div className='topTitle'>select a product</div> */}

                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='select-a-product-location'>


                        {/* <div className='graphLine'>
              <div className='dashboargraph-inner-mid'>
                <div className='dashboargraph-inner-mid-right'>
                  <iframe src="https://www.google.com/maps/d/embed?mid=1hjs3mIoZBblBP_CvxiP4w38STiY&hl=en_US&ehbc=2E312F" width="100%" height="400" />

                </div>
              </div>

            </div> */}
                        <div className='wallet-page2'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className='wallet-close-icon'>
                                        <img src={close} onClick={() => { navigate('/wallet') }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='wallet-whole-div'>

                                        <div className='accountsection-1'>

                                            <div className='account-text'>
                                                <strong>Account</strong>
                                                <p>{address}<span style={{ cursor: 'pointer', marginLeft: "2px" }} onClick={() => { navigator.clipboard.writeText(address); toast.success('Successfully Copied!!') }}><FileCopyIcon /></span></p>
                                            </div>

                                        </div>
                                        <div className='accountsection-2'>
                                            <div className='metamask-logo'>
                                                <img src={poly} alt="icon" />
                                            </div>
                                            <div className='text'>
                                                {balance} MATIC<span><ShowChartTwoToneIcon /></span>
                                                {/* <p>14.68888 USD</p> */}
                                            </div>
                                        </div>


                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='wallet-whole-div'>
                                        <div className='asset-img'>
                                            <img src={asset} alt='asset' />
                                        </div>
                                        {payment ? (<div className='payment-success-text'>
                                            <h5>Ticket payment Successful</h5>
                                            <p>Transaction Number : 123456789</p>
                                            <hr />
                                            <div>
                                                Amount Paid <span>$45.25</span>
                                            </div>
                                            <div>
                                                Payed by DTRACER
                                            </div>
                                            <div className='wallet2-btn'>
                                                <Button onClick={handlepayment}>
                                                    Return
                                                </Button>
                                            </div>
                                        </div>) : (<>
                                            <div className='select-amount'>
                                                Select the amount
                                            </div>
                                            <div className='select-amount-div'>
                                                <div onClick={() => { setAmount("50"); setAmounterr("") }} >
                                                    $50
                                                </div >
                                                <div onClick={() => { setAmount("100"); setAmounterr("") }} >
                                                    $100
                                                </div>
                                                <div onClick={() => { setAmount("200"); setAmounterr("") }} >
                                                    $200
                                                </div>
                                            </div>
                                            <div className='input-form'>
                                                <input placeholder='Enter the Amount' value={Amount} onChange={(e) => { setAmount(e.target.value); setAmounterr("") }} />
                                                {Amounterr !== "" ? <div style={{ color: 'red' }}>{Amounterr}</div> : <></>}
                                            </div>
                                            <div className='wallet-trans-card'>
                                                <img src={card} alt="card" />
                                            </div>
                                            <div className='wallet2-btn'>
                                                {/* <Button onClick={handlepayment}> */}
                                                <Button onClick={() => { rechargeWallet() }}>
                                                    Proceed Transaction
                                                </Button>
                                            </div>
                                        </>)}

                                    </div>
                                    {/* <div className='payment-success-div'>
                                                    <div className='asset-img'>
                                                        <img src={asset} alt='asset' />
                                                    </div>

                                                </div> */}
                                </Grid>
                            </Grid>


                            {/* <div className='wallet-page-btn'>
                    <Button>Recharge Wallet</Button>
                    <Button>Withdrawals</Button>
                  </div> */}
                        </div>

                    </Grid>


                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='select-a-product-location'>
            <div className='InvoicePaidTable'>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className='invoiceTableHead'>
                  <TableRow className='invoiceTableRow'>
                    <StyledTableCell className="head-part-table">S.No</StyledTableCell>
                    <StyledTableCell className="head-part-table">Transaction Id</StyledTableCell>
                    <StyledTableCell className="head-part-table">From</StyledTableCell>
                    <StyledTableCell className="head-part-table">To</StyledTableCell>
                    <StyledTableCell className="head-part-table">Status</StyledTableCell>
                    <StyledTableCell className="head-part-table">action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='invoiceTableBody'>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell className="table-value"><img src={locationMark} alt={locationMark} /></StyledTableCell>
                      <StyledTableCell component="th" scope="row" className="table-value"><img src={ProductImage} alt={ProductImage} /></StyledTableCell>
                      <StyledTableCell className="table-value">{row.id}</StyledTableCell>
                      <StyledTableCell className="table-value">{row.startingPoint}</StyledTableCell>
                      <StyledTableCell className="table-value">{row.lastMove}</StyledTableCell>
                      <StyledTableCell className="table-value">
                        <div className='icon-space'>
                          <img src={actionLocation} alt={actionLocation} />
                          <img src={downloadIcon} alt={downloadIcon} />
                          <img src={group} alt={group} />
                        </div>

                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div className='tablebody'>
              <div className='pagination-numb'>
                <Pagination count={9} color="primary" shape="rounded" />

              </div>

            </div>
          </Grid> */}


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}

export default WalletPage2;
