import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import '../Admin/AdminDashboard/AdminDashboard.css'
import togglebtn from '../../img/togglebtn.png'
import Menuclose from '../../img/Menuclose.png'
import { Toaster, toast } from 'react-hot-toast'
import UserDashboardBody from './userDashboardBody';
import AdminMenuSide from '../Admin/AdminMenuSide';
import Axios from '../../Axios';
// import consts from '../../../constants';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
});

const AdminDashboard = () => {

  const classes = useStyles();

  const urlParams = new URLSearchParams(window.location.search);
  const notificationMessage = urlParams.get('success');


  useEffect(() => {

    if (notificationMessage === "true") {
      // Display the notification message
      toast.success("Certificate Send to your email");
      urlParams.delete('success');
    }
    else if (notificationMessage === "false") {
      toast.error("Cant send to certificate");
      urlParams.delete('success');
    }
    else if (notificationMessage === "false1") {
      toast.error("Something Went Wrong");
      urlParams.delete('success');
    }
    else if (notificationMessage === "true1") {
      toast.success("Mail send to Buyer");
      urlParams.delete('success');
    }
  }, [notificationMessage])

  const [menuOpen, setMenuOpen] = useState(true)
  const [nftdat, setNftdat] = useState([])

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }



  const nfts = async () => {
    var token = window.localStorage.getItem('AdminToken')
    console.log(token, "adgf");
    const { data } = await Axios.post('/users/productDetailById', {},
      {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      }
    )
    console.log(data, "dataavdjvb");
    if (data.success === true) {
      setNftdat(data?.result)
    } else {
      setNftdat([])
    }
  }

  useEffect(() => {
    nfts()
  }, [])

  return (
    <div className='dashboard-page'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
              <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                <AdminMenuSide />
              </div>



            </Item>
            <div className='dashbody' onClick={closeMenuHandler}>
              {
                nftdat?.length > 0 ?
                  <UserDashboardBody nftdat={nftdat} />
                  :
                  <div className='notFound' >No Product Available</div>
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.footercls}>
              <Footer />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default AdminDashboard
