
import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const DashboardLineChart = ({ this_Week, dates }) => {

  const [state, setState] = useState({
    series: this_Week,
    options: {
      chart: {
        id: 'Count',
        height: 350,

        type: 'line',
        zoom: {
          enabled: true
        },
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'smooth',
        dashArray: [8, 8]
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val
              }
            }
          },
          {
            title: {
              formatter: function (val) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#f1f1f1',
      }
    },


  }
  )

  useEffect(() => {
    setState({
      series: this_Week,
      options: {
        chart: {
          id: 'Count',
          height: 350,
          type: 'line',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'smooth',
          dashArray: [8, 8]
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: dates
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },


    })
  }, [this_Week])

  return (
    <div id="chart">
      <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
    </div>


  );
}

export default DashboardLineChart;