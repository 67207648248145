import React, { useState,useRef } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import PaidPop from './PaidPop'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import Axios from "../../../Axios";
import { Toaster, toast } from 'react-hot-toast'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

const useStyles = makeStyles({

  modalpara: {
    fontSize: '18px',
    textAlign: 'center',
    maxWidth: '750px !important',
    margin: '0 auto',
    paddingBottom: '80px',
    '@media (max-width: 767.98px)': {
      paddingBottom: '36px',
    },

  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600',
    margin: '0'
  }

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CancelInvoice = ({ row, status }) => {
  const classes = useStyles();
  const reasontext = useRef(null)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); setReason([]) }
  const [checked, setChecked] = useState(false);
  const [istext, setIstext] = useState(true)
  const [otherchecked, setOtherchecked] = useState(false);
  const [reason, setReason] = useState([])
  const [checkerr, setCheckerr] = useState(false)
  const [checkboxes, setCheckboxes] = useState([
    { label: 'The invoice could not be collected', checked: false, value: 'The invoice could not be collected' },
    { label: 'Invoice creation error', checked: false, value: 'Invoice creation error' },
    { label: 'Others', checked: false, value: 'Others' },
  ]);
  const [checkedIndex, setCheckedIndex] = useState(-1);
  const handleChanges = (index) => (event) => {

    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = event.target.checked;
    setCheckboxes(newCheckboxes);
    setCheckerr(false)
    setChecked(true)
    if (newCheckboxes[index].value === 'Others') {
      if (newCheckboxes[index].checked === true) {
        setIstext(false)
        setReason([reasontext?.current?.value])
      } else {
        setIstext(true)
      }
    } else if((event.target.checked)) {
      setIstext(true)
      setReason([...reason,newCheckboxes[index].value])
      console.log(newCheckboxes[index].value,"sadsd")
    }
    else{
      // If checkbox is unchecked, remove the value from the array
      setIstext(true);
      const updatedReason = reason.filter(item => item !== newCheckboxes[index].value);
      setReason(updatedReason);
      console.log(newCheckboxes[index].value, "removed from array");
    }
    if ((event.target.checked) && (newCheckboxes[index].value === 'Others')) {
      setCheckedIndex(index);
    } else {
      setCheckedIndex(-1);
      setChecked(false)
      
    }
  };

  // const setResons = (data) => {
  //   const dt = reason
  //   const check = dt.includes(data)
  //   if (check === false) {
  //     setReason([...reason, data])
  //   } else {
  //     setReason(reason.filter(item => item !== data))
  //   }
  // }

  // const setReasontext = (data) => {
  //   console.log(data,"reasons")
  //    setReason([data])
  // }
  const submit = async () => {
    try {
      console.log(reason,"==")
      const { data } = await Axios.post(`/admin/decline_billing`, {
        invoice_id: row?._id,
        reason: reason
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        toast.success(data?.message)
        handleClose()
        status()
        setReason([])
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error?.response?.data?.message)
      }
    }

  }

  return (
    <div className="qr-detail cancel-invoice">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {/* <Button onClick={handleOpen} variant="contained"> Request Block</Button> */}

      <Button onClick={handleOpen}  ><DoDisturbAltOutlinedIcon /></Button>

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup cancel-invoice">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


          <div className="invoice-create">
            <p className="new-invoice">Cancel invoice</p>
            <p>Please enter the reason why you want to cancel the invoice ID XXXX</p>
          </div>

          <div className="invoice-create">
            <p className="new-invoice">Error type</p>

            <div className="check-box-align">
            {checkboxes.map((checkbox, index) => (
                <p key={index}>
                  <Checkbox
                    checked={checkbox.checked}
                    onChange={handleChanges(index)}
                    color="primary"
                    inputProps={{ 'aria-label': checkbox.label }}
                    disabled={checkedIndex !== -1 && checkedIndex !== index}
                  />
                  <label>{checkbox.label}</label>
                </p>
              ))}
        
            </div>
            {istext === false ?
            <TextField id="outlined-password-input" type="text" className="field-txt-box" inputRef={reasontext}
                disabled={istext}
                onChange={(e) => { setReason([e.target.value]); setCheckerr(true) }} />
                :
                <></>}
          </div>

          <div className="create-cancel-btn invoice-reject">

            <div className="create-btn">
              <Button className="button-cls" onClick={() => { submit() }} >Invoice Cancel</Button>

            </div>
            <div className="create-btn">
              <Button className="cancel-green-btn" onClick={() => { handleClose() }} >Cancel</Button>

            </div>

          </div>
        </Box>
      </Modal>
    </div>



  );
};

export default CancelInvoice;
