import React, { useRef, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import "../Login/Login.css"
// import profilebrand from '../../img/profilebrand.png'
import profilebrand from '../../img/Group 28.png'
import Axios from '../../Axios';
import { Toaster, toast } from 'react-hot-toast'

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   boxShadow: 'none !important',
// }));




const Register = () => {

  // const [showPassword, setShowPassword] = React.useState(false);
  // const [showPassword1, setShowPassword1] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const navigate = useNavigate()
  const email = useRef(null);
  const firstname = useRef(null);
  // const lastname = useRef(null);
  const phone_no = useRef(null);
  const address = useRef(null);
  const city = useRef(null);
  const state = useRef(null);
  const country = useRef(null);
  const inpassref = useRef(null);
  const Company = useRef(null);
  const selectedValueRef = useRef('user');
  const [emailerr, setemailerr] = useState(null)
  const [usernameerr, setusernameerr] = useState(null)
  // const [usernameerr1, setusernameerr1] = useState(null)
  const [passworderr, setpassworderr] = useState(null)
  const [phonerr, setPhonerr] = useState(null)
  const [addressErr, setAddressErr] = useState(null)
  const [cityErr, setCityErr] = useState(null)
  const [stateErr, setstateErr] = useState(null)
  const [countryErr, setcountryErr] = useState(null)
  const [comperr, setComperr] = useState(null)
  // const [mail, setMail] = useState("")
  const [isActive, setIsActive] = useState(true);
  const redirectPath = '/'
  const timeoutSeconds = 2 * 60 * 1000

  useEffect(() => {
    screenInactive()
  }, [timeoutSeconds, isActive, navigate, redirectPath]);

  const screenInactive = () => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      if (isActive) {
        timeoutId = setTimeout(() => {
          navigate(redirectPath);
        }, timeoutSeconds);
      }
    };

    const handleMouseActivity = () => {
      setIsActive(true);
      resetTimeout();
    };
    resetTimeout();

    window.addEventListener('mousemove', handleMouseActivity);
    window.addEventListener('keydown', handleMouseActivity);


    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleMouseActivity);
      window.removeEventListener('keydown', handleMouseActivity);
    };
  }
  // const handleChange = (event) => {

  //   selectedValueRef.current = event.target.value;
  // };
  function verifyemail() {
    // setemailerr(null)
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.current.value)) {
      setemailerr("Please Enter valid email")
    } else if (email.current.value === "") {
      setemailerr("Please Enter Email")
    } else {
      setemailerr("")
    }
  }
  const phoneChange = () => {
    let reg = /^[0-9]+$/;
    if (phone_no.current.value === "") {
      setPhonerr("Please Phone Number")
    }
    else if (!(reg.test(phone_no.current.value))) {
      setPhonerr("Please Enter Valid Phone Number")
    }
    else {
      setPhonerr("")
    }
  }
  // const dateChange = () => {
  //   var dateReg = /^\d[0-31]{1}([./-])\d{2}\1\d{4}$/
  //   if (lastname.current.value === "") {
  //     setusernameerr1("Please DOB")
  //   }
  //   else if (!(dateReg.test(lastname.current.value))) {
  //     setusernameerr1("Please Enter Valid DOB Format (DD-MM-YYYY)")
  //   }
  //   else {
  //     setusernameerr1("")
  //   }
  // }
  const handleSuccessRegister = async () => {
    try {
      var dateReg = /^\d[0-31]{1}([./-])\d{2}\1\d{4}$/
      let reg = /^[0-9]+$/;

      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // console.log(name.current.value, email.current.value, inputconfrimRef.current.value, inpassref.current.value, passworderr, cpassworderr, usernameerr, emailerr, confirmPass, "redfs")
      // if (name.current.value !== "" && email.current.value !== "" && inputconfrimRef.current.value !== "" && inpassref.current.value !== ""
      //   && passworderr === null && cpassworderr === null && usernameerr === null && emailerr === null && confirmPass === null)


      if (firstname.current.value === "") {
        setusernameerr("Please Enter Name");
      }
      else if (phone_no.current.value === "") {
        setPhonerr("Please Phone Number")
      }
      else if (!(reg.test(phone_no.current.value))) {
        setPhonerr("Please Enter Valid Phone Number")
      }
      // else if (lastname.current.value === "") {
      //   setusernameerr1("Please Enter DOB")
      // }
      else if (Company.current.value === "") {
        setComperr("Please Enter Company Name")
      }
      else if (email.current.value === "") {
        setemailerr("Please Enter Email")
      }
      else if (!re.test(email.current.value)) {
        setemailerr("Please Enter valid email")
      }
      else if (address.current.value === "") {
        setAddressErr("Please Enter Address");
      }
      else if (city.current.value === "") {
        setCityErr("Please Enter City");
      }
      else if (state.current.value === "") {
        setstateErr("Please Enter State");
      }
      else if (country.current.value === "") {
        setcountryErr("Please Enter Country");
      }

      // else if (!(dateReg.test(lastname.current.value))) {
      //   setusernameerr1("Please Enter Valid DOB Format (DD-MM-YYYY)")
      // }
      else if (inpassref.current.value === "") {
        setpassworderr("Please Enter Postal Code")
      }

      else {
        sendMail()
      }
    } catch (error) {
      console.log(error, "err")
      if (error?.response?.data?.message === "Email Already Exists") {
        setemailerr(error?.response?.data?.message)
      } else {
        toast.error(error?.response?.data?.message)
      }
    }
  }
  const sendMail = async () => {
    try {
      const payload = {
        email: email.current.value,
        Brand_Name: firstname.current.value,
        phone: phone_no.current.value,
        Address: address.current.value,
        City: city.current.value,
        State: state.current.value,
        Country: country.current.value,
        Company: Company.current.value,
        // DOB: lastname.current.value,
        PostalCode: inpassref.current.value,
      }

      const data = await Axios.post('/users/brandMailSend', payload)
      toast.success('Thank you for your enquiry, a member of the Dtracer team will get back to you as soon as possible')

      setTimeout(() => {
        navigate('/')
      }, 1000)

      // if (data?.success && data?.result?.length > 0) {
      // setStatusCheck(data?.result)
      // } else {
      // defaultStatus()
      // }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.log("🚀 ~ file: SubAdminList.js:266 ~ getCheckStatus ~ error:", error)
    }
  }


  return (
    <div className='login-page register'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
            <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
            <p>We Protect your brand 100% with blockchain technology</p>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>
            <div className="form-input">
              <div className='login-txt'>
                <h4>Brand Enquiry Form</h4>
                <h6>To Enquire,Fill The Below Form</h6>
                {/* <h1>Welcome 🥳 </h1> */}
              </div>

              <div className='email-password-block'>
                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Name" variant="outlined" className='textField' inputRef={firstname} onChange={() => { setusernameerr(null) }} />
                  {usernameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{usernameerr}</div> : <></>}
                </div>

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Phone" variant="outlined" className='textField' inputRef={phone_no} onChange={() => { phoneChange() }} />
                  {phonerr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{phonerr}</div> : <></>}
                </div>

                {/* <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="DOB" variant="outlined" className='textField' inputRef={lastname} onChange={() => { dateChange() }} />
                  {usernameerr1 ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{usernameerr1}</div> : <></>}
                </div> */}

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email} onChange={verifyemail} />

                  {emailerr !== null ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{emailerr}</div> : <></>}
                </div>

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Company Name" variant="outlined" className='textField' inputRef={Company} onChange={() => { setComperr(null) }} />
                  {comperr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{comperr}</div> : <></>}
                </div>



                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Address" variant="outlined" className='textField' inputRef={address} onChange={() => { setAddressErr(null) }} />
                  {addressErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{addressErr}</div> : <></>}
                </div>

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="City" variant="outlined" className='textField' inputRef={city} onChange={() => { setCityErr(null) }} />
                  {cityErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{cityErr}</div> : <></>}
                </div>

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="State" variant="outlined" className='textField' inputRef={state} onChange={() => { setstateErr(null) }} />
                  {stateErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{stateErr}</div> : <></>}
                </div>

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Country" variant="outlined" className='textField' inputRef={country} onChange={() => { setcountryErr(null) }} />
                  {countryErr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{countryErr}</div> : <></>}
                </div>

                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Postal Code" variant="outlined" className='textField' inputRef={inpassref} onChange={() => { setpassworderr(null) }} />
                  {passworderr ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{passworderr}</div> : <></>}
                </div>



                {/* <div className='cmn-input-cls'>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                    <FormLabel component="legend">Select a Role:</FormLabel>
                    <RadioGroup
                      row
                      aria-label="options"
                      name="Role"
                      inputRef={selectedValueRef}
                      onChange={(e) => handleChange(e)}
                      style={{ "justify-content": "center" }}
                    >
                      <FormControlLabel
                        value="user"
                        control={<Radio />}
                        label="User"
                      />
                      <FormControlLabel
                        value="brand"
                        control={<Radio />}
                        label="Brand"
                      />
                    </RadioGroup>
                  </FormControl>
                </div> */}


                <div className='crtPassword'>Already have an account? <Link to="/">Click here to Sign in.</Link></div>
              </div>

              <Button className='submit' onClick={() => { handleSuccessRegister() }}>Register</Button>


            </div>
          </Grid>

        </Grid>
      </Box>


    </div>
  )
}

export default Register

