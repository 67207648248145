import React, {useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import './QrProduct.css';
import ScanTab from './ScanTab'
import ContactSupportIcon from '@mui/icons-material/ContactSupport';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

  const useStyles = makeStyles({

    modalpara:{
        fontSize: '18px',
        textAlign:'center',
        maxWidth:'750px !important',
        margin:'0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)' : {
            paddingBottom: '36px',
          },

      },

      reasonBlock:{
        fontSize:'24px',
        fontWeight:'600',
        margin:'0'
    }

  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ScanBlockchain = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div >
      
           <div className="qr-detail">
      <Button onClick={handleOpen} variant="contained">Scan </Button>
      <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup scanpopup">
        <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            
            <div className="block-part">
                <p className="scan-blockchain-para">Scan blockchain</p>
            </div>

            <div className="address-detail">
                <p className="scan-blockchain-para">Address <span> 0X6DEGEE45JFKS11521677D90C</span> </p>
            </div>

            <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="overview-box-outer">
                        <div className="head-over">
                            <p className="view-over">Overview</p>
                        </div>
                        <div className="head-over">
                            <p className="view-over">Balance:</p>
                            <div className="value-overview">
                                <p>3.911420006375824931</p>
                            </div>
                        </div>
                        <div className="head-over">
                            <p className="view-over">Matic Value:</p>
                            <div className="value-overview">
                                <p>$2.99 (@ $0.74/matic)</p>
                            </div>
                        </div>
                        <div className="head-over">
                            <p className="view-over">Token</p>
                            <div className="value-overview">
                                <p>$2.99 (@ $0.74/matic)</p>
                            </div>
                        </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="overview-box-outer">
                        <div className="head-over">
                            <p className="view-over">More Info </p>
                            <div className="value-overview">
                            <MoreVertOutlinedIcon />
                            </div>
                        </div>
                        <div className="head-over">
                            <p className="view-over"><ContactSupportIcon /> My Name Tag</p>
                            <div className="value-overview">
                                <p>Not Available, <span>Login to update</span></p>
                            </div>
                        </div>
                       
                </div>
            </Grid>
            </Grid>
         
             <div className="trans-tab-table">
                <ScanTab />
             </div>
       
     


        </Box>
      </Modal>
      </div>
    </div>

    
  );
};

export default ScanBlockchain;
