import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
// import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button'
import '../Login/Login.css'
// import btnlogo from '../../img/btnlogo.png'
// import profilebrand from '../../img/profilebrand.png'
import profilebrand from '../../img/Group 28.png'


// import IconButton from '@mui/material/IconButton';
// import Input from '@mui/material/Input';
// import FilledInput from '@mui/material/FilledInput';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import InputAdornment from '@mui/material/InputAdornment';
// import FormControl from '@mui/material/FormControl';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Axios from '../../Axios';
import consts from '../../constants';
import { Toaster, toast } from 'react-hot-toast'
// import diamond from '../../img/diamond-form-dec.png'
// import CryptoJS from "crypto-js";
// import { Message } from '@mui/icons-material';


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   boxShadow: 'none !important',
// }));

// const useStyles = makeStyles({

// });

const Login = () => {
  // const classes = useStyles();
  const navigate = useNavigate()
  const history = useLocation();

  useEffect(() => {
    if (history?.state?.data === true) {
      block()
    }
  }, [history?.state?.data])

  const block = () => {
    if (history.state.data === true) {
      toast.error('Admin Blocked You!!!')
    }
  }

  // const [lat, setLatitude] = useState()
  // const [long, setLongitutde] = useState()

  // const [active, setActive] = useState()

  useEffect(() => {
    // if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
    // } else {
    //   console.log("Geolocation not supported");
    // }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      // setLatitude(latitude)
      // setLongitutde(longitude)
    }

    function error() {
      console.log("Unable to retrieve your location");
    }
  }, []);

  // const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [is_send, setIssend] = useState(false)
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const email = useRef(null);
  // const password = useRef(null);

  const [emailerr, setemailerr] = useState(null);
  // const [passworderr, setpassworderr] = useState(null);

  // const secretPass = 'DtRaCeRsEcrEtFrEe'
  const handleEmailchange = () => {
    const re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
    if (email.current.value === "") {
      setemailerr("Please Enter your Email Address");
    } else if (!re.test(email.current.value)) {
      setemailerr("Please Enter Valid mail");
    }
    else {
      setemailerr(null)
    }
  }
  const sendEmail = async () => {
    try {
      const re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
      console.log(email.current.value)
      if (email.current.value === "") {
        setemailerr("Please Enter your Email Address");
      } else if (!re.test(email.current.value)) {
        setemailerr("Please Enter Valid mail");
      }
      else {
        setIssend(true)
        const { data } = await Axios.post("/forgotpassword", {
          email: email.current.value,

        });
        // console.log(data)

        if (data?.success) {
          toast.success(`Please Check your Mail ${email.current.value} to Reset your Password`,)
          // console.log(data)
          email.current.value = null
          setIssend(false)
          setTimeout(() => {
            navigate(`${consts.route}/`)

            // navigate('/')
            setIssend(false)
          }, 1000);
        }
        else {
          // setActive(false)
          setIssend(false)

          email.current.value = null
          if (data?.message === "User Not Found") {
            setemailerr("User Not Found");
          }
          else if (data?.message === "Admin Blocked You") {
            setemailerr("Admin Blocked You");
          }

          else {
            toast.error("Somthing Went Wrong")
          }

        }
      }
    } catch (error) {
      // setActive(false)
      setIssend(false)

      console.log(error, "err")

    }
  };

  // useEffect(() => {
  //   console.log("logs")
  //   if (history?.state?.emailVerify === 0) {
  //     toast.success("Email Verified Successfully", {
  //       icon: '👏',
  //     })
  //   } else if (history?.state?.emailVerify === 1) {
  //     toast.error("Email Already Verified")
  //   } else if (history?.state?.login === 0) {
  //     toast.success("Please Login")
  //   }
  // }, []);



  return (

    <div className='login-page'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
            <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
            <p>We Protect your brand 100% with blockchain technology</p>
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>

            <div className="form-input1">
              <div className='login-main-text'>
                Forget Password

              </div>

              <div className='email-password-block'>
                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email}
                    onChange={() => {
                      handleEmailchange();
                    }} />
                  {emailerr !== null ? (
                    <div style={{ color: "red", textAlign: 'center' }}>{emailerr}</div>
                  ) : (
                    <></>
                  )}

                </div>

                <div className='crtPassword'><Link to={`${consts.route}/`}>Login</Link></div>
              </div>

              {is_send ? <Button className='submit' >Sending...</Button> : <Button className='submit' onClick={() => { sendEmail() }}>Send Verify Mail</Button>}

              {/* <div className='did-have-account'>
               If you didn't have an account?<span><Link to="/register">Register Now</Link></span>
             </div> */}

              {/* <div className='choice'>Or Used</div>

             <Button className='meta'><div className='btn-logo'><img src={btnlogo} alt="logo" /></div>Wallet Metamask</Button> */}
              {/* <div className='diamond-img'><img src={diamond} alt="diamond"/></div> */}
            </div>


          </Grid>



        </Grid>
      </Box>


    </div>
  )
}

export default Login
