import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const MonthlyChart = ({ first, date, last }) => {

  const [state, setState] = useState({

    // series: [{
    //   name: 'PRODUCT C',
    //   data:last
    // }, {
    //   name: 'PRODUCT D',
    //   data: first
    // }],
    series: [
      {
        name: 'Income',
        data: first,
      },
      {
        name: 'Cost',
        data: last,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true
        },
        // id:"Post Sales Income vs costs"

      },
      title: {
        text: "Post Sales Income vs costs",
        align: "left",
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          dataLabels: {
            total: {
              enabled: false,
              style: {

                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      xaxis: {
        // type: 'datetime',
        categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      },
      legend: {
        position: 'bottom',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    },
  })

  useEffect(() => {
    setState({

      // series: [{
      //   name: 'PRODUCT C',
      //   data: last
      // }, {
      //   name: 'PRODUCT D',
      //   data: first
      // }],
      series: [
        {
          name: 'Income',
          data: first,
        },
        {
          name: 'Outcome',
          data: last,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true
          },
          title: {
            text: "Post Sales Income vs costs",
            align: "left",
          },
          // id:"Post Sales Income vs costs"
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            dataLabels: {
              total: {
                enabled: false,
                style: {

                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          // type: 'datetime',
          categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
        legend: {
          position: 'bottom',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
    })
  }, [first, date, last])

  return (


    <div id="chart">
      <ReactApexChart options={state?.options} series={state?.series} type="bar" height={200} />
    </div>
  )
}

export default MonthlyChart;