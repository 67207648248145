import React from 'react'

const Footer = () => {
  return (
    
    <div className='footer-section'>
      <p><strong>All Rights Reserved at Dtracer.io</strong></p>
      <p>Contact : <strong>support@dtracer.io</strong></p>
    </div>
  )
}

export default Footer;
