import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'
import { Toaster, toast } from 'react-hot-toast'
import "../Homepage/Home.css";
import "./GenerateCertificate.css";
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import warrentylogo from "../../../img/logo-warrenty.png";
import secure from "../../../img/generate-certificate/secure.svg";
import AdminMenuSide from '../../Admin/AdminMenuSide';
import cancel from "../../../img/cancel.png"
import Axios from '../../../Axios'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },
    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const GenerateCertificate2 = () => {

    const classes = useStyles();
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const [menuOpen, setMenuOpen] = useState(true)

    const [email, setEmail] = useState("")
    const [emailerr, setEmailerr] = useState(null)
    const [stsDownloads, setStsDownloads] = useState(false)

    const [datas, setDatas] = useState()
    const [history, setHistory] = useState()
    const user = window.localStorage.getItem('AdminToken')
    const [lat, setLatitude] = useState('')
    const [long, setLongitutde] = useState('')

    useEffect(() => {
        setDatas(location?.state?.data)
        setHistory(location?.state?.history)
    }, [location])



    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

        useEffect(() => {
            console.log('useEffect triggered');
            if ("geolocation" in navigator) {
                // Request the user's location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Success callback
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;
                        setLatitude(position.coords.latitude)
                        setLongitutde(position.coords.longitude)
                        console.log("Latitude:", latitude);
                        console.log("Longitude:", longitude);
                        // Here you can use latitude and longitude to determine the user's location
                    },
                    (error) => {
                        // Error callback
                        console.error("Error getting user's location:", error.message);
                    }
                );
            } else {
                // Geolocation is not supported
                console.error("Geolocation is not supported by this browser.");
            }
      }, []);

    const sendPdfCertificate = async () => {
        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            //setStsDownloads(true)
            if (email === "") {
                setEmailerr("Email Required")
            }
            else if (!(re.test(email))) {
                setEmailerr("Please Enter valid Email")
            }
            else {
                await Axios.post(`/users/user_payment`, {
                    product_id: datas?.product_details?.product_id,
                    email: email,
                    price: datas?.product_details?.Certificate_Price,
                    latitude: lat,
                    longitude: long
                },
                    {
                        headers: {
                            Authorization: user,
                        },
                    }).then(async (res) => {
                        console.log(res)
                        if (res?.data?.success) {
                            //setStsDownloads(false)
                            window.location.href = res?.data?.result?.url
                            //toast.success(res?.data?.message)

                        }
                        else {
                            //setStsDownloads(false)
                            toast.error(res?.data?.message)
                        }
                    }).catch((err) => {

                        console.log(err)
                        // setStsDownloads(false)
                    })
            }
        } catch (error) {
            setStsDownloads(false)
            console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
        }

    }

    // const sendPdfCertificate = async (product_id) => {
    //     try {
    //       setStsDownloads(true)
    //       await Axios.post(`/users/create_user_cretificate`, {
    //         product_id: datas?.product_details?.product_id,
    //         email: email
    //       },
    //         {
    //           headers: {
    //             Authorization: user,
    //           },
    //         }).then(async (res) => {
    //           if (res?.status === 200) {
    //             setStsDownloads(false)

    //             toast.success("certificate send Successfully")

    //           }
    //           else {
    //             setStsDownloads(false)
    //             toast.error("Can't Download Successfully")
    //           }
    //         }).catch((err) => {

    //           console.log(err)
    //           setStsDownloads(false)
    //         })
    //     } catch (error) {
    //       setStsDownloads(false)
    //       console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
    //     }

    //   }

    return (
        <div className='Generate-certificate-page'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>

                        <div className='extented-warrenty' onClick={closeMenuHandler}>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div2'>
                                    <div>
                                        {/* <Link to="/user/generate-certificate1"><img src={Menuclose} alt="close" /></Link> */}
                                        <div onClick={() => { navigate('/user/generate-certificate1', { state: { data: datas, history: history } }) }} ><img src={Menuclose} alt="close" /></div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className='watch-div'>
                                    <img src={datas?.product_details?.main_image} alt="watch" />
                                </div>
                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        {datas?.product_details?.Product_Name} ID {datas?.product_details?.product_id}
                                    </div>
                                    <div className='watch-description'>
                                        Generate Certificate of Authenticity
                                    </div>
                                    <div className='watch-sub-description'>
                                        Enter the email where you want to receive the certificate of authenticity
                                    </div>

                                    <div className='email-input'>
                                        <TextField id="outlined-basic" placeholder='Email' variant="outlined" className='textField' onChange={(e) => { setEmail(e.target.value); setEmailerr(null) }} />

                                    </div>
                                    {emailerr !== null ? (
                                        <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{emailerr}</div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='watch-sub-description'>
                                        Make sure of the email entered, we will send to this email the certificate of authenticity of the product
                                        <span> ID {datas?.product_details?.product_id}</span>
                                    </div>



                                    <div className='sales'>
                                        <div className='watch-div'>
                                            <img src={datas?.product_details?.main_image} alt="watch" />
                                        </div>

                                        <div className='col-svg'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="100" viewBox="0 0 3 100" fill="none">
                                                <path d="M1.33984 0.569336V87.6855" stroke="url(#paint0_linear_1464_6020)" stroke-width="1.76955" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6020" x1="1.34042" y1="87.5856" x2="-0.446899" y2="87.5752" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0378631" stop-color="#DFE5F4" />
                                                        <stop offset="0.132489" stop-color="#0070F0" />
                                                        <stop offset="0.244412" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='watch-description'>
                                            Generate Certificate of Authenticity
                                        </div>
                                        <div className='col-svg'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="100" viewBox="0 0 3 100" fill="none">
                                                <path d="M1.33984 0.569336V87.6855" stroke="url(#paint0_linear_1464_6020)" stroke-width="1.76955" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6020" x1="1.34042" y1="87.5856" x2="-0.446899" y2="87.5752" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0378631" stop-color="#DFE5F4" />
                                                        <stop offset="0.132489" stop-color="#0070F0" />
                                                        <stop offset="0.244412" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='price'>
                                            <p>for only</p>
                                            <h6>${datas?.product_details?.Certificate_Price}</h6>
                                        </div>
                                    </div>
                                    {/* <Link to="/user/generate-certificate3"> */}
                                    <div className="approved-btn">
                                        {
                                            stsDownloads === true ?
                                                <Button className="down-info" >Sending...</Button>
                                                :
                                                // <Button className="down-info" onClick={() => generatePdf(selected, details?.status)}>Download info</Button>

                                                <Button className="approve-button" onClick={() => { sendPdfCertificate(datas?.product_details?.product_id) }}>Generate Certificate</Button>
                                        }
                                    </div>
                                    {/* <div className='gen-btn'>
                                        <Button>Generate Certificate</Button>
                                    </div> */}
                                    {/* </Link> */}
                                    <div className='user-logo generate2'>
                                        <img src={secure} alt="secure" />
                                        <div>
                                            <span>100%</span> SECURE PAYMENTS
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default GenerateCertificate2;

