import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

const  MonthlyChart=({lastpriceData,priceData})=> {
  const [chartdata,setChartData]=useState({
    series: [{
      name: 'Previous Year',
      data: lastpriceData
    }, {
      name: 'Current Year',
      data: priceData
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true
        },
       
        id:"Post_Sale",
      },
      title: {
        text: "Post Sale",
        align: "left",
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'March', 'April',
          'May', 'June','July', 'Aug', 'Sep', 'Oct',
          'Nov', 'Dec'
        ],
      },
      legend: {
        position: 'bottom',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    },
        
        
        })


        useEffect(()=>{
         setChartData({
        
          series: [{
            name: 'Previous Year',
            data: lastpriceData
          }, {
            name: 'Current Year',
            data: priceData
          }],
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true
              },
              
              id:"Post_Sale",
            },
            title: {
              text: "Post Sale",
              align: "left",
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 5,
                dataLabels: {
                  total: {
                    enabled: false,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900
                    }
                  }
                }
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'March', 'April',
              'May', 'June','July', 'Aug', 'Sep', 'Oct',
              'Nov', 'Dec'
              ],
            },
            legend: {
              position: 'bottom',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
          },
        
        
        })
        },[lastpriceData,priceData])
   

      return (
        

<div id="chart">
  <ReactApexChart options={chartdata.options} series={chartdata.series} type="bar" height={200} />
</div>


      );
  }

  export default MonthlyChart;