import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import BrandBlockchainBody from './BrandBlockchainBody';
import './BrandBlockchain.css'
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import AdminMenuSide from '../../Admin/AdminMenuSide';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
});

const BrandBlockchain = () => {

  useEffect(() => {
    document.title = "Dtracer | Blockchain Transactions"
  }, [])

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }
  // useEffect(() => {
  //   screenInactive()
  // }, [timeoutSeconds, isActive, navigate, redirectPath]);

  // const screenInactive = () => {
  //   let timeoutId;

  //   const resetTimeout = () => {
  //     clearTimeout(timeoutId);
  //     if (isActive) {
  //       timeoutId = setTimeout(() => {
  //         logout()
  //         window.localStorage.removeItem('electrónico')
  //         window.localStorage.removeItem('AdminToken')
  //         window.localStorage.removeItem('Squelch')
  //         navigate(`${consts.route}/`)
  //       }, timeoutSeconds);
  //     }
  //   };

  //   const handleMouseActivity = () => {
  //     setIsActive(true);
  //     resetTimeout();
  //   };


  //   resetTimeout();


  //   window.addEventListener('mousemove', handleMouseActivity);
  //   window.addEventListener('keydown', handleMouseActivity);


  //   return () => {
  //     clearTimeout(timeoutId);
  //     window.removeEventListener('mousemove', handleMouseActivity);
  //     window.removeEventListener('keydown', handleMouseActivity);
  //   };
  // }

  return (
    <div className='blockchain-page'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.bodyclass}>
              <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
              <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                <AdminMenuSide />
              </div>
              <div onClick={closeMenuHandler}>
                <BrandBlockchainBody />
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.footercls}>
              <Footer />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default BrandBlockchain;
