import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useParams } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'

import "./Warrenty.css"
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import guard from "../../../img/guard.png"
import cancel from "../../../img/cancel.png"
import AdminMenuSide from '../../Admin/AdminMenuSide';
import Axios from '../../../Axios';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const ExtendWarrenty2 = () => {

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }
    const { userId } = useParams()
    const [dat, setDat] = useState()
    const [period, setPeriod] = useState()
    useEffect(() => {
        products()
    }, [userId])

    const products = async () => {
        try {
            const { data } = await Axios.post('/GetProductDetails', { product_id: userId })
            console.log("🚀 ~ file: ExtendWarrent2.js:93 ~ products ~ data:", data)
            if (data?.success === true) {
                setDat(data?.result)
                let matches = data?.result?.Extra_warranty_time.match(/(\d+)/)
                setPeriod(matches[0])
            }
        } catch (error) {
            console.log("🚀 ~ file: ExtendWarrent2.js:94 ~ products ~ error:", error)
        }
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>

                        <div className='extented-warrenty'>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div'>
                                    {/* <div>
                                        <Link to="/user/extendwarrenty"><img src={Menuclose} alt="close" /></Link>
                                    </div> */}
                                    <div>

                                    </div>
                                </div>

                                <div className='watch-id'>
                                    <div className='watch-default' >   <img src={dat?.main_image} alt="watch" /></div>
                                    <div className='warrenty-main-display'>
                                        {/* <div className='watch-default' >   <img src={dat?.main_image} alt="watch" /></div> */}

                                        {/* <div className='warrenty-records1 br'>
                                            Extend the warranty now for an extra <p className='para-warrenty'><span>{period}</span> years</p>
                                        </div> */}
                                        {/* <div>
                                            <div className='for-only-65'>
                                                <div>for only</div>
                                                <span>{dat?.Type_of_currency} {dat?.Warranty_extension_price}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className='warrenty-records3'>
                                        You have purchased a <span className='go-green'>{period}</span> year warranty extension.
                                    </div> */}
                                    <div className='warrenty-records3'>
                                        Currently your warranty is valid until <span className='go-green'>{dat?.userExtenWarrenty} </span>
                                    </div>


                                    <div className='Guarantee'>
                                        Thanks for your purchase!
                                    </div>

                                    <div className='warrenty-records4'>
                                        We have registered this operation in the blockchain, <span>do not forget to check</span>
                                    </div>
                                    <div className='warrenty-trns'>
                                        latest transaction
                                    </div>



                                    <div className='trns-details'>
                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr className='warrenty-hr' />

                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <Link to="/user/myproductlist" className='paswd-change-link'><Button className='Buy-now-warrenty' >Thank You</Button></Link>
                                    </div>
                                    <div className='user-logo2'>
                                        <img src={guard} alt="lg-1" />
                                        <span className='go-green'>100%</span> <span>SECURE PAYMENTS</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default ExtendWarrenty2
