import React, { useEffect, useState } from 'react'
import logo from '../../img/logo.png'
import Messages from '../../img/message.png'
import notificationimg from '../../img/notification.png'
import profileiconstatic from '../../img/profile-icom-static.png'
import profileicomstaticbig from '../../img/profile-icom-static-big.png'
import messageIcons from '../../img/msgIcon.png'
import esp from '../../img/flags/sp.png'
import eng from '../../img/flags/us.png'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import consts from '../../constants'
import './Header.css'

import walletnew from '../../img/wallet.png'
import { Link, useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import AdjustIcon from '@mui/icons-material/Adjust';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moreicon from '../../img/moreicon.png'
import settime from '../../img/settime.png'
import cancel from '../../img/cancel.png'
import messgcount from '../../img/messgcount.png'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LanguageIcon from '@mui/icons-material/Language';
import Axios from '../../Axios'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MetaMask from '../../img/MetaMask.png'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Toaster, toast } from 'react-hot-toast'
import closeMenu from '../../img/closemenu.png';
import Badge from '@mui/material/Badge';
import io from "socket.io-client";
import PremiumChat from '../User/PremiumBrands/PremiumChat'
import CompleteChat from '../User/PremiumBrands/ComptedChat'
import Menuclose from '../../img/Menuclose.png';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '111.111px',
  backgroundColor: '#fff',
  boxShadow: '3.33333px 3.33333px 7.77778px 0px rgba(156, 156, 156, 0.48) inset, -3.33333px -3.33333px 7.77778px 0px #FFF inset',
  '&:hover': {
    backgroundColor: '#fff',
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));



// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 5),
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       width: '100%',
//       '&:focus': {
//         width: '100%',
//       },
//     },
//   },
// }));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  headermainouter: {
    background: 'rgba(0, 0, 0, 0.75) !important',
    backdropFilter: 'blur(27.777780532836914px)',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      paddingBottom: '10px',
    }
  },
  removerbs: {
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    border: 'none !important',
    background: 'transparent !important',
    height: '100%',
    padding: '0px !important'
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    color : 'white !important',
    verticalAlign : 'super !important',
    '& img': {
      'max-width': '24px', // Ajusta el tamaño de la imagen según lo necesites
      'max-height': '24px',
    },
    
  }
});

function TabPanel(props) {

	
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}





const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const stylewallet = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  p: 4,
  border: 'none'
};
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

let socket = io.connect(`${consts.MainUrl}`, { path: "/socket" })
// let socket = io.connect(`${consts.socketurl}`)
// let socket = io.connect(consts.socketurl);

const Header = ({ status }) => {
  
  const [notify, setNotify] = React.useState(false);
  const [unread, setunread] = React.useState(false);
  const { t } = useTranslation('common');
	const { i18n } = useTranslation('common');
  let languageOption = consts.languageOption;
  

  // const handleNotify = ()=>{
  //   setNotify(true)
  // };

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [notification, setNotification] = React.useState(null);
  const openNotification = Boolean(notification);
  const handleClickNotification = (event) => {
    // alert('iubyuy ')
    setNotification(event.currentTarget);
    setNotify(true)
  };
  const handleCloseNotification = () => {
    setNotification(null);
  };

  const [openViewAll, setOpenViewAll] = useState(false);
  // const handleOpenViewAll = () => setOpenViewAll(true);
  const handleCloseViewAll = () => setOpenViewAll(false);


  const [openViewActivity, setOpenViewActivity] = useState(false);
  const handleOpenViewActivity = () => setOpenViewActivity(true);
  const handleCloseViewActivity = () => setOpenViewActivity(false);

  const [openViewMessage, setOpenViewMessage] = useState(false);
  const handleOpenViewMessage = () => setOpenViewMessage(true);
  const handleCloseViewMessage = () => {

    setOpenViewMessage(false)
    getNotification()
  };

  const [openViewFaq, setOpenViewFaq] = useState(false);
  const handleOpenViewFaq = () => setOpenViewFaq(true);
  const handleCloseViewFaq = () => setOpenViewFaq(false);

  const [openViewFaqBlockchain, setOpenViewFaqBlockchain] = useState(false);
  const handleOpenViewFaqBlockchain = () => { setOpenViewFaqBlockchain(true); handleCloseViewFaq() };
  const handleCloseViewFaqBlockchain = () => setOpenViewFaqBlockchain(false);

  const [openViewFaqNFT, setOpenViewFaqNFT] = useState(false);
  const handleOpenViewFaqNFT = () => { setOpenViewFaqNFT(true); handleCloseViewFaq() };
  const handleCloseViewFaqNFT = () => setOpenViewFaqNFT(false);

  const [openViewFaqSystem, setOpenViewFaqSystem] = useState(false);
  const handleOpenViewFaqSystem = () => { setOpenViewFaqSystem(true); handleCloseViewFaq() };
  const handleCloseViewFaqSystem = () => setOpenViewFaqSystem(false);

  const [openViewFaqWork, setOpenViewFaqWork] = useState(false);
  const handleOpenViewFaqWork = () => { setOpenViewFaqWork(true); handleCloseViewFaq() };
  const handleCloseViewFaqWork = () => setOpenViewFaqWork(false);

  const [value, setValue] = React.useState(0);
  const [userDetails, setUserDetails] = useState()
  const handleChange = (event, newValue) => {

    setValue(newValue);
    setChat(false)
    setChat1(false)
    setdesErr(null)
    setcusErr(null)
    setnameErr(null)
    setidErr(null)
    setMessage()
    setName()
    // setId()
    setProductId()
    setCustomer()
  };

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [receivers, setReceivers] = useState([])
  // const [metamaskadd, setMetamaskadd] = useState(null);
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [message, setMessage] = useState()

  const [customers, setCustomer] = useState()
  const [pro_id, setProductId] = useState()

  const [iderr, setidErr] = useState(null)
  const [nameerr, setnameErr] = useState(null)
  const [cuserr, setcusErr] = useState(null)
  const [deserr, setdesErr] = useState(null)

  const [message1, setMessage1] = useState()
  const [messageList, setmessageList] = useState([])
  const [messageLists, setmessageLists] = useState([])
  const [TicketList, setTicketList] = useState([])
  const [CompletedTickets, setCompletedTickets] = useState([])
  const [wallet, setWallet] = useState(null);
  const [notifications, setNotifications] = useState()
  const [openWallet, setOpenWallet] = useState(false);
  const handleOpenWallet = () => setOpenWallet(true);
  const handleCloseWallet = () => setOpenWallet(false);
  const navigate = useNavigate()
  const [room, setRoom] = useState()
  const [tickets, setTickets] = useState([])

  const [unreadCount, setUnreadCount] = useState(0)
  const languages = [
    { value: 'es', label: 'ES', image: esp },
    { value: 'en', label: 'EN', image: eng },
    // Agrega más opciones según necesites
  ];

  const [chat, setChat] = useState(false)
  const [chat1, setChat1] = useState(false)
  const handleChat = () => {
    setChat(!chat)
  }

  const handleChat1 = () => {
    setChat1(!chat1)
  }
  const connectwallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWallet(addressArray[0])
        // setMetamaskadd(addressArray[0])
        // const obj = {
        //   status: "👆🏽 Write a message in the text-field above.",
        //   address: addressArray[0],
        // };
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      console.log("dis connect")
    }
  }

  const logout = async () => {
    try {
      if ((window.localStorage.getItem('Squelch') === 'brand') || (window.localStorage.getItem('Squelch') === 'user')) {
        await Axios.post(`/logout`, {
          email: window.localStorage.getItem('electrónico')
        }, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
      }

      if (window.localStorage.getItem('Squelch') === "user") {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        window.localStorage.removeItem('isimo')
        window.localStorage.removeItem('electrónico')
        window.localStorage.removeItem('camino')
        window.localStorage.removeItem("comunicazione")

        toast.success('LoggedOut Successfully')
        setTimeout(() => {
          navigate(`${consts.route}/user/login`)
        }, 1000);
      } else {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        window.localStorage.removeItem('isimo')
        window.localStorage.removeItem('electrónico')
        window.localStorage.removeItem('camino')
        window.localStorage.removeItem("comunicazione")
        toast.success('LoggedOut Successfully')
        setTimeout(() => {
          navigate(`${consts.route}/`)
        }, 1000);
      }

    } catch (error) {
      console.log("🚀 ~ file: Header.js:353 ~ logout ~ error:", error)
    }


  }

  const [Activity, setActivity] = useState([])
  const [ids, setIds] = useState()

  const getActivity = async () => {
    if ((window.localStorage.getItem('Squelch') != "admin") && (window.localStorage.getItem('Squelch') != "subAdmin")) {
      try {
        const { data } = await Axios.post(`/getActivities`, { email: window.localStorage.getItem('electrónico') }, {
          headers: {

            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          setActivity(data?.result)
        }
      } catch (error) {
        console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
      }
    }

  }

  useEffect(() => {
    getActivity()
    getbrand()
    getTicket()
    getCompltedTicket()
  }, [])

  useEffect(() => {
    getTicket()
    getCompltedTicket()
  }, [chat, value])

  // useEffect(() => {

  // }, []);

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  const [datass, setDatass] = useState()
  const [names, setNames] = useState()
  const [activeLoader, setActiveLoader] = useState(false)

  const profile = async () => {
    try {
      if (role === 'brand') {
        const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
          headers: {
            Authorization: user
          }
        })
        if (data?.success === true) {
          setDatass(data?.result)
          setIds(data?.result?._id)
          setNames(data?.result?.user_info?.name)
        }
      } else {
        const { data } = await Axios.post(`/getAdminDetails`, {}, {
          headers: {
            Authorization: user
          }
        })
        if (data?.success === true) {
          setDatass(data?.result)
          setIds(data?.result?._id)
          if (data?.result?.name !== undefined) {
            setNames(data?.result?.name)
          }
          else {
            setNames(data?.result?.firstname)
          }


        }
      }
    } catch (error) {
    }

  }
  const [messages, setMessages] = useState([])


  const getNotification = async () => {
    try {
      // if (role === 'seller') {
      // }
      const { data } = await Axios.post(`/getNotificationById`, {}, {
        headers: {
          Authorization: user
        }
      })
      setUnreadCount(0)
      if (data?.success === true) {
        setNotifications(data?.result)
        // setNotify(data?.length)
        setunread(data?.length)
        if (data?.messageCount[0]?.totalUnreadMessageCount > 0) {
          setUnreadCount(data?.messageCount[0]?.totalUnreadMessageCount)
          setunread(data?.length + 1)
        }
      }
      var setToTrue = []

      for (var j = 0; j < data?.result?.length; j++) {
        var trueId = data?.result[j]._id
        setToTrue.push(trueId)
      }
      setMessages(setToTrue)
      // else {
      //   console.log("Not a Seller")
    }
    catch (error) {
      console.log("🚀 ~ ~ error:", error)
    }

  }

  const setNotificationTrue = async (id) => {
    try {

      const { data } = await Axios.post(`/changetotrue`, { id: id }, {
        headers: {
          Authorization: user
        },
      })
      if (data?.success === true) {
        getNotification()
      }

    }
    catch (error) {
      console.log("🚀 ~ ~ error:", error)
    }

  }


  // const approveNotification = async (notificationdata) => {
  //   try {
  //     if (role === 'seller' || role === 'brand') {
  //       const payData = {
  //         to_id: notificationdata?.to_id,
  //         product_id: notificationdata?.product_id,
  //       }
  //       const { data } = await Axios.post(`/approveProduct`, payData, {
  //         headers: {
  //           Authorization: user
  //         }
  //       })
  //       if (data?.success === true) {
  //         navigate(`${consts.route}/user/dashboard`)
  //       }
  //     }
  //     else {
  //       console.log("Not a Seller")
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ ~ error:", error)
  //   }

  // }
  useEffect(() => {
    // if (status === true) {
    getNotification()
    // }

  }, [])

  useEffect(() => {
    // if (status === true) {
    profile()
    // }
  }, [status])

  useEffect(() => {
    profile()
    getNotification()
  }, [role])

  // const getMessage = async() => {
  //   try {

  //     const { data } = await Axios.get(`/message/get_ticket`, {
  //       headers: {

  //         Authorization: window.localStorage.getItem('AdminToken')
  //       }
  //     })
  //     if (data?.success === true) {

  //       setTickets(data?.result)

  //     }
  //     else {
  //       toast.error(data?.result?.message)
  //     }

  //   } catch (error) {
  //     console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
  //   }
  // }

  // useEffect(() => {
  //   const dt = async () => {
  //     try {
  //       if (user) {
  //         const { data } = await Axios.post('/users/userDetails', "", {
  //           headers: {
  //             Authorization: user
  //           }
  //         })
  //         if (data?.success === true) {
  //           console.log(data?.result,"data")
  //           setUserDetails(data?.result)
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error, "err")
  //     }

  //   }
  //   dt()
  // }, [user])

  const getbrand = async () => {
    try {
      const { data } = await Axios.get(`/message/get_user_brand`, {
        headers: {

          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setReceivers(data?.result)
      }
      else {
        toast.error(data?.result?.message)
      }
    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }
  }

  const getTicket = async () => {
    try {
      const { data } = await Axios.get(`/message/get_ticket`, {
        headers: {

          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {

        setTickets(data?.result)

      }


    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }
  }

  const getCompltedTicket = async () => {

    try {

      const { data } = await Axios.get(`/message/get_completed_ticket`, {
        headers: {

          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {

        setCompletedTickets(data?.result)

      }


    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }
  }

  useEffect(() => {
    socket.on("receive", (data) => {
      setmessageList((prev) => [...prev, data]);
    });
  }, []);

  const getOneTicket = async (datas) => {

    try {

      const { data } = await Axios.get(`/message/get_one_ticket/${datas}`, {
        headers: {

          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setRoom(data?.result?.ticketDetails?.room)
        setmessageList(data?.result?.messageDetails)
        var datass = []
        for (var i = 0; i < data?.result?.messageDetails; i++) {
          datass.push(data?.result?.messageDetails[i].message)
        }
        setmessageLists(datass)
        setTicketList(data?.result?.ticketDetails)
        //setTickets(data?.result)
        socket.emit("join", {
          room: data?.result?.ticketDetails?.room,
        })


      }


    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }
  }

  useEffect(() => {
  }, [id])

  const createTicket = async () => {
    try {
      if (!id) {
        setidErr("Please select Requestor")
      }
      else if (!customers) {
        setcusErr("Please give Customer")
      }
      else if (!name) {
        setnameErr("Please give Title")
      }
      else if (!message) {
        setdesErr("Please give Description")
      }

      else {
        setActiveLoader(true)
        const { data } = await Axios.post(`/message/create_ticket`, {
          name: name,
          id: id,
          description: message,
          customer: customers,
          product_id: pro_id
        }, {
          headers: {

            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          getTicket()
          setdesErr(null)
          setcusErr(null)
          setnameErr(null)
          setidErr(null)
          setMessage()
          setName()
          // setId()
          setProductId()
          setCustomer()
          setOpenViewMessage(false)
          toast.success(data?.message)
          setActiveLoader(false)
          setTimeout(() => {
            setValue(0)
          }, 2000);

        }
        else {
          setActiveLoader(false)
          setOpenViewMessage(false)
          toast.error(data?.message)
        }
      }
    } catch (error) {
      setActiveLoader(false)
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }

  }

  const getOneTicketMesg = async (datas) => {
    try {

      const { data } = await Axios.get(`/message/get_one_ticket/${datas}`, {
        headers: {

          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setRoom(data?.result?.ticketDetails?.room)
        setmessageList(data?.result?.messageDetails)
        setTicketList(data?.result?.ticketDetails)
        //setTickets(data?.result)
        socket.emit("join", {
          room: data?.result?.ticketDetails?.room,
        })

      }
      else {
        // toast.error(data?.result?.message)
        setRoom()
      }

    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }
  }

  const createMessage = async () => {
    try {
      socket.emit("join", {
        room: room,
      })
      const time = `${Date.now()}`
      socket.emit("send", {
        sender_name: names,
        room: room,
        time: time,
        message: message1,
      });

      const { data } = await Axios.post(`/message/send_message`, {

        room: room,
        time: time,
        message: message1,
      }, {
        headers: {

          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        getOneTicketMesg(room)
        setMessage1('')


      }
      else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }


  }

  const deleteTicket = async (datas, id) => {
    try {

      const { data } = await Axios.post(`/message/change_ticket`, {
        _id: datas,
        name: names,
        product_id: id
        // to_id : 
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        toast.success(data?.message)
        getTicket()
        getCompltedTicket()
        getNotification()
      }
      else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
    }


  }

  const mainnotifi = (data) => {
    if (data == "message") {
      handleCloseNotification()
      handleOpenViewMessage()
    } else if (data?.message === "Raised Ticket") {
      setNotificationTrue(data?._id);
      handleOpenViewMessage()
    } else if (data?.message == "Closed Ticket") {
      setNotificationTrue(data?._id);
      handleOpenViewMessage()
    } else {
      setNotificationTrue(data?._id);
      navigate('/products/all')
    }
  }

  useEffect(() => {
    // alert(receivers[0]?.name)
    if (role === 'brand' && receivers.length > 0) {
      setId(receivers[0]?.id)
    }
  }, [receivers])

  return (

    <Box sx={{ flexGrow: 1 }} className={classes.headermainouter}>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Grid container spacing={0} className={classes.containwidth}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Item className={classes.removerbs}>
            <div className='logo-outer-part'>
              {(role == "admin") || (role == "subAdmin") ? <div className='logo'><Link to="/dashboard" ><img src={logo} alt="logo" /></Link></div> : <></>}
              {role === "brand" ? <div className='logo'><Link to="/brand-/brand-dashboard" ><img src={logo} alt="logo" /></Link></div> : <></>}
              {role === "user" ? <div className='logo'><Link to="/user/dashboard" ><img src={logo} alt="logo" /></Link></div> : <></>}
              {/* {
                datass?.user_info?.name ?
                  <div className='logo-slogon'><p>Welcome, {datass?.user_info?.name} Welcome to Dtracer this is your personal space</p></div>
                  :
                  <div className='logo-slogon'><p>Welcome, Admin Welcome to Dtracer Admin this is your personal space</p></div>
              } */}
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center' }} >
          <Item className={classes.removerbs}>
            {
              datass?.user_info?.logo ?
                <div className='brandname'><img src={datass?.user_info?.logo} alt="brandname" /> </div>
                :
                <div className='brandname1'></div>

            }
            {/* <div className='brandname' style={{ backgroundImage: `url(${datass?.user_info?.logo})` }} ></div> */}
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <Item className={classes.removerbs}>
            <div className='search-nmwp'>
              <Search>
                {/* <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                /> */}
              </Search>
              <div className='nmwp'>
                {languageOption === 'true' && (
                <div className='icon' style={{cursor : 'pointer'}} data-toggle="tooltip" data-placement="top" title={i18n.language === 'es' ? "Spanish"  : "English" } >
                  {/* <img className='icon' src={i18n.language === 'es' ? esp : eng}></img> */}
                  {/* <span style={{fontSize : '1rem', color : 'white', textAlign : 'center', verticalAlign : 'super'}}>{i18n.language === 'es' ? 'ES' : 'EN'}</span> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={i18n.language}
                    className={classes.select + " testing-classes"}
                    onChange={() => {}}
                    renderValue={(selected) => (
                      <>
                        <img className='icon flags' src={i18n.language === 'es' ? esp : eng}/>&nbsp;&nbsp;
                        <span className={'language-style'}>{i18n.language.toUpperCase()}
                        </span>
                      </>
                    )}
                  >
                    {languages.map((option, index) => (
                    <MenuItem key={index} value={option.value} className='icon' onClick={() => { i18n.changeLanguage(option.value); }} >
                      <img className='icon flags' src={option.image} alt={option.label} />&nbsp;&nbsp;{option.label}
                    </MenuItem>
                  ))}
                  </Select>
                  </div>
                )}
                {/* <div className='icon'><Link to="/login"><LoginIcon/></Link></div> */}
                {user === null ? <div className='icon'><Link to="/login"><HowToRegIcon /></Link></div> : <></>}
                {(role == "user") || (role == "subAdmin") ? <></> :
                  <div onClick={() => { navigate('/wallet') }} className='icon'><img src={walletnew} alt="wallet" /></div>}
                <div className='icon'><img src={Messages} alt="message" onClick={() => { handleOpenViewMessage() }} /></div>
                <div className='icon'>
                  <div className='notify-img' onClick={handleClickNotification}> <Badge badgeContent={unread || notify ? unread : "0"} color="secondary" anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}><img src={notificationimg} alt="notification-img" /></Badge></div>
                  <Menu
                    anchorEl={notification}
                    id="account-menu-notification"
                    className="notifiction-detail-block-new"
                    open={openNotification}
                    onClose={handleCloseNotification}
                    onClick={handleCloseNotification}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        width: 350,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >

                    <Stack spacing={2} direction="row" className='view-all-caption'>
                      <span>You have {unread ? unread : '0'} unread mails</span>


                    </Stack>

                    {
                      unreadCount > 0 &&
                      <div className='notify-message' style={{ cursor: "pointer" }} onClick={() => mainnotifi("message")}>
                        <div className='notify-message-img'> <img src={messageIcons} /></div>
                        <div className='notify-message-detail'>
                          <strong> You Have {unreadCount} Unread Message</strong>
                        </div>

                      </div>
                    }
                    {notifications ? <>
                      {notifications && notifications?.map((note, index) => {
                        return <div className='notify-message' style={{ cursor: "pointer" }} key={index} onClick={() => mainnotifi(note)}>
                          <div className='notify-message-img'> <img src={note?.productDetails?.product_details[0]?.main_image ? note?.productDetails?.product_details[0]?.main_image : messageIcons} /></div>
                          <div>
                            <div className='notify-message-detail'>
                              {
                                note?.productDetails?.product_details[0]?.status === "2" ?
                                  <strong> Product Blocked</strong> :
                                  note?.message === "Raised Ticket" ?
                                    <strong>{note?.sender}</strong> :
                                    <stong>Product Activate</stong>
                              }
                              {/* <Button onClick={() => { approveNotification(note) }} variant="contained">Approve</Button> */}
                            </div>
                            <div className='notify-message-detail'>
                              <strong> {note?.productDetails?.product_details[0]?.product_id}</strong>
                              {/* <Button onClick={() => { approveNotification(note) }} variant="contained">Approve</Button> */}
                            </div>
                            <div className='notify-message-detail'>
                              <strong> {note?.productDetails?.product_details[0]?.Product_Name}</strong>  <p>{note.message}</p>
                              {/* <Button onClick={() => { approveNotification(note) }} variant="contained">Approve</Button> */}
                            </div>
                          </div>
                        </div>
                      })
                      }
                    </>
                      :
                      <div className='notify-message'>
                        <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                        <div className='notify-message-detail'>
                          <strong>No Notification</strong> <p>Has been Approved</p>
                        </div>
                      </div>}
                    {/* <div className='notify-message'>
                      <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                      <div className='notify-message-detail'>
                        <strong>Product ID AAD1123-11</strong> <p>Has been Approved</p>
                      </div>
                    </div>

                    <div className='notify-message'>
                      <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                      <div className='notify-message-detail'>
                        <strong>Product ID AAD1123-11</strong> <p>Has been Approved</p>
                      </div>
                    </div>

                    <div className='notify-message'>
                      <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                      <div className='notify-message-detail'>
                        <strong>Product ID AAD1123-11</strong> <p>Has been Approved</p>
                      </div>
                    </div> */}



                  </Menu>
                </div>
                <Modal
                  open={openViewAll}
                  className='whole-notify-inbox-outer'
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >

                  <Box sx={style}>

                    <Box
                      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: 1000, justifyContent: 'flex-start', margin: '0 auto' }}
                    >
                      <div className='close-change-passwrd-pop-up close-inbox-pop-up' onClick={handleCloseViewAll}><HighlightOffIcon /></div>
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                      >
                        <Tab className='inbox-tab' label="inbox" {...a11yProps(0)} />
                        <Tab className='Create-Incitant-tab' label="Create Incidentsss" {...a11yProps(1)} />
                      </Tabs>
                      <TabPanel value={value} index={0} className="tab-content-whole-notify-inbox-outer">


                        <div className='whole-notify-inbox'>

                          {tickets.length <= 0 ? <>
                            (
                            <div className='notify-message notify-message-all-view'>
                              <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                              <div className='notify-message-detail'>
                                <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                  Leakage on Lambd....</span> to you</strong>
                                <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                              </div>
                              <div className='notify-message-action'>
                                <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                <div className='action-icon'><img src={settime} alt="settime" /></div>
                                <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                              </div>
                            </div>
                            )
                          </> : <>
                            <div className='notify-message notify-message-all-view'>

                              <div className='notify-message-detail'>
                                <strong> No Tickets</strong>
                              </div>

                            </div>
                          </>}
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={1} className="tab-content-whole-notify-inbox-outer">
                        <div className='create-issue-form'>
                          <div className='text-field-issue'>
                            <label>Requester Name</label>

                            <TextField
                              fullWidth
                              id="outlined-select-currency"
                              select
                              defaultValue="EUR"
                            >
                              <MenuItem value="Joshua">Joshua</MenuItem>
                              <MenuItem value="Joshua">Jacob</MenuItem>
                              <MenuItem value="Joshua">Joseph</MenuItem>
                              <MenuItem value="Joshua">Joshua</MenuItem>
                            </TextField>

                          </div>
                          <div className='text-field-issue'>
                            <label>Site</label>
                            <TextField fullWidth id="outlined-basic" variant="outlined" />
                          </div>
                          <div className='text-field-issue'>
                            <label>Requester Tittle</label>
                            <TextField fullWidth id="outlined-basic" variant="outlined" />
                          </div>
                          <div className='text-field-issue'>
                            <label>Description</label>
                            <TextField
                              id="outlined-multiline-flexible"
                              multiline
                              fullWidth
                              maxRows={4}
                            />
                          </div>
                          {/* <Link className='Add-More-Details' to="">Add More Details</Link> */}
                          <div className='Create-Issue-btn-submit'>
                            <Button variant="contained" disabled={activeLoader} onClick={() => { createTicket() }}>Create Issue</Button>
                          </div>
                        </div>

                      </TabPanel>
                    </Box>
                  </Box>
                </Modal>

                <Modal
                  open={openWallet}
                  // onClose={handleCloseWallet}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylewallet}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      <div className='connect-wallet-head'>Connect Wallet <div onClick={handleCloseWallet}><HighlightOffIcon /></div> </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <Button onClick={connectwallet} className='connect-wallet-btn'><div><img src={MetaMask} alt="MetaMask" /></div> Metamask</Button>
                    </Typography>
                  </Box>
                </Modal>



                <div className='profile'>
                  {/* {user !== null ? <div className='profile-img' onClick={handleClick}><img src={profileiconstatic} alt="profile-icom-static" /></div> : <></>} */}

                  {role === 'user' ?
                    <>
                      {user !== null ? <div className='profile-img' onClick={handleClick}><img src={datass?.coverPicture ? datass?.coverPicture : profileiconstatic} alt="profile-icom-static" /></div> : <></>}
                    </>

                    :
                    role === 'admin' ?
                      <>
                        {user !== null ? <div className='profile-img' onClick={handleClick}><img src={datass?.thumb_nail ? datass?.thumb_nail : profileiconstatic} alt="profile-icom-static" /></div> : <></>}
                      </> :

                      <>
                        {user !== null ? <div className='profile-img' onClick={handleClick}><img src={datass?.user_info?.thumb_nail ? datass?.user_info?.thumb_nail : profileiconstatic} alt="profile-icom-static" /></div> : <></>}
                      </>

                  }
                  {/* <div className='profile-detail-block'>
           <div className='profileicomstaticbig'><img src={profileicomstaticbig}/></div>
           <p>Allen Moreno</p>
           <span>allenmoreno@dtracer.io</span>
           <div className='profile-menu'><Link><PersonIcon/> My Profile</Link></div>
           <div className='profile-menu'><Link><MessageIcon/> Message</Link></div>
           <div className='profile-menu'><Link><AdjustIcon/> Activity</Link></div>
          </div> */}

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    className="profile-detail-block-new"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {
                      role === 'user' ?
                        <div className='profileicomstaticbig'>
                          <img src={datass?.coverPicture ? datass?.coverPicture : profileicomstaticbig} />
                        </div>
                        : role === 'admin' ?
                          <div className='profileicomstaticbig'>
                            <img src={datass?.thumb_nail ? datass?.thumb_nail : profileicomstaticbig} />
                          </div>
                          :
                          <div className='profileicomstaticbig'>
                            <img src={datass?.user_info?.thumb_nail ? datass?.user_info?.thumb_nail : profileicomstaticbig} />
                          </div>
                    }

                    <p>{userDetails?.firstname} {userDetails?.lastname} </p>
                    <span>{userDetails?.email}</span>
                    <Divider />
                    <div className='profile-menu'><Link to="/profile"><PersonIcon /> {t('components.header.profile')}</Link></div>
                    {role === 'admin' ? <div className='profile-menu'><Link to="/usersList"><PersonIcon /> {t('components.header.manageUsers')}</Link></div> : <></>}
                    <div onClick={handleOpenViewMessage} className='profile-menu message-new-menu'><Link><MessageIcon />{t('components.header.message')}</Link></div>
                    <div onClick={handleOpenViewActivity} className='profile-menu'><Link><AdjustIcon />{t('components.header.activity')}</Link></div>
                    <div className='faq-logout profile-menu' onClick={handleOpenViewFaq}><Link><HelpOutlineIcon />{t('components.header.faq')}</Link></div>
                    <div className='faq-logout'>
                      {userDetails?.role === 'brand' ? <div><Link to='/create-brand'><AddCircleOutlineIcon />{t('components.header.addBrand')}</Link></div> : <></>}
                      <Link onClick={() => { logout() }} ><LogoutIcon />{t('components.header.logout')}</Link>
                    </div>
                  </Menu>

                  <Modal
                    open={openViewMessage}
                    className='whole-notify-inbox-outer whole-notify-inbox-outer-chatbox'
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >

                    <Box sx={style}>

                      <Box className='msg-pop'
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', width: 1000, height: 500, overflow: 'scroll', justifyContent: 'flex-start', margin: '0 auto', borderRadius: '5px' }}>
                        <div className="menu" onClick={handleCloseViewMessage} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                        <Grid container>

                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                            <Tabs
                              orientation="vertical"
                              variant="scrollable"
                              value={value}
                              onChange={handleChange}
                              aria-label="Vertical tabs example"
                              sx={{ borderRight: 1, borderColor: 'divider', alignItems: 'center' }}
                            >
                              <Tab className='inbox-tab' label="inbox" {...a11yProps(0)} />
                              <Tab className='Create-Incitant-tab' label="Create Ticket" {...a11yProps(1)} />
                              <Tab className='Create-Incitant-tab' label="Closed Tickets" {...a11yProps(2)} />
                            </Tabs>
                          </Grid>

                          <Grid xs={12} sm={12} md={12} lg={9} xl={9}>

                            <TabPanel value={value} index={0} className="tab-content-whole-notify-inbox-outer">


                              <div className='whole-notify-inbox'>
                                {chat ? (<>
                                  <div>
                                    <PremiumChat TicketList={TicketList} ids={ids} createMessage={createMessage} message1={message1} setMessage1={setMessage1} messageList={messageList} handleChat={handleChat} />
                                  </div>
                                </>)
                                  : (
                                    <>
                                      {tickets.length > 0 ? <>
                                        {tickets && tickets?.map((ticket, index) => {
                                          return <div className='notify-message notify-message-all-view' key={index}>
                                            <div className='notify-message-detail' style={{ "display": "flex", "justifyContent": "space-between" }}>
                                              <div onClick={() => { handleChat(); getOneTicket(ticket?.room) }} className="opendiv" >
                                                {ticket?.unreadMessageCount > 0 ? <div> You Have {ticket?.unreadMessageCount} unread message</div> : <></>}
                                                <strong> <span>From :</span> {(String(ticket.requestor1) === ids) ? ticket?.receiver_name : ticket?.sender_name} </strong>
                                                <strong> <span>To :</span> {(String(ticket.requestor1) === ids) ? ticket?.sender_name : ticket?.receiver_name} </strong>

                                                {/* - <span>{ticket?.name}</span> */}
                                                <p>Title : {ticket?.name}</p>
                                                <p>Description : {ticket?.description}</p>
                                                <p>Poduct Id: {ticket?.product_id}</p>
                                                <div className='message-count-outer'>
                                                  <div className='message-count'> <label className='message-counter'>
                                                    <span>{moment(ticket.updatedAt).fromNow()}</span></label>

                                                    <div>
                                                      <img src={messgcount} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {(String(ticket.requestor2) === ids) ? <Button className="cancelbtn" variant="contained" onClick={() => { deleteTicket(ticket?._id, ticket?.product_id) }}>Close</Button> : <></>}
                                              {/* {(String(ticket.requestor2) === ids) ? <img src={cancel} onClick={() => { deleteTicket(ticket?._id) }} alt="cancel" /> : <></>} */}
                                              {/* onClick={() => { handleChat1(); getOneTicket(ticket?.room) }} */}
                                            </div>
                                            <div className='whole-inbox-action-btns'>

                                            </div>

                                          </div>

                                        })}


                                      </> : <>
                                        <div className='notify-message notify-message-all-view'>

                                          <div className='notify-message-detail'>
                                            <strong> No Tickets</strong>
                                          </div>

                                        </div>
                                      </>}
                                    </>
                                  )
                                }


                              </div>
                            </TabPanel>
                            <TabPanel value={value} index={1} className="tab-content-whole-notify-inbox-outer">
                              <div className='create-issue-form'>
                                <div className='text-field-issue'>
                                  {role === 'admin' ? <label>Select Brand</label> : <> {role === 'brand' ? <label>{/*Select Admin*/}</label> : <label>Select Brand</label>}</>}
                                  {role === 'brand' ?
                                    (<React.Fragment></React.Fragment>)
                                    //    (<div className='text-field-issue'>
                                    //    <TextField fullWidth id="outlined-basic"
                                    //      value={receivers[0]?.name}
                                    //     //  onChange={(e) => setId(e.target.value)}
                                    //      variant="outlined" />
                                    //  </div>)
                                    :
                                    (<TextField
                                      fullWidth
                                      value={id}
                                      onChange={(e) => { setId(e.target.value); setidErr(null) }}
                                      id="outlined-select-currency"
                                      select
                                      defaultValue="EUR"
                                    >

                                      {receivers && receivers?.map((rec, index) => {
                                        return <MenuItem key={index} value={rec?.id}>{rec?.name}</MenuItem>
                                      })}

                                    </TextField>
                                    )
                                  }
                                  {iderr !== null ? (
                                    <div style={{ color: "red", textAlign: 'center' }}>{iderr}</div>
                                  ) : (
                                    <></>
                                  )}

                                </div>
                                <div className='text-field-issue'>
                                  <label>Product Id</label>
                                  <TextField fullWidth id="outlined-basic"
                                    value={pro_id}
                                    onChange={(e) => setProductId(e.target.value)}
                                    variant="outlined" />
                                </div>
                                <div className='text-field-issue'>
                                  <label>Customer</label>
                                  <TextField fullWidth id="outlined-basic"
                                    value={customers}
                                    onChange={(e) => { setCustomer(e.target.value); setcusErr(null) }}
                                    variant="outlined" />
                                  {cuserr !== null ? (
                                    <div style={{ color: "red", textAlign: 'center' }}>{cuserr}</div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className='text-field-issue'>
                                  <label>Title</label>
                                  <TextField fullWidth id="outlined-basic"
                                    value={name}
                                    onChange={(e) => { setName(e.target.value); setnameErr(null) }}
                                    variant="outlined" />
                                  {nameerr !== null ? (
                                    <div style={{ color: "red", textAlign: 'center' }}>{nameerr}</div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className='text-field-issue'>
                                  <label>Description</label>
                                  <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    fullWidth
                                    value={message}
                                    onChange={(e) => { setMessage(e.target.value); setdesErr(null) }}
                                    maxRows={4}
                                  />
                                  {deserr !== null ? (
                                    <div style={{ color: "red", textAlign: 'center' }}>{deserr}</div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {/* <Link className='Add-More-Details' to="">Add More Details</Link> */}
                                <div className='Create-Issue-btn-submit'>
                                  <Button variant="contained" onClick={() => { createTicket() }}>Create Issue</Button>
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel value={value} index={2} className="tab-content-whole-notify-inbox-outer">


                              <div className='whole-notify-inbox'>
                                {chat1 ? (<>
                                  <div>
                                    <CompleteChat TicketList={TicketList} ids={ids} messageList={messageList} handleChat1={handleChat1} />
                                  </div>
                                </>)
                                  : (
                                    <>
                                      {CompletedTickets.length > 0 ? <>
                                        {CompletedTickets && CompletedTickets?.map((ticket, index) => {
                                          return <div className='notify-message notify-message-all-view' key={index}>
                                            <div className='notify-message-detail' style={{ "display": "flex", "justifyContent": "space-between" }}>
                                              <div onClick={() => { handleChat1(); getOneTicket(ticket?.room) }} className="opendiv" >
                                                <strong> <span>From :</span> {(String(ticket.requestor1) === ids) ? ticket?.receiver_name : ticket?.sender_name} </strong>
                                                <strong> <span>To :</span> {(String(ticket.requestor1) === ids) ? ticket?.sender_name : ticket?.receiver_name} </strong>
                                                {/* - <span>{ticket?.name}</span> */}
                                                <p>Title : {ticket?.name}</p>
                                                <p>Description : {ticket?.description}</p>
                                                <p>Poduct Id: {ticket?.product_id}</p>
                                                <div className='message-count-outer'>
                                                  <div className='message-count'> <label className='message-counter'>
                                                    <span>{moment(ticket.updatedAt).fromNow()}</span></label>

                                                    <div>
                                                      <img src={messgcount} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* {(String(ticket.requestor2) === ids) ?  <Button className ="cancelbtn" variant="contained" onClick={() => { deleteTicket(ticket?._id) }}>Cancel</Button>:<></>} */}

                                            </div>

                                            <div className='whole-inbox-action-btns'>

                                            </div>
                                          </div>

                                        })}


                                      </> : <>
                                        <div className='notify-message notify-message-all-view'>

                                          <div className='notify-message-detail'>
                                            <strong> No Tickets</strong>
                                          </div>

                                        </div>
                                      </>}
                                    </>
                                  )
                                }


                              </div>

                            </TabPanel>
                          </Grid>


                        </Grid>


                        {/* <div className='notify-message notify-message-all-view'>
                              <div className='notify-message-img'><img src={profileicomstaticbig} /></div>
                              <div className='notify-message-detail'>
                                <strong>Jake jungle <span>Assigned  PROJ-3 Investigate Memory
                                  Leakage on Lambd....</span> to you</strong>
                                <div className='message-count-outer'><div className='message-count'> <label className='message-counter'><span>0</span>m</label> <div><img src={messgcount} /></div></div><Button variant="contained">To do</Button></div>
                              </div>
                              <div className='notify-message-action'>
                                <div className='action-icon'><img src={moreicon} alt="moreicon" /></div>
                                <div className='action-icon'><img src={settime} alt="settime" /></div>
                                <div className='action-icon'><img src={cancel} alt="cancel" /></div>
                              </div>
                            </div> */}



                        {/* </div>
                        </TabPanel> */}


                      </Box>
                    </Box>
                  </Modal>


                  {<Modal
                    open={openViewActivity}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX message-popup">

                      <div className="menu" onClick={handleCloseViewActivity} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


                      <div className="headTitle">Activity Status</div>

                      <div className="listOne">

                        <div className='SellerPaidTable'>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Name</TableCell>
                                  <TableCell align="center">Event Info</TableCell>
                                  <TableCell align="center">Detection IP</TableCell>
                                  <TableCell align="center">Detection Time</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Activity?.map((row, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell align="center">{row.calories}</TableCell>
                                    <TableCell align="center">{row?.status}</TableCell>
                                    <TableCell align="center">{row?.ip}</TableCell>
                                    <TableCell align="center">{row.updatedAt}</TableCell>
                                  </TableRow>
                                ))}
                                {
                                  Activity?.length === 0 ?
                                    <>
                                      <TableRow

                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell align="center" colSpan={4}>No Activity Found</TableCell>
                                      </TableRow></>
                                    :
                                    <></>
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>


                    </Box>
                  </Modal>}

                  {/* Faq popup */}

                  {<Modal
                    open={openViewFaq}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX message-popup">

                      <div className="menu" onClick={handleCloseViewFaq} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                      <div className='faq-head-body'>

                        <div className="headTitle">{t('components.header.modalFaqs.title')}</div>

                        <div className='faq-body'>
                          <div className="headTitle">Blockchain</div>

                          <Accordion expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='faq-qus-title'>
                              <Typography>Can polygon disapper from the blockchain listing?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqBlockchain}>Blockchain</Link></p>
                                <p>Not likely today, but anything can happen, but don’t worry. From Dtracer we are prepared to adapt our technology at any time since it is a modular system.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className='faq-qus-title'>
                              <Typography>Do I have to invest in th blockchain to work with Dtracer?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqBlockchain}>Blockchain</Link></p>
                                <p>No, they are two separate things, one is the public investment and the other is the technology, knowing how to differentiate this section to use the Dtracer system is not necessary to invest in the blockchain.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel3'} onChange={handleChangePanel('panel3')}>
                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className='faq-qus-title'>
                              <Typography> The blockchain is public?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqBlockchain}>Blockchain</Link></p>
                                <p>Yes, all the records that are made in the blockchain are public records that are published in the ledger.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel4'} onChange={handleChangePanel('panel4')}>
                            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" className='faq-qus-title'>
                              <Typography>The Blockchain is saft?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqBlockchain}>Blockchain</Link></p>
                                <p>Yes, the blockchain is a conceptually very secure technology that is exposed in the course of its implementation to errors and vulnerabilities typical of any information system, added to those specific to this technology.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel5'} onChange={handleChangePanel('panel5')}>
                            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" className='faq-qus-title'>
                              <Typography>What happens if the stock market crashes or increases a lot?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqBlockchain}>Blockchain</Link></p>
                                <p>There is no problem as DTracer does not focus on the investment of the assets, you will not suffer any loss, since we focus on the technology.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>


                          <Accordion expanded={expanded === 'panel6'} onChange={handleChangePanel('panel6')}>
                            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" className='faq-qus-title'>
                              <Typography>What technology does DTracer use within th blockchain?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqBlockchain}>Blockchain</Link></p>
                                <p>In DTracer we use Polygon technology (matic) it is a layer 2 of the etherum network</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>


                        </div>


                        <div className='faq-body'>
                          <div className="headTitle">NFT</div>

                          <Accordion expanded={expanded === 'panel7'} onChange={handleChangePanel('panel7')}>
                            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" className='faq-qus-title'>
                              <Typography>Can I lose my product registration,registered as NFT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>No, the NTFs are kept in your own wallet and if you lose your wallet from Dtracer we store a backup to recover the NFTs and prove authorship.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel8'} onChange={handleChangePanel('panel8')}>
                            <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" className='faq-qus-title'>
                              <Typography>Can one product be the same as another?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>No, from Dtracer we treat each product as unique and registered on the network as NFT for each reference we make a record.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel9'} onChange={handleChangePanel('panel9')}>
                            <AccordionSummary aria-controls="panel9d-content" id="panel9d-header" className='faq-qus-title'>
                              <Typography> What are NFTs used for?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>NFTs can be used to indicate ownership of assets that represent real-world items, such as real estate and works of art and products.</p>
                                <p>However, it is important to note that the purchase of an NFT that includes the copyright of the original artwork does not automatically transfer the copyright, for this the transfer must be authorized by authorized sellers.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel10'} onChange={handleChangePanel('panel10')}>
                            <AccordionSummary aria-controls="panel10d-content" id="panel10d-header" className='faq-qus-title'>
                              <Typography>What is an NFT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>A non-fungible token, or NFT for short, is an encrypted digital asset, a special type of cryptographic token that represents something unique.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel11'} onChange={handleChangePanel('panel11')}>
                            <AccordionSummary aria-controls="panel11d-content" id="panel11d-header" className='faq-qus-title'>
                              <Typography>What is the future of NFTs?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>The possibilities of NFTs are endless as they can be used to record ownership of any single asset. The use of NFTs as event tickets, software licenses, fan club memberships, or other interactive experiences is already a common use case for NFTs.</p>
                                <p>Therefore, its use is becoming more and more widespread in different media, we do not rule out that in the future the same personal documentation will be an NTF, so answering the question we firmly believe that it will not only be part of the future but that it will also be useful for many public administrations.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                        </div>


                        <div className='faq-body'>
                          <div className="headTitle">How the Dtracer system works</div>

                          <Accordion expanded={expanded === 'panel12'} onChange={handleChangePanel('panel12')}>
                            <AccordionSummary aria-controls="panel12d-content" id="panel12d-header" className='faq-qus-title'>
                              <Typography>Can DTracer recall a product?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>No, from Dtracer you cannot withdraw any product once it has been created as NFT and this product always belongs to the brand or manufacturer, not to DTRACER.</p>
                                <p> At this point, before registering a product in the blockchain network, preliminary reviews are carried out so that it complies with what is described.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel13'} onChange={handleChangePanel('panel13')}>
                            <AccordionSummary aria-controls="panel13d-content" id="panel13d-header" className='faq-qus-title'>
                              <Typography>DTRACER can block a product?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>Yes, we can block the product in the creation of the NFT or in the movement of the NFT for the following reasons.</p>
                                <p>1) the product entered in the system does not correspond to the description, images, certifications…</p>
                                <p>2) due to the infringement by the brand or the manufacturer of the intellectual property of the product</p>
                                <p>3) by express judicial order in claim of coming</p>
                                <p>4) by express order of the public administrations.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel14'} onChange={handleChangePanel('panel14')}>
                            <AccordionSummary aria-controls="panel14d-content" id="panel14d-header" className='faq-qus-title'>
                              <Typography> How do I register my products in the DTRACER system?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>Currently there are 3 ways to enter the products in the DTracer system, the 1 x 1 method that is entered product by product, the CSV method, by means of a list all the references of the brands are loaded and finally the API method for those brands with department of IT carry out the synchronization automatically (we still have the latter in the development phase, when it is available we will notify all our associates).</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel15'} onChange={handleChangePanel('panel15')}>
                            <AccordionSummary aria-controls="panel15d-content" id="panel15d-header" className='faq-qus-title'>
                              <Typography>Is an NFT automatically generated for all products registered in DTRACER?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>No, you need a validation by the Dtracer team to verify that the registration by the brands is correct, once this validation is passed, the product is registered in the blockchain network.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>


                        <div className='faq-body'>
                          <div className="headTitle">Work at Dtracer</div>

                          <Accordion expanded={expanded === 'panel16'} onChange={handleChangePanel('panel16')}>
                            <AccordionSummary aria-controls="panel16d-content" id="panel16d-header" className='faq-qus-title'>
                              <Typography>Can I work from any country?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqWork}>Work at Dtracer</Link></p>
                                <p>Yes, at Dtracer we have a vision of the future where it is not necessary to be in an office working during specific hours, so we offer the possibility of working from anywhere in the world.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel17'} onChange={handleChangePanel('panel17')}>
                            <AccordionSummary aria-controls="panel17d-content" id="panel17d-header" className='faq-qus-title'>
                              <Typography>Do I have the benefits of the company from day one?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqWork}>Work at Dtracer</Link></p>
                                <p>Yes, absolutely from the beginning that you work with us you have all the benefits assigned.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>


                        </div>



                      </div>

                    </Box>
                  </Modal>}

                  {/* Blockchain popoup */}

                  {<Modal
                    open={openViewFaqBlockchain}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX message-popup ppp">

                      <div className="menu faq-menus" onClick={handleCloseViewFaqBlockchain} >
                        <Link onClick={handleOpenViewFaq} className='togglebtn-close'><img src={Menuclose} alt="Menuclose" /></Link>
                        <Link><img src={closeMenu} alt="closeMenu" /></Link>
                      </div>

                      <div className='faq-head-body'>
                        <div className="headTitle">FAQs Of Blockchain</div>


                        <div className='faq-body'>

                          <Accordion expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='faq-qus-title'>
                              <Typography>Can polygon disapper from the blockchain listing?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link>Blockchain</Link></p>
                                <p>Not likely today, but anything can happen, but don’t worry. From Dtracer we are prepared to adapt our technology at any time since it is a modular system.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className='faq-qus-title'>
                              <Typography>Do I have to invest in th blockchain to work with Dtracer?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link>Blockchain</Link></p>
                                <p>No, they are two separate things, one is the public investment and the other is the technology, knowing how to differentiate this section to use the Dtracer system is not necessary to invest in the blockchain.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel3'} onChange={handleChangePanel('panel3')}>
                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className='faq-qus-title'>
                              <Typography> The blockchain is public?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link>Blockchain</Link></p>
                                <p>Yes, all the records that are made in the blockchain are public records that are published in the ledger.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel4'} onChange={handleChangePanel('panel4')}>
                            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" className='faq-qus-title'>
                              <Typography>The Blockchain is saft?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link>Blockchain</Link></p>
                                <p>Yes, the blockchain is a conceptually very secure technology that is exposed in the course of its implementation to errors and vulnerabilities typical of any information system, added to those specific to this technology.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel5'} onChange={handleChangePanel('panel5')}>
                            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" className='faq-qus-title'>
                              <Typography>What happens if the stock market crashes or increases a lot?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link>Blockchain</Link></p>
                                <p>There is no problem as DTracer does not focus on the investment of the assets, you will not suffer any loss, since we focus on the technology.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>


                          <Accordion expanded={expanded === 'panel6'} onChange={handleChangePanel('panel6')}>
                            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" className='faq-qus-title'>
                              <Typography>What technology does DTracer use within th blockchain?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link>Blockchain</Link></p>
                                <p>In DTracer we use Polygon technology (matic) it is a layer 2 of the etherum network</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>


                        </div>

                      </div>

                    </Box>
                  </Modal>}


                  {/* Nft popup */}

                  {<Modal
                    open={openViewFaqNFT}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX message-popup ppp">

                      <div className="menu faq-menus" onClick={handleCloseViewFaqNFT} >
                        <Link onClick={handleOpenViewFaq} className='togglebtn-close'><img src={Menuclose} alt="Menuclose" /></Link>
                        <Link><img src={closeMenu} alt="closeMenu" /></Link>
                      </div>

                      <div className='faq-head-body'>

                        <div className="headTitle">FAQs Of NFT</div>

                        <div className='faq-body'>
                          <div className="headTitle">NFT</div>

                          <Accordion expanded={expanded === 'panel7'} onChange={handleChangePanel('panel7')}>
                            <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" className='faq-qus-title'>
                              <Typography>Can I lose my product registration,registered as NFT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>No, the NTFs are kept in your own wallet and if you lose your wallet from Dtracer we store a backup to recover the NFTs and prove authorship.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel8'} onChange={handleChangePanel('panel8')}>
                            <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" className='faq-qus-title'>
                              <Typography>Can one product be the same as another?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>No, from Dtracer we treat each product as unique and registered on the network as NFT for each reference we make a record.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel9'} onChange={handleChangePanel('panel9')}>
                            <AccordionSummary aria-controls="panel9d-content" id="panel9d-header" className='faq-qus-title'>
                              <Typography> What are NFTs used for?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>NFTs can be used to indicate ownership of assets that represent real-world items, such as real estate and works of art and products.</p>
                                <p>However, it is important to note that the purchase of an NFT that includes the copyright of the original artwork does not automatically transfer the copyright, for this the transfer must be authorized by authorized sellers.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel10'} onChange={handleChangePanel('panel10')}>
                            <AccordionSummary aria-controls="panel10d-content" id="panel10d-header" className='faq-qus-title'>
                              <Typography>What is an NFT?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>A non-fungible token, or NFT for short, is an encrypted digital asset, a special type of cryptographic token that represents something unique.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel11'} onChange={handleChangePanel('panel11')}>
                            <AccordionSummary aria-controls="panel11d-content" id="panel11d-header" className='faq-qus-title'>
                              <Typography>What is the future of NFTs?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqNFT}>NFT</Link></p>
                                <p>The possibilities of NFTs are endless as they can be used to record ownership of any single asset. The use of NFTs as event tickets, software licenses, fan club memberships, or other interactive experiences is already a common use case for NFTs.</p>
                                <p>Therefore, its use is becoming more and more widespread in different media, we do not rule out that in the future the same personal documentation will be an NTF, so answering the question we firmly believe that it will not only be part of the future but that it will also be useful for many public administrations.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                        </div>

                      </div>

                    </Box>
                  </Modal>}


                  {/* System popup */}

                  {<Modal
                    open={openViewFaqSystem}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX message-popup ppp">

                      <div className="menu faq-menus" onClick={handleCloseViewFaqSystem} >
                        <Link onClick={handleOpenViewFaq} className='togglebtn-close'><img src={Menuclose} alt="Menuclose" /></Link>
                        <Link><img src={closeMenu} alt="closeMenu" /></Link>
                      </div>

                      <div className='faq-head-body'>

                        <div className="headTitle">FAQs Of How the DTRACER System Works</div>

                        <div className='faq-body'>
                          <div className="headTitle">How the Dtracer system works</div>

                          <Accordion expanded={expanded === 'panel12'} onChange={handleChangePanel('panel12')}>
                            <AccordionSummary aria-controls="panel12d-content" id="panel12d-header" className='faq-qus-title'>
                              <Typography>Can DTracer recall a product?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>No, from Dtracer you cannot withdraw any product once it has been created as NFT and this product always belongs to the brand or manufacturer, not to DTRACER.</p>
                                <p> At this point, before registering a product in the blockchain network, preliminary reviews are carried out so that it complies with what is described.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel13'} onChange={handleChangePanel('panel13')}>
                            <AccordionSummary aria-controls="panel13d-content" id="panel13d-header" className='faq-qus-title'>
                              <Typography>DTRACER can block a product?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>Yes, we can block the product in the creation of the NFT or in the movement of the NFT for the following reasons.</p>
                                <p>1) the product entered in the system does not correspond to the description, images, certifications…</p>
                                <p>2) due to the infringement by the brand or the manufacturer of the intellectual property of the product</p>
                                <p>3) by express judicial order in claim of coming</p>
                                <p>4) by express order of the public administrations.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel14'} onChange={handleChangePanel('panel14')}>
                            <AccordionSummary aria-controls="panel14d-content" id="panel14d-header" className='faq-qus-title'>
                              <Typography> How do I register my products in the DTRACER system?  </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>Currently there are 3 ways to enter the products in the DTracer system, the 1 x 1 method that is entered product by product, the CSV method, by means of a list all the references of the brands are loaded and finally the API method for those brands with department of IT carry out the synchronization automatically (we still have the latter in the development phase, when it is available we will notify all our associates).</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion expanded={expanded === 'panel15'} onChange={handleChangePanel('panel15')}>
                            <AccordionSummary aria-controls="panel15d-content" id="panel15d-header" className='faq-qus-title'>
                              <Typography>Is an NFT automatically generated for all products registered in DTRACER?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqSystem}>How the tracer system works </Link></p>
                                <p>No, you need a validation by the Dtracer team to verify that the registration by the brands is correct, once this validation is passed, the product is registered in the blockchain network.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>


                      </div>

                    </Box>
                  </Modal>}


                  {/* Work popup */}

                  {<Modal
                    open={openViewFaqWork}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="popBOX message-popup ppp">

                      <div className="menu faq-menus" onClick={handleCloseViewFaqWork} >
                        <Link onClick={handleOpenViewFaq} className='togglebtn-close'><img src={Menuclose} alt="Menuclose" /></Link>
                        <Link><img src={closeMenu} alt="closeMenu" /></Link>
                      </div>

                      <div className='faq-head-body'>

                        <div className="headTitle">FAQs Of Work at Dtracer</div>

                        <div className='faq-body'>
                          <div className="headTitle">Work at Dtracer</div>

                          <Accordion expanded={expanded === 'panel16'} onChange={handleChangePanel('panel16')}>
                            <AccordionSummary aria-controls="panel16d-content" id="panel16d-header" className='faq-qus-title'>
                              <Typography>Can I work from any country?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                {/* <label className='category-label'>Category : <span><Link to='/blockchain'>Blockchain</Link></span></label> */}
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqWork}>Work at Dtracer</Link></p>
                                <p>Yes, at Dtracer we have a vision of the future where it is not necessary to be in an office working during specific hours, so we offer the possibility of working from anywhere in the world.
                                </p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion expanded={expanded === 'panel17'} onChange={handleChangePanel('panel17')}>
                            <AccordionSummary aria-controls="panel17d-content" id="panel17d-header" className='faq-qus-title'>
                              <Typography>Do I have the benefits of the company from day one?</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='faq-ans-para'>
                              <Typography>
                                <p className='faq-link'>Category: <Link onClick={handleOpenViewFaqWork}>Work at Dtracer</Link></p>
                                <p>Yes, absolutely from the beginning that you work with us you have all the benefits assigned.</p>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>


                        </div>



                      </div>

                    </Box>
                  </Modal>}


                </div>
              </div>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box >


  )
}

export default Header
