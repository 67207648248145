import React, { useState, useEffect } from 'react'
import Axios from '../../../Axios';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MonthlyChart from '../../Brand/BrandPostSales/MonthlyChart'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import YearlyChart from './YearlyChart';
import AdminFilterPopup from '../AdminPostSales/AdminFilterPopup';
import AdminSettingPopup from '../AdminPostSales/AdminSettingPopup';
import AdminProductsBody from '../AdminPostSales/AdminProductsBody';
import Pagination from '@mui/material/Pagination';
import dollarClick from '../../../img/dollarClick.png';
import actionCross from '../../../img/action-ps-2.png';
import CancelIcons from '../../../img/CancelIconAPP.png';
import pendPaid from '../../../img/pendPaid.svg';
import PrintPop from './PrintPop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from 'react-hot-toast'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});


const AdminPostSalesBody = () => {

  const classes = useStyles();
  const [priceData, setpriceData] = useState([])
  const [lastpriceData, setlastpriceData] = useState([])
  const [this_Week, setThisWeek] = useState([])
  const [last_Week, setLastWeek] = useState([])
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const handleChange1 = (event, value) => {
    setPage(value);
  };
  const [isFilter, setIisfilter] = useState(false)
  const [product, setProduct] = useState([])
  const [productFull, setProductFull] = useState([])
  const [selecteds, setSelecteds] = useState([])
  const [orderId, setorderId] = useState()
  const [product_id, setproduct_id] = useState()
  const [brand_id, setbrand_id] = useState()
  const [ean, setean] = useState()
  const [paid, setpaid] = useState(false)
  const [unpaid, setunpaid] = useState(false)
  const [refund, setrefund] = useState(false)
  const [stripe, setstripe] = useState(false)
  const [bank, setbank] = useState(false)
  const [creationDate, setCreationDate] = useState([])
  const [creation1Date, setCreation1Date] = useState([])
  const [endDate, setEndDate] = useState([])
  const [end1Date, setEnd1Date] = useState([])
  const [paymentDate, setPaymentDate] = useState([])
  const [endpaymentDate, setendPaymentDate] = useState([])

  useEffect(() => {
    products()
    getGraphs()
    // graphdata()
  }, [])


  useEffect(() => {

    if (isFilter === false) {
      products()
      getGraphs()
    }
    else {
      productfilter()
      getfilterGraphs()
    }
  }, [page, isFilter])

  // const handlecreationdatechange = (d, m, y) => {

  //   var credate = y + '-' + m + '-' + d

  //   setCreationDate(credate)

  //   if ((paymentDate !== undefined) && (paymentDate.length !== 0)) {


  //     filterbyDate(credate)
  //   }

  // }

  // const handleenddatechange = (d, m, y) => {

  //   var endDates = y + '-' + m + '-' + d

  //   setEndDate(endDates)
  //   if ((creationDate !== undefined) && (creationDate.length !== 0)) {
  //          filterbyDate1(payDate)
  //   }

  // }

  // const handlepaymentdatechange = (d, m, y) => {

  //   var payDate = y + '-' + m + '-' + d

  //   setPaymentDate(payDate)
  //   // if ((creationDate !== undefined) && (creationDate.length !== 0)) {
  //   //        filterbyDate1(payDate)
  //   // }

  // }


  const products = async () => {
    try {
      const { data } = await Axios.post(`/admin/getPostSale`, {
        page: page - 1,
        limit: 10
      }, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      console.log("🚀 ~ products ~ data:", data)
      if (data?.result?.length > 0) {
        setProduct(data?.result)
        setProductFull(data?.export)
        setCount(Math.ceil(data?.count / 10))
      }
    } catch (error) {
    }

  }

  const productfilter = async () => {
    try {
      setProduct([])
      setIisfilter(true)
      getfilterGraphs()
      const { data } = await Axios.post(`/admin/getPostSale`, {
        order_id: orderId,
        product_id: product_id,
        brand_id: brand_id,
        ean: ean,
        paid: paid,
        unpaid: unpaid,
        refund: refund,
        bank: bank,
        stripe: stripe,
        creation_date: creationDate,
        creation1_date: creation1Date,
        end_date: endDate,
        end1_date: end1Date,
        payend_date: endpaymentDate,
        pay_date: paymentDate,
        page: page - 1,
        limit: 10
      }, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })

      if (data?.result?.length > 0) {
        setCount(Math.ceil(data?.count / 10))
        setProduct(data?.result)
        setProductFull(data?.export)
      }
    } catch (error) {
      console.log("AdminProductsBody:", error)
    }

  }

  const productfilterss = async () => {
    handleChange1(1)
    productfilter()
  }

  const getGraphs = async () => {
    try {


      const { data } = await Axios.post(`/admin/getsale_graph`, '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {

          var graphDetails = []
          var priceDetail = []
          var lastpriceDetail = []
          var weekpriceDetail = []
          var lastweekpriceDetail = []

          for (var i = 0; i < data?.result?.month.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.price[i] === undefined || data?.result?.price[i] === null || data?.result?.price[i] === "null") {
              priceDetail.push(0)
            }
            else {
              priceDetail.push(data?.result?.price[i])
            }
            if (data?.result?.lastprice[i] === undefined || data?.result?.lastprice[i] === null || data?.result?.lastprice[i] === "null") {
              lastpriceDetail.push(0)
            }
            else {
              lastpriceDetail.push(data?.result?.lastprice[i])
            }
          }
          setpriceData(priceDetail)
          setlastpriceData(lastpriceDetail)
          for (var i = 0; i < data?.result?.week.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.weekprice[i] === undefined || data?.result?.weekprice[i] === null || data?.result?.weekprice[i] === "null") {
              weekpriceDetail.push(0)
            }
            else {
              weekpriceDetail.push(data?.result?.weekprice[i])
            }
            if (data?.result?.lastweekprice[i] === undefined || data?.result?.lastweekprice[i] === null || data?.result?.lastweekprice[i] === "null") {
              lastweekpriceDetail.push(0)
            }
            else {
              lastweekpriceDetail.push(data?.result?.lastweekprice[i])
            }
          }
          setThisWeek(weekpriceDetail)
          setLastWeek(lastweekpriceDetail)

        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }
  const getfilterGraphs = async () => {
    try {


      const { data } = await Axios.post(`/admin/getsale_graph`, {
        order_id: orderId,
        product_id: product_id,
        brand_id: brand_id,
        ean: ean,
        paid: paid,
        unpaid: unpaid,
        refund: refund,
        bank: bank,
        stripe: stripe,
        creation_date: creationDate,
        creation1_date: creation1Date,
        end_date: endDate,
        end1_date: end1Date,
        payend_date: endpaymentDate,
        pay_date: paymentDate,
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {

          var graphDetails = []
          var priceDetail = []
          var lastpriceDetail = []
          var weekpriceDetail = []
          var lastweekpriceDetail = []

          for (var i = 0; i < data?.result?.month.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.price[i] === undefined || data?.result?.price[i] === null || data?.result?.price[i] === "null") {
              priceDetail.push(0)
            }
            else {
              priceDetail.push(data?.result?.price[i])
            }
            if (data?.result?.lastprice[i] === undefined || data?.result?.lastprice[i] === null || data?.result?.lastprice[i] === "null") {
              lastpriceDetail.push(0)
            }
            else {
              lastpriceDetail.push(data?.result?.lastprice[i])
            }
          }
          setpriceData(priceDetail)
          setlastpriceData(lastpriceDetail)
          for (var i = 0; i < data?.result?.week.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.weekprice[i] === undefined || data?.result?.weekprice[i] === null || data?.result?.weekprice[i] === "null") {
              weekpriceDetail.push(0)
            }
            else {
              weekpriceDetail.push(data?.result?.weekprice[i])
            }
            if (data?.result?.lastweekprice[i] === undefined || data?.result?.lastweekprice[i] === null || data?.result?.lastweekprice[i] === "null") {
              lastweekpriceDetail.push(0)
            }
            else {
              lastweekpriceDetail.push(data?.result?.lastweekprice[i])
            }
          }
          setThisWeek(weekpriceDetail)
          setLastWeek(lastweekpriceDetail)


        }
        else {
          setpriceData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
          setlastpriceData([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
          setThisWeek([0, 0, 0, 0, 0, 0, 0])
          setLastWeek([0, 0, 0, 0, 0, 0, 0])
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }
  const resetFilters = () => {
    setorderId()
    setproduct_id()
    setbrand_id()
    setpaid(false)
    setunpaid(false)
    setrefund(false)
    setstripe(false)
    setbank(false)
    setCreationDate([])
    setEndDate([])
    setCreation1Date([])
    setEnd1Date([])
    setPaymentDate([])
    setendPaymentDate([])

    products()
    getGraphs()
  };

  const [canMultiple, setCanMultiple] = useState([])

  const cancelMulti = (data, type) => {
    if (type == "single") {
      if (canMultiple?.includes(data)) {
        setCanMultiple(canMultiple.filter((selectedItem) => selectedItem != data));
      } else {
        setCanMultiple([...canMultiple, data])
      }
    } else {
      setCanMultiple(data)
    }

  }

  const cancelMultipleItem = async (status) => {
    try {
      const { data } = await Axios.post('/users/postsaleBlock', {
        id: canMultiple,
        // reason: reason,
        status: status,
        type: "multiple"
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success == true) {
        toast.success(data?.message)
        // products()
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        // handleCloseDeclinePopUp()
      } else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ cancelMultipleItem ~ error:", error)
    }
  }

  const cancelMultipleBrandPaid = async (status) => {
    try {
      const { data } = await Axios.post('/users/postsalesBrandAmount', {
        id: canMultiple,
        status: status,
        type: "multiple"
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success == true) {
        toast.success(data?.message)
        window.location.reload()
        // products()
      } else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ cancelMultipleBrandPaid ~ error:", error)
    }
  }

  return (
    <div className='AdminPostSalesBody'>

      <Box sx={{ flexGrow: 1 }}>

        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Item className={classes.dashboargraph} id="dashboargraph">

              <div className='filter-btn'>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className='filter-filed'>
                    <div className='filter-box'>
                      <AdminFilterPopup orderId={orderId} setorderId={setorderId} product_id={product_id} setproduct_id={setproduct_id} isFilter={isFilter} setIisfilter={setIisfilter}
                        brand_id={brand_id} setbrand_id={setbrand_id} ean={ean} setean={setean} paid={paid} setpaid={setpaid} unpaid={unpaid} setunpaid={setunpaid} refund={refund} setrefund={setrefund}
                        products={products} getGraphs={getGraphs} stripe={stripe} setstripe={setstripe} bank={bank} setbank={setbank}
                        creationDate={creationDate} setCreationDate={setCreationDate}
                        endDate={endDate} setEndDate={setEndDate}
                        creation1Date={creation1Date} setCreation1Date={setCreation1Date}
                        end1Date={end1Date} setEnd1Date={setEnd1Date}
                        paymentDate={paymentDate} setPaymentDate={setPaymentDate}
                        endpaymentDate={endpaymentDate} setendPaymentDate={setendPaymentDate}
                        productfilter={productfilterss} />
                      <Button className='reset' onClick={() => { resetFilters() }}>Reset filter</Button>
                      <AdminSettingPopup />
                    </div>
                  </div>

                </Grid>
              </div>


            </Item>
          </Grid>

        </Grid>


        <Grid container spacing={0} className={classes.containwidth} style={{ marginTop: "50px" }} >

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Item className={classes.dashboargraph} id="dashboargraph">

              {/* <div className='start-end-date'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'DatePicker',
                      'DateTimePicker',
                      'TimePicker',
                      'DateRangePicker',
                    ]}
                  >
                    <DemoItem label="Start Date">
                      <DatePicker
                        defaultValue={yesterday}
                        
                        views={['year', 'month', 'day']}
                      />
                    </DemoItem>

                    <DemoItem label="End Date">
                      <DatePicker
                        defaultValue={yesterday}
                        
                        views={['year', 'month', 'day']}
                      />
                    </DemoItem>

                  </DemoContainer>
                </LocalizationProvider>
              </div> */}

              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>
                  {/* <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button> */}
                </div>
                {/* <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <div className='years-part'>
                      <h5>Total revenue</h5>
                      <div>
                        <p><FiberManualRecordIcon color='primary' />Previous Year</p>
                      </div>
                      <div>
                        <p><FiberManualRecordIcon  style={{ color: '#00e396' }} />Current Year</p>
                      </div>
                    </div>


                    <p>Sales performance for Previous Year and Current Year </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div> */}

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <div className='years-part'>
                      <h5>Total revenue</h5>
                      <div>
                        <p><FiberManualRecordIcon color='primary' />Previous Year</p>
                      </div>
                      <div>
                        <p><FiberManualRecordIcon style={{ color: '#00e396' }} />Current Year</p>
                      </div>
                    </div>


                    <p>Sales performance for Previous Year and Current Year </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                  <MonthlyChart lastpriceData={lastpriceData} priceData={priceData} />
                </div>

                <div className='dashboargraph-inner-bottom'>

                </div>

              </div>


            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.rightpartstyle}>
            <Item className={classes.dashboargraph} id="dashboargraph">

              {/* <div className='start-end-date'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'DatePicker',
                      'DateTimePicker',
                      'TimePicker',
                      'DateRangePicker',
                    ]}
                  >
                    <DemoItem label="Start Date">
                      <DatePicker
                        defaultValue={yesterday}
                        
                        views={['year', 'month', 'day']}
                      />
                    </DemoItem>

                    <DemoItem label="End Date">
                      <DatePicker
                        defaultValue={yesterday}
                        
                        views={['year', 'month', 'day']}
                      />
                    </DemoItem>

                  </DemoContainer>
                </LocalizationProvider>
              </div> */}

              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>
                  {/* <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button> */}
                </div>


                <div className='dashboargraph-inner-mid'>
                  <YearlyChart priceData={priceData} />
                  {/* <DashboardLineChart priceData={priceData}  /> */}
                </div>

              </div>


            </Item>
          </Grid>

        </Grid>

        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.dashboargraph} id="dashboargraph">

              <AdminProductsBody product={product} products={products} orderId={orderId} selecteds={selecteds} setSelecteds={setSelecteds} cancelMulti={cancelMulti} />

            </Item>
          </Grid>

          <Grid container spacing={0} className={classes.containwidth}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='belowBtn'>

                <div className='download-keys'>
                  {/* <div className='save'><img src={dollarClick} alt={dollarClick} /></div> */}
                  <div className='print' onClick={() => { cancelMultipleBrandPaid(true) }}><img src={dollarClick} alt={dollarClick} /> <div>Paid to Brand</div></div>
                  <div className='print' onClick={() => { cancelMultipleBrandPaid(false) }}><img src={actionCross} alt={actionCross} /> <div>Not Paid to Brand </div></div>
                  <PrintPop bill={selecteds} />
                  {/* <div className='print'><img src={actionupload} alt={actionupload} /></div> */}
                </div>

                <div className='download-keys right'>
                  {/* <div className='color-green-color icon'><img src={paid} alt={paid} />PAID</div> */}
                  <div className='color-green-color icon' onClick={() => { cancelMultipleItem(true) }} style={{ cursor: "pointer" }}> <CheckCircleIcon /> PAID </div>
                  {/* <div className='print icon'><img src={pendPaid} alt={pendPaid} />PENDING PAID</div> */}
                  <div className='color-red-color icon' onClick={() => { cancelMultipleItem(false) }} style={{ cursor: "pointer" }}><img src={CancelIcons} alt={CancelIcons} />CANCEL</div>
                </div>

              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {product.length > 0 ? <div className='pagination-numb'>
                <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" />
              </div> : <></>}
            </Grid>
          </Grid>

        </Grid>
      </Box>
    </div>
  )
}

export default AdminPostSalesBody
