import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import profilebrand from '../../img/Group 28.png';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const RegisterFail = () => {


    const { t } = useTranslation('common');
    const navigate = useNavigate();


    useEffect(() => {

        const script = document.createElement('script');
        script.src = 'https://clk1.reachclk.com/sdk/reach.js';
        script.async = true;
        document.body.appendChild(script);

        if (window.Reach) {
            window.Reach.conversion({
                advertiser_id: 452,
            });
        }


    }, [])

    return (
        <div className='register'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid item container spacing={0}>

                    <Grid item container direction="column" justifyContent="flex-start" xs={12} sm={12} md={12} lg={4} xl={4} >
                        <Grid item  >
                            <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
                            <div className='titles-reg'>
                                <p>{t('components.registerTrial.description')}</p>
                            </div>
                        </Grid>
                        <Grid item  >
                            <div className='titles-reg trial'>
                                <h1 className=''>{t('components.registerTrial.form.title')}</h1>
                                <h2>{t('components.registerTrial.form.description')}</h2>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='d-flex justify-content-center trial'>

                        <div className="form-reg-trial">
                            <Box
                                component="form"
                                noValidate
                            >
                                <div className='d-flex fields-form'>
                                    <div className='content row justify-content-center step-terms'>
                                        <br />
                                        <h2>{t('components.registerTrial.form.error.title')}</h2>
                                        <br />
                                        <h4>{t('components.registerTrial.form.error.msg')}</h4>
                                        <h4>{t('components.registerTrial.form.error.msg2')}</h4>
                                        <br />
                                    </div>
                                </div>
                            </Box>

                        </div>

                    </Grid>

                </Grid>
            </Box>



        </div>
    )
}

export default RegisterFail;