import React from 'react'
import customer from '../../img/customer.svg';
import maptrace from '../../img/maptrace.svg';
import guarantee from '../../img/guarantee.svg';
import bestseller from '../../img/bestseller.svg';
import bill from '../../img/bill.svg';
import product from '../../img/product.svg';
import supplychain from '../../img/supplychain.svg';
import { Link } from 'react-router-dom'
import customericon from "../../img/customer-icon.svg"
import { useTranslation } from 'react-i18next';


const AdminMenuSide = () => {
	const { t } = useTranslation('common');
  const state = window.localStorage.getItem('Squelch')

  return (
    <div className='categories-menu-inner'>

      {
        ((state === 'admin') || (state === 'subAdmin')) ?
          <Link to='/dashboard'><div className='iconic'><img src={customer} alt={customer} /></div>{t('components.adminMenuSide.dashboardAdmin')}</Link>
          :
          <>
            {
              state === 'user' ?
                <>         <Link to='/user/dashboard'><div className='iconic'><img src={customer} alt={customer} /></div>{t('components.adminMenuSide.dashboardUser')}</Link>
                </>

                :
                <>
                  <Link to='/brand-/brand-dashboard'><div className='iconic'><img src={customer} alt={customer} /></div>{t('components.adminMenuSide.dashboardBrand')}</Link>
                </>
            }</>

      }
      {state !== 'user' ? (<Link to='/products/all'><div className='iconic'><img src={guarantee} alt={guarantee} /></div>{t('components.adminMenuSide.products')}</Link>) : <></>}
      {
        ((state === 'admin') || (state === 'subAdmin')) && <Link to='/customer'><div className='iconic'><img src={customer} alt={customer} /></div>{t('components.adminMenuSide.brands')}</Link>
      }
      {/* <Link to='/admin/admin-brand'><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Brand Authorization </Link> */}


      {
        (state === 'admin') || (state === 'brand') ?
          <Link to='/admin/customer' ><div className='iconic'><img src={customericon} alt={customericon} /></div>{t('components.adminMenuSide.customers')}</Link>
          :
          <></>
      }
      {state !== 'user' ? (<> </>) : <Link to='/user/myproductlist' ><div></div>{t('components.adminMenuSide.myProducts')}</Link>}
      {state !== 'user' ? (<Link to='/traceability'><div className='iconic'><img src={maptrace} alt={maptrace} /></div>{t('components.adminMenuSide.traceability')}</Link>) : null}
      {/* {state !== 'user' ? (<Link to='/products/all'><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>) : <></>} */}
      {state !== 'user' ? (state === 'brand' ? (<Link to='/brand-/blockchain'><div className='iconic'><img src={bestseller} alt={bestseller} /></div>{t('components.adminMenuSide.blockchain')}</Link>) : (<Link to='/blockchain'><div className='iconic'><img src={bestseller} alt={bestseller} /></div>{t('components.adminMenuSide.blockchain')}</Link>)) : <></>}
      {state !== 'user' ? (state === "admin" ? (<Link to='/postsales'><div className='iconic'><img src={bill} alt={bill} /></div>{t('components.adminMenuSide.postSales')}</Link>) : (<Link to='/brand/postsales'><div className='iconic'><img src={bill} alt={bill} /></div>{t('components.adminMenuSide.postSales')}</Link>)) : <></>}
      {state !== 'user' ? (state === 'brand' ? (<Link to='/brand-/billing'><div className='iconic'><img src={product} alt={product} /></div>{t('components.adminMenuSide.billing')}</Link>) : (<Link to='/billing'><div className='iconic'><img src={product} alt={product} /></div>{t('components.adminMenuSide.billing')}</Link>)) : <> </>}
      {state !== 'user' ? (<Link to='/seller-auth'><div className='iconic'><img src={supplychain} alt={supplychain} /></div>{t('components.adminMenuSide.sellersAuthorization')}</Link>) : <Link to='/user/myorders'><div className='iconic'></div>{t('components.adminMenuSide.myTransactions')} / {t('components.adminMenuSide.myOrders')}</Link>}
      {/* {state !== 'user' ? (<> </>) : <Link className='user-side-display'><div className='watch-text'><img src={watch} alt='watch'/><p>TEST PRODUCT ID <div> ADD-12231-11</div></p></div><div className='demo-img'><img src={demo}  alt="demo"/></div></Link>} */}
      {/* {state !== 'user' ? (<> </>) :    <Link to="/user/login"><Button className='submit' ><img src={logout} className='logout' alt="logout"/>Log Out</Button></Link>} */}
      {/* {state !== 'user' ? (<> </>) :    <Link to='/usersList' className='user-side-display'><div className='contact-us'>Contact us</div></Link>} */}
      {
        state === 'admin' ?
          <Link to='/usersList'><div className='iconic'><img src={supplychain} alt={supplychain} /></div>{t('components.adminMenuSide.users')}</Link>
          :
          <></>
      }


      {/* <Link to='/user/productdetails'><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Producr Purchase</Link> */}

    </div>
  )
}

export default AdminMenuSide
