import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import './ProductDetail.css'
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';

import locationMark from '../../../img/locationMark.png';
import locationMarkGreen from '../../../img/placeholder.png';
import locationMarkBlue from '../../../img/placeholder2.png';
import locationMarkRed from '../../../img/placeholder1.png';
import MenuIcon from '@mui/icons-material/Menu';
import toast, { Toaster } from 'react-hot-toast';
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp';
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import AdminMenuSide from '../../Admin/AdminMenuSide';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import polygon from '../../../img/polygon-icon.png';
import { useLocation } from 'react-router-dom'
import Axios from '../../../Axios';
import consts from '../../../constants';
import Modal from '@mui/material/Modal';
import dtlogo from "../../../img/dtlogo.png"
import cancel from "../../../img/CancelIconAPP.png"
import loader from '../../../img/mintLoaderNew.gif'
import tickSecond from '../../../img/tickSecond.gif'
import loadingAnimation from '../../../img/loadingAnimation.gif'

import Web3 from 'web3';
import Abi from '../../../web3/Abi/contractAbi.json'

import web3SendTransaction from '../../Admin/AdminProducts/web3SendTransaction'
import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import Avatar from '@mui/material/Avatar';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  bgcolor: 'rgb(233 235 237) !important',
  borderRadius: '5px',
  border: '0px solid #fff',
  boxShadow: 24,
  p: 4,
  overflowX: 'overlay',
  height: '700px',
  '@media (max-width: 991.98px)': {
    width: '80%'
  }, '@media (max-width: 1440px)': {
    height: '550px',
  }
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  color: theme.palette.text.secondary,
}));


const useStyles = makeStyles({
  leftcls: {
    background: 'transparent !important',
    border: 'none !important',
    padding: '0 20px !important',
    boxShadow: 'none !important',
    '@media (max-width: 991.98px)': {
      padding: '0 !important',
    }
  },
  rightcls: {
    background: 'transparent !important',
    border: 'none !important',
    padding: '0 20px !important',
    marginTop: "35px",
    boxShadow: 'none !important',
    '@media (max-width: 991.98px)': {
      padding: '0 !important',
    },
    '@media (max-width: 1199.98px)': {
      marginTop: "0px !important",
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    textAlign: 'center',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  }


});


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[6],
    borderRadius: 5,
    fontSize: 12,
    padding: 8,
  },
}));

const ProductDetail = () => {
  const web3 = new Web3(consts.polygon_url)
  const [open, setOpen] = React.useState(false);

  const handleClose2 = () => setOpen(false);

  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate()
  const path = location.pathname.split("/")
  window.localStorage.setItem('camino', path[3])
  const camino = window.localStorage.getItem('camino')

  const email_id = path[4]
  window.localStorage.setItem('comunicazione', email_id)
  const [nftdata, setNftdata] = useState()
  const [nftdetails, setNftdetails] = useState()

  const handleOpen = () => {
    // var login = window.localStorage.getItem("AdminToken")
    // if (login) {
    setOpen(true);
    // } else {
    // navigate('/user/login')
    // }
  }

  const [menuOpen, setMenuOpen] = useState(true)

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  useEffect(() => {
    getassest()
  }, []);


  // const purchase = async()=>{
  //   if(path){
  //     window.localStorage.removeItem('AdminToken')
  //   }
  // }

  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [email1, setEmail1] = useState("")

  const [passworderr, setPassworderr] = useState("")
  const [nameerr, setNameerr] = useState("")
  const [emailerr, setEmailerr] = useState("")
  const [emailerr1, setEmailerr1] = useState("")
  // const [untill, setUntill] = useState()
  // const [data, setData] = useState()
  // console.log(data, "dtasssss")
  // const location = useLocation()
  const [transHistory, setTransHistory] = useState([])
  useEffect(() => {
    setNftdata(location?.state?.data)
  }, [location])

  const [amountLoad, setAmountLoad] = useState(false)
  const [approveLoad, setApproveLoad] = useState(false)
  const [transferLoad, setTransferLoad] = useState(false)
  const [brand, setBrand] = useState()

  const [markers, setMarkers] = useState([])
  const [points, setPoints] = React.useState([]);

  const setTime = async (row) => {
    const product = row?.trackExactLocation
    if (product.length > 0) {
      var pois = []
      var arr = []
      for (let i = 0; i < product.length; i++) {
        const element = product[i];
        if (element !== null || element !== " ") {
          var obj = {
            geocode: [element, product[i + 1]]
          }
          var poi = { lat: element, lng: product[i + 1] }
          arr.push(obj)
          pois.push(poi)
          i++
        }
      }


      // if (product.length === 2) {
      //   arr = [{
      //     geocode: [product[0], product[1]]
      //   }]
      //   pois = [{ lat: product[0], lng: product[1] }]
      // } else if (product.length === 4) {
      //   arr = [{
      //     geocode: [product[0], product[1]],
      //   },
      //   {
      //     geocode: [product[2], product[3]],
      //   }]

      //   pois = [{ lat: product[0], lng: product[1] }, { lat: product[2], lng: product[3] }]
      // } else if (product.length >= 6) {
      //   arr = [{
      //     geocode: [product[0], product[1]],
      //   },
      //   {
      //     geocode: [product[2], product[3]],
      //   },
      //   {
      //     geocode: [product[product.length - 2], product[product.length - 1]],
      //   }]

      //   pois = [{ lat: product[0], lng: product[1] }, { lat: product[2], lng: product[3] }, { lat: product[product.length - 2], lng: product[product.length - 1] }]
      // }


      setMarkers(arr)
      setPoints(pois)
    }

  }


  const history = async () => {
    try {
      const dts = await Axios.post(`/users/productTansHistory`, {
        productId: window.localStorage.getItem('camino')
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (dts?.data?.success === true) {
        setTransHistory(dts?.data?.result)
      }
    } catch (error) {
      console.log("🚀 ~ file: ExtendWarrenty.js:148 ~ history ~ error:", error)
    }
  }

  useEffect(() => {
    history()
  }, [])

  const states = {
    center: [51.505, -0.091],
    zoom: 3,
  };
  const customIcon = new Icon({
    iconUrl: locationMark,
    iconSize: [23, 30]
  })
  const customIconRed = new Icon({
    iconUrl: locationMarkRed,
    iconSize: [30, 30]
  })
  const customIconBlue = new Icon({
    iconUrl: locationMarkBlue,
    iconSize: [30, 30]
  })
  const customIconGreen = new Icon({
    iconUrl: locationMarkGreen,
    iconSize: [30, 30]
  })

  const getassest = async () => {
    try {
      const data1 = await Axios.post('/GetProductDetails', {
        product_id: window.localStorage.getItem('camino')
      },)
      setNftdata(data1?.data?.result)
      setTime(data1?.data?.result)
      setBrand(data1?.data?.brand)
      setNftdetails(data1?.data?.productDetails)
    } catch (error) {
      console.log("🚀 ~ file: ProductDetail.js:303 ~ getassest ~ error:", error)
    }
  }
  const [loading, setLoading] = useState(false)

  const [preLoading, setPreLoading] = useState(false)
  //not working flow


  // const buynft = async () => {
  //   try {
  //     if (!window.localStorage.getItem('AdminToken')) {
  //       toast.error('Please LoggedIn')
  //       setTimeout(() => {
  //         navigate(`${consts.route}/user/login`)
  //       }, 1000);
  //     } else {
  //       // const { data } = await Axios.post(`/users/getWalletDetails`, {}, {
  //       //   headers: {
  //       //     Authorization: window.localStorage.getItem('AdminToken')
  //       //   }
  //       // })
  //       // if(data?.result?.balance > 0){
  //       const { data } = await Axios.post(`/getNotification`, {
  //         to_id: nftdata.product_id,
  //         message: "Purchase request"
  //       }, {
  //         headers: {
  //           Authorization: window.localStorage.getItem('AdminToken')
  //         }
  //       })
  //       if (data.success === true) {
  //         window.localStorage.removeItem('camino')
  //         toast.success('Waiting For Sellers Approval')
  //         setTimeout(() => {
  //           navigate(`${consts.route}/user/dashboard`)
  //         }, 1000);
  //       } else {
  //         toast.success('Keep Calm and Try Again')
  //       }

  //       // }else{
  //       //   toast.error('insufficent balance')
  //       //   setTimeout(() => {
  //       //     navigate(`${consts.route}/user/dashboard`)
  //       //   }, 1000);
  //       // }


  //     }
  //   } catch (error) {

  //   }

  // }

  //working old flow

  // const buyNft = async () => {
  //   try {
  //     if (!window.localStorage.getItem('AdminToken')) {
  //       toast.error('Please LoggedIn')
  //       setTimeout(() => {
  //         navigate(`${consts.route}/user/login`)
  //       }, 1000);
  //     } else {
  //       if (!password) {
  //         toast.error('Please Enter Password')
  //       } else {
  //         setOpen(false)
  //         setLoading(true)
  //         const toastId = toast.loading('Purchase In-Progress');
  //         var payload = {
  //           productId: window.localStorage.getItem('camino'),
  //           password: password,
  //           staff_id: name
  //         }
  //         const { data } = await Axios.post(`/approveProduct`, payload, {
  //           headers: {
  //             Authorization: window.localStorage.getItem('AdminToken')
  //           }
  //         })
  //         if (data?.success === true) {
  //           window.localStorage.removeItem('camino')
  //           toast.dismiss(toastId);
  //           toast.success(data?.message)
  //           navigate('/user/dashboard')
  //         } else {
  //           toast.dismiss(toastId);
  //           toast.error(data?.message)
  //         }
  //         setLoading(false)
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     console.log("🚀 ~ file: ProductDetail.js:491 ~ buyNft ~ error:", error)
  //     toast.error(error?.response?.data?.message)
  //   }
  // }


  const buyNft = async () => {
    try {
      if (name === "") {
        setNameerr("Please Enter Seller Id")
      } else if (password === "") {
        setPassworderr("Please Enter Password")
      } else if (email === "") {
        setEmailerr("Please Enter User Email")
      } else if (email1 === "") {
        setEmailerr1("Please Enter Confirm Email")
      } else if (email != email1) {
        setEmailerr1("Email Not Match")
      }
      else {
        setOpen(false)
        setLoading(true)
        setPreLoading(true)
        const toastId = toast.loading('Purchase In-Progress');
        const { data } = await Axios.post(`/findbysellerid`, {
          StaffId: name,
          password: password,
          email: email,
          Reactivate: true,
          product_id: window.localStorage.getItem('camino')
        })
        if (data.success === true) {
          toast.dismiss(toastId);
          setLoading(false)
          if (window.localStorage.getItem("AdminToken")) {
            await Axios.post(`/logout`, {
              email: window.localStorage.getItem('electrónico')
            }, {
              headers: {
                Authorization: window.localStorage.getItem('AdminToken')
              }
            })
            window.localStorage.removeItem('AdminToken')
          }
          toast.success(data?.message);
          setTimeout(() => {
            window.localStorage.removeItem('camino')
            window.localStorage.removeItem("comunicazione")
            window.localStorage.removeItem('electrónico')
            window.localStorage.removeItem('Squelch')
            window.localStorage.removeItem('isimo')
            navigate('/user/purchase-success')
          }, 1000)

          // window.localStorage.removeItem('camino')
        } else {
          toast.dismiss(toastId);
          setLoading(false)
          toast.error(data?.message)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log("🚀 ~ file: ProductDetail.js:564 ~ buyNft ~ error:", error)
    }

    // window.localStorage.removeItem('camino')
    // window.localStorage.removeItem("comunicazione")
    // window.localStorage.removeItem('electrónico')
    // window.localStorage.removeItem('Squelch')
    // window.localStorage.removeItem('isimo')

  }

  // users buy old flow backend

  // const userBuy = async () => {
  //   console.log("login")
  //   var toastId
  //   try {
  //     var token = window.localStorage.getItem('AdminToken')
  //     if (token) {
  //       if (email_id != 'product') {
  //         toastId = toast.loading('Purchase In-Progress');
  //         setOpen(false)
  //         setLoading(true)

  //         const { data } = await Axios.post(`/findbyemail`, {
  //           email: email_id,
  //           product_id: camino
  //         },
  //           {
  //             headers: {
  //               Authorization: window.localStorage.getItem('AdminToken')
  //             }
  //           })
  //         console.log("🚀 ~ file: ProductDetail.js:594 ~ userBuy ~ data:", data)
  //         if (data?.success === true) {
  //           setTimeout(() => {
  //             toast.dismiss(toastId);
  //             toast.success(data?.message);
  //             setLoading(false)
  //             navigate('/user/dashboard')
  //           }, 10000)
  //         } else {
  //           toast.dismiss(toastId);
  //           toast.error(data?.message)
  //           setLoading(false)
  //         }
  //       } else {
  //         toast.error('Not Buy Product')
  //       }
  //     } else {
  //       toast.dismiss(toastId);
  //       navigate('/user/login')
  //     }
  //   } catch (error) {
  //     // setTimeout(() => {
  //     toast.dismiss(toastId);
  //     console.log("🚀 ~ file: ProductDetail.js:587 ~ userBuy ~ error:", error)
  //     setLoading(false)
  //     navigate('/user/dashboard')
  //     // }, 120000);
  //   }
  // }

  const userBuy = async () => {
    try {
      var token = window.localStorage.getItem('AdminToken')
      if (token) {
        if (email_id != 'product' && nftdetails.status === "1") {
          setOpen(false)
          setLoading(true)
          setTransferLoad(true)
          setApproveLoad(true)
          setAmountLoad(true)
          const { data } = await Axios.post(`/userBuy`, {
            email: email_id,
            product_id: camino
          }, {
            headers: { Authorization: token }
          })

          if (data?.success === true) {
            var details = data?.result
            if (details.selluserPublic) {
              var contractInstance = await new web3.eth.Contract(Abi, consts?.contractAddress)

              const transactionId = await web3.eth.getTransactionReceipt(details?.finalProduct)
              const convert = await web3.eth.abi.decodeParameter('uint256', transactionId?.logs[1]?.data)

              const gasAmount = await contractInstance.methods.approve(details?.userPublic, convert).estimateGas({ from: details?.selluserPublic })
              const gasPrice = await web3.eth.getGasPrice();
              const amount = gasAmount * (gasPrice / (10 ** 18))

              const transferGasAmount = await contractInstance.methods.transferFrom(details?.selluserPublic, details?.userPublic, Number(convert)).estimateGas({ from: details?.selluserPublic })

              const transferamount = transferGasAmount * (gasPrice / (10 ** 18))
              var fee
              if (details?.count >= 0 && details?.count < 100000) {
                fee = (transferamount * 25) / 100
              } else if (details?.count >= 100000 && details?.count < 500000) {
                fee = (transferamount * 20) / 100
              } else if (details?.count >= 500000 && details?.count < 1000000) {
                fee = (transferamount * 15) / 100
              } else if (details?.count >= 1000000 && details?.count < 3000000) {
                fee = (transferamount * 10) / 100
              } else if (details?.count >= 3000000 && details?.count < 5000000) {
                fee = (transferamount * 8) / 100
              } else if (details?.count >= 5000000 && details?.count < 10000000) {
                fee = (transferamount * 5) / 100
              } else if (details?.count >= 10000000) {
                fee = (transferamount * 3) / 100
              }
              const amountTxnObjectUser = {
                from: details?.brandPublic,
                nonce: await web3.eth.getTransactionCount(details?.brandPublic),
                to: details?.selluserPublic,
                value: Math.floor((transferamount + amount + fee + 0.001) * (10 ** 18)),
                gas: '21000'
              }
              const amountTransfer = await web3SendTransaction(amountTxnObjectUser, details?.brandPrivate)

              var bal = await web3.eth.getBalance(details?.selluserPublic)
              if (Number(bal) >= (Number(amount) + Number(transferamount) + Number(fee))) {
                const amountTxObject = {
                  from: details?.selluserPublic,
                  nonce: await web3.eth.getTransactionCount(details?.selluserPublic),
                  to: details?.adminPublic,
                  value: Math.floor(fee * (10 ** 18)),
                  gas: '21000'
                }

                const txObject = {
                  from: details?.selluserPublic,
                  nonce: await web3.eth.getTransactionCount(details?.selluserPublic) + 1,
                  to: consts?.contractAddress,
                  gas: gasAmount,
                  value: "0x0",
                  data: contractInstance.methods.approve(details?.userPublic, convert).encodeABI(),
                  // gasPrice: gasAmount
                }

                const transferTxObject = {
                  from: details?.selluserPublic,
                  nonce: await web3.eth.getTransactionCount(details?.selluserPublic) + 2,
                  to: consts?.contractAddress,
                  gas: '93963',
                  value: "0x0",
                  data: contractInstance.methods.transferFrom(details?.selluserPublic, details?.userPublic, convert).encodeABI(),
                  // gasPrice: gasAmount
                }

                const amountTransfer = await web3SendTransaction(amountTxObject, details?.selluserPrivate)
                if (amountTransfer) {
                  setAmountLoad(false)
                  var ApproveToken
                  const getApproved = await contractInstance.methods.getApproved(convert).call({ from: details?.selluserPublic })
                  if (getApproved === details?.userPublic) {
                    ApproveToken = true
                  } else {
                    ApproveToken = await web3SendTransaction(txObject, details?.selluserPrivate)
                  }

                  if (ApproveToken) {
                    setApproveLoad(false)
                    const TransferToken = await web3SendTransaction(transferTxObject, details?.selluserPrivate)
                    if (TransferToken) {
                      setTransferLoad(false)
                      var payload = {
                        amountTransfer: amountTransfer,
                        TransferToken: TransferToken,
                        TransactionAmount: (Number(amount) + Number(transferamount) + Number(fee)),
                        product_id: camino,
                        to: details?.userPublic,
                        amount: Number(fee)
                      }
                      const dts = await Axios.post(`/userBuyUpdate`, payload, {
                        headers: { Authorization: window.localStorage.getItem("AdminToken") }
                      })
                      if (dts?.data?.success === true) {
                        toast.success(dts?.data?.message)
                        setLoading(false)
                        setTimeout(() => {
                          navigate(`/user/dashboard`)
                        }, 1000)
                      } else {
                        toast.error(dts?.data?.message)
                      }
                    } else {
                      toast.error("Token Not Transfer")
                      setLoading(false)
                      setTransferLoad(false)
                      setApproveLoad(false)
                      setAmountLoad(false)
                    }
                  } else {
                    toast.error("Product Not Approve")
                    setLoading(false)
                    setTransferLoad(false)
                    setApproveLoad(false)
                    setAmountLoad(false)
                  }
                } else {
                  toast.error("Amount Not Transfer")
                  setLoading(false)
                  setTransferLoad(false)
                  setApproveLoad(false)
                  setAmountLoad(false)
                }
              } else {
                toast.error("Not Enough Balance")
              }
            } else {
              var contractInstance = await new web3.eth.Contract(Abi, consts?.contractAddress)
              const transactionId = await web3.eth.getTransactionReceipt(details?.finalProduct)
              const name = await contractInstance.methods.name().call()
              const convert = await web3.eth.abi.decodeParameter('uint256', transactionId?.logs[1]?.data ? transactionId?.logs[1]?.data : transactionId?.logs[0]?.data)
              const gasAmount = await contractInstance.methods.approve(details?.userPublic, convert).estimateGas({ from: details?.brandPublic })
              const gasPrice = await web3.eth.getGasPrice();
              const amount = gasAmount * (gasPrice / (10 ** 18))

              const transferGasAmount = await contractInstance.methods.transferFrom(details?.brandPublic, details?.userPublic, Number(convert)).estimateGas({ from: details?.brandPublic })
              const transferamount = transferGasAmount * (gasPrice / (10 ** 18))
              var fee
              if (details?.count >= 0 && details?.count < 100000) {
                fee = (transferamount * 25) / 100
              } else if (details?.count >= 100000 && details?.count < 500000) {
                fee = (transferamount * 20) / 100
              } else if (details?.count >= 500000 && details?.count < 1000000) {
                fee = (transferamount * 15) / 100
              } else if (details?.count >= 1000000 && details?.count < 3000000) {
                fee = (transferamount * 10) / 100
              } else if (details?.count >= 3000000 && details?.count < 5000000) {
                fee = (transferamount * 8) / 100
              } else if (details?.count >= 5000000 && details?.count < 10000000) {
                fee = (transferamount * 5) / 100
              } else if (details?.count >= 10000000) {
                fee = (transferamount * 3) / 100
              }

              var bal = await web3.eth.getBalance(details?.brandPublic)

              if (Number(bal) >= (Number(amount) + Number(transferamount) + Number(fee))) {
                const amountTxObject = {
                  from: details?.brandPublic,
                  nonce: await web3.eth.getTransactionCount(details?.brandPublic),
                  to: details?.adminPublic,
                  value: Math.floor(fee * (10 ** 18)),
                  gas: '21000'
                }

                const txObject = {
                  from: details?.brandPublic,
                  nonce: await web3.eth.getTransactionCount(details?.brandPublic) + 1,
                  to: consts?.contractAddress,
                  gas: gasAmount,
                  value: "0x0",
                  data: contractInstance.methods.approve(details?.userPublic, convert).encodeABI(),
                  // gasPrice: gasAmount
                }

                const transferTxObject = {
                  from: details?.brandPublic,
                  nonce: await web3.eth.getTransactionCount(details?.brandPublic) + 2,
                  to: consts?.contractAddress,
                  gas: '93963',
                  value: "0x0",
                  data: contractInstance.methods.transferFrom(details?.brandPublic, details?.userPublic, convert).encodeABI(),
                  // gasPrice: gasAmount
                }
                const amountTransfer = await web3SendTransaction(amountTxObject, details?.brandPrivate)
                if (amountTransfer) {
                  setAmountLoad(false)
                  var ApproveToken
                  const getApproved = await contractInstance.methods.getApproved(convert).call({ from: details?.brandPublic })
                  if (getApproved === details?.userPublic) {
                    ApproveToken = true
                  } else {
                    ApproveToken = await web3SendTransaction(txObject, details?.brandPrivate)
                  }

                  if (ApproveToken) {
                    setApproveLoad(false)
                    const TransferToken = await web3SendTransaction(transferTxObject, details?.brandPrivate)
                    if (TransferToken) {
                      setTransferLoad(false)
                      var payload = {
                        amountTransfer: amountTransfer,
                        TransferToken: TransferToken,
                        TransactionAmount: (Number(amount) + Number(transferamount) + Number(fee)),
                        product_id: camino,
                        to: details?.userPublic,
                        amount: Number(fee)
                      }
                      const dts = await Axios.post(`/userBuyUpdate`, payload, {
                        headers: { Authorization: window.localStorage.getItem("AdminToken") }
                      })
                      if (dts?.data?.success === true) {
                        toast.success(dts?.data?.message)
                        setLoading(false)
                        setTimeout(() => {
                          navigate(`/user/dashboard`)
                        }, 1000)
                      } else {
                        toast.error(dts?.data?.message)
                      }
                    } else {
                      toast.error("Token Not Transfer")
                      setLoading(false)
                      setTransferLoad(false)
                      setApproveLoad(false)
                      setAmountLoad(false)
                    }
                  } else {
                    toast.error("Product Not Approve")
                    setLoading(false)
                    setTransferLoad(false)
                    setApproveLoad(false)
                    setAmountLoad(false)
                  }
                } else {
                  toast.error("Amount Not Transfer")
                  setLoading(false)
                  setTransferLoad(false)
                  setApproveLoad(false)
                  setAmountLoad(false)
                }
              } else {
                toast.error("Not Enough Balance")
              }
            }
          } else {
            toast.error(data?.message)
            setLoading(false)
          }
        } else {
          toast.error(`Not Able to Buy Product ${nftdetails.status}`)
        }
      } else {
        navigate('/user/login')
      }
    } catch (error) {
      console.log("🚀 ~ file: ProductDetail.js:659 ~ userBuy ~ error:", error)
    }
  }

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {
        loading === true &&
        <>
          <div className='loaders-load gif'>
            <img src={loader} />
          </div>
          {
            preLoading === false &&
            <div className='loaders-load text'>
              <div className='please-load-text'>
                <div className='load-imgs'><img src={amountLoad === true ? loadingAnimation : tickSecond} /></div>
                <div className='load-imgs'><img src={approveLoad === true ? loadingAnimation : tickSecond} /></div>
                <div className='load-imgs'><img src={transferLoad === true ? loadingAnimation : tickSecond} /></div>

              </div>
              <div className='please-load-text feees'>
                <label>Admin Fee In-Progress.</label>
                <label>Approving Nft.</label>
                <label>Nft On Transfer.</label>
              </div>
              {/* <div className='please-load-text'><div className='load-imgs'><img src={approveLoad === true ? loadingAnimation : tickSecond} /></div> Approve Product For User.</div>
              <div className='please-load-text'><div className='load-imgs'><img src={transferLoad === true ? loadingAnimation : tickSecond} /></div>  Transfer Image To User.</div> */}
              {/* <div className='please-load-para'>{countss}/{total}</div> */}
            </div>
          }


        </>
      }
      <div className={loading === true ? "product-detail product-detail-main-content-page blurs" : "product-detail product-detail-main-content-page"} >

        {/* <Toaster
       position="bottom-right"
       toastOptions={{
         success: {
           style: {
             fontSize: "16px",
             fontWeight: 600,
             color: "#000",
             marginBottom: "80px",
           },
         },
       }}
     /> */}




        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                camino ?
                  <></>
                  :
                  <>
                    {
                      loading === true ?
                        <Item className={classes.bodyclass}>
                          <div className='togglebtn prodetails' ><img src={togglebtn} alt="togglebtn" /></div>
                          <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                            <div className='togglebtn-close' ><img src={Menuclose} alt="Menuclose" /></div>
                            <AdminMenuSide />
                          </div>
                        </Item>
                        :
                        <Item className={classes.bodyclass}>
                          <div className='togglebtn prodetails' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                          <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                            <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                            <AdminMenuSide />
                          </div>
                        </Item>
                    }</>
              }


            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div class="SubPages prodetails">
                <Box sx={{ flexGrow: 1 }}>
                  <div className='productdetail-image1'>
                    <img src={brand?.image} alt='image' />
                  </div>
                  <div style={{ textAlign: "center" }} >
                    <h4>{brand?.name}</h4>
                  </div>
                  <Grid container spacing={0} className='total-subproduct'>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='field-mobile-response'>
                      <Item className={classes.leftcls}>
                        <div className="leftside">
                          <div className="item-summary">
                            <div className="coin-icon">
                              <LightTooltip
                                title="Chain: Polygon"
                                placement="top-start"
                                arrow
                              >
                                <Button>
                                  <img src={polygon} alt="polygon" />
                                </Button>
                              </LightTooltip>
                            </div>
                            {/* <div className="coin-icon share">
                           <LightTooltip
                             title="View Original media"
                             placement="top-start"
                             arrow
                           >
                             <Button className="share">
                               <LaunchIcon />
                             </Button>
                           </LightTooltip>
                           <p>0</p>
                           <LightTooltip title="Favorite" placement="top-start" arrow>
                             <Button>
                               <FavoriteBorderIcon />
                             </Button>
                           </LightTooltip>
                         </div> */}
                          </div>

                          <div className="left-crayons">
                            <img src={nftdata?.main_image} alt="approved" />
                          </div>
                        </div>

                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='field-desktop-response'>
                      <Item className={classes.rightcls}>
                        <div className="field-trip">
                          <h5>
                            {nftdata?.Product_Name}<VerifiedSharpIcon />{" "}
                          </h5>
                          {/* <div className="coin-icon share">
                         <LightTooltip title="Make a deal" placement="top-start" arrow>
                           <Button>
                             <HandshakeIcon />
                           </Button>
                         </LightTooltip>

                         <LightTooltip title="Share" placement="top-start" arrow>
                           <Button>
                             <IosShareIcon />
                           </Button>
                         </LightTooltip>


                         <LightTooltip title="More" placement="top-start" arrow>
                           <Button>
                             <MoreHorizIcon />
                           </Button>
                         </LightTooltip>
                         <LightTooltip title="More" placement="top-start" arrow>
                           <div>
                             <Button
                               id="demo-positioned-button"
                               aria-controls={openProflie ? 'demo-positioned-menu' : undefined}
                               aria-haspopup="true"
                               aria-expanded={openProflie ? 'true' : undefined}
                               onClick={handleButtonClick}
                               className='dotInfo'
                             >
                               <MoreHorizIcon />
                             </Button>
                             <Menu className='more-drop-menu'
                               id="demo-positioned-menu"
                               aria-labelledby="demo-positioned-button"
                               anchorEl={anchorEl}
                               open={openProflie}
                               onClose={handleClose}
                               anchorOrigin={{
                                 vertical: 'top',
                                 horizontal: 'left',
                               }}
                               transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'left',
                               }}
                             >
                               <MenuItem><LoopIcon />Refresh metadata</MenuItem>
                               <MenuItem><LanguageIcon />View website</MenuItem>
                               <MenuItem><FlagIcon />Report</MenuItem>
                             </Menu>
                           </div>
                         </LightTooltip>
                       </div> */}
                        </div>

                        <div className="trip">
                          <h2>{nftdata?.product_id}</h2>
                          <p>
                            Owned by <span>{nftdata?.brand_name}</span>
                          </p>
                        </div>
                        {/* 
                     <div className="views">
                       <h4># 355</h4>
                       <p>
                         {" "}
                         <RemoveRedEyeIcon /> 150 Views
                       </p>
                       <p>
                         {" "}
                         <ApartmentIcon /> Art
                       </p>
                     </div> */}


                        <div className="description sm-responsive">
                          <div className="head-title">
                            <h3>
                              <MenuIcon /> Description{" "}
                            </h3>
                          </div>
                        </div>

                        <div className="para sm-responsive">
                          <div className="descr-para">
                            <h6>
                              By <span>{nftdata?.brand_name}</span> <VerifiedSharpIcon />
                            </h6>
                            <p>
                              {nftdata?.product_description}
                            </p>
                          </div>
                        </div>




                        {/* <div className="traits sm-responsive">
                       <Accordion className="inner-accor">
                         <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                         >
                           <Typography>
                             {" "}
                             <LabelOutlinedIcon />
                             Traits
                           </Typography>
                         </AccordionSummary>
                         <AccordionDetails>
                           <Typography>
                             <Box sx={{ flexGrow: 1 }}>
                               <Grid container spacing={1}>
                                 <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                   <Link
                                     to="/collection-detail"
                                     className="trait-card-link"
                                   >
                                     <div className="trait-card">
                                       <div className="text-sec">Background</div>
                                       <p>
                                         Yellow <span>18%</span>
                                       </p>
                                       <div className="floor">
                                         Floor: <span>0.849 </span>
                                         <span>ETH</span>
                                       </div>
                                     </div>
                                   </Link>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                   <Link
                                     to="/collection-detail"
                                     className="trait-card-link"
                                   >
                                     <div className="trait-card">
                                       <div className="text-sec">Beard</div>
                                       <p>
                                         Stubble <span>19%</span>
                                       </p>
                                       <div className="floor">
                                         Floor: <span>0.719 </span>
                                         <span>ETH</span>
                                       </div>
                                     </div>
                                   </Link>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                   <Link
                                     to="/collection-detail"
                                     className="trait-card-link"
                                   >
                                     <div className="trait-card">
                                       <div className="text-sec">Eyes</div>
                                       <p>
                                         Blue <span>12%</span>
                                       </p>
                                       <div className="floor">
                                         Floor: <span>0.74 </span>
                                         <span>ETH</span>
                                       </div>
                                     </div>
                                   </Link>
                                 </Grid>
                               </Grid>

                               <Grid container spacing={1}>
                                 <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                   <Link
                                     to="/collection-detail"
                                     className="trait-card-link sec"
                                   >
                                     <div className="trait-card">
                                       <div className="text-sec">Fur</div>
                                       <p>
                                         Bengal <span>6%</span>
                                       </p>
                                       <div className="floor">
                                         Floor: <span>0.74 </span>
                                         <span>ETH</span>
                                       </div>
                                     </div>
                                   </Link>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                   <Link
                                     to="/collection-detail"
                                     className="trait-card-link sec"
                                   >
                                     <div className="trait-card">
                                       <div className="text-sec">Necklace</div>
                                       <p>
                                         Gold Chains <span>3%</span>
                                       </p>
                                       <div className="floor">
                                         Floor: <span>2.39 </span>
                                         <span>ETH</span>
                                       </div>
                                     </div>
                                   </Link>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                   <Link
                                     to="/collection-detail"
                                     className="trait-card-link sec"
                                   >
                                     <div className="trait-card">
                                       <div className="text-sec">Shirt</div>
                                       <p>
                                         Tracksuit <span>4%</span>
                                       </p>
                                       <div className="floor">
                                         Floor: <span>0.85 </span>
                                         <span>ETH</span>
                                       </div>
                                     </div>
                                   </Link>
                                 </Grid>
                               </Grid>
                             </Box>
                           </Typography>
                         </AccordionDetails>
                       </Accordion>
                     </div> */}

                        {/* <div className="accordion sm-responsive">
                       <Accordion className="inner-accor">
                         <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                         >
                           <Typography>
                             {" "}
                             <VerticalSplitOutlinedIcon /> About Field Trip by Andrew
                             McWhae
                           </Typography>
                         </AccordionSummary>
                         <AccordionDetails>
                           <Typography>
                             Field Trip is Andrew McWhae's genesis NFT collection. It
                             consists of 600 pieces of generative artworks composed of
                             brightly colored shapes arranged in lively patterns. Going
                             on a field trip implies experiencing something other than
                             your normal environment and that's exactly what I want the
                             viewer to do. At its core it is a collision-avoidance
                             algorithm that places lines which are then drawn using
                             stylized geometry. Every output is a different style or
                             color palette; no two are simply different seeds.Category
                             Art.
                           </Typography>
                         </AccordionDetails>
                       </Accordion>
                     </div> */}

                        {/* <div className="detail detail-items-customer sm-responsive">
                       <Accordion className="inner-accor">
                         <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                         >
                           <Typography>
                             <BallotOutlinedIcon /> Details
                           </Typography>
                         </AccordionSummary>
                         <AccordionDetails>
                           <Typography>
                             <div className="flex-detail-outer">
                               <div className="flex-detail">
                                 <label>Contract Address</label>
                                 <span>
                                   <a>0xb59b...401d</a>
                                 </span>
                               </div>
                               <div className="flex-detail">
                                 <label>Token ID</label>
                                 <span>
                                   <a>358</a>
                                 </span>
                               </div>
                               <div className="flex-detail">
                                 <label>Token Standard</label>
                                 <span>ERC-721</span>
                               </div>
                               <div className="flex-detail">
                                 <label>Chain</label>
                                 <span>Ethereum</span>
                               </div>
                               <div className="flex-detail">
                                 <label>Last Updated</label>
                                 <span>9 months ago</span>
                               </div>
                               <div className="flex-detail">
                                 <div className="earning">
                                   <label>Creator Earnings</label>
                                   <LightTooltip
                                     title="The creator(s) of this item will receive 10% for every sale"
                                     placement="top-start"
                                     arrow
                                   >
                                     <Button className="share">
                                       <InfoOutlinedIcon />
                                     </Button>
                                   </LightTooltip>
                                 </div>

                                 <span>10%</span>
                               </div>
                             </div>
                           </Typography>
                         </AccordionDetails>
                       </Accordion>
                     </div> */}





                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='qr-product-scan-map-div'>
                      <div className='map-component genral1'>
                        {
                          nftdata != undefined &&


                          <Map center={[nftdata?.latitude, nftdata?.longitude]} zoom={states?.zoom}>
                            <TileLayer
                              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                            />
                            {
                              markers?.map((marker, index) => (
                                <Marker position={marker?.geocode} icon={index == 0 ? customIconRed : index == 1 ? customIconGreen : index == 2 ? customIconBlue : customIcon}  >
                                  <Popup>
                                    <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                    <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                    <label>Owned By: </label> <span>{marker?.userName}</span>
                                  </Popup>
                                </Marker>
                              ))
                            }
                            <Polyline positions={points} color="blue" />

                            {/* <Marker position={[nftdata?.latitude, nftdata?.longitude]} icon={customIcon} >
                              <Popup>
                                                        <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                                                        <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                                                        <label>Owned By: </label> <span>{marker?.userName}</span>
                                                    </Popup>
                            </Marker> */}


                          </Map>
                        }
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d54336394.93150973!2d-38.2961321!3d33.77538325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1700033209017!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                      </div>


                      <div className='warrenty-trns'>
                        latest transaction
                      </div>


                      <div className='trns-details'>

                        {
                          transHistory?.length > 0 &&


                          transHistory?.map((item, index) => {
                            return (
                              <>
                                <div className='trns-body'>
                                  <div className='warrenty-display'>
                                    <div className='avatar-div'>
                                      <Avatar>N</Avatar>
                                    </div>
                                    <div className='reference-body'>
                                      <div className='ref-text'>
                                        {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[1])}
                                      </div>
                                      {/* <div className='ref-text2'>
                                                                            26 sec ago
                                                                        </div> */}
                                    </div>

                                  </div>
                                  <div className='warrenty-display'>
                                    <div className='avatar-div'>
                                      <Avatar>N</Avatar>
                                    </div>
                                    <div className='reference-body'>
                                      <div className='ref-text'>
                                        {web3.eth.abi.decodeParameter('address', item?.Other?.logs[0]?.topics[2])}
                                      </div>
                                      {/* <div className='ref-text2'>
                                                                            26 sec ago
                                                                        </div> */}
                                    </div>

                                  </div>
                                </div>
                                <hr className='warrenty-hr' />
                              </>

                            )
                          })

                        }


                      </div>

                      <div className="two-btn under-field qr-product-detail">
                        <div className="BuynOW-cart">
                          {
                            nftdata?.isSold === true ?
                              <>
                                <Button
                                  onClick={userBuy}
                                  className="btnBuy" variant="contained">
                                  Activate
                                </Button>
                              </>
                              :
                              <>
                                {
                                  loading === true ?
                                    <Button
                                      // onClick={buynft}
                                      className="btnBuy" variant="contained">
                                      Activate
                                    </Button>
                                    :
                                    <Button
                                      // onClick={buynft}
                                      onClick={handleOpen}
                                      className="btnBuy" variant="contained">
                                      Activate
                                    </Button>
                                  //
                                }

                              </>
                          }


                          {/* <Button
                         className="btnCart"
                         variant="contained"
                         onClick={handleAddCart}
                         startIcon={
                           addCart ? (
                             <RemoveShoppingCartIcon />
                           ) : (
                             <ShoppingCartIcon />
                           )
                         }
                       ></Button> */}
                        </div>

                        <div className='reactive-btn' onClick={handleOpen}>Reactivate</div>

                      </div>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                   <Item className={classes.rightcls}>
                     <div className="listing activity Item">
                       <Accordion className="inner-accor">
                         <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                         >
                           <Typography>
                             {" "}
                             <ImportExportOutlinedIcon /> Item Activity
                           </Typography>
                         </AccordionSummary>
                         <AccordionDetails className="table-accordion">
                           <Autocomplete
                             multiple
                             id="tags-outlined"
                             className="tags-outlined"
                             options={top100Films}
                             getOptionLabel={(option) => option.title}
                             defaultValue={[top100Films[13]]}
                             filterSelectedOptions
                             renderInput={(params) => (
                               <TextField
                                 {...params}
                                 // label="filterSelectedOptions"
                                 placeholder="Filter"
                               />
                             )}
                           />

                           <Typography className="item-activity">
                             <TableContainer component={Paper}>
                               <Table
                                 sx={{ minWidth: 650 }}
                                 aria-label="simple table"
                                 className="item-activity-table"
                                 id="style-1"
                               >
                                 <TableHead>
                                   <TableRow>
                                     <TableCell align="center">Event</TableCell>
                                     <TableCell align="center">Price</TableCell>
                                     <TableCell align="center">From</TableCell>
                                     <TableCell align="center">To</TableCell>
                                     <TableCell align="center">Date</TableCell>
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>
                                   {rowsitem.map((row) => (
                                     <TableRow
                                       key={row.name}
                                       sx={{
                                         "&:last-child td, &:last-child th": {
                                           border: 0,
                                         },
                                       }}
                                     >
                                       <TableCell align="center" className="event">
                                         <SellOutlinedIcon /> <p>List</p>
                                       </TableCell>
                                       <TableCell align="center" className="list-Name">
                                         {row.price} ETH
                                       </TableCell>
                                       <TableCell
                                         align="center"
                                         className="mild-font starting"
                                       >
                                         {row.from}
                                       </TableCell>
                                       <TableCell
                                         align="center"
                                         className="mild-font"
                                       ></TableCell>
                                       <TableCell align="center" className="mild-font">
                                         {row.Date} ago
                                       </TableCell>
                                     </TableRow>
                                   ))}
                                 </TableBody>
                               </Table>
                             </TableContainer>
                           </Typography>
                         </AccordionDetails>
                       </Accordion>
                     </div>
                   </Item>
                 </Grid>

                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                   <Item className={classes.rightcls}>
                     <div className="listing activity product-scroll">
                       <Accordion className="inner-accor">
                         <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                           onClick={handleAccordion}
                         >
                           <Typography>
                             {" "}
                             <CalendarViewMonthOutlinedIcon />
                             More From This Collection
                           </Typography>
                         </AccordionSummary>
                         <AccordionDetails className="table-accordion">
                           <div className="table-width">
                             <div className="total-card grid-pdt-block-specific">
                               <div className="cards-items">
                                 <Link
                                   className="productdetail-redirect"
                                   to="/productdetail"
                                 >
                                   <div className="product-item">
                                     <div className="product-item-img">
                                       <div
                                         className={tiles22 ? "minheightdiffer" : ""}
                                       >
                                         <img src={collection1} alt="collection1" />
                                       </div>
                                     </div>
                                     <div className="ratingDetails">
                                       <div className="pdt-name-price">
                                         <h4 className="pdt-name">WD #4079</h4>
                                         <span className="pdt-price">0.0353 ETH</span>
                                         <span className="sale">
                                           Last sale: 0.02 WETH
                                         </span>
                                       </div>
                                       <div className="idContent">#1,723</div>
                                     </div>
                                   </div>
                                 </Link>
                                 <div className="BuynOW-cart">
                                   <Button className="btnBuy" variant="contained">
                                     Buy now
                                   </Button>
                                   <Button
                                     className="btnCart"
                                     variant="contained"
                                     onClick={handleAddCart}
                                     startIcon={
                                       addCart ? (
                                         <RemoveShoppingCartIcon />
                                       ) : (
                                         <ShoppingCartIcon />
                                       )
                                     }
                                   ></Button>
                                 </div>
                               </div>

                               <div className="cards-items">
                                 <Link
                                   className="productdetail-redirect"
                                   to="/productdetail"
                                 >
                                   <div className="product-item">
                                     <div className="product-item-img">
                                       <div
                                         className={tiles22 ? "minheightdiffer" : ""}
                                       >
                                         <img src={collection1} alt="collection1" />
                                       </div>
                                     </div>
                                     <div className="ratingDetails">
                                       <div className="pdt-name-price">
                                         <h4 className="pdt-name">WD #4079</h4>
                                         <span className="pdt-price">0.0353 ETH</span>
                                         <span className="sale">
                                           Last sale: 0.02 WETH
                                         </span>
                                       </div>
                                       <div className="idContent">#1,723</div>
                                     </div>
                                   </div>
                                 </Link>
                                 <div className="BuynOW-cart">
                                   <Button className="btnBuy" variant="contained">
                                     Buy now
                                   </Button>
                                   <Button
                                     className="btnCart"
                                     variant="contained"
                                     onClick={handleAddCart}
                                     startIcon={
                                       addCart ? (
                                         <RemoveShoppingCartIcon />
                                       ) : (
                                         <ShoppingCartIcon />
                                       )
                                     }
                                   ></Button>
                                 </div>
                               </div>

                               <div className="cards-items">
                                 <Link
                                   className="productdetail-redirect"
                                   to="/productdetail"
                                 >
                                   <div className="product-item">
                                     <div className="product-item-img">
                                       <div
                                         className={tiles22 ? "minheightdiffer" : ""}
                                       >
                                         <img src={collection1} alt="collection1" />
                                       </div>
                                     </div>
                                     <div className="ratingDetails">
                                       <div className="pdt-name-price">
                                         <h4 className="pdt-name">WD #4079</h4>
                                         <span className="pdt-price">0.0353 ETH</span>
                                         <span className="sale">
                                           Last sale: 0.02 WETH
                                         </span>
                                       </div>
                                       <div className="idContent">#1,723</div>
                                     </div>
                                   </div>
                                 </Link>
                                 <div className="BuynOW-cart">
                                   <Button className="btnBuy" variant="contained">
                                     Buy now
                                   </Button>
                                   <Button
                                     className="btnCart"
                                     variant="contained"
                                     onClick={handleAddCart}
                                     startIcon={
                                       addCart ? (
                                         <RemoveShoppingCartIcon />
                                       ) : (
                                         <ShoppingCartIcon />
                                       )
                                     }
                                   ></Button>
                                 </div>
                               </div>

                               <div className="cards-items">
                                 <Link
                                   className="productdetail-redirect"
                                   to="/productdetail"
                                 >
                                   <div className="product-item">
                                     <div className="product-item-img">
                                       <div
                                         className={tiles22 ? "minheightdiffer" : ""}
                                       >
                                         <img src={collection1} alt="collection1" />
                                       </div>
                                     </div>
                                     <div className="ratingDetails">
                                       <div className="pdt-name-price">
                                         <h4 className="pdt-name">WD #4079</h4>
                                         <span className="pdt-price">0.0353 ETH</span>
                                         <span className="sale">
                                           Last sale: 0.02 WETH
                                         </span>
                                       </div>
                                       <div className="idContent">#1,723</div>
                                     </div>
                                   </div>
                                 </Link>
                                 <div className="BuynOW-cart">
                                   <Button className="btnBuy" variant="contained">
                                     Buy now
                                   </Button>
                                   <Button
                                     className="btnCart"
                                     variant="contained"
                                     onClick={handleAddCart}
                                     startIcon={
                                       addCart ? (
                                         <RemoveShoppingCartIcon />
                                       ) : (
                                         <ShoppingCartIcon />
                                       )
                                     }
                                   ></Button>
                                 </div>
                               </div>

                               <div className="cards-items">
                                 <Link
                                   className="productdetail-redirect"
                                   to="/productdetail"
                                 >
                                   <div className="product-item">
                                     <div className="product-item-img">
                                       <div
                                         className={tiles22 ? "minheightdiffer" : ""}
                                       >
                                         <img src={collection1} alt="collection1" />
                                       </div>
                                     </div>
                                     <div className="ratingDetails">
                                       <div className="pdt-name-price">
                                         <h4 className="pdt-name">WD #4079</h4>
                                         <span className="pdt-price">0.0353 ETH</span>
                                         <span className="sale">
                                           Last sale: 0.02 WETH
                                         </span>
                                       </div>
                                       <div className="idContent">#1,723</div>
                                     </div>
                                   </div>
                                 </Link>
                                 <div className="BuynOW-cart">
                                   <Button className="btnBuy" variant="contained">
                                     Buy now
                                   </Button>
                                   <Button
                                     className="btnCart"
                                     variant="contained"
                                     onClick={handleAddCart}
                                     startIcon={
                                       addCart ? (
                                         <RemoveShoppingCartIcon />
                                       ) : (
                                         <ShoppingCartIcon />
                                       )
                                     }
                                   ></Button>
                                 </div>
                               </div>

                               <div className="cards-items">
                                 <Link
                                   className="productdetail-redirect"
                                   to="/productdetail"
                                 >
                                   <div className="product-item">
                                     <div className="product-item-img">
                                       <div
                                         className={tiles22 ? "minheightdiffer" : ""}
                                       >
                                         <img src={collection1} alt="collection1" />
                                       </div>
                                     </div>
                                     <div className="ratingDetails">
                                       <div className="pdt-name-price">
                                         <h4 className="pdt-name">WD #4079</h4>
                                         <span className="pdt-price">0.0353 ETH</span>
                                         <span className="sale">
                                           Last sale: 0.02 WETH
                                         </span>
                                       </div>
                                       <div className="idContent">#1,723</div>
                                     </div>
                                   </div>
                                 </Link>
                                 <div className="BuynOW-cart">
                                   <Button className="btnBuy" variant="contained">
                                     Buy now
                                   </Button>
                                   <Button
                                     className="btnCart"
                                     variant="contained"
                                     onClick={handleAddCart}
                                     startIcon={
                                       addCart ? (
                                         <RemoveShoppingCartIcon />
                                       ) : (
                                         <ShoppingCartIcon />
                                       )
                                     }
                                   ></Button>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </AccordionDetails>

                         <div className="view-collect">
                           <Link to="/collection-detail">View Collection</Link>
                         </div>
                       </Accordion>
                     </div>
                   </Item>
                 </Grid> */}
                  </Grid>

                  <Modal
                    open={open}

                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="product-detail-box" >
                      <div className='productdetails-popup'>
                        <div className='cancel-div' onClick={handleClose2}>
                          <img src={cancel} alt="logo" />
                        </div>
                        <div className='productdetails-logo'>
                          <img src={dtlogo} alt="logo" />
                        </div>
                        <div className='productdetails-logo'>
                          <div className='productdetail-image'>
                            <img src={nftdata?.main_image} alt="approved" />
                          </div>
                        </div>
                        <div className='product-id-text'>
                          TEST PRODUCT ID {nftdata?.product_id}  {/* ADD-12231-11 */}
                        </div>

                        <div className='product-id-text2' >
                          Please Give {nftdata?.Product_Name}  to Seller
                        </div>
                        <div>
                          <div className='text-box'>
                            <label>Staff Id:</label>
                            <div>
                              <TextField id="outlined-basic" variant="outlined" onChange={(e) => { setName(e.target.value); setNameerr("") }} />
                              {nameerr != "" ? <div className='go-red'>{nameerr}</div> : <></>}
                            </div>

                          </div>

                          <br />
                          <div className='text-box'>
                            <label>Staff Password:</label>
                            <div>
                              <TextField id="outlined-basic" variant="outlined" onChange={(e) => { setPassword(e.target.value); setPassworderr("") }} />
                              {passworderr != "" ? <p className='go-red'>{passworderr}</p> : <></>}
                            </div>
                          </div>
                          <br />
                          <div className='text-box'>
                            <label>Customer Email:</label>
                            <div>
                              <TextField id="outlined-basic" variant="outlined" onChange={(e) => { setEmail(e.target.value); setEmailerr("") }} />
                              {emailerr != "" ? <p className='go-red'>{emailerr}</p> : <></>}
                            </div>
                          </div>
                          <br />
                          <div className='text-box'>
                            <label>Confirm Email:</label>
                            <div>
                              <TextField id="outlined-basic" variant="outlined" onChange={(e) => { setEmail1(e.target.value); setEmailerr1("") }} />
                              {emailerr1 != "" ? <p className='go-red'>{emailerr1}</p> : <></>}
                            </div>
                          </div>
                        </div>
                        <div className="purchase-btn">
                          <Button onClick={() => { buyNft() }} >Purchase</Button>
                        </div>
                      </div>
                    </Box>
                  </Modal>

                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>

  );
}

export default ProductDetail
