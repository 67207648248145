import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { toast } from 'react-hot-toast'
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import Checkbox from '@mui/material/Checkbox';
import InvoicesPopup from "./InvoicesPopup"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import actionps1 from '../../../img/action-ps-1.png';
import actionps2 from '../../../img/action-ps-2.png';
import actionps3 from '../../../img/action-ps-3.png';
import actionps5 from '../../../img/action-ps-5.png';
import './AdminProductsBody.css'
import Axios from '../../../Axios';
import consts from '../../../constants';
import * as XLSX from "xlsx";


// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// function clientMovements(id, name, movement, imports) {
//   return { id, name, movement, imports };
// }



// const today = dayjs();
// const yesterday = dayjs().subtract(1, 'day');
// const todayStartOfTheDay = today.startOf('day');


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  addserviceline: {
    padding: '0 80px',
    borderRadius: '16.667px',
    border: '0.986px solid rgba(203, 239, 255, 0.16)',
    background: '#F2F8FF',
    padding: '20px !important',
    marginTop: '40px',
    backdropFilter: 'blur(98.61111450195312px)',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    }
  },
  smallpopup: {
    width: '400px'
  }
});


// const styleAdminProductsFilter = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: '#fff',
//   border: 'none',
//   p: 4,
// };


const AdminProductsBody = ({ product, products, orderId, selecteds, setSelecteds, cancelMulti }) => {
  const [stsDownload, setStsDownload] = useState(false)
  const [selected, setSelected] = useState()
  const [details, setDetails] = useState()
  const [openProductInfoAdmin, setOpenProductInfoAdmin] = useState(false);
  const [reason, setReason] = useState([])

  const [deleteId, setDeleteId] = useState()
  const handleOpenProductInfoAdmin = () => setOpenProductInfoAdmin(true);
  const handleCloseProductInfoAdmin = () => setOpenProductInfoAdmin(false);
  const [openDeclinePopUp, setOpenDeclinePopUp] = useState(false);
  const handleOpenDeclinePopUp = () => setOpenDeclinePopUp(true);
  const handleCloseDeclinePopUp = () => setOpenDeclinePopUp(false);
  const [selectIndex, setSelectIndex] = useState([])


  const [opensmall, setOpen] = useState(false);
  const smallhandleOpen = () => setOpen(true);
  const smallhandleClose = () => setOpen(false);

  const [selectItems, setSelectItems] = useState()

  const setResons = (data) => {
    const dt = reason
    const check = dt.includes(data)
    if (check === false) {
      setReason([...reason, data])
    } else {
      setReason(reason.filter(item => item !== data))
    }
  }

  const setReasontext = (data) => {
    setReason([data])
  }

  const setSelection = async (data, detail) => {
    setSelected(data)
    setDetails(detail)
  }
  const classes = useStyles();

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


  // const downloadPdf = async (product_id) => {
  //   try {
  //     setStsDownload(true)

  //     await Axios.get(`/pdf/download_pdf/${product_id}`,
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("AdminToken"),
  //         },
  //       }).then(async (res) => {
  //         if (res?.status === 200) {
  //           setStsDownload(false)
  //           window.location.href = `${consts.BackendUrl}/pdf/download_pdf/${product_id}`;
  //           setTimeout(() => {

  //             toast.success("Pdf Downloaded Successfully")

  //           }, 2000);
  //         }
  //         else {
  //           setStsDownload(false)
  //           toast.error("Can't Download Successfully")
  //         }
  //       }).catch((err) => {

  //         console.log(err)
  //         setStsDownload(false)
  //       })
  //   } catch (error) {
  //     setStsDownload(false)
  //     console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
  //   }

  // }

  const downloadExcelsheet = (bill) => {
    let tableData = []
    // if (bill.length > 0) {
    // for (var i = 0; i < bill.length; i++) {
    var infos = {
      brandName: bill?.billing?.Brand_Name,
      OrderId: bill?.order_id,
      Service: bill?.service,
      ProductId: bill?.product_id,
      SalePrice: bill?.amount ? bill?.amount : 0,
      DtracerFee: bill?.details?.product_details[0]?.Dtrace_Cer_Fee,
      PaymentProcessingFee: bill?.payment_processing_fee.toString(),
      BrandBalance: bill?.positive_balance.toString(),
      SettlementDate: bill?.billing?.Payment_date?.split('T')[0],
      CreatedDate: bill?.billing?.Creation_date?.split('T')[0],
      PaymentMethod: bill?.billing?.Payment_method,
      status: bill?.status,
      paidtoBrand: bill?.paytobrand == true ? "paid" : "Not Paid"
    }
    tableData.push(infos)
    // }
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'postsales.csv');
    //   } else {
    //     toast.error('No Product Selected')
    // }
  };

  // const DeclineProduct = async () => {
  //   try {
  //     if (reason.length > 0) {
  //       const { data } = await Axios.post(`/users/approveProduct`, {
  //         product_id: deleteId,
  //         status: "2",
  //         reason: reason,
  //         type: "postsales"
  //       },
  //         {
  //           headers: {
  //             Authorization: localStorage.getItem("AdminToken"),
  //           },
  //         })
  //       if (data?.success === true) {
  //         toast.success(data?.message)
  //         products()

  //         handleCloseDeclinePopUp()

  //       } else {
  //         toast.error(data?.message)
  //       }
  //     }
  //     else {
  //       toast.error("Select Any Reason")
  //     }

  //   } catch (error) {
  //     console.log("🚀  ~ error:", error)
  //   }
  // }
  const DeclineProduct = async (id) => {
    try {
      const { data } = await Axios.post('/users/postsaleBlock', {
        id: id,
        reason: "Admin Block Product",
        type: "single"
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success == true) {
        toast.success(data?.message)
        products()
        handleCloseDeclinePopUp()
      } else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ DeclineProduct ~ error:", error)
    }
  }

  const selectedInvoice = (selc, row, index) => {
    try {
      if (selc == "all" && row?.target?.checked == true) {
        var arr = []
        var ids = []
        for (var i = 0; i < product?.length; i++) {
          arr.push(i);
          ids.push(product[i]?._id)
        }
        setSelectIndex(arr)
        setSelecteds(product)
        cancelMulti(ids, "all")
      } else if (selc == "all" && row?.target?.checked == false) {
        setSelecteds([])
        setSelectIndex([])
        cancelMulti([], "all")
      }
      else if (selecteds.includes(row)) {
        // If selected, remove it
        setSelecteds(selecteds.filter((selectedItem) => selectedItem._id !== row._id));
        setSelectIndex(selectIndex.filter((selectedItem) => selectedItem != index))

      } else {
        // If not selected, add it
        setSelecteds([...selecteds, row]);
        setSelectIndex([...selectIndex, index])
      }
    } catch (error) {
      console.log("🚀 ~ selectedInvoice ~ error:", error)
    }
  }

  const paidtoBrand = async (id, status) => {
    try {
      const { data } = await Axios.post('/users/postsalesBrandAmount', {
        id: id,
        status: status == "approve" ? true : false,
        type: "single"
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success == true) {
        toast.success(data?.message)
        products()
        smallhandleClose()
      } else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ paidtoBrand ~ error:", error)
    }
  }

  return (
    <div className='dashboard-body'>
      <Box sx={{ flexGrow: 1 }}>

        <Grid container spacing={0} className={classes.containwidth}>

          {stsDownload ?

            <div className='loaders-loads'>
              <h1>Downloading ...</h1>
            </div> : <>
              {product?.length > 0
                ?
                <Grid item xs={12} sm={12} md={12} lg={1} xl={1} >
                  <Item className={classes.headercls}>
                    <Checkbox {...label} onChange={(e) => { selectedInvoice("all", e) }} />
                    <p>Select All</p>
                  </Item>
                </Grid>
                :
                <></>
              }

              {
                product?.length > 0 && product?.map((item, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='bg-blue-padding-30px' key={index}>
                      <Item className={classes.headercls}>

                        <Grid container spacing={0} className='bg-color-new-block'>

                          <Grid item xs={12} sm={12} md={12} lg={1} xl={1} >
                            <Item className={classes.headercls}>
                              <Checkbox {...label} checked={selectIndex?.includes(index) ? true : false} onChange={() => { selectedInvoice("single", item, index); cancelMulti(item?._id, 'single') }} />
                            </Item>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={10} xl={10} >

                            <Item className={classes.headercls}>

                              <div className='many-block-with-label-span-post-sale-admin'>

                                <div className='post-sale-admin-block'>
                                  <div><label>BRAND NAME</label></div>
                                  <div><span>{item?.details?.brand_name}</span></div>
                                </div>
                                <div className='post-sale-admin-block'>
                                  <div><label>BRAND ID</label></div>
                                  <div><span>{item?.brandss?.brandId ? item?.brandss?.brandId : "-"}</span></div>
                                </div>


                                <div className='post-sale-admin-block'>
                                  <div><label>ORDER ID</label></div>
                                  <div><span>{item?.order_id}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>SERVICE</label></div>
                                  <div className='color-orange-bg'><span>{item?.service} </span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>PRODUCT ID</label></div>
                                  <div><span>{item?.product_id} </span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>PRODUCT IMAGE</label></div>
                                  <div><span><img src={item?.details?.product_details[0]?.main_image} alt="pdtimg" /></span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>SALE PRICE</label></div>
                                  <div><span>{item?.amount ? item?.amount : 0}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>DTRACER FEE</label></div>
                                  {item?.service === "Certificate Creation" ? <div className='color-green-bg'><span>{item?.details?.product_details[0]?.Dtrace_Cer_Fee ? item?.details?.product_details[0]?.Dtrace_Cer_Fee : 0}</span></div> : <></>}
                                  {item?.service === "Warranty Extend" ? <div className='color-green-bg'><span>{item?.details?.product_details[0]?.Dtracer_Warr_Fee ? item?.details?.product_details[0]?.Dtracer_Warr_Fee : 0}</span></div> : <></>}
                                  {item?.service === "Change OwnerShip" ? <div className='color-green-bg'><span>{item?.details?.product_details[0]?.Dtracer_Change_Owner_Fee ? item?.details?.product_details[0]?.Dtracer_Change_Owner_Fee : 0}</span></div> : <></>}
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>payment processing fee</label></div>
                                  <div><span>{item?.payment_processing_fee ? item?.payment_processing_fee : 0}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>Balance for the brand</label></div>
                                  <div className='color-red-bg'><span>{item?.positive_balance ? item?.positive_balance : 0}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>CREATED</label></div>
                                  <div><span>{new Date(item?.createdAt).toLocaleDateString()}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>Expected settlement date</label></div>
                                  <div><span>{new Date(item?.billing?.Payment_date).toLocaleDateString()}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>Settlement date to the brand</label></div>
                                  <div><span>{new Date(item?.billing?.Payment_date).toLocaleDateString()}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>payment method</label></div>
                                  <div><span>{item?.billing?.Payment_method}</span></div>
                                </div>

                                <div className='post-sale-admin-block'>
                                  <div><label>ACTIONS</label></div>
                                  <div className='action-ps-options'>
                                    {/* <img src={actionps1} alt="actionps1" /> */}
                                    {item?.status == false ? <></> : <img src={actionps2} alt="actionps2" onClick={() => { DeclineProduct(item?._id); setDeleteId(item?._id) }} />}
                                    <img src={actionps3} onClick={() => downloadExcelsheet(item)} alt="actionps3" />
                                    <InvoicesPopup item={item} />
                                    <img src={actionps5} alt="actionps5" onClick={() => { handleOpenProductInfoAdmin(); setSelection(item?.filteredDetails, details) }} />
                                  </div>
                                </div>

                                <div className='post-sale-admin-block post-sale-admin-block-status'>
                                  <div><label>Status</label></div>
                                  {item?.status == true ?
                                    <div className='color-green-color'><CheckCircleIcon /></div>
                                    :
                                    <div className='color-red-color' ><CancelIcon /></div>
                                  }
                                </div>

                                <div className='post-sale-admin-block post-sale-admin-block-status' >
                                  <div><label>Paid To Brand</label></div>
                                  {item?.paytobrand == true ?
                                    <>
                                      <span>Paid</span>
                                      <div className='color-green-color' onClick={() => { smallhandleOpen(); setSelectItems(item) }} style={{ cursor: "pointer" }} ><CheckCircleIcon /></div>

                                    </>
                                    :
                                    <>
                                      <span>Un Paid</span>
                                      <div className='color-red-color' onClick={() => { smallhandleOpen(); setSelectItems(item) }} style={{ cursor: "pointer" }}><CancelIcon /></div>

                                    </>

                                  }
                                </div>
                              </div>
                            </Item>
                          </Grid>

                          {/* <Grid item xs={12} sm={12} md={12} lg={1} xl={1} >
                          <Item className={classes.headercls}>
                            <div className='post-sale-admin-block post-sale-admin-block-status'>
                              <div><label>Status</label></div>
                              <div className='color-green-color'><CheckCircleIcon /></div>
                            </div>
                          </Item>
                        </Grid> */}
                        </Grid>
                      </Item>
                    </Grid>
                  )
                })
              }</>}


        </Grid>

        <Modal
          open={openProductInfoAdmin}

          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style} className="popBOX BlockPopup infopopup">
            <div className="menu" onClick={handleCloseProductInfoAdmin} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

            <div className="product-qr-info">
              <div className="product-info-head">
                <p className="product-code-head">Product info</p>
                <p className="pro-id">PRODUCT ID - {selected?.product_id}</p>
              </div>

              <div className="info-product-scan">
                <img src={selected?.main_image} alt='mainImage' />
              </div>
              <p className="lv-bag">{selected?.Product_Name}</p>
              <div className="productinfo-img-bag">
                {selected?.Detail1_image &&
                  <div className="info-bag">
                    <img src={selected?.Detail1_image} alt='product' />
                  </div>
                }
                {selected?.Detail2_image &&
                  <div className="info-bag">
                    <img src={selected?.Detail2_image} alt='product' />
                  </div>
                }
                {
                  selected?.Detail3_image &&
                  <div className="info-bag">
                    <img src={selected?.Detail3_image} alt='product' />
                  </div>
                }

                {/* <div className="info-bag">
                        <img src={QrBag} alt={QrBag} />
                      </div> */}
              </div>

              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <div className="special-bag">
                    {/* <p>{selected?.Product_Name}</p> */}
                    {/* <div>Description</div> */}
                    <p className="product-info-title">Description</p>
                    {/* <p className="lv-made">Louis vuitton handbag made with cowhide</p> */}
                    <p className="cristin-para">{selected?.product_description}</p>
                  </div>
                  <div className="ean-info">
                    <div className="ean-cust">
                      <p className="product-info-title">EAN</p>
                      <p>{selected?.ean ? selected?.ean : '-'}</p>
                    </div>
                    <div className="ean-cust">
                      <p className="product-info-title">Ref customer</p>
                      <p>D1113s</p>
                    </div>
                    <div className="ean-cust">
                      <p className="product-info-title">Brand:</p>
                      <p>{selected?.brand_name ? selected?.brand_name : '-'} </p>
                    </div>
                    <div className="ean-cust">
                      <p className="product-info-title">Initial stock</p>
                      <p> {selected?.Initial_stock ? selected?.Initial_stock : '-'}</p>
                    </div>
                  </div>

                  <div className="ean-info">
                    <div className="ean-cust">
                      <p className="product-info-title">measures</p>
                      <p>{selected?.Measures ? selected?.Measures : '-'} </p>
                    </div>
                    <div className="ean-cust">
                      <p className="product-info-title">Material</p>
                      <p> {selected?.material ? selected?.material : '-'} </p>
                    </div>
                    <div className="ean-cust">
                      <p className="product-info-title">unit of measurement</p>
                      <p>{selected?.Unit_of_measurement ? selected?.Unit_of_measurement : '-'} </p>
                    </div>

                  </div>

                  <div className="ean-info">
                    <div className="ean-cust">
                      <p className="product-info-title">warranty</p>
                      <p>{selected?.Duration_of_the_official_warranty ? selected?.Duration_of_the_official_warranty : '-'} </p>
                    </div>
                    {/* {
                              roles === 'admin' &&
                              <div className="ean-cust">
                                <p className="product-info-title">License attachments</p>
                               
                                <p> <Link to={selected?.License_Attachments} target="_blank" download>Download license</Link></p>
                              </div>
                            } */}
                  </div>

                  <div className="location-info">
                    <p className="product-info-title">Location</p>
                    <p>Passeig de gracia 88, Barcelona, CP 08008 click to see on the map</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div className="category-sctn info-category">
                    <div className="category-info">
                      <p className="product-client-id">Client ID <span> XXX11122S</span></p>
                    </div>
                    <div className="category-info">
                      <p className="product-client-id">ID PRODUCT<span> {selected?.product_id ? selected?.product_id : '-'}</span></p>
                    </div>

                    <div className="category-info">
                      <p className="product-client-id">Category <span>
                        {selected?.product_category ? selected?.product_category : '-'}</span></p>
                    </div>

                    <div className="pend-approved-btn">
                      {
                        details?.status === '0' ?
                          <div className="pending-appr-button">PENDING APROVAL</div>
                          :
                          <>
                            {
                              details?.status === '1' ?
                                <div className="approve-tick">Approved</div>
                                :
                                <>
                                  <div className="reason-info">
                                    <p className="product-client-id">Reason</p>

                                    {details?.reason?.length > 0 && details?.reason?.map((value, index) => (
                                      <p className="product-client-id" key={index}>
                                        <span>


                                          {value ? value : '-'}
                                        </span></p>
                                    ))}

                                  </div>
                                  <div className="wrong-tick">Declined</div>
                                </>
                            }</>

                      }
                    </div>

                    <div className="category-info">
                      <p className="product-client-id">Blockchain info Not AVAILABLE</p>
                    </div>

                    <div className="buttons-approve">
                      {/* <div>
                              <Button className="approve-tick">Approval</Button>
                            </div>
                            <div>
                              <Button className="wrong-tick">DECLINE</Button>
                            </div> */}

                      {/* {
                                details?.status === '0' ?
                                  <div className="pending-appr-button">PENDING APROVAL</div>
                                  :
                                  <>
                                    {
                                      details?.status === '1' ?
                                        <div className="approve-tick">Approved</div>
                                        : <div className="wrong-tick">Declined</div>
                                    }</>

                              } */}
                    </div>
                  </div>


                </Grid>

              </Grid>

              <div className="warranty-extension">
                <div className="warranty-yrs">
                  <p className="product-info-title">Warranty:</p>
                  <p className="blue-msg">{selected?.Duration_of_the_official_warranty ? selected?.Duration_of_the_official_warranty : '-'} </p>
                </div>
                <div className="warranty-yrs">
                  <p className="product-info-title">Extension permit</p>
                  <p className="blue-msg">{selected?.Allows_warranty_extension}, {selected?.Extra_warranty_time}</p>
                </div>
                <div className="warranty-yrs">
                  <p className="product-info-title">Price Extension Warranty</p>
                  <p className="blue-msg">{selected?.Warranty_extension_price ? selected?.Warranty_extension_price : '-'} {selected?.Type_of_currency ? selected?.Type_of_currency : '-'} </p>
                </div>
                <div className="warranty-yrs">
                  <p className="product-info-title">Warranty extension applied</p>
                  {selected?.Allows_warranty_extension === "yes" || selected?.Allows_warranty_extension === "Yes" ? <p className="blue-msg">Yes, {selected?.userExtenWarrenty}</p> : <></>}
                </div>
              </div>

            </div>



          </Box>
        </Modal>


        {<Modal open={openDeclinePopUp} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">

          <Box sx={style} className="popBOX BlockPopup requpdate">
            <div className="menu" onClick={handleCloseDeclinePopUp}>
              <Link><img src={closeMenu} alt="closeMenu" /></Link>
            </div>

            <div className="reject-info">
              <div className="rejection-indication">
                <p className="reject-head">Request product</p>
                {/* <p className="reason-reject">please inform the brand why you need to update your product before being approved</p> */}
              </div>
            </div>

            <div className="check-list">
              <ul>
                <li>
                  <Checkbox {...label} className="box-border" onClick={() => { setResons('The description does not match the product image') }} />The description does not match the product image</li>
                <li>
                  <Checkbox {...label} className="box-border" onClick={() => { setResons('Product image does not match description') }} />Product image does not match description</li>
                <li>
                  <Checkbox {...label} className="box-border" onClick={() => { setResons('We have not been able to verify the authenticity of manufacture') }} />We have not been able to verify the authenticity of manufacture</li>
                <li>
                  <Checkbox {...label} className="box-border" onClick={() => { setResons('The product is duplicated') }} />The product is duplicated</li>
                <li>
                  <Checkbox {...label} className="box-border" onClick={() => { setResons('The product appears as the owner of another brand') }} />The product appears as the owner of another brand</li>
              </ul>
            </div>

            <div className="text-box">
              <TextField className="box-size" id="outlined-multiline-static" multiline="multiline" onChange={(e) => { setReasontext(e.target.value) }} rows={5} placeholder="Other reasons, please specify the reason" />
            </div>

            <div className="decline-cancel-btn">
              {/* <div className="decline-btn">
                                                            <Button className="req-up"><ReplayOutlinedIcon />Request update</Button>
                                                          </div> */
              }

              <div className='decline-cancel-btn'>
                {deleteId?.status === "2" ? <></> : <Button className="approve-tick" onClick={() => { DeclineProduct() }} >Decline</Button>}

                <Button className="wrong-tick">Cancel</Button>
              </div>

            </div>

          </Box>
        </Modal>}



        {<Modal
          open={opensmall}
          onClose={smallhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >

          <Box sx={style} className="popBOX adminFilterPopup AdminSettingPopup AdminSettingPopup-postsale smallpopup">
            <div className="menu" onClick={smallhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            {
              selectItems?.paytobrand == true ?
                <>
                  <div className="reason">Are you sure you want to Un Paid</div>

                  <div className="wholeButton">
                    <Button className='apply' onClick={() => { paidtoBrand(selectItems?._id, "decline") }} >Apply</Button>
                  </div>
                </>
                :
                <>
                  <div className="reason">Are you sure you want to Paid</div>

                  <div className="wholeButton">
                    <Button className='apply' onClick={() => { paidtoBrand(selectItems?._id, "approve") }} >Apply</Button>
                  </div>
                </>


            }
          </Box>
        </Modal>}

      </Box>
    </div>
  )
}

export default AdminProductsBody
