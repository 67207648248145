import React, { useEffect } from "react";
import Axios from "axios";
import Constant from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";

export default function TOkenverify() {
  const path = useLocation();
  const navigate = useNavigate();
  // const [errs, setErrs] = useState()
  // const [done, setdones] = useState()
  useEffect(() => {
    // console.log(path.pathname.split('/'),"open")

    Axios.get(`${Constant.BackendUrl}/users/tokenVerify`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: path.pathname.split("/")[2],
      },
    })
      .then((res) => {
        console.log(res, "res")
        if (window.localStorage.getItem('camino')) {
          navigate(`${Constant.route}/user/login`, { state: { emailVerify: 0 } })
        } else {
          navigate(`${Constant.route}/`, { state: { emailVerify: 0 } })
        }

      }
      )
      .catch((err) =>
        navigate(`${Constant.route}/user/login`, { state: { emailVerify: 1 } })

      );
  }, []);

  return <></>;
}
