import React, {useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import './QrProduct.css'
import ProductInfo from '../../../img/qr-product-info.png'
import QrBag from '../../../img/qr-bag-img.png'
import Checkbox from '@mui/material/Checkbox';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

  const useStyles = makeStyles({

    modalpara:{
        fontSize: '18px',
        textAlign:'center',
        maxWidth:'750px !important',
        margin:'0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)' : {
            paddingBottom: '36px',
          },

      },

      reasonBlock:{
        fontSize:'24px',
        fontWeight:'600',
        margin:'0'
    }

  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const QrProduct = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div >
     
           <div className="qr-detail">
      <Button onClick={handleOpen} variant="contained">Reactivate Qr</Button>
      <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup qrproduct-pop">
        <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            
         <div className="product-qr-info">
            <div className="product-info-head">
                <p className="product-code-head">QR product info</p>
                <p className="pro-id">PRODUCT ID ADD-11123-99</p>
            </div>

            <div className="qr-product-scan">
             <img src={ProductInfo} alt={ProductInfo} />
            </div>

            <div className="product-img-bag">
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            </div>

            <div className="category-sctn">
                <div className="category-info">
                    <p className="product-client-id">Client ID <span> XXX11122S</span></p>
                </div>
                <div className="category-info">
                    <p className="product-client-id">ID PRODUCT<span> XXX 1002 - 001</span></p>
                </div>

                <div className="category-info">
                    <p className="product-client-id">Category <span> FashionStatus product</span></p>
                </div>

                <div className="approved-btn">
                    <Button className="approve-button">Approved</Button>
                </div>

                <div className="category-info">
                    <p className="product-client-id">Blockchain info <Link hrefLang="#" className="url-link">url link</Link></p>
                </div>
            </div>


             <Grid container spacing={0}>
             <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="reactive-size-check">
                    <div className="size-row">
                    <Checkbox {...label} className="box-shadow-check"/>3/4 ‘X 3/4’
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />1′ X 1′
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />1-1/2 ‘X 1-1/2’
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />2′ X 2′
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />2-1/8′ X 2-1/8′
                    </div>
                
                </div>
             </Grid>
             <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="reactive-size-check">
                    <div className="size-row">
                    <Checkbox {...label} />2-1/2′ X 2-1/2 ‘
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />2-3/4′ X 2-3/4 ‘
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />3′ X 3′
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />4′ X 4′
                    </div>
                    <div className="size-row">
                    <Checkbox {...label} />8’ X 8’
                    </div>
                
                </div>
             </Grid>
             </Grid>
             <div className="other-size">
                    <Checkbox {...label} />Other measures in CM
                    </div>

                    <div className="decline-cancel-btn">
              <div className="decline-btn">
                <Button className="req-up print"><LocalPrintshopIcon />Print</Button>
              </div>
             
       </div>     
       </div>
         
       

        </Box>
      </Modal>
      </div>


    </div>

    
  );
};

export default QrProduct;
