import React, { useState } from "react";
import "./BrandFilterPopup.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const BrandFilterPopup = ({ orderId, setorderId, product_id, setproduct_id, brand_id, setbrand_id, ean, setean,
  paid, setpaid, unpaid, setunpaid, refund, setrefund, stripe, setstripe, bank, setbank,
  creationDate, endDate, creation1Date, end1Date, paymentDate, endpaymentDate,
  setEnd1Date, setCreationDate, setCreation1Date, setEndDate, setPaymentDate, setendPaymentDate,
  isFilter, setIsfilter, getGraphs, products, productfilter }) => {
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const resetFilters = () => {
    setorderId()
    setproduct_id()
    setbrand_id()
    setpaid(false)
    setunpaid(false)
    setrefund(false)
    setstripe(false)
    setbank(false)
    setCreationDate([])
    setEndDate([])
    setCreation1Date([])
    setEnd1Date([])
    setPaymentDate([])
    setendPaymentDate([])
    setOpen(false)
    products()
    getGraphs()
    console.log("data")
  };

  const handlecreationdatechange = (d, m, y) => {

    var credate = y + '-' + m + '-' + d

    setCreationDate(credate)

    // if ((paymentDate !== undefined) && (paymentDate.length !== 0)) {


    //   filterbyDate(credate)
    // }

  }
  const handlecreation1datechange = (d, m, y) => {

    var credate = y + '-' + m + '-' + d

    setCreation1Date(credate)

    // if ((paymentDate !== undefined) && (paymentDate.length !== 0)) {


    //   filterbyDate(credate)
    // }

  }

  const handleenddatechange = (d, m, y) => {

    var endDates = y + '-' + m + '-' + d

    setEndDate(endDates)
    // if ((creationDate !== undefined) && (creationDate.length !== 0)) {
    //        filterbyDate1(payDate)
    // }

  }
  const handleend1datechange = (d, m, y) => {

    var endDates = y + '-' + m + '-' + d

    setEnd1Date(endDates)
    // if ((creationDate !== undefined) && (creationDate.length !== 0)) {
    //        filterbyDate1(payDate)
    // }

  }

  const handlepaymentdatechange = (d, m, y) => {

    var payDate = y + '-' + m + '-' + d

    setPaymentDate(payDate)

  }

  const handleendpaymentdatechange = (d, m, y) => {

    var payDate = y + '-' + m + '-' + d

    setendPaymentDate(payDate)


  }

  return (
    <div className="adminFilterPopup">
      <Button onClick={handleOpen} className="filterBtn">
        <SearchIcon />
        &nbsp; Filter
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >


        <Box sx={style} className="popBOX adminFilterPopup adminFilterPopup-postsale lastpop">
          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
          <div className="reason">Filters </div>
          <div className="listOne">

            <div className="productId">
              {/* <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Brand ID
              </Typography> */}

              {/* <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="textField"
                  value={brand_id}
                  onChange={(e) => { setbrand_id(e.target.value) }}
                />
              </div> */}
            </div>

            {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Status
              </Typography>

              <div className="warn">
                <div className="statusList"><Checkbox className="checkBox" checked={unpaid} onChange={() => setunpaid(!unpaid)} />Unpaid</div>
                <div className="statusList"><Checkbox className="checkBox" checked={paid} onChange={() => setpaid(!paid)} />Paid</div>
                <div className="statusList"><Checkbox className="checkBox" checked={refund} onChange={() => setrefund(!refund)} />Refund</div>
              </div>


            </div> */}

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Product ID
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="textField"
                  value={product_id}
                  onChange={(e) => { setproduct_id(e.target.value) }}
                />
              </div>
            </div>

            {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Product Ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="textField"
                  value={ean}
                  onChange={(e) => { setean(e.target.value) }}
                />
              </div>
            </div> */}

          </div>


          <div className="listTwo">
            <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Creation Date
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      className="date-pick"
                      onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}

                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                  <DemoItem>
                    <DatePicker

                      className="date-pick"
                      onChange={(e) => { handlecreation1datechange(e.$D, e.$M + 1, e.$y) }}

                      views={["year", "month", "day"]}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Payment Date
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      className="date-pick"
                      onChange={(e) => { handleenddatechange(e.$D, e.$M + 1, e.$y) }}

                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                  <DemoItem>
                    <DatePicker
                      className="date-pick"
                      onChange={(e) => { handleend1datechange(e.$D, e.$M + 1, e.$y) }}

                      views={["year", "month", "day"]}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>


          <div className="listThree">

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Order ID
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // placeholder="Spain"
                  variant="outlined"
                  className="textField"
                  value={orderId}
                  onChange={(e) => { setorderId(e.target.value) }}
                />
              </div>
            </div>

            <div className="warn">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Payment Method
              </Typography>
              <div className="warn">
                <div className="statusList"><Checkbox className="checkBox" checked={stripe} onChange={() => setstripe(!stripe)} />Stripe</div>
                <div className="statusList"><Checkbox className="checkBox" checked={bank} onChange={() => setbank(!bank)} />Bank</div>
              </div>
            </div>
            {/* <div className="start-end-date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Expected Payment Date
                </Typography>
                <DemoContainer
                  components={[
                    "DatePicker",
                    "DateTimePicker",
                    "TimePicker",
                    "DateRangePicker",
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      className="date-pick"
                      onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }}

                      views={["year", "month", "day"]}
                    />
                  </DemoItem>

                  <DemoItem>
                    <DatePicker
                      className="date-pick"
                      onChange={(e) => { handleendpaymentdatechange(e.$D, e.$M + 1, e.$y) }}

                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div> */}
          </div>
          <div className="wholeButton">
            <Button className='apply' onClick={() => { productfilter(); setOpen(false) }}>Apply</Button>
            <Button className='reset' onClick={() => { resetFilters() }}>Reset filter</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default BrandFilterPopup;
