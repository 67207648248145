import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
// import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button'
import "./Login.css"
// import profilebrand from '../../img/profilebrand.png'
import profilebrand from '../../img/Group 28.png'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Axios from '../../Axios';
import consts from '../../constants';
import { Toaster, toast } from 'react-hot-toast'
import CryptoJS from "crypto-js";


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   boxShadow: 'none !important',
// }));

// const useStyles = makeStyles({

// });
// const conction = () => {
//   io('http://localhost:3500').connect()
// }
const Login = () => {
  // const classes = useStyles();

  useEffect(() => {
    document.title = "Dtracer"; //Test comments
  }, [])

  const navigate = useNavigate()
  const history = useLocation();

  const check = () => {
    const dts = window.localStorage.getItem('Squelch')
    if (dts === 'admin') {
      navigate('/dashboard')
    } else if (dts === 'subAdmin') {
      navigate('/dashboard')
    } else if (dts === 'user') {
      navigate('/user/dashboard')
    } else if (dts === 'brand') {
      navigate('/brand-/brand-dashboard')
    }
  }
  useEffect(() => {
    check()
  }, [])
  useEffect(() => {
    if (history?.state?.data === true) {
      block()
    }
  }, [history?.state?.data])

  const block = () => {
    if (history.state.data === true) {
      toast.error('Admin Blocked You!!!')
    }
  }

  const [lat, setLatitude] = useState()
  const [long, setLongitutde] = useState()

  useEffect(() => {
    // if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
    // } else {
    //   console.log("Geolocation not supported");
    // }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLatitude(latitude)
      setLongitutde(longitude)
    }

    function error() {
      console.log("Unable to retrieve your location");
    }
  }, []);




  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [active, setActive] = useState(false)
  const email = useRef(null);
  const password = useRef(null);

  const [emailerr, setemailerr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);

  const secretPass = 'DtRaCeRsEcrEtFrEe'

  const onSubmit = async () => {
    try {

      if (email.current.value === "") {
        setemailerr("Please Enter your Email Address");

      } else if (password.current.value === "") {
        setpassworderr("Please Enter your Password");

      } else {
        setActive(true)
        // const re = new RegExp('^[a-z]+@[a-z]+\.[a-z]{1,3}$')
        // re.test(email.current.value)
        window.localStorage.removeItem("AdminToken");

        const { data } = await Axios.post("/adminlogin", {
          email: email.current.value,
          password: password.current.value,
          latitude: lat,
          longitude: long
        });
        if (data?.success) {
          // conction()
          // await Axios.get("/users/check2fa", {
          //   headers: {
          //     Authorization: data?.result?.token,
          //   },
          // })
          //   .then((res) => {
          //     return res.data.result
          //       ? navigate(`${consts.route}/2FA`, {
          //         state: { token: data?.result },
          //       })
          //       : (window.localStorage.setItem(
          //         "Mellifluous",
          //         data.result.token
          //       ),

          //         window.localStorage.setItem(
          //           "users",
          //           JSON.stringify(data.result.user)
          //         ),
          //         window.localStorage.setItem(
          //           "kyc_verify", data.result.user.kyc_verify
          //         ),
          //         navigate(`${consts.route}/`)
          //       );
          //   })
          //   .catch((err) => {
          //     console.log(err.response.data.message, "err");
          //   });
          toast.success('LoggedIn Successfully')
          window.localStorage.setItem(
            "electrónico",
            email.current.value
          )
          window.localStorage.setItem(
            "AdminToken",
            data.result.token
          )
          window.localStorage.setItem(
            "Squelch",
            data.result.admin.role
          )
          if ((data?.result?.admin?.role === 'admin') || (data?.result?.admin?.role === 'subAdmin')) {
            if (data?.result?.admin?.role === 'subAdmin') {

              const data1 = await Axios.post('/admin/getSubadminPermission', {},
                {
                  headers: {
                    Authorization: data.result.token,
                  },
                }
              )
              if (data1?.data?.success && data1?.data?.result?.length > 0) {
                const datas = CryptoJS.AES.encrypt(
                  JSON.stringify(data1?.data?.result),
                  secretPass
                ).toString();
                window.localStorage.setItem('isimo', datas)
              } else {
                // defaultStatus()
              }
              setTimeout(() => {
                navigate(`${consts.route}/dashboard`)
              }, 2000);
            } else {
              setTimeout(() => {
                navigate(`${consts.route}/dashboard`)
              }, 2000);
            }

          } else if (data?.result?.admin?.role === 'user') {
            const data = window.localStorage.getItem('camino')
            if (data) {
              setTimeout(() => {
                navigate(`${consts.route}/user/productdetails/${data}`)
                window.localStorage.removeItem('camino')
              }, 2000);
            } else {
              setTimeout(() => {
                navigate(`${consts.route}/user/dashboard`)
              }, 2000);
            }

          } else {
            setTimeout(() => {
              navigate(`${consts.route}/brand-/brand-dashboard`)
            }, 2000);
          }




        }
      }
    } catch (error) {
      setActive(false)
      console.log(error, "err")
      if (
        error?.response?.data?.message === "Invalid Email" ||
        error?.response?.data?.message === "Please Verify Email"
      ) {
        setemailerr(error?.response?.data?.message);
      }
      if (error?.response?.data?.message === "Wrong Password") {
        setpassworderr(error?.response?.data?.message);
      } else {
        setemailerr(error?.response?.data?.message);
      }
    }
  };

  function verifyemail() {
    // setemailerr(null)
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.current.value)) {
      setemailerr("Please Enter valid email")
    } else if (email.current.value === "") {
      setemailerr("Please Enter Email")
    } else {
      setemailerr("")
    }
  }

  useEffect(() => {
    if (history?.state?.emailVerify === 0) {
      toast.success("Email Verified Successfully", {
        icon: '👏',
      })
    } else if (history?.state?.emailVerify === 1) {
      toast.error("Email Already Verified")
    } else if (history?.state?.login === 0) {
      toast.success("Please Login")
    }
  }, []);

  return (

    <div className='login-page'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
            <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
            <p>We Protect your brand 100% with blockchain technology</p>
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>
            <div className="form-input">
              <div className='login-txt'>
                <h4>Hey! Buddy</h4>
                <h1>Welcome Back 🥳 </h1>
              </div>

              <div className='email-password-block'>
                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email}
                    onChange={() => {
                      verifyemail();
                    }} />
                  {emailerr !== null ? (
                    <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{emailerr}</div>
                  ) : (
                    <></>
                  )}

                </div>

                <div className='cmn-input-cls'>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      inputRef={password}
                      onChange={() => {
                        setpassworderr(null);
                      }}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {passworderr !== null ? (
                    <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{passworderr}</div>
                  ) : (
                    <></>
                  )}
                  {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                </div>
                <div className='crtPassword'><Link to={`${consts.route}/forgot-mail`}>Forgot Password ?</Link></div>
              </div>
              {
                active === true ?
                  <Button className='submit'>Loading...</Button>
                  :
                  <Button className='submit' onClick={() => { onSubmit(); }}>Login</Button>
              }

              <div className='did-have-account'>
                If you didn't have an account?<span><Link to="/register">Register Now</Link></span>
              </div>

              {/* <div className='choice'>Or Used</div>

              <Button className='meta'><div className='btn-logo'><img src={btnlogo} alt="logo" /></div>Wallet Metamask</Button> */}
              {/* <div className='diamond-img'><img src={diamond} alt="diamond"/></div> */}
            </div>


          </Grid>



        </Grid>
      </Box>


    </div>
  )
}

export default Login
