import React, {useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import './QrProduct.css'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

  const useStyles = makeStyles({

    modalpara:{
        fontSize: '18px',
        textAlign:'center',
        maxWidth:'750px !important',
        margin:'0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)' : {
            paddingBottom: '36px',
          },

      },

      reasonBlock:{
        fontSize:'24px',
        fontWeight:'600',
        margin:'0'
    }

  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Reactivate = () => {
  const classes = useStyles();

  const [openAdminReActive, setOpenAdminReActive] = useState(false);
  const handleOpenAdminReActive = () => setOpenAdminReActive(true);
  const handleCloseAdminReActive = () => setOpenAdminReActive(false);

  return (
    <div >
      
           <div className="qr-detail">
      <Button onClick={handleOpenAdminReActive} variant="contained">Reactivate</Button>
      <Modal 
        open={openAdminReActive}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup rejectpop approve">
        <div className="menu" onClick={handleCloseAdminReActive} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            
       <div className="reject-info">
        <div className="rejection-indication">
            <p className="reject-head">REACTIVATE PRODUCT</p>
            <p className="reason-reject approval-txt">You are about to reactivate the product, this will leave it in the same state 
                 it was in before it was blocked.</p>
        </div>
       </div>
       



       <div className="decline-cancel-btn">
             
              <div className="cancel-button">
                <Button className="approve">Confirm</Button>
              </div>
       </div>

        </Box>
      </Modal>
      </div>
    </div>

    
  );
};

export default Reactivate;
