import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import { Toaster, toast } from 'react-hot-toast'

import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import Axios from '../../../Axios'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'
import "./Owner.css"
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import guard from "../../../img/guard.png";
import home from "../../../img/home-btn.png"
import proceed from '../../../img/proceed.png'
import AdminMenuSide from '../../Admin/AdminMenuSide';
import cancel from "../../../img/cancel.png"



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const ChangeOwner = () => {

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const location = useLocation()
    const navigate = useNavigate()
    const [datas, setDatas] = useState()
    const [pass, setpass] = useState("")
    const [passerr, setpasserr] = useState(null)
    const user = window.localStorage.getItem('AdminToken')
    const [lat, setLatitude] = useState('')
    const [long, setLongitutde] = useState('')

    const [details, setDetails] = useState({})

    useEffect(() => {
        console.log(location.state, "state")
        setDatas(location?.state?.data)
        if (location.state.detail) {
            setDetails(location.state.detail)

        }
    }, [location])

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    useEffect(() => {
        console.log('useEffect triggered');
        if ("geolocation" in navigator) {
            // Request the user's location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Success callback
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setLatitude(position.coords.latitude)
                    setLongitutde(position.coords.longitude)
                    console.log("Latitude:", latitude);
                    console.log("Longitude:", longitude);
                    // Here you can use latitude and longitude to determine the user's location
                },
                (error) => {
                    // Error callback
                    console.error("Error getting user's location:", error.message);
                }
            );
        } else {
            // Geolocation is not supported
            console.error("Geolocation is not supported by this browser.");
        }
  }, []);

    const OwnerPayment = async (data) => {
        try {

            if (pass === "") {
                setpasserr("Password is Required")
            }

            else {
                await Axios.post(`/admin/ownerpayment`, {
                    product_id: data,
                    email: details.email,
                    phone: details.phone,
                    password: pass,
                    amount: datas?.product_details?.Change_Owner_price,
                    latitude: lat,
                    longitude: long
                },
                    {
                        headers: {
                            Authorization: user,
                        },
                    }).then(async (res) => {
                        console.log(res)
                        if (res?.data?.success) {
                            //setStsDownloads(false)
                            window.location.href = res?.data?.result?.url
                            //toast.success(res?.data?.message)

                        }
                        else {
                            //setStsDownloads(false)
                            toast.error(res?.data?.message)
                        }
                    }).catch((err) => {
                        toast.error(err?.response?.data?.message)
                        console.log(err)
                        // setStsDownloads(false)
                    })
            }
        } catch (error) {
            //setStsDownloads(false)

            console.log("🚀 ~ file: AdminProducts.js:756 ~ downloadPdf ~ error:", error)
        }

    }

    return (
        <div className='dashboard-page'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>

                        <div className='extented-warrenty'>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div2'>
                                    <div>
                                        {/* <Link to="/user/changeowner"><img src={Menuclose} alt="close" /></Link> */}
                                        <div onClick={() => { navigate('/user/changeowner', { state: { data: datas } }) }} ><img src={Menuclose} alt="close" /></div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className='watch-div'>
                                    <img src={datas?.product_details?.main_image} alt="watch" />
                                </div>
                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        {datas?.product_details?.Product_Name} ID {datas?.product_details?.product_id}
                                    </div>
                                    {/* <div className='watch-description'>
                                        Test product description, this is a demonstration of the description of a product where we inform about the characteristics of the product
                                    </div>
                                    <div className='map-component'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15719.42095469063!2d78.1227348!3d9.94600025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1699970698829!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div> */}
                                    {/* <div className='Guarantee'>
                                        Guarantee activated on 01/01/2030 Valid until 01/01/2032
                                    </div>
                                    <div className='warrenty-records1'>
                                        Run don't lose it! The time allowed by the manufacturer to extend the warranty runs out,
                                        <p className='para-warrenty'>you have <span>3</span> days left</p>
                                    </div> */}

                                    {/* <div className='owner-text'>
                                        <div>
                                            OWNER :
                                        </div>
                                        <div className='smith'>
                                            john smith
                                        </div>
                                    </div> */}
                                    <div className='owner-form'>

                                        <div>
                                            <TextField id="outlined-basic1" label="Password" variant="outlined" name='password' onChange={(e) => { setpass(e.target.value); setpasserr(null) }} />
                                        </div>
                                        {passerr !== null ? (
                                            <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{passerr}</div>
                                        ) : (
                                            <></>
                                        )}

                                    </div>

                                    <div className='Guarantee3'>
                                        <div className='securidy-code-text'>
                                            THIS ACTION GENERATES A NEW BLOCK IN THE BLOCKCHAIN NETWORK.INFORMING THE NEW HOLDER OF THE <span className='go-green'>PRODUCT ID ADD12231-1,</span>  IT IS NOT REVERSIBLE, BEFORE CONTINUING CARRYING OUT THE NECESSARY CHECKS,WE WILL COMMUNICATE VIA EMAIL WITH THE NEW HOLDER TO REQUEST HIS NEW SECURITY KEY
                                        </div>

                                    </div>

                                    <div className='warrenty-main-display'>
                                        <div className='watch-div' >   <img src={datas?.product_details?.main_image} alt="watch" /></div>

                                        <div className='warrenty-records1 br'>
                                            Change Owner
                                        </div>
                                        <div>
                                            <div className='for-only-65'>
                                                <div>for only</div>
                                                <span>${datas?.product_details?.Change_Owner_price} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {/* <Link to='/user/purchasesuccess'><Button className='proceed-btn' ><img src={proceed} alt="homr" />proceed</Button></Link> */}
                                        {/* <Button className='proceed-btn' onClick={() => { navigate('/user/purchasesuccess', { state: { data: datas } }) }} ><img src={proceed} alt="homr" />proceed</Button> */}
                                        <Button className='proceed-btn' onClick={() => { OwnerPayment(datas?.product_details?.product_id) }}><img src={proceed} alt="homr" />proceed</Button>
                                    </div>
                                    {/* 
                                    <div className='trns-details'>
                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <hr className='warrenty-hr' />

                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>N</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                        </div>




                                    </div> */}
                                    <div className='user-logo2'>
                                        <img src={guard} alt="lg-1" />
                                        <span className='go-green'>100%</span> <span>SECURE PAYMENTS</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default ChangeOwner
