import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import profilebrand from '../../img/Group 28.png';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import './RegisterTrial.css'
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { verifyEmail, verifyPhone, verifyUrl, verifyText, verifyPostCode, verifyFreeText } from '../GenericFunctions';
import { Toaster, toast } from 'react-hot-toast';
import Axios from '../../Axios';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'

const styleForms = {
  '& > :not(style)': { 
   minWidth: '35rem' },
   textAlign : 'left',
   '& .MuiInputBase-input': {
        color: 'rgb(4, 69, 175)', 
      },
   '& .MuiInput-underline:before': {
      borderBottomWidth: '1,5px', 
      borderBottomColor: 'rgb(4, 69, 175)', 
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'rgb(4, 69, 175)', 
    },
    '& .MuiInput-underline:after': {
      borderBottomWidth: '2,5px', 
      borderBottomColor: 'rgb(4, 69, 175)', 
    },
}

const RegisterTrial = () => {


  const { t } = useTranslation('common');
  const { i18n } = useTranslation('common');
  const [step, setStep] = useState(-1);
  const [logo, setlogo] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate()
  const [idReg, setIdReg] = useState();
  const [tid, setTid] = useState();
  const [checkFields, setCheckFields] = useState({
    stepOne: { error: false, msg: "", value: undefined },
    name: { error: true, msg: "", value: undefined },
    lastName: { error: false, msg: "", value: undefined },
    phone: { error: false, msg: "", value: undefined },
    email: { error: false, msg: "", value: undefined },
    company: { error: false, msg: "", value: undefined },
    stepCompany: { error: false, msg: "", value: undefined },
    url: { error: false, msg: "", value: undefined },
    brandName: { error: false, msg: "", value: undefined },
    logo: { error: true, msg: "", value: undefined },
    stepContact: { error: false, msg: "", value: undefined },
    contactName: { error: true, msg: "", value: undefined },
    contactEmail: { error: false, msg: "", value: undefined },
    contactPhone: { error: false, msg: "", value: undefined },
    contactAddress: { error: false, msg: "", value: undefined },
    contactPostCode: { error: false, msg: "", value: undefined },
    stepBilling: { error: false, msg: "", value: undefined },
    billingNumber: { error: true, msg: "", value: undefined },
    billingName: { error: false, msg: "", value: undefined },
    billingAddress: { error: false, msg: "", value: undefined },
    billingPostCode: { error: false, msg: "", value: undefined },
    legalConditions: { error: true, msg: "Mandatory", value: undefined },
  });


  const checkSteps = (step) => {
    let valid = false;
    if (step === 0) {
      valid = checkFields.name.value && checkFields.lastName.value && checkFields.phone.value && checkFields.email.value ? true : false;
      setCheckFields({ ...checkFields, stepOne: { error: !valid, msg: valid ? '' : t('components.registerTrial.form.errors.review') } })
      if (valid) saveStepOne();
    }
    if (step === 1) {
      valid = checkFields.url.value && checkFields.brandName.value && checkFields.logo.value ? true : false;
      setCheckFields({ ...checkFields, stepCompany: { error: !valid, msg: valid ? '' : t('components.registerTrial.form.errors.review') } });
      if (valid) saveStepCompany();
    }
    // if (step === 2) {
    //   valid = checkFields.contactName.value && checkFields.contactEmail.value && checkFields.contactPhone.value && checkFields.contactAddress.value && checkFields.contactPostCode.value ? true : false;
    //   setCheckFields({ ...checkFields, stepContact: { error: !valid, msg: valid ? '' : t('components.registerTrial.form.errors.review') } });
    //   if (valid) saveStepContact();
    // }
    // if (step === 3) {
    //   valid = checkFields.billingNumber.value && checkFields.billingName.value && checkFields.billingAddress.value && checkFields.billingPostCode.value ? true : false;
    //   setCheckFields({ ...checkFields, stepBilling: { error: !valid, msg: valid ? '' : t('components.registerTrial.form.errors.review') } });
    //   if (valid) saveStepBilling();
    // }

    if (valid) setStep(step + 1);
  }

  const createLogo = async (e) => {
    try {
      let files = await e.target.files[0]
      if (!files.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        toast.error('Please Select Only Image')
      } else {
        let formData = await new FormData();
        formData.append("image", files)

        const { data } = await Axios.post('/users/uploadlogo', formData)

        if (data?.result) {
          console.log("logo saved:", data);
          setlogo(data?.result)
          setCheckFields({ ...checkFields, logo: { error: false, msg: '', value: data.result } })
          // navigate(`${consts.route}/register-success`)
          toast.success('Image uploaded Successfully')
        }
      }

    } catch (error) {
      console.log(error, "err")
      setCheckFields({ ...checkFields, logo: { error: true, msg: 'Error uploading image', value: undefined } })
    }
  }

  const sendForm = async () => {

    try {
      
      
      if(checkFields.legalConditions.value){

        const queryParams = new URLSearchParams(location.search);
        const fpr = queryParams.get('fpr') ? queryParams.get('fpr') : '';

        const payload = {
          legalConditions: true,
          idReg,
          fp_tid : tid ? tid : '9032eaaf-9abb-4a9e-b405-b5d72ef255a0' ,
          fpr
        }

  
        const response = await Axios.post('/admin/register-brand', payload);

        toast.success(t(response.data.updated ? 'components.registerTrial.form.success.updated' : 'components.registerTrial.form.success.saved'));

        const url = response.data.url;
        
        // setStep(step + 1);
        // test_bIYdSaewYdhX9VudQR LOCAL
        // test_9AQcO6dsUgu92t2aEG TESTING
        // 28oeWT3r9b2VgeIeUY PROD
        window.location.href = url;
      }
      
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }


  }



  

  const saveStepOne = async () => {

    try {
      const payload = {
        user_info: {
          name: checkFields.name.value + ' ' + checkFields.lastName.value,
          phone: checkFields.phone.value
        },
        email: checkFields.email.value,
        idReg
      }

      const response = await Axios.post('/admin/register-brand', payload);
      // console.log("data:", response.data);
      setIdReg(response.data.result._id);
      toast.success(t(response.data.updated ? 'components.registerTrial.form.success.updated' : 'components.registerTrial.form.success.saved'));

    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  const saveStepCompany = async () => {

    try {
      const payload = {
        company: {
          web: checkFields.url.value,
          brand_name: checkFields.brandName.value,
          logo: checkFields.logo.value
        },
        email: checkFields.email.value,
        idReg
      }

      const response = await Axios.post('/admin/register-brand', payload);
      // console.log("data:", response.data);

      toast.success(t(response.data.updated ? 'components.registerTrial.form.success.updated' : 'components.registerTrial.form.success.saved'));

    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  const saveStepContact = async () => {

    try {
      const payload = {
        contact_info: {
          contact_name: checkFields.contactName.value,
          contact_mail: checkFields.contactEmail.value,
          contact_phone: checkFields.contactPhone.value,
          address: checkFields.contactAddress.value,
          pin_code: checkFields.billingPostCode.value
        },
        email: checkFields.email.value,
        idReg
      }

      const response = await Axios.post('/admin/register-brand', payload);
      // console.log("data:", response.data);

      toast.success(t(response.data.updated ? 'components.registerTrial.form.success.updated' : 'components.registerTrial.form.success.saved'));

    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  const saveStepBilling = async () => {

    try {
      const payload = {
        bill_info: {
          tax_id: checkFields.billingNumber.value,
          tax_name: checkFields.billingName.value,
          address_billing: checkFields.billingAddress.value,
          pin_code_billing: checkFields.billingPostCode.value
        },
        email: checkFields.email.value,
        idReg
      }

      const response = await Axios.post('/admin/register-brand', payload);
      // console.log("data:", response.data);

      toast.success(t(response.data.updated ? 'components.registerTrial.form.success.updated' : 'components.registerTrial.form.success.saved'));

    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }


  useEffect(() => {


    const getEmail = async (clientId) => {
      try {

        const payload = {
          idReg: clientId
        }

        const response = await Axios.post('/admin/get-email-brand', payload);

        const email = response.data.result;
        setCheckFields({ ...checkFields, contactEmail: { error: '', msg: '', value: email } })
        setIdReg(clientId);
        setStep(4);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setStep(0);
      }
    }

    const finalizeRegister = async (sessionId, fpr,testMode) => {
      try {

        const response = await Axios.post('/admin/finalize-register-brand', { sessionId });

        toast.success(t(response.data.checkedSuscription ? 'components.registerTrial.form.success.updated' : 'components.registerTrial.form.success.saved'));

        try {
          window.fpr('referral', { email: response?.data?.result?.email });
        } catch (error) {
          console.error("FPR Error:", error);
        }

        navigate(testMode !== undefined && testMode !== null ? '/thank-you-trial?fpr=' + fpr + '&test_mode=' + testMode : '/thank-you-trial?fpr=' + fpr);

      } catch (error) {
        toast.error(error?.response?.data?.message)
        navigate(testMode !== undefined && testMode !== null ? '/error-trial?fpr=' + fpr + '&test_mode=' + testMode : '/error-trial?fpr=' + fpr);
      }
    }

    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('language') ? queryParams.get('language') : '';
    const sessionId = queryParams.get('sessionId') ? queryParams.get('sessionId') : '';
    const fpr = queryParams.get('fpr') ? queryParams.get('fpr') : '';
    const testMode = queryParams.get('test_mode') ? queryParams.get('test_mode') : '';
    const clientId = queryParams.get('clientId') ? queryParams.get('clientId') : '';

    // const script = document.createElement('script');
    // script.src = 'https://clk1.reachclk.com/sdk/reach.js';
    // script.async = true;
    // document.body.appendChild(script);

    if (lang) {
      i18n.changeLanguage(lang)
    }

    if (clientId) {
      getEmail(clientId);
    }

    if (sessionId) {
      finalizeRegister(sessionId, fpr,testMode);
    }

    if (!clientId && !sessionId) {
      // script.onload = () => {
      //   if (window.Reach) {
      //     window.Reach.track();
      //   }
      // };
      setLoading(false);
      setStep(0);

    }


    console.log("Register trial working")

    // const script = document.createElement('script');
    // script.src = 'https://cdn.firstpromoter.com/fpr.js';
    // script.async = true;
    // document.body.appendChild(script);

    setTimeout (() => {
      const tid = window.FPROM && window.FPROM.data.tid;
      console.log("TID: ", tid);
      setTid(tid);
    }, 500);

    setTimeout(() => {
      const inputElement = document.querySelector('.PhoneInputInput');
      // Verificar que el elemento existe
      if (inputElement) {
        inputElement.classList.add('MuiInputBase-input', 'MuiInput-input', 'css-1x51dt5-MuiInputBase-input-MuiInput-input');
      }
    }, 500);

    setTimeout(() => {
      const inputElement = document.querySelector('.PhoneInputInput');
      // Verificar que el elemento existe
      if (inputElement) {
        inputElement.classList.add('MuiInputBase-input', 'MuiInput-input', 'css-1x51dt5-MuiInputBase-input-MuiInput-input');
      }
    }, 1000);

    setTimeout(() => {
      const inputElement = document.querySelector('.PhoneInputInput');
      // Verificar que el elemento existe
      if (inputElement) {
        inputElement.classList.add('MuiInputBase-input', 'MuiInput-input', 'css-1x51dt5-MuiInputBase-input-MuiInput-input');
      }
    }, 1500);



  }, []);



  return (
    <div className='register-trial'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid item container spacing={0}>


          <Grid item container direction="column" justifyContent="flex-start" xs={12} sm={12} md={12} lg={4} xl={4} >
            <Grid item  >
              <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
              <div className='titles-reg'>
                <p>{t('components.registerTrial.description')}</p>
              </div>
            </Grid>
            <Grid item  >
              <div className='titles-reg trial'>
                <h1 className=''>{t('components.registerTrial.form.title')}</h1>
                <h2>{t('components.registerTrial.form.description')}</h2>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='d-flex justify-content-center trial'>

            <div className="form-reg-trial">
              {!loading && (<div className=''>
                {step < 3 && (
                  <div className='step-title'>
                    <h1>{step + 1} {"->"} {t('components.registerTrial.form.stepTitles.' + step.toString())}</h1>
                  </div>)}
              </div>)}
              

              {step === 0 && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center'>
                      <div className='input-reg'>
                        <TextField id="standard-basic"  color="success" sx={styleForms}type='text' error={checkFields.name.error} helperText={t(checkFields.name.msg)} value={checkFields.name.value} label={t('components.registerTrial.form.stepOne.name')}
                          variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, name: verifyText(e) }) }} required sx={styleForms}/>

                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'usernameerr'}</div> : <></>} */}
                      </div>
                      <div className='input-reg'>
                        <TextField id="standard-basic" sx={styleForms}type='text' error={checkFields.lastName.error} helperText={t(checkFields.lastName.msg)} value={checkFields.lastName.value} label={t('components.registerTrial.form.stepOne.lastName')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, lastName: verifyText(e) }) }} required className='' />
                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'usernameerr'}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-6'>
                        {/* <TextField error={checkFields.phone.error} helperText={t(checkFields.phone.msg)} id="standard-basic" sx={styleForms}value={checkFields.phone.value} label={t('components.registerTrial.form.stepOne.phone')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, phone: verifyPhone(e) }) }} required className='' /> */}
                        <PhoneInput
                          className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl css-1eed5fa-MuiInputBase-root-MuiInput-root styled-input'
                          placeholder={t('components.registerTrial.form.stepOne.phone')}
                          value={checkFields.phone.value}
                          onChange={(e) => { setCheckFields({ ...checkFields, phone: verifyPhone(e) }) }} />
                        {checkFields.phone.error ? <div className='h3' style={{ color: '#d32f2f', textAlign: 'center', fontSize: "1em", marginTop: '5px' }}>{t(checkFields.phone.msg)}</div> : <></>}
                      </div>

                      <div className='input-reg col-12'>
                        <TextField error={checkFields.email.error} helperText={t(checkFields.email.msg)} id="standard-basic" sx={styleForms}type='email' value={checkFields.email.value} label={t('components.registerTrial.form.stepOne.email')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, email: verifyEmail(e) }) }} required className='' />
                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'Error email'}</div> : <></>} */}
                      </div>
                      {/* <div className='input-reg col-12'>
                        <TextField error={checkFields.company.error} helperText={t(checkFields.company.msg)} id="standard-basic" sx={styleForms}value={checkFields.company.value} label={t('components.registerTrial.form.stepOne.company')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, company: verifyFreeText(e) }) }} required className='' />
                      </div> */}
                      {checkFields.stepOne.error && (<>
                        <h4>{checkFields.stepOne.msg}</h4>
                      </>)}
                    </div>

                  </div>
                </Box>

              )}
              {step === 1 && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center'>
                      {/* <h3>{t('components.registerTrial.form.stepCompany.talkCompany')}</h3> */}
                      <br />
                      <label className='label-brand'>{t('components.registerTrial.form.stepCompany.yourIdentity')}</label>
                      <br />
                      <br />
                      <div className='input-reg-2 row d-flex'>
                        <div className='col-auto d-flex'>
                          {/* <div className='col-auto'>
                          <br/>
                          </div> */}
                          {checkFields.logo.value ?
                            (<>
                              <div className='profile-image profile-image-design-block logo-register'>
                                <img src={checkFields.logo.value} alt={''} />
                              </div>
                            </>) :
                            (<h4>{t('components.registerTrial.form.stepCompany.yourLogo')}&nbsp;&nbsp;</h4>)
                          }
                          <div className='profile-image-outer'>
                            <Button
                              className='submitReg button-logo'
                              variant=""
                              component="label"
                            >
                              {checkFields.logo.value ? t('components.registerTrial.form.stepCompany.logoButtonChange') : t('components.registerTrial.form.stepCompany.logoButton')}
                              <input
                                type="file"
                                hidden
                                onChange={createLogo}
                              />
                            </Button>
                            {checkFields.logo.error ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{checkFields.logo.msg}</div> : <></>}
                          </div>

                        </div>
                        {/* <div className='col-auto'>

                          <p>{t('components.registerTrial.form.stepCompany.yourLogo')}</p>
                        </div> */}
                      </div>
                      <div className='input-reg-2 col-6 '>
                        <TextField id="standard-basic" sx={styleForms}type='text' error={checkFields.url.error} helperText={t(checkFields.url.msg ? checkFields.url.msg : t('components.registerTrial.form.stepCompany.tellWeb'))} value={checkFields.url.value} label={t('components.registerTrial.form.stepOne.web')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, url: verifyUrl(e) }) }} required className='' />

                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'usernameerr'}</div> : <></>} */}
                      </div>
                      <br /><br />
                      <div className='input-reg-2 col-6 '>
                        <TextField id="standard-basic" sx={styleForms}type='text' error={checkFields.brandName.error} helperText={checkFields.brandName.msg ? t(checkFields.brandName.msg) : t('components.registerTrial.form.stepCompany.tellBrand')} value={checkFields.brandName.value} label={t('components.registerTrial.form.stepOne.brandName')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, brandName: verifyText(e) }) }} required className='' />
                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'usernameerr'}</div> : <></>} */}
                      </div><br /><br />

                      {checkFields.stepCompany.error && (<>
                        <h4>{checkFields.stepCompany.msg}</h4>
                      </>)}
                    </div>

                  </div>
                </Box>

              )}
              {step === 2 && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center step-terms'>
                      <div>
                        <p>{t('components.registerTrial.form.terms.message')}</p>
                        <p>{t('components.registerTrial.form.terms.messageTwo')}</p>
                        <p>{t('components.registerTrial.form.terms.messageThree')}</p>
                      </div>
                      <br />
                      {/* <h2>{t('components.registerTrial.form.terms.title')}</h2> */}
                      <h2>{t('components.registerTrial.form.description')}</h2>
                      <br />
                      <div className='text-cond'>
                        <h3>{t('components.registerTrial.form.terms.desc')}</h3>
                      </div>
                      <br />
                      <div className='input-reg-conditions'>
                        <Checkbox onChange={ (e) => {setCheckFields({...checkFields, legalConditions : { value : e.target.checked}}) ; console.log("CHECKBOX" , e.target.checked)}}/>
                        <span>{t('components.registerTrial.form.terms.textLink')}&nbsp;<Link  className='link-conditions' target="_blank" to="https://dtracer.io/wp-content/uploads/2024/06/Terms-and-Conditions-of-Dtracer.pdf">{t('components.registerTrial.form.terms.link')}</Link></span>
                        {/* {!checkFields.legalConditions.value && (<>
                        <h4>{'Field mandatory'}</h4>
                      </>)} */}
                      </div>
                      <br />
                      <Button className='submitReg' onClick={() => { sendForm() }}>{t('components.registerTrial.form.button')}</Button>
                    </div>
                  </div>
                </Box>
              )}



              {step === 3 && (
                <Box
                  component="form"
                  noValidate
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center step-terms'>
                      <br />
                      <h2>{t('components.registerTrial.form.thanks.title')}</h2>
                      <br />
                      <p>{t('components.registerTrial.form.thanks.msg')}</p>
                      <p>{t('components.registerTrial.form.thanks.msg2')}</p>
                      <br />
                      <Button className='submitReg' onClick={() => { navigate('/') }}>{t('components.registerTrial.form.thanks.button')}</Button>
                    </div>
                  </div>
                </Box>
              )}

              {!loading && (<div className='row d-flex butons-container justify-content-between'>
                {step > 0 && step < 3 && (
                  <span className='buttons-form'>
                    <ArrowBackIosIcon />
                    <Button className='button-form ' onClick={() => { setStep(step - 1) }}>{t('components.registerTrial.form.buttonPrevious')}</Button>
                  </span>)}
                {step < 2 && (
                  <span className='buttons-form align-right'>
                    <Button className={'button-form'} onClick={() => { checkSteps(step) }}>{t('components.registerTrial.form.buttonNext')}</Button>
                    <ArrowForwardIosIcon className={checkFields.stepOne.error ? 'opaqueButton ' : ''} />
                  </span>)}
                {/* {step === 3 && (<Button className='submit' onClick={() => { }}>{t('components.registerTrial.form.button')}</Button>)} */}
              </div>)}

              {/* {step === 9 && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center price-table'>
                      <div className=''>

                        <stripe-pricing-table
                          pricing-table-id="prctbl_1PTmesExURxVkQFirAlSPA7v"
                          publishable-key="pk_test_51N8m4BExURxVkQFihz5uYMp1zWu6gNuSuPK7EamfZ9YjNNb1LfneHq9UD5xUYVkEcR7Msz0SmTyhtgp0S1wKgDnE0042zcmxgJ"
                          customer-email={checkFields.contactEmail.value}
                          client-reference-id={idReg}
                        >
                        </stripe-pricing-table>
                      </div>
                    </div>
                  </div>
                </Box>
              )} */}

              {step === 10 && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center'>

                      <div className='input-reg col-6 '>
                        <TextField id="standard-basic" sx={styleForms}type='text' error={checkFields.contactName.error} helperText={t(checkFields.contactName.msg)} value={checkFields.contactName.value} label={t('components.registerTrial.form.stepTwo.name')}
                          variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, contactName: verifyText(e) }) }} required className='' />

                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'usernameerr'}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-12'>
                        <TextField error={checkFields.contactEmail.error} helperText={t(checkFields.contactEmail.msg)} id="standard-basic" sx={styleForms}type='email' value={checkFields.contactEmail.value}
                          label={t('components.registerTrial.form.stepTwo.email')} onChange={(e) => setCheckFields({ ...checkFields, contactEmail: verifyEmail(e) })} required className='' />
                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'Error email'}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-6'>
                        <TextField error={checkFields.contactPhone.error} helperText={t(checkFields.contactPhone.msg)} id="standard-basic" sx={styleForms}value={checkFields.contactPhone.value} label={t('components.registerTrial.form.stepTwo.phone')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, contactPhone: verifyPhone(e.target.value) }) }} required className='' />
                        {/* {checkFields.phone.error ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "1em" }}>{checkFields.phone.msg}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-12'>
                        <TextField error={checkFields.contactAddress.error} helperText={t(checkFields.contactAddress.msg)} id="standard-basic" sx={styleForms}value={checkFields.contactAddress.value} label={t('components.registerTrial.form.stepTwo.address')} variant="standard" onChange={(e) => setCheckFields({ ...checkFields, contactAddress: verifyFreeText(e) })} required className='' />
                      </div>
                      <div className='input-reg col-12'>
                        <TextField error={checkFields.contactPostCode.error} helperText={t(checkFields.contactPostCode.msg)} id="standard-basic" sx={styleForms}value={checkFields.contactPostCode.value} label={t('components.registerTrial.form.stepTwo.postCode')} variant="standard" onChange={(e) => setCheckFields({ ...checkFields, contactPostCode: verifyPostCode(e) })} required className='' />
                      </div>
                      {checkFields.stepContact.error && (<>
                        <h4>{checkFields.stepContact.msg}</h4>
                      </>)}
                    </div>

                  </div>
                </Box>
              )}

              {step === 11 && (
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                >
                  <div className='d-flex fields-form'>
                    <div className='content row justify-content-center'>

                      <div className='input-reg col-6 '>
                        <TextField id="standard-basic" sx={styleForms}type='text' error={checkFields.billingNumber.error} helperText={t(checkFields.billingNumber.msg)} value={checkFields.billingNumber.value} label={t('components.registerTrial.form.stepThree.tax')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, billingNumber: verifyFreeText(e) }) }} required className='' />

                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'usernameerr'}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-12'>
                        <TextField error={checkFields.billingName.error} helperText={t(checkFields.billingName.msg)} id="standard-basic" sx={styleForms}type='email' value={checkFields.billingName.value} label={t('components.registerTrial.form.stepThree.taxName')} onChange={(e) => setCheckFields({ ...checkFields, billingName: verifyFreeText(e) })} required className='' />
                        {/* {'true' ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "10px" }}>{'Error email'}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-6'>
                        <TextField error={checkFields.billingAddress.error} helperText={t(checkFields.billingAddress.msg)} id="standard-basic" sx={styleForms}value={checkFields.billingAddress.value} label={t('components.registerTrial.form.stepThree.address')} variant="standard" onChange={(e) => { setCheckFields({ ...checkFields, billingAddress: verifyFreeText(e) }) }} required className='' />
                        {/* {checkFields.phone.error ? <div className='h3' style={{ color: 'red', textAlign: 'center', fontSize: "1em" }}>{checkFields.phone.msg}</div> : <></>} */}
                      </div>
                      <div className='input-reg col-12'>
                        <TextField error={checkFields.billingPostCode.error} helperText={t(checkFields.billingPostCode.msg)} id="standard-basic" sx={styleForms}value={checkFields.billingPostCode.value} label={t('components.registerTrial.form.stepThree.postCode')} variant="standard" onChange={(e) => setCheckFields({ ...checkFields, billingPostCode: verifyPostCode(e) })} required className='' />
                      </div>
                      {checkFields.stepBilling.error && (<>
                        <h4>{checkFields.stepBilling.msg}</h4>
                      </>)}
                    </div>

                  </div>
                </Box>

              )}

            </div>

          </Grid>

        </Grid>
      </Box>



    </div>
  )
}

export default RegisterTrial;

