import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";


const QRTotal = ({ ActivateCount }) => {

  const [options, setOptions] = useState({
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        track: {
          background: '#f0f0f0',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: '30px',
            fontWeight: 700,
          }
        },

      },

    },
    // labels: ['Cricket'],
  })

  const [series, setSeries] = useState([0])

  useEffect(() => {
    setSeries([Math.floor(ActivateCount)])
  }, [ActivateCount])
  return (


    <div id="chart">
      <ReactApexChart options={options} series={series} type="radialBar" height={200} />
      <p className='font-size-cmn'>Total Products Activated</p>
    </div>


  );
}

export default QRTotal
// class QRTotal extends React.Component {
//   constructor(props) {
//     super(props);
//     console.log(props?.ActivateCount, "coutns")
//     this.state = {

//       series: [props?.ActivateCount],
//       options: {
//         chart: {
//           type: 'radialBar',
//         },
//         plotOptions: {
//           radialBar: {
//             hollow: {
//               size: '60%',
//             },
//             track: {
//               background: '#f0f0f0',
//             },
//             dataLabels: {
//               name: {
//                 show: false,
//               },
//               value: {
//                 fontSize: '30px',
//                 fontWeight: 700,
//               }
//             },

//           },

//         },
//         // labels: ['Cricket'],
//       },


//     };
//   }

//   componentDidUpdate(props) {

//     console.log(props?.ActivateCount, "coutns123")
//     this.state = {

//       series: [props?.ActivateCount],
//       options: {
//         chart: {
//           type: 'radialBar',
//         },
//         plotOptions: {
//           radialBar: {
//             hollow: {
//               size: '60%',
//             },
//             track: {
//               background: '#f0f0f0',
//             },
//             dataLabels: {
//               name: {
//                 show: false,
//               },
//               value: {
//                 fontSize: '30px',
//                 fontWeight: 700,
//               }
//             },

//           },

//         },
//         // labels: ['Cricket'],
//       },
//     };
//   }


//   render() {
//     return (


//       <div id="chart">
//         <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={200} />
//         <p className='font-size-cmn'>Total QR Codes Activated</p>
//       </div>


//     );
//   }
// }

// export default QRTotal;