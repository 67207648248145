import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import "./UserloginNew.css"
import { Toaster, toast } from 'react-hot-toast'
import userwatch from "../../../img/user-watch-logo.png"

const RecoverNew = () => {
  // const classes = useStyles();
  const history = useLocation();

  useEffect(() => {
    if (history?.state?.data === true) {
      console.log(history?.state?.data, "1");
      block()
    }
  }, [history?.state?.data])

  const block = () => {
    if (history.state.data === true) {
      console.log(history.state.data, "data-from product")
      toast.error('Admin Blocked You!!!')
    }
  }

  const email = useRef(null);

  const [emailerr, setemailerr] = useState(null);

  useEffect(() => {
    console.log("logs")
    if (history?.state?.emailVerify === 0) {
      toast.success("Email Verified Successfully", {
        icon: '👏',
      })
    } else if (history?.state?.emailVerify === 1) {
      toast.error("Email Already Verified")
    } else if (history?.state?.login === 0) {
      toast.success("Please Login")
    }
  }, []);

  return (

    <div className='login-page1'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} sx={{ alignItems: "center" }}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
            <div className='user-login-text1'>
              <div className='active-text'>
                ACTIVATE PRODUCT
              </div>
              <div>
                <img src={userwatch} alt="watch" className='watch-img' />
              </div>
              <div className='active-text'>
                TEST PRODUCT
                <div>
                  ID ADD-12231-11
                </div>

              </div>

            </div>
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>

            <div className="form-input1">
              <div className='login-main-text'>
                DTracer
              </div>

              {/* <div className='login-txt'>
                <h4>Hey! Buddy</h4>
                <h1>Welcome Back 🥳 </h1>
              </div> */}

              <div className='email-password-block'>
                <div className='cmn-input-cls'>
                  <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' inputRef={email}
                    onChange={() => {
                      setemailerr(null);
                    }} />
                  {emailerr !== null ? (
                    <div style={{ color: "red", textAlign: 'center' }}>{emailerr}</div>
                  ) : (
                    <></>
                  )}

                </div>

                {/* <div className='crtPassword'><Link to={`${consts.route}/reset-password`}>Forgot Password ?</Link></div> */}
              </div>

              <Link to="/user/recoveremail"><Button className='submit'  >Recover Password</Button></Link>

              {/* <div className='did-have-account'>
                If you didn't have an account?<span><Link to="/register">Register Now</Link></span>
              </div> */}

              {/* <div className='choice'>Or Used</div>

              <Button className='meta'><div className='btn-logo'><img src={btnlogo} alt="logo" /></div>Wallet Metamask</Button> */}
              {/* <div className='diamond-img'><img src={diamond} alt="diamond"/></div> */}
            </div>


          </Grid>



        </Grid>
      </Box>


    </div>
  )
}

export default RecoverNew;
