import React, { useState, useRef } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import customer from '../../../img/customer.svg'
import maptrace from '../../../img/maptrace.svg'
import guarantee from '../../../img/guarantee.svg'
import bestseller from '../../../img/bestseller.svg'
import bill from '../../../img/bill.svg'
import product from '../../../img/product.svg'
import supplychain from '../../../img/supplychain.svg'

import "../Homepage/Home.css";
import "./GenerateCertificate.css";
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import watch from "../../../img/user-watch-logo.png"
import Avatar from '@mui/material/Avatar';
import warrentylogo from "../../../img/logo-warrenty.png";
import secure from "../../../img/generate-certificate/secure.svg";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdminMenuSide from '../../Admin/AdminMenuSide';
import cancel from "../../../img/cancel.png"



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },
    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const GenerateCertificate1 = () => {

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }

    return (
        <div className='Generate-certificate-page page3'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>

                        <div className='extented-warrenty' onClick={closeMenuHandler}>

                            <div className='warrenty-body'>
                                <div className='warrenty-close-div2'>
                                    <div>
                                        <Link to="/user/generate-certificate2"><img src={Menuclose} alt="close" /></Link>
                                    </div>
                                    <div>

                                    </div>
                                </div>

                                <div className='watch-id'>
                                    <div className='watch-id-text'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                            }
                                            label="ORDER ID #1213"
                                        />
                                    </div>

                                    <div className='sales'>
                                        <div className='watch-div'>
                                            <img src={watch} alt="watch" />
                                        </div>

                                        <div className='col-svg'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="100" viewBox="0 0 3 100" fill="none">
                                                <path d="M1.33984 0.569336V87.6855" stroke="url(#paint0_linear_1464_6020)" stroke-width="1.76955" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6020" x1="1.34042" y1="87.5856" x2="-0.446899" y2="87.5752" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0378631" stop-color="#DFE5F4" />
                                                        <stop offset="0.132489" stop-color="#0070F0" />
                                                        <stop offset="0.244412" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='watch-description'>
                                            change of ownership
                                        </div>
                                        <div className='col-svg'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="100" viewBox="0 0 3 100" fill="none">
                                                <path d="M1.33984 0.569336V87.6855" stroke="url(#paint0_linear_1464_6020)" stroke-width="1.76955" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6020" x1="1.34042" y1="87.5856" x2="-0.446899" y2="87.5752" gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.0378631" stop-color="#DFE5F4" />
                                                        <stop offset="0.132489" stop-color="#0070F0" />
                                                        <stop offset="0.244412" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='price'>
                                            <h6>$65</h6>
                                        </div>
                                    </div>

                                    <div className='watch-sub-description'>
                                        We have sent you by email the certificate of authenticity of the product ID ADD12234-11 Thank you for your purchase
                                    </div>

                                    <div className='Guarantee'>
                                        Thanks for your purchase!
                                    </div>

                                    <div className='watch-sub-description'>
                                        We have registered this operation in the blockchain,<span> do not forget to check</span>
                                    </div>

                                    <div className='warrenty-records'>
                                        latest transaction
                                    </div>

                                    <div className='trns-details'>
                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>Tx</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>

                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        <p>From </p>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text'>
                                                        <p>To</p>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='gradient-border'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="415" height="2" viewBox="0 0 415 2" fill="none">
                                                <path d="M0.279297 0.884766H414.762" stroke="url(#paint0_linear_1464_6006)" stroke-width="1.08895" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1464_6006" x1="415.442" y1="0.384946" x2="-7.87292" y2="-3.32038" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#DFE5F4" />
                                                        <stop offset="0.503797" stop-color="#0070F0" />
                                                        <stop offset="1" stop-color="#DFE5F4" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>

                                        <div className='trns-body'>
                                            <div className='warrenty-display'>
                                                <div className='avatar-div'>
                                                    <Avatar>Tx</Avatar>
                                                </div>
                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text2'>
                                                        26 sec ago
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='warrenty-display'>

                                                <div className='reference-body'>
                                                    <div className='ref-text'>
                                                        <p>From </p>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                    <div className='ref-text'>
                                                        <p>To</p>
                                                        0x5fhheij556dvjhbrfb..
                                                    </div>
                                                </div>

                                            </div>
                                        </div>




                                    </div>



                                    <Link to="/user/generate-certificate1">
                                        <div className='gen-btn'>
                                            <Button>Thank You</Button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 39 39" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8082 12.5172C36.8082 16.3303 34.6946 20.0909 31.5243 23.8256C28.4166 27.4852 24.3311 31.0942 20.2464 34.7028C20.1005 34.8316 19.9126 34.9023 19.7182 34.9015C19.5238 34.9008 19.3364 34.8286 19.1915 34.6986C15.1068 31.0908 11.0248 27.4818 7.91671 23.8256C4.74637 20.0909 2.63281 16.3303 2.63281 12.5172C2.64392 11.4339 2.88701 10.3656 3.34561 9.38467C3.80421 8.40377 4.46761 7.53316 5.2909 6.8318C7.03723 5.3236 9.27055 4.50336 11.575 4.52381C15.4725 4.52381 17.7319 6.08189 19.7213 9.17787C21.7106 6.08189 23.97 4.52381 27.8675 4.52381C30.1715 4.50374 32.4042 5.32395 34.1501 6.8318C34.9734 7.53316 35.6368 8.40377 36.0954 9.38467C36.554 10.3656 36.7971 11.4339 36.8082 12.5172Z" fill="white" />
                                            </svg>
                                        </div>
                                    </Link>
                                    <div className='user-logo'>
                                        <img src={secure} alt="secure" />
                                        <div>
                                            <span>100%</span> SECURE PAYMENTS
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default GenerateCertificate1;

