import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import AdminMenuSide from '../../Admin/AdminMenuSide'
import "./MyProfile.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import ProfileUpload from '../../../img/profile-upload.png'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Axios from '../../../Axios';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const MyProfile = () => {

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }
    const [imgBannerName, setImgBannerName] = useState('No selected files')
    const [thump, setThump] = useState()
    const [profileBannerImageChange, setBannerImageChange] = useState(null)

    const createThump = async (e) => {
        try {
            let files = await e[0]
            let formData = await new FormData();
            formData.append("image", files)

            const { data } = await Axios.post('/users/uploadlogo', formData)
            if (data.result) {
                setThump(data.result)
                // navigate(`${consts.route}/register-success`)
                // toast.success('Image uploaded Successfully')
            }

        } catch (error) {
            console.log(error, "err")
        }
    }



    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />

                            </div>

                        </Item>
                        <div className='profile-heading-name'>
                            <h1>My Profile</h1>
                        </div>

                        <div className='extented-warrenty'>

                            <div className='warrenty-body profile-body'>
                                <div className='profile-image-outer profile-dp'>

                                    <div className='profile-image profile-image-design-block'>
                                        <form>
                                            <input type="file" accept='image/*' className='input-field-banner' hidden
                                                onChange={({ target: { files } }) => {
                                                    if (files?.length > 0) {
                                                        createThump(files)
                                                        files[0] && setImgBannerName(files[0].name)
                                                        if (files) {
                                                            setBannerImageChange(URL.createObjectURL(files[0]))
                                                        }
                                                    }

                                                }}
                                            />
                                            {profileBannerImageChange ?
                                                <img src={profileBannerImageChange} alt={imgBannerName} /> :
                                                <>
                                                    {
                                                        thump ?
                                                            <img src={thump} alt={thump} /> :
                                                            <div className='upload-image-block'>
                                                                <img src={ProfileUpload} alt={ProfileUpload} />
                                                            </div>
                                                    }
                                                </>
                                            }
                                        </form>
                                    </div>

                                </div>
                                <div className='edit-view' onClick={() => document.querySelector(".input-field-banner").click()}>
                                    <Button variant="contained" endIcon={<BorderColorIcon />}>

                                    </Button>
                                </div>

                                <div className='profile-detail-part'>

                                    <div className='input-textfield-block'>
                                        <label>Name </label>
                                        <TextField id="outlined-basic" placeholder="John" variant="outlined" className='textField' />
                                    </div>
                                    <div className='input-textfield-block'>
                                        <label>Surname </label>
                                        <TextField id="outlined-basic" placeholder="Mike" variant="outlined" className='textField' />

                                    </div>
                                    <div className='input-textfield-block'>
                                        <label>Birthday </label>
                                        <TextField id="outlined-basic" placeholder="12/02/2000" variant="outlined" className='textField' />

                                    </div>
                                    <div className='input-textfield-block'>
                                        <label>Address </label>
                                        <TextField id="outlined-basic" placeholder="Johave miami, fl,usan" variant="outlined" className='textField' />

                                    </div>
                                    <div className='input-textfield-block'>
                                        <label>Email </label>
                                        <TextField id="outlined-basic" placeholder="mikejohn@gmail.com" variant="outlined" className='textField' />

                                    </div>
                                    <div className='input-textfield-block'>
                                        <label>Phone </label>
                                        <TextField id="outlined-basic" placeholder="2001144596594" variant="outlined" className='textField' />

                                    </div>


                                    <div className='save-btn'>
                                        <Button>Save</Button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default MyProfile
