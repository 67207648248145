import React, { useEffect, useState,useRef } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import logo from '../../../img/dtlogo.png'
import Button from '@mui/material/Button';
import jsPDF from 'jspdf'
import * as XLSX from "xlsx";
import 'jspdf-autotable';
import Checkbox from "@mui/material/Checkbox";
import BrandFilterPopup from "./BrandFilterPopup"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChartFlow from '../../../img/ChartFlow.png';
import BillingChart from './BillingChart';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import dayjs from 'dayjs';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import WalletPart from '../../../img/wallet-img.png'
import InvoiceStatus from '../../../img/invoice-status.png'
import { pink, blue } from '@mui/material/colors';
import Pagination from '@mui/material/Pagination';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Filter from './Filter'
import "./BrandInvoice.css"
import Axios from '../../../Axios';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PaidIcon from '@mui/icons-material/Paid';
import InvoicesPopup from "../BrandInvoice/InvoicesPopup"
import CancelInvoice from './cancelInvoice';
import PaidPop from './PaidPop';
import PrintPop from './PrintPop';
import success from '../../../img/approvedicon.png'
import failure from '../../../img/cancelicon.png'
import CryptoJS from "crypto-js";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Toaster, toast } from 'react-hot-toast'
import { Details } from '@mui/icons-material';


const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function salesEarning(date, count, earniings, tax, total, sts, rate, create, pay, surcharge, invoice, qty, bill, bank, action) {
  return { date, count, earniings, tax, total, sts, rate, create, pay, surcharge, invoice, qty, bill, bank, action };
}

const rows = [
  salesEarning('FA1111', 'LUIS BULTON S.L', 'Street gacamno 34, CP 123444 Paris', 'FRANCE', '$2499FRANCE 20%', 'InvoiceStatus', 'FRANCE 20%', '01 Oct 2022', '01 Oct 2022', 'YES 5%', 'access license', '1', '$2998.8', 'BANK',),
  salesEarning('FA1111', 'LUIS BULTON S.L', 'Street gacamno 34, CP 123444 Paris', 'FRANCE', '$2499FRANCE 20%', 'InvoiceStatus', 'FRANCE 20%', '01 Oct 2022', '01 Oct 2022', 'YES 5%', 'access license', '1', '$2998.8', 'BANK',),
  salesEarning('FA1111', 'LUIS BULTON S.L', 'Street gacamno 34, CP 123444 Paris', 'FRANCE', '$2499FRANCE 20%', 'InvoiceStatus', 'FRANCE 20%', '01 Oct 2022', '01 Oct 2022', 'YES 5%', 'access license', '1', '$2998.8', 'BANK',),
  salesEarning('FA1111', 'LUIS BULTON S.L', 'Street gacamno 34, CP 123444 Paris', 'FRANCE', '$2499FRANCE 20%', 'InvoiceStatus', 'FRANCE 20%', '01 Oct 2022', '01 Oct 2022', 'YES 5%', 'access license', '1', '$2998.8', 'BANK',),
  salesEarning('FA1111', 'LUIS BULTON S.L', 'Street gacamno 34, CP 123444 Paris', 'FRANCE', '$2499FRANCE 20%', 'InvoiceStatus', 'FRANCE 20%', '01 Oct 2022', '01 Oct 2022', 'YES 5%', 'access license', '1', '$2998.8', 'BANK',),
];

function recentEarning(country, recentearnings) {
  return { country, recentearnings };
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 30px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});

const AdminDashboardBody = () => {

  const classes = useStyles();

  const [bill, setBil] = useState([])
  const [page, setPage] = useState(1);
  const [count, setCount] = useState()
  const [selected, setSelected] = useState([])
  const [graphData,setGraphData] = useState()
  const [priceData,setpriceData] = useState([])
  const [lastpriceData,setlastpriceData] = useState([])
  const user = window.localStorage.getItem('Squelch')
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const role = window.localStorage.getItem('Squelch')
  const status1 = window.localStorage.getItem('isimo')
  const handleChange1 = (event, value) => {
    setPage(value);
  };
  const [decrptedData, setDecrptedData] = useState()
  const [statusss, setstatusss] = useState()
  const secretPass = 'DtRaCeRsEcrEtFrEe'
  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt(status1, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };

  useEffect(() => {
    if (role === 'subAdmin') {
      decryptData()
    }
  }, [status1])


  const getbillings = async () => {
    try {
      setstatusss("Loading...")

      const { data } = await Axios.post(`/admin/get_billing_details`, '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result?.length > 0) {
          data?.result?.forEach(element => {
            element.Creation_date = formatDate(element?.Creation_date);
            element.Payment_date = formatDate(element?.Payment_date)
          });
          setBil(data?.result)
          setCount(Math.ceil(data?.result?.length / 10))

          setstatusss()

        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }
  const getGraphs = async () => {
    try {
      setstatusss("Loading...")

      const { data } = await Axios.post(`/admin/billing_graph`, '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {
          setGraphData(data?.result)
          var graphDetails = []
          var priceDetail = []
          var lastpriceDetail = []
for(var i=0;i<data?.result?.month.length;i++){
  graphDetails.push(data?.result?.month[i])
  if(data?.result?.price[i] === undefined || data?.result?.price[i] === null || data?.result?.price[i] === "null"){
    priceDetail.push(0)
  }
  else{
    priceDetail.push(data?.result?.price[i])
  }
  if(data?.result?.lastprice[i] === undefined || data?.result?.lastprice[i] === null || data?.result?.lastprice[i] === "null"){
    lastpriceDetail.push(0)
  }
  else{
    lastpriceDetail.push(data?.result?.lastprice[i])
  }
}
setpriceData(priceDetail)
setlastpriceData(lastpriceDetail)

console.log(lastpriceDetail,priceDetail)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }
  const filterPro = (data) => {
    setstatusss("Loading...")

    console.log("🚀 ~ file: AdminProducts.js:555 ~ filterPro ~ data:", data)
    if (data.length > 0) {
      setstatusss()
      setCount(Math.ceil(data?.length / 10))

      setBil(data)
      // setCount(Math.ceil(data?.length / 10))
    } else {
      setBil(data)
      setstatusss("No product available for this filter.....")
    }

  }
  const reset = (data) => {

    setstatusss("Loading...")
    getbillings()
    getGraphs()
  }
  useEffect(() => {
    getProductsa()
  }, [page])

  const getProductsa = async () => {
    if (bill.length > 0) {
      var currentpage = page - 1
      var dt = []
      if (currentpage === 0) {
        for (let i = 0; i < 10; i++) {
          const element = bill[i];
          if (element) {
            dt.push(element)
          }
        }
        setBil(dt)
      } else {
        var first = (currentpage * 10);
        var second = first + 10;
        for (let i = first; i <= second; i++) {
          const element = bill[i];
          if (element) {
            dt.push(element)
          }
        }
        setBil(dt)
      }
    }

  }

  useEffect(() => {
    getbillings()
    getGraphs()
  }, [])

  const status = () => {
    getbillings()
    getGraphs()
  }
  const generatePdf = (datas) => {
    console.log("Pdf", datas)
    const dates = new Date(datas.Creation_date).toLocaleDateString()
    const sdates = new Date(datas.Payment_date).toLocaleDateString()
    const subtotalPrice = Number(datas.price) - (Number(datas.discount / 100 * datas.price))

    const head = [['Concept', 'Quantity', 'Price', 'Subtotal', 'Discount', 'Tax', 'Total']]
    const data = [
      [datas.Invoice_concept, datas.Quantity, datas.price, datas?.Total_without_tax, `${datas.discount}%`, datas.Tax_rate, datas.Total_bil,],

    ]
    const paydata = [
      ['payment Menthod', datas.Total_bil],
      ['payment Menthod', datas.Total_bil],
    ]
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    doc.setFontSize(10);

    doc.addImage(logo, "PNG", 10, 15, 70, 30);

    doc.setFont('Arial', 'bold');
    doc.setFontSize(13);
    doc.text("Payment To", 200, 41, "right");
    doc.setFont('Arial', 'bold');
    doc.setFontSize(25);
    doc.text("Dtracer", 200, 52, "right");
    // doc.setFontSize(13);
    // doc.setFont('Arial', 'normal');
    // doc.text("dtracer.io", 200, 57, "right");
    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text("1210 Washington Avenue,", 200, 57, "right");
    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text("Miami Beach,Florida,", 200, 62, "right");
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("United States", 200, 67, "right");

    // doc.setFont('Arial', 'bold');
    // doc.setFontSize(15);
    // doc.text("CLIENT", 15, 95, "left");

    doc.setFont('Arial', 'bold');
    doc.setFontSize(15);
    doc.text("CLIENT", 15, 95, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Brand_Name}`, 15, 100, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Invoice_Id}`, 15, 106, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.email}`, 15, 112, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Address}`, 15, 118, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Country}`, 15, 124, "left");

    // doc.setFontSize(13);
    // doc.setFont('Arial', 'bold');
    // doc.text(`${datas?.Country}`, 15, 67, "right");

    doc.setFont('Arial', 'bold');
    doc.setFontSize(15);
    doc.text(`${datas?.Invoice_Id}`, 200, 95, "right");

    doc.setFont('Arial', 'normal');
    doc.setFontSize(15);
    doc.text(`Date:${dates}`, 200, 103, "right");

    doc.setFont('Arial', 'normal');
    doc.setFontSize(15);
    doc.text(`Expire Date:${sdates}`, 200, 110, "right");


    doc.setFontSize(15);

    var finalY = doc.lastAutoTable.finalY || 120
    var finalX = doc.lastAutoTable.finalX || 20
    doc.autoTable({
      startX: 10,
      startY: finalY + 20,
      head: head,
      body: data,

      styles: {
        cellPadding: 5,
        fontSize: 12, fontStyle: 'bold', font: 'Arial', color: [0, 0, 0], lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      bodyStyles: {
        minCellHeight: 12,
        valign: 'middle',
        cellPadding: 5,
        fillColor: [207, 225, 255],
        textColor: [0, 0, 0, 222 / 255],
      },
      headStyles: {
        minCellHeight: 10,
        cellPadding: 5,
        borderRadius: 20,
        fillColor: [0, 112, 240],
        valign: 'middle',
        textColor: [255, 255, 255],
      },
    })

    var finalY = doc.lastAutoTable.finalY || 160

    function roundedRect(x, y, width, height, radius, shadowSize) {
      doc.setLineWidth(0.1);
      doc.setDrawColor(0);
      doc.setFillColor(255);
      doc.roundedRect(x + shadowSize, y + shadowSize, width, height, radius, shadowSize, 'FD');
    }
    var shadowSize = 5;


    // Draw a rounded rectangle with a border radius of 10
    roundedRect(130, finalY + 10, 60, 60, 5, shadowSize);
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("Subtotal", 140, finalY + 30, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("Tax", 140, finalY + 45, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("Total", 140, finalY + 60, "left");

    doc.setDrawColor(204, 204, 204);
    doc.line(140, finalY + 36, 190, finalY + 36);
    doc.line(140, finalY + 51, 190, finalY + 51);

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Total_without_tax ? datas?.Total_without_tax : 0}`, 190, finalY + 30, "right");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Tax_rate ? datas?.Tax_rate : 0}`, 190, finalY + 45, "right");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Total_bil ? datas?.Total_bil : 0}`, 190, finalY + 60, "right");

    doc.save(`${datas?.Brand_Name}_invoice.pdf`)
  }
  const downloadExcelsheet = () => {
    console.log(bill, "Bills")
    let tableData = []

    for (var i = 0; i < bill.length; i++) {
      let reasons;
      var info = {
        Name: bill[i].Brand_Name,
        Email: bill[i].email,
        Address: bill[i].Address,
        Country: bill[i].Country,
        Invoice_Id: bill[i].Invoice_Id,
        Invoice_concept: bill[i].Invoice_concept,
        Invoice_status: bill[i].Invoice_status,
        Payment_date: bill[i].Payment_date,
        Payment_method: bill[i].Payment_method,
        Quantity: bill[i].Quantity,
        Tax_rate: bill[i].Tax_rate,
        Total_bil: bill[i].Total_bil,
        Total_without_tax: bill[i]?.price - (bill[i]?.Tax_rate / 100 * bill[i]?.price) ? bill[i]?.price - (bill[i]?.Tax_rate / 100 * bill[i]?.price) : ('-'),
        Discount: bill[i].discount,
        Price: bill[i].price,
        Payment_status: bill[i].payment_status,
        Proof: bill[i].proof,
      }
      tableData.push(info)
    }
    console.log(tableData, "Data")

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Billing.csv');

  };

  // const newDate = new Date()
  const [creationDate, setCreationDate] = useState([])
  const [paymentDate, setPaymentDate] = useState([])

  const [result, setResult] = useState([])
  const selectedDetails = []
  const checkboxRef = useRef(null);
  const handlecreationdatechange = (d, m, y) => {
    // console.log(d,m,y,'DATEEEEEEEE');
    var credate = y + '-' + m + '-' + d
    // console.log(credate,'credate');
    setCreationDate(credate)
    if (paymentDate.length !== 0) {
      billdata(credate, paymentDate)
    }


  }

  const handlepaymentdatechange = (d, m, y) => {
    //  console.log(d,m,y,'pay DATEEEEEEEE');
    var payDate = y + '-' + m + '-' + d

    setPaymentDate(payDate)
    if (creationDate.length !== 0) {
      billdata(creationDate, payDate)

    }
    // billdata(creationDate,payDate)
  }

  const billdata = async (creationDates, paymentDates) => {
    try {
      setstatusss("Loading...")
      console.log(creationDates, paymentDates)
      const data = await Axios.post(`/sortbillbydate`, {
        creation_date: creationDates,
        payment_date: paymentDates
      }, {
        // headers:{
        //     Authorization: window.localStorage.getItem('AdminToken')
        // }
      })
        // .then( response => console.log(response,'response'))
        .then(response => {
          console.log(response, 'response');
          if (response?.data.success == true) {
            toast.success(response?.data?.message)
          } else {
            toast.error(response?.data?.message)
          }
          // console.log(response.data.result,'response');
          // console.log(newDate,'NEWDATE TODEYS DATE');
          setBil(response.data.result)
          setstatusss()
          // console.log(result,'result');
        }).catch(error => toast.error(error.response?.data?.message))

    } catch (error) {
      toast.error(error.response?.data?.message)
      // console.log(error,'error');
    }
  }

  const selectedInvoice = (row) => {
  
 console.log(selected,"details")
 if (selected.includes(row)) {
  // If selected, remove it
  setSelected(selected.filter((selectedItem) => selectedItem._id !== row._id));
} else {
  // If not selected, add it
  setSelected([...selected, row]);
}

 
  }

  const exportdetails = () => {
    console.log(selected,"Export")
    // console.log(bill, "Bills")
    let tableData = []
if(selected.length > 0){
  for (var i = 0; i < selected.length; i++) {
    let reasons;
    var info = {
      Name: selected[i].Brand_Name,
      Email: selected[i].email,
      Address: selected[i].Address,
      Country: selected[i].Country,
      Invoice_Id: selected[i].Invoice_Id,
      Invoice_concept: selected[i].Invoice_concept,
      Invoice_status: selected[i].Invoice_status,
      Payment_date: selected[i].Payment_date,
      Payment_method: selected[i].Payment_method,
      Quantity: selected[i].Quantity,
      Tax_rate: selected[i].Tax_rate,
      Total_bil: selected[i].Total_bil,
      Total_without_tax:selected[i]?.price - (selected[i]?.Tax_rate/100 * selected[i]?.price) ? selected[i]?.price - (selected[i]?.Tax_rate/100 * selected[i]?.price) : ('-'),
      Discount: selected[i].discount,
      Price: selected[i].price,
      Payment_status: selected[i].payment_status,
      Proof: selected[i].proof,
    }
    tableData.push(info)
  }
  console.log(tableData, "Data")

  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate a Blob from the workbook
  XLSX.writeFile(workbook, 'Billing.csv');
}
else{
  toast.error("Please Select Data")
}
    
  }


  if ((role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[5]?.Read === true)) || (role === 'brand')) {
    return (
      <div className='dashboard-body'>
        <div className='billing-top-btn brand-bill' style={{"marginBottom":"30px"}}>
          <div className='accnt-btn'>
            <div>
              <BrandFilterPopup filterPro={filterPro} reset={reset} />
              {/* <Button className="filter-btn">Filter</Button> */}
            </div>
            {/* <div>
              <Filter />
            </div> */}
            {/* <div>
              <Button className="filter-btn">See Accounting</Button>
            </div>
            <div>
              <Button className="filter-btn">Blockchain Account</Button>
            </div> */}
          </div>
          <div className='start-end-date start-end-date-admin'>
            <div className='date-display'>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      
                      views={['year', 'month', 'day']}
                      onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y) }}      //console.log(e.$D,e.$M,e.$y)
                    // value={creationDate}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >

                  <DemoItem>
                    <DatePicker
                      
                      views={['year', 'month', 'day']}
                      onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }} //;billdata();
                    // value={paymentDate}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider> */}
            </div>

          </div>
        </div>



        <Grid container spacing={0} className={classes.containwidth}>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ margin: '0 auto' }}>
            <Item className={classes.dashboargraph} id="dashboargraph">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>
                 
                </div>

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <div className='years-part'>
                      <h5>Billing</h5>
                      <div>
                        <p><FiberManualRecordIcon color='primary' />Previous Year</p>
                      </div>
                      <div>
                        <p><FiberManualRecordIcon  style={{ color: '#00e396' }} />Current Year</p>
                      </div>
                    </div>


                    <p>Billing performance for Previous Year and Current Year </p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>

                <div className='dashboargraph-inner-bottom'>
                
                    <BillingChart lastpriceData={lastpriceData} priceData={ priceData } /> 
                  
                  
                </div>
                {/* <div className='dashboargraph-inner-top'>
                  <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button>
                </div> */}

              </div>


            </Item>
          </Grid>
        
        </Grid>

        <Box sx={{ flexGrow: 1 }}>


          <Grid container spacing={0} className={classes.containwidth}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <div className='dashboargraph-inner-top'>
                <Button variant="contained" onClick={() => downloadExcelsheet()} endIcon={<ExitToAppIcon />}>
                  Export
                </Button>
              </div> */}
              <Item className={classes.dashboargraph} id="earnings-sales">
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      <TableCell align="center" className='head-topic'></TableCell>
                        <TableCell align="center" className='head-topic'>Invoice ID</TableCell>
                        <TableCell align="center" className='head-topic'>Brand Name</TableCell>
                        <TableCell align="center" className='head-topic'>Address</TableCell>
                        <TableCell align="center" className='head-topic'>Country</TableCell>
                        <TableCell align="center" className='head-topic'>Total without taxes</TableCell>
                        <TableCell align="center" className='head-topic'>Invoice status</TableCell>
                        <TableCell align="center" className='head-topic'>TAX RATE</TableCell>
                        <TableCell align="center" className='head-topic'>Creation date</TableCell>
                        <TableCell align="center" className='head-topic'>Payment date</TableCell>
                        <TableCell align="center" className='head-topic'>Equivalence surcharge</TableCell>
                        <TableCell align="center" className='head-topic'>Invoice concept</TableCell>
                        <TableCell align="center" className='head-topic'>QTY</TableCell>
                        <TableCell align="center" className='head-topic'>Total bill</TableCell>
                        <TableCell align="center" className='head-topic'>Payment method</TableCell>
                        <TableCell align="center" className='head-topic'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bill.length > 0 ? bill?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {/* {console.log(row,'row')} */}
                          <TableCell><Checkbox  className="checkBox" onChange={()=>selectedInvoice(row)}  inputRef={checkboxRef}/></TableCell>
                          <TableCell className='row-topic'>{row?.Invoice_Id}</TableCell>
                          <TableCell className='row-topic'>{row.Brand_Name}</TableCell>
                          <TableCell className='row-topic'>${row.Address}</TableCell>
                          <TableCell className='row-topic'>{row.Country}</TableCell>
                          <TableCell className='row-topic'>{row?.price - (row?.discount / 100 * row?.price) ? row?.price - (row?.discount / 100 * row?.price) : ('-')}</TableCell>
                          <TableCell className='row-topic'>{row?.payment_status === '0' ? <img src={InvoiceStatus} alt={InvoiceStatus} /> : <>{row?.payment_status === '1' ? <img src={success} alt={success} /> : <img src={failure} alt={failure} />}</>}  </TableCell>
                          <TableCell className='row-topic'>{row.Tax_rate}</TableCell>
                          <TableCell className='row-topic'>{new Date(row.Creation_date).toLocaleDateString()}</TableCell>
                          <TableCell className='row-topic'>{new Date(row.Payment_date).toLocaleDateString()}</TableCell>
                          <TableCell className='row-topic'>{row.surcharge}</TableCell>
                          <TableCell className='row-topic'>{row.Invoice_concept}</TableCell>
                          <TableCell className='row-topic'>{row.Quantity}</TableCell>
                          <TableCell className='row-topic'>{row.Total_bil}</TableCell>
                          <TableCell className='row-topic'>{row.Payment_method}</TableCell>
                          <TableCell className='row-topic'>
                            <div className='icon-space'>
                              <Button onClick={() => { generatePdf(row) }}>{<SaveAltIcon color="primary" />}
                              </Button>
                              <Button><InvoicesPopup row={row} status={status} role={role} decrptedData={decrptedData} />
                              </Button>
                              {
                                (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[5]?.Write === true)) ?
                                  <>
                                    <Button> <CancelInvoice row={row} status={status} />   </Button>
                                    <Button><PaidPop row={row} status={status} />
                                    </Button>
                                  </>
                                  :
                                  <>
                                  </>
                              }

                            </div>
                          </TableCell>
                        </TableRow>
                      )) :
                        <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statusss}</h3></TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <div className='pagination-numb'>
                  <Pagination count={9} color="primary" shape="rounded" /> 
                   <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" />

                    </div>*/}
              </Item>
            </Grid>

          </Grid>


          <Grid container spacing={0} className={classes.containwidth}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='pagination-secyion'>
                <div className='download-keys'>
                  <div className='save-icon' onClick={()=>exportdetails()}>{<SaveAltIcon sx={{ color: blue[500] }} />}</div>
                  <PrintPop bill={selected}/>
                  {/* <div className='print-icon'>{<LocalPrintshopOutlinedIcon />}</div> */}
                  {/* <div className='print-icon'>{<DoDisturbAltOutlinedIcon />}</div> */}
                </div>

                <div className='pagination-box'>
                  <div className='pagination-numb'>
                    {/* <Pagination count={9} color="primary" shape="rounded" /> */}
                  </div>
                </div>
                <div className='dashboargraph-inner-top'>
                  <Button variant="contained" onClick={() => downloadExcelsheet()} endIcon={<ExitToAppIcon />}>
                    Export
                  </Button>
                </div>
              </div>
            </Grid>

          </Grid>





          {/* <div id="five-block-divs-two-rows-top">

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>QR Active</label>
              </div>
              <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content span-bg'>
                <label>123,987</label>
                <span>+14.67%</span>
              </div>

            </div>


            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Connected Bands</label>
              </div>
              <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content span-bg'>
                <label>68.23</label>
                <span>+14.67%</span>
              </div>

            </div>

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Active Customers</label>
              </div>
              <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content span-bg'>
                <label>1800</label>
                <span>+14.67%</span>
              </div>

            </div>

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Avg. time on Admin Brand</label>
              </div>
              <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content span-bg'>
                <label>2ms : 35s </label>
                <span>+14.67%</span>
              </div>

            </div>

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Avg. time on Customer Brand</label>
              </div>
              <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content span-bg'>
                <label>2ms : 35s</label>
                <span>+14.67%</span>
              </div>

            </div>

          </div> */}

          <Box sx={{ flexGrow: 1 }} id="five-block-divs-two-rows">


            {/* <Grid container spacing={0} className={classes.containwidth} id="five-block-divs">

              <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                <Item className={classes.dashboargraph} id="">

                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-1">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Start Date</label>
                      <span>Sept 01, 2018</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-2">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>End Date</label>
                      <span>Sept 01, 2018</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-3">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Sales Measure</label>
                      <span>Revenue</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-4">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Customer Type</label>
                      <span>All Customers</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-5">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Transaction Type</label>
                      <span>All Transaction</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                <Item className={classes.dashboargraph} id="">

                </Item>
              </Grid>


            </Grid> */}



            {/* <Grid container spacing={0} className={classes.containwidth}>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-1">
                  <div className='total-pdts quantity-bg'>
                    <p>Total Quantity</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>111,000</span><label>2.00% <label>(30 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-2">
                  <div className='total-pdts quantity-bg'>
                    <p>Total Cost</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>$11,000</span><label>2.00% <label>(30 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-3">
                  <div className='total-pdts quantity-bg'>
                    <p>Total  Revenue</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>$11,000</span><label>2.00% <label>(30 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-4">
                  <div className='total-pdts quantity-bg'>
                    <p>Total Profit</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>$121,000</span><label>2.00% <label>(30 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

            </Grid> */}

          </Box>

        </Box>
      </div>
    )
  } else {
    return (
      <div>You dont have Permission to View Billing</div>
    )
  }

}

export default AdminDashboardBody
