import React, { useState, useRef } from "react";
import "./AdminSellersAuth.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import toast from 'react-hot-toast';
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import send from '../../../img/send-msg.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SvgMap from "./SvgMap";
import Axios from "../../../Axios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete"

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const EditSeller = ({ getData, row }) => {
  console.log("🚀 ~ file: EditSeller.js:63 ~ EditSeller ~ row:", row)
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [createopen, createsetOpen] = React.useState(false);
  const createhandleOpen = () => createsetOpen(true);
  const createhandleClose = () => createsetOpen(false);
  const email = useRef(null);
  const firstname = useRef(null);
  const sellerid = useRef(null);
  const phone = useRef(null);
  const contactphone = useRef(null);
  const contactmail = useRef(null);
  const contactname = useRef(null);
  const staffno = useRef(null);

  const address = useRef(null);
  const city = useRef(null);
  const state = useRef(null);
  const country = useRef(null);
  const postalcode = useRef(null);

  const inputconfrimRef = useRef(null);
  const selectedValueRef = useRef('user');
  const [emailerr, setemailerr] = useState(null)
  const [usernameerr, setusernameerr] = useState(null)
  const [selleriderr, setselleriderr] = useState(null)
  const [phoneerr, setphoneerr] = useState(null)
  const [iderr, setiderr] = useState(null)

  const [contactphoneerr, setcontactphoneerr] = useState(null)
  const [contactemailerr, setcontactemailerr] = useState(null)
  const [contactnameerr, setcontactnameerr] = useState(null)

  const [staffnoerr, setstaffnoerr] = useState(null)

  const [addresserr, setaddresserr] = useState(null)
  const [cityerr, setcityerr] = useState(null)
  const [stateerr, setstateerr] = useState(null)
  const [countryerr, setcountryerr] = useState(null)
  const [postalcodeerr, setpostalcodeerr] = useState(null)
  const [user, setUser] = useState({
    _id: row?._id,
    firstname: row?.firstname,
    email: row?.email,
    phone: row?.phone,
    contact_mail: row?.contact_mail,
    contact_phone: row?.contact_phone,
    contact_name: row?.contact_name,
    address: row?.address,
    city: row?.city,
    state: row?.state,
    country: row?.country,
    postal_code: row?.postalcode,
  })

  const handleChange = (e) => {
    setemailerr(null)
    setusernameerr(null)
    setselleriderr(null)
    setphoneerr(null)
    setiderr(null)
    setcontactphoneerr(null)
    setcontactemailerr(null)
    setcontactnameerr(null)
    setstaffnoerr(null)
    setaddresserr(null)
    setcityerr(null)
    setstateerr(null)
    setcountryerr(null)
    setpostalcodeerr(null)
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }


  function createData(
    dueDate,
    service

  ) {
    return { dueDate, service };
  }


  const [storeopen, storesetOpen] = React.useState(false);
  const storehandleOpen = () => storesetOpen(true);
  const storehandleClose = () => storesetOpen(false);



  function createData(
    dueDate,
    service,
    password

  ) {
    return { dueDate, service, password };
  }


  const [deltaopen, deltasetOpen] = React.useState(false);
  const deltahandleOpen = () => deltasetOpen(true);
  const deltahandleClose = () => deltasetOpen(false);


  const [mapopen, mapsetOpen] = React.useState(false);
  const maphandleOpen = () => mapsetOpen(true);
  const maphandleClose = () => mapsetOpen(false);
  const [address1, setAddress1] = useState(row?.address);
  const [valiaddre, setValiaddre] = useState(null)





  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleSuccessEdit = async () => {
    try {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let reg = /^[0-9]+$/;
      if (user.firstname === "") {
        setusernameerr("Please Enter Name")
      }
      else if (user.postal_code === "") {
        setpostalcodeerr("Please Enter Postal Code")
      }
      else if (user.email === "") {
        setemailerr("Please Enter Email")
      } else if (!(re.test(user.email))) {
        setemailerr("Please Enter valid Email")
      } else if (user.phone === "") {
        setphoneerr("Please Enter Phone Number")
      }
      // else if (user.contact_name === "") {
      //   setcontactnameerr("Please Enter Contact Name")
      // }
      else if (user.contact_phone === "") {
        setcontactphoneerr("Please Enter Contact Phone Number")
      } else if (!(reg.test(user.contact_phone))) {
        setcontactphoneerr("Please Valid number")
      }
      else if (user.contact_mail === "") {
        setcontactemailerr("Please Enter Email")
      } else if (!(re.test(user.contact_mail))) {
        setcontactemailerr("Please Enter valid Email")
      }

      else if (!(reg.test(user.postal_code))) {
        setpostalcodeerr("Please Valid number")
      }
      else if (address1 === "") {
        setaddresserr("Please Enter Address")
      }
      // else if (user.city === "") {
      //   setcityerr("Please Enter City")
      // }
      // else if (user.state === "") {
      //   setstateerr("Please Enter State")
      // }
      // else if (user.country === "") {
      //   setcountryerr("Please Enter Country")
      // }

      else {
        const { data } = await Axios.post('/admin/edit_seller', user,
          {
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
            },
          }
        )

        if (data.success) {
          setValiaddre("")
          // navigate(`${consts.route}/register-success`)
          toast.success('Details Updated Successfully')
          getData()
          setTimeout(() => {
            setOpen(false)
          }, 2000);
        }
        else {
          console.log("Eroordata", data.message)
          toast.error(data.message)
        }
      }
    } catch (error) {
      console.log(error?.response?.data?.message, "err")
      if (error?.response?.data?.message === "Email Already Exists") {
        setemailerr(error?.response?.data?.message)
      } else {
        toast.error("Cant Add Seller")
      }
    }
  }
  const nft = async (e) => {
    try {
      const { name, value } = e.target;
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
      setpostalcodeerr(null)
      const add = await Axios.post('/validateaddress', {
        address: address1

      })
      if (add?.data?.success === true) {
        setValiaddre("Address Verified")
        let fromadd = add?.data?.result?.address.split(",")
        let postad = fromadd[2]
        let postadd = postad.split(" ")
        // if (fromadd[1] != undefined) {
        //   city.current.value = fromadd[1]
        // }
        // if (fromadd[3] != undefined) {
        //   country.current.value = fromadd[3]
        // }
        // if (postadd[1] != undefined) {
        //   state.current.value = postadd[1]
        // }
        // if (postadd[2] != undefined) {
        //   postalcode.current.value = postadd[2]
        // }
      } else {
        console.log("failed");
        setValiaddre("Address Not Verified")
      }


    } catch (error) {
      console.log(error, "error");
    }
  }
  const handleChangeAddress = (newAddress) => {
    setAddress1(newAddress);
  };
  const handleSelectAddress = (newAddress) => {
    setUser((prevUser) => ({
      ...prevUser,
      ["postal_code"]: "",
    }));
    // let ad = newAddress.split(",")
    // console.log(ad.length, "address-vijay");
    // const cou = ad.length - 1
    // console.log();
    // console.log(cou, "vjlenghth");
    // console.log(ad[cou], "country");
    setAddress1(newAddress);
    // city.current.value = ad[1]
    // state.current.value = ad[2]
    // country.current.value = ad[cou]
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  return (
    <div className='add-seller'>

      <Button onClick={handleOpen}> <EditIcon /> </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX add-seller">

          <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

          <div className="headTitle">Edit Seller</div>

          <div className="listOne">
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Seller Name
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' value={user?.firstname} name="firstname" onChange={(e) => { handleChange(e) }} />
                {usernameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{usernameerr}</div> : <></>}
              </div>
            </div>

            <div className="productId">


              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Email
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' value={user?.email} name="email" onChange={(e) => { handleChange(e) }} />
                {emailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{emailerr}</div> : <></>}
              </div>
            </div>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Phone
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' value={user?.phone} name="phone" onChange={(e) => { handleChange(e) }} />
                {phoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{phoneerr}</div> : <></>}
              </div>
            </div>
          </div>

          <div className="listOne">
            {/* <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Contact Person
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' value={user?.contact_name} name="contact_name" onChange={(e) => { handleChange(e) }} />
                {contactnameerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactnameerr}</div> : <></>}
              </div>
            </div> */}
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Contact Person's Email
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' value={user?.contact_mail} name="contact_mail" onChange={(e) => { handleChange(e) }} />
                {contactemailerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactemailerr}</div> : <></>}
              </div>
            </div>
            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Contact Person's Phone
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' name="contact_phone" value={user?.contact_phone} onChange={(e) => { handleChange(e) }} />
                {contactphoneerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{contactphoneerr}</div> : <></>}
              </div>
            </div>

            <div className="productId">

              <div className="cmn-input-cls">
                <span>Address</span>
                <PlacesAutocomplete
                  value={address1}
                  onChange={handleChangeAddress}
                  onSelect={handleSelectAddress}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input
                        {...getInputProps({
                          // placeholder: "Search Places ...",
                          className: "location-search-input"
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={suggestion.placeId}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

              </div>
            </div>

            {/* <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Address
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' name="address" value={user?.address} onChange={(e) => { handleChange(e) }} />
                {addresserr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{addresserr}</div> : <></>}
              </div>
            </div> */}

          </div>



          <div className="listOne">



            {/* <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  City
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' name="city" value={user?.city} onChange={(e) => { handleChange(e) }} />
                {cityerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{cityerr}</div> : <></>}
              </div>
            </div>

            <div className="productId">

              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  State
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' name="state" value={user?.state} onChange={(e) => { handleChange(e) }} />
                {stateerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{stateerr}</div> : <></>}
              </div>
            </div> */}
            <div className="productId">


              {/* <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Country
                </Typography>
                <TextField id="outlined-basic" variant="outlined" className='textField' value={user?.country} name="country" onChange={(e) => { handleChange(e) }} />
                {countryerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{countryerr}</div> : <></>}
              </div> */}
            </div>


          </div>
          <div className="listOne">

            <div className="productId">
              <div className="cmn-input-cls">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Postal Code
                </Typography>

                <TextField id="outlined-basic" variant="outlined" className='textField' name="postal_code" value={user?.postal_code} onChange={(e) => { nft(e) }} />

                {postalcodeerr ? <div className='h3' style={{ color: 'red', textAlign: 'center' }}>{postalcodeerr}</div> : <></>}
              </div>
            </div>
          </div>
          <div className="error-msg">
            <>{valiaddre === "Address Verified" ? <></> : <p>{valiaddre}</p>}</>
          </div>

          <div className="sucess-msg">
            <>{valiaddre === "Address Not Verified" ? <></> : <p>{valiaddre}</p>}</>
          </div>
          <div className="filter-box account">
            <Button onClick={() => { handleSuccessEdit() }}>Edit Seller</Button>
          </div>



          {/* {<Modal
              open={deltaopen}
              onClose={deltahandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>

                <div className="message">
                    <img src={send} alt="closeMenu" />
                    <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                    <h6>Make sure before sending the email, since these codes are private for each seller.If you are not sure, please enter a secure email:Make sure before sending the email, since these codes are private for each seller.If you are not sure, please enter a secure email:</h6>
                </div>

                <div className="listOne">

                    <div className="productId">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                    >
                    New Email
                    </Typography>

                    <div className="cmn-input-cls">
                        <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        />
                    </div>
                    </div>

                </div>


              <div className="filter-box account add">
                  <Button>send by email,the passwords to the seller</Button>
                  <Button className='cancel' onClick={createhandleOpen} >Generate all password</Button>
              </div>

              </Box>
            </Modal>} */}


          {/* {<Modal
              open={createopen}
              onClose={createhandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>
                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                    Seller ID
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Bharath"
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                    seller name
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="kumar"
                        className="textField"
                      />
                    </div>
                  </div>

                </div>


                <div className="listOne">

                    <div className="productId">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                    >
                    Email
                    </Typography>

                    <div className="cmn-input-cls">
                        <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        />
                    </div>
                    </div>

                    <div className="productId">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                    >
                    Staff Number
                    </Typography>

                    <div className="cmn-input-cls">
                        <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        />
                    </div>
                    </div>

                    </div>



                <div className="listTwo">
                    <div className="leftCheck">
                        <div className="productId">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo"
                        >
                            Status
                        </Typography>
                        <div className="option"><Checkbox /> Active</div>
                        <div className="option"><Checkbox /> Disabled</div>
                    

                        </div>

                    </div>

                    <div className="start-end-date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className="modelTypo create"
                            >
                            Creation Date
                            </Typography>
                            <DemoContainer
                            components={[
                                "DatePicker",
                                "DateTimePicker",
                                "TimePicker",
                                "DateRangePicker",
                            ]}
                            >
                            <DemoItem>
                                <DatePicker
                                defaultValue={yesterday}
                                disablePast
                                views={["year", "month", "day"]}
                                />
                            </DemoItem>

                            <DemoItem>
                                <DatePicker
                                defaultValue={yesterday}
                                disablePast
                                views={["year", "month", "day"]}
                                />
                            </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </div>


              <div className="filter-box account add">
                  <Button onClick={createhandleClose} className='cancel'>Cancel</Button>
                  <Button onClick={maphandleOpen}>Generate user</Button>
              </div>

              </Box>
            </Modal>}

            {<Modal
              open={mapopen}
              onClose={maphandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={maphandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                <div><SvgMap/></div>


              </Box>
            </Modal>}

            {<Modal
              open={storeopen}
              onClose={storehandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>

                <div className="listOne">

                  <div className='SellerPaidTable'>
                      <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>
                              <StyledTableCell className="head-part-table">staff number </StyledTableCell>
                              <StyledTableCell className="head-part-table">id user</StyledTableCell>
                              <StyledTableCell className="head-part-table">password</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                          {storerows.map((storerow) => (
                            <StyledTableRow key={storerow.name}>
                              <StyledTableCell className="table-value">{storerow.dueDate}</StyledTableCell>
                              <StyledTableCell  className="table-value">{storerow.service}</StyledTableCell>
                              <StyledTableCell  className="table-value">{storerow.password}<RefreshIcon/></StyledTableCell>
                            </StyledTableRow>

                            
                          ))}
                        </TableBody>
                    </Table>
                    </div>
                </div>


              <div className="filter-box account add">
                  <Button onClick={deltahandleOpen}>send by email,the passwords to the seller</Button>
                  <Button className='cancel'>Generate all password</Button>
              </div>

              </Box>
            </Modal>} */}




        </Box>
      </Modal>

    </div>
  );
};

export default EditSeller;
