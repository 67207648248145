import React, {useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import './QrProduct.css'
import MainBag from '../../../img/main-bag.png'
import QrBag from '../../../img/qr-bag-img.png'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

  const useStyles = makeStyles({

    modalpara:{
        fontSize: '18px',
        textAlign:'center',
        maxWidth:'750px !important',
        margin:'0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)' : {
            paddingBottom: '36px',
          },

      },

      reasonBlock:{
        fontSize:'24px',
        fontWeight:'600',
        margin:'0'
    }

  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductInformation = () => {
  const classes = useStyles();

  const [openProductInfoAdmin, setOpenProductInfoAdmin] = useState(false);
  const handleOpenProductInfoAdmin = () => setOpenProductInfoAdmin(true);
  const handleCloseProductInfoAdmin = () => setOpenProductInfoAdmin(false);

  return (
    <div >
     
           <div className="qr-detail">
      <Button onClick={handleOpenProductInfoAdmin}><InfoOutlinedIcon /></Button>
      <Modal 
        open={openProductInfoAdmin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup infopopup">
        <div className="menu" onClick={handleCloseProductInfoAdmin} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            
         <div className="product-qr-info">
            <div className="product-info-head">
                <p className="product-code-head">Product info</p>
                <p className="pro-id">PRODUCT ID ADD-11123-99</p>
            </div>

            <div className="info-product-scan">
             <img src={MainBag} alt={MainBag} />
            </div>
            <p className="lv-bag">Louis vuitton handbag</p>
            <div className="productinfo-img-bag">
               
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            </div>

            <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                 <div className="special-bag">
                    <p>The special handbag</p>
                    <p className="lv-made">Louis vuitton handbag made with cowhide</p>
                    <p className="cristin-para">Cristin is a leather shoulder bag for women, with a simple and versatile design. You can carry it as a handbag by picking it up or hang it as a shoulder bag with its extendable strap.It is made of cowhide leather and has an ornamental pompom.Its interior made of cotton fabric and has a pocket to store your other objects.It is a small and light bag. Its actual measurements are 20 cm long, 14 cm high and 7 cm wide.This shoulder bag is ideal for everyday life, it can accompany you to work, to go out with friends or on a trip.</p>
                 </div>
                 <div className="ean-info">
                    <div className="ean-cust">
                        <p className="product-info-title">EAN</p>
                        <p>842888218828</p>
                    </div>
                    <div className="ean-cust">
                        <p className="product-info-title">Ref customer</p>
                        <p>D1113s</p>
                    </div>
                    <div className="ean-cust">
                        <p className="product-info-title">Brand:</p>
                        <p>Addidas</p>
                    </div>
                    <div className="ean-cust">
                        <p className="product-info-title">Initial stock</p>
                        <p>10</p>
                    </div>
                 </div>

                 <div className="ean-info">
                    <div className="ean-cust">
                        <p className="product-info-title">measures</p>
                        <p>30</p>
                    </div>
                    <div className="ean-cust">
                        <p className="product-info-title">Material</p>
                        <p>pel de vaca</p>
                    </div>
                    <div className="ean-cust">
                        <p className="product-info-title">unit of measurement</p>
                        <p>CM</p>
                    </div>
                    
                 </div>

                 <div className="ean-info">
                    <div className="ean-cust">
                        <p className="product-info-title">warranty</p>
                        <p>2 years</p>
                    </div>
                    <div className="ean-cust">
                        <p className="product-info-title">License attachments</p>
                        <p>Download license</p>
                    </div>
                 </div>

                <div className="location-info">
                    <p className="product-info-title">Location</p>
                    <p>Passeig de gracia 88, Barcelona, CP 08008 click to see on the map</p>
                </div>

 




            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <div className="category-sctn info-category">
                <div className="category-info">
                    {/* <p className="product-client-id">Client ID <span> XXX11122S</span></p> */}
                </div>
                <div className="category-info">
                    <p className="product-client-id">ID PRODUCT<span> XXX 1002 - 001</span></p>
                </div>

                <div className="category-info">
                    <p className="product-client-id">Category <span> FashionStatus product</span></p>
                </div>

                <div className="pend-approved-btn">
                    <Button className="pending-appr-button">PENDING APROVAL</Button>
                </div>

                <div className="category-info">
                    <p className="product-client-id">Blockchain info NO AVAILABLE</p>
                </div>

          <div className="buttons-approve">
                 <div>
                    <Button className="approve-tick">Approval</Button>
                </div>
                <div>
                <Button className="wrong-tick">DECLINE</Button>
                </div>
          </div>
          </div>


            </Grid>

            </Grid>
            
            <div className="warranty-extension"> 
                   <div className="warranty-yrs">
                   <p className="product-info-title">Warranty:</p>
                   <p className="blue-msg">Initial 2 Years</p>
                   </div>
                   <div className="warranty-yrs">
                   <p className="product-info-title">Extension permitet</p>
                   <p className="blue-msg">Yes, 1 Years</p>
                   </div>
                   <div className="warranty-yrs">
                   <p className="product-info-title">Price Extension Warranty</p>
                   <p className="blue-msg">60€</p>
                   </div>
                   <div className="warranty-yrs">
                   <p className="product-info-title">Warranty extension applied</p>
                   <p className="blue-msg">Yes, 10-10-2022</p>
                   </div>
               </div>
           
          <div className="edit-doenlod-info">
            <div>
                <Button className="edit-info">Edit info</Button>
            </div>
            <div>
                <Button className="down-info">Download info</Button>
            </div>
          </div>
         

          

         </div>
         
       

        </Box>
      </Modal>
      </div>

     
    </div>

    
  );
};

export default ProductInformation;
