import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import profilebrand from '../../../img/Group 28.png'
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom'
import approved from '../../../img/approved.png'
import cancel from '../../../img/CancelIconAPP.png'
import brandnamelogo from '../../../img/Group 28.png'
import defaultprofile from '../../../img/defaultprofile.png'
import Goback from '@mui/icons-material/KeyboardBackspaceRounded';
import consts from '../../../constants'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import userProfile from '../../../img/userProfile.png'
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import Axios from '../../../Axios';
import { Toaster, toast } from 'react-hot-toast'
import Typography from "@mui/material/Typography";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '20px'
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const AdminProfileBody = ({ sts }) => {

  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const name = useRef('')
  const email = useRef('');
  const password = useRef('');
  const [nameerr, setNameerr] = useState(null)
  // const [emailerr, setemailerr] = useState(null);
  // const [passworderr, setpassworderr] = useState(null);

  const [loadingstatus, setLoadingStatus] = useState(false)




  const [name1, setName] = useState("")
  const [password1, setPassword] = useState()
  const [emailerr, setemailerr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);
  const [profileImageChange, setProfileImageChange] = useState(null)
  const [imgName, setImgName] = useState('No selected files')
  const [profileBannerImageChange, setBannerImageChange] = useState(null)
  const [imgBannerName, setImgBannerName] = useState('No selected files')
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [details, setDetails] = useState()

  const [logo, setLogo] = useState()
  const [thump, setThump] = useState()

  const [logo1, setLogo1] = useState()
  const [thump1, setThump1] = useState()

  const usrrole = window.localStorage.getItem('Squelch')
  const useremail = window.localStorage.getItem('electrónico')

  const navigate = useNavigate()

  const getdetails = async () => {
    try {
      const { data } = await Axios.post(`/users/get_brand_detail`, { email: useremail }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success) {
        setDetails(data?.result)
        setName(data?.user_name)
        console.log("🚀 ~ file: AdminProfileBody.js:126 ~ getdetails ~ data?.result:", data?.result)
        setLogo(data?.result?.user_info?.logo)
        setThump(data?.result?.user_info?.thumb_nail)
        setLogo1(data?.result?.user_info?.logo)
        setThump1(data?.result?.user_info?.thumb_nail)
      }
    } catch (error) {
      console.log("🚀 getdetails ~ error:", error)

    }

  }
  useEffect(() => {

    getdetails()
  }, [])


  const createLogo = async (e) => {
    try {
      let files = await e[0]
      let formData = await new FormData();
      formData.append("image", files)

      const { data } = await Axios.post('/users/uploadlogo', formData)
      if (data.result) {
        setLogo(data.result)
        // navigate(`${consts.route}/register-success`)
        // toast.success('Image uploaded Successfully')

      }

    } catch (error) {
      console.log(error, "err")
    }
  }

  const createThump = async (e) => {
    try {
      let files = await e[0]
      let formData = await new FormData();
      formData.append("image", files)

      const { data } = await Axios.post('/users/uploadlogo', formData)
      if (data.result) {
        console.log(data?.result, "res")
        setThump(data.result)
        // navigate(`${consts.route}/register-success`)
        // toast.success('Image uploaded Successfully')
      }

    } catch (error) {
      console.log(error, "err")
    }
  }

  const update_profile = async () => {

    try {


      const { data } = await Axios.post(`/update_brand`, {
        name: name,
        email: useremail,
        logo: logo,
        thumbnail: thump
      },
        {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
      console.log("gjg", data, name, useremail, logo, thump)
      if (data?.success === true) {
        toast.success('Profile Updated Successfully')
        sts(true)
        //getdetails()
      }

    } catch (error) {
      console.log("🚀 ~ error:", error)
    }
  }

  const createSubAdmin = async () => {
    try {
      if (name.current.value === '') {
        setNameerr('Please Enter Name')
      } else if (email.current.value === '') {
        setemailerr('Please Enter Email Address')
      } else if (password.current.value === '') {
        setpassworderr('Please Enter Password')
      } else {
        setLoadingStatus(true)
        console.log(
          name.current.value, email.current.value, password.current.value
        )
        const { data } = await Axios.post(`/subAdminRegister`, {
          name: name.current.value,
          email: email.current.value,
          password: password.current.value
        },
          {
            headers: {
              Authorization: window.localStorage.getItem('AdminToken')
            }
          })
        if (data.success === true) {
          toast.success(data?.message)
          setLoadingStatus(false)
          name.current.value = ''
          email.current.value = ''
          password.current.value = ''
        }

      }
    } catch (error) {
      if (error?.response?.data?.success === false) {
        setLoadingStatus(false)
        toast.error(error?.response?.data?.message)
      }
    }

  }

  return (
    <div className='dashboard-body'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Item className={classes.dashboargraph} id="profile-block">
              <div className='gotoDiv'>
                <Button variant="contained" onClick={() => { navigate(`${consts.route}/dashboard`) }} startIcon={<Goback />}>
                  Back
                </Button>
              </div>
              {
                usrrole !== 'admin' ?
                  <Grid container spacing={0} className='profile-inner'>

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>

                      <div className='company-details'>
                        <label>Company:</label>
                        <p>{details?.user_info?.name}</p>
                        <div className='firm-logo'><img src={details?.user_info?.logo ? details?.user_info?.logo : profilebrand} alt="brandnamelogo" /></div>
                        <div className='profile-image-outer'>
                          <h4>Select Logo</h4>
                          <div className='profile-image profile-image-design-block'>
                            <form>
                              <input type="file" accept='image/*' className='input-field' hidden
                                onChange={({ target: { files } }) => {
                                  if (files?.length > 0) {
                                    createLogo(files)
                                    files[0] && setImgName(files[0].name)
                                    if (files) {
                                      setProfileImageChange(URL.createObjectURL(files[0]))
                                    }
                                  }

                                }}
                              />
                              {profileImageChange ?
                                <img src={profileImageChange} alt={imgName} /> :
                                <>
                                  {logo ?
                                    <img src={logo} alt={logo} /> :
                                    <div className='upload-image-block'><CloudUploadIcon /></div>
                                  }</>

                              }
                            </form>
                          </div>
                          <div className='edit-image-btn' onClick={() => document.querySelector(".input-field").click()}>
                            <Button variant="contained" endIcon={<BorderColorIcon />}>
                              Select
                            </Button>
                          </div>
                        </div>

                        <div className='profile-image-outer'>
                          <h4>Select Thumbnail</h4>
                          <div className='profile-image profile-image-design-block'>
                            <form>
                              <input type="file" accept='image/*' className='input-field-banner' hidden
                                onChange={({ target: { files } }) => {
                                  if (files?.length > 0) {
                                    createThump(files)
                                    files[0] && setImgBannerName(files[0].name)
                                    if (files) {
                                      setBannerImageChange(URL.createObjectURL(files[0]))
                                    }
                                  }

                                }}
                              />
                              {profileBannerImageChange ?
                                <img src={profileBannerImageChange} alt={imgBannerName} /> :
                                <>
                                  {
                                    thump ?
                                      <img src={thump} alt={thump} /> :
                                      <div className='upload-image-block'><CloudUploadIcon /></div>
                                  }
                                </>
                              }
                            </form>
                          </div>
                          <div className='edit-image-btn' onClick={() => document.querySelector(".input-field-banner").click()}>
                            <Button variant="contained" endIcon={<BorderColorIcon />}>
                              Select
                            </Button>
                          </div>
                        </div>

                      </div>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <div className='profilebrand'><img src={profilebrand} alt="profilebrand" /></div>
                      <div className='input-textfield-block'>
                        <label>User Name :</label>
                        <TextField id="outlined-basic" variant="outlined" disabled value={details?.user_info?.name} />
                      </div>
                      {/* <div className='input-textfield-block'>
                    <label>Brand ID :</label>
                    <TextField id="outlined-basic" variant="outlined" value={details?.user_info?.name} />
                  </div> */}
                      <div className='input-textfield-block'>
                        <label>Email :</label>
                        <TextField id="outlined-basic" variant="outlined" disabled value={details?.user_info?.email} />
                      </div>

                      <div className='textfield-block '>
                        <label>Password :</label>
                        <FormControl sx={{ m: 1, width: 'fit-content' }} variant="outlined" className='textField'>
                          <OutlinedInput
                            inputRef={password}
                            disabled
                            onChange={() => {
                              setpassworderr(null);
                            }}
                            id="outlined-adornment-password"
                            type={'password'}
                            value='summaasdffd'
                            // endAdornment={
                            //   <InputAdornment position="end">
                            //     <IconButton
                            //       aria-label="toggle password visibility"
                            //       onClick={handleClickShowPassword}
                            //       onMouseDown={handleMouseDownPassword}
                            //       edge="end"
                            //     >
                            //       {showPassword ? <Visibility /> : <VisibilityOff />}
                            //     </IconButton>
                            //   </InputAdornment>
                            // }
                            label="Password"
                          />
                        </FormControl>
                        {passworderr !== null ? (
                          <div style={{ color: "red", textAlign: 'center' }}>{passworderr}</div>
                        ) : (
                          <></>
                        )}
                        {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                      </div>

                      {/* <div className='input-textfield-block'>
      <label>Password :</label>
      <div className='psswrd-block-last'>
      <TextField id="outlined-basic" variant="outlined" disabled value="bharath@25" type="password" />
      </div>
    </div> */}
                      {/* <div className='input-textfield-block'>
      <label>Confirm :</label>
      <div className='psswrd-block-last'>
      <TextField id="outlined-basic" variant="outlined" value="bharath@25" type="password" />
      </div>
    </div> */}

                      <div className='input-textfield-block Update-Data'>
                        <Button variant="contained" onClick={() => { update_profile() }} >Sumbit</Button>
                      </div>

                    </Grid>
                  </Grid> :
                  <Grid container spacing={0} className='profile-inner'>

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                      <div className='company-details'>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <div className='profilebrand'><img src={profilebrand} alt="profilebrand" /></div>
                      <div className='input-textfield-block'>
                        <label>Admin Name :</label>
                        <TextField id="outlined-basic" variant="outlined" inputRef={name} onChange={(e) => { setNameerr(null) }} />
                      </div>
                      {nameerr !== null ? (
                        <div style={{ color: "red", textAlign: 'center' }}>{nameerr}</div>
                      ) : (
                        <></>
                      )}
                      {/* <div className='input-textfield-block'>
    <label>Brand ID :</label>
    <TextField id="outlined-basic" variant="outlined" value={details?.user_info?.name} />
  </div> */}
                      <div className='input-textfield-block'>
                        <label>Admin Email :</label>
                        <TextField id="outlined-basic" variant="outlined" inputRef={email} onChange={(e) => { setemailerr(null) }} />

                      </div>
                      {emailerr !== null ? (
                        <div style={{ color: "red", textAlign: 'center' }}>{emailerr}</div>
                      ) : (
                        <></>
                      )}


                      <div className='cmn-input-cls input-textfield-block profile-pass-box'>
                        <label>Password :</label>
                        <FormControl sx={{ m: 1, width: 'auto' }} variant="outlined" className='textField'>
                          <OutlinedInput
                            inputRef={password}
                            onChange={() => {
                              setpassworderr(null);
                            }}
                            id="outlined-adornment-password"
                            type={'password'}

                            // endAdornment={
                            //   <InputAdornment position="end">
                            //     <IconButton
                            //       aria-label="toggle password visibility"
                            //       onClick={handleClickShowPassword}
                            //       onMouseDown={handleMouseDownPassword}
                            //       edge="end"
                            //     >
                            //       {showPassword ? <Visibility /> : <VisibilityOff />}
                            //     </IconButton>
                            //   </InputAdornment>
                            // }
                            label="Password"
                          />
                        </FormControl>

                        {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                      </div>
                      {passworderr !== null ? (
                        <div style={{ color: "red", textAlign: 'center' }}>{passworderr}</div>
                      ) : (
                        <></>
                      )}

                      {/* <div className='input-textfield-block'>
<label>Password :</label>
<div className='psswrd-block-last'>
<TextField id="outlined-basic" variant="outlined" value="bharath@25" type="password" />
</div>
</div> */}
                      {/* <div className='input-textfield-block'>
<label>Confirm :</label>
<div className='psswrd-block-last'>
<TextField id="outlined-basic" variant="outlined" value="bharath@25" type="password" />
</div>
</div> */}

                      <div className='input-textfield-block Update-Data'>
                        {
                          loadingstatus === true ?
                            <>
                              <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                              >
                                Submit....
                              </LoadingButton></>
                            :
                            <>
                              <Button variant="contained" onClick={() => { createSubAdmin() }} >Sumbit</Button></>
                        }

                        {/* <Button variant="contained" onClick={() => { createSubAdmin() }} >Sumbit</Button> */}
                      </div>

                    </Grid>
                  </Grid>}
            </Item>
          </Grid>

          {
            usrrole !== 'admin' ?
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className='three-block-profile'>

                  <div className='block-item'>
                    <h5>Licensing</h5>
                    <div className='text-block-div'>{details?.licence_info?.ondate}</div>
                  </div>

                  <div className='block-item'>
                    <h5>License Expiration</h5>
                    <div className='text-block-div'>{details?.licence_info?.expiredate}</div>
                  </div>

                  <div className='block-item'>
                    <h5>Self renewal</h5>
                    <Stack direction="row" spacing={1} alignItems="center" className='action-ptxt'>
                      <Typography>Off</Typography>
                      <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                      <Typography>On</Typography>
                    </Stack>
                  </div>

                </div>
              </Grid>
              :
              <></>
          }



        </Grid>
      </Box>






    </div>
  )
}

export default AdminProfileBody
