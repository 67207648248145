import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DashboardLineChart from './DashboardLineChart';
import ChartFlow from '../../../img/ChartFlow.png';
import QRTotal from './QRTotal';
import QRTotalPending from './QRTotalPending';
import MonthlyChart from './MonthlyChart';
import DailyCost from './DailyCost';
import locationMark from '../../../img/locationMark.png';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Axios from '../../../Axios'
import { useNavigate } from 'react-router-dom'
import consts from '../../../constants'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';



// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

// const today = dayjs();
// const yesterday = dayjs().subtract(1, 'day');
// const todayStartOfTheDay = today.startOf('day');


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});

const DashboardBody = () => {

  const navigate = useNavigate()

  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  const [details, setDetails] = useState([])
  const [markers, setMarkers] = useState([])

  const [foucsLocation, setFocusLocation] = useState()

  const [this_Week, setThisWeek] = useState([])
  const [dates, setDates] = useState([])

  const [cost, setCost] = useState([])
  const [costTime, setCostTime] = useState([])

  // const [transCount, setTransCount] = useState()

  useEffect(() => {
    profile()
  }, [])

  const [blochChainCount, setBlocChainCount] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)
  const [ActivateCount, setActivateCount] = useState(0)
  const [creationDate, setCreationDate] = useState()
  const [paymentDate, setPaymentDate] = useState()

  const [dailyCostsss, setDailyCosts] = useState()
  const [dailyCostsss1, setDailyCosts1] = useState()
  const [dailyCostsss2, setDailyCosts2] = useState()

  const [EndDate, setEndDate] = useState()

  function getDateOneWeekAfter(inputDate) {
    // Parse the input date string into a Date object
    var date = new Date(inputDate);

    // Add 7 days to the input date
    date.setDate(date.getDate() + 7);

    // Return the new date
    return date;
  }

  const handlecreationdatechange = (d, m, y) => {
    var credate = y + '-' + m + '-' + d
    setCreationDate(credate)
    var oneWeekAfterDate = getDateOneWeekAfter(credate);

    var EndDate = dayjs(new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0])
    var Enddates = new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0]
    setEndDate(EndDate)

    setPaymentDate(Enddates)
    countsof(credate, Enddates)
    getPositioncount(Enddates)
    graphdata(credate, Enddates)
    dailyCosts(credate, Enddates)
    getTransactions(credate, Enddates)
    dailyCostss(credate, Enddates)
  }

  // const handlepaymentdatechange = (d, m, y) => {

  //   var payDate = y + '-' + m + '-' + d

  //   setPaymentDate(payDate)
  //   countsof(payDate)
  //   getPositioncount(payDate)
  //   graphdata(creationDate, payDate)
  //   dailyCosts(creationDate, payDate)
  //   getTransactions(creationDate, payDate)
  // }

  const profile = async () => {
    if (role === 'brand') {
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        navigate(`${consts.route}/`, { state: { data: true } })
      }

    }
  }

  const counts = async () => {
    try {
      const { data } = await Axios.post('/admin/brand_dashboard_count', {
        creation_date: creationDate,
        payment_date: paymentDate
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setBlocChainCount(data?.result?.blockChain)
        setPendingCount(data?.result?.pendingActivate)
        setActivateCount(data?.result?.ActivateCount)
      }
    } catch (error) {
      console.log("🚀 ~ file: DashboardBody.js:116 ~ counts ~ error:", error)

    }

  }
  const countsof = async (creationDates, pay) => {
    try {
      const { data } = await Axios.post('/admin/brand_dashboard_count', {
        creation_date: creationDates,
        payment_date: pay
      }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      if (data?.success === true) {
        setBlocChainCount(data?.result?.blockChain)
        setPendingCount(data?.result?.pendingActivate)
        setActivateCount(data?.result?.ActivateCount)

      }
    } catch (error) {
      console.log("🚀 ~ file: DashboardBody.js:116 ~ counts ~ error:", error)

    }

  }

  // const getTransactions = async () => {
  //   try {
  //     const { data } = await Axios.post('/admin/getBlockchain', {}, {
  //       headers: { Authorization: window.localStorage.getItem("AdminToken") }
  //     })
  //     console.log("🚀 ~ file: BrandBlockchainBody.js:478 ~ getTransactions ~ data:", data)
  //     if (data?.success === true) {
  //       const dts = data?.result
  //       var arr = []
  //       for (let i = 0; i < dts.length; i++) {
  //         const element = dts[i];
  //         // if (element?.TransactionFee) {
  //         arr.push([element?._id, element?.totalTransactionFee])
  //         // }
  //       }
  //       setTransac(arr)
  //     }
  //   } catch (error) {
  //     console.log("🚀 ~ file: BrandBlockchainBody.js:477 ~ getTransactions ~ error:", error)

  //   }
  // }

  const graphdata = async (start, end) => {
    try {
      const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dav = []
      if (start && end) {
        const { data } = await Axios.post(`/admin/brandDashboardCount`, {
          start: start,
          end: end
        }, {
          headers: { Authorization: window.localStorage.getItem("AdminToken") }
        })
        if (data?.success == true) {
          var arr = []
          var temp = []
          var temp1 = []
          var date = []
          for (let i = 0; i < data?.result.length; i++) {
            const element = data?.result[i];
            temp.push(element?.transactionCount)
            date.push(element?._id)

            dav.push(dayNames[element?._id - 1])

          }
          if (temp1 == [] || temp == []) {
            arr = [
              {
                name: "Last Week",
                data: []
              },
              {
                name: 'This Week',
                data: []
              }
            ]
          } else {
            arr = [
              {
                name: "Last Week",
                data: temp1
              },
              {
                name: 'This Week',
                data: temp
              }
            ]
          }

          setThisWeek(arr)
          setDates(dav)
        }
      } else {
        const { data } = await Axios.post(`/admin/brandDashboardCount`, {}, {
          headers: { Authorization: window.localStorage.getItem("AdminToken") }
        })
        if (data?.success == true) {

          var arr = []
          var temp = [0, 0, 0, 0, 0, 0, 0]
          var temp1 = [0, 0, 0, 0, 0, 0, 0]
          var date = []
          for (let i = 0; i < 7; i++) {
            var element = data?.result[i];
            if (element === "undefined" || element === undefined || element === null) {
              if (temp[i] === 0) {
                temp[i] = 0
              }
            }
            else {
              temp[data?.result[i]._id - 1] = data?.result[i]?.transactionCount
            }


          }
          for (let i = 0; i < 7; i++) {
            var element = data?.response2[i];
            if (element === "undefined" || element === undefined || element === null) {
              if (temp1[i] === 0) {
                temp1[i] = 0
              }

            }
            else {

              temp1[data?.response2[i]._id - 1] = data?.response2[i]?.transactionCount
            }



          }

          arr = [
            {
              name: "Last Week",
              data: temp1
            },
            {
              name: 'This Week',
              data: temp
            }
          ]
          setThisWeek(arr)
          setDates(dayNames)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: DashboardBody.js:180 ~ graphdata ~ error:", error)

    }
  }

  useEffect(() => {
    graphdata()
  }, [])

  const dailyCosts = async (start, end) => {
    try {
      if (start && end) {
        const { data } = await Axios.post(`/admin/brandDailyCost`, {
          start: start,
          end: end
        }, {
          headers: { Authorization: window.localStorage.getItem("AdminToken") }
        })
        if (data?.success == true) {
          var arr = []
          var arr1 = []
          if (data?.result?.length > 0) {
            for (let i = 0; i < data?.result?.length; i++) {
              const element = data?.result[i];
              arr.push(element?.dailyCost)
              arr1.push(element?.date)
            }
            setCost(arr)
            setCostTime(arr1)
          } else {
            setCost([])
            setCostTime([])
          }
        }
      } else {
        const { data } = await Axios.post(`/admin/brandDailyCost`, {}, {
          headers: { Authorization: window.localStorage.getItem("AdminToken") }
        })
        if (data?.success == true) {
          var arr = []
          var arr1 = []
          if (data?.result?.length > 0) {
            for (let i = 0; i < data?.result?.length; i++) {
              const element = data?.result[i];
              arr.push(element?.dailyCost)
              arr1.push(element?.date)
            }
            setCost(arr)
            setCostTime(arr1)
          } else {
            setCost([])
            setCostTime([])

          }

        }
      }

    } catch (error) {
      console.log("🚀 ~ file: DashboardBody.js:180 ~ dailyCosts ~ error:", error)

    }
  }

  // const dailyCosts1 = async () => {
  //   try {

  //     const { data } = await Axios.post(`/admin/brandDailyCost`, {}, {
  //       headers: { Authorization: window.localStorage.getItem("AdminToken") }
  //     })
  //     if (data?.success == true) {
  //       var arr = []
  //       var arr1 = []
  //       if (data?.result?.length > 0) {
  //         for (let i = 0; i < data?.result?.length; i++) {
  //           const element = data?.result[i];
  //           arr.push(element?.dailyCost)
  //           arr1.push(element?.date)
  //         }
  //         setCost(arr)
  //         setCostTime(arr1)
  //       }

  //     }


  //   } catch (error) {
  //     console.log("🚀 ~ file: DashboardBody.js:180 ~ dailyCosts ~ error:", error)

  //   }
  // }

  const getTransactions = async (start, end) => {
    try {
      const { data } = await Axios.post('/admin/getBlockchain', {
        payment_date: start,
        creation_date: end
      }, {
        headers: { Authorization: window.localStorage.getItem("AdminToken") }
      })
      if (data?.success === true) {
        const dts = data?.result
        // var arr = []
        var arr = []
        var arr1 = []
        for (let i = 0; i < dts.length; i++) {
          const element = dts[i];
          arr.push(element?.totalTransactionFee)
          arr1.push(element?._id)
          // arr.push([element?._id, element?.totalTransactionFee])

        }
        setCost(arr)
        setCostTime(arr1)
      } else {
        setCost([])
        setCostTime([])
      }
    } catch (error) {
      console.log("🚀 ~ file: BrandBlockchainBody.js:477 ~ getTransactions ~ error:", error)

    }
  }

  useEffect(() => {
    // dailyCosts1()
    getTransactions()
  }, [])

  const getPositioncount = async (pay) => {
    try {
      if (role === 'admin') {
        const { data } = await Axios.post('/admin/adminTracability', {
          creation_date: creationDate,
          payment_date: pay
        }, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if (data?.result?.length > 0) {
            setDetails(data?.result)
            const dts = data?.result
            var arr = []
            // setFocusLocation([dts[0]?.product_details?.latitude, dts[0]?.product_details?.longitude])
            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,

                }
                arr.push(obj)
              }
            }

            setMarkers(arr)

          }

        }
      } else if (role === 'brand') {
        const { data } = await Axios.post('/admin/brandTracability', {}, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if (data?.resultBrand?.length > 0) {
            var tempArr = data?.resultBrand?.concat(data?.resultUser)
            setDetails(tempArr)
            const dts = tempArr
            var arr = []
            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];
              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }

        }
      }

    } catch (error) {
      console.log("🚀 ~ file: AdminTraceability1.js:184 ~ getPosition ~ error:", error)
    }
  }

  useEffect(() => {
    counts()
  }, [])

  const getPosition = async () => {
    try {
      if (role === 'admin') {
        const { data } = await Axios.post('/admin/adminTracability', {}, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if (data?.result?.length > 0) {
            setDetails(data?.result)
            const dts = data?.result
            var arr = []
            // setFocusLocation([dts[0]?.product_details?.latitude, dts[0]?.product_details?.longitude])
            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];

              if (((element?.product_details?.latitude !== null) && (element?.product_details?.latitude !== undefined)) && ((element?.product_details?.longitude !== null) && (element?.product_details?.longitude !== undefined))) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,

                }
                arr.push(obj)
              }
            }

            setMarkers(arr)

          }

        }
      } else if (role === 'brand') {
        const { data } = await Axios.post('/admin/brandTracability', {}, {
          headers: {
            Authorization: window.localStorage.getItem('AdminToken')
          }
        })
        if (data?.success === true) {
          if (data?.resultBrand?.length > 0) {
            var tempArr = data?.resultBrand?.concat(data?.resultUser)
            setDetails(tempArr)
            const dts = tempArr
            var arr = []
            for (let i = 0; i < dts.length; i++) {
              const element = dts[i];
              if ((element?.product_details?.latitude !== null) && (element?.product_details?.longitude !== null)) {
                setFocusLocation([element?.product_details?.latitude, element?.product_details?.longitude])
                var obj = {
                  geocode: [element?.product_details?.latitude, element?.product_details?.longitude],
                  popUp: element?.product_details?.product_id,
                  ProductName: element?.product_details?.Product_Name,
                  userName: element?.brandDetails ? element?.brandDetails?.user_info?.name : element?.usersDetails?.firstname
                }
                arr.push(obj)
              }
            }
            setMarkers(arr)
          }

        }
      }

    } catch (error) {
      console.log("🚀 ~ file: AdminTraceability1.js:184 ~ getPosition ~ error:", error)
    }
  }

  useEffect(() => {
    getPosition()
  }, [])

  const states = {
    center: [51.505, -0.091],
    zoom: 3,
  };

  const customIcon = new Icon({
    iconUrl: locationMark,
    iconSize: [23, 30]
  })

  const dailyCostss = async (start, end) => {
    try {
      const { data } = await Axios.post(`/admin/brandDashIncomeChart`, {
        creation_date: start,
        payment_date: end
      }, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      if (data?.success === true) {
        // var dates = []
        var amount = ['0', '0', '0', '0', '0', '0', '0']
        var amount1 = ['0', '0', '0', '0', '0', '0', '0']
        var dates1 = []
        var dates2 = []

        for (let i = 0; i < data?.income?.length; i++) {
          const element = data?.income[i];
          if (!dates1.includes(Number(element?.dayOfWeek) - 1)) {
            dates1.push(Number(element?.dayOfWeek) - 1)
            amount[Number(element?.dayOfWeek) - 1] = (element?.totalAmount).toFixed(2)
          }
        }

        for (let i = 0; i < data?.outcome?.length; i++) {
          const element = data?.outcome[i];
          if (!dates2.includes(Number(element?.dayOfWeek) - 1)) {
            dates2.push(Number(element?.dayOfWeek) - 1)
            amount1[Number(element?.dayOfWeek) - 1] = (element?.totalAmount).toFixed(2)

          }
        }

        const dates = Array.from(new Set(dates1.concat(dates2)));

        setDailyCosts1(amount)
        setDailyCosts(dates)
        setDailyCosts2(amount1)
      }
    } catch (error) {
      console.log("🚀 ~ file: DashboardBody.js:501 ~ dailyCostss ~ error:", error)
    }
  }

  useEffect(() => {
    dailyCostss()
  }, [])

  const classes = useStyles();

  return (
    <div className='dashboard-body'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className={classes.containwidth}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Item className={classes.dashboargraph} id="dashboargraph">
              <div className='dashboargraph-part'>
                <div className='dashboargraph-inner-top'>
                  {/* <Button variant="contained" endIcon={<ExitToAppIcon />}>
                    Export
                  </Button> */}
                </div>

                <div className='dashboargraph-inner-mid'>
                  <div className='dashboargraph-inner-mid-left'>
                    <h5>Daily blockchain transactions</h5>
                    <p>Information on how many transactions in the blockchain have been made per week.</p>
                  </div>
                  <div className='dashboargraph-inner-mid-right'>
                  </div>
                </div>

                <div className='dashboargraph-inner-bottom'>
                  <DashboardLineChart this_Week={this_Week} dates={dates} />
                </div>
              </div>

              {
                foucsLocation?.length > 0 ?

                  <Map center={foucsLocation} zoom={states?.zoom}>
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    />
                    {
                      markers?.map((marker) => (
                        <Marker position={marker?.geocode} icon={customIcon} >
                          <Popup>
                            <label>Product Id: </label> <span>{marker?.popUp}</span><br></br>
                            <label>Product Name: </label> <span>{marker?.ProductName}</span><br></br>
                            <label>Owned By: </label> <span>{marker?.userName}</span>
                          </Popup>
                        </Marker>
                      ))
                    }

                  </Map>
                  :
                  <Map center={states?.center} zoom={states?.zoom}>
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    />
                    {/* {
                          markers?.map((marker) => (
                            <Marker position={marker?.geocode} icon={customIcon} >
                              <Popup>
                                <h2>{marker?.popUp}</h2>
                              </Popup>
                            </Marker>
                          ))
                        } */}
                    {/* <Marker position={this.state.center}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker> */}
                  </Map>
              }

              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20311214.073350176!2d101.98305051684045!3d71.89764895113782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1693909504227!5m2!1sen!2sin" width="100%" height="550" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" /> */}

            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.rightpartstyle}>
            <Item className={classes.headercls}>


              <div className='start-end-date'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'DatePicker',
                      'DateTimePicker',
                      'TimePicker',
                      'DateRangePicker',
                    ]}
                  >
                    <DemoItem label="Start Date">
                      <DatePicker

                        views={['year', 'month', 'day']}
                        onChange={(e) => { handlecreationdatechange(e.$D, e.$M + 1, e.$y); console.log(e, "date") }}

                      />
                    </DemoItem>

                    <DemoItem label="End Date">
                      <DatePicker
                        views={['year', 'month', 'day']}
                        value={EndDate}
                        disabled
                      // onChange={(e) => { handlepaymentdatechange(e.$D, e.$M + 1, e.$y) }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <div className='total-pdts'>
                <p>Total Products Registered in The Blockchain</p>
                <div className='total-pdts-div'>
                  <div className='total-pdts-div-left'><label>Products</label><span>{blochChainCount}</span></div>
                  {/* <div className='total-pdts-div-right'>
                    <img src={ChartFlow} alt="ChartFlow" />
                  </div> */}
                </div>
              </div>

              <div className='QR-codes'>
                {
                  ActivateCount > 0 ?
                    <div className='total-qr-codes-activated'>
                      <QRTotal ActivateCount={ActivateCount} />
                    </div>
                    :
                    <div className='total-qr-codes-activated'>
                      <QRTotal ActivateCount={0} />
                    </div>
                }

                <div className='total-qr-codes-activated pending'>
                  <QRTotalPending pendingCount={pendingCount} />
                </div>
              </div>

              <div className='monthly-chart daily-cost'>
                <h5></h5>
                <MonthlyChart first={dailyCostsss1} date={dailyCostsss} last={dailyCostsss2} />
              </div>

              <div className='monthly-chart daily-cost'>
                <h5>Blockchain Transactions Daily Costs</h5>
                <DailyCost cost={cost} costTime={costTime} />
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default DashboardBody
