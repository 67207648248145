import React, { useState } from "react";
import "./AdminSellersAuth.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import Axios from "../../../Axios";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import filter from '../../../img/filter.svg';

// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const FilterPopup = ({ filterPro, reset, setIsfilter }) => {
  // const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setsellerId('')
    setfirstname('')
    setChecked()
    setCheckeds()

  }
  const [sellerId, setsellerId] = useState()
  const [firstname, setfirstname] = useState()
  const role = window.localStorage.getItem('Squelch')

  const [isChecked, setChecked] = useState();
  const [isCheckeds, setCheckeds] = useState();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChanges = (event) => {
    setCheckeds(event.target.checked);
  };



  const filterApi = async () => {
    const datas = {
      sellerId: sellerId,
      firstname: firstname,
      is_active: isChecked,
      verified: isCheckeds

    }
    if ((role === 'admin') || (role === 'subAdmin')) {
      const { data } = await Axios.post(`/users/get_all_sellers`, datas,
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      handleClose()
      setIsfilter(true)
      filterPro(data?.result)

    }
    else {
      const { data } = await Axios.post(`/users/get_seller`, datas,
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      handleClose()
      setIsfilter(true)

      filterPro(data?.result)
    }

  }

  const ResetFilter = async () => {
    setsellerId('')
    setfirstname('')
    setChecked()
    setCheckeds()
    handleClose()
    reset(true)
    setIsfilter(false)

  }


  return (
    <div >
      <Button variant="contained" onClick={handleOpen}>Filter &nbsp;<img src={filter} alt="filter" /></Button>
      <Button className='filter-color' onClick={() => { ResetFilter() }}>Filter Reset</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="AdminCustomerpopBOX AdminCustomerpopBOX-product-filter" >
          <div className="filter-overflow">
            <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            <div className="h2Variant">Seller filter</div>

            <div className="listOne">

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Seller Id
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Enter Your Amount "
                    variant="outlined"
                    className="textField"
                    value={sellerId}
                    onChange={(e) => { setsellerId(e.target.value) }}
                  />
                </div>
              </div>

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                internal ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Brand mail"
                  variant="outlined"
                  className="textField"
                  type="email"
                />
              </div>
            </div> */}

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                customer ref
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                />
              </div>
            </div> */}

              <div className="productId">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Name
                </Typography>

                <div className="cmn-input-cls">
                  <TextField
                    id="outlined-basic"
                    // label="Brand mail"
                    variant="outlined"
                    className="textField"
                    value={firstname}
                    onChange={(e) => { setfirstname(e.target.value) }}

                  />
                </div>
              </div>
              <div>
                <Checkbox className="checkBox" checked={isChecked} onChange={handleChange} />
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Is Active
                </Typography>

                <Checkbox className="checkBox" checked={isCheckeds} onChange={handleChanges} />
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modelTypo"
                >
                  Is Verified
                </Typography>
              </div>

              {/* <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                Total_without_tax
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Contact Mail"
                  variant="outlined"
                  className="textField"
                  value={Total_without_tax}
                  onChange={(e) => { setTotal_without_tax(e.target.value) }}
                />
              </div>
            </div>

            <div className="productId">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modelTypo"
              >
                price
              </Typography>

              <div className="cmn-input-cls">
                <TextField
                  id="outlined-basic"
                  // label="Enter Your Amount "
                  variant="outlined"
                  className="textField"
                  value={price}
                  onChange={(e) => { setprice(e.target.value) }}
                />
              </div>
            </div> */}

            </div>



            <div className="butOutLayer">
              <Button className='reset' onClick={() => { ResetFilter() }} >Reset filter</Button>
              <Button className='apply' onClick={() => { filterApi() }} >Apply</Button>
            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default FilterPopup;
