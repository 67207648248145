import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import { Link, useNavigate } from 'react-router-dom'
import AdminMenuSide from '../../Admin/AdminMenuSide';
import './MyProduct.css'
import Axios from '../../../Axios';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },
    stickycls: {
        position: 'sticky',
        top: '0',
        zIndex: '999',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const MyProductList = () => {

    const classes = useStyles();

    const [menuOpen, setMenuOpen] = useState(true)

    const openMenuHandler = () => {
        setMenuOpen(false)
    }

    const closeMenuHandler = () => {
        setMenuOpen(true)
    }
    const [nftdata, setNftdat] = useState([])

    const navigate = useNavigate()

    const nfts = async () => {
        const { data } = await Axios.post('/users/productDetailById', {},
            {
                headers: {
                    Authorization: window.localStorage.getItem("AdminToken"),
                },
            }
        )
        if (data.success === true) {
            setNftdat(data?.result)
        } else {
            setNftdat(null)
        }
    }

    useEffect(() => {
        nfts()
    }, [])

    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
                        <Item className={classes.headercls}>
                            <Header />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.bodyclass}>
                            <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                            <div className='categories-menu2' id={!menuOpen ? "openmenucls" : ""}>
                                <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                                <AdminMenuSide />
                            </div>
                        </Item>

                        <div className='my-products' onClick={closeMenuHandler}>
                            <div className='my-product-body-main'>

                                <div className='warrenty-close-div'>
                                    <div>
                                        <Link to="/user/dashboard"><img src={Menuclose} alt="close" /></Link>
                                    </div>
                                    <div>

                                    </div>
                                </div>

                                <div className='product-list'>
                                    {/* <div className='product-list-text'>
                                        MY PRODUCTS Select a product, to manage it
                                    </div> */}
                                    {
                                        nftdata ?
                                            <>
                                                {
                                                    nftdata?.map((item, index) => {
                                                        return (
                                                            // <Link to="/user/myproductview">
                                                            <div className='lists' onClick={() => { navigate('/user/myproductview', { state: { data: item } }) }}>

                                                                <div className='product-img'>
                                                                    <img src={item?.product_details?.main_image} alt="watch" />
                                                                </div>
                                                                <div className='product-id'>
                                                                    {item?.product_details?.Product_Name}
                                                                </div>
                                                                <div className='product-description'>
                                                                    {item?.product_details?.product_description}
                                                                </div>
                                                            </div>
                                                            // </Link>
                                                        )
                                                    })
                                                }

                                            </>
                                            :
                                            <div>No Product Available</div>
                                    }

                                    {/* <Link to="/user/myproductview">
                                        <div className='lists'>
                                            <div className='product-img'>
                                                <img src={motor} alt="watch" />
                                            </div>
                                            <div className='product-id'>
                                                TEST PRODUCT ID ADD-12231-11
                                            </div>

                                            <div className='product-description'>
                                                Sample company brand watch made of titanium and 18K gold
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="/user/myproductview">
                                        <div className='lists'>
                                            <div className='product-img'>
                                                <img src={bag} alt="watch" />
                                            </div>
                                            <div className='product-id'>
                                                TEST PRODUCT ID ADD-12231-11
                                            </div>

                                            <div className='product-description'>
                                                Sample company brand watch made of titanium and 18K gold
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="/user/myproductview">
                                        <div className='lists'>
                                            <div className='product-img'>
                                                <img src={motor} alt="watch" />
                                            </div>
                                            <div className='product-id'>
                                                TEST PRODUCT ID ADD-12231-11
                                            </div>

                                            <div className='product-description'>
                                                Sample company brand watch made of titanium and 18K gold
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to="/user/myproductview">
                                        <div className='lists'>
                                            <div className='product-img'>
                                                <img src={watch} alt="watch" />
                                            </div>
                                            <div className='product-id'>
                                                TEST PRODUCT ID ADD-12231-11
                                            </div>

                                            <div className='product-description'>
                                                Sample company brand watch made of titanium and 18K gold
                                            </div>
                                        </div>
                                    </Link> */}


                                </div>

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default MyProductList
