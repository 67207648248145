import React, { useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import { styled } from '@mui/material/styles';
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../constants';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import TextField from '@mui/material/TextField';
// import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button'
import "../Login/Login.css"
import Axios from '../../Axios';
import profilebrand from '../../img/profilebrand.png'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   boxShadow: 'none !important',
// }));

const ForgetPassword = () => {

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);

  const password = useRef(null);
  const cpassword = useRef(null);

  const [passworderr, setpassworderr] = useState(null);
  const [cpassworderr, setcpassworderr] = useState(null);

  const [active, setActive] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const params = useParams()
  const navigate = useNavigate()

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // useEffect(() => {
  //   console.log(params)
  // }, [])



  const handlepsaaword = () => {
    const re = new RegExp(/(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/g)
    if (password.current.value === "") {
      setpassworderr("Please Enter Password");
    }
    // else if(!re.test(password.current.value)){
    //   setpassworderr("Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abcd@1234");
    // }

    else {
      setpassworderr(null);
    }
  }

  const handlecpsaaword = () => {
    const re = new RegExp(/(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/g)
    if (cpassword.current.value === "") {
      setcpassworderr("Please Enter Confirm Password");
    }
    // else if(!re.test(cpassword.current.value)){
    //   setcpassworderr("Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abcd@1234");
    // }
    else if (cpassword.current.value !== password.current.value) {
      setcpassworderr("Both must Be Equal");
    }
    else {
      setcpassworderr(null);
    }
  }

  const sendEmail = async () => {
    try {
      const re = new RegExp(/(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/g)

      if (password.current.value === "") {
        setpassworderr("Please Enter Password");
      }
      // else if(!re.test(password.current.value)){
      //   setpassworderr("Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abcd@1234");
      // }
      else if (cpassword.current.value === "") {
        setcpassworderr("Please Enter Confirm Password");
      }
      // else if(!re.test(cpassword.current.value)){
      //   setcpassworderr("Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abcd@1234");
      // }
      else if (cpassword.current.value !== password.current.value) {
        setcpassworderr("Both must Be Equal");
      }
      else {


        // re.test(email.current.value)
        setActive(true)
        const { data } = await Axios.post("/verifyforgotpassword", {
          email: params.email,
          password: password.current.value
        },
          {
            headers: {
              Authorization: params.token
            }
          }
        );
        // console.log(data)

        if (data?.success) {
          toast.success("Password Changed Successfully")
          setTimeout(() => {
            navigate(`${consts.route}/`)
          }, 2000);
          // console.log(data)
        }
        else {
          setActive(false)
          toast.error(data?.message)
        }
      }
    } catch (error) {
      setActive(false)
      //console.log(error, "err")
      setpassworderr(error?.response?.data?.message)
    }
  };


  return (
    <div className='login-page forget'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='logo-side'>
            <div className='left-side-txt'><Link to="/"><img src={profilebrand} alt="profilebrand" /></Link></div>
            <p>We product your brand 100% with blockchain technology</p>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='right-content'>
            <div className="form-input">
              <div className='login-txt'>
                <h1>Reset Password 🥳 </h1>
              </div>

              <div className='email-password-block'>

                <div className='cmn-input-cls'>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      onChange={() => {
                        handlepsaaword()
                      }}
                      inputRef={password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {passworderr !== null ? (
                    <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{passworderr}</div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className='cmn-input-cls'>
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className='textField'>
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword1 ? 'text' : 'password'}
                      onChange={() => {
                        handlecpsaaword()
                      }}
                      inputRef={cpassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {cpassworderr !== null ? (
                    <div style={{ color: "red", textAlign: 'center', fontSize: "10px" }}>{cpassworderr}</div>
                  ) : (
                    <></>
                  )}
                </div>

              </div>
              {
                active === true ?
                  <Button className='submit'>Submit...</Button>
                  :
                  <Button className='submit' onClick={() => { sendEmail() }}>Submit</Button>
              }



            </div>
          </Grid>

        </Grid>
      </Box>


    </div>
  )
}

export default ForgetPassword
