import React from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../Header/Header';
import { Link } from 'react-router-dom'
import "./PremiumBrands.css"
import Avatar from '@mui/material/Avatar';
import brand1 from "../../../img/premiumBrand/brand1.svg"
import brand2 from "../../../img/premiumBrand/brand2.svg"
import brand3 from "../../../img/premiumBrand/brand3.svg"
import brand4 from "../../../img/premiumBrand/brand4.svg"
import brand5 from "../../../img/premiumBrand/brand5.svg"
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
});

const Home = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <div className='dashboard-page premium-brand-chat'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='premium-brands'>
                <div className='communicate'>talk to your brand</div>
                <div className='active'>active contacts (2)</div>

                <div>
                  <Stack direction="row" spacing={2} className="contact-profile" >
                    <Avatar>
                      <img src={brand1} alt="brand1" />
                    </Avatar>
                    <Avatar>
                      <img src={brand2} alt="brand2" />
                    </Avatar>
                    <Avatar>
                      <img src={brand3} alt="brand3" />
                    </Avatar>
                    <Avatar>
                      <img src={brand4} alt="brand4" />
                    </Avatar>
                    <Avatar>
                      <img src={brand5} alt="brand5" />
                    </Avatar>
                  </Stack>
                </div>


                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                >
                  <Link to="/user/PremiumChat">
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <img src={brand2} alt="brand2" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className='content' primary="the demo company" secondary="new product available" />
                      <ListItemText className='caption'>2 hours</ListItemText>
                    </ListItem>
                  </Link>
                  <Divider component="li" className="divider-border" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={brand1} alt="brand1" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className='content' primary="dexter dela curz" secondary="new product available" />
                    <ListItemText className='caption'>5 hours</ListItemText>
                  </ListItem>
                  <Divider component="li" className="divider-border" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={brand3} alt="brand3" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className='content' primary="joyce chua" secondary="watches product available" />
                    <ListItemText className='caption'>5 hours</ListItemText>
                  </ListItem>
                  <Divider component="li" className="divider-border" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={brand4} alt="brand4" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className='content' primary="joyce chua" secondary="watches product available" />
                    <ListItemText className='caption'>Yesturday</ListItemText>
                  </ListItem>
                  <Divider component="li" className="divider-border" />
                  <Link to="/user/PremiumChat">
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <img src={brand5} alt="brand5" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText className='content' primary="the demo company" secondary="new product available" />
                      <ListItemText className='caption'>2 hours</ListItemText>
                    </ListItem>
                  </Link>
                  <Divider component="li" className="divider-border" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={brand1} alt="brand1" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className='content' primary="dexter dela curz" secondary="new product available" />
                    <ListItemText className='caption'>5 hours</ListItemText>
                  </ListItem>
                  <Divider component="li" className="divider-border" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={brand3} alt="brand3" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className='content' primary="joyce chua" secondary="watches product available" />
                    <ListItemText className='caption'>5 hours</ListItemText>
                  </ListItem>
                  <Divider component="li" className="divider-border" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img src={brand4} alt="brand4" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText className='content' primary="joyce chua" secondary="watches product available" />
                    <ListItemText className='caption'>Yesturday</ListItemText>
                  </ListItem>
                </List>



                <Box sx={{ width: '100%' }} className="chat-tab">
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Item One" {...a11yProps(0)} />
                      <Tab label="Item Two" {...a11yProps(1)} />
                      <Tab label="Item Three" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    Item One
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    Item Two
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    Item Three
                  </CustomTabPanel>
                </Box>




              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.footercls}>
              {/* <Footer />Genera */}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Home
