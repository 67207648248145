import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './BrandProducts.css'
import DownloadProducts from './DownloadProducts'
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Axios from '../../../Axios';
import closeMenu from "../../../img/closemenu.png";
import adidasLogo from '../../../img/adidasLogo.png';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import { Link, useLocation, useParams } from 'react-router-dom'
import Files from '../../../img/files.svg';
import dayjs from 'dayjs';
import './QrProduct.css'
import Pagination from '@mui/material/Pagination';
import CustomerFilter from './CustomerFilter';
import qrbar from '../../../img/qr-bar.png';
import rightState from '../../../img/rightState.png';
import wrongState from '../../../img/wrongState.png';
import productimagethumb from '../../../img/product-image-thumb.png';
import box from '../../../img/box.svg';
import AdminMenuSide from '../../Admin/AdminMenuSide';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import cross from '../../../img/cross.svg'
import MainBag from '../../../img/main-bag.png'
import QrBag from '../../../img/qr-bag-img.png'
import ProductInfo from '../../../img/qr-product-info.png'
import Thumbup from '../../../img/like-btn.png';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as XLSX from "xlsx";
import approve from '../../../img/approve-img.png'
import decline from '../../../img/decline-img.png'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Toaster, toast } from 'react-hot-toast'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddSellersPopup from './AddSellersPopup1';
import QRCode from 'react-qr-code';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CryptoJS from "crypto-js";




import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { async } from 'q';
import consts from '../../../constants';




const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '30px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },

  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});

function createData(
  brandLogo,
  brandName,
  pdtName,
  refint,
  moreInfo,
  status

) {
  return { brandLogo, brandName, pdtName, refint, moreInfo, status };
}

const row = [
  createData(productimagethumb, 'ADIDAS', 'test product name', 'ADD1112-99', 'TEST PRODUCT DESCRIPTION SHORT ', rightState),
  createData(productimagethumb, 'NIKE', 'test product name', 'ADD1112-99', 'TEST PRODUCT DESCRIPTION SHORT ', wrongState),
  createData(productimagethumb, 'TE DEMO BRAND', 'test product name', 'ADD1112-99', 'TEST PRODUCT DESCRIPTION SHORT ', rightState),
];

const styleFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: '82%',
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "none",
  p: 4,
};

const styleReActive = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: '#fff',
  border: "3px solid #BFFF00 !important",
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const styledecline = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  border: 'none',
  p: 4,
  borderRadius: '20px'
};

const BrandProducts = () => {

  const [age, setAge] = useState('');
  const [sellersId, setSellersId] = useState([])

  const handleChange1 = (event) => {
    setAge(event.target.value);
  };

  const [logoerr, setlogoerr] = useState(null)
  const [logo, setlogo] = useState(null)
  const classes = useStyles();
  const isMounted = useRef(null)
  const [menuOpen, setMenuOpen] = useState(true)
  const [products, setProducts] = useState([])
  const [products1, setProducts1] = useState([])
  const [open, setOpen] = React.useState(false);

  const location = useLocation()

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loc, setLoc] = useState()

  // const queryParameters = new URLSearchParams(window.location.search)
  // const type = queryParameters.get("id")
  // console.log("🚀 ~ file: AdminProducts.js:214 ~ AdminProducts ~ type:", type)


  const role = window.localStorage.getItem('Squelch')
  const status = window.localStorage.getItem('isimo')

  const [sellers, setSellers] = useState([])

  const getSellers = async () => {
    try {
      const { data } = await Axios.post('/users/get_seller', '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      console.log(data, "dtas")
      if (data?.success) {
        setAge(data?.result[0]?._id)
        setSellers(data?.result)
      }
      else {
        // toast.error(data?.message)
      }

    } catch (error) {
      console.log(error, "err")
      // toast.error("error")
    }

  }

  useEffect(() => {
    if (role === 'brand') {
      getSellers()
    }
  }, [role])

  const [decrptedData, setDecrptedData] = useState()
  const secretPass = 'DtRaCeRsEcrEtFrEe'
  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt(status, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };

  useEffect(() => {
    if (role === 'subAdmin') {
      decryptData()
    }
  }, [status])

  const { id } = useParams()

  useEffect(() => {
    if (id !== "all") {
      getProductsById(id)

    } else {
      getProducts();
    }
  }, [id])

  const [click, setClick] = useState(false);
  const handleOpen = () => setClick(true);
  const handleExit = () => setClick(false);
  const [openAdminReActive, setOpenAdminReActive] = useState(false);
  const handleOpenAdminReActive = () => setOpenAdminReActive(true);
  const handleCloseAdminReActive = () => setOpenAdminReActive(false);
  const [openProductInfoAdmin, setOpenProductInfoAdmin] = useState(false);
  const [openProductInfoAdmin1, setOpenProductInfoAdmin1] = useState(false);

  const handleOpenProductInfoAdmin = () => setOpenProductInfoAdmin(true);
  const handleCloseProductInfoAdmin = () => setOpenProductInfoAdmin(false);

  const handleOpenProductInfoAdmin1 = () => setOpenProductInfoAdmin1(true);
  const handleCloseProductInfoAdmin1 = () => setOpenProductInfoAdmin1(false);

  const [openAdminPdtDeActive, setOpenAdminPdtDeActive] = useState(false);
  const handleOpenAdminPdtDeActive = () => setOpenAdminPdtDeActive(true);
  const handleCloseAdminPdtDeActive = () => setOpenAdminPdtDeActive(false);

  const [openAdminApproval, setOpenAdminApproval] = useState(false);
  const handleOpenAdminApproval = () => setOpenAdminApproval(true);
  const handleCloseAdminApproval = () => setOpenAdminApproval(false);

  const [openAdminQRProduct, setOpenAdminQRProduct] = useState(false);
  const handleOpenAdminQRProduct = () => setOpenAdminQRProduct(true);
  const handleCloseAdminQRProduct = () => setOpenAdminQRProduct(false);

  const [opendecline, setOpendecline] = React.useState(false);
  const handleOpendecline = () => setOpendecline(true);
  const handleClosedecline = () => setOpendecline(false);


  const [openDeclinePopUp, setOpenDeclinePopUp] = useState(false);
  const handleOpenDeclinePopUp = () => setOpenDeclinePopUp(true);
  const handleCloseDeclinePopUp = () => setOpenDeclinePopUp(false);


  const [openUploadPopUp, setOpenUploadPopUp] = useState(false);
  const handleOpenUploadPopUp = () => setOpenUploadPopUp(true);
  const handleCloseUploadPopUp = () => setOpenUploadPopUp(false);

  const roles = window.localStorage.getItem('Squelch')

  // useEffect(() => {

  //   if (!isMounted.current) {
  //     getProducts();
  //     isMounted.current = true;
  //   }
  //   else {
  //     console.log("fetched")
  //   }
  // }, [])


  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState()

  const [statussss, setstatusss] = useState()

  const handleChange = (event, value) => {
    setPage(value);
  };

  // useEffect(() => {
  //   getProductsa()
  // }, [page, products1])

  useEffect(() => {
    if (id !== "all") {
      getProductsById(id)
    } else {
      getProducts()
    }
  }, [page])
  const [reason, setReason] = useState([])
  const [reasons, setReasonText] = useState("")

  const setResons = (data) => {
    const dt = reason
    const check = dt.includes(data)
    if (check === false) {
      setReason([...reason, data])
    } else {
      setReason(reason.filter(item => item !== data))
    }
  }

  const setReasontext = (data) => {
    console.log(data, "reasons")
    setReason([data])
  }

  const getProductsa = async () => {
    if (products1.length > 0) {
      var currentpage = page - 1
      var dt = []
      if (currentpage === 0) {
        for (let i = 0; i < 10; i++) {
          const element = products1[i];
          if (element) {
            dt.push(element)
          }
        }
        setProducts(dt)
      } else {
        var first = (currentpage * 10) + 1;
        var second = first + 10;
        for (let i = first; i < second; i++) {
          const element = products1[i];
          if (element) {
            dt.push(element)
          }
        }
        setProducts(dt)
      }
    } else {
      setProducts([])
    }
  }

  const getProducts = async () => {
    try {
      setstatusss('Loading....')
      setProducts([])
      const { data } = await Axios.get(`/admin/get_products?limit=10&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data?.success) {
        console.log(data?.success, "success")
        if (data?.result?.docs?.length > 0) {
          setCount(data?.result?.totalPages)
          setProducts(data?.result?.docs)
        } else {
          setstatusss('No Products Available')
        }

      }
    } catch (error) {
      setstatusss('No Products Available')
      console.log(error, "err")
    }

  }

  const getProductsById = async (id) => {
    try {
      setstatusss('Loading....')
      const { data } = await Axios.post(`/admin/productByBrand?limit=10&page=${page}`, {
        id: id
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )

      console.log(data.result.docs.length, "data");
      if (data?.success) {
        if (data?.result?.docs?.length > 0) {
          // setCount(Math.ceil(data?.result?.docs?.length / 10))
          setCount(data?.result?.totalPages)
          // setProducts1(data?.result?.docs)
          setProducts(data?.result?.docs)
        } else {
          setstatusss('No Products Available')
        }

      }
    } catch (error) {
      console.log(error, "err")
    }

  }

  const [datas, setData] = useState([]);

  const [selected, setSelected] = useState()
  const [selected1, setSelected1] = useState()
  const [details, setDetails] = useState({})
  const [stscheck, setStsCheck] = useState(false)

  const [opens, setOpens] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const downloadExcelsheet = () => {

    const tableData = [
      ['Serial_No', 'product_category', 'product_identifier', 'ean', 'Product_Name', 'Initial_stock', 'Unit_of_measurement', 'Measures', 'main_image', 'Detail1_image', 'Detail2_image', 'Detail3_image', 'product_description', 'material', 'ingredients', 'departure_location', 'Cordenate_location', 'Attachments', 'Warranty', 'Duration_of_the_official_guarantee', 'Allows_warranty_extension', 'Extra_warranty_time', 'Warranty_extension_price', 'Type_of_currency', 'Physical_product_1_or_virtual_0', 'production_date', 'LOT_NUMBER', 'UN_Product_Classification_Number', 'Toxic', 'substance_type', 'composition'],

    ];
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Products.csv');

  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { defval: '-' });
      setData(parsedData);
    };
  }
  const uploadProduct = async () => {
    try {
      // const { data } = await Axios.post('/users/createproduct',
      const toastId = toast.loading('Uploading...');
      const { data } = await Axios.post('/users/productDetails',
        { products: datas },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data?.success === true) {
        handleCloses()
        handleCloseUploadPopUp()
        toast.dismiss(toastId);
        toast.success("Product Added Successfully")
        getProducts()
      }
    } catch (error) {
      console.log("🚀 ~ ~ uploadProduct ~ error:", error)
    }
  }

  useEffect(() => {
    if (datas.length > 0) {
      // uploadProduct()
      handleClickOpens()
    }
  }, [datas])

  const filterPro = (data) => {
    setProducts1(data)
    setCount(Math.ceil(data?.length / 10))
  }
  const reset = (data) => {
    console.log(data, "data")
    if (data === true) {
      if (id !== "all") {
        getProductsById(id)
      } else {
        getProducts()

      }
    }
  }

  const sts = (data) => {
    if (data === true) {
      if (id !== "all") {
        getProductsById(id)
      } else {
        getProducts()
      }
    }
  }

  const setSelection = async (data) => {
    console.log(data, "data")
    setSelected(data)
  }

  const setSelection1 = async (datas, data) => {

    setSelected1(data)
    setDetails(datas)
  }



  /*File Upload */
  const product_name = useRef()
  const brand_name = useRef()
  const category = useRef()
  const product_identifier = useRef()
  const ean = useRef()
  const initial_stock = useRef()
  const unit_measurement = useRef()
  const measurement = useRef()
  const mainimg = useRef()
  const detailimg1 = useRef()
  const detailimg2 = useRef()
  const detailimg3 = useRef()
  const product_description = useRef()
  const material = useRef()
  const ingredients = useRef()
  const depature_location = useRef()
  const coordinates = useRef()
  const license_link = useRef()
  const warrenty = useRef()
  const duration_guarantee = useRef()
  const extra_warrenty = useRef()
  const warrenty_price = useRef()
  const type_currency = useRef()
  const phy_pro_vir = useRef()
  const Production_date = useRef()
  const lot_number = useRef()
  const classification_num = useRef()
  const toxic = useRef()
  const substance_type = useRef()
  const composition = useRef()

  const [deleteId, setDeleteId] = useState()


  const submits = async () => {
    try {
      const datas = {
        Product_Name: product_name.current.value,
        product_category: category?.current?.value,
        product_identifier: product_identifier?.current?.value,
        ean: ean?.current?.value,
        brand_name: brand_name?.current?.value,
        Initial_stock: initial_stock?.current?.value,
        Unit_of_measurement: unit_measurement?.current?.value,
        Measures: measurement?.current?.value,
        main_image: mainimg?.current?.value,
        Detail1_image: detailimg1?.current?.value,
        Detail2_image: detailimg2?.current?.value,
        Detail3_image: detailimg3?.current?.value,
        product_description: product_description?.current?.value,
        material: material?.current?.value,
        ingredients: ingredients?.current?.value,
        departure_location: depature_location?.current?.value,
        Cordenate_location: coordinates?.current?.value,
        License_Attachments: license_link?.current?.value,
        Warranty: warrenty?.current?.value,
        Duration_of_the_official_guarantee: duration_guarantee?.current?.value,
        // Allows_warranty_extension: extra_warrenty?.current?.value,
        Extra_warranty_time: extra_warrenty?.current?.value,
        Warranty_extension_price: warrenty_price?.current?.value,
        Type_of_currency: type_currency?.current?.value,
        Physical_product_1_or_virtual_0: phy_pro_vir?.current?.value,
        production_date: Production_date?.current?.value,
        LOT_NUMBER: lot_number?.current?.value,
        UN_Product_Classification_Number: classification_num?.current?.value,
        Toxic: toxic?.current?.value,
        substance_type: substance_type?.current?.value,
        composition: composition?.current?.value
      }
      // const { data } = await Axios.post(`/users/createproduct`, {
      const { data } = await Axios.post(`/users/productDetails`, {
        products: [datas]
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data.success === true) {
        toast.success("Product Added Successfully")
        handleClose()
        sts(true)
      }
    } catch (error) {
      console.log("🚀 ~ submits ~ error:", error)
    }

  }

  const DeclineProduct = async () => {
    console.log("Reasons", reason)
    try {
      const { data } = await Axios.post(`/users/approveProduct`, {
        product_id: deleteId?._id,
        status: "2",
        reason: reason
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      console.log(data.result)
      if (data.success === true) {
        toast.success(data?.message)
        window.location.reload(true)
      } else {
        toast.error(data?.message)
      }
    } catch (error) {
      console.log("🚀  ~ error:", error)
    }
  }

  const generatePdf = (datas, status) => {
    console.log("Pdfss", datas, status)
    DownloadProducts(datas, status)

  }

  const setSellersIds = async () => {
    try {
      console.log(sellersId, "sellersid", age, "ages")
      const { data } = await Axios.post(`/addSellerId`, {
        product_id: sellersId,
        seller_id: age,
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      console.log("🚀 ~ file: AdminProducts.js:717 ~ setSellersIds ~ data:", data)
      if (data?.success === true) {
        toast.success(data?.message)
      }
    } catch (error) {
      console.log("🚀 ~ file: AdminProducts.js:727 ~ setSellersIds ~ error:", error)
      toast.error(error?.response?.data?.message)
    }


  }

  if ((role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[1]?.Read === true)) || (role === 'brand')) {
    return (
      <>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <div className='Admin customer address list-full-cnt dashboard-page Admin-customer-page'>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.headercls}>
                  <Header />
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.bodyclass}>
                  <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                  <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                    <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                    <AdminMenuSide />
                  </div>
                </Item>
              </Grid>



              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='padding-lr-0px-80px'>
                <Item className={classes.headercls}>
                  <div className='listbody'>
                    <div className='filter-btn'>

                      <div className='filter-filed'>
                        <div className='filter-box'>
                          <CustomerFilter filterPro={filterPro} reset={reset} loc={id} />
                          <Modal
                            open={click}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >

                            <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate AdminAddUser ">
                              <div className="menu" onClick={handleExit} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                              <div className="brand"><img src={adidasLogo} alt={'adidasLogo'} /></div>



                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='addressList'>
                                  <p className="reason">ADDRESS</p>

                                  <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block">

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='BRAND NAME'
                                            variant="outlined"
                                            className="textField"
                                            type="name"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='EMAIL'
                                            variant="outlined"
                                            className="textField"
                                            type="email"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='ADDRESS'
                                            variant="outlined"
                                            className="textField"
                                            type="address"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='WEB'
                                            variant="outlined"
                                            className="textField"
                                            type="email"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='CITY'
                                            variant="outlined"
                                            className="textField"
                                            type="name"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='NAME CONTACT PERSON'
                                            variant="outlined"
                                            className="textField"
                                            type="email"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='STATE'
                                            variant="outlined"
                                            className="textField"
                                            type="address"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='PHONE'
                                            variant="outlined"
                                            className="textField"
                                            type="email"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='COUNTRY'
                                            variant="outlined"
                                            className="textField"
                                            type="name"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                      <Item className={classes.headercls}>
                                        <div className="cmn-input-cls">
                                          <TextField
                                            id="outlined-basic"
                                            placeholder='NAME CONTACT PERSON'
                                            variant="outlined"
                                            className="textField"
                                            type="email"
                                          />
                                        </div>
                                      </Item>
                                    </Grid>

                                  </Grid>

                                </div>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listSec'>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                  <div className='info'>
                                    <div className='Bill'>
                                      <p className="reason">Billing Info</p>

                                      <div className='billingInfo'>

                                        <div className="productId">

                                          <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-billing">

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Tax identification number'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="name"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Address Billing'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="email"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Tax Name'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="name"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Status Billing'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="email"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Country Billing'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="name"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                          </Grid>


                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                                  <div className='info'>
                                    <div className='Bill'>
                                      <p className="reason">Financial info</p>

                                      <div className='billingInfo'>

                                        <div className="productId">

                                          <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-finance-info">

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Bank Account Number'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="name"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Bank Name'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="email"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Routing Number'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="name"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Swift'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="email"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                              <Item className={classes.headercls}>
                                                <div className="cmn-input-cls">
                                                  <TextField
                                                    id="outlined-basic"
                                                    placeholder='Other Information'
                                                    variant="outlined"
                                                    className="textField"
                                                    type="name"
                                                  />
                                                </div>
                                              </Item>
                                            </Grid>

                                          </Grid>


                                        </div>
                                      </div>

                                    </div>

                                  </div>

                                </Grid>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTri'>
                                <div>
                                  <p className="reason">Licensing Information</p>
                                </div>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='listTriRow'>

                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='rowOne'>

                                      <Grid container spacing={0} className={classes.containwidth} id="admin-customer-body-block-license-info">

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                licences number
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                price per licence
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                price total licenses
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                Discount applied
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                activation date
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                expiration date
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <Item className={classes.headercls}>
                                            <div className="productId">
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                className="modelTypo"
                                              >
                                                remember date
                                              </Typography>

                                              <div className="cmn-input-cls">
                                                <TextField
                                                  id="outlined-basic"
                                                  // label="Enter Your Amount "
                                                  variant="outlined"
                                                  className="textField"
                                                />
                                              </div>
                                            </div>
                                          </Item>
                                        </Grid>

                                      </Grid>

                                    </div>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='rightSide'>
                                      <div className='choice'>
                                        <div className="warn">
                                          <div className="statusList"><Checkbox className="checkBox" />BUSINESS</div>
                                          <div className="statusList"><Checkbox className="checkBox" />FREELANCERS</div>
                                        </div>
                                      </div>

                                      <div className='mailActive'>
                                        <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          className="modelTypo"
                                        >
                                          Brand user email seperate for ,
                                        </Typography>

                                        <div className='input-box'>
                                          <TextField className='field-box'
                                            required
                                            multiline
                                            rows={5}
                                            rowsMax={10}
                                            variant="outlined"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>


                              <div className="butOutLayer">
                                <Button className='edit'>edit info</Button>
                                <Button className='create'>Create brand</Button>
                              </div>
                            </Box>
                          </Modal>
                        </div>

                        {
                          (roles === 'brand') ?
                            <div className='profile-image-outer excel-file-upload'>
                              {logoerr ? <div className='h3' style={{ color: 'red', textAlign: 'left' }}>{logoerr}</div> : <></>}
                              <div className='profile-image profile-image-design-block'>
                                <form>
                                  <input type="file" accept=".csv, .xlsx, .xls"
                                    onChange={handleFileUpload} className='input-field' hidden
                                  // onChange={(e) => { createLogo(e) }}
                                  />

                                </form>
                              </div>
                              {/* <div className='edit-image-btn' onClick={() => document.querySelector(".input-field").click()}>
                                <Button variant="contained" endIcon={<BorderColorIcon />}>
                                  Upload
                                </Button>
  
                              </div> */}
                              <div className='add-seller'>
                                <div className='filter-box account'>
                                  <Button className='filter-btn button' onClick={handleOpenUploadPopUp} ><BorderColorIcon />Upload</Button>
                                </div>

                                <Modal
                                  open={openUploadPopUp}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >

                                  <Box sx={style} className="popBOX add-seller product-upload-popup">
                                    <div className="menu" onClick={handleCloseUploadPopUp} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                                    <div className="headTitle">Create New Product</div>
                                    <div className='download' >

                                      <h6>Sample Document <span> (CSV,XL) </span> to Upload the Product details</h6>

                                      <div>
                                        <p>Note: <span>The product details should be in the same format as the downloaded Csv file</span></p>
                                      </div>
                                      <Button variant="contained" endIcon={<CloudDownloadIcon />} onClick={() => { downloadExcelsheet() }}>
                                        Download
                                      </Button>
                                    </div>
                                    <div className='upload'>

                                      <h6>Upload the product details here<span>(CSV,XL)</span></h6>

                                      <div>
                                        <p>Note:<span>The product details should be in the same format as the downloaded Csv file</span> </p>
                                      </div>
                                      <Button variant="contained" endIcon={<BorderColorIcon />} onClick={() => document.querySelector(".input-field").click()}>
                                        Upload
                                      </Button>

                                    </div>



                                    {/* {<Modal
                open={createopen}
                onClose={createhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX add-seller">
  
                <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
  
  
                <div className="headTitle">Add Staff</div>
                  <div className="listOne">
  
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                      >
                      seller's name
                      </Typography>
  
                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Bharath"
                          className="textField"
                        />
                      </div>
                    </div>
  
                    <div className="productId">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="modelTypo"
                      >
                      Seller delta
                      </Typography>
  
                      <div className="cmn-input-cls">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="kumar"
                          className="textField"
                        />
                      </div>
                    </div>
  
                  </div>
  
  
                  <div className="listOne">
  
                    <div className='SellerPaidTable'>
                        <Table sx={{ minWidth: 550 }} aria-label="customized table">
                              <TableHead className='SellerTableHead'>
                              <TableRow className='SellerTableRow'>
                                <StyledTableCell className="head-part-table">user </StyledTableCell>
                                <StyledTableCell className="head-part-table">password</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className='SellerTableBody'>
                            {rows.map((row) => (
                              <StyledTableRow key={row.name}>
                                <StyledTableCell className="table-value">{row.dueDate}</StyledTableCell>
                                <StyledTableCell  className="table-value">{row.service}</StyledTableCell>
  
                              </StyledTableRow>
  
                              
                            ))}
                          </TableBody>
                      </Table>
                      </div>
                  </div>
  
                  <div className="add-staff">
                    <div className='user-txt'>
                        <h4>User</h4>
                        <p>If you don't know the name of the staff, leave it blank and we will create a unique ID for that staff.</p>
                      </div>
                      
                      <div className='user-txt'>
                        <h4>Password</h4>
                        <p>If you do not know what password to assign, leave the space blank and we will assign a random password for each staff</p>
                      </div>
                    </div>
  
                <div className="filter-box account add">
                    <Button onClick={createhandleClose} className='cancel'>Cancel</Button>
                    <Button onClick={storehandleOpen}>Generate user</Button>
                </div>
  
                </Box>
              </Modal>} */}


                                    {/* {<Modal
                open={storeopen}
                onClose={storehandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX add-seller">
  
                <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
  
  
                <div className="headTitle">SELLER STORE DELTA</div>
  
                  <div className="listOne">
  
                    <div className='SellerPaidTable'>
                        <Table sx={{ minWidth: 550 }} aria-label="customized table">
                              <TableHead className='SellerTableHead'>
                              <TableRow className='SellerTableRow'>
                                <StyledTableCell className="head-part-table">staff number </StyledTableCell>
                                <StyledTableCell className="head-part-table">id user</StyledTableCell>
                                <StyledTableCell className="head-part-table">password</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className='SellerTableBody'>
                            {storerows.map((storerow) => (
                              <StyledTableRow key={storerow.name}>
                                <StyledTableCell className="table-value">{storerow.dueDate}</StyledTableCell>
                                <StyledTableCell  className="table-value">{storerow.service}</StyledTableCell>
                                <StyledTableCell  className="table-value">{storerow.password}<RefreshIcon/></StyledTableCell>
                              </StyledTableRow>
  
                              
                            ))}
                          </TableBody>
                      </Table>
                      </div>
                  </div>
  
  
                <div className="filter-box account add">
                    <Button onClick={deltahandleOpen}>send by email,the passwords to the seller</Button>
                    <Button className='cancel'>Generate all password</Button>
                </div>
  
                </Box>
              </Modal>} */}


                                    {/* {<Modal
                open={deltaopen}
                onClose={deltahandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX add-seller">
  
                <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
  
  
                <div className="headTitle">SELLER STORE DELTA</div>
  
                  <div className="message">
                      <img src={send} alt="closeMenu" />
                      <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                  </div>
  
  
                <div className="filter-box account add">
                    <Button>send by email,the passwords to the seller</Button>
                    <Button className='cancel'>Generate all password</Button>
                </div>
  
                </Box>
              </Modal>} */}


                                  </Box>
                                </Modal>

                              </div>
                            </div> :
                            <></>
                        }


                        {/* <Button variant="contained">Export
                          <input
                            hidden={true}
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={() => { handleFileUpload() }}
  
                          />
                          <img src={Files} alt={Files} />
  
                        </Button> */}
                        {/* <input
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
  
                        /> */}
                      </div>
                    </div>
                    {
                      (roles === 'admin') || (roles === 'brand') ?
                        <div>
                          <AddSellersPopup sts={sts} />
                        </div>
                        : <></>
                    }

                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='padding-lr-0px-80px'>
                <div className='tablebody admin-customer-table-main admin-pdts-table-main-block'>
                  <Item className={classes.dashboargraph} id="earnings-sales">
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='tableScroll'>
                        <TableHead>
                          <TableRow className='row-head row-head-product-admin'>
                            <TableCell className='head-bg'></TableCell>
                            <TableCell className='head-bg'>product image</TableCell>
                            <TableCell className='head-bg'>brand name</TableCell>
                            <TableCell className='head-bg'>product name</TableCell>
                            {/* <TableCell className='head-bg'>Internal REF</TableCell> */}
                            <TableCell className='head-bg'>Short description</TableCell>
                            <TableCell className='head-bg'>Initial Stock</TableCell>
                            <TableCell className='head-bg'>Status</TableCell>
                            <TableCell className='head-bg'>Action</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {products?.length > 0 ? products?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className='body-bg'><Checkbox {...label} /></TableCell>
                              <TableCell component="th" scope="row" className='body-bg'>

                                <div className='thumbalin'><img src={row?.product_details[0]?.main_image} alt={"brandLogo"} />
                                </div>
                              </TableCell>
                              <TableCell className='body-bg'>{row?.brand_name ? row?.brand_name : '-'}</TableCell>
                              <TableCell className='body-bg'>{row?.product_details[0]?.Product_Name ? row?.product_details[0]?.Product_Name : '-'}</TableCell>
                              {/* <TableCell className='body-bg'>{row?.refint}</TableCell> */}
                              <TableCell className='body-bg'>{row?.product_details[0]?.product_description ? row?.product_details[0]?.product_description?.split(/\s+/).slice(0, 10).join(" ") : '-'}</TableCell>
                              <TableCell className='body-bg'>{row?.Initial_stock ? row?.Initial_stock : '-'}</TableCell>
                              <TableCell className='body-bg'>

                                {
                                  row?.status === '0' ?
                                    <p>Pending..</p>
                                    : <>
                                      {
                                        row?.status === '1' ?
                                          <p style={{ color: 'green' }} >Approved</p>
                                          :
                                          <p style={{ color: 'red' }} >Declined</p>
                                      }
                                    </>
                                }
                              </TableCell>
                              <TableCell className='body-bg'>
                                <div className='icon-space'>
                                  {/* <div>
                                {
                                  (row?.status === '1') && ((roles === 'brand') || (roles === 'admin')) ?
                                    <Button className="activate">
                                      <div onClick={() => { handleOpenAdminQRProduct(); setSelection1(row?.product_details) }} className='qrbar-code'><img src={qrbar} alt="qrbar" /></div>
                                    </Button>
                                    :
                                    <></>
                                }
                              </div> */}
                                  <Button onClick={() => { handleOpenProductInfoAdmin1(); setSelection1(row, row?.product_details) }} className="activate qr-detail"><InfoOutlinedIcon /></Button>
                                  {
                                    (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[1]?.Write === true)) ?
                                      <>
                                        {
                                          (roles === 'admin') || (roles === 'subAdmin') ?
                                            <Link to='/products-approved' state={{ data: row }} ><img src={approve} alt={approve} /></Link>
                                            :
                                            <></>
                                        }
                                        {
                                          (roles === 'admin') || (roles === 'subAdmin') ?
                                            <div className='wdwtc'>
                                              <Link onClick={() => { handleOpenDeclinePopUp(); setDeleteId(row) }}><img src={decline} alt={decline} /></Link>
                                            </div>
                                            :
                                            <></>
                                        }
                                      </>
                                      :
                                      <>
                                      </>
                                  }




                                  {/* <Modal
                                open={opendecline}
                                onClose={handleClosedecline}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styledecline}>
                                  <div className='CloseOption-pop-up' onClick={handleClosedecline}><HighlightOffIcon /></div>
                                  <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                    Select the type of imported product
                                  </Typography>
                                </Box>
                              </Modal> */}



                                  {/* <Button onClick={handleOpenAdminReActive}><img src={box} alt={box} /></Button>
                  <Button onClick={handleOpenAdminPdtDeActive} className="activate"><img src={cross} alt={cross} /></Button>
                  <Button onClick={handleOpenAdminApproval} className="activate"><img src={rightState} alt={rightState} /></Button> */}

                                </div>
                              </TableCell>


                            </TableRow>
                          ))
                            :
                            <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>
                            // <h3 style={{ textAlign: 'center' }} >{statussss}</h3>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>


                  <div className='pagination-numb'>
                    <Pagination count={count} page={page} onChange={handleChange} color="primary" shape="rounded" />
                    {/* <Pagination count={10} color="primary" shape="rounded" /> */}
                  </div>

                </div>
              </Grid>



              <Modal
                open={openAdminPdtDeActive}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <Box sx={styleFilter} className="AdminCustomerpopBOX Deactivate Deactivate-user-popup">
                  <div className="menu" onClick={handleCloseAdminPdtDeActive} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                  <div className="brand"><img src={adidasLogo} alt={'adidasLogo'} /></div>

                  <div className="h2Variant">deactivate user</div>

                  <p className="reason">Reason</p>


                  <div className="listThree">
                    <div className="warn">
                      <div className="statusList"><Checkbox className="checkBox" />No license renewal</div>
                      <div className="statusList"><Checkbox className="checkBox" />not satisfied with the service</div>
                      <div className="statusList"><Checkbox className="checkBox" />temporary suspension</div>
                      <div className="statusList"><Checkbox className="checkBox" />temporary penalty</div>
                      <div className="statusList"><Checkbox className="checkBox" />others</div>
                    </div>

                  </div>


                  <div className='input-box'>
                    <TextField className='field-box'
                      required
                      multiline
                      rows={5}
                      rowsMax={10}
                      width={100}
                      variant="outlined"
                    />
                  </div>
                  <div className="butOutLayer">
                    <Button className='deactive'>Deactivate User And All Its Products</Button>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={openAdminReActive}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <Box sx={style} className="popBOX BlockPopup rejectpop approve">
                  <div className="menu" onClick={handleCloseAdminReActive} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                  <div className="reject-info">
                    <div className="rejection-indication">
                      <p className="reject-head">REACTIVATE PRODUCT</p>
                      <p className="reason-reject approval-txt">You are about to reactivate the product, this will leave it in the same state
                        it was in before it was blocked.</p>
                    </div>
                  </div>




                  <div className="decline-cancel-btn">

                    <div className="cancel-button">
                      <Button className="approve">Confirm</Button>
                    </div>
                  </div>

                </Box>
              </Modal>

              {<Modal open={openDeclinePopUp} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">

                <Box sx={style} className="popBOX BlockPopup requpdate">
                  <div className="menu" onClick={handleCloseDeclinePopUp}>
                    <Link><img src={closeMenu} alt="closeMenu" /></Link>
                  </div>

                  <div className="reject-info">
                    <div className="rejection-indication">
                      <p className="reject-head">Request product update</p>
                      {/* <p className="reason-reject">please inform the brand why you need to update your product before being approved</p> */}
                    </div>
                  </div>

                  <div className="check-list">
                    <ul>
                      <li>
                        <Checkbox {...label} className="box-border" onClick={() => { setResons('The description does not match the product image') }} />The description does not match the product image</li>
                      <li>
                        <Checkbox {...label} className="box-border" onClick={() => { setResons('Product image does not match description') }} />Product image does not match description</li>
                      <li>
                        <Checkbox {...label} className="box-border" onClick={() => { setResons('We have not been able to verify the authenticity of manufacture') }} />We have not been able to verify the authenticity of manufacture</li>
                      <li>
                        <Checkbox {...label} className="box-border" onClick={() => { setResons('The product is duplicated') }} />The product is duplicated</li>
                      <li>
                        <Checkbox {...label} className="box-border" onClick={() => { setResons('The product appears as the owner of another brand') }} />The product appears as the owner of another brand</li>
                    </ul>
                  </div>

                  <div className="text-box">
                    <TextField className="box-size" id="outlined-multiline-static" multiline="multiline" onChange={(e) => { setReasontext(e.target.value) }} rows={5} placeholder="Other reasons, please specify the reason" />
                  </div>

                  <div className="decline-cancel-btn">
                    {/* <div className="decline-btn">
                                                            <Button className="req-up"><ReplayOutlinedIcon />Request update</Button>
                                                          </div> */
                    }

                    <div className='decline-cancel-btn'>
                      <Button className="approve-tick" onClick={() => { DeclineProduct() }} >Decline</Button>
                      <Button className="wrong-tick">Cancel</Button>
                    </div>

                  </div>

                </Box>
              </Modal>}

              <Modal
                open={openProductInfoAdmin}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <Box sx={style} className="popBOX BlockPopup infopopup">
                  <div className="menu" onClick={handleCloseProductInfoAdmin} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                  <div className="product-qr-info">
                    <div className="product-info-head">
                      <p className="product-code-head">Product info</p>
                      <p className="pro-id">PRODUCT ID - {selected?.product_id}</p>
                    </div>

                    <div className="info-product-scan">
                      <img src={selected?.main_image} alt='mainImage' />
                    </div>
                    <p className="lv-bag">{selected?.Product_Name}</p>
                    <div className="productinfo-img-bag">
                      {selected?.Detail1_image &&
                        <div className="info-bag">
                          <img src={selected?.Detail1_image} alt='product' />
                        </div>
                      }
                      {selected?.Detail2_image &&
                        <div className="info-bag">
                          <img src={selected?.Detail2_image} alt='product' />
                        </div>
                      }
                      {
                        selected?.Detail3_image &&
                        <div className="info-bag">
                          <img src={selected?.Detail3_image} alt='product' />
                        </div>
                      }

                      {/* <div className="info-bag">
                        <img src={QrBag} alt={QrBag} />
                      </div> */}
                    </div>

                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                        <div className="special-bag">
                          {/* <p>{selected?.Product_Name}</p> */}
                          {/* <div>Description</div> */}
                          <p className="product-info-title">Description</p>
                          {/* <p className="lv-made">Louis vuitton handbag made with cowhide</p> */}
                          <p className="cristin-para">{selected?.product_description}</p>
                        </div>
                        <div className="ean-info">
                          <div className="ean-cust">
                            <p className="product-info-title">EAN</p>
                            <p>{selected?.ean ? selected?.ean : '-'}</p>
                          </div>
                          <div className="ean-cust">
                            <p className="product-info-title">Ref customer</p>
                            <p>D1113s</p>
                          </div>
                          <div className="ean-cust">
                            <p className="product-info-title">Brand:</p>
                            <p>{selected?.brand_name ? selected?.brand_name : '-'} </p>
                          </div>
                          <div className="ean-cust">
                            <p className="product-info-title">Initial stock</p>
                            <p> {selected?.Initial_stock ? selected?.Initial_stock : '-'}</p>
                          </div>
                        </div>

                        <div className="ean-info">
                          <div className="ean-cust">
                            <p className="product-info-title">measures</p>
                            <p>{selected?.Measures ? selected?.Measures : '-'} </p>
                          </div>
                          <div className="ean-cust">
                            <p className="product-info-title">Material</p>
                            <p> {selected?.material ? selected?.material : '-'} </p>
                          </div>
                          <div className="ean-cust">
                            <p className="product-info-title">unit of measurement</p>
                            <p>{selected?.Unit_of_measurement ? selected?.Unit_of_measurement : '-'} </p>
                          </div>

                        </div>

                        <div className="ean-info">
                          <div className="ean-cust">
                            <p className="product-info-title">warranty</p>
                            <p>{selected?.Duration_of_the_official_guarantee ? selected?.Duration_of_the_official_guarantee : '-'} </p>
                          </div>
                          {
                            roles === 'admin' &&
                            <div className="ean-cust">
                              <p className="product-info-title">License attachments</p>
                              {/* <p> <a href={selected?.License_Attachments} > Download license</a></p> */}
                              <p> <Link to={selected?.License_Attachments} target="_blank" download>Download license</Link></p>
                            </div>
                          }
                        </div>

                        <div className="location-info">
                          <p className="product-info-title">Location</p>
                          <p>Passeig de gracia 88, Barcelona, CP 08008 click to see on the map</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                        <div className="category-sctn info-category">
                          <div className="category-info">
                            <p className="product-client-id">Client ID <span> XXX11122S</span></p>
                          </div>
                          <div className="category-info">
                            <p className="product-client-id">ID PRODUCT<span> {selected?.product_id ? selected?.product_id : '-'}</span></p>
                          </div>

                          <div className="category-info">
                            <p className="product-client-id">Category <span>
                              {selected?.product_category ? selected?.product_category : '-'}</span></p>
                          </div>
                          {console.log(details, "detailsss")}
                          <div className="pend-approved-btn">
                            {
                              details?.status === '0' ?
                                <div className="pending-appr-button">PENDING APROVAL</div>
                                :
                                <>
                                  {
                                    details?.status === '1' ?
                                      <div className="approve-tick">Approved</div>
                                      :
                                      <>
                                        <div className="reason-info">
                                          <p className="product-client-id">Reason</p>

                                          {details?.reason?.length > 0 && details?.reason?.map((value, index) => (
                                            <p className="product-client-id" key={index}>
                                              <span>


                                                {value ? value : '-'}
                                              </span></p>
                                          ))}

                                        </div>
                                        <div className="wrong-tick">Declined</div>
                                      </>
                                  }</>

                            }
                          </div>

                          <div className="category-info">
                            <p className="product-client-id">Blockchain info NO AVAILABLE</p>
                          </div>

                          <div className="buttons-approve">
                            {/* <div>
                              <Button className="approve-tick">Approval</Button>
                            </div>
                            <div>
                              <Button className="wrong-tick">DECLINE</Button>
                            </div> */}

                            {
                              details?.status === '0' ?
                                <div className="pending-appr-button">PENDING APROVAL</div>
                                :
                                <>
                                  {
                                    details?.status === '1' ?
                                      <div className="approve-tick">Approved</div>
                                      : <div className="wrong-tick">Declined</div>
                                  }</>

                            }
                          </div>
                        </div>


                      </Grid>

                    </Grid>

                    <div className="warranty-extension">
                      <div className="warranty-yrs">
                        <p className="product-info-title">Warranty:</p>
                        <p className="blue-msg">{selected?.Duration_of_the_official_guarantee ? selected?.Duration_of_the_official_guarantee : '-'} </p>
                      </div>
                      <div className="warranty-yrs">
                        <p className="product-info-title">Extension permit</p>
                        <p className="blue-msg">{selected?.Allows_warranty_extension}, {selected?.Extra_warranty_time}</p>
                      </div>
                      <div className="warranty-yrs">
                        <p className="product-info-title">Price Extension Warranty</p>
                        <p className="blue-msg">{selected?.Warranty_extension_price ? selected?.Warranty_extension_price : '-'} {selected?.Type_of_currency ? selected?.Type_of_currency : '-'} </p>
                      </div>
                      <div className="warranty-yrs">
                        <p className="product-info-title">Warranty extension applied</p>
                        {selected?.Allows_warranty_extension === "yes" || selected?.Allows_warranty_extension === "Yes" ? <p className="blue-msg">Yes, {selected?.userExtenWarrenty}</p> : <></>}
                      </div>
                    </div>

                    <div className="edit-doenlod-info">
                      {
                        roles === 'brand' ?
                          <div>

                            <Button className="edit-info">Edit info</Button>
                          </div> :
                          <></>
                      }

                      <div>
                        <Button className="down-info" onClick={(e) => generatePdf(selected, details?.status)}>Download info</Button>
                      </div>
                    </div>




                  </div>



                </Box>
              </Modal>

              <Modal
                open={openProductInfoAdmin1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="popBOX BlockPopup infopopup">
                  <div className="menu" onClick={handleCloseProductInfoAdmin1} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='tablebody admin-customer-table-main admin-pdts-table-main-block'>
                      <div className='dashboargraph-inner-top'>
                        {/* {
                          roles === 'brand' ?
                            <div className='product-allocate-btn'>
                              <FormControl sx={{ width: "auto" }}>

                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  defaultValue={age}
                                  onChange={handleChange1}
                                >
                                  {
                                    sellers?.map((item) => {
                                      return (
                                        <MenuItem value={item?._id}>{item?.firstname}</MenuItem>
                                      )
                                    })
                                  }
                                </Select>
                              </FormControl>
                              <Button onClick={() => { setSellersIds() }} >Product allocation</Button>
                            </div>
                            :
                            <></>
                        } */}

                        <Item className={classes.dashboargraph} id="earnings-sales">

                          <TableContainer sx={{ maxHeight: 640 }}>
                            <Table aria-label="simple table" className='tableScroll'>
                              <TableHead>
                                <TableRow className='row-head row-head-product-admin'>
                                  <TableCell className='head-bg'></TableCell>
                                  <TableCell className='head-bg'>product image</TableCell>
                                  <TableCell className='head-bg'>brand name</TableCell>
                                  <TableCell className='head-bg'>product name</TableCell>
                                  {/* <TableCell className='head-bg'>Internal REF</TableCell> */}
                                  <TableCell className='head-bg'>Short description</TableCell>
                                  <TableCell className='head-bg'>Status</TableCell>
                                  <TableCell className='head-bg'>Sold</TableCell>
                                  <TableCell className='head-bg'>Action</TableCell>

                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selected1?.length > 0 ? selected1?.map((row, index) => (
                                  <TableRow key={index}>
                                    {console.log(row, "rows")}
                                    <TableCell className='body-bg' onClick={() => { console.log(row?.product_id, 'one'); setSellersId([...sellersId, row?.product_id]) }} ><Checkbox {...label} /></TableCell>

                                    <TableCell component="th" scope="row" className='body-bg'>
                                      <div className='thumbalin'><img src={row?.main_image} alt={"brandLogo"} />
                                      </div>
                                    </TableCell>
                                    <TableCell className='body-bg'>{row?.brand_name ? row?.brand_name : '-'}</TableCell>
                                    <TableCell className='body-bg'>{row?.Product_Name ? row?.Product_Name : '-'}</TableCell>
                                    {/* <TableCell className='body-bg'>{row?.refint}</TableCell> */}

                                    <TableCell className='body-bg'>{row?.product_description ? row?.product_description?.split(/\s+/).slice(0, 10).join(" ") : '-'}</TableCell>
                                    <TableCell className='body-bg'>
                                      {
                                        details?.status === '0' ?
                                          <p>Pending..</p>
                                          : <>
                                            {
                                              details?.status === '1' ?
                                                <p style={{ color: 'green' }} >Approved</p>
                                                :
                                                <p style={{ color: 'red' }} >Declined</p>
                                            }
                                          </>
                                      }
                                    </TableCell>
                                    <TableCell className='body-bg'>{row?.isSold === true ? "Sold" : "Not Sold"}</TableCell>
                                    <TableCell className='body-bg'>
                                      <div className='icon-space'>
                                        <div>
                                          {
                                            (details?.status === '1') && ((roles === 'brand') || (roles === 'admin')) ?
                                              <Button className="activate">
                                                <div onClick={() => { handleOpenAdminQRProduct(); setSelection(row) }} className='qrbar-code'><img src={qrbar} alt="qrbar" /></div>
                                              </Button>
                                              :
                                              <></>
                                          }
                                        </div>
                                        <Button onClick={() => { handleOpenProductInfoAdmin(); setSelection(row) }} className="activate qr-detail"><InfoOutlinedIcon /></Button>
                                        {/* {
                                    roles === 'admin' ?
                                      <Link to='/products-approved' state={{ data: row }} ><img src={approve} alt={approve} /></Link>
                                      :
                                      <></>
                                  }
                                  {
                                    (roles === 'admin') ?
                                      <div className='wdwtc'>
                                        <Link onClick={handleOpenDeclinePopUp}><img src={decline} alt={decline} /></Link>
                                      </div>
                                      :
                                      <></>
                                  } */}



                                        {/* <Modal
                                open={opendecline}
                                onClose={handleClosedecline}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={styledecline}>
                                  <div className='CloseOption-pop-up' onClick={handleClosedecline}><HighlightOffIcon /></div>
                                  <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                    Select the type of imported product
                                  </Typography>
                                </Box>
                              </Modal> */}



                                        {/* <Button onClick={handleOpenAdminReActive}><img src={box} alt={box} /></Button>
                  <Button onClick={handleOpenAdminPdtDeActive} className="activate"><img src={cross} alt={cross} /></Button>
                  <Button onClick={handleOpenAdminApproval} className="activate"><img src={rightState} alt={rightState} /></Button> */}

                                      </div>
                                    </TableCell>


                                  </TableRow>
                                ))
                                  :
                                  <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statussss}</h3></TableCell>
                                  // <h3 style={{ textAlign: 'center' }} >{statussss}</h3>
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Item>
                      </div>


                      <div className='pagination-numb'>
                        {/* <Pagination count={count} page={page} onChange={handleChange} color="primary" shape="rounded" /> */}
                        {/* <Pagination count={10} color="primary" shape="rounded" /> */}
                      </div>

                    </div>
                  </Grid>
                </Box>
              </Modal>


              <Modal
                open={openAdminApproval}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <Box sx={style} className="popBOX BlockPopup rejectpop approve">
                  <div className="menu" onClick={handleCloseAdminApproval} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                  <div className="reject-info">
                    <div className="rejection-indication">
                      <p className="reject-head">Product Approval</p>
                      <p className="reason-reject approval-txt">You are going to approve the products “$ product” , this action generates a new QR
                        Code for the product, which then will be recorded as a block in the
                        blockchain, are you sure of this action?</p>
                    </div>
                  </div>




                  <div className="decline-cancel-btn">
                    <div className="decline-btn">
                      <Button className="cancel">Cancel</Button>
                    </div>
                    <div className="cancel-button">
                      <Button className="approve">Approval</Button>
                    </div>
                  </div>

                </Box>
              </Modal>

              <Modal
                open={openAdminQRProduct}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <Box sx={style} className="popBOX BlockPopup qrproduct-pop">
                  <div className="menu" onClick={handleCloseAdminQRProduct} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                  <div className="product-qr-info">
                    <div className="product-info-head">
                      <p className="product-code-head">QR product info</p>
                      <p className="pro-id">PRODUCT ID {selected?.product_id}</p>
                    </div>

                    <div className="qr-product-scan">
                      {/* <QRCode
                        title="Dtracer"
                        value={selected?.qr_link}
                      // bgColor={back}
                      // fgColor={fore}
                      // size={size === '' ? 0 : size}
                      /> */}
                      <img src={selected?.qr_link} alt={'qr'} />
                    </div>

                    <div className="product-img-bag">
                      <div className="info-bag">
                        <img src={selected?.main_image} alt={QrBag} />
                      </div>
                      {selected?.Detail1_image ?
                        <div className="info-bag">
                          <img src={selected?.Detail1_image} alt={QrBag} />
                        </div>
                        :
                        <></>
                      }
                      {
                        selected?.Detail2_image ?
                          <div className="info-bag">
                            <img src={selected?.Detail2_image} alt={QrBag} />
                          </div> :
                          <></>
                      }
                      {
                        selected?.Detail3_image ?
                          <div className="info-bag">
                            <img src={selected?.Detail3_image} alt={QrBag} />
                          </div>
                          :
                          <></>
                      }


                    </div>

                    <div className="category-sctn">
                      <div className="category-info">
                        <p className="product-client-id">Client ID <span> XXX11122S</span></p>
                      </div>
                      <div className="category-info">
                        <p className="product-client-id">ID PRODUCT<span> {selected?.product_id ? selected?.product_id : '-'}</span></p>
                      </div>

                      <div className="category-info">
                        <p className="product-client-id">Category <span> {selected?.product_category?.selected?.product_category}</span></p>
                      </div>

                      <div className="approved-btn">
                        {
                          selected?.status === '0' ?
                            <Button className="approve-button">Pending</Button>
                            :
                            <>
                              {
                                selected?.status === '1' ?
                                  <Button className="approve-button">Approved</Button>
                                  :
                                  <Button className="approve-button">Blocked</Button>

                              }
                            </>
                        }
                      </div>

                      <div className="category-info">
                        <p className="product-client-id">Blockchain info <Link to={`${consts?.URL}/tx/${selected?.TxHash}`} target="_blank" className="url-link">url link</Link></p>
                      </div>
                    </div>

                    <div className="action-part">
                      <p className="action-head">ACTION</p>
                      <p className="action-part-cnt">With this action you can generate a certificate of authenticity and send it to the client who has requested it,</p>
                      <p className="action-info">Warning: Do not generate any certificate without having received the payment for it</p>
                    </div>

                    <div className="generate-btns">
                      <div className="certificate-btn">
                        <Button>GENEREATE CERTIFICATE</Button>
                      </div>
                      <div className="certificate-btn qr-code">
                        <Button>Send QR code</Button>
                      </div>
                      <div className="certificate-btn print-btns">
                        <Button>Print</Button>
                      </div>
                    </div>

                    <div className="email-input-box">
                      <div className="email-certificate">
                        <div className="certificate-box">
                          <p className="email-send">Enter email where the certificate will be sent</p>
                        </div>
                        <div className="input-email">
                          <TextField className="input-txt-box" id="outlined-password-input" placeholder="Email" type="email" />
                          <div className="default-acct">
                            <p className="default-para">Use default account email</p>
                            <Checkbox {...label} />
                          </div>
                          <div className="approved-btn">
                            <Button className="approve-button">Send certificate</Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="successfull-certificate">
                      <div className="thumbs-up">
                        <img src={Thumbup} alt={Thumbup} />
                      </div>

                      <p className="success-msg">Certificate successfully sent to applicant</p>
                    </div>



                  </div>



                </Box>
              </Modal>
              {
                datas.length > 0 &&
                <Dialog
                  fullScreen={fullScreen}
                  open={opens}
                  onClose={handleCloses}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Products"}
                  </DialogTitle>
                  <DialogContent>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {Object.keys(datas[0])?.map((key) => (
                              <TableCell key={key}>{key}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {datas.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {Object.values(row).map((value, index) => (
                                <TableCell component="th" scope="row" key={index}>
                                  {value}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DialogContent>
                  <DialogActions className='two-btn'>
                    <Button autoFocus onClick={handleCloses} className='cancel'>
                      Cancel
                    </Button>
                    {
                      stscheck === false ?
                        <Button onClick={() => { setStsCheck(true); uploadProduct() }} autoFocus disabled={stscheck} className='add-btn'>
                          Add
                        </Button> :
                        <Button autoFocus disabled={stscheck} className='add-btn'>
                          Add
                        </Button>
                    }

                  </DialogActions>
                </Dialog>
              }


              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.footercls}>
                  <Footer />
                </Item>
              </Grid>

            </Grid>
          </Box>
        </div >
      </>
    )
  } else {
    return (
      <div className='Admin customer address list-full-cnt dashboard-page Admin-customer-page'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.bodyclass}>
                <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                  <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                  <AdminMenuSide />
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='premission-access'>You Dont Have a Permission to View Products</div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }

}

export default BrandProducts;
