import React, {useState} from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './AdminTraceability.css';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import togglebtn from '../../../img/togglebtn.png';
import Menuclose from '../../../img/Menuclose.png';
import locationMark from '../../../img/locationMark.png';
import Pagination from '@mui/material/Pagination';
import ProductImage from "../../../img/thumbLogo.svg";
import downloadIcon from "../../../img/downloadIcon.svg";
import group from "../../../img/group.svg";
import AdminMenuSide from '../AdminMenuSide';



  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow:'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)' : {
            padding: '30px 0 !important',
          }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow:'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow:'none !important',
        position: 'relative'
    },

    reasonBlock:{
        fontSize:'24px',
        fontWeight:'600'
    }
  });

  function createData(
    loc,
    pdtimg,
    id,
    startingPoint,
    cationpoint,
    lastMove,
    action1, 
    action2
  ) {
    return {loc, pdtimg, id, startingPoint, cationpoint, lastMove, action1, action2};
  }

  const rows = [
    createData(locationMark, ProductImage, 'ADD-11234-11', '120 Av. Washiton st , USA', 'Exit', 'C/ MADRID 122, ESP',downloadIcon,group),
    createData('', '', '', '', 'ACTIVATION', 'C/ Buenos aires 3, BCN, ESP', '', ''),
    createData('', '', '', '', 'Certificate Creation', 'AV.Monaco 1, Monaco','', ''),
    createData('', '', '', '', 'QR code reading', 'PLZ.Milano 100, Roma, ITA', '', ''),
  ];

const AdminTraceability2 = () => {
    const classes = useStyles();

 const [menuOpen, setMenuOpen] = useState(true)

 const openMenuHandler = () => {
    setMenuOpen(false)
 }

 const closeMenuHandler = () => {
    setMenuOpen(true)
 }


  return (
    
    <div className='TrabiliDadMainPage TrabiliDad2 adminTraceability-main-page'>

<Box sx={{ flexGrow: 1 }}>

<Grid container> 

         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Item className={classes.headercls}>
        <Header />
    </Item>
    </Grid>

       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Item className={classes.bodyclass}>
          <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn"/></div>
          <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
          <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose"/></div>
          <AdminMenuSide/>
          </div>
        
          </Item>
      </Grid>
 


       <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='Title select-a-product-head'>

        <div className='leftFirstButton'>
         {/* <div className='filter-box'><PostSalesFilterPopup /></div> */}
         <Button className="filterBtn">
         <SearchIcon />
         &nbsp; Search
        </Button>

        <div className='topTitle'>select a product</div>

        </div>
        </Grid>

       <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='select-a-product-location'>

            <Grid container> 

            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Item id="iframe-map-1" className={classes.headercls}>
            <iframe src="https://www.google.com/maps/d/embed?mid=1hjs3mIoZBblBP_CvxiP4w38STiY&hl=en_US&ehbc=2E312F" />
            </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Item id="iframe-map-2" className={classes.headercls}>
            <iframe src="https://www.google.com/maps/d/embed?mid=1hjs3mIoZBblBP_CvxiP4w38STiY&hl=en_US&ehbc=2E312F" />
            </Item>
            </Grid>

            </Grid>

        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='select-a-product-location'>
        <div className='InvoicePaidTable'>
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className='invoiceTableHead'>
            <TableRow className='invoiceTableRow'>
              <StyledTableCell className="head-part-table">mark on the map</StyledTableCell>
              <StyledTableCell className="head-part-table">product image</StyledTableCell>
              <StyledTableCell className="head-part-table">Product ID</StyledTableCell>
              <StyledTableCell className="head-part-table">starting point</StyledTableCell>
              <StyledTableCell className="head-part-table">last movement</StyledTableCell>
              <StyledTableCell className="head-part-table">action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody className='invoiceTableBody'>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell className="table-value">{row.loc ? <img src={row.loc} alt="location"/> : ""}</StyledTableCell> 
              <StyledTableCell component="th" scope="row" className="table-value">{row.pdtimg ? <img src={row.pdtimg} alt="product"/> : ""}</StyledTableCell>
              <StyledTableCell  className="table-value">{row.id}</StyledTableCell>
              <StyledTableCell className="table-value">{row.startingPoint}</StyledTableCell>
              <StyledTableCell className="table-value"><div className='caption-top-bottom'><div className='caption-top'>{row.cationpoint}</div>{row.lastMove}</div></StyledTableCell>
              <StyledTableCell className="table-value "><div className='icon-space'>{row.action1 ? <img src={row.action1} alt="location"/> : ""}
              {row.action2 ? <img src={row.action2} alt="location"/> : ""}
              </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
    </Table>
    </div>
    <div className='tablebody'>
        <div className='pagination-numb'>
               <Pagination count={9} color="primary" shape="rounded"/>
              
        </div>

    </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Item className={classes.footercls}>
      <Footer />
    </Item>
    </Grid>



       </Grid>
       </Box>
    </div>
  )
}

export default AdminTraceability2;
