import React, { useEffect, useState } from "react";
import "./BrandSellersAuth.css";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import closeMenu from "../../../img/closemenu.png";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import send from '../../../img/send-msg.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRef } from "react";
import Axios from "../../../Axios";
import { Toaster, toast } from 'react-hot-toast'
import consts from '../../../constants'
import { useNavigate } from 'react-router-dom'




// const today = dayjs();
const yesterday = dayjs().subtract(1, "day");
// const todayStartOfTheDay = today.startOf("day");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

//   const useStyles = makeStyles({

//   });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  p: 4,
};

const AddSellersPopup = ({ sts }) => {
  // const classes = useStyles();
  const user = window.localStorage.getItem('AdminToken')
  const role = window.localStorage.getItem('Squelch')

  const navigate = useNavigate()


  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);


  const [createopen, createsetOpen] = React.useState(false);
  const createhandleOpen = () => createsetOpen(true);
  const createhandleClose = () => createsetOpen(false);






  useEffect(() => {
    profile()
  }, [])

  const profile = async () => {
    if (role === 'brand') {
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      console.log(data.result.is_active, "datass")
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        navigate(`${consts.route}/`, { state: { data: true } })
        console.log("Logout")
      }

    }
  }

  const handleOpen = async () => {
    console.log(role, "rols")
    if (role === 'brand') {
      console.log(role, "rols")
      const { data } = await Axios.post(`/users/get_brand_detail`, {}, {
        headers: {
          Authorization: user
        }
      })
      if (data.result.is_active === false) {
        window.localStorage.removeItem('AdminToken')
        window.localStorage.removeItem('Squelch')
        navigate(`${consts.route}/`, { state: { data: true } })
      } else {
        setOpen(true);
        console.log(role, "rols")
      }
    } else {
      setOpen(true);
    }
  }


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const rows = [
    createData('USR22', '43y6p2'),
    createData('USR23', '43y6p2'),
    createData('USR24', '43y6p2'),
  ];

  function createData(
    dueDate,
    service

  ) {
    return { dueDate, service };
  }


  const [storeopen, storesetOpen] = React.useState(false);
  const storehandleOpen = () => storesetOpen(true);
  const storehandleClose = () => storesetOpen(false);

  const storerows = [
    createData('SF1001', 'SF1001', '786543'),
    createData('SF1002', 'SF1002', '786543'),
    createData('SF1003', 'SF1003', '786543'),
    createData('SF1004', 'SF1004', '786543'),
    createData('SF1005', 'SF1005', '786543')

  ];

  function createData(
    dueDate,
    service,
    password

  ) {
    return { dueDate, service, password };
  }


  const [deltaopen, deltasetOpen] = React.useState(false);
  const deltahandleOpen = () => deltasetOpen(true);
  const deltahandleClose = () => deltasetOpen(false);


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const product_name = useRef()
  const brand_name = useRef()
  const category = useRef()
  const product_identifier = useRef()
  const ean = useRef()
  const initial_stock = useRef()
  const unit_measurement = useRef()
  const measurement = useRef()
  const mainimg = useRef()
  const detailimg1 = useRef()
  const detailimg2 = useRef()
  const detailimg3 = useRef()
  const product_description = useRef()
  const material = useRef()
  const ingredients = useRef()
  const depature_location = useRef()
  const coordinates = useRef()
  const license_link = useRef()
  const warrenty = useRef()
  const duration_guarantee = useRef()
  const extra_warrenty = useRef()
  const warrenty_price = useRef()
  const type_currency = useRef()
  const phy_pro_vir = useRef()
  const Production_date = useRef()
  const lot_number = useRef()
  const classification_num = useRef()
  const toxic = useRef()
  const substance_type = useRef()
  const composition = useRef()

  const submits = async () => {
    try {
      const datas = {
        Product_Name: product_name.current.value,
        product_category: category?.current?.value,
        product_identifier: product_identifier?.current?.value,
        ean: ean?.current?.value,
        brand_name: brand_name?.current?.value,
        Initial_stock: initial_stock?.current?.value,
        Unit_of_measurement: unit_measurement?.current?.value,
        Measures: measurement?.current?.value,
        main_image: mainimg?.current?.value,
        Detail1_image: detailimg1?.current?.value,
        Detail2_image: detailimg2?.current?.value,
        Detail3_image: detailimg3?.current?.value,
        product_description: product_description?.current?.value,
        material: material?.current?.value,
        ingredients: ingredients?.current?.value,
        departure_location: depature_location?.current?.value,
        Cordenate_location: coordinates?.current?.value,
        License_Attachments: license_link?.current?.value,
        Warranty: warrenty?.current?.value,
        Duration_of_the_official_guarantee: duration_guarantee?.current?.value,
        // Allows_warranty_extension: extra_warrenty?.current?.value,
        Extra_warranty_time: extra_warrenty?.current?.value,
        Warranty_extension_price: warrenty_price?.current?.value,
        Type_of_currency: type_currency?.current?.value,
        Physical_product_1_or_virtual_0: phy_pro_vir?.current?.value,
        production_date: Production_date?.current?.value,
        LOT_NUMBER: lot_number?.current?.value,
        UN_Product_Classification_Number: classification_num?.current?.value,
        Toxic: toxic?.current?.value,
        substance_type: substance_type?.current?.value,
        composition: composition?.current?.value
      }
      // const { data } = await Axios.post(`/users/createproduct`, {
      const { data } = await Axios.post(`/users/productDetails`, {
        products: [datas]
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      if (data.success === true) {
        toast.success("Product Added Successfully")
        handleClose()
        sts(true)
      }
    } catch (error) {
      console.log("🚀 ~ submits ~ error:", error)
    }

  }

  const userrole = window.localStorage?.getItem('Squelch')

  return (
    <>
      {
        userrole !== 'admin' ?
          <div className='add-seller'>
            <div className='filter-box account'>
              <Button className='filter-color' onClick={handleOpen}> <AccountCircleIcon /> Add Product</Button>
            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >

              <Box sx={style} className="popBOX add-seller">
                <div className="menu" onClick={handleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>

                <div className="headTitle">Create New Product</div>
                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Product Name
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={product_name}
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Brand Name
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={brand_name}
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Category
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={category}
                      />
                    </div>
                  </div>


                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Product Identifier
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={product_identifier}
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Ean Number
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={ean}
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Initial Stock
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={initial_stock}
                        className="textField"
                      />
                    </div>
                  </div>


                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"


                    >
                      Unit Of Measurement
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={unit_measurement}
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Measures
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={measurement}

                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Main Image Url
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={mainimg}

                      />
                       <div style={{ color: "red", textAlign: 'left',fontSize:"10px" }}>* Give Image URL</div>
                    </div>
                  </div>


                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Detail Image1
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={detailimg1}
                      />
                       <div style={{ color: "red", textAlign: 'left',fontSize:"10px" }}>* Give Image URL</div>
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Detail Image2
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={detailimg2}
                        className="textField"
                      />
                       <div style={{ color: "red", textAlign: 'left',fontSize:"10px" }}>* Give Image URL</div>
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Detail Image3
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={detailimg3}
                        className="textField"
                      />
                       <div style={{ color: "red", textAlign: 'left',fontSize:"10px" }}>* Give Image URL</div>
                    </div>
                  </div>

                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Product Description
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={product_description}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Material
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={material}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Ingredients
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={ingredients}
                        className="textField"
                      />
                    </div>
                  </div>

                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Departure Location
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={depature_location}

                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Coordinates
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={coordinates}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Licence Attachment Link
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={license_link}
                        className="textField"
                      />
                    </div>
                  </div>

                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      warranty
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={warrenty}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Duration of Warranty
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={duration_guarantee}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Extra warranty
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={extra_warrenty}
                        className="textField"
                      />
                    </div>
                  </div>

                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      warranty Extension Price
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={warrenty_price}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Type of Currency
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={type_currency}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Physical Product or Virtual Product
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={phy_pro_vir}
                        className="textField"
                      />
                    </div>
                  </div>

                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Production Date
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="textField"
                        inputRef={Production_date}
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      LOT number
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={lot_number}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Production Classification Number
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={classification_num}
                        className="textField"
                      />
                    </div>
                  </div>

                </div>

                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Toxic
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={toxic}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Substance Type
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={substance_type}
                        className="textField"
                      />
                    </div>
                  </div>
                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                      Composition
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputRef={composition}
                        className="textField"
                      />
                    </div>
                  </div>

                </div>


                <div className="filter-box account">
                  {/* <Button onClick={() => { handleClose() }}>Create Product</Button> */}
                  <Button onClick={() => { submits() }}>Create Product</Button>
                </div>

                {/* {<Modal
              open={createopen}
              onClose={createhandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={createhandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">Add Staff</div>
                <div className="listOne">

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                    seller's name
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Bharath"
                        className="textField"
                      />
                    </div>
                  </div>

                  <div className="productId">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="modelTypo"
                    >
                    Seller delta
                    </Typography>

                    <div className="cmn-input-cls">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="kumar"
                        className="textField"
                      />
                    </div>
                  </div>

                </div>


                <div className="listOne">

                  <div className='SellerPaidTable'>
                      <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>
                              <StyledTableCell className="head-part-table">user </StyledTableCell>
                              <StyledTableCell className="head-part-table">password</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                          {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                              <StyledTableCell className="table-value">{row.dueDate}</StyledTableCell>
                              <StyledTableCell  className="table-value">{row.service}</StyledTableCell>

                            </StyledTableRow>

                            
                          ))}
                        </TableBody>
                    </Table>
                    </div>
                </div>

                <div className="add-staff">
                  <div className='user-txt'>
                      <h4>User</h4>
                      <p>If you don't know the name of the staff, leave it blank and we will create a unique ID for that staff.</p>
                    </div>
                    
                    <div className='user-txt'>
                      <h4>Password</h4>
                      <p>If you do not know what password to assign, leave the space blank and we will assign a random password for each staff</p>
                    </div>
                  </div>

              <div className="filter-box account add">
                  <Button onClick={createhandleClose} className='cancel'>Cancel</Button>
                  <Button onClick={storehandleOpen}>Generate user</Button>
              </div>

              </Box>
            </Modal>} */}


                {/* {<Modal
              open={storeopen}
              onClose={storehandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={storehandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>

                <div className="listOne">

                  <div className='SellerPaidTable'>
                      <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead className='SellerTableHead'>
                            <TableRow className='SellerTableRow'>
                              <StyledTableCell className="head-part-table">staff number </StyledTableCell>
                              <StyledTableCell className="head-part-table">id user</StyledTableCell>
                              <StyledTableCell className="head-part-table">password</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className='SellerTableBody'>
                          {storerows.map((storerow) => (
                            <StyledTableRow key={storerow.name}>
                              <StyledTableCell className="table-value">{storerow.dueDate}</StyledTableCell>
                              <StyledTableCell  className="table-value">{storerow.service}</StyledTableCell>
                              <StyledTableCell  className="table-value">{storerow.password}<RefreshIcon/></StyledTableCell>
                            </StyledTableRow>

                            
                          ))}
                        </TableBody>
                    </Table>
                    </div>
                </div>


              <div className="filter-box account add">
                  <Button onClick={deltahandleOpen}>send by email,the passwords to the seller</Button>
                  <Button className='cancel'>Generate all password</Button>
              </div>

              </Box>
            </Modal>} */}


                {/* {<Modal
              open={deltaopen}
              onClose={deltahandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="popBOX add-seller">

              <div className="menu" onClick={deltahandleClose} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>


              <div className="headTitle">SELLER STORE DELTA</div>

                <div className="message">
                    <img src={send} alt="closeMenu" />
                    <p>Brilliant! We have emailed the activation codes to Seller Delta with the email: <span>test@SellerDelta.com </span></p>
                </div>


              <div className="filter-box account add">
                  <Button>send by email,the passwords to the seller</Button>
                  <Button className='cancel'>Generate all password</Button>
              </div>

              </Box>
            </Modal>} */}


              </Box>
            </Modal>

          </div> :
          <></>
      }
    </>


  );
};

export default AddSellersPopup;
