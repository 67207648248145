import React from "react";
import { Navigate } from "react-router-dom";
import Constant from '../constants'
const navigateToLogin = () => {
  const stateData = { login: 0 }; // Your state data

  return (
    <Navigate
      to={`${Constant.route}/`}
      state={stateData} // Pass your state data here
    />
  );
};
function PrivateRoute({ children }) {

  return localStorage.getItem("AdminToken") !== null ? children : navigateToLogin();
}

export default PrivateRoute;
