import Axios from "../Axios"

const logout = async () => {
    try {

        if (window.localStorage.getItem('AdminToken') !== undefined) {
            const { data } = await Axios.post(`/logout`, {
                email: window.localStorage.getItem('electrónico')
            }, {
                headers: {
                    Authorization: window.localStorage.getItem('AdminToken')
                }
            })
            if (data?.success === true) {
                return true
            } else {
                return false
            }
        }

    } catch (error) {
        return false
    }

}

export default logout