import React from 'react'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import "./PremiumBrands.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MyProfile = ({ TicketList, ids, messageList, handleChat1 }) => {

    return (
        <div className='dashboard-page'>

            <Grid container spacing={0}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <div className='close-menu-message'>
                            <img onClick={handleChat} src={Menuclose} alt='close' />
                        </div> */}
                    <div className='extented-warrenty msg'>

                        <div className='warrenty-body premimum-body'>
                            <div className='premimum-chats'>
                                <div className='heading-direct-chat'>
                                    <div className='profile-name-dp'>
                                        <div className='profile-dp-icon-img'>
                                            <ArrowBackIcon onClick={() => { handleChat1() }} />

                                        </div>
                                        <div className='premimum-company-name'>
                                            <p>{(String(TicketList.requestor1) === ids) ? TicketList?.receiver_name : TicketList?.sender_name}</p>
                                            <span>About {TicketList?.name}</span>
                                        </div>
                                    </div>
                                    <div className='icons-sctn'>
                                        <Stack direction="row" spacing={2}>
                                            {/* <WifiCalling3OutlinedIcon />
                                                <DeleteOutlinedIcon />
                                                <InfoOutlinedIcon onClick={()=>{handleChat()}}/> */}
                                        </Stack>
                                    </div>
                                </div>
                            </div>

                            <div className='chat-box-scroll'>
                                <div className='text-day'>
                                    {/* <p>Today</p> */}
                                </div>
                                <div className='chat-box'>
                                    <div className='send-txt1'>  <p></p></div>
                                    <div className='send-txt'>  <p>{TicketList?.description}</p></div>
                                    {messageList && messageList.map((msg, index) => {
                                        return <>
                                            <div className={(String(msg.from) === ids) ? 'send-txt1' : 'receive-txt1'}> {msg.from} <p>{msg?.sender_name}</p></div>
                                            <div className={(String(msg.from) === ids) ? 'send-txt' : 'receive-txt'} >
                                                <p>{msg.message}</p>
                                            </div>
                                        </>

                                    })}

                                </div>
                            </div>
                        </div>


                    </div>

                    {/* <div className='chat-txt-area'>
                        <div className='txt-block'>
                            
                            <div className='txt-field'>
                                <FormControl>
                                    <OutlinedInput
                                        value={message1}
                                        onChange={(e) => setMessage1(e.target.value)}
                                        className='msg-input' />

                                </FormControl>
                            </div>
                           
                            
                        </div>

                    </div> */}

                </Grid>

            </Grid>

        </div>
    )
}

export default MyProfile
