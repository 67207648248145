import React from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom'
import "../User/ExtendedWarrenty/Warrenty.css"
import Button from '@mui/material/Button'
import logo from '../../img/logo.png'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)': {
            padding: '30px 0 !important',
        }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        position: 'relative'
    },
});

const Purchase_Success = () => {

    const classes = useStyles();


    return (
        <div className='dashboard-page'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>


                        <div className='purch-sucess'>
                            <div className='logo'><img src={logo} alt="logo" /></div>
                            <p>Product Approved Successfully, To Initiate The Purchase, Click The Link That Was Sent To Your Email </p>
                            <div>
                                <Link to='/user/login' className='paswd-change-link'><Button className='Buy-now-warrenty' >Thank You</Button></Link>
                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.footercls}>
                            <Footer />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Purchase_Success
