import React, {useState} from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import TextField from '@mui/material/TextField';
import QrBar from '../../../img/qr-bar.png';
import './QrProduct.css'
import ProductInfo from '../../../img/qr-product-info.png'
import QrBag from '../../../img/qr-bag-img.png'
import Checkbox from '@mui/material/Checkbox';
import Thumbup from '../../../img/like-btn.png';
import RejectPopup from './RejectPopup';
import Approval from './Approval';
import ScanBlockchain from './ScanBlockchain';
import ProductInformation from './ProductInformation'
import Reactivate from './Reactivate'
import RequestUpdate from './RequestUpdate'
import BlockRequest from './BlockRequest'
import BlockProduct from './BlockProduct'
import ReactivateQr from './ReactivateQr'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none !important",
}));

  const useStyles = makeStyles({

    modalpara:{
        fontSize: '18px',
        textAlign:'center',
        maxWidth:'750px !important',
        margin:'0 auto',
        paddingBottom: '80px',
        '@media (max-width: 767.98px)' : {
            paddingBottom: '36px',
          },

      },

      reasonBlock:{
        fontSize:'24px',
        fontWeight:'600',
        margin:'0'
    }

  });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const QrProduct = () => {
  const classes = useStyles();

  const [openAdminQRProduct, setOpenAdminQRProduct] = useState(false);
  const handleOpenAdminQRProduct = () => setOpenAdminQRProduct(true);
  const handleCloseAdminQRProduct = () => setOpenAdminQRProduct(false);

  return (
    <div >
      <div>
        <RejectPopup />
      </div>
           <div className="qr-detail">
      <Button onClick={handleOpenAdminQRProduct}>{<img src={QrBar} alt={QrBar} />}</Button>
      <Modal 
        open={openAdminQRProduct}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className="popBOX BlockPopup qrproduct-pop">
        <div className="menu" onClick={handleCloseAdminQRProduct} ><Link><img src={closeMenu} alt="closeMenu" /></Link></div>
            
         <div className="product-qr-info">
            <div className="product-info-head">
                <p className="product-code-head">QR product info</p>
                <p className="pro-id">PRODUCT ID ADD-11123-99</p>
            </div>

            <div className="qr-product-scan">
             <img src={ProductInfo} alt={ProductInfo} />
            </div>

            <div className="product-img-bag">
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            <div className="info-bag">
             <img src={QrBag} alt={QrBag} />
            </div>
            </div>

            <div className="category-sctn">
                <div className="category-info">
                    <p className="product-client-id">Client ID <span> XXX11122S</span></p>
                </div>
                <div className="category-info">
                    <p className="product-client-id">ID PRODUCT<span> XXX 1002 - 001</span></p>
                </div>

                <div className="category-info">
                    <p className="product-client-id">Category <span> FashionStatus product</span></p>
                </div>

                <div className="approved-btn">
                    <Button className="approve-button">Approved</Button>
                </div>

                <div className="category-info">
                    <p className="product-client-id">Blockchain info <Link hrefLang="#" className="url-link">url link</Link></p>
                </div>
            </div>

            <div className="action-part">
                <p className="action-head">ACTIONS</p>
                <p className="action-part-cnt">With this action you can generate a certificate of authenticity and send it to the client who has requested it,</p>
                <p className="action-info">Warning: Do not generate any certificate without having received the payment for it</p>
            </div>
          
          <div className="generate-btns">
            <div className="certificate-btn">
                <Button>GENEREATE CERTIFICATE</Button>
            </div>
            <div className="certificate-btn qr-code">
                <Button>Send QR code</Button>
            </div>
            <div className="certificate-btn print-btns">
                <Button>Print</Button>
            </div>
          </div>

          <div className="email-input-box">
            <div className="email-certificate">
                    <div className="certificate-box">
                        <p className="email-send">Enter email where the certificate will be sent</p>
                    </div>
                    <div className="input-email">
                  <TextField className="input-txt-box"  id="outlined-password-input" placeholder="Email" type="email" />
                  <div className="default-acct">
                    <p className="default-para">Use default account email</p>
                    <Checkbox {...label} />
                   </div>
                   <div className="approved-btn">
                    <Button className="approve-button">Send certificate</Button>
                   </div>
                   </div>
            </div>
          </div>

          <div className="successfull-certificate">
              <div className="thumbs-up">
                <img src={Thumbup} alt={Thumbup} />
              </div>

              <p className="success-msg">Certificate successfully sent to applicant</p>
          </div>

          

         </div>
         
       

        </Box>
      </Modal>
      </div>

      <div>
            <Approval />
          </div>
          <div>
            <ScanBlockchain />
          </div>

          <div>
            <ProductInformation />
          </div>

          <div>
            <Reactivate />
          </div>

          <div>
            <RequestUpdate />
          </div>

          <div>
            <BlockRequest />
          </div>

          <div>
            <BlockProduct />
          </div>
          <div>
            <ReactivateQr />
          </div>
    </div>

    
  );
};

export default QrProduct;
