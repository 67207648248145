import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ReactToPrint from "react-to-print";
import Paper from '@mui/material/Paper';
import logo from '../../../img/dtlogo.png'
import Button from '@mui/material/Button';
import * as XLSX from "xlsx";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChartFlow from '../../../img/ChartFlow.png';
import BillingChart from './BillingChart';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import WalletPart from '../../../img/wallet-img.png'
import InvoiceStatus from '../../../img/invoice-status.png'
import { blue } from '@mui/material/colors';
import Pagination from '@mui/material/Pagination';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Filter from './Filter'
import "./AdminInvoice.css"
import Axios from '../../../Axios';
import InvoicesPopup from "../AdminInvoice/InvoicesPopup"
import CancelInvoice from './cancelInvoice';
import PaidPop from './PaidPop';
import success from '../../../img/approvedicon.png'
import failure from '../../../img/cancelicon.png'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DailyCost from './DailyCost';
import UnPayDailyCost from './UnPayDailyCost'
import PayDailyCost from './PayDailyCost'
import BillDailyCost from './BillDailyCost'
import CryptoJS from "crypto-js";
import AdminFilterPopup from '../AdminInvoice/AdminFilterPopup';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Modal from "@mui/material/Modal";
import closeMenu from "../../../img/closemenu.png";
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Chart } from "react-google-charts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1207,
  border: "2px solid #000",
  p: 4,
};

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 20,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 30px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});

const AdminDashboardBody = () => {
  const today = dayjs();

  const classes = useStyles();
  const [accounting, setAcccounting] = useState(false);
  const [blockchainAccounting, setBlockchainAccounting] = useState(false);
  const [isfilter, setisFilter] = useState(false)

  const [dashboardData, setDashboardData] = useState([])
  const [quantity, setQuantity] = useState(0)
  const [sold, setSold] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null);
  const [priceData, setpriceData] = useState([])
  const [lastpriceData, setlastpriceData] = useState([])
  const [weekEarn, setweekEarn] = useState([])
  const [countryEarn, setcontryEarn] = useState([])
  const ref = useRef()
  // const handleAccounting = () =>{
  //   if(accounting === true){
  //     setAcccounting(false)
  //   }else{
  //     setAcccounting(true)
  //   }
  // }

  let initialValue = "Select";

  const handleAcccountingOpen = () => setAcccounting(true)
  const handleAcccountingClose = () => setAcccounting(false)
  const handleBlockChainAcccountingOpen = () => setBlockchainAccounting(true)
  const handleBlockChainAcccountingClose = () => setBlockchainAccounting(false)


  const [statusss, setstatusss] = useState()
  const [bill, setBil] = useState([])
  const [bill1, setBil1] = useState([])
  const [page, setPage] = useState(1);
  const [count, setCount] = useState()
  const [datas, setDatas] = useState([])



  const role = window.localStorage.getItem('Squelch')
  const status1 = window.localStorage.getItem('isimo')

  const [decrptedData, setDecrptedData] = useState()
  const secretPass = 'DtRaCeRsEcrEtFrEe'
  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt(status1, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setDecrptedData(data);
  };

  useEffect(() => {
    if (isfilter === false) {

      getbillings()

    }
    else {
      filterApi()
    }

  }, [page, isfilter])

  const handleChange1 = (event, value) => {
    setPage(value);
  };

  const [creationDate, setCreationDate] = useState([])
  const [paymentDate, setPaymentDate] = useState([])

  const [toValues, setToValues] = useState()
  const [toValues0, setToValues0] = useState("")
  const [toValues1, setToValues1] = useState()
  const [toValues11, setToValues11] = useState("")

  const [blockchaindata, setBlockchainData] = useState()

  const handlecreationdatechange = (d, m, y) => {
    var credate = y + '-' + m + '-' + d
    setCreationDate(credate)
  }
  function getDateOneWeekAfter(inputDate) {
    // Parse the input date string into a Date object
    var date = new Date(inputDate);
    // Add 7 days to the input date
    date.setDate(date.getDate() + 7);
    // Return the new date
    return date;
  }
  const handledatechange = (d, m, y) => {
    var credate = y + '-' + m + '-' + d
    // var oneWeekAfterDate = getDateOneWeekAfter(credate);
    // var EndDate = dayjs(new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0])
    // var Enddates = new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0]
    setToValues(credate)
    if (toValues0 != "") {
      billingfilterGraph(credate, toValues0)
    }
    // billingfilterGraph(credate, Enddates)
  }

  const handledatechange0 = (d, m, y) => {
    var credate = y + '-' + m + '-' + d
    // var oneWeekAfterDate = getDateOneWeekAfter(credate);
    // var EndDate = dayjs(new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0])
    // var Enddates = new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0]
    // setToValues(EndDate)
    setToValues0(credate)
    billingfilterGraph(toValues, credate)
  }



  const handledatechange1 = (d, m, y) => {
    var credate = y + '-' + m + '-' + d
    // var oneWeekAfterDate = getDateOneWeekAfter(credate);
    // var EndDate = dayjs(new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0])
    // var Enddates = new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0]
    setToValues1(credate)
    if (toValues11 != "") {
      blockchainGraph(credate, toValues11)
    }
    // blockchainGraph(credate, Enddates)
  }

  const handledatechange11 = (d, m, y) => {
    var credate = y + '-' + m + '-' + d
    // var oneWeekAfterDate = getDateOneWeekAfter(credate);
    // var EndDate = dayjs(new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0])
    // var Enddates = new Date(oneWeekAfterDate)?.toISOString()?.split('T')[0]
    // setToValues1(EndDate)
    setToValues11(credate)
    blockchainGraph(toValues1, credate)
  }



  const handlepaymentdatechange = (d, m, y) => {
    var payDate = y + '-' + m + '-' + d
    setPaymentDate(payDate)
  }

  const [Invoice_Id, setInvoice_Id] = useState()
  const [Payment_method, setPayment_method] = useState()
  const [Total_without_tax, setTotal_without_tax] = useState()
  const [graphData, setGraphData] = useState()
  const [invoice, setinvoiceData] = useState({})

  const [unpaygraph, setunpaygraph] = useState({})
  const [paygraph, setpaygraph] = useState({})
  const [billgraph, setbillgraph] = useState({})
  const [totalbillgraph, settotalbillgraph] = useState({})

  const [price, setprice] = useState()
  const [Total_bil, setTotal_bil] = useState()
  const [Brand_Name, setBrand_Name] = useState()
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  }


  const filterApi = async () => {
    try {
      setisFilter(true)
      const datas = {
        Invoice_Id: Invoice_Id,
        Payment_method: Payment_method,
        Total_without_tax: Total_without_tax,
        price: price,
        Total_bil: Total_bil,
        Brand_Name: Brand_Name,
        payment_date: paymentDate,
        creation_date: creationDate,
        page: page - 1,
        limit: 10,
      }
      const { data } = await Axios.post(`/admin/get_billing_details`, datas,
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      handleClose()
      filterPro(data?.result)
      setBil1(data?.export)
      setCount(Math.ceil(data?.count / 10))
      setDatas(data?.result)
      // setInvoice_Id()
      // setPayment_method()
      // setTotal_without_tax()
      // setprice()
      // setCreationDate([])
      // setPaymentDate([])
      // setTotal_bil()
      // setBrand_Name()

    } catch (error) {
      console.log("🚀 ~ file: AdminFilterPopup.js:132 ~ filterApi ~ error:", error)

    }

  }
  const ResetFilter = async () => {
    setInvoice_Id('')
    setPayment_method('')
    setTotal_without_tax('')
    setprice('')
    setisFilter(false)
    setCreationDate([])
    setPaymentDate([])
    setTotal_bil('')
    setBrand_Name('')
    reset(true)

  }

  useEffect(() => {
    if (role === 'subAdmin') {
      decryptData()
    }
  }, [status1])

  const filterPro = (data) => {
    setstatusss("Loading...")
    if (data.length > 0) {
      setstatusss()
      setBil(data)
      //setCount(Math.ceil(data?.length / 10))
    } else {
      setBil(data)
      setstatusss("No product available for this filter.....")
    }

  }
  const reset = (data) => {

    setstatusss("Loading...")
    getbillings()
  }

  const getbillings = async () => {
    try {
      setstatusss("Loading...")
      const { data } = await Axios.post(`/admin/get_billing_details`, {
        page: page - 1,
        limit: 10,
      },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result?.length > 0) {
          setCount(Math.ceil(data?.count / 10))

          // data?.result?.forEach(element => {
          //   element.Creation_date = formatDate(element?.Creation_date);
          //   element.Payment_date = formatDate(element?.Payment_date)
          // });
          // setCount(Math.ceil(data?.length / 5))
          setBil1(data?.export)
          setBil(data?.result)
        }
        else {
          setBil(data?.result)
          setstatusss("No Data Available")
        }
      }
      else {
        setBil([])
        setstatusss("No Data Available")
      }

    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }

  const getGraphs = async () => {
    try {


      const { data } = await Axios.post(`/admin/billing_graph`, '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {
          setGraphData(data?.result)
          var graphDetails = []
          var priceDetail = []
          var lastpriceDetail = []
          for (var i = 0; i < data?.result?.month.length; i++) {
            graphDetails.push(data?.result?.month[i])
            if (data?.result?.price[i] === undefined || data?.result?.price[i] === null || data?.result?.price[i] === "null") {
              priceDetail.push(0)
            }
            else {
              priceDetail.push((data?.result?.price[i])?.toFixed(2))
            }
            if (data?.result?.lastprice[i] === undefined || data?.result?.lastprice[i] === null || data?.result?.lastprice[i] === "null") {
              lastpriceDetail.push(0)
            }
            else {
              lastpriceDetail.push((data?.result?.lastprice[i])?.toFixed(2))
            }
          }
          setpriceData(priceDetail)
          setlastpriceData(lastpriceDetail)

          // console.log(lastpriceDetail,priceDetail)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }

  useEffect(() => {
    getbillings()
    getGraphs()
    fetchData()
    totalquantity()
    soldproduct()
    billingGraph()
  }, [])

  const billingGraph = async () => {
    try {
      const { data } = await Axios.post(`/admin/billing_data`, '',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {
          setinvoiceData(data?.result)
          settotalbillgraph(data?.totalbillGraph)
          setbillgraph(data?.billGraph)
          setpaygraph(data?.payGraph)
          setunpaygraph(data?.unpayGraph)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)
    }
  }

  const blockchainGraph = async (start, end) => {
    try {
      var payload = {}
      if (start != undefined && end != undefined) {
        payload = {
          date: start,
          end: end
        }
      }
      const { data } = await Axios.post('/admin/blockchainData', payload, {
        headers: { Authorization: localStorage.getItem("AdminToken"), }
      })
      if (data?.success == true) {
        setBlockchainData(data?.result)
      }
    } catch (error) {
      console.log("🚀 ~ blockchainGraph ~ error:", error)
    }
  }

  useEffect(() => {
    blockchainGraph()
  }, [])


  const billingfilterGraph = async (start, end) => {
    try {
      const { data } = await Axios.post(`/admin/billing_data`, { date: start, end: end },
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        if (data?.result) {
          setinvoiceData(data?.result)
          settotalbillgraph(data?.totalbillGraph)
          setbillgraph(data?.billGraph)
          setpaygraph(data?.payGraph)
          setunpaygraph(data?.unpayGraph)
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: Billingbody.js:140 ~ getbillings ~ error:", error)

    }

  }

  const fetchData = async () => {
    try {
      let formattedDate = '';
      if (selectedDate) {
        formattedDate = selectedDate.format('YYYY-MM-DD');
      }
      // if(selectedDate){
      // const formattedDate = selectedDate.format("YYYY-MM-DD");
      const datas = await Axios.get(`/admin/dashboardCount${formattedDate ? `?date=${formattedDate}` : ''}`, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      });
      const response = datas?.data?.result
      setDashboardData(response)
      setcontryEarn(datas?.data?.EarningsbyCountry)
      setweekEarn(datas?.data?.WeekEarnings)

      // }else{
      //   console.log("No date selected")
      // }



    } catch (error) {
      console.log(error, "error")
    }
  }
  const totalquantity = async () => {
    try {
      let formattedDate = '';
      if (selectedDate) {
        formattedDate = selectedDate.format('YYYY-MM-DD');
      }
      // if(selectedDate){

      // const formattedDate = selectedDate.format("YYYY-MM-DD");
      const response = await Axios.get(`/admin/productQuantity${formattedDate ? `?date=${formattedDate}` : ''}`, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      const quanti = response?.data?.result
      setQuantity(quanti)
      // }else{
      //   console.log("No date selected")
      // }


    } catch (error) {
      console.log(error, "error")

    }
  }

  const soldproduct = async () => {
    try {
      let formattedDate = '';
      if (selectedDate) {
        formattedDate = selectedDate.format('YYYY-MM-DD');
      }
      // if(selectedDate){

      // const formattedDate = selectedDate.format("YYYY-MM-DD");
      const product = await Axios.get(`/admin/soldProduct${formattedDate ? `?date=${formattedDate}` : ''}`, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      const resp = product?.data?.result
      setSold(resp)
      // }else{
      //   console.log("No date selected")
      // }
    } catch (error) {
      console.log(error, "error")
    }
  }

  const status = () => {
    getbillings()
  }
  const generatePdf = (datas) => {
    const dates = new Date(datas.Creation_date).toLocaleDateString()
    const sdates = new Date(datas.Payment_date).toLocaleDateString()
    const subtotalPrice = Number(datas.price) - (Number(datas.discount / 100 * datas.price))

    const head = [['Concept', 'Quantity', 'Price', 'Subtotal', 'Discount', 'Tax', 'Total']]
    const data = [
      [datas.Invoice_concept, datas.Quantity, datas.price, datas?.Total_without_tax, `${datas.discount}%`, datas.Tax_rate, datas.Total_bil,],

    ]
    const paydata = [
      ['payment Menthod', datas.Total_bil],
      ['payment Menthod', datas.Total_bil],
    ]
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    doc.setFontSize(10);

    doc.addImage(logo, "PNG", 10, 15, 70, 30);

    doc.setFont('Arial', 'bold');
    doc.setFontSize(13);
    doc.text("Payment To", 200, 41, "right");
    doc.setFont('Arial', 'bold');
    doc.setFontSize(25);
    doc.text("Dtracer", 200, 52, "right");
    // doc.setFontSize(13);
    // doc.setFont('Arial', 'normal');
    // doc.text("dtracer.io", 200, 57, "right");
    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text("1210 Washington Avenue,", 200, 57, "right");
    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text("Miami Beach,Florida,", 200, 62, "right");
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("United States", 200, 67, "right");

    // doc.setFont('Arial', 'bold');
    // doc.setFontSize(15);
    // doc.text("CLIENT", 15, 95, "left");

    doc.setFont('Arial', 'bold');
    doc.setFontSize(15);
    doc.text("CLIENT", 15, 95, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Brand_Name}`, 15, 100, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Invoice_Id}`, 15, 106, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.email}`, 15, 112, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Address}`, 15, 118, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Country}`, 15, 124, "left");

    // doc.setFontSize(13);
    // doc.setFont('Arial', 'bold');
    // doc.text(`${datas?.Country}`, 15, 67, "right");

    doc.setFont('Arial', 'bold');
    doc.setFontSize(15);
    doc.text(`${datas?.Invoice_Id}`, 200, 95, "right");

    doc.setFont('Arial', 'normal');
    doc.setFontSize(15);
    doc.text(`Date:${dates}`, 200, 103, "right");

    doc.setFont('Arial', 'normal');
    doc.setFontSize(15);
    doc.text(`Expire Date:${sdates}`, 200, 110, "right");


    doc.setFontSize(15);

    var finalY = doc.lastAutoTable.finalY || 120
    var finalX = doc.lastAutoTable.finalX || 20
    doc.autoTable({
      startX: 10,
      startY: finalY + 20,
      head: head,
      body: data,

      styles: {
        cellPadding: 5,
        fontSize: 12, fontStyle: 'bold', font: 'Arial', color: [0, 0, 0], lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      bodyStyles: {
        minCellHeight: 12,
        valign: 'middle',
        cellPadding: 5,
        fillColor: [207, 225, 255],
        textColor: [0, 0, 0, 222 / 255],
      },
      headStyles: {
        minCellHeight: 10,
        cellPadding: 5,
        borderRadius: 20,
        fillColor: [0, 112, 240],
        valign: 'middle',
        textColor: [255, 255, 255],
      },
    })

    var finalY = doc.lastAutoTable.finalY || 160

    function roundedRect(x, y, width, height, radius, shadowSize) {
      doc.setLineWidth(0.1);
      doc.setDrawColor(0);
      doc.setFillColor(255);
      doc.roundedRect(x + shadowSize, y + shadowSize, width, height, radius, shadowSize, 'FD');
    }
    var shadowSize = 5;


    // Draw a rounded rectangle with a border radius of 10
    roundedRect(130, finalY + 10, 60, 60, 5, shadowSize);
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("Subtotal", 140, finalY + 30, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("Tax", 140, finalY + 45, "left");

    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("Total", 140, finalY + 60, "left");

    doc.setDrawColor(204, 204, 204);
    doc.line(140, finalY + 36, 190, finalY + 36);
    doc.line(140, finalY + 51, 190, finalY + 51);

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Total_without_tax ? datas?.Total_without_tax : 0}`, 190, finalY + 30, "right");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Tax_rate ? datas?.Tax_rate : 0}`, 190, finalY + 45, "right");

    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text(`${datas?.Total_bil ? datas?.Total_bil : 0}`, 190, finalY + 60, "right");

    doc.save(`${datas?.Brand_Name}_invoice.pdf`)
  }

  const downloadExcelsheet = () => {
    let tableData = []

    for (var i = 0; i < bill1.length; i++) {
      let reasons;
      var info = {
        Name: bill1[i].Brand_Name,
        Email: bill1[i].email,
        Address: bill1[i].Address,
        Country: bill1[i].Country,
        Invoice_Id: bill1[i].Invoice_Id,
        Invoice_concept: bill1[i].Invoice_concept,
        Invoice_status: bill1[i].payment_status == "2" ? "cancel" : `${bill1[i].payment_status == "1" ? "paid" : "pending"}`,
        Creation_Date: bill1[i]?.Creation_date?.split('T')[0],
        Payment_date: bill1[i]?.Payment_date?.split('T')[0],
        Payment_method: bill1[i].Payment_method,
        Quantity: bill1[i].Quantity,
        Tax_rate: bill1[i].Tax_rate,
        Total_bil: bill1[i].Total_bil,
        Total_without_tax: bill1[i]?.price - (bill1[i]?.Tax_rate / 100 * bill1[i]?.price) ? bill1[i]?.price - (bill1[i]?.Tax_rate / 100 * bill1[i]?.price) : ('-'),
        Discount: bill1[i].discount,
        Price: bill1[i].price,
        Payment_status: bill1[i].payment_status,
        Proof: bill1[i].proof,
      }
      tableData.push(info)
    }

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Billing.csv');

  };

  const downloadExcelinfosheet = () => {
    let tableData = [invoice]
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    XLSX.writeFile(workbook, 'Invoice_count.csv');

  };

  const [datass1, setDatass1] = useState([
    ["Country", "Amount"],
  ])

  const BrandGraph = async () => {
    try {
      const { data } = await Axios.post('/admin/brandCountryWise', {}, {
        headers: { Authorization: window.localStorage.getItem('AdminToken') }
      })
      console.log("🚀 ~ BrandGraph ~ data:", data)
      if (data?.success == true) {
        var arr1 = []
        for (let i = 0; i < data?.result?.length; i++) {
          const element = data?.result[i];
          arr1.push([element?._id, element?.dailyCost])
        }
        setDatass1([...datass1, ...arr1])
      }
    } catch (error) {
      console.log("🚀 ~ BrandGraph ~ error:", error)
    }
  }

  useEffect(() => {
    BrandGraph()
  }, [])

  if ((role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[5]?.Read === true)) || (role === 'brand')) {
    return (
      <div className='dashboard-body'>
        <div className='accnt-btn'  >
          <div>
            {/* <Button className="filter-btn"><SearchOutlinedIcon />Filter</Button> */}
            <AdminFilterPopup ResetFilter={ResetFilter} filterApi={filterApi} reset={reset} setisFilter={setisFilter} page={page} setCount={setCount} Invoice_Id={Invoice_Id} setInvoice_Id={setInvoice_Id}
              Payment_method={Payment_method} setPayment_method={setPayment_method}
              Total_without_tax={Total_without_tax} setTotal_without_tax={setTotal_without_tax}
              price={price} setprice={setprice}
              Total_bil={Total_bil} setTotal_bil={setTotal_bil}
              Brand_Name={Brand_Name} setBrand_Name={setBrand_Name}
              open={open} setOpen={setOpen} handleOpen={handleOpen} handleClose={handleClose}
              creationDate={creationDate} setCreationDate={setCreationDate}
              paymentDate={paymentDate} setPaymentDate={setPaymentDate}
              handlecreationdatechange={handlecreationdatechange}
              handlepaymentdatechange={handlepaymentdatechange}
            />
          </div>
          <div>
            <Filter reset={reset} setisFilter={setisFilter} />
          </div>
          <div className='see-accounting'>
            <Button className="filter-btn" onClick={handleAcccountingOpen} ><AutoGraphIcon />See Accounting</Button>
          </div>
          <div className='blockChain-aoocunting'>
            <Button className="filter-btn" onClick={handleBlockChainAcccountingOpen}><SsidChartIcon />Blockchain Account</Button>
          </div>
        </div>
        {
          <Modal
            open={blockchainAccounting}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

            <Box sx={style} className="popBOX BlockPopup filterpopup">
              <div className="menu" ><img src={closeMenu} onClick={handleBlockChainAcccountingClose} alt="closeMenu" /></div>
              <Grid container spacing={0}>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <div className='heading-accounting'>
                    <h3>See Blockchain Account</h3>
                  </div>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12} className='seeAccountingGeneral'>
                  <div className="id-client">
                    <p className="new-invoice">Invoice issue date</p>
                    <div className='start-end-date start-end-date-admin invoice-date'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'DateTimePicker',
                            'TimePicker',
                            'DateRangePicker',
                          ]}
                        >

                          <DemoItem>
                            <DatePicker
                              defaultValue={today}
                              onChange={(e) => { handledatechange1(e.$D, e.$M + 1, e.$y) }}


                              views={['year', 'month', 'day']}
                            />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="id-client">
                    <p className="new-invoice">To</p>
                    <div className='start-end-date start-end-date-admin invoice-date'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'DateTimePicker',
                            'TimePicker',
                            'DateRangePicker',
                          ]}
                        >
                          <DemoItem>
                            <DatePicker
                              defaultValue={today}
                              onChange={(e) => { handledatechange11(e.$D, e.$M + 1, e.$y) }}
                              // disabled
                              // value={toValues1}
                              views={['year', 'month', 'day']}
                            />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className='reset-filter'><button onClick={() => { blockchainGraph(); setToValues1("") }} ><RefreshIcon />Reset Filter</button></div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total blockchain transactions</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={blockchaindata?.count ? blockchaindata?.count : 0} className="field-txt-box" />
                      {/* <p className='go-green'>{total_invoice}%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total Matic received</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={blockchaindata?.totalmatic[0]?.total ? blockchaindata?.totalmatic[0]?.total : 0} className="field-txt-box" />
                      {/* <p className='go-red'>-0.5%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total Matic Paid</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={0} className="field-txt-box" />
                      {/* <p className='go-green'>-0.5%</p> */}
                    </div>
                  </div>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Total Blockchain Transaction</h5>
                      <DailyCost cost={blockchaindata?.totalTransa?.count} costTime={blockchaindata?.totalTransa?.month} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Total Matic Received</h5>
                      <BillDailyCost cost={blockchaindata?.totalMaticGraphs?.count ? blockchaindata?.totalMaticGraphs?.count : [0]} costTime={blockchaindata?.totalMaticGraphs?.month ? blockchaindata?.totalMaticGraphs?.month : [0]} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Total Matic Paid</h5>

                      <PayDailyCost cost={[0]} costTime={[0]} />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className='accounting-btn'>
                    <Button onClick={() => { downloadExcelinfosheet() }}>
                      Download Information
                    </Button>
                  </div>

                </Grid>
              </Grid>

            </Box>
          </Modal>
        }
        {
          <Modal
            open={accounting}
            onClose={handleAcccountingClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >

            <Box sx={style} className="popBOX BlockPopup filterpopup">
              <div className="menu" ><img src={closeMenu} onClick={handleAcccountingClose} alt="closeMenu" /></div>
              <Grid container spacing={0}>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <div className='heading-accounting'>
                    <h3>See Accounting General</h3>
                  </div>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12} className='seeAccountingGeneral'>

                  <div className="id-client">
                    <p className="new-invoice">Invoice issue date</p>
                    <div className='start-end-date start-end-date-admin invoice-date'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'DateTimePicker',
                            'TimePicker',
                            'DateRangePicker',
                          ]}
                        >

                          <DemoItem>
                            <DatePicker
                              defaultValue={today}
                              onChange={(e) => { handledatechange(e.$D, e.$M + 1, e.$y) }}
                              views={['year', 'month', 'day']}
                            />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="id-client">
                    <p className="new-invoice">To</p>
                    <div className='start-end-date start-end-date-admin invoice-date'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DatePicker',
                            'DateTimePicker',
                            'TimePicker',
                            'DateRangePicker',
                          ]}
                        >
                          <DemoItem>
                            <DatePicker
                              defaultValue={today}
                              onChange={(e) => { handledatechange0(e.$D, e.$M + 1, e.$y) }}
                              // disabled
                              // value={toValues}
                              views={['year', 'month', 'day']}
                            />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='reset-filter'><button onClick={() => { billingGraph(); setToValues("") }} ><RefreshIcon />Reset Filter</button></div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total invoices created</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={invoice?.total_invoice ? invoice?.total_invoice : ('-')} className="field-txt-box" />
                      {/* <p className='go-green'>{total_invoice}%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total invoice amount paid</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={invoice?.paid_totalTransaction ? invoice?.paid_totalTransaction : ('-')} className="field-txt-box" />
                      {/* <p className='go-red'>-0.5%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total Number invoices paid</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={invoice?.paid_transactionCount ? invoice?.paid_transactionCount : ('-')} className="field-txt-box" />
                      {/* <p className='go-green'>-0.5%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total $ unpaid invoices</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={invoice?.unpaid_totalTransaction ? invoice?.unpaid_totalTransaction : ('-')} className="field-txt-box" />
                      {/* <p className='go-red'>-0.5%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="id-client">
                    <p className="new-invoice">Total number of unpaid invoices</p>
                    <div>
                      <TextField id="outlined-password-input" type="text" disabled value={invoice?.unpaid_transactionCount ? invoice?.unpaid_transactionCount : ('-')} className="field-txt-box" />
                      {/* <p className='go-green'>-0.5%</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Total Invoice Created</h5>
                      <DailyCost cost={totalbillgraph.count} costTime={totalbillgraph.month} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Total Amount Invoice Paid</h5>
                      <BillDailyCost cost={billgraph.count ? billgraph.count : [0]} costTime={billgraph.month ? billgraph.month : [0]} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Unpaid Invoices</h5>

                      <PayDailyCost cost={paygraph.count ? paygraph.count : [0]} costTime={paygraph.month ? paygraph.month : [0]} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='monthly-chart2 daily-cost'>
                      <h5>Total Amount Unpaid Invoices</h5>
                      <UnPayDailyCost cost={unpaygraph.count ? unpaygraph.count : [0]} costTime={unpaygraph.month ? unpaygraph.month : [0]} />


                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className='accounting-btn'>
                    <Button onClick={() => { downloadExcelinfosheet() }}>
                      Download Information
                    </Button>
                  </div>

                </Grid>
              </Grid>

            </Box>
          </Modal>
        }

        <Box sx={{ flexGrow: 1 }}>


          <Grid container spacing={0} className={classes.containwidth}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='dashboargraph-inner-top'>
                <Button variant="contained" onClick={() => downloadExcelsheet()} endIcon={<ExitToAppIcon />}>
                  Export
                </Button>
              </div>
              <Item className={classes.dashboargraph} id="earnings-sales">
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className='head-topic'>Invoice ID</TableCell>
                        <TableCell align="center" className='head-topic'>Brand Name</TableCell>
                        <TableCell align="center" className='head-topic'>Address</TableCell>
                        <TableCell align="center" className='head-topic'>Country</TableCell>
                        <TableCell align="center" className='head-topic'>Total without taxes</TableCell>
                        <TableCell align="center" className='head-topic'>Invoice status</TableCell>
                        <TableCell align="center" className='head-topic'>TAX RATE</TableCell>
                        <TableCell align="center" className='head-topic'>Creation date</TableCell>
                        <TableCell align="center" className='head-topic'>Payment date</TableCell>
                        {/* <TableCell align="center" className='head-topic'>Equivalence surcharge</TableCell> */}
                        <TableCell align="center" className='head-topic'>Invoice concept</TableCell>
                        <TableCell align="center" className='head-topic'>QTY</TableCell>
                        <TableCell align="center" className='head-topic'>Total bill</TableCell>
                        <TableCell align="center" className='head-topic'>Payment method</TableCell>
                        <TableCell align="center" className='head-topic'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>


                      {bill.length > 0 ? bill?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell className='row-topic'>{row?.Invoice_Id}</TableCell>
                          <TableCell className='row-topic'>{row?.Brand_Name}</TableCell>
                          <TableCell className='row-topic'>{row?.Address}</TableCell>
                          <TableCell className='row-topic'>{row?.Country}</TableCell>
                          <TableCell className='row-topic'>{Math.abs(row?.price) - (row?.discount / 100 * Math.abs(row?.price)) ? (Math.abs(row?.price) - (row?.discount / 100 * Math.abs(row?.price))) * Number(row?.Quantity) : ('-')}</TableCell>
                          <TableCell className='row-topic'>{row?.payment_status === '0' ? <img src={InvoiceStatus} alt={InvoiceStatus} /> : <>{row?.payment_status === '1' ? <img src={success} alt={success} /> : <img src={failure} alt={failure} />}</>}  </TableCell>
                          <TableCell className='row-topic'>{row?.Tax_rate}</TableCell>
                          <TableCell className='row-topic'>{new Date(row?.Creation_date).toLocaleDateString() === "Invalid Date" ? new Date(row?.createdAt).toLocaleDateString() : new Date(row?.Creation_date).toLocaleDateString()}</TableCell>
                          <TableCell className='row-topic'>{new Date(row?.Payment_date).toLocaleDateString() === "Invalid Date" ? new Date(row?.createdAt).toLocaleDateString() : new Date(row?.Payment_date).toLocaleDateString()}</TableCell>
                          {/* <TableCell className='row-topic'>{row?.surcharge}</TableCell> */}
                          <TableCell className='row-topic'>{row?.Invoice_concept}</TableCell>
                          <TableCell className='row-topic'>{row?.Quantity}</TableCell>
                          <TableCell className='row-topic'>{row?.Total_bil}</TableCell>
                          <TableCell className='row-topic'>{row?.Payment_method}</TableCell>
                          <TableCell className='row-topic'>
                            <div className='icon-space'>
                              <Button onClick={() => { generatePdf(row) }}>{<SaveAltIcon color="primary" />}
                              </Button>
                              <Button><InvoicesPopup row={row} status={status} role={role} decrptedData={decrptedData} />
                              </Button>
                              {
                                (role === 'admin') || ((role === 'subAdmin') && (decrptedData && decrptedData[5]?.Write === true)) ?
                                  <>
                                    <Button> <CancelInvoice row={row} status={status} />   </Button>
                                    <Button><PaidPop row={row} status={status} />
                                    </Button>
                                  </>
                                  :
                                  <>
                                  </>
                              }

                            </div>
                          </TableCell>
                        </TableRow>
                      )) :
                        <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statusss}</h3></TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer style={{ "display": "none" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={ref} >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className='head-topic'>Invoice ID</TableCell>
                        <TableCell align="center" className='head-topic'>Brand Name</TableCell>
                        <TableCell align="center" className='head-topic'>Address</TableCell>
                        <TableCell align="center" className='head-topic'>Country</TableCell>
                        <TableCell align="center" className='head-topic'>Total without taxes</TableCell>
                        <TableCell align="center" className='head-topic'>Invoice status</TableCell>
                        <TableCell align="center" className='head-topic'>TAX RATE</TableCell>
                        <TableCell align="center" className='head-topic'>Creation date</TableCell>
                        <TableCell align="center" className='head-topic'>Payment date</TableCell>
                        <TableCell align="center" className='head-topic'>Equivalence surcharge</TableCell>
                        <TableCell align="center" className='head-topic'>Invoice concept</TableCell>
                        <TableCell align="center" className='head-topic'>QTY</TableCell>
                        <TableCell align="center" className='head-topic'>Total bill</TableCell>
                        <TableCell align="center" className='head-topic'>Payment method</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>


                      {bill.length > 0 ? bill?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell className='row-topic'>{row?.Invoice_Id}</TableCell>
                          <TableCell className='row-topic'>{row?.Brand_Name}</TableCell>
                          <TableCell className='row-topic'>{row?.Address}</TableCell>
                          <TableCell className='row-topic'>{row?.Country}</TableCell>
                          <TableCell className='row-topic'>{row?.price - (row?.discount / 100 * row?.price) ? row?.price - (row?.discount / 100 * row?.price) : ('-')}</TableCell>
                          <TableCell className='row-topic'>{row?.payment_status === '0' ? <img src={InvoiceStatus} alt={InvoiceStatus} /> : <>{row?.payment_status === '1' ? <img src={success} alt={success} /> : <img src={failure} alt={failure} />}</>}  </TableCell>
                          <TableCell className='row-topic'>{row?.Tax_rate}</TableCell>
                          <TableCell className='row-topic'>{new Date(row?.Creation_date).toLocaleDateString() === "Invalid Date" ? new Date(row?.createdAt).toLocaleDateString() : new Date(row?.Creation_date).toLocaleDateString()}</TableCell>
                          <TableCell className='row-topic'>{new Date(row?.Payment_date).toLocaleDateString() === "Invalid Date" ? new Date(row?.createdAt).toLocaleDateString() : new Date(row?.Payment_date).toLocaleDateString()}</TableCell>
                          <TableCell className='row-topic'>{row?.surcharge}</TableCell>
                          <TableCell className='row-topic'>{row?.Invoice_concept}</TableCell>
                          <TableCell className='row-topic'>{row?.Quantity}</TableCell>
                          <TableCell className='row-topic'>{row?.Total_bil}</TableCell>
                          <TableCell className='row-topic'>{row?.Payment_method}</TableCell>

                        </TableRow>
                      )) :
                        <TableCell colspan='7' className='loading-center' style={{ textAlign: 'center' }}><h3>{statusss}</h3></TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Item>
            </Grid>

          </Grid>


          <Grid container spacing={0} className={classes.containwidth}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className='pagination-secyion'>
                <div className='download-keys'>
                  <div className='save-icon'>{<SaveAltIcon sx={{ color: blue[500] }} onClick={() => downloadExcelsheet()} />}</div>
                  <ReactToPrint
                    bodyClass="print-agreement"
                    content={() => ref.current}
                    trigger={() => (
                      <div className='print-icon'>{<LocalPrintshopOutlinedIcon />}</div>

                    )}
                  />
                  {/* <div className='print-icon'>{<LocalPrintshopOutlinedIcon />}</div> */}
                  <div className='print-icon'>{<DoDisturbAltOutlinedIcon />}</div>
                </div>

                <div className='pagination-box'>
                  <div className='pagination-numb'>

                    <Pagination count={count} page={page} onChange={handleChange1} color="primary" shape="rounded" />

                  </div>
                </div>
                <div>

                </div>
              </div>
            </Grid>

          </Grid>





          <div id="five-block-divs-two-rows-top">

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>QR Active</label>
              </div>
              {dashboardData ?
                <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content'>
                  <label>{dashboardData?.qractive_list}</label>
                  {/* <span>+14.67%</span> */}
                </div> : <>
                </>
              }

            </div>


            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Connected Brands</label>
              </div>
              {dashboardData ?
                <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content'>
                  <label>{dashboardData?.brand_list}</label>
                  {/* <span>+14.67%</span> */}
                </div> : <>
                </>
              }

            </div>

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Active Customers</label>
              </div>
              {dashboardData ?
                <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content'>
                  <label>{dashboardData?.user_list}</label>
                  {/* <span>+14.67%</span> */}
                </div> : <></>
              }

            </div>

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Avg. time on Admin Brand</label>
              </div>
              {dashboardData ?
                <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content'>
                  <label>{dashboardData?.average_time_for_brand} </label>
                  {/* <span>+14.67%</span> */}
                </div> : <>
                </>
              }

            </div>

            <div className='five-block-divs-two-rows-top-inner'>

              <div className='iconic-icon'>
                <div className='five-block-divs-two-rows-top-inner-five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                <label>Avg. time on Customer Brand</label>
              </div>
              {dashboardData ?
                <div className='five-block-divs-two-rows-top-inner-five-blk-inner-content'>
                  <label>{dashboardData?.average_time_for_user}</label>
                  {/* <span>+14.67%</span> */}
                </div> : <></>
              }

            </div>

          </div>

          <Box sx={{ flexGrow: 1 }} id="five-block-divs-two-rows">


            {/* <Grid container spacing={0} className={classes.containwidth} id="five-block-divs">

              <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                <Item className={classes.dashboargraph} id="">

                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-1">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Start Date</label>
                      <span>Sept 01, 2018</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-2">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>End Date</label>
                      <span>Sept 01, 2018</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-3">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Sales Measure</label>
                      <span>Revenue</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-4">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Customer Type</label>
                      <span>All Customers</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                <Item className={classes.dashboargraph} id="five-block-divs-5">
                  <div className='five-blk-inner'>
                    <div className='five-block-img'><img src={WalletPart} alt="WalletPart" /></div>
                    <div className='five-blk-inner-content'>
                      <label>Transaction Type</label>
                      <span>All Transaction</span>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                <Item className={classes.dashboargraph} id="">

                </Item>
              </Grid>


            </Grid> */}



            <Grid container spacing={0} className={classes.containwidth}>

              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-1">
                  <div className='total-pdts quantity-bg'>
                    <p>Total Quantity</p>

                    <div className='total-pdts-div'>
                      {quantity ?
                        <div className='total-pdts-div-left'>
                          <span>{quantity?.productquantity}</span>
                          {/* <label>2.00%  */}
                          <label>
                            (30 Days)
                          </label>
                          {/* </label> */}
                        </div> : <></>
                      }                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-2">
                  <div className='total-pdts quantity-bg'>
                    <p>Total Cost</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>${dashboardData?.totalCost ? dashboardData?.totalCost.toFixed(3) : 0}</span><label>{/*2.00% */}<label>(15 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-3">
                  <div className='total-pdts quantity-bg'>
                    <p>Total  Revenue</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>${dashboardData?.totalRevenue ? dashboardData?.totalRevenue.toFixed(2) : 0}</span> <label>{/*2.00% */}<label>(15 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Item className={classes.dashboargraph} id="speciality-4">
                  <div className='total-pdts quantity-bg'>
                    <p>Total Profit</p>
                    <div className='total-pdts-div'>
                      <div className='total-pdts-div-left'><span>${Number(dashboardData?.totalRevenue) - Number(dashboardData?.totalCost) ? (Number(dashboardData?.totalRevenue) - Number(dashboardData?.totalCost)).toFixed(3) : 0}</span><label>{/*2.00% */}<label>(15 Days)</label></label></div>
                      <div className='total-pdts-div-right'>
                        <img src={ChartFlow} alt="ChartFlow" />
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>

            </Grid>

          </Box>


          <Grid container spacing={0} className={classes.containwidth}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Item className={classes.dashboargraph} id="dashboargraph">
                <div className='dashboargraph-part'>
                  <div className='dashboargraph-inner-top'>
                    <Button variant="contained" endIcon={<ExitToAppIcon />}>
                      Export
                    </Button>
                  </div>

                  <div className='dashboargraph-inner-mid'>
                    <div className='dashboargraph-inner-mid-left'>
                      <div className='years-part'>
                        <h5>This years total revenue</h5>
                        <div>
                          {/* <p><FiberManualRecordIcon color='primary' />Online revenue</p> */}
                        </div>
                        <div>
                          {/* <p><FiberManualRecordIcon color='primary' />Offline revenue</p> */}
                        </div>
                      </div>


                      <p>Sales performance for online and offline revenue </p>
                    </div>
                    <div className='dashboargraph-inner-mid-right'>
                    </div>
                  </div>

                  <div className='dashboargraph-inner-bottom'>
                    <BillingChart priceData={priceData} />
                  </div>

                </div>


              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.rightpartstyle}>
              <Chart className='map-dashboard'
                chartEvents={[
                  {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      if (selection.length === 0) return;
                      const region = datass1[selection[0].row + 1];
                    },
                  },
                ]}
                chartType="GeoChart"
                // width="100%"
                height="315px"
                legend_toggle="none"
                data={datass1}
                options={{
                  isStacked: true,
                  legend: false
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  } else {
    return (
      <div>You dont have Permission to View Billing</div>
    )
  }

}

export default AdminDashboardBody
