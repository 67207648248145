import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import profilebrand from '../../../img/Group 28.png'
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom'
import approved from '../../../img/approved.png'
import cancel from '../../../img/CancelIconAPP.png'
import brandnamelogo from '../../../img/Group 28.png'
import defaultprofile from '../../../img/defaultprofile.png'
import Goback from '@mui/icons-material/KeyboardBackspaceRounded';
import consts from '../../../constants'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import userProfile from '../../../img/userProfile.png'
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import Axios from '../../../Axios';
import { Toaster, toast } from 'react-hot-toast'
import Typography from "@mui/material/Typography";
import InvoicesPopup from './InvoicesPopup';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '20px'
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({

  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },
  containwidth: {
    padding: '0 80px',
    '@media (max-width: 991.98px)': {
      padding: '0 10px',
    },
  },
  rightpartstyle: {
    paddingLeft: '50px',
    '@media (max-width: 991.98px)': {
      paddingLeft: '0px',
    },
  },
  dashboargraph: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  }
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const AdminProfileBody = ({ sts }) => {

  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [loadingstatus, setLoadingStatus] = useState(false)


  const [name1, setName1] = useState("")
  const [password1, setPassword] = useState()
  const [name, setName] = useState("")
  const [nameerr, setnameerr] = useState(null);
  const [emailerr, setemailerr] = useState(null);
  const [logoerr, setlogoerr] = useState(null);
  const [thumberr, setthumberr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);
  const [passerr, setpasserr] = useState(null);
  const [newpasserr, setnewpasserr] = useState(null);

  const [profileImageChange, setProfileImageChange] = useState(null)
  const [imgName, setImgName] = useState('No selected files')
  const [profileBannerImageChange, setBannerImageChange] = useState(null)
  const [imgBannerName, setImgBannerName] = useState('No selected files')


  const [details, setDetails] = useState()
  const [row, setRow] = useState()
  const [logo, setLogo] = useState("")
  const [thump, setThump] = useState("")
  const [expire, setExpire] = useState(false)
  const [logo1, setLogo1] = useState("")
  const [thump1, setThump1] = useState("")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); setExpire(false) };
  const usrrole = window.localStorage.getItem('Squelch')
  const useremail = window.localStorage.getItem('electrónico')


  const [email, setEmail] = useState()
  const [oldpassword, setOldPassword] = useState("")
  const [newpassword, setNewPassword] = useState("")

  const navigate = useNavigate()

  const getdetails = async () => {
    try {
      const { data } = await Axios.post(`/users/get_brand_detail`, { email: useremail }, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })
      console.log(data, 'data')
      if (data?.success) {
        setDetails(data?.result)
        if (data?.result?.role === 'admin') {
          setName(data?.result?.name)
          console.log("🚀 ~ file: AdminProfileBody.js:126 ~ getdetails ~ data?.result:", data?.result)
          setLogo(data?.result?.logo)
          setThump(data?.result?.thumb_nail)
          setEmail(data?.result?.email)
          setLogo1(data?.result?.logo)
          setThump1(data?.result?.thumb_nail)
        } else if (data?.result?.role === 'user') {
          setName(data?.result?.firstname)
          setEmail(data?.result?.email)
          setThump(data?.result?.coverPicture)
          setThump1(data?.result?.coverPicture)
          console.log(data.result.coverPicture, 'cover picture');
        } else if (data?.result?.user_info.role === 'brand') {
          setLogo(data?.result?.user_info?.logo)
          setThump(data?.result?.user_info?.thumb_nail)
          setEmail(data?.result?.user_info?.email)
          const da = data?.result?.email_pswd
          for (let i = 0; i <= da.length; i++) {
            if (da[i].email == useremail) {
              setName(da[i].name)
            }
          }

        }
        else {
          setName(data?.firstname)
          console.log("🚀 ~ file: AdminProfileBody.js:126 ~ getdetails ~ data?.result:", data?.result)
          setLogo(data?.result?.user_info?.logo)
          setThump(data?.result?.user_info?.thumb_nail)
          setLogo1(data?.result?.user_info?.logo)
          setThump1(data?.result?.user_info?.thumb_nail)
        }

      }
    } catch (error) {
      console.log("🚀 getdetails ~ error:", error)
    }

  }
  useEffect(() => {
    getdetails()
  }, [])

  const getProfile = () => {
    getdetails()
  }
  const selfRenewal = async () => {
    try {
      setExpire(true)
      const { data } = await Axios.post(`/users/self_renewal`, {}, {
        headers: {
          Authorization: window.localStorage.getItem('AdminToken')
        }
      })

      if (data?.success) {
        toast.success("Selfrenewal Updated Successfully")
        //setRow(data?.result)
        //setExpire(true)
        // setOpen(true)
        getdetails()
      }
      else {
        toast.error("Something went Wrong")
      }
    } catch (error) {
      console.log("🚀 getdetails ~ error:", error)

    }

  }

  const createLogo = async (e) => {
    try {
      let files = await e[0]
      let formData = await new FormData();
      formData.append("image", files)

      const { data } = await Axios.post('/users/uploadlogo', formData)
      if (data.result) {
        setLogo(data.result)
        // navigate(`${consts.route}/register-success`)
        // toast.success('Image uploaded Successfully')

      }
    } catch (error) {
      console.log(error, "err")
    }
  }

  const createThump = async (e) => {
    try {
      let files = await e[0]
      let formData = await new FormData();
      formData.append("image", files)

      const { data } = await Axios.post('/users/uploadlogo', formData)
      if (data.result) {
        console.log(data?.result, "res")
        setThump(data.result)
        // navigate(`${consts.route}/register-success`)
        // toast.success('Image uploaded Successfully')
      }

    } catch (error) {
      console.log(error, "err")
    }
  }

  const update_profile = async () => {
    try {
      console.log(name, emailerr, logo, thump, oldpassword, newpassword, "vijay")
      if (usrrole != "user") {
        if (name === "") {
          setnameerr("Please Enter Name")
        }
        else if (logo === "") {
          console.log("hai2");
          setlogoerr("Please Select Logo")
        }
        else if (thump === "") {
          setthumberr("Please Select Thump")
        }
        // else if(oldpassword === ""){
        //   setpassworderr("Please Select Old Password")
        // }
        // else if(newpassword === ""){
        //   setnewpasserr("Please Select New Password")
        // }

        else {
          const { data } = await Axios.post(`/update_brand`, {
            name: name,
            logo: logo,
            thumbnail: thump,
            email: email,
            oldpassword: oldpassword,
            newpassword: newpassword,

          },
            {
              headers: {
                Authorization: window.localStorage.getItem('AdminToken')
              }
            })
          if (data?.success === true) {
            toast.success('Profile Updated Successfully')
            sts(true)
            setOldPassword("")
            setNewPassword("")
            //getdetails()
          }
          else {
            if (data?.message === "Incorrect Password") {
              setpassworderr(data?.message)
            }
            else {
              toast.error(data?.message)
            }
          }
        }
      } else {
        if (name === "") {
          setnameerr("Please Enter Name")
        }
        else if (thump === "") {
          setthumberr("Please Select Thump")
        }
        // else if(oldpassword === ""){
        //   setpassworderr("Please Select Old Password")
        // }
        // else if(newpassword === ""){
        //   setnewpasserr("Please Select New Password")
        // }

        else {
          const { data } = await Axios.post(`/update_brand`, {
            name: name,
            logo: logo,
            thumbnail: thump,
            email: email,
            oldpassword: oldpassword,
            newpassword: newpassword,

          },
            {
              headers: {
                Authorization: window.localStorage.getItem('AdminToken')
              }
            })
          if (data?.success === true) {
            toast.success('Profile Updated Successfully')
            sts(true)
            setOldPassword("")
            setNewPassword("")
            //getdetails()
          }
          else {
            if (data?.message === "Incorrect Password") {
              setpassworderr(data?.message)
            }
            else {
              toast.error(data?.message)
            }
          }
        }
      }


    } catch (error) {
      console.log("🚀 ~ error:", error)
    }
  }

  const update_profile_admin = async () => {
    try {
      console.log(name, logo, thump, "asdfsdf")
      if (name == "") {
        setnameerr("Please Enter Name")
      }
      else if (logo == "") {
        setlogoerr("Please Select Logo")
      }
      else if (thump == "") {
        setthumberr("Please Select Thump")
      }
      else {
        const { data } = await Axios.post(`/update_brand_admin`, {
          name: name,
          email: useremail,
          logo: logo,
          thumbnail: thump
        },
          {
            headers: {
              Authorization: window.localStorage.getItem('AdminToken')
            }
          })
        if (data?.success === true) {
          toast.success('Profile Updated Successfully')
          sts(true)
          //getdetails()
        }
      }
    } catch (error) {
      console.log("🚀 ~ error:", error)
    }
  }

  const handleSubmitClick = async () => {
    if (newpassword !== oldpassword) {
      try {
        const { data } = await Axios.post(`/updatebrandprofile`,
          {
            name: name,
            email: email,
            logo: logo,
            thumbnail: thump,
            oldpassword: oldpassword,
            newpassword: newpassword
          }, {
          // headers: {
          // Authorization: window.localStorage.getItem('AdminToken')
          // }
        })
        if (data?.success === true) {
          toast.success(data?.message)
          sts(true)
        } else {
          toast.error(data?.message)
        }
      } catch (error) {
        toast.error(error.response?.data?.message)
        // console.log("🚀 ~ error:", error)
      }
    } else {
      try {
        const { data } = await Axios.post(`/update_brand`, {
          name: name,
          email: email,
          logo: logo,
          thumbnail: thump
        },
          {
            headers: {
              Authorization: window.localStorage.getItem('AdminToken')
            }
          })
        if (data?.success === true) {
          toast.success('Profile Updated Successfully')
          sts(true)
          //getdetails()
        }
      } catch (error) {
        console.log("🚀 ~ error:", error)
      }
    }
  }

  // const update_profile_brand = async () => {
  //   try{
  //     const { data } = await Axios.post(`/updatebrandprofile`, 
  //     {
  //       name: name,
  //       email: email,
  //       oldpassword: oldpassword,
  //       newpassword: newpassword
  //     }, {
  //       headers: {
  //         Authorization: window.localStorage.getItem('AdminToken')
  //       }
  //     }).then(response => console.log(data,'wegewgwege'))
  //   }catch (error){
  //     console.log("🚀 ~ error:", error)
  //   }

  // }

  return (
    <div className='dashboard-body'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className={classes.containwidth}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Item className={classes.dashboargraph} id="profile-block">
              <div className='gotoDiv'>
                {
                  (usrrole === 'admin') ?
                    <Button variant="contained" onClick={() => { navigate(`${consts.route}/dashboard`) }} startIcon={<Goback />}>
                      Back
                    </Button> : <></>
                }
                {
                  (usrrole === 'brand') ?
                    <Button variant="contained" onClick={() => { navigate(`${consts.route}/brand-/brand-dashboard`) }} startIcon={<Goback />}>
                      Back
                    </Button> : <></>
                }
                {
                  (usrrole === 'user') ?
                    <Button variant="contained" onClick={() => { navigate(`${consts.route}/user/dashboard`) }} startIcon={<Goback />}>
                      Back
                    </Button> : <></>
                }



                {details?.is_expire === '1' ? <div className='block-item'>
                  <h6>Your Licence will be expired within 2 days,Click selfRenewal button to renew your Licence</h6>

                </div> : <></>}
              </div>
              {
                usrrole !== 'admin' ?
                  <Grid container spacing={0} className='profile-inner'>

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>

                      <div className='company-details'>
                        {
                          usrrole !== 'user' ?
                            <>
                              <label>Company:</label>   <span style={{ fontSize: '22px', color: 'black', fontWeight: 300 }} >{details?.user_info?.name}</span>

                              <div className='firm-logo'><img src={details?.user_info?.logo ? details?.user_info?.logo : profilebrand} alt="brandnamelogo" /></div>
                              <div className='profile-image-outer'>
                                <h4>Select Logo</h4>
                                <div className='profile-image profile-image-design-block'>
                                  <form>
                                    <input type="file" accept='image/*' className='input-field' hidden
                                      onChange={({ target: { files } }) => {
                                        if (files?.length > 0) {
                                          createLogo(files)
                                          files[0] && setImgName(files[0].name)
                                          if (files) {
                                            setProfileImageChange(URL.createObjectURL(files[0]))
                                          }
                                        }

                                      }}
                                    />
                                    {profileImageChange ?
                                      <img src={profileImageChange} alt={imgName} /> :
                                      <>
                                        {logo ?
                                          <img src={logo} alt={logo} /> :
                                          <div className='upload-image-block'><CloudUploadIcon /></div>
                                        }</>

                                    }
                                  </form>
                                </div>
                                <div className='preview-img'>
                                  <div className='edit-image-btn'></div>
                                  <div className='edit1' onClick={() => document.querySelector(".input-field").click()}><CloudUploadIcon /></div>
                                </div>
                                {/* <div class="edit1"><Button variant="contained" className='upload-hover-icon' endIcon={<CloudUploadIcon />}></Button></div> */}

                                {/* <div class="edit1">
                                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloudUploadIcon"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                                  <div>Upload Audio</div>
                                </div> */}

                              </div>

                            </>
                            :
                            <></>
                        }

                        <div className='profile-image-outer'>
                          <h4>Select Profile Picture</h4>
                          <div className='profile-image profile-image-design-block'>
                            <form>
                              <input type="file" accept='image/*' className='input-field-banner' hidden

                                onChange={({ target: { files } }) => {
                                  if (files?.length > 0) {
                                    createThump(files)
                                    files[0] && setImgBannerName(files[0].name)
                                    if (files) {
                                      setBannerImageChange(URL.createObjectURL(files[0]))
                                    }
                                  }

                                }}
                              />
                              {profileBannerImageChange ?
                                <img src={profileBannerImageChange} alt={imgBannerName} /> :
                                <>
                                  {
                                    thump ?
                                      <img src={thump} alt={thump} /> :
                                      <div className='upload-image-block'><CloudUploadIcon /></div>
                                  }
                                </>
                              }
                            </form>
                          </div>
                          <div className='preview-img'>
                            <div className='edit-image-btn'></div>
                            <div className='edit1' onClick={() => document.querySelector(".input-field-banner").click()}><CloudUploadIcon /></div>
                          </div>

                          {/* <div className='edit-image-btn' onClick={() => document.querySelector(".input-field-banner").click()}>
                            <Button variant="contained" endIcon={<BorderColorIcon />}>
                              Select
                            </Button>
                          </div> */}
                        </div>

                      </div>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <div className='profilebrand'><img src={profilebrand} alt="profilebrand" /></div>
                      <div className='input-textfield-block'>
                        <label>User Name :</label>
                        <TextField id="outlined-basic" variant="outlined" onChange={(e) => { setnameerr(null); setName(e.target.value) }} value={name} />
                      </div>
                      {nameerr !== null ? (
                        <div className="errorDiv">{nameerr}</div>
                      ) : (
                        <></>
                      )}
                      {/* <div className='input-textfield-block'>
                    <label>Brand ID :</label>
                    <TextField id="outlined-basic" variant="outlined" value={details?.user_info?.name} />
                  </div> */}
                      <div className='input-textfield-block'>
                        <label>Email :</label>
                        <TextField id="outlined-basic" variant="outlined" value={email} disabled onChange={(e) => { setEmail(e.target.value) }} />
                      </div>

                      <div className='cmn-input-cls input-textfield-block profile-pass-box '>
                        <label>Old Password :</label>
                        <FormControl sx={{ m: 1, width: 'fit-content' }} variant="outlined" className='textField'>
                          <OutlinedInput
                            // inputRef={password}
                            //  disabled
                            onChange={(e) => {
                              setpassworderr(null);
                              setOldPassword(e.target.value)
                            }}
                            id="outlined-adornment-password"
                            type={showPassword ? 'password' : 'text'}
                            value={oldpassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Old Password"
                          />
                          {passworderr ? <div className="errorDiv">{passworderr}</div> : <></>}

                        </FormControl>
                      </div>

                      <div className='cmn-input-cls input-textfield-block profile-pass-box'>
                        <label>New Password :</label>
                        <FormControl sx={{ m: 1, width: 'fit-content' }} variant="outlined" className='textField'>
                          <OutlinedInput
                            // inputRef={password}
                            //  disabled
                            onChange={(e) => {
                              setNewPassword(e.target.value)
                              setpassworderr(null);
                              setnewpasserr(null)
                            }}
                            id="outlined-adornment-password"
                            type={showPassword ? 'password' : 'text'}
                            value={newpassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="New Password"
                          />
                          {newpasserr ? <div className="errorDiv">{newpasserr}</div> : <></>}
                        </FormControl>

                        {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                      </div>
                      {/* <div className='input-textfield-block'>
      <label>Confirm :</label>
      <div className='psswrd-block-last'>
      <TextField id="outlined-basic" variant="outlined" value="bharath@25" type="password" />
      </div>
    </div> */}

                      <div className='input-textfield-block Update-Data'>
                        <Button variant="contained" onClick={() => { update_profile() }} >Update Data</Button>
                      </div>

                    </Grid >
                  </Grid > :
                  <Grid container spacing={0} className='profile-inner'>

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>

                      <div className='company-details'>
                        <label>Company:</label><span style={{ fontSize: '22px', color: 'black', fontWeight: 300 }}>{details?.user_info?.name}</span>

                        <div className='firm-logo'><img src={details?.user_info?.logo ? details?.user_info?.logo : profilebrand} alt="brandnamelogo" /></div>
                        <div className='profile-image-outer'>
                          <h4>Select Logo</h4>
                          <div className='profile-image profile-image-design-block'>
                            <form>
                              <input type="file" accept='image/*' className='input-field' hidden
                                onChange={({ target: { files } }) => {
                                  if (files?.length > 0) {
                                    setlogoerr("")
                                    createLogo(files)
                                    files[0] && setImgName(files[0].name)
                                    if (files) {
                                      setProfileImageChange(URL.createObjectURL(files[0]))
                                    }
                                  }

                                }}
                              />
                              {profileImageChange ?
                                <img src={profileImageChange} alt={imgName} /> :
                                <>
                                  {logo ?
                                    <img src={logo} alt={logo} /> :
                                    <div className='upload-image-block'><CloudUploadIcon /></div>
                                  }</>

                              }
                            </form>
                          </div>
                          <div className='edit-image-btn' onClick={() => document.querySelector(".input-field").click()}>
                            <Button variant="contained" onClick={() => { setlogoerr("") }} endIcon={<BorderColorIcon />}>
                              Select
                            </Button>
                          </div>
                          {logoerr != "" ? <div>{logoerr}</div> : <></>}
                        </div>

                        <div className='profile-image-outer'>
                          <h4>Select Profile Picture</h4>
                          <div className='profile-image profile-image-design-block'>
                            <form>
                              <input type="file" accept='image/*' className='input-field-banner' hidden
                                onChange={({ target: { files } }) => {
                                  if (files?.length > 0) {
                                    setthumberr("")
                                    createThump(files)
                                    files[0] && setImgBannerName(files[0].name)
                                    if (files) {
                                      setBannerImageChange(URL.createObjectURL(files[0]))
                                    }
                                  }

                                }}
                              />
                              {profileBannerImageChange ?
                                <img src={profileBannerImageChange} alt={imgBannerName} /> :
                                <>
                                  {
                                    thump ?
                                      <img src={thump} alt={thump} /> :
                                      <div className='upload-image-block'><CloudUploadIcon /></div>
                                  }
                                </>
                              }
                            </form>
                          </div>
                          <div className='edit-image-btn' onClick={() => document.querySelector(".input-field-banner").click()}>
                            <Button variant="contained" onClick={() => { setthumberr("") }} endIcon={<BorderColorIcon />}>
                              Select
                            </Button>
                          </div>
                          {thumberr != "" ? <div>{thumberr}</div> : <></>}
                        </div>

                      </div>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <div className='profilebrand'><img src={profilebrand} alt="profilebrand" /></div>
                      <div className='input-textfield-block'>
                        <label>User Name :</label>
                        <TextField id="outlined-basic" variant="outlined" value={name} onChange={(e) => { setName(e.target.value); setnameerr(null) }} />

                      </div>
                      {nameerr !== null ? (
                        <div className="errorDiv">{nameerr}</div>
                      ) : (
                        <></>
                      )}
                      {/* <div className='input-textfield-block'>
                  <label>Brand ID :</label>
                  <TextField id="outlined-basic" variant="outlined" value={details?.user_info?.name} />
                </div> */}
                      <div className='input-textfield-block'>
                        <label>Email :</label>
                        <TextField id="outlined-basic" variant="outlined" disabled value={details?.email} />
                      </div>

                      <div className='cmn-input-cls input-textfield-block profile-pass-box'>
                        <label>Password :</label>
                        <FormControl sx={{ m: 1, width: 'fit-content' }} variant="outlined" className='textField'>
                          <OutlinedInput
                            // inputRef={password}
                            disabled
                            onChange={() => {
                              setpassworderr(null);
                            }}
                            id="outlined-adornment-password"
                            type={'password'}
                            value='summaasdffd'
                            // endAdornment={
                            //   <InputAdornment position="end">
                            //     <IconButton
                            //       aria-label="toggle password visibility"
                            //       onClick={handleClickShowPassword}
                            //       onMouseDown={handleMouseDownPassword}
                            //       edge="end"
                            //     >
                            //       {showPassword ? <Visibility /> : <VisibilityOff />}
                            //     </IconButton>
                            //   </InputAdornment>
                            // }
                            label="Password"
                          />
                        </FormControl>
                        {passworderr !== null ? (
                          <div style={{ color: "red", textAlign: 'center' }}>{passworderr}</div>
                        ) : (
                          <></>
                        )}
                        {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                      </div>

                      {/* <div className='input-textfield-block'>
    <label>Password :</label>
    <div className='psswrd-block-last'>
    <TextField id="outlined-basic" variant="outlined" disabled value="bharath@25" type="password" />
    </div>
  </div> */}
                      {/* <div className='input-textfield-block'>
    <label>Confirm :</label>
    <div className='psswrd-block-last'>
    <TextField id="outlined-basic" variant="outlined" value="bharath@25" type="password" />
    </div>
  </div> */}

                      <div className='input-textfield-block Update-Data'>
                        <Button variant="contained" onClick={() => { update_profile_admin() }} >Update Data</Button>
                      </div>

                    </Grid>
                  </Grid >
                // <Grid container spacing={0} className='profile-inner'>

                //   <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                //     <div className='company-details'>
                //     </div>
                //   </Grid>
                //   <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                //     <div className='profilebrand'><img src={profilebrand} alt="profilebrand" /></div>
                //     <div className='input-textfield-block'>
                //       <label>Admin Name :</label>
                //       <TextField id="outlined-basic" variant="outlined" inputRef={name} onChange={(e) => { setNameerr(null) }} />
                //     </div>
                //     {nameerr !== null ? (
                //       <div style={{ color: "red", textAlign: 'center' }}>{nameerr}</div>
                //     ) : (
                //       <></>
                //     )}
                //     <div className='input-textfield-block'>
                //       <label>Admin Email :</label>
                //       <TextField id="outlined-basic" variant="outlined" inputRef={email} onChange={(e) => { setemailerr(null) }} />

                //     </div>
                //     {emailerr !== null ? (
                //       <div style={{ color: "red", textAlign: 'center' }}>{emailerr}</div>
                //     ) : (
                //       <></>
                //     )}


                //     <div className='cmn-input-cls input-textfield-block profile-pass-box'>
                //       <label>Password :</label>
                //       <FormControl sx={{ m: 1, width: 'auto' }} variant="outlined" className='textField'>
                //         <OutlinedInput
                //           inputRef={password}
                //           onChange={() => {
                //             setpassworderr(null);
                //           }}
                //           id="outlined-adornment-password"
                //           type={'password'}

                //           // endAdornment={
                //           //   <InputAdornment position="end">
                //           //     <IconButton
                //           //       aria-label="toggle password visibility"
                //           //       onClick={handleClickShowPassword}
                //           //       onMouseDown={handleMouseDownPassword}
                //           //       edge="end"
                //           //     >
                //           //       {showPassword ? <Visibility /> : <VisibilityOff />}
                //           //     </IconButton>
                //           //   </InputAdornment>
                //           // }
                //           label="Password"
                //         />
                //       </FormControl>

                //       {/* <TextField id="outlined-basic" label="Password" variant="outlined" className='textField' /> */}
                //     </div>
                //     {passworderr !== null ? (
                //       <div style={{ color: "red", textAlign: 'center' }}>{passworderr}</div>
                //     ) : (
                //       <></>
                //     )}


                //     <div className='input-textfield-block Update-Data'>
                //       {
                //         loadingstatus === true ?
                //           <>
                //             <LoadingButton
                //               loading
                //               loadingPosition="start"
                //               startIcon={<SaveIcon />}
                //               variant="outlined"
                //             >
                //               Submit....
                //             </LoadingButton></>
                //           :
                //           <>
                //             <Button variant="contained" onClick={() => { createSubAdmin() }} >Sumbit</Button></>
                //       }

                //     </div>

                //   </Grid>
                // </Grid>

              }
            </Item >
          </Grid >
          {/* {console.log(usrrole, "roles")} */}
          {
            (usrrole !== 'admin') && (usrrole !== 'user') && (usrrole !== "subAdmin") ?
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className='three-block-profile'>
                  <div className='block-item'>
                    <h5>Licensing</h5>
                    <div className='text-block-div'>{details?.licence_info?.ondate}</div>
                  </div>

                  <div className='block-item'>
                    <h5>License Expiration</h5>
                    <div className='text-block-div'>{details?.licence_info?.expiredate}</div>
                  </div>
                  {details?.is_expire === '2' ? <div className='block-item'>
                    <h5>Your Billing is <br></br>waiting for Admin Approval</h5>

                  </div> : <></>}
                  <div className='block-item'>
                    <h5>Self renewal</h5>

                    <Stack direction="row" spacing={1} alignItems="center" className='action-ptxt'>
                      <Typography>Off</Typography>
                      <AntSwitch checked={details?.selfRenewal !== undefined ? details?.selfRenewal : expire} onClick={() => selfRenewal()} inputProps={{ 'aria-label': 'ant design' }} />
                      <Typography>On</Typography>
                    </Stack>
                    <InvoicesPopup open={open} row={row} handleClose={handleClose} getProfile={getProfile} />
                  </div>

                </div>
              </Grid>
              :
              <></>
          }



        </Grid >
      </Box >






    </div >
  )
}

export default AdminProfileBody
